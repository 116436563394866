import React, { FC } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { useGetApplication } from '~/controllers/wizard';
import { Space } from '~/components';
import ShareholdersIndividualTable from './ShareholdersIndividualTable';
import ShareholdersBusinessTable from './ShareholdersBusinessTable';
import ShareholdersBeneficialTable from './ShareholdersBeneficialTable';
import theme from '~/constants/theme';
import colors from '~/constants/colors';

const ShareholdersTab: FC = () => {
  const { t } = useTranslation('fillApplication');

  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.sm });
  const applicationQuery = useGetApplication();
  if (!applicationQuery.data) return null;
  const hasBusinessShareholder = !!applicationQuery.data.payload.shareholders?.businesses;
  const hasBeneficialOwners = !!applicationQuery.data.payload.shareholders?.businesses;

  return (
    <StyledSpace direction="vertical" size={isTablet ? 24 : 56}>
      <div>
        <Typography.Title level={4}>{t('Individual shareholders')}</Typography.Title>
        <ShareholdersIndividualTable payload={applicationQuery.data.payload} />
      </div>
      {hasBusinessShareholder && (
        <div>
          <Typography.Title level={4}>{t('Legal entity shareholders')}</Typography.Title>
          <ShareholdersBusinessTable payload={applicationQuery.data.payload} />
        </div>
      )}
      {hasBeneficialOwners && (
        <div>
          <Typography.Title level={4}>{t('Beneficial owners')}</Typography.Title>
          <ShareholdersBeneficialTable payload={applicationQuery.data.payload} />
        </div>
      )}
    </StyledSpace>
  );
};

const StyledSpace = styled(Space)`
  h4.ant-typography {
    text-transform: uppercase;
    @media (max-width: ${theme.breakpoints.sm}px) {
      text-transform: unset;
      color: ${colors.disabledBlueTextColor};
      font-size: 14px;
      line-height: 22px;
      margin-button: 16px;
    }
  }
`;

export default ShareholdersTab;
