import React, { FC } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { PickerProps } from 'antd/lib/date-picker/generatePicker';
import DatePicker from '../DatePicker';

interface Props {
  value?: any;
  onChange?: (value: any) => void;
  disableDateRule?: 'date <= today' | 'date > today' | PickerProps<Dayjs>['disabledDate'];
  [x: string]: any;
}

const DateInput: FC<Props & Omit<PickerProps<Dayjs>, 'placeholder'>> = (props) => {
  const { value, onChange, disableDateRule, ...rest } = props;
  return (
    <DatePicker
      value={parseValue(value)}
      onChange={parseOnChange(onChange)}
      size="large"
      disabledDate={(date) => {
        if (typeof disableDateRule === 'function') {
          return disableDateRule(date);
        }
        const today = dayjs();
        if (disableDateRule === 'date <= today') {
          if (date.isAfter(today) && !date.isSame(today)) return true;
        }
        if (disableDateRule === 'date > today') {
          if (date.isBefore(today)) return true;
        }
        return false;
      }}
      {...rest}
      placeholder=""
    />
  );
};

const parseValue = (value?: string | dayjs.Dayjs): dayjs.Dayjs | undefined => {
  if (typeof value === 'string') {
    return dayjs(value);
  }
  return value;
};

const parseOnChange =
  (onChange: ((value: any) => void) | undefined) => (value: dayjs.Dayjs | null) => {
    onChange?.(value?.format('YYYY-MM-DD'));
  };

export default DateInput;
