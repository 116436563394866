import axios from '~/api/axios';

export const getLocale = () => {
  return axios.get<string>('/user/locale').then((res) => res.data);
};

export const getAllLanguages = () => {
  return axios
    .get<{ defaultLanguage: string; languages: string[] }>('/misc/languages')
    .then((res) => res.data);
};

export const updateLocale = () => {
  // locale is injected to the request headers in axios config
  return axios.post('/user/locale').then((res) => res.data);
};
