export default {
  'Step data must be changed before pressing "Continue" button':
    'Step data must be changed before pressing "Continue" button',
  "Before application submit, please ensure a payment link is sent to merchant and payment is received. To get the latest payment status please update the page. Once payment is successful you will see payment reference in the 'Pay-by-link' section.":
    "Before application submit, please ensure a payment link is sent to merchant and payment is received. To get the latest payment status please update the page. Once payment is successful you will see payment reference in the 'Pay-by-link' section.",
  'Application has been updated. Please refresh the page to see changes.':
    'Application has been updated. Please refresh the page to see changes.',
  'Rental amount can not be changed at this stage':
    'Rental amount can not be changed at this stage',
  'The application has already been assigned to another team':
    'The application has already been assigned to another team',
  'Session has expired': 'Session has expired',
  'Internet connection is lost. Please check your connection':
    'Internet connection is lost. Please check your connection',
};
