import paths from '~/pages/paths';
import { Application } from '~/types/Application';

export const getActiveStepPath = (application: Application): string => {
  switch (application.stage) {
    case 'companyType':
      return paths.companyType._;
    case 'highRisk':
      return paths.getInTouch._;
    case 'terminated':
    case 'fillApplication': {
      const stepKey = application.stagePayload.activeStep.key;
      const stepView = application.stagePayload.activeStep.uiKey;
      switch (stepKey) {
        case 'services':
          return paths.wizard.services;

        default:
          if (stepKey?.startsWith('application')) {
            return `${paths.wizard.application}/${stepView}`;
          }
          return paths.wizard._;
      }
    }
    case 'waitingForScreening':
      return paths.stageResult.waitingForScreening;
    case 'error':
      return paths.stageResult.error;
    case 'screeningError':
      return paths.stageResult.screeningError;
    case 'screeningReject':
    case 'salesforceReject':
      return paths.stageResult.screeningReject;
    case 'salesforceSoftDecline':
      return paths.stageResult.salesforceSoftDecline;
    case 'salesforceHardDecline':
      return paths.stageResult.salesforceHardDecline;
    case 'salesforceError':
      return paths.stageResult.salesforceError;
    case 'salesforceTechnicalError':
      return paths.stageResult.salesforceTechnicalError;
    case 'submit':
      return paths.stageResult.submit;
    case 'successfulOnboarding':
      return paths.stageResult.successfulOnboarding;
    case 'completed':
      return paths.stageResult.completed;
    case 'contract':
      return paths.wizard.signContract;
    case 'payment':
      return paths.wizard.payment;
    default:
      return paths.stageResult.unknownStage;
  }
};
