import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { getI18n } from 'react-i18next';
import TableHeader from '~/components/views/agent/tableElements/TableHeader';
import { CellText } from '~/components/views/agent/tableElements/cells/CellText';
import { HandbookRes } from '~/types/Handbook';
import { AgentLead } from '~/types/AgentLead';
import { AgentApplication } from '~/types/AgentApplication';

function businessLineCell(handbook?: HandbookRes): ColumnsType<any>[0] {
  return {
    title: () => (
      <TableHeader
        title={getI18n().t('Business line', { ns: 'agent' })}
        sortingDataKey="businessLine"
      />
    ),
    render: (businessLine, record: AgentLead | AgentApplication) => {
      const businessLineLocalized = handbook?.handbooks
        ?.find((item) => item.key === 'businessNature')
        ?.values?.find((item) => item.apiValue === record.businessNature)
        ?.values?.find((item) => item.key.includes('businessLine'))
        ?.values?.find((item) => item.apiValue === businessLine)?.localizedValue;
      return <CellText>{businessLineLocalized}</CellText>;
    },
    dataIndex: ['businessLine'],
    key: 'businessLine',
    width: 155,
  };
}

export default businessLineCell;
