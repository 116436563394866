import { Application } from '~/types/Application';
import { getStagePayload } from './getStagePayload';

export const hasSoftDeclineFields = (
  application: Application | undefined,
  fieldKey: string
): boolean => {
  const stagePayload = getStagePayload.wizard(application);
  if (!stagePayload) return false;
  return (
    !!stagePayload.activeStep.errorInformation?.errorFields?.find((errObj) =>
      Object.keys(errObj).find((namePath) => namePath.includes(fieldKey))
    ) ||
    !!stagePayload.activeStep.errorInformation?.enabledFields?.find((errObj) =>
      errObj.includes(fieldKey)
    )
  );
};
