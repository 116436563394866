import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import LeadForm, { formName } from '~/components/views/agent/leadForm/LeadForm';
import buildNamePath from '~/utils/buildNamePath';
import FormDrawer from '~/components/FormDrawer';
import { AgentLead, AgentLeadPayload } from '~/types/AgentLead';
import { openNewApplicationTab } from '~/utils/goToApplication';
import { Form } from '~/components';
import LeadStatusTag from '~/components/StatusTag';

interface Props {
  open: boolean;
  onClose: () => void;
  initialValues?: AgentLead;
}

export const LeadShowConverted: FC<Props> = (props) => {
  const { t } = useTranslation('agent');
  const { open, onClose, initialValues } = props;
  const [form] = Form.useForm<AgentLeadPayload>();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(
      initialValues
        ? {
            leadId: initialValues.leadId,
            assignee: initialValues.assignee,
            ...initialValues.payload,
          }
        : {}
    );
  }, [form, initialValues]);

  return (
    <FormDrawer
      id="leadConverted"
      visible={open}
      onClose={onClose}
      title={
        <LeadStatusTag
          status={initialValues?.status}
          title={initialValues?.companyName}
          statusKey={initialValues?.statusKey}
        />
      }
      formName={formName}
      closable
      withContinueButton={false}
      withCustomButtons
      customButtonsProps={[
        {
          id: 'goToApplicationButton',
          title: t('Go to Application'),
          type: 'primary',
          onClick: () => {
            if (initialValues?.applicationId) openNewApplicationTab(initialValues.applicationId);
          },
        },
      ]}
    >
      <LeadForm form={form} n={buildNamePath([])} disabled status={initialValues?.statusKey} />
    </FormDrawer>
  );
};
