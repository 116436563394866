import React, { FC, useState } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Coords } from 'google-map-react';
import styled from 'styled-components';
import { isEqual } from 'lodash';

import { LocationIcon } from '~/img';
import Drawer, { DRAWER_WIDTH } from './Drawer';
import GoogleMap, { isValidCoords } from './GoogleMap';
import MapMarker from './GoogleMap/MapMarker';
import { Button } from '~/components';

interface Props {
  id: string;
  value?: { latitude: number; longitude: number };
  onChange?: (value: Props['value']) => void;
  disabled?: boolean;
}

const PinLocation: FC<Props> = (props) => {
  const { value, onChange, disabled, id } = props;
  const { t } = useTranslation('fillApplication');

  const [state, setState] = useState(value);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setState(value);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleSave = () => {
    onChange?.(state);
    handleClose();
  };

  return (
    <>
      <Button
        id={id}
        success={isValidCoords(value)}
        type="default"
        icon={<LocationIcon />}
        onClick={handleOpen}
        disabled={disabled}
      >
        {isValidCoords(value) ? t('Location pinned') : t('Pin location')}
      </Button>

      <StyledDrawer className="PinLocation-StyledDrawer" visible={open} onClose={handleClose}>
        <Typography.Title level={4}>{t('Location').toUpperCase()}</Typography.Title>
        <Typography.Paragraph>
          {t('Please pin your company location on the map.')}
        </Typography.Paragraph>
        <div className="PinLocation-MapContainer">
          <GoogleMapInput value={state} onChange={setState} />
        </div>
        <Button
          id={`${id}_saveButton`}
          className="PinLocation-SaveButton"
          type="primary"
          disabled={
            !isValidCoords(state) ||
            (isValidCoords(value) && isValidCoords(state) && isEqual(value, state))
          }
          onClick={handleSave}
        >
          {t('Save')}
        </Button>
      </StyledDrawer>
    </>
  );
};

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    position: relative;

    .PinLocation-MapContainer {
      height: calc(100vh - 126px);
      margin: 0 -32px -40px -32px;
    }

    .PinLocation-SaveButton {
      position: absolute;
      bottom: 40px;
      width: 368px;
      @media (max-width: ${DRAWER_WIDTH}px) {
        width: calc(100vw - 64px);
      }
    }
  }
`;

const GoogleMapInput: FC<Omit<Props, 'disabled' | 'id'>> = (props) => {
  const { value, onChange } = props;

  const geolocation = {
    lat: value?.latitude || 0,
    lng: value?.longitude || 0,
  };

  const handleMapClick = ({ lat, lng }: Coords) => {
    onChange?.({ latitude: lat, longitude: lng });
  };

  return (
    <GoogleMap
      onClick={handleMapClick}
      center={geolocation}
      zoom={isValidCoords(geolocation) ? 16 : undefined}
      options={{ fullscreenControl: false, zoomControl: false }}
    >
      {isValidCoords(geolocation) ? (
        <MapMarker lat={geolocation.lat} lng={geolocation.lng} />
      ) : null}
    </GoogleMap>
  );
};

export default PinLocation;
