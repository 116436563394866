import React, { FC } from 'react';
import { Button, ButtonProps } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDirection } from '~/hooks/i18nHooks';
import { UaePassLogoIcon } from '~/img';

const UaePassButton: FC<ButtonProps> = (props) => {
  const { t } = useTranslation();
  const dir = useDirection();

  const { title } = props;

  return (
    <StyledButton icon={<UaePassLogoIcon />} block dir={dir} {...props}>
      <span className="UaePassButton-Text">{title || t('Sign in with UAE PASS')}</span>
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-color: #231f20;
  border-width: 1px;
  .anticon {
    font-size: 20px;
    display: flex;
    font-color: #1d1d1a;
    padding-right: 8px;
  }
  .UaePassButton-Text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    margin-left: 0 !important;
  }
  &[dir='rtl'] {
    .anticon {
      padding-right: 0;
      padding-left: 8px;
    }
  }
`;

export default UaePassButton;
