import { Application, Stage } from '~/types/Application';

export const getStagePayload = {
  wizard: (application: Application | undefined) => {
    if (!application) return undefined;
    return application.stage === Stage.fillApplication
      ? application.stagePayload
      : application.stage === Stage.terminated
      ? application.stagePayload
      : undefined;
  },
  terminated: (application: Application | undefined) => {
    if (!application) return undefined;
    return application.stage === Stage.terminated ? application.stagePayload : undefined;
  },
  contract: (application: Application | undefined) => {
    if (!application) return undefined;
    return application.stage === Stage.contract ? application.stagePayload : undefined;
  },
  payment: (application: Application | undefined) => {
    if (!application) return undefined;
    return application.stage === Stage.payment ? application.stagePayload : undefined;
  },
  salesforceSoftDecline: (application: Application | undefined) => {
    if (!application) return undefined;
    return application.stage === Stage.salesforceSoftDecline ? application.stagePayload : undefined;
  },
};
