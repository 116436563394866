import React, { FC, useMemo } from 'react';
import { Select as AntdSelect, SelectProps as AntdSelectProps, SelectProps } from 'antd';
import styled from 'styled-components';
import { DirectionProp } from '~/types/Direction';
import { useDirection } from '~/hooks/i18nHooks';
import direction from '~/utils/direction';

function Select<VT = any[]>(props: AntdSelectProps<VT>) {
  const { options, id, showSearch, loading, value: rawInputValue, ...rest } = props;
  const value = typeof rawInputValue === 'object' ? undefined : rawInputValue;

  const dir = useDirection();

  const resultOptions = useMemo(() => {
    return options?.map((opt) => ({
      ...opt,
      value: opt.value,
      label: reduceSpaces(opt.label as string),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return (
    <StyledSelect<any>
      id={loading ? '' : id}
      showSearch={showSearch}
      filterOption={
        showSearch
          ? (enteredValue: string, option: any) => {
              return (
                option.label?.toLowerCase().indexOf(reduceSpaces(enteredValue)?.toLowerCase()) !==
                -1
              );
            }
          : undefined
      }
      loading={loading}
      dir={dir}
      value={value}
      {...rest}
    >
      {resultOptions?.map((option) => {
        const { label, key, value: optionValue, ...restOption } = option;
        return (
          <AntdSelect.Option
            key={key || optionValue}
            value={optionValue}
            label={label}
            dir={dir}
            {...restOption}
            id={`${id}_${optionValue}`}
          >
            {React.isValidElement(label) ? label : label?.toString()}
          </AntdSelect.Option>
        );
      })}
    </StyledSelect>
  );
}

const reduceSpaces = (value: string | undefined) => {
  if (!value) return value;
  return value.split(' ').filter(Boolean).join(' ');
};

type UIProps = SelectProps<any> & DirectionProp;

const StyledSelect = styled<FC<UIProps>>(AntdSelect)`
  .ant-select-selection-item {
    ${direction.padding.right('18px !important')};
    ${direction.padding.left('0px !important')};
  }
  .ant-select-selection-search {
    ${direction.left('15px !important')};
    ${direction.right('unset !important')};
  }
  .ant-select-arrow,
  .ant-select-clear {
    ${direction.right(11)};
    ${direction.left('unset')};
  }
`;

export default Select;
