export const agent = {
  applications: 'التطبيقات',
  leads: 'يؤدي',
  map: 'خريطة',
  menu: 'قائمة',
  searchPlaceholder: 'البحث عن طريق اسم الشركة أو العنوان',
  OPENED: 'افتتح',
  DONE: 'منتهي',
  ALL: 'الجميع',
  APPLICATION: 'طلب',
  LEAD: 'قيادة',
  Status: 'حالة',
  Emirate: 'الإمارات',
  CreatedDate: 'تاريخ الإنشاء',
  'Created date from': 'تاريخ الإنشاء من',
  'Created date to': 'تاريخ الإنشاء ل',
  Assignee: 'الوكيل',
  Filter: 'منقي',
  'Sort by': 'ترتيب حسب',
  Terminate: 'إنهاء',
  'Flag as Contacted': 'وضع علامة باسم جهات الاتصال',
  'Create Application': 'إنشاء التطبيق',
  Confirm: 'يتأكد',
  'Are you ready to create Merchant Application?': 'هل أنت جاهز لإنشاء تطبيق التاجر؟',
  'Merchant onboarding application will be created for this merchant. Please confirm to proceed.':
    'سيتم إنشاء تطبيق التاجر على متن الطائرة لهذا التاجر. يرجى التأكيد للمتابعة.',
  'Are you sure?': 'هل أنت واثق؟',
  'Please define the reason why you would like to terminate merchant onboarding process.':
    'يُرجى تحديد سبب رغبتك في إنهاء عملية إعداد التاجر.',
  'Reason for termination': 'سبب الإنهاء',
  'Termination reason': 'سبب الإنهاء',
  'Termination date': 'تاريخ الإنهاء',
  Save: 'يحفظ',
  'Convert to Application': 'قم بالتحويل إلى التطبيق',
  'New Lead': 'قيادة جديدة',
  'Create Lead': 'خلق الرصاص',
  'Lead source': 'مصدر الدليل',
  'Customer type': 'نوع العميل',
  'Company basic details': 'التفاصيل الأساسية للشركة',
  'Company Name': 'اسم الشركة',
  'Company type': 'نوع الشركة',
  'Company contact person': 'الشخص المسؤول عن الاتصال بالشركة',
  'Business nature': 'طبيعة العمل',
  'Please specify company business nature': 'يرجى تحديد طبيعة عمل الشركة',
  'Please specify company contact person': 'يرجى تحديد جهة الاتصال بالشركة',
  'Please upload a photo of outside the outlet': 'يرجى تحميل صورة من خارج المنفذ',
  'Please upload a photo of inside the outlet': 'الرجاء تحميل صورة من داخل المنفذ',
  'Select the products that merchant is interested in': 'حدد المنتجات التي يهتم بها التاجر',
  'Please select company type': 'الرجاء تحديد نوع الشركة',
  'Please select industry': 'الرجاء تحديد الصناعة',
  'Please select business line': 'الرجاء تحديد خط الأعمال',
  'First name': 'الاسم الاول',
  'Last name': 'اللقب',
  Address: 'تبوك',
  'Address line': 'خط عنوان',
  Products: 'منتجات',
  'N-Genius POS': 'N-Genius POS',
  'E-commerce': 'التجارة الإلكترونية',
  'Soft POS': 'Soft POS',
  'My Service': 'خدمتي',
  'Referral code': 'كود الإحالة',
  'Partner referral code': 'رمز إحالة الشريك',
  'Terminate Lead': 'إنهاء الرصاص',
  'Terminate Application': 'إنهاء التطبيق',
  'Go to Application': 'اذهب إلى التطبيق',
  Activate: 'تفعيل',
  'Business line': 'خط الأعمال',
  Company: 'شركة',
  Contact: 'اتصال',
  Created: 'مخلوق',
  Request: 'طلب',
  'Risk level': 'مستوى الخطر',
  Updated: 'محدث',
  '<strong>{{type}}</strong> created {{date}}': '<strong>{{type}}</strong> خلقت {{date}}',
  '"{{companyName}}" company will be deleted.': 'سيتم حذف شركة "{{companyName}}".',
};

export const leadStatus = {
  NEW: 'جديد',
  CONTACTED: 'تم الاتصال',
  TERMINATED: 'تم إنهاؤه',
  CONVERTED: 'تم تحويله إلى التطبيق',
};

export const applicationStatus = {
  duplicate: 'ينسخ',
  draft: 'مشروع',
  terminated: 'تم إنهاؤه',
  fillingData: 'تعبئة البيانات',
  rejected: 'مرفوض',
  screeningFailed: 'فشل الفرز',
  onReview: 'عند المراجعة',
  approval: 'موافقة',
  onboarding: 'على متن الطائرة',
  posPreparation: 'تحضير نقاط البيع',
  posInstallation: 'تركيب نقاط البيع',
  completed: 'مكتمل',
  hardRejected: 'رفض بشدة',
  screening: 'تحري',
  contract: 'عقد',
  payment: 'دفع',
  submissionFailure: 'فشل التقديم',
};
