import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import RadioGroupInput from '~/components/inputs/RadioGroupInput';
import { MapItemType } from '~/types/AgentMapItem';

interface Props {
  value?: any;
  onChange?: (value: any) => void;
}

const MapItemTypeInput: FC<Props> = (props): JSX.Element => {
  const { t } = useTranslation('agent');

  return (
    <RadioGroupInput
      options={[
        {
          label: t(MapItemType.APPLICATION),
          value: MapItemType.APPLICATION,
        },
        {
          label: t(MapItemType.LEAD),
          value: MapItemType.LEAD,
        },
        {
          label: t(MapItemType.ALL),
          value: MapItemType.ALL,
        },
      ]}
      {...props}
    />
  );
};

export default MapItemTypeInput;
