import { Application } from '~/types/Application';
import { isAgent } from '~/utils/getDecodedJwt';
import { isReadOnly } from '~/utils/isReadOnly';
import { isResubmit } from '~/utils/isResubmit';
import { getStagePayload } from '~/utils/getStagePayload';

export const isShareholderEditButtonDisabled = (
  application: Application | undefined,
  hasResubmitFields: boolean
) => {
  const stagePayload = getStagePayload.wizard(application);
  if (isReadOnly(application)) return true;
  if (isAgent()) return false;
  if (hasResubmitFields) return false;
  if (isResubmit(application)) return true;
  if (stagePayload?.activeStep.state === 'UPDATE_ENABLED_FIELDS' && !hasResubmitFields) return true;
  return false;
};
