import React, { CSSProperties, FC } from 'react';
import styled from 'styled-components';

import { get, isEqual, isUndefined } from 'lodash';

import AmountInput from './inputs/AmountInput';
import PercentInput from './inputs/PercentInput';
import { PricingValue } from '~/types/PricingValue';
import Form from './Form';
import { NameFn, NamePath } from '~/utils/buildNamePath';
import { validateAmount } from './views/ServicesView/validateAmount';
import { validateNumber } from './views/ServicesView/validateNumber';
import { PriceValidation } from '~/types/Services';
import { useTotalPriceState } from '~/controllers/totalPrice';
import { Amount } from '~/types/Amount';

export interface PriceInputProps {
  n: NameFn;
  amountFromName?: NamePath;
  percentFromName?: NamePath;
  disabled?: boolean;
  defaultValue: PricingValue;
  validation?: PriceValidation;
  style?: CSSProperties;
  //
  affectsPrice?: boolean;
  savedCustomValue?: PricingValue;
}

const PriceInput: FC<PriceInputProps> = (props) => {
  const {
    n,
    amountFromName,
    percentFromName,
    disabled,
    defaultValue,
    validation,
    style,
    affectsPrice,
    savedCustomValue,
  } = props;
  const defaultPercent = defaultValue?.percent;
  const defaultAmount = defaultValue?.amount;

  const [{ requiresRecalculate }, setTotalPriceState] = useTotalPriceState();
  const handleStale = (
    value: Amount | number,
    savedScopedValue: Amount | number | undefined,
    defaultScopedValue: Amount | number | undefined
  ) => {
    if (requiresRecalculate) return;
    const lastSavedValue = savedScopedValue ?? defaultScopedValue;

    if (lastSavedValue !== undefined && !isEqual(value, lastSavedValue)) {
      setTotalPriceState((prev) => ({ ...prev, requiresRecalculate: true }));
    }
  };

  return (
    <Container className="PriceInput" style={style}>
      {!isUndefined(defaultPercent) && (
        <Form.Item
          name={percentFromName || n('percent')}
          shouldUpdate={(prev, next) => get(prev, n('percent')) !== get(next, n('percent'))}
          rules={[
            {
              validator: validateNumber({
                min: validation?.percent?.min,
                max: validation?.percent?.max,
              }),
            },
          ]}
        >
          {(form) => {
            const percent = form.getFieldValue(n('percent'));
            const isPercentChanged =
              percent !== undefined && defaultPercent !== undefined && percent !== defaultPercent;

            if (affectsPrice) handleStale(percent, savedCustomValue?.percent, defaultPercent);
            return (
              <PercentInput
                label={isPercentChanged ? defaultPercent.toString() : undefined}
                disabled={disabled || !!percentFromName}
                size="middle"
                min={validation?.percent?.min}
                max={validation?.percent?.max}
                step={0.01}
              />
            );
          }}
        </Form.Item>
      )}
      {!isUndefined(defaultPercent) && !isUndefined(defaultAmount) && (
        <div className="PriceInput-Plus">+</div>
      )}
      {!isUndefined(defaultAmount) && (
        <Form.Item
          name={amountFromName || n('amount')}
          rules={[
            {
              validator: validateAmount({
                min: validation?.amount?.min,
                max: validation?.amount?.max,
              }),
            },
          ]}
          shouldUpdate={(prev, next) =>
            get(prev, n(['amount', 'value'])) !== get(next, n(['amount', 'value']))
          }
        >
          {(form) => {
            const amount = form.getFieldValue(n('amount'));

            const isAmountChanged =
              amount !== undefined &&
              defaultAmount !== undefined &&
              !isEqual(amount, defaultAmount);

            if (affectsPrice) handleStale(amount, savedCustomValue?.amount, defaultAmount);
            return (
              <AmountInput
                label={isAmountChanged ? defaultAmount.value.toString() : undefined}
                size="middle"
                disabled={disabled || !!amountFromName}
                min={validation?.amount?.min}
                max={validation?.amount?.max}
                step={0.1}
              />
            );
          }}
        </Form.Item>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: -24px;
  .PriceInput-Plus {
    margin: 0 8px 24px 8px;
  }
  .ant-input-number {
    max-width: 106px;
  }
  .ant-form-item-explain {
    text-align: end;
  }
  .ant-form-item-control-input {
    min-height: 0 !important;
  }
`;

export default PriceInput;
