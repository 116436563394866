export default {
  'Please note, any individual provided in this section will be screened.':
    'يرجى ملاحظة أنه سيتم فحص أي فرد مذكور في هذا القسم.',
  // Services initial page
  'Select services': 'حدد الخدمات',
  'Please select how you would like to take payments.':
    'يرجى تحديد الطريقة التي ترغب في تحصيل المدفوعات بها.',
  'You can select POS, E-Commerce, or both together.':
    'يمكنك تحديد نقاط البيع أو التجارة الإلكترونية أو كليهما معًا.',
  'However, if you are looking for SoftPOS we can currently only on-board you for this service on its own.':
    'ومع ذلك ، إذا كنت تبحث عن SoftPOS ، فيمكننا حاليًا فقط ضمك لهذه الخدمة بمفردها.',
  'If you are looking for SoftPOS with POS or E-Commerce, please complete the application and once on-boarded, you can request for additional services.':
    'إذا كنت تبحث عن SoftPOS مع نقاط البيع أو التجارة الإلكترونية ، فيرجى إكمال الطلب وبمجرد الانضمام ، يمكنك طلب خدمات إضافية.',
  'Please select services and options that better fits your business case.':
    'يرجى تحديد الخدمات والخيارات التي تناسب حالة عملك بشكل أفضل.',
  'Setup fee': 'رسوم الإعداد',
  'Annual fee': 'رسوم سنوية',
  'Agreement to the above Fees and Charges required':
    'الموافقة على الرسوم والنفقات المذكورة أعلاه مطلوبة',
  // Services page
  Services: 'خدمات',
  'Number of applications': 'عدد الطلبات',
  'Number of devices': 'عدد الأجهزة',
  'Read more': 'اقرأ أكثر',
  'Website requirements': 'متطلبات الموقع',
  'Website URL': 'رابط الموقع',
  'I need web integration': 'أحتاج إلى تكامل الويب',
  'Select plugin': 'حدد البرنامج المساعد',
  'Select method': 'حدد الطريقة',
  'Fees and charges*': 'الرسوم والمصاريف*',
  'Additional services*': 'خدمات إضافية*',
  Free: 'حر',
  'Payment Schemes*': 'مخططات الدفع *',
  '*These fees are subject to 5% VAT.': '* تخضع هذه الرسوم لضريبة القيمة المضافة بنسبة 5٪.',
  'Acceptance of Canada and USA cards require extra approval. Are you selling to Canada or USA? Request a call with sales.':
    'يتطلب قبول بطاقات كندا والولايات المتحدة موافقة إضافية. هل تبيع إلى كندا أو الولايات المتحدة الأمريكية؟ طلب مكالمة مع المبيعات.',
  'Reset to default': 'إعادة تعيين إلى الافتراضي',
  'Default pricing:': 'التسعير الافتراضي:',
  'Callback is requested': 'مطلوب رد الاتصال',
  'Special pricing applied': 'يتم تطبيق أسعار خاصة',
  'Total for all applications': 'المجموع لجميع التطبيقات',
  'Total for all terminals': 'المجموع لجميع المحطات',
  'General configurations': 'التكوينات العامة',
  'Request a callback': 'طلب استدعاء',
  'Please enter you message in the field below. We will contact you as soon as possible.':
    'الرجاء إدخال رسالتك في الحقل أدناه. سنتواصل معك بأقرب وقت ممكن.',
  'Your message': 'رسالتك',
  'Contact me': 'اتصل بي',
  'Company request': 'طلب الشركة',
  // Company information step
  'Company Information': 'معلومات الشركة',
  'Company license': 'رخصة الشركة',
  'Please upload the company license photo.': 'يرجى تحميل صورة رخصة الشركة.',
  'Document data:': 'بيانات المستند:',
  'Please share basic details about the company you represent.':
    'يرجى مشاركة التفاصيل الأساسية حول الشركة التي تمثلها.',
  'Date of establishment': 'تاريخ التأسيس',
  'Company legal type': 'النوع القانوني للشركة',
  'Company commercial name': 'الاسم التجاري للشركة',
  'Company license number': 'رقم ترخيص الشركة',
  'License authority': 'سلطة الترخيص',
  'License issue date': 'تاريخ اصدار الرخصة',
  'License expiry date': 'تاريخ انتهاء الترخيص',
  'Company email': 'البريد الإلكتروني للشركة',
  'Tax registration certificate': 'شهادة التسجيل الضريبي',
  'Yes, I have a Tax registration certificate.': 'نعم ، لدي شهادة التسجيل الضريبي.',
  'Please upload the Tax registration certificate photo.': 'يرجى تحميل صورة شهادة التسجيل الضريبي.',
  'Tax registration number': 'رقم التسجيل الضريبي',
  // Addresses step
  Address: 'عنوان',
  'Head office': 'مدير المكتب رئيس المكتب',
  'This must be the location where you will take payments and where we can reach you.':
    'يجب أن يكون هذا هو المكان الذي ستتلقى فيه المدفوعات وأين يمكننا الوصول إليك.',
  Country: 'دولة',
  'Address line': 'خط عنوان',
  'P.O. Box': 'ص. صندوق',
  'Office phone number': 'رقم هاتف المكتب',
  'Fax number': 'رقم الفاكس',
  Emirate: 'الإمارات',
  'Type of property occupied': 'نوع الممتلكات المشغولة',
  'Please upload your <strong>RERA registration</strong> or <strong>Lease Agreement</strong> documents.':
    'يرجى تحميل <strong>تسجيل RERA</strong> الخاص بك أو وثائق <strong>اتفاقية الإيجار</strong> .',
  'Please upload a photo of <strong>outside</strong> the outlet.':
    'يرجى تحميل صورة من <strong>خارج</strong> المنفذ.',
  'Please upload a photo of <strong>inside</strong> the outlet.':
    'الرجاء تحميل صورة من <strong>داخل</strong> المنفذ.',
  '<strong>Do you have any branches?</strong>': '<strong>هل لديك فروع؟</strong>',
  'We are currently only able to on-board one location / branch where you can take payments. However, you can continue with this process and we will be happy to add more locations / branches once you are onboarded.':
    'لا يمكننا حاليًا استخدام سوى موقع / فرع واحد على متن الطائرة حيث يمكنك تلقي المدفوعات. ومع ذلك ، يمكنك متابعة هذه العملية وسنكون سعداء بإضافة المزيد من المواقع / الفروع بمجرد انضمامك.',
  'Alternatively, you can stop the process now and request a member of our team to contact you using the contact us form <1>by link<1/>.':
    'بدلاً من ذلك ، يمكنك إيقاف العملية الآن وتطلب من أحد أعضاء فريقنا الاتصال بك باستخدام نموذج اتصل بنا <1>عن طريق الرابط<1 />.',
  Rented: 'مستأجرة',
  Owned: 'مملوكة',
  'Pin location': 'دبوس الموقع',
  'Location pinned': 'الموقع مثبت',
  Location: 'موقع',
  'Please pin your company location on the map.': 'الرجاء تثبيت موقع شركتك على الخريطة.',
  Save: 'يحفظ',
  // Authorized Representative step
  'Authorized Signatory': 'المفوض بالتوقيع',
  'Please provide the details of the person who is an authorised representative of your company.':
    'يرجى تقديم تفاصيل الشخص الذي يمثل ممثلاً مفوضًا لشركتك.',
  'Some of the fields were pre populated. Feel free to edit this data.':
    'تم ملء بعض الحقول مسبقًا. لا تتردد في تحرير هذه البيانات.',
  'Please provide the details of at least one person who is an authorised representative of your company.':
    'يرجى تقديم تفاصيل شخص واحد على الأقل يكون ممثلاً مفوضًا لشركتك.',
  'At least one of these representatives must have the authority and proof to act as an authorised signatory for your company.':
    'يجب أن يتمتع أحد هؤلاء الممثلين على الأقل بالسلطة والإثبات للعمل كموقع مفوض لشركتك.',
  'Authorised Representative {{number}}': 'الممثل المعتمد {{number}}',
  'Authorized Representative cannot be deleted': 'لا يمكن حذف الممثل المعتمد',
  'This representative is used as shareholder in "Shareholders" step':
    'يتم استخدام هذا الممثل كمساهم في خطوة "المساهمون"',
  'Remove this representative': 'إزالة هذا الممثل',
  'Add Authorised representative': 'إضافة مندوب مفوض',
  'This person is authorised signatory': 'هذا الشخص مفوض بالتوقيع',
  'Shareholder is a Politically Exposed Person (PEP)': 'المساهم هو شخص سياسي سياسي (PEP)',
  'Please upload the <strong>passport main page</strong> photo and provide the details in the fields below.':
    'يرجى تحميل صورة <strong>الصفحة الرئيسية لجواز السفر</strong> وتقديم التفاصيل في الحقول أدناه.',
  'Please upload the <strong>visa page</strong> photo and provide the details in the fields below.':
    'يرجى تحميل صورة <strong>صفحة التأشيرة</strong> وتقديم التفاصيل في الحقول أدناه.',
  Designation: 'تعيين',
  'Upload a copy of the Power of Attorney showing this person as an authorised signatory':
    'قم بتحميل نسخة من التوكيل يوضح أن هذا الشخص مفوض بالتوقيع',
  'This person is listed on the Company License as part of the company ownership structure':
    'تم إدراج هذا الشخص في ترخيص الشركة كجزء من هيكل ملكية الشركة',
  // Shareholders sole proprietor step
  'No authorized representative found': 'لم يتم العثور على ممثل مفوض',
  'Since your Company Type was selected as a sole proprietor, the Shareholders section is disabled. You can go to the next step.':
    'نظرًا لاختيار نوع الشركة الخاصة بك كمالك وحيد ، تم تعطيل قسم المساهمين. يمكنك الانتقال إلى الخطوة التالية.',
  'Ownership share:': 'حصة الملكية:',
  'Shares, %': 'تشارك، ٪',
  // Shareholders multiple step
  'Shareholder Proof Document': 'وثيقة إثبات المساهم',
  'Select the document type': 'حدد نوع المستند',
  'Memorandum of Association': 'عقد التأسيس',
  'Partnership deed': 'عقد شراكة',
  'Add shareholders': 'إضافة المساهمين',
  'Please add all shareholders with total share of 25% or more.':
    'الرجاء إضافة جميع المساهمين بحصة إجمالية تبلغ 25٪ أو أكثر.',
  'Shareholders are listed in company license': 'المساهمون مدرجون في ترخيص الشركة',
  'Upload Copy of Memorandum of Association or Partnership deed':
    'تحميل نسخة من عقد التأسيس أو عقد الشراكة',
  'Individual shareholders': 'المساهمون الأفراد',
  'Legal entity shareholders': 'مساهمي الكيان القانوني',
  'First name': 'الاسم الأول',
  'Last name': 'الكنية',
  'Full name': 'الاسم الكامل',
  'Commercial name': 'الاسم التجاري',
  'Company type': 'نوع الشركة',
  'Business line': 'خط الأعمال',
  'Company name': 'اسم الشركة',
  Passport: 'جواز سفر',
  'Emirates ID': 'هويه الإمارات',
  'Edit Individual shareholder': 'تحرير المساهم الفردي',
  'Add new Individual shareholder': 'إضافة مساهم فردي جديد',
  'Personal data': 'بيانات شخصية',
  'Date of birth': 'تاريخ الولادة',
  License: 'رخصة',
  Nationality: 'جنسية',
  'Document number': 'رقم المستند',
  'Passport expiry date': 'وقت نفاذ صلاحيه جواز السفر',
  'Visa expiry date': 'تاريخ انتهاء التأشيرة',
  'Please upload <strong>front side</strong> and <strong>back side</strong> Emirates ID photos.':
    'يرجى تحميل صور هوية الإمارات على <strong>الوجه الأمامي</strong> <strong>والخلفي</strong> .',
  'Emirates ID number': 'رقم الهوية الإماراتية',
  'Emirates ID expiry date': 'تاريخ انتهاء الهوية الإماراتية',
  'Residency & contact details': 'تفاصيل الإقامة والاتصال',
  City: 'مدينة',
  Shares: 'تشارك',
  'Legal name': 'الاسم القانوني',
  'Country of incorporation': 'بلد التأسيس',
  'Add Legal entity shareholder': 'إضافة كيان قانوني مساهم',
  'Please share basic details about the company.': 'يرجى مشاركة التفاصيل الأساسية حول الشركة.',
  'Company legal name': 'الاسم القانوني للشركة',
  'Company location': 'موقع الشركة',
  'Edit Legal Entity shareholder': 'تحرير مساهم كيان قانوني',
  'Beneficial owners': 'الملاك المستفيدين',
  'Because shares are held by a Legal entity as disclosed in the above section, please provide the details of Beneficial owners of the entity.':
    'نظرًا لأن كيانًا قانونيًا يحتفظ بالأسهم كما هو موضح في القسم أعلاه ، يرجى تقديم تفاصيل المالكين المستفيدين للكيان.',
  'Add beneficial owner': 'إضافة مالك مستفيد',
  'Select authorised representative as a shareholder': 'حدد الممثل المعتمد كمساهم',
  'Please select person from the list of shareholders to pre populate the data or fill the form to add new person.':
    'يرجى تحديد شخص من قائمة المساهمين لملء البيانات مسبقًا أو ملء النموذج لإضافة شخص جديد.',
  'Please specify ownership share': 'الرجاء تحديد حصة الملكية',
  'Please specify ownership share for this person': 'يرجى تحديد حصة الملكية لهذا الشخص',
  'Additional information': 'معلومة اضافية',
  'In case of complex ownership structure please provide comments and/or schemas that might be relevant.':
    'في حالة وجود هيكل ملكية معقد ، يرجى تقديم التعليقات و / أو المخططات التي قد تكون ذات صلة.',
  'Enter your comments': 'أدخل تعليقاتك',
  'Edit beneficial owner': 'تحرير المالك المستفيد',
  'Authorised Signatory Proof': 'إثبات التوقيع المعتمد',
  // Questionnaire step
  'Company Questionnaire': 'استبيان الشركة',
  'Please share additional information about your business.':
    'يرجى مشاركة معلومات إضافية حول عملك.',
  'Company information': 'معلومات الشركة',
  'Annual turnover / expected annual turnover': 'رقم الأعمال السنوي / حجم الأعمال السنوي المتوقع',
  'Do you hold any position from the below list of categories?':
    'هل تشغل أي منصب من قائمة الفئات أدناه؟',
  'Primary location': 'الموقع الرئيسي',
  Industry: 'صناعة',
  'Trade area': 'منطقة تجارة',
  'Years in business': 'سنوات في الأعمال',
  'Number of employees': 'عدد الموظفين',
  'Market share, %': 'الحصة السوقية، ٪',
  'Number of transactions': 'عدد المعاملات',
  'Expected card sales': 'مبيعات البطاقات المتوقعة',
  'Total value in AED of expected card and digital payments':
    'القيمة الإجمالية بالدرهم الإماراتي للمدفوعات الرقمية والبطاقات المتوقعة',
  '1st year': 'السنة الأولى',
  '2nd year': 'السنة الثانية',
  'Expected non-card sales': 'المبيعات المتوقعة بدون بطاقة',
  'Total value in AED of non card and digital transactions (e.g. cash and cheque)':
    'القيمة الإجمالية بالدرهم الإماراتي للمعاملات غير المصرفية والرقمية (مثل النقد والشيكات)',
  'Total expected sales': 'إجمالي المبيعات المتوقعة',
  'This is the total value in AED of card and non card sales':
    'هذه هي القيمة الإجمالية بالدرهم الإماراتي لمبيعات البطاقات وغير البطاقات',
  'Total expected turnover after two years: <1></1>':
    'إجمالي حجم الأعمال المتوقع بعد عامين: <1> </1>',
  'A natural person who is or has been entrusted with prominent public functions including:':
    'شخص طبيعي كان أو تم تكليفه بوظائف عامة بارزة بما في ذلك:',
  'a head of state, head of government, minister or deputy or assistant minister;':
    'رئيس دولة أو رئيس حكومة أو وزير أو نائب أو مساعد وزير ؛',
  'a senior government official;': 'مسؤول حكومي كبير.',
  'a member of parliament;': 'عضو في البرلمان ؛',
  'a senior politician;': 'سياسي كبير',
  'an important political party official;': 'مسؤول حزب سياسي مهم ؛',
  'a senior judicial official;': 'مسؤول قضائي كبير؛',
  'a member of a court of auditors or the board of a central bank;':
    'عضو في محكمة مراجعي الحسابات أو مجلس إدارة بنك مركزي ؛',
  'an ambassador, charge d’affaires or other high-ranking officer in a diplomatic service;':
    'سفير أو قائم بالأعمال أو غيره من كبار المسؤولين في السلك الدبلوماسي ؛',
  'a high-ranking officer in an armed force;': 'ضابط برتبة عالية في قوة مسلحة.',
  'a senior member of an administrative, management or supervisory body of a state-owned enterprise;':
    'عضو بارز في هيئة إدارية أو تنظيمية أو إشرافية لمؤسسة مملوكة للدولة ؛',
  'Any of the following family members of the above, including:':
    'أي من أفراد الأسرة التالية أسماؤهم ، بما في ذلك:',
  'a spouse;': 'الزوج؛',
  'a partner considered by national law as equivalent to a spouse;':
    'الشريك الذي يعتبره القانون الوطني معادلاً للزوج ؛',
  'a child': 'طفل',
  Yes: 'نعم',
  No: 'لا',
  'Indicate at least one <1>Authorized Representative</1> or <3>Individual Shareholder</3> as a PEP person.':
    'حدد ما لا يقل عن <1> ممثل مفوض </ 1> أو <3> مساهم فردي </ 3> كشخص PEP.',
  'Does your company have any direct/indirect dealings involving a Sanctioned Country* or any Sanctioned Party**?':
    'هل لدى شركتك أي معاملات مباشرة / غير مباشرة تتعلق بدولة خاضعة للعقوبات * أو أي طرف خاضع للعقوبات **؟',
  'Is your company owned by any person/entity that is incorporated/based in, or resident in a Sanctioned Country/Territory?':
    'هل شركتك مملوكة لأي شخص / كيان مؤسس / مقره ، أو مقيم في بلد / إقليم خاضع للعقوبات؟',
  'Does your business have any offices, investment activities or planned activities in any sanctioned country or have an ownership interest in a Sanctioned Party?':
    'هل لشركتك أي مكاتب أو أنشطة استثمارية أو أنشطة مخطط لها في أي بلد خاضع للعقوبات أو لديها مصلحة ملكية في أحد الأطراف الخاضعة للعقوبات؟',
  '*Sanctioned Countries - Iran, Syria, Cuba, North Korea)/Territory (Crimea) and any other countries/territories as updated time to time by the relevant authorities.':
    '* البلدان الخاضعة للعقوبات - إيران وسوريا وكوبا وكوريا الشمالية) / الإقليم (القرم) وأي دول / مناطق أخرى حسب تحديثها من وقت لآخر من قبل السلطات المختصة.',
  '**A sanctioned party includes any person or entity designated for sanction reasons by United Nations Security council, The European Union, The Office of Foreign Assets Control (OFAC), UK HMT and Central Bank of UAE Blacklist.':
    '** يشمل الطرف الخاضع للعقوبات أي شخص أو كيان تم تعيينه لأسباب معاقبة من قبل مجلس الأمن التابع للأمم المتحدة ، والاتحاد الأوروبي ، ومكتب مراقبة الأصول الأجنبية (OFAC) ، و HMT في المملكة المتحدة ، والقائمة السوداء للبنك المركزي لدولة الإمارات العربية المتحدة.',
  'Specify the name of the Sanctioned Countries/Territories:':
    'حدد اسم البلدان / الأقاليم الخاضعة للعقوبات:',
  'Enter text here': 'أدخل النص هنا',
  'Brief description of business activities conducted in the Sanctioned Countries/Territories:':
    'وصف موجز للأنشطة التجارية التي يتم إجراؤها في البلدان / الأقاليم الخاضعة للعقوبات:',
  'What goods/products are involved, what they are used for, the country of origin and destination?':
    'ما هي السلع / المنتجات المعنية ، وما الغرض من استخدامها ، وبلد المنشأ والوجهة؟',
  'Going forward, would you expect the dealings with Sanctioned Countries/Territories to increase/decrease/remain the same?':
    'للمضي قدمًا ، هل تتوقع أن تزداد / تقل / ستظل المعاملات مع البلدان / الأقاليم الخاضعة للعقوبات كما هي؟',
  // Bank account step
  'Bank accounts': 'حسابات بنكية',
  'Account for devices': 'حساب للأجهزة',
  'Please upload scan of bank statement with IBAN number.':
    'يرجى تحميل نسخة من كشف حساب مصرفي برقم IBAN.',
  'Bank name': 'اسم البنك',
  'Account name': 'إسم الحساب',
  'Account number': 'رقم حساب',
  'Account for E-commerce': 'حساب للتجارة الإلكترونية',
  'Use the same account': 'استخدم نفس الحساب',
  // Review & Submit step
  'Review & Submit': 'مراجعة وإرسال',
  'Please select each tab to review the information before submitting the application.':
    'يرجى تحديد كل علامة تبويب لمراجعة المعلومات قبل تقديم الطلب.',
  'To make changes, go to the appropriate section.': 'لإجراء تغييرات ، انتقل إلى القسم المناسب.',
  'Company details': 'تفاصيل الشركة',
  Representatives: 'مندوب',
  Shareholders: 'المساهمين',
  'Business nature': 'طبيعة العمل',
  Email: 'بريد الالكتروني',
  'Phone number': 'رقم الهاتف',
  'Device account IBAN': 'حساب الجهاز IBAN',
  'E-Commerce account IBAN': 'حساب التجارة الإلكترونية IBAN',
  Residency: 'الإقامة',
  'Submitting application': 'تقديم الطلب',
  'Please make sure that you verified the information in the tabs. You will not be able to change the application data after submitting.':
    'يرجى التأكد من التحقق من المعلومات الموجودة في علامات التبويب. لن تتمكن من تغيير بيانات التطبيق بعد التقديم.',
  'Do you want to continue?': 'هل تريد الاستمرار؟',
  'Correct application data': 'بيانات التطبيق الصحيحة',
  'Complete all steps to submit application': 'أكمل جميع الخطوات لتقديم الطلب',
  'Submit application': 'قدم الطلب',
  'Upload data from UAE PASS': 'تحميل البيانات من UAE PASS',
  'Consent on UAE PASS': 'الموافقة على UAE PASS',
  'Since you are authorized representative of this company and login through UAE PASS, your data will be prepopulated from UAE PASS instead of filling this form. Please give consent for uploading the following documents:':
    'نظرًا لأنك ممثل معتمد لهذه الشركة وقمت بتسجيل الدخول من خلال UAE PASS ، فستتم تعبئة بياناتك مسبقًا من UAE PASS بدلاً من ملء هذا النموذج. يرجى الموافقة على تحميل المستندات التالية:',
  Visa: 'تأشيرة',
  'Additionally if you need to add POA as a signatory proof, you can add it to your UAE PASS app by requesting from Ministry of Justice department':
    'بالإضافة إلى ذلك ، إذا كنت بحاجة إلى إضافة توكيل رسمي كدليل موقع ، فيمكنك إضافته إلى تطبيق UAE PASS الخاص بك عن طريق الطلب من وزارة العدل',
  'Import UAE PASS': 'استيراد UAE PASS',
  'Documents uploaded from UAE PASS': 'المستندات التي تم تحميلها من UAE PASS',
  "We couldn't receive your information from UAE PASS, please login again without UAE PASS to enter your personal data manually":
    'لم نتمكن من تلقي معلوماتك من UAE PASS ، يرجى تسجيل الدخول مرة أخرى بدون UAE PASS لإدخال بياناتك الشخصية يدويًا',
  'Invitation sent': 'دعوة التي وجهت',
  'Send invitation': 'إرسال دعوة',
};
