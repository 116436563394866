import React, { FC } from 'react';
import { Modal } from 'antd';

import { Button, Space, Alert } from '~/components';
import { useSetApplicationStage } from '~/controllers/wizard';

interface Props {
  salesforceErrorMessage: string | null;
}

const SalesforceErrorModal: FC<Props> = (props) => {
  const { salesforceErrorMessage } = props;
  const setStageMutation = useSetApplicationStage();
  const onClick = () => {
    setStageMutation.mutate('fillApplication');
  };
  return (
    <Modal visible={!!salesforceErrorMessage} title="Correct data" footer={null} centered>
      <Space direction="vertical" size={24}>
        <Alert message={salesforceErrorMessage} />
        <Button
          id="salesforceErrorModal_goToApplicationButton"
          onClick={onClick}
          loading={setStageMutation.isLoading}
          type="primary"
        >
          Go to application stage
        </Button>
      </Space>
    </Modal>
  );
};

export default SalesforceErrorModal;
