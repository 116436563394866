import React, { FC } from 'react';
import { Tabs as AntdTabs, TabsProps as AntdTabsProps } from 'antd';
import styled, { css } from 'styled-components';
import colors from '~/constants/colors';
import theme from '~/constants/theme';
import { DirectionProp } from '~/types/Direction';
import { useDirection } from '~/hooks/i18nHooks';
import direction from '~/utils/direction';

interface Props extends AntdTabsProps, StyleProps {
  //
}

const Tabs: FC<Props> = (props) => {
  const { sticky, centered, ...tabsProps } = props;
  const dir = useDirection();

  return (
    <Container sticky={sticky} centered={centered} dir={dir}>
      <AntdTabs centered={centered} {...tabsProps} />
    </Container>
  );
};

interface StyleProps {
  sticky?: boolean;
  centered?: boolean;
}

const Container = styled.div<StyleProps & DirectionProp>`
  .ant-tabs-tab-btn {
    *[data-error=${`"true"`}] {
      color: ${colors.errorColor};
    }
    *[data-warning=${`"true"`}] {
      color: ${colors.warningColor};
    }
  }
  .ant-tabs-nav-operations {
    display: none !important; // Prevents issue NANSO-207
  }
  .ant-tabs-tab .anticon {
    ${direction.margin.right(12)};
    ${direction.margin.left(0)};
  }
  @media (max-width: ${theme.breakpoints.md}px) {
    .ant-tabs-tab-btn {
      font-size: 14px;
      * {
        display: inline-flex;
        white-space: break-spaces;
        align-items: center;
      }
    }
    .ant-tabs-tab + .ant-tabs-tab {
      ${direction.margin.left(16)};
      ${direction.margin.right(0)};
    }
    .ant-tabs-tab .anticon {
      ${direction.margin.right(8)};
      ${direction.margin.left(0)};
      font-size: 24px;
    }
  }
  ${({ centered }) =>
    centered
      ? css`
          .ant-tabs-nav-list {
            flex: 1;
            .ant-tabs-tab {
              flex: 1;
              justify-content: center;
            }
          }
        `
      : css``}
  ${({ sticky }) =>
    sticky
      ? css`
          .ant-tabs-nav {
            position: sticky;
            top: 0;
            z-index: 2;
            background-color: ${colors.componentBackground};
          }
        `
      : css``}
`;

export default Tabs;
