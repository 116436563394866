import { useMediaQuery } from 'react-responsive';
import theme from '~/constants/theme';

export const useIsTablet = () => {
  return useMediaQuery({ maxWidth: theme.breakpoints.md });
};

export const useIsMobile = () => {
  return useMediaQuery({ maxWidth: theme.breakpoints.sm });
};
