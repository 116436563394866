import React from 'react';
import { getI18n } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components';
import { NotPinIcon, PinIcon } from '~/img';
import { AgentLead } from '~/types/AgentLead';
import { AgentApplication } from '~/types/AgentApplication';
import colors from '~/constants/colors';
import direction from '~/utils/direction';

function pinCell(
  onClick: (id: string, doPinning: boolean) => any
): ColumnsType<AgentLead>[0] | ColumnsType<AgentApplication>[0] {
  const dir = getI18n().dir();
  return {
    title: undefined,
    dataIndex: ['pinned'],
    key: 'pinned',
    render: (pinned: boolean, record: AgentLead | AgentApplication) => {
      const id =
        'leadId' in record
          ? record?.leadId
          : 'applicationId' in record
          ? record?.applicationId
          : '';
      return (
        <Container
          dir={dir}
          onClick={(e) => {
            onClick(id, !pinned);
            e.stopPropagation();
          }}
        >
          {pinned ? (
            <PinIcon className="agentTablePinIcon" />
          ) : (
            <NotPinIcon dir={dir} className="agentTableNotPinIcon" />
          )}
        </Container>
      );
    },
    width: 30,
  };
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 16px;
  ${direction.border.left(0)}
  ${direction.padding.right(16)}
  cursor: pointer;

  .agentTableNotPinIcon,
  .agentTablePinIcon {
    color: ${colors.textColor};
  }
`;

export default pinCell;
