import { ImageResponse } from '~/types/files';
import axios from './axios';

export const generateContract = () => {
  return axios.get<Blob>('/stages/contract/generate', { responseType: 'blob' }).then((res) => {
    return {
      name:
        res.headers['content-disposition']?.split('; ')?.[1]?.split('filename=')?.[1] ||
        'contract.pdf',
      blob: res.data,
    };
  });
};

export const uploadSignedContract = (data: ImageResponse[]) => {
  return axios.post('/stages/contract/uploadSignedContract', data).then((res) => res.data);
};

export const sendInvitation = (email: string) => {
  return axios.post('/stages/contract/sendInvitation', { email }).then((res) => res.data);
};

export const downloadContractUnderReview = () => {
  return axios.get<Blob>('/stages/submit/download', { responseType: 'blob' }).then((res) => {
    return {
      name:
        res.headers['content-disposition']?.split('; ')?.[1]?.split('filename=')?.[1] ||
        'contract.pdf',
      blob: res.data,
    };
  });
};

export const signWithUaePass = () => {
  return axios.post('/stages/contract/signWithUaePass').then((res) => res.data);
};

export const fetchDocumentFromUaePass = () => {
  return axios.post('/stages/contract/fetchDocumentFromUaePass').then((res) => res.data);
};
