import React, { FC, useMemo } from 'react';
import { Space } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Form, Tabs } from '~/components';
import { BusinessIcon, IndividualsIcon } from '~/img';
import { useGetApplication } from '~/controllers/wizard';
import IndividualTable from './IndividualTable';
import BusinessTable from './BusinessTable';
import BeneficialOwnerTable from './BeneficialOwnerTable';
import BeneficialOwnerAdditionalInformation from './BeneficialOwnerAdditionalInformation';
import { getStagePayload } from '~/utils/getStagePayload';

const ShareTables: FC = () => {
  const { t } = useTranslation('fillApplication');

  const applicationQuery = useGetApplication();
  const stagePayload = getStagePayload.wizard(applicationQuery.data);
  const errorFields = stagePayload?.activeStep.errorInformation?.errorFields;
  const individualHasWarnings = useMemo(() => {
    if (!errorFields) return false;
    return !!errorFields.find((errorObj) =>
      Object.keys(errorObj).find(
        (namePath) =>
          namePath.includes('individuals') || namePath.includes('authorizedRepresentative')
      )
    );
  }, [errorFields]);
  const businessesHasWarnings = useMemo(() => {
    if (!errorFields) return false;
    return !!errorFields.find((errorObj) =>
      Object.keys(errorObj).find(
        (namePath) => namePath.includes('businesses') || namePath.includes('beneficialOwners')
      )
    );
  }, [errorFields]);

  if (!applicationQuery.data) {
    return null;
  }

  return (
    <>
      <StyledTabs defaultActiveKey="first" sticky centered>
        <StyledTabs.TabPane
          tab={
            <span data-warning={individualHasWarnings.toString()}>
              <IndividualsIcon />
              {t('Individual shareholders')}
            </span>
          }
          key="first"
        >
          <div className="ShareTables-TabContent">
            <Form.Item name={['shareholders']} noStyle>
              <IndividualTable />
            </Form.Item>
          </div>
        </StyledTabs.TabPane>
        <StyledTabs.TabPane
          tab={
            <span data-warning={businessesHasWarnings.toString()}>
              <BusinessIcon />
              {t('Legal entity shareholders')}
            </span>
          }
          key="second"
        >
          <div className="ShareTables-TabContent">
            <Space size={56} direction="vertical" style={fullWidth}>
              <Form.Item name={['shareholders']} noStyle>
                <BusinessTable />
              </Form.Item>
              <Form.Item name={['shareholders']} noStyle>
                <BeneficialOwnerTable />
              </Form.Item>
              <BeneficialOwnerAdditionalInformation />
            </Space>
          </div>
        </StyledTabs.TabPane>
      </StyledTabs>
    </>
  );
};

const fullWidth = { width: '100%' };

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 24px;
    z-index: 3;
  }
  .ant-table-empty {
    tbody {
      display: none;
    }
  }
  .ShareTables-TabContent {
    max-width: calc(100vw - ${16 * 2 + 8 * 2}px);
  }
`;

export default ShareTables;
