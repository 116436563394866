import { Typography } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDirection } from '~/hooks/i18nHooks';
import { ArrowIcon } from '~/img';

interface Props {
  longDescription: string;
  aboutServiceUrl: string;
}

const LongDescription: FC<Props> = (props) => {
  const { longDescription, aboutServiceUrl } = props;
  const { t } = useTranslation('fillApplication');
  const dir = useDirection();

  return (
    <StyledParagraph className="LongDescription">
      {longDescription.split('\\n').map((s, sIndex, sArr) => {
        const isLast = sArr.length - 1 === sIndex;
        if (isLast)
          return (
            <React.Fragment key={s}>
              {s}{' '}
              {isLast && (
                <a href={aboutServiceUrl} rel="noreferrer" target="_blank" dir={dir}>
                  {t('Read more')} <ArrowIcon dir={dir} />
                </a>
              )}
            </React.Fragment>
          );
        return <p key={s}>{s}</p>;
      })}
    </StyledParagraph>
  );
};

const StyledParagraph = styled(Typography.Paragraph)`
  margin-bottom: 32px !important;
  a {
    white-space: nowrap;
  }
`;

export default LongDescription;
