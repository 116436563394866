/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC } from 'react';
import { InputNumber } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Form } from '~/components';
import { NamePath } from '~/utils/buildNamePath';
import IncreaseDecreaseButton from './IncreaseDecreaseButton';
import colors from '~/constants/colors';
import theme from '~/constants/theme';
import { DirectionProp } from '~/types/Direction';
import { useDirection } from '~/hooks/i18nHooks';
import { useServicesContext } from '~/controllers/ServicesContext';

interface Props {
  name: NamePath;
  disabled?: boolean;
  hideButtons?: boolean;
  label?: string;
}

const NumberOfDevicesFormItem: FC<Props> = (props) => {
  const { name, disabled, label, hideButtons } = props;
  const { t } = useTranslation();
  const dir = useDirection();
  const servicesContext = useServicesContext();

  const resultDisabled = servicesContext.disableServiceSelection || disabled;

  return (
    <Container disabled={resultDisabled} hideButtons={hideButtons} dir={dir}>
      <label>{label || t('Number of devices')}</label>
      <div className="NumberOfDevicesFormItem-Controls">
        {!hideButtons && (
          <Form.Item name={name} noStyle>
            <IncreaseDecreaseButton type="decrease" min={1} max={50} disabled={resultDisabled} />
          </Form.Item>
        )}
        <Form.Item name={name} noStyle>
          <InputNumber
            className="NumberOfDevicesFormItem-InputNumber"
            size="middle"
            min={1}
            max={50}
            precision={0}
            disabled={resultDisabled}
          />
        </Form.Item>
        {!hideButtons && (
          <Form.Item name={name} noStyle>
            <IncreaseDecreaseButton type="increase" min={1} max={50} disabled={resultDisabled} />
          </Form.Item>
        )}
      </div>
    </Container>
  );
};

const CONTROL_HEIGHT = 32;
const CONTROL_BORDER_RADIUS = 4;

interface UIProps extends DirectionProp {
  disabled: boolean | undefined;
  hideButtons: boolean | undefined;
}

const Container = styled.div<UIProps>`
  display: flex;
  flex-direction: column;
  label {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    margin-bottom: 8px;
    color: ${({ disabled }) => (disabled ? colors.disabledBlueTextColor : undefined)};
  }
  .NumberOfDevicesFormItem-Controls {
    display: flex;
    .FloatLabel {
      display: flex;
    }
    .NumberOfDevicesFormItem-InputNumber {
      margin-left: 8px;
      margin-right: 8px;
      height: ${CONTROL_HEIGHT}px;
      border-radius: ${CONTROL_BORDER_RADIUS}px;
      input {
        height: ${CONTROL_HEIGHT - 2}px;
        font-weight: 500;
      }
      .ant-input-number-handler-wrap {
        display: none;
      }
    }
  }
  @media (max-width: ${theme.breakpoints.sm}px) {
    flex-direction: row;
    justify-content: ${({ hideButtons }) => (hideButtons ? 'flex-start' : 'space-between')};
    align-items: center;
    label {
      margin-bottom: 0;
      margin-right: 8px;
      color: ${colors.textColor};
      font-weight: 400;
    }
    .NumberOfDevicesFormItem-Controls {
      .ant-input-number {
        max-width: 88px;
      }
    }
  }

  &[dir='rtl'] {
    .NumberOfDevicesFormItem-Controls {
      .NumberOfDevicesFormItem-InputNumber {
        input {
          direction: ltr;
          text-align: right;
        }
      }
    }
  }
`;

export default NumberOfDevicesFormItem;
