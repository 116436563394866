import React, { FC, memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useGetLeadById } from '~/controllers/agent';
import { useGetMapItems } from '~/controllers/agentMap';
import { MapItemType } from '~/types/AgentMapItem';
import { openNewApplicationTab } from '~/utils/goToApplication';
import LeadShow from '../agent/leadForm/LeadShow';
import PageHeader from '../agent/PageHeader';
import MapFilters from './filters/MapFilters';
import MapMemorized from './MapMemorized';
import Marker from './Marker';
import { useIsTablet } from '~/hooks/useScreenSizes';
import MapFiltersButton from '~/components/views/agentMap/filters/MapFiltersButton';
import theme from '~/constants/theme';
import { BOTTOM_MENU_HEIGHT } from '~/layouts/AgentMobileMenu';

const AgentMap: FC = () => {
  const mapItemsQuery = useGetMapItems();
  const [leadId, setLeadId] = useState<string | undefined>();
  const { t } = useTranslation('agent');
  const currLeadQuery = useGetLeadById(leadId);

  const handleOpen = (args: { uuid: string; type: MapItemType }) => () => {
    const { uuid, type } = args;
    if (type === MapItemType.APPLICATION) {
      openNewApplicationTab(uuid);
      return;
    }
    if (type === MapItemType.LEAD) {
      setLeadId(uuid);
    }
  };

  const markers = useMemo(() => {
    return mapItemsQuery.data?.map((item) => (
      <Marker
        key={item.uuid}
        record={item}
        lat={item.geolocation.latitude}
        lng={item.geolocation.longitude}
        onClick={handleOpen({
          uuid: item.uuid,
          type: item.type,
        })}
      />
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapItemsQuery.data]);

  const isTablet = useIsTablet();

  return (
    <Container>
      <div className="AgentMap-HeaderBlur" />
      <PageHeader heading={t('map')} isFetching={mapItemsQuery.isFetching}>
        {!isTablet ? <MapFilters /> : <MapFiltersButton />}
      </PageHeader>
      <MapMemorized markers={markers} />
      <LeadShow
        open={!!leadId}
        initialValues={currLeadQuery.data}
        onClose={() => setLeadId(undefined)}
      />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  height: calc(100vh - 77px);
  width: 100vw;
  overflow-x: hidden;
  .AgentMap-HeaderBlur {
    position: absolute;
    pointer-events: none;
    height: 230px;
    width: 100%;
    z-index: 2;
    background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  }
  .PageHeader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    padding: 40px 16px 0 16px;
  }

  @media (max-width: ${theme.breakpoints.sm}px) {
    height: calc(100vh - ${BOTTOM_MENU_HEIGHT}px);
  }
`;

export default memo(AgentMap, () => false);
