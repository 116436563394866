import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Typography } from 'antd';

import { Button, ButtonCard } from '~/components';
import { DownloadIcon, PdfIcon } from '~/img';
import colors from '~/constants/colors';
import theme from '~/constants/theme';
import direction from '~/utils/direction';
import { useDirection } from '~/hooks/i18nHooks';
import { ImageResponse } from '~/types/files';
import { useDownloadFileById } from '~/controllers/file';

interface Props {
  value: ImageResponse;
}

const SaveSignedContract: FC<Props> = ({ value }) => {
  const { originalName, id } = value;

  const { t } = useTranslation('contract');
  const dir = useDirection();

  const { download } = useDownloadFileById();

  const handleSaveClick = async () => {
    await download(id, originalName);
  };

  return (
    <ButtonCard>
      <Container dir={dir}>
        <div className="SaveSignedContract-AboutFileContainer">
          <div className="SaveSignedContract-FileNameContainer">
            <PdfIcon className="SaveSignedContract-Icon" />
            <Typography.Text ellipsis id="SaveSignedContract_fileName">
              {originalName}
            </Typography.Text>
          </div>
        </div>
        <Button
          id="SaveSignedContract_downloadButton"
          onClick={handleSaveClick}
          icon={<DownloadIcon />}
          type="default"
        >
          {t('View contract')}
        </Button>
      </Container>
    </ButtonCard>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  .SaveSignedContract-AboutFileContainer {
    display: flex;
    .SaveSignedContract-FileNameContainer {
      display: flex;
      align-items: center;
      max-width: 377px;
      @media (max-width: ${theme.breakpoints.xl + 32}px) {
        max-width: calc(50vw - 342px);
      }
      @media (max-width: ${theme.breakpoints.lg}px) {
        max-width: calc(70vw - 360px);
      }
      .SaveSignedContract-Icon {
        ${direction.margin.right(8)};
      }
    }
    .SaveSignedContract-FileSize {
      color: ${colors.disabledBlueTextColor};
      margin-right: 8px;
    }
  }
  @media (max-width: ${theme.breakpoints.sm}px) {
    flex-direction: column;
    .SaveSignedContract-AboutFileContainer {
      max-width: calc(100vw - 100px);
      margin-bottom: 16px;
      .SaveSignedContract-FileNameContainer {
        max-width: calc(100vw - 160px);
      }
    }
  }
`;

export default SaveSignedContract;
