import React, { FC, useState } from 'react';
import { Input, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button, DateInput, Form } from '~/components';
import { usePayByCard } from '~/controllers/payment';
import PayByCardFrameDrawer from './PayByCardFrameDrawer';
import { PaymentMethodsImg } from '~/img';
import { useGetApplication } from '~/controllers/wizard';
import InputsGroupLayout from '~/layouts/InputsGroupLayout';
import PayOnlineErrorModal from './PayOnlineErrorModal';

const PayByCard: FC = () => {
  const { t } = useTranslation('payment');
  const [openError, setOpenError] = useState(false);
  const getPaymentFrameLink = usePayByCard({
    onError() {
      setOpenError(true);
    },
  });

  const applicationQuery = useGetApplication();
  const stagePayload =
    applicationQuery?.data?.stage === 'payment' ? applicationQuery?.data?.stagePayload : undefined;
  const alreadyPaid =
    !!stagePayload?.alreadyPaid && stagePayload.paymentDetails?.paymentMethod === 'payByCard';
  const errors = stagePayload?.paymentDetails?.errors || [];

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onClick = async () => {
    try {
      await getPaymentFrameLink.mutateAsync();
      handleOpen();
    } catch (error) {
      //
    }
  };

  const onSuccess = () => {
    void applicationQuery.refetch();
    handleClose();
  };

  if (errors[0] && stagePayload?.paymentDetails?.paymentMethod === 'payByCard') {
    return (
      <InputsGroupLayout>
        <Form.Item
          name={['paymentReference']}
          label={t('Payment reference')}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name={['paymentDate']} label={t('Payment date')} rules={[{ required: true }]}>
          <DateInput />
        </Form.Item>
      </InputsGroupLayout>
    );
  }
  if (alreadyPaid) {
    return (
      <>
        <Typography.Paragraph>{t('Transaction successful.')}</Typography.Paragraph>
        <br />
        <Form.Item
          name={['paymentReference']}
          label={t('Payment reference')}
          rules={[{ required: true }]}
        >
          <Input disabled />
        </Form.Item>
        <InputsGroupLayout>
          <Form.Item name={['paymentDate']} label={t('Payment date')} rules={[{ required: true }]}>
            <DateInput disabled />
          </Form.Item>
        </InputsGroupLayout>
      </>
    );
  }
  return (
    <>
      <PayOnlineErrorModal visible={openError} onClose={() => setOpenError(false)} />
      <PayByCardFrameDrawer
        visible={open}
        onClose={handleClose}
        onSuccess={onSuccess}
        paymentFrameLink={getPaymentFrameLink.data?.paymentFrameLink}
      />
      <Container className="PayByCard">
        {applicationQuery.isRefetching ? (
          <Spin />
        ) : (
          <>
            <div className="PayByCard-PaymentMethodsContainer">
              <Typography.Paragraph>{t('Payment by card is available.')}</Typography.Paragraph>
              <PaymentMethodsImg className="PayByCard-PaymentMethodsImg" />
            </div>
            <Button
              id="payByCardProceedButton"
              size="large"
              className="PayByCard-ProceedButton"
              onClick={onClick}
              danger
              loading={getPaymentFrameLink.isLoading}
            >
              {t('Proceed')}
            </Button>
          </>
        )}
      </Container>
    </>
  );
};

const Container = styled.div`
  .PayByCard-PaymentMethodsContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;

    .PayByCard-PaymentMethodsImg {
      height: 40px;
      margin-left: 16px;
    }
  }

  .PayByCard-ProceedButton {
    width: 100%;
    margin-bottom: 8px;
  }
`;

export default PayByCard;
