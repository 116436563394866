/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-spread */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-cond-assign */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-template */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable func-names */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable prefer-destructuring */

function validation(dependencies) {
  const libphonenumberLib = dependencies.libphonenumberLib;
  const lodashLib = dependencies.lodashLib;
  const temporalLib = dependencies.temporalLib;
  const ibanLib = dependencies.ibanLib;
  const handbook = dependencies.handbook
    ? typeof dependencies.handbook === 'string'
      ? JSON.parse(dependencies.handbook)
      : dependencies.handbook
    : undefined;

  const t =
    dependencies.t ||
    function (value) {
      return value;
    };

  const get = lodashLib.get;
  const assign = lodashLib.assign;
  const find = lodashLib.find;
  const negate = lodashLib.negate;
  const startsWith = lodashLib.startsWith;
  const uniq = lodashLib.uniq;

  const MIN_SHARES = 1;
  const MAX_SHARES = 100;
  const AE_COUNTRY_CODE = '784';
  const NO_ERRORS = {};
  const VALID_SPECIAL_SYMBOLS = [
    '!',
    '#',
    '$',
    '%',
    '&',
    '*',
    '+',
    '-',
    '/',
    '?',
    '^',
    '_',
    '{',
    '|',
    '}',
    ',',
    '-',
    '~',
    '.',
    ' ',
  ];

  /**
   * Return a function
   * that sequentially performs each check (from {@param arguments}) with given payload
   * @param arguments - list of validate functions
   */
  function validate() {
    const fieldValidatorsArr = Array.from(arguments);
    return function (payload) {
      let errors = {};
      fieldValidatorsArr.forEach(function (fieldValidatorFn) {
        const fieldError = fieldValidatorFn(payload);
        errors = assign(errors, fieldError);
      });
      return errors;
    };
  }

  /**
   * Check if all characters in input string contain numbers and letters (only latin by default)
   *
   * @param value string
   * @param options
   * {
   * allowArabic: boolean, - if true, then Arabic letters are accepted;
   * mustIncludesLetters: boolean,- if true, then numeric characters are not accepted;
   * disableFirstLastDotsRule: boolean,- if true, then first and last symbols can be dot;
   * disableFirstLastSpacesRule: boolean, - if true, then first and last symbols can be space;
   * disableConsecutivelyDotsRule: boolean, - if true, then two and more dots can occur consecutively;
   * }
   * all options are false by default;
   * @returns boolean
   * @throws {Error}
   */
  function validateCharacters(value, options) {
    const o = options || {};
    const allowArabic = !!o.allowArabic;
    const mustIncludesLetters = !!o.mustIncludesLetters;
    const disableFirstLastDotsRule = !!o.disableFirstLastDotsRule;
    const disableFirstLastSpacesRule = !!o.disableFirstLastSpacesRule;
    const disableConsecutivelyDotsRule = !!o.disableConsecutivelyDotsRule;

    let haveLetters = false;
    for (let i = 0; i < value.length; i++) {
      const isFirst = i === 0;
      const isLast = i === value.length - 1;
      const currentChar = value[i];
      const prevChar = isFirst ? undefined : value[i - 1];
      if (!disableFirstLastDotsRule) {
        if ((isFirst && currentChar === '.') || (isLast && currentChar === '.')) {
          throw new Error(t("Character '.' (dot) should not be first or last character"));
        }
      }
      if (!disableFirstLastSpacesRule) {
        if ((isFirst && currentChar === ' ') || (isLast && currentChar === ' ')) {
          throw new Error(t("Character ' ' (space) should not be first or last character"));
        }
      }
      if (!disableConsecutivelyDotsRule) {
        if (prevChar === '.' && currentChar === '.') {
          throw new Error(
            t("Character '.' (dot) should not appear two or more times consecutively")
          );
        }
      }
      const code = currentChar.charCodeAt(0);
      if (code >= 1536 && code <= 1791 && !allowArabic) {
        throw new Error(t('Must include only latin letters'));
      }
      if (
        !(code > 47 && code < 58) && // numeric (0-9)
        !(code > 64 && code < 91) && // upper alpha (A-Z)
        !(code > 96 && code < 123) && // lower alpha (a-z)
        !(code >= 1536 && code <= 1791 && allowArabic) && // arabic alpha
        !VALID_SPECIAL_SYMBOLS.includes(currentChar)
      ) {
        throw new Error(t('Includes forbidden symbols'));
      }
      // numeric (0-9)
      if (mustIncludesLetters) {
        if (!(code > 47 && code < 58) && !haveLetters) {
          haveLetters = true;
        }
      }
    }

    if (mustIncludesLetters && !haveLetters) {
      throw new Error(t('Must includes letters'));
    }

    return true;
  }

  /**
   * Check if the length of the input string is less than 255 characters
   * and perform checks from {@link validateCharacters}
   *
   * @param value string
   * @param options are used in {@link validateCharacters}
   * {
   * allowArabic: boolean, - if true, then Arabic letters are accepted;
   * mustIncludesLetters: boolean,- if true, then numeric characters are not accepted;
   * disableFirstLastDotsRule: boolean,- if true, then first and last symbols can be dot;
   * disableFirstLastSpacesRule: boolean, - if true, then first and last symbols can be space;
   * disableConsecutivelyDotsRule: boolean, - if true, then two and more dots can occur consecutively;
   * }
   * all options are false by default;
   * @returns boolean
   * @throws {Error}
   */
  function commonValidation(value, options) {
    if (typeof value === 'string') {
      if (value.length > 255) {
        throw new Error(t('Max length is 255 characters'));
      }
      validateCharacters(value, options);
    }
    return true;
  }

  /**
   * Check if value located by namePath is optional and
   * continues validation of input value using the returned function
   *
   * @param namePath: string - field name
   * @param withCommonValidation: boolean - use {@link commonValidation}; true by default
   * @returns function
   */
  function optional(namePath, withCommonValidation) {
    withCommonValidation = typeof withCommonValidation === 'boolean' ? withCommonValidation : true;

    return function () {
      const curriedValidationFns = Array.from(arguments);
      return function (payload) {
        const value = get(payload, namePath);
        if (
          value === undefined ||
          value === null ||
          value === '' ||
          (Array.isArray(value) && value.length === 0)
        ) {
          return NO_ERRORS;
        }
        try {
          if (withCommonValidation) {
            commonValidation(value);
          }
          curriedValidationFns.forEach(function (curriedFn) {
            curriedFn(value);
          });
        } catch (error) {
          const resultError = {};
          resultError[namePath] = error.message;
          return resultError;
        }
        return NO_ERRORS;
      };
    };
  }

  /**
   * Check if value located by namePath is required and
   * continues validation of input value using the returned function
   *
   * @param namePath: string - field name
   * @param withCommonValidation: boolean - use {@link commonValidation}; true by default
   * @returns function
   */
  function required(namePath, withCommonValidation) {
    withCommonValidation = typeof withCommonValidation === 'boolean' ? withCommonValidation : true;

    return function () {
      const curriedValidationFns = Array.from(arguments);
      return function (payload) {
        const value = get(payload, namePath);
        if (
          value === undefined ||
          value === null ||
          value === '' ||
          (Array.isArray(value) && value.length === 0)
        ) {
          const resultError = {};
          resultError[namePath] = m.required();
          return resultError;
        }
        try {
          if (withCommonValidation) {
            commonValidation(value);
          }
          curriedValidationFns.forEach(function (curriedFn) {
            curriedFn(value);
          });
        } catch (error) {
          const resultError = {};
          resultError[namePath] = error.message;
          return resultError;
        }
        return NO_ERRORS;
      };
    };
  }

  /**
   * Check if all values of input arguments are true and only then
   * continues validation of input value using the returned function
   *
   * @param arguments: ...boolean[] - list of conditions
   * @returns function
   */
  function condition() {
    const conditionFns = Array.from(arguments);
    return function () {
      const curriedValidationFnsArgs = Array.from(arguments);
      return function (payload) {
        let allConditionsTrue = true;
        conditionFns.forEach(function (conditionFn) {
          if (allConditionsTrue && !conditionFn(payload)) {
            allConditionsTrue = false;
          }
        });
        if (allConditionsTrue) {
          const errors = validate.apply({}, curriedValidationFnsArgs)(payload);
          return errors;
        }
        return NO_ERRORS;
      };
    };
  }

  const utils = {
    /**
     * Check if input string satisfies the Luhn algorithm
     * https://en.wikipedia.org/wiki/Luhn_algorithm
     *
     * @param str
     * @returns {boolean}
     */
    luhnAlgorithm: function (str) {
      const value = str.replace(/\D/g, '');

      let nCheck = 0;
      let bEven = false;

      for (let n = value.length - 1; n >= 0; n--) {
        let nDigit = parseInt(value.charAt(n), 10);

        if (bEven && (nDigit *= 2) > 9) {
          nDigit -= 9;
        }

        nCheck += nDigit;
        bEven = !bEven;
      }

      return nCheck % 10 === 0;
    },
    /**
     * Return total shares and shareholders
     * of authorized representative, businesses and individuals
     *
     * @param payload
     * @returns {{shares: number, shareholders: number}}
     */
    getTotalShares: function (payload) {
      const total = {
        shares: 0,
        shareholders: 0,
      };
      (get(payload, 'shareholders.authorizedRepresentative') || []).forEach(function (_, _index) {
        const name = 'shareholders.authorizedRepresentative.' + _index;
        if (get(payload, name + '.shares')) {
          total.shares += get(payload, name + '.shares');
          total.shareholders += 1;
        }
      });
      (get(payload, 'shareholders.individuals') || []).forEach(function (_, _index) {
        const name = 'shareholders.individuals.' + _index;
        if (get(payload, name + '.shares')) {
          total.shares += get(payload, name + '.shares');
          total.shareholders += 1;
        }
      });
      (get(payload, 'shareholders.businesses') || []).forEach(function (_, _index) {
        const name = 'shareholders.businesses.' + _index;
        if (get(payload, name + '.shares')) {
          total.shares += get(payload, name + '.shares');
          total.shareholders += 1;
        }
      });

      return total;
    },
    /**
     * Return total shares and shareholders of beneficial owners
     *
     * @param payload
     * @returns {{shares: number, shareholders: number}}
     */
    getTotalBeneficialOwnersShares: function (payload) {
      const total = {
        shares: 0,
        shareholders: 0,
      };
      (get(payload, 'shareholders.beneficialOwners') || []).forEach(function (_, _index) {
        const name = 'shareholders.beneficialOwners.' + _index;
        if (get(payload, name + '.shares')) {
          total.shares += get(payload, name + '.shares');
          total.shareholders += 1;
        }
      });
      return total;
    },
  };

  /**
   * Condition functions
   */
  const c = {
    /**
     * Return strict equal function
     *
     * @param path - field name
     * @param equalTo - value
     * @returns {function(*): boolean}
     */
    strictEqual: function (path, equalTo) {
      return function (payload) {
        return get(payload, path) === equalTo;
      };
    },
    /**
     * Return strict not equal function
     *
     * @param path - field name
     * @param equalTo - value
     * @returns {function(*): boolean}
     */
    strictNotEqual: function (path, equalTo) {
      return function (payload) {
        return get(payload, path) !== equalTo;
      };
    },
    /**
     * Return equal function where values are cast to boolean
     *
     * @param path
     * @param equalTo
     * @returns {function(*): boolean}
     */
    equal: function (path, equalTo) {
      return function (payload) {
        return Boolean(get(payload, path)) === Boolean(equalTo);
      };
    },
    /**
     * Return function that checks every argument with {@link strictEqual}
     *
     * @param arguments: ...[path, equalTo][]
     * @returns {function(*): boolean}
     */
    strictEqualSome: function () {
      const pairs = Array.from(arguments); // [path, equalTo][]
      return function (payload) {
        let hasEqualValues = false;
        pairs.forEach(function (item) {
          const path = item[0];
          const equalTo = item[1];
          if (!hasEqualValues && c.strictEqual(path, equalTo)(payload)) {
            hasEqualValues = true;
          }
        });
        return hasEqualValues;
      };
    },
    /**
     * Return function that checks every argument with {@link equal}
     *
     * @param arguments: ...[path, equalTo][]
     * @returns {function(*): boolean}
     */
    equalSome: function () {
      const pairs = Array.from(arguments); // [path, equalTo][]
      return function (payload) {
        let hasEqualValues = false;
        pairs.forEach(function (item) {
          const path = item[0];
          const equalTo = item[1];
          if (!hasEqualValues && c.equal(path, equalTo)(payload)) {
            hasEqualValues = true;
          }
        });
        return hasEqualValues;
      };
    },
    /**
     * Return function that checks that one of roles is agent
     *
     * @param roles
     * @returns {function(): *}
     */
    isAgent: function (roles) {
      return function () {
        return roles.includes('agent');
      };
    },
    /**
     * Return function that checks that one of roles is team-lead
     *
     * @param roles
     * @returns {function(): *}
     */
    isTeamLead: function (roles) {
      return function () {
        return roles.includes('team-lead');
      };
    },
  };

  /**
   * Validation error messages functions
   */
  const m = {
    required: function () {
      return t('Required');
    },
    requiredAtLeastOne: function () {
      return t('Required at least 1');
    },
    phoneImpossible: function () {
      return t('Impossible number');
    },
    phoneInvalid: function () {
      return t('Invalid number');
    },
    emailInvalid: function () {
      return t('Invalid email');
    },
    notNumber: function () {
      return t('Must contain digits only');
    },
    notString: function () {
      return t('Must be a string');
    },
    mustHave18yo: function () {
      return t('Person must be older 18 years old');
    },
    daysBefore: function (days) {
      return t('Must be {{days}} days before', { days: days });
    },
    daysAfter: function (days) {
      return t('Must be {{days}} days after', { days: days });
    },
    urlInvalid: function () {
      return t('Must be like https://example.com');
    },
    minLength: function (len) {
      return len === 1
        ? t('Must be at least {{count}} character', { count: len })
        : t('Must be at least {{count}} characters', { count: len });
    },
    maxLength: function (len) {
      return len === 1
        ? t('Max length is {{count}} character', { count: len })
        : t('Max length is {{count}} characters', { count: len });
    },
    equalLength: function (len) {
      return len === 1
        ? t('Must be {{count}} character', { count: len })
        : t('Must be {{count}} characters', { count: len });
    },
    min: function (minNumber) {
      return t('Min is {{minNumber}}', { minNumber: minNumber });
    },
    max: function (maxNumber) {
      return t('Max is {{maxNumber}}', { maxNumber: maxNumber });
    },
    more: function (num) {
      return t('Must be more than {{num}}', { num: num });
    },
    less: function (num) {
      return t('Must be less than {{num}}', { num: num });
    },
    totalShares: function (totalShares) {
      return t('Total shares is {{totalShares}}', { totalShares: totalShares });
    },
    ibanInvalid: function () {
      return t('Invalid IBAN');
    },
    notHandbookValue: function () {
      return t('This value is not a handbook value');
    },
  };

  /**
   * Field validation functions
   */
  const v = {
    string: {
      /**
       * Check if value is a string
       * @param value
       * @returns {boolean}
       * @throws {Error}
       */
      isString: function (value) {
        if (typeof value !== 'string') {
          throw new Error(m.notString());
        }
        return true;
      },
      /**
       * Check if value consists only of digits
       * @param value
       * @returns {boolean}
       * @throws {Error}
       */
      digitsOnly: function (value) {
        v.string.isString(value);
        if (!/^\d+$/.test(value)) {
          throw new Error(m.notNumber());
        }
        return true;
      },
      /**
       * Return a function that checks if value consists of letters only
       * @param options: {
       *   allowArabic: boolean; - if true, then Arabic letters are accepted;
       *   withInnerSpaces: boolean; - if true, then inner spaces are accepted;
       * }
       * all options are false by default;
       * @returns {function(*): boolean}
       */
      textOnly: function (options) {
        const o = options || {};
        const allowArabic = !!o.allowArabic;
        const withInnerSpaces = !!o.withInnerSpaces;

        return function (value) {
          for (let i = 0; i < value.length; i++) {
            const currentChar = value[i];
            const code = currentChar.charCodeAt(0);

            if (withInnerSpaces && currentChar === ' ') {
              const isFirst = i === 0;
              const isLast = i === value.length - 1;
              if (isFirst || isLast) {
                throw new Error(t("Character ' ' (space) should not be first or last character"));
              }
              const prevChar = isFirst ? undefined : value[i - 1];
              if (prevChar === ' ') {
                throw new Error(
                  t("Character ' ' (space) should not appear two or more times consecutively")
                );
              }
            } else if (code >= 1536 && code <= 1791 && !allowArabic) {
              throw new Error(t('Must include only latin letters'));
            } else if (
              !(code > 64 && code < 91) && // upper alpha (A-Z)
              !(code > 96 && code < 123) && // lower alpha (a-z)
              !(code >= 1536 && code <= 1791 && allowArabic) // arabic alpha
            ) {
              throw new Error(t('Must includes only letters'));
            }
          }
          return true;
        };
      },
      /**
       * Return a function that checks
       * if length of value is greater than or equal to {@param len}
       *
       * @param len
       * @returns {function(*): boolean}
       */
      minLength: function (len) {
        return function (value) {
          v.string.isString(value);
          if (!(value.length >= len)) {
            throw new Error(m.minLength(len));
          }
          return true;
        };
      },
      /**
       * Return a function that checks
       * if length of value is less than or equal to {@param len}
       *
       * @param len
       * @returns {function(*): boolean}
       */
      maxLength: function (len) {
        return function (value) {
          v.string.isString(value);
          if (!(value.length <= len)) {
            throw new Error(m.maxLength(len));
          }
          return true;
        };
      },
      /**
       * Return a function that checks
       * if length of value is equal to {@param len}
       *
       * @param len
       * @returns {function(*): boolean}
       */
      equalLength: function (len) {
        return function (value) {
          v.string.isString(value);
          if (value.length !== len) {
            throw new Error(m.equalLength(len));
          }
          return true;
        };
      },
    },
    number: {
      /**
       * Check if value is a number
       * @param value
       * @returns {boolean}
       * @throws {Error}
       */
      isNumber: function (value) {
        if (typeof value !== 'number') {
          throw new Error(m.notNumber());
        }
        return true;
      },
      /**
       * Return a function that checks
       * if value is greater than or equal to {@param minValue}
       *
       * @param minValue
       * @returns {function(*): boolean}
       */
      min: function (minValue) {
        return function (value) {
          v.number.isNumber(value);
          if (value < minValue) {
            throw new Error(m.min(minValue));
          }
          return true;
        };
      },
      /**
       * Return a function that checks
       * if value is less than or equal to {@param maxValue}
       *
       * @param maxValue
       * @returns {function(*): boolean}
       */
      max: function (maxValue) {
        return function (value) {
          v.number.isNumber(value);
          if (value > maxValue) {
            throw new Error(m.max(maxValue));
          }
          return true;
        };
      },
      /**
       * Return a function that checks
       * if length of value is greater than or equal to {@param len}
       *
       * @param len
       * @returns {function(*): boolean}
       */
      minLength: function (len) {
        return function (numValue) {
          v.number.isNumber(numValue);
          const value = numValue.toString();
          return v.string.minLength(len)(value);
        };
      },
      /**
       * Return a function that checks
       * if length of value is less than or equal to {@param len}
       *
       * @param len
       * @returns {function(*): boolean}
       */
      maxLength: function (len) {
        return function (numValue) {
          v.number.isNumber(numValue);
          const value = numValue.toString();
          return v.string.maxLength(len)(value);
        };
      },
    },
    /**
     * Check if value is possible and valid phone number
     *
     * @param phoneNumber
     * @returns {boolean}
     * @throws {Error}
     */
    phone: function (phoneNumber) {
      v.string.isString(phoneNumber);
      try {
        if (!libphonenumberLib.isPossiblePhoneNumber(phoneNumber)) {
          throw new Error(m.phoneImpossible());
        }
        if (!libphonenumberLib.isValidPhoneNumber(phoneNumber)) {
          throw new Error(m.phoneInvalid());
        }
      } catch (error) {
        throw new Error(error.message);
      }
      return true;
    },
    /**
     * Check if value is possible and valid email
     *
     * @param email
     * @returns {boolean}
     */
    email: function (email) {
      function isEmailValid(value) {
        const emailRegEx =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailRegEx.test(String(value).toLowerCase());
      }
      v.string.isString(email);
      if (!isEmailValid(email)) {
        throw new Error(m.emailInvalid());
      }
      v.string.minLength(6)(email);
      v.string.maxLength(40)(email);
      return true;
    },
    /**
     * Check if value is valid post office box
     * @param poBox
     * @returns {boolean}
     */
    poBox: function (poBox) {
      v.string.digitsOnly(poBox);
      return v.string.maxLength(10)(poBox);
    },
    /**
     * Check if value is valid Emirates Id
     * https://en.wikipedia.org/wiki/Emirates_ID
     *
     * @param formattedEmiratesId
     * @returns {boolean}
     */
    emiratesId: function (formattedEmiratesId) {
      // formattedEmiratesId is like 784-1998-1234567-8
      const emiratesId = formattedEmiratesId.split('-').join('');
      v.string.digitsOnly(emiratesId);
      if (!emiratesId.startsWith(AE_COUNTRY_CODE)) {
        throw new Error(t('Must starts with {{countryCode}}', { countryCode: AE_COUNTRY_CODE }));
      }
      v.string.equalLength(15)(emiratesId);
      if (!utils.luhnAlgorithm(emiratesId)) {
        throw new Error(t('Impossible Emirates ID. Check for typos carefully'));
      }
      return true;
    },
    /**
     * Return a function that check if value is valid url with https protocol
     * @param httpsOnly
     * @returns {function(*): boolean}
     */
    url: function (httpsOnly) {
      return function (string) {
        v.string.isString(string);
        v.string.maxLength(150);
        const pattern = new RegExp(
          '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', // fragment locator
          'i'
        );
        if ((httpsOnly === undefined ? true : httpsOnly) && !startsWith(string, 'https')) {
          throw new Error(m.urlInvalid());
        }
        if (!pattern.test(string)) {
          throw new Error(m.urlInvalid());
        }
        return true;
      };
    },
    date: {
      /**
       * Check if value represents a date that is at least 18 years behind the current one
       * @param date
       * @returns {boolean}
       */
      has18yo: function (date) {
        v.string.isString(date);
        const birthDay = temporalLib(date);
        const nYearsAgo = temporalLib().subtract(18, 'years');
        if (!birthDay.isBefore(nYearsAgo)) {
          throw new Error(m.mustHave18yo());
        }
        return true;
      },
      /**
       * Return a function that check if the date is {@param days} less than today
       * @param days
       * @returns {function(*): boolean}
       */
      daysBefore: function (days) {
        return function (date) {
          v.string.isString(date);
          const dateObj = temporalLib(date);
          const nDaysAgo = temporalLib().subtract(days, 'days');
          if (!dateObj.isBefore(nDaysAgo)) {
            throw new Error(m.daysBefore(days));
          }
          return true;
        };
      },
      /**
       * Return a function that check if the date is {@param days} greater than today
       * @param days
       * @returns {function(*): boolean}
       */
      daysAfter: function (days) {
        return function (date) {
          v.string.isString(date);
          const dateObj = temporalLib(date);
          const nDaysAfter = temporalLib().add(days, 'days');
          if (!dateObj.isAfter(nDaysAfter)) {
            throw new Error(m.daysAfter(days));
          }
          return true;
        };
      },
      /**
       * Check if date is 3 days less than today
       * @param date
       * @returns {boolean}
       */
      defaultBefore: function (date) {
        return v.date.daysBefore(3)(date);
      },
      /**
       * Check if date is 3 days greater than today
       * @param date
       * @returns {boolean}
       */
      defaultAfter: function (date) {
        return v.date.daysAfter(3)(date);
      },
    },
    /**
     * Return a function that checks if value is equalTo {@param equalTo}
     * @param equalTo
     * @param errorMessage
     * @returns {function(*): boolean}
     */
    mustEqual: function (equalTo, errorMessage) {
      return function (value) {
        if (value !== equalTo) {
          throw new Error(errorMessage);
        }
        return true;
      };
    },
    /**
     * Check if value is valid IBAN
     * https://en.wikipedia.org/wiki/International_Bank_Account_Number
     * @param data
     * @returns {boolean}
     */
    iban: function (data) {
      v.string.isString(data);
      if (data.split('').includes(' ')) {
        throw new Error(t("Character ' ' (space) is forbidden"));
      }
      if (!/^[A-Z0-9]+$/.test(data)) {
        throw new Error(t('Should contain only letters (A-Z) and digits (0-9)'));
      }
      if (!ibanLib.isValid(data)) {
        throw new Error(m.ibanInvalid());
      }
      return true;
    },
    /**
     * Check if value is valid first name
     * It must contain <= 40 symbols, consists only os letters and may have inner spaces
     * @param value
     */
    firstName: function (value) {
      v.string.maxLength(40)(value);
      v.string.textOnly({ withInnerSpaces: true })(value);
    },
    /**
     * Check if value is valid last name
     * It must contain <= 60 symbols, consists only os letters and may have inner spaces
     * @param value
     */
    lastName: function (value) {
      v.string.maxLength(60)(value);
      v.string.textOnly({ withInnerSpaces: true })(value);
    },
    /**
     * Check if value is valid legal name
     * It must contain <= 100 symbols, consists only os letters
     * @param value
     */
    legalName: function (value) {
      v.string.maxLength(100)(value);
      validateCharacters(value, { mustIncludesLetters: true });
    },
    /**
     * Check if value is valid address line
     * @param value
     */
    addressLine: function (value) {
      v.string.maxLength(100)(value);
      validateCharacters(value, {
        disableFirstLastDotsRule: true,
        disableFirstLastSpacesRule: true,
      });
    },
    /**
     * Check if value is valid license number
     * It must contain only digits and have <= 50 symbols
     * @param value
     */
    licenseNumber: function (value) {
      v.string.digitsOnly(value);
      v.string.maxLength(50)(value);
    },
    /**
     * Check if value is valid account number
     * @param value
     */
    accountNumber: function (value) {
      v.string.digitsOnly(value);
      v.string.maxLength(16)(value);
    },
    /**
     * Check if value is valid passport number
     * @param value
     */
    passportNumber: function (value) {
      v.string.maxLength(18)(value);

      for (let i = 0; i < value.length; i++) {
        const currentChar = value[i];
        const code = currentChar.charCodeAt(0);
        if (
          !(code > 47 && code < 58) && // numeric (0-9)
          !(code > 64 && code < 91) // upper alpha (A-Z)
        ) {
          throw new Error(t('A-Z (hyphen) and 0-9 symbols are allowed only'));
        }
      }
    },
    handbook: {
      /**
       * Check if handbook is loaded
       */
      isLoaded: function () {
        if (!handbook) throw new Error('No handbook found');
      },
      /**
       * Return a function that checks if value is from handbook
       * and corresponds to proper company type
       * @param roles
       * @returns function
       */
      companyType: function (roles) {
        return function (value) {
          v.handbook.isLoaded();
          const companyType = find(handbook, function (item) {
            return item.key === 'companyType';
          });
          const hbValue = find(companyType.values, function (item) {
            return item.apiValue === value;
          });
          const isRoleCorrect = hbValue.additionalParams
            ? hbValue.additionalParams.onlyForAgent === undefined
              ? true
              : c.isAgent(roles)() && hbValue.additionalParams.onlyForAgent === 'true'
            : true;
          if (!hbValue || !isRoleCorrect) throw new Error(m.notHandbookValue());
        };
      },
      /**
       * Check if value is from handbook and corresponds to proper business nature
       * @param value
       */
      businessNature: function (value) {
        v.handbook.isLoaded();
        const businessNature = find(handbook, function (item) {
          return item.key === 'businessNature';
        });
        const isHbValue = find(businessNature.values, function (item) {
          return item.apiValue === value;
        });
        if (!isHbValue) throw new Error(m.notHandbookValue());
      },
      /**
       * Check if value is from handbook and corresponds to proper business industry
       * @param businessNatureValue
       * @returns function
       */
      businessIndustry: function (businessNatureValue) {
        return function (value) {
          v.handbook.isLoaded();
          const businessNature = find(handbook, function (item) {
            return item.key === 'businessNature';
          });
          const businessNatureHandbookItem = find(businessNature.values, function (item) {
            return item.apiValue === businessNatureValue;
          });
          const businessIndustry = find(businessNatureHandbookItem.values, function (item) {
            return item.key.includes('businessLine');
          });
          const isHbValue = find(businessIndustry.values, function (item) {
            return item.apiValue === value;
          });
          if (!isHbValue) throw new Error(m.notHandbookValue());
        };
      },
      /**
       * Return a function that checks
       * if value corresponds to proper business nature and business industry from handbook
       * @param businessNatureValue
       * @param businessIndustryValue
       * @returns function
       */
      mcc: function (businessNatureValue, businessIndustryValue) {
        return function (value) {
          v.handbook.isLoaded();
          const businessNature = find(handbook, function (item) {
            return item.key === 'businessNature';
          });
          const businessNatureHandbookItem = find(businessNature.values, function (item) {
            return item.apiValue === businessNatureValue;
          });
          const businessIndustry = find(businessNatureHandbookItem.values, function (item) {
            return item.key.includes('businessLine');
          });
          const businessIndustryHandbookItem = find(businessIndustry.values, function (item) {
            return item.apiValue === businessIndustryValue;
          });
          const isHbValue = businessIndustryHandbookItem.additionalParams.mcc === value;
          if (!isHbValue) throw new Error(m.notHandbookValue());
        };
      },
    },
  };

  const powerOfAttorney =
    'Upload a copy of the Power of Attorney showing this person as an authorised signatory';

  /**
   * Check if all authorized representative have unique Emirates ID
   * @param payload
   */
  function validateAuthorizedRepresentativeEmiratesId(payload) {
    const errors = {};
    const authorizedRepresentative = get(payload, 'authorizedRepresentative');

    function documentNumberPath(index) {
      return ['authorizedRepresentative', index, 'emiratesId', 'documentNumber'].join('.');
    }

    const emiratesIds =
      payload &&
      Array.isArray(authorizedRepresentative) &&
      authorizedRepresentative
        .map(function (item) {
          return get(item, 'emiratesId.documentNumber');
        })
        .filter(function (item) {
          return !!item;
        });

    const uniqEmiratesIds = uniq(emiratesIds);

    if (emiratesIds.length > 1 && emiratesIds.length !== uniqEmiratesIds.length) {
      payload &&
        Array.isArray(authorizedRepresentative) &&
        authorizedRepresentative.forEach(function (_item, _index) {
          errors[documentNumberPath(_index)] = t('Must be unique. Check another representative');
        });
    }
    return errors;
  }

  /**
   * Check if all shareholders have unique Emirates ID
   * @param payload
   */
  function validatePersonShareholdersEmiratesId(payload) {
    const errors = {};

    const authorizedRepresentative = get(payload, 'authorizedRepresentative') || [];
    const authorizedRepresentativeShareholdersRecords =
      get(payload, 'shareholders.authorizedRepresentative') || [];
    const authorizedRepresentativeShareholders = authorizedRepresentativeShareholdersRecords.map(
      function (item) {
        return find(authorizedRepresentative, function (person) {
          return person.uid === item.uid;
        });
      }
    );
    const authorizedRepresentativeShareholdersEmiratesIds =
      authorizedRepresentativeShareholders.map(function (person) {
        return get(person, 'emiratesId.documentNumber');
      });

    const individualShareholders = get(payload, 'shareholders.individuals') || [];
    const individualShareholdersIds = individualShareholders.map(function (person) {
      return get(person, 'data.emiratesId.documentNumber') || '';
    });

    const emiratesIds = authorizedRepresentativeShareholdersEmiratesIds
      .concat(individualShareholdersIds)
      .filter(function (item) {
        return !!item;
      });
    const uniqEmiratesIds = uniq(emiratesIds);

    function documentNumberPath(index) {
      return ['shareholders', 'individuals', index, 'data', 'emiratesId', 'documentNumber'].join(
        '.'
      );
    }

    if (emiratesIds.length > 1 && emiratesIds.length !== uniqEmiratesIds.length) {
      payload &&
        Array.isArray(individualShareholders) &&
        individualShareholders.forEach(function (_item, _index) {
          errors[documentNumberPath(_index)] = t('Must be unique. Check another shareholder');
        });
    }

    return errors;
  }

  /**
   * Check if information about Emirates Id is presented (only if user has UAE nationality)
   * @param n
   */
  function emiratesIdRequiredWithCondition(n) {
    return condition(c.strictNotEqual(n('nationality'), 'UAE'))(
      required(n('emiratesId.files'))(),
      required(n('emiratesId.expiryDate'))(),
      required(n('emiratesId.documentNumber'))()
    );
  }

  /**
   * Check if information about Visa is presented (only if user has UAE nationality)
   * @param n
   */
  function visaRequiredWithCondition(n) {
    return condition(c.strictNotEqual(n('nationality'), 'UAE'))(
      required(n('visa.files'))(),
      required(n('visa.expiryDate'))()
    );
  }

  /**
   * Check if all information about person is presented and valid
   * @param data
   * @param name
   */
  function validatePerson(data, name) {
    function n(path) {
      return [name, path].join('.');
    }
    return validate(
      required(n('uid'), false)(),
      required(n('firstName'), false)(v.firstName),
      required(n('lastName'), false)(v.lastName),
      required(n('birthDate'))(v.date.has18yo),
      required(n('nationality'), false)(),
      emiratesIdRequiredWithCondition(n),
      optional(n('emiratesId.files'))(),
      optional(n('emiratesId.expiryDate'))(v.date.defaultAfter),
      optional(n('emiratesId.documentNumber'))(v.emiratesId),
      required(n('passport.files'))(),
      required(n('passport.documentNumber'))(v.passportNumber),
      required(n('passport.expiryDate'))(v.date.defaultAfter),
      visaRequiredWithCondition(n),
      optional(n('visa.files'))(),
      optional(n('visa.expiryDate'))(v.date.defaultAfter),
      required(n('country'), false)(),
      required(n('city'))(),
      required(n('addressLine'), false)(v.addressLine),
      required(n('poBox'))(v.poBox),
      required(n('email'), false)(v.email),
      required(n('phoneNumber'))(v.phone),
      condition(c.equal(n('isAuthorizedSignatory'), true))(
        required(n('proof.designation'))(v.string.maxLength(128)),
        required(n('proof.documentType'), false)()
      ),
      condition(
        c.equal(n('isAuthorizedSignatory'), true),
        c.strictEqual(n('proof.documentType', false), powerOfAttorney)
      )(required(n('proof.files'))())
    )(data);
  }

  /**
   * Check if all information about shareholder is presented and valid
   * @param data
   * @param name
   */
  function validateShareholderIndividual(data, name) {
    function n(path) {
      return [name, path].join('.');
    }
    return validate(
      required(n('uid'))(),
      required(n('firstName'), false)(v.firstName),
      required(n('lastName'), false)(v.lastName),
      required(n('birthDate'))(v.date.has18yo),
      required(n('nationality'), false)(),
      emiratesIdRequiredWithCondition(n),
      optional(n('emiratesId.files'))(),
      optional(n('emiratesId.expiryDate'))(v.date.defaultAfter),
      optional(n('emiratesId.documentNumber'))(v.emiratesId),
      required(n('passport.files'))(),
      required(n('passport.documentNumber'))(v.passportNumber),
      required(n('passport.expiryDate'))(v.date.defaultAfter),
      visaRequiredWithCondition(n),
      optional(n('visa.files'))(),
      optional(n('visa.expiryDate'))(v.date.defaultAfter),
      required(n('country'), false)(),
      required(n('city'))(),
      required(n('addressLine'), false)(v.addressLine),
      required(n('poBox'))(v.poBox),
      // optional(n('email'), false)(v.email), // not represented in the form
      required(n('phoneNumber'))(v.phone)
    )(data);
  }

  /**
   * Check if all information about beneficial owner is presented and valid
   * @param data
   * @param name
   */
  function validateBeneficialOwner(data, name) {
    function n(path) {
      return [name, path].join('.');
    }
    return validate(
      required(n('uid'))(),
      required(n('firstName'), false)(v.firstName),
      required(n('lastName'), false)(v.lastName),
      required(n('birthDate'))(v.date.has18yo),
      required(n('nationality'), false)(),
      optional(n('emiratesId.files'))(),
      optional(n('emiratesId.expiryDate'))(v.date.defaultAfter),
      optional(n('emiratesId.documentNumber'))(v.emiratesId),
      required(n('passport.files'))(),
      required(n('passport.documentNumber'))(v.passportNumber),
      required(n('passport.expiryDate'))(v.date.defaultAfter),
      optional(n('visa.files'))(),
      optional(n('visa.expiryDate'))(v.date.defaultAfter),
      required(n('country'), false)(),
      required(n('city'))(),
      required(n('addressLine'), false)(v.addressLine),
      required(n('poBox'))(v.poBox),
      // optional(n('email'), false)(v.email), // not represented in the form
      required(n('phoneNumber'))(v.phone),
      condition(c.equal(n('isAuthorizedSignatory'), true))(
        required(n('proof.designation'))(),
        required(n('proof.documentType'))()
      ),
      condition(
        c.equal(n('isAuthorizedSignatory'), true),
        c.strictEqual(n('proof.documentType'), powerOfAttorney)
      )(required(n('proof.files'))())
    )(data);
  }

  /**
   * Check if all information about business is presented and valid
   * @param data
   * @param name
   */
  function validateBusiness(data, name) {
    function n(path) {
      return [name, path].join('.');
    }
    return validate(
      required(n('uid'))(),
      required(n('companyLicense.files'))(),
      required(n('companyLicense.legalName'))(v.legalName),
      required(n('companyLicense.legalType'))(),
      required(n('companyLicense.licenseNumber'))(v.licenseNumber),
      required(n('companyLicense.dateOfEstablishment'))(v.date.defaultBefore),
      required(n('country'), false)(),
      required(n('city'))(),
      required(n('addressLine'), false)(v.addressLine),
      required(n('poBox'))(v.poBox),
      required(n('phoneNumber'))(v.phone)
    )(data);
  }

  return {
    /**
     * Check if application form with services is valid
     *
     * "services" links to "view" property in application flow schema file (*.json) in admin panel
     * https://networkinternational.atlassian.net/wiki/spaces/NANSO/pages/2406875321/White-label+How+to+customize+an+Application
     * @param payload
     */
    services: function (payload) {
      return validate(
        condition(c.equal('services.nGeniusPos.enabled', true))(
          required('services.nGeniusPos.terminalFee')(v.number.min(1), v.number.max(50))
        ),
        condition(c.equal('services.nGeniusGo.enabled', true))(
          required('services.nGeniusGo.terminalFee')(v.number.min(1), v.number.max(50))
        )
      )(payload);
    },
    /**
     * Check if application form with company information is valid
     *
     * "companyInformation" links to "view" property in application flow schema file (*.json) in admin panel
     * https://networkinternational.atlassian.net/wiki/spaces/NANSO/pages/2406875321/White-label+How+to+customize+an+Application
     */
    companyInformation: function (payload, roles) {
      const businessNature = get(payload, 'companyType.businessNature');
      const businessIndustry = get(payload, 'companyType.businessIndustry');
      return validate(
        required('companyInformation.companyLicense.files')(),
        required('companyInformation.companyLicense.legalType', false)(),
        required('companyInformation.companyLicense.legalName')(v.legalName),
        required('companyInformation.companyLicense.licenseNumber')(v.licenseNumber),
        required('companyInformation.companyLicense.licenseIssueDate')(v.date.defaultBefore),
        required('companyInformation.companyLicense.dateOfEstablishment')(v.date.defaultBefore),
        required('companyInformation.companyLicense.commercialName')(v.string.maxLength(100)),
        required('companyInformation.companyLicense.licenseAuthority')(),
        required('companyInformation.companyLicense.licenseExpiryDate')(v.date.defaultAfter),
        condition(c.equal('companyInformation.taxRegistrationCertificate.enabled', true))(
          required('companyInformation.taxRegistrationCertificate.files')(),
          required('companyInformation.taxRegistrationCertificate.number')(
            v.string.digitsOnly,
            v.string.equalLength(15)
          )
        ),
        condition(c.isAgent(roles))(
          required('companyInformation.email', false)(v.email),
          required('companyType.companyType', false)(v.handbook.companyType(roles)),
          required('companyType.businessNature', false)(v.handbook.businessNature),
          required(
            'companyType.businessIndustry',
            false
          )(v.handbook.businessIndustry(businessNature)),
          required('companyType.mcc')(v.handbook.mcc(businessNature, businessIndustry))
        )
      )(payload);
    },
    /**
     * Check if application form with addresses is valid
     *
     * "addresses" links to "view" property in application flow schema file (*.json) in admin panel
     * https://networkinternational.atlassian.net/wiki/spaces/NANSO/pages/2406875321/White-label+How+to+customize+an+Application
     */
    addresses: function (payload) {
      return validate(
        required('addresses.headOffice.country', false)(),
        optional('addresses.headOffice.geolocation')(),
        required('addresses.headOffice.addressLine', false)(v.addressLine),
        required('addresses.headOffice.poBox')(v.poBox),
        required('addresses.headOffice.phoneNumber')(v.phone),
        optional('addresses.headOffice.faxNumber')(v.phone),
        required('addresses.headOffice.emirate', false)(),
        required('addresses.headOffice.propertyType', false)(),
        optional('addresses.documents.files')(),
        optional('addresses.photos.outside.files')(),
        optional('addresses.photos.inside.files')()
      )(payload);
    },
    /**
     * Check if application form with authorized representative is valid
     *
     * "soleProprietorAuthorizedSignatory" links to "view" property in application flow schema file (*.json) in admin panel
     * https://networkinternational.atlassian.net/wiki/spaces/NANSO/pages/2406875321/White-label+How+to+customize+an+Application
     */
    soleProprietorAuthorizedSignatory: function (payload) {
      let errors = {};
      const authorizedRepresentative = get(payload, 'authorizedRepresentative');
      payload &&
        Array.isArray(authorizedRepresentative) &&
        authorizedRepresentative.forEach(function (_item, _index) {
          errors = assign(errors, validatePerson(payload, 'authorizedRepresentative.' + _index));
        });
      return errors;
    },
    /**
     * Check if application form with authorized representative is valid
     *
     * "multipleAuthorizedSignatory" links to "view" property in application flow schema file (*.json) in admin panel
     * https://networkinternational.atlassian.net/wiki/spaces/NANSO/pages/2406875321/White-label+How+to+customize+an+Application
     */
    multipleAuthorizedSignatory: function (payload) {
      let errors = {};
      const authorizedRepresentative = get(payload, 'authorizedRepresentative');

      payload &&
        Array.isArray(authorizedRepresentative) &&
        authorizedRepresentative.forEach(function (_item, _index) {
          errors = assign(errors, validatePerson(payload, 'authorizedRepresentative.' + _index));
        });

      errors = assign(errors, validateAuthorizedRepresentativeEmiratesId(payload));

      const hasAuthorizedSignatory = !!(
        payload &&
        Array.isArray(authorizedRepresentative) &&
        find(authorizedRepresentative, function (person) {
          return person.isAuthorizedSignatory;
        })
      );

      if (payload && Array.isArray(authorizedRepresentative) && !hasAuthorizedSignatory) {
        errors['COMMON.isAuthorizedSignatory'] = t(
          'Mark at least one person as an authorized signatory'
        );
      }

      return errors;
    },
    /**
     * Check if application form with shareholders is valid
     *
     * "multipleShareholders" links to "view" property in application flow schema file (*.json) in admin panel
     * https://networkinternational.atlassian.net/wiki/spaces/NANSO/pages/2406875321/White-label+How+to+customize+an+Application
     */
    multipleShareholders: function (payload) {
      const total = utils.getTotalShares(payload);
      const totalBeneficialOwners = utils.getTotalBeneficialOwnersShares(payload);
      const memorandumOrPartnership = t(
        'Upload Copy of Memorandum of Association or Partnership deed'
      );

      let errors = validate(
        required('shareholders.proof.documentType', false)(),

        condition(
          c.strictEqual('shareholders.proof.documentType', memorandumOrPartnership),
          c.equal('shareholders.proof.partnershipDeed.checked', false),
          c.equal('shareholders.proof.memorandumOfAssociation.checked', false)
        )(function () {
          const resultError = {};
          resultError['shareholders.proof.partnershipDeed.checked'] = m.requiredAtLeastOne();
          return resultError;
        }),

        condition(
          c.strictEqual('shareholders.proof.documentType', memorandumOrPartnership),
          c.equal('shareholders.proof.partnershipDeed.checked', true)
        )(required('shareholders.proof.partnershipDeed.files')()),

        condition(
          c.strictEqual('shareholders.proof.documentType', memorandumOrPartnership),
          c.equal('shareholders.proof.memorandumOfAssociation.checked', true)
        )(required('shareholders.proof.memorandumOfAssociation.files')())
      )(payload);

      (get(payload, 'shareholders.authorizedRepresentative') || []).forEach(function (_, _index) {
        const name = ['shareholders', 'authorizedRepresentative', _index];
        const enabledName = name.concat(['enabled']).join('.');
        const sharesName = name.concat(['shares']).join('.');
        const uidName = name.concat(['uid']).join('.');

        if (get(payload, enabledName)) {
          if (!get(payload, sharesName)) {
            errors[sharesName] = m.required();
          } else if (total.shares > MAX_SHARES) {
            errors[sharesName] = m.totalShares(total.shares);
          } else if (get(payload, sharesName) <= 0) {
            errors[sharesName] = m.more(0);
          }
          if (!get(payload, uidName)) {
            errors[uidName] = m.required();
          }
        }
      });

      (get(payload, 'shareholders.individuals') || []).forEach(function (_, _index) {
        const name = ['shareholders', 'individuals', _index];
        const sharesName = name.concat(['shares']).join('.');
        const dataName = name.concat(['data']).join('.');

        if (!get(payload, sharesName)) {
          errors[sharesName] = m.required();
        } else if (total.shares > MAX_SHARES) {
          errors[sharesName] = m.totalShares(total.shares);
        } else if (get(payload, sharesName) <= 0) {
          errors[sharesName] = m.more(0);
        }
        errors = assign(errors, validateShareholderIndividual(payload, dataName));
      });

      (get(payload, 'shareholders.businesses') || []).forEach(function (_, _index) {
        const name = ['shareholders', 'businesses', _index];
        const sharesName = name.concat(['shares']).join('.');
        const dataName = name.concat(['data']).join('.');

        if (!get(payload, sharesName)) {
          errors[sharesName] = m.required();
        } else if (total.shares > MAX_SHARES) {
          errors[sharesName] = m.totalShares(total.shares);
        } else if (get(payload, sharesName) <= 0) {
          errors[sharesName] = m.more(0);
        }
        errors = assign(errors, validateBusiness(payload, dataName));
      });

      (get(payload, 'shareholders.beneficialOwners') || []).forEach(function (_, _index) {
        const name = ['shareholders', 'beneficialOwners', _index];
        const sharesName = name.concat(['shares']).join('.');
        const dataName = name.concat(['data']).join('.');

        if (!get(payload, sharesName)) {
          errors[sharesName] = m.required();
        } else if (totalBeneficialOwners.shares > MAX_SHARES) {
          errors[sharesName] = m.totalShares(totalBeneficialOwners.shares);
        } else if (get(payload, sharesName) <= 0) {
          errors[sharesName] = m.more(0);
        }
        errors = assign(errors, validateBeneficialOwner(payload, dataName));
      });

      errors = assign(errors, validatePersonShareholdersEmiratesId(payload));

      if (total.shares < MIN_SHARES) {
        errors['COMMON.TOTAL_SHARES'] = t('Total shares must be at least {{minShares}}%', {
          minShares: MIN_SHARES,
        });
      }

      return errors;
    },

    /**
     * Check if application form with additional information is valid
     *
     * "additionalInformation" links to "view" property in application flow schema file (*.json) in admin panel
     * https://networkinternational.atlassian.net/wiki/spaces/NANSO/pages/2406875321/White-label+How+to+customize+an+Application
     */
    additionalInformation: function (payload) {
      function hasPep(_payload) {
        if (
          Array.isArray(get(_payload, 'authorizedRepresentative')) &&
          find(get(_payload, 'authorizedRepresentative'), function (person) {
            return person.isPep;
          })
        ) {
          return true;
        }
        if (
          Array.isArray(get(_payload, 'shareholders.individuals')) &&
          find(get(_payload, 'shareholders.individuals'), function (item) {
            return item.data.isPep;
          })
        ) {
          return true;
        }
        if (
          Array.isArray(get(_payload, 'shareholders.beneficialOwners')) &&
          find(get(_payload, 'shareholders.beneficialOwners'), function (item) {
            return item.data.isPep;
          })
        ) {
          return true;
        }
        return false;
      }

      function hasRelationToSanctionedCountries(_payload) {
        const hasDealings = get(_payload, 'additionalInformation.sanctions.hasDealings');
        const isPersonResident = get(_payload, 'additionalInformation.sanctions.isPersonResident');
        const hasPropertyIn = get(_payload, 'additionalInformation.sanctions.hasPropertyIn');
        return hasDealings || isPersonResident || hasPropertyIn;
      }

      function mustBeFalse(value) {
        if (value) {
          throw new Error(t('Edit persons at corresponding pages'));
        }
        return true;
      }

      return validate(
        optional('additionalInformation.companyInformation.website', false)(v.url()),
        optional('additionalInformation.companyInformation.primaryLocation')(
          v.string.maxLength(127)
        ),
        optional('additionalInformation.companyInformation.tradeArea')(v.string.maxLength(127)),
        required('additionalInformation.companyInformation.yearsInBusiness')(
          v.number.min(0),
          v.number.max(9999)
        ),
        optional('additionalInformation.companyInformation.numberOfEmployees')(v.number.min(0)),
        required('additionalInformation.companyInformation.marketShare')(
          v.number.min(0),
          v.number.max(100)
        ),
        condition(c.equal('services.eCommerce.enabled', true))(
          required('additionalInformation.companyInformation.numberOfTransactions')(v.number.min(0))
        ),
        required('additionalInformation.turnover.expectedCardVolume.firstYear')(v.number.min(0)),
        required('additionalInformation.turnover.expectedCardVolume.secondYear')(v.number.min(0)),
        required('additionalInformation.turnover.expectedVolumePerYear.firstYear')(v.number.min(0)),
        required('additionalInformation.turnover.expectedVolumePerYear.secondYear')(
          v.number.min(0)
        ),
        condition(negate(hasPep))(required('additionalInformation.hasPep')(mustBeFalse)),
        required('additionalInformation.sanctions.hasDealings')(),
        required('additionalInformation.sanctions.isPersonResident')(),
        required('additionalInformation.sanctions.hasPropertyIn')(),
        condition(hasRelationToSanctionedCountries)(
          required('additionalInformation.sanctions.sanctionedCountries')(),
          required('additionalInformation.sanctions.businessActivities')(),
          required('additionalInformation.sanctions.products')(),
          required('additionalInformation.sanctions.expectedDealings')()
        )
      )(payload);
    },

    /**
     * Check if application form with bank account is valid
     *
     * "bankAccount" links to "view" property in application flow schema file (*.json) in admin panel
     * https://networkinternational.atlassian.net/wiki/spaces/NANSO/pages/2406875321/White-label+How+to+customize+an+Application
     */
    bankAccount: function (payload) {
      return validate(
        condition(
          c.equalSome(['services.nGeniusPos.enabled', true], ['services.nGeniusGo.enabled', true])
        )(
          required('bankAccount.devicesAccount.files')(),
          required('bankAccount.devicesAccount.bankName', false)(),
          required('bankAccount.devicesAccount.IBAN')(v.iban),
          optional('bankAccount.devicesAccount.accountName')(),
          required('bankAccount.devicesAccount.accountNumber')(v.accountNumber)
        ),
        condition(
          c.equal('services.eCommerce.enabled', true),
          c.equal('bankAccount.ecommerceAccount.isSameAsDevicesAccount', false)
        )(
          required('bankAccount.ecommerceAccount.files')(),
          required('bankAccount.ecommerceAccount.bankName', false)(),
          required('bankAccount.ecommerceAccount.IBAN')(v.iban),
          optional('bankAccount.ecommerceAccount.accountName')(),
          required('bankAccount.ecommerceAccount.accountNumber')(v.accountNumber)
        )
      )(payload);
    },
  };
}

export default validation;
