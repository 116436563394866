import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';

import dayjs from 'dayjs';
import dayjsUtc from 'dayjs/plugin/utc';
import dayjsTimezone from 'dayjs/plugin/timezone';
import dayjsAdvancedFormat from 'dayjs/plugin/advancedFormat';

import 'antd/dist/antd.less';
import './styles/index.less';
import './i18n';

import { store } from './store';
import App from './App';
import { onApiError } from './utils/handleError';
import { ApiError } from './types/ApiError';

dayjs.extend(dayjsUtc);
dayjs.extend(dayjsTimezone);
dayjs.extend(dayjsAdvancedFormat);

export const history = createBrowserHistory();
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      onError: (error: any) => onApiError(error as ApiError),
    },
    mutations: {
      onError: (error: any) => onApiError(error as ApiError),
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Router history={history}>
          <App />
        </Router>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
