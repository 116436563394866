import { useMediaQuery } from 'react-responsive';

import theme from '~/constants/theme';
import { isAgent } from '~/utils/getDecodedJwt';

interface Props {
  wizardHeader: boolean;
}

export const useHeaderHeight = (props: Props) => {
  const { wizardHeader } = props || {};

  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.md });
  const isMobile = useMediaQuery({ maxWidth: theme.breakpoints.sm });
  const middleContentBottom = useMediaQuery({
    maxWidth: isAgent() ? theme.breakpoints.md : theme.breakpoints.sm,
  });

  const defaultHeight = 80;
  if (wizardHeader) {
    if (isMobile) return 128;
    if (isTablet && middleContentBottom) return 134;
    return defaultHeight;
  }
  return defaultHeight;
};

export const getLayoutHeight = (headerHeight: number) => ({
  minHeight: `calc(100vh - ${headerHeight}px)`,
});
