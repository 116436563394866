import React, { FC } from 'react';
import { InputNumber, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { get } from 'lodash';
import styled from 'styled-components';

import Form from '~/components/WizardForm';
import buildNamePath from '~/utils/buildNamePath';
import InputsGroupLayout from '~/layouts/InputsGroupLayout';
import { AmountField, Hint } from '~/components';
import direction from '~/utils/direction';
import { DirectionProp } from '~/types/Direction';
import { useDirection } from '~/hooks/i18nHooks';

const AnnualTurnoverInputs: FC = () => {
  const { t } = useTranslation('fillApplication');
  const dir = useDirection();

  return (
    <Container className="AnnualTurnoverInputs" dir={dir}>
      <Typography.Title level={5} className="AnnualTurnoverInputs-TitleWithHint">
        {t('Expected card sales')}
        <Hint text={t('Total value in AED of expected card and digital payments')} />
      </Typography.Title>
      <InputsGroupLayout>
        <Form.Item name={commonPaths.expectedCardVolume1} label={t('1st year')}>
          <InputNumber />
        </Form.Item>
        <Form.Item name={commonPaths.expectedCardVolume2} label={t('2nd year')}>
          <InputNumber />
        </Form.Item>
      </InputsGroupLayout>

      <Typography.Title level={5} className="AnnualTurnoverInputs-TitleWithHint">
        {t('Expected non-card sales')}
        <Hint
          text={t('Total value in AED of non card and digital transactions (e.g. cash and cheque)')}
        />
      </Typography.Title>
      <InputsGroupLayout>
        <Form.Item name={commonPaths.expectedVolumePerYear1} label={t('1st year')}>
          <InputNumber />
        </Form.Item>
        <Form.Item name={commonPaths.expectedVolumePerYear2} label={t('2nd year')}>
          <InputNumber />
        </Form.Item>
      </InputsGroupLayout>

      <Typography.Title
        level={5}
        className="AnnualTurnoverInputs-TitleWithHint AnnualTurnoverInputs-TotalAmountTitle"
      >
        {t('Total expected sales')}
        <Hint text={t('This is the total value in AED of card and non card sales')} />
      </Typography.Title>
      <Form.ShouldUpdate name={n(['turnover'])}>
        {(form) => {
          const formValues = form.getFieldsValue();
          const expectedCardVolume1 = get(formValues, commonPaths.expectedCardVolume1);
          const expectedCardVolume2 = get(formValues, commonPaths.expectedCardVolume2);
          const expectedVolumePerYear1 = get(formValues, commonPaths.expectedVolumePerYear1);
          const expectedVolumePerYear2 = get(formValues, commonPaths.expectedVolumePerYear2);
          const total =
            typeof expectedCardVolume1 === 'number' &&
            typeof expectedCardVolume2 === 'number' &&
            typeof expectedVolumePerYear1 === 'number' &&
            typeof expectedVolumePerYear2 === 'number'
              ? expectedCardVolume1 +
                expectedCardVolume2 +
                expectedVolumePerYear1 +
                expectedVolumePerYear2
              : null;

          return (
            <Typography.Paragraph className="AnnualTurnoverInputs-TotalTurnover">
              <Trans t={t} i18nKey="Total expected turnover after two years: <1></1>">
                {'Total expected turnover after two years: '}
                <AmountField
                  amount={total ? { value: total, currency: 'AED' } : undefined}
                  style={{ fontWeight: 600 }}
                />
              </Trans>
            </Typography.Paragraph>
          );
        }}
      </Form.ShouldUpdate>
    </Container>
  );
};

const n = buildNamePath('additionalInformation');
const commonPaths = {
  expectedCardVolume1: n(['turnover', 'expectedCardVolume', 'firstYear']),
  expectedCardVolume2: n(['turnover', 'expectedCardVolume', 'secondYear']),
  expectedVolumePerYear1: n(['turnover', 'expectedVolumePerYear', 'firstYear']),
  expectedVolumePerYear2: n(['turnover', 'expectedVolumePerYear', 'secondYear']),
};

const Container = styled.div<DirectionProp>`
  .AnnualTurnoverInputs-TitleWithHint {
    display: flex;
    align-items: center;
    .Hint {
      ${direction.margin.left(8)};
    }
  }
  .AnnualTurnoverInputs-TotalAmountTitle {
    margin-bottom: 16px;
  }
  .AnnualTurnoverInputs-TotalTurnover {
    margin-bottom: 0;
  }
`;

export default AnnualTurnoverInputs;
