import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import Space from '~/components/Space';
import theme from '~/constants/theme';
import { PrimitiveFieldMap, ServiceField } from '~/types/Services';
import { NameFn } from '~/utils/buildNamePath';
import { isAgent } from '~/utils/getDecodedJwt';
import FieldResolver from './FieldResolver';
import ResetServiceFields from './ResetServiceFields';
import TitleWithCollapse from './TitleWithCollapse';
import { isVisible } from './utils';

interface Props {
  n: NameFn;
  fields: ServiceField[];
  fieldMap: PrimitiveFieldMap;
}

const PaymentSchemaFields: FC<Props> = (props) => {
  const { n, fields, fieldMap } = props;
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.sm });
  const { t } = useTranslation('fillApplication');

  const currFields = useMemo(() => {
    return fields.filter((f) => f.tag === 'PAYMENT_SCHEMAS' && isVisible(f));
  }, [fields]);

  return (
    <TitleWithCollapse withCollapse={isTablet} header={t('Payment Schemes*')}>
      <Space size={24} direction="vertical" fullWidth>
        {currFields.map((f) => (
          <FieldResolver key={f.key} n={n} field={f} fieldMap={fieldMap} />
        ))}
        {isAgent() && (
          <ResetServiceFields id="paymentSchema_resetButton" n={n} fields={currFields} />
        )}
      </Space>
    </TitleWithCollapse>
  );
};

export default PaymentSchemaFields;
