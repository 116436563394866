import React, { FC } from 'react';
import { Input, Typography } from 'antd';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import { useGetApplication } from '~/controllers/wizard';

import { DocumentInput, Form } from '~/components';
import buildNamePath from '~/utils/buildNamePath';
import { Person } from '~/types/Person';

const BeneficialOwnerAdditionalInformation: FC = () => {
  const { t } = useTranslation('fillApplication');
  const applicationQuery = useGetApplication();

  if (!applicationQuery.data) {
    return null;
  }

  return (
    <Form.Visible<Person[] | undefined>
      name={['shareholders', 'beneficialOwners']}
      visibleIf={(beneficialOwners) => !isEmpty(beneficialOwners || [])}
    >
      <Typography.Title level={4}>{t('Additional information').toUpperCase()}</Typography.Title>
      <Typography.Paragraph>
        {t(
          'In case of complex ownership structure please provide comments and/or schemas that might be relevant.'
        )}
      </Typography.Paragraph>
      <Form.Item name={n('files')}>
        <DocumentInput name="photos" variant="default" />
      </Form.Item>
      <Form.Item name={n('comment')} label={t('Enter your comments')} textarea>
        <Input.TextArea autoSize={{ minRows: 4 }} />
      </Form.Item>
    </Form.Visible>
  );
};

const n = buildNamePath(['shareholders', 'additionalInfo']);

export default BeneficialOwnerAdditionalInformation;
