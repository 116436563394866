import { CheckboxOptionType, Radio } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';
import colors from '~/constants/colors';
import direction from '~/utils/direction';
import theme from '~/constants/theme';
import { useDirection } from '~/hooks/i18nHooks';

interface Props {
  value?: any;
  onChange?: (value: any) => void;
  options: CheckboxOptionType[];
}

const RadioGroupInput: FC<Props> = (props): JSX.Element => {
  const dir = useDirection();
  return (
    <Container dir={dir}>
      <Radio.Group optionType="button" {...props} />
    </Container>
  );
};

const Container = styled.div`
  .ant-radio-group {
    display: flex;
    flex-direction: row;
  }

  .ant-radio-button-wrapper-checked,
  .ant-radio-button-wrapper-checked:hover,
  .ant-radio-button-wrapper-checked:focus-within,
  .ant-radio-button-wrapper-checked:first-child {
    box-shadow: 0 0 0;
    background-color: ${colors.grey[200]};
    border-color: ${colors.borderColorBase} !important;
  }

  .ant-radio-button-wrapper::before,
  .ant-radio-button-wrapper:hover::before {
    content: unset;
  }

  .ant-radio-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    min-width: 0;
  }

  .ant-radio-button-wrapper:hover,
  .ant-radio-button-wrapper-checked {
    color: ${colors.textColor};
    font-weight: 600;
  }

  .ant-radio-button-wrapper:first-child {
    border-radius: unset;
    border-left: ${({ dir }) => (dir === 'ltr' ? `1px solid ${colors.borderColorBase}` : '0')};
    ${direction.borderRadius.topLeft(theme.borderRadiusBase)};
    ${direction.borderRadius.bottomLeft(theme.borderRadiusBase)};
  }

  .ant-radio-button-wrapper:last-child {
    border-radius: unset;
    border-right: ${({ dir }) => (dir === 'rtl' ? `1px solid ${colors.borderColorBase}` : '0')};
    ${direction.border.right(`1px solid ${colors.borderColorBase}`)};
    ${direction.borderRadius.topRight(theme.borderRadiusBase)};
    ${direction.borderRadius.bottomRight(theme.borderRadiusBase)};
  }
`;

export default RadioGroupInput;
