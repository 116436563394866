import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TextField, BusinessNatureField, BusinessLineField } from '~/components';
import AddressField from '~/components/fields/AddressField';
import { applyMask } from '~/components/inputs/PhoneNumberInput';
import { useGetApplication } from '~/controllers/wizard';
import GroupLayout, { GroupLayoutProps } from '~/layouts/GroupLayout';
import { getDecodedJwt, isAgent } from '~/utils/getDecodedJwt';

const CompanyDetailsTab: FC<GroupLayoutProps> = (props) => {
  const groupLayoutProps = props;
  const { t } = useTranslation('fillApplication');

  const applicationQuery = useGetApplication();
  if (!applicationQuery.data) return null;
  const { companyType, addresses, companyInformation, bankAccount } = applicationQuery.data.payload;

  const legalName = companyInformation?.companyLicense?.legalName;
  const commercialName = companyInformation?.companyLicense?.commercialName;
  const phoneNumber = addresses?.headOffice.phoneNumber;

  const hasDevice = !!applicationQuery.data?.payload.services?.nGeniusPos?.enabled;
  const hasEcommerce = !!applicationQuery.data?.payload.services?.eCommerce?.enabled;
  const isSeveralIBANs =
    hasDevice &&
    hasEcommerce &&
    bankAccount?.ecommerceAccount &&
    !bankAccount.ecommerceAccount.isSameAsDevicesAccount &&
    bankAccount.devicesAccount?.IBAN !== bankAccount.ecommerceAccount.IBAN;
  const deviceIBAN = bankAccount?.devicesAccount?.IBAN;
  const ecommerceIBAN =
    bankAccount &&
    !bankAccount.ecommerceAccount?.isSameAsDevicesAccount &&
    bankAccount?.ecommerceAccount?.IBAN;

  const businessNature = companyType?.businessNature;
  const businessIndustry = companyType?.businessIndustry;
  const email = isAgent()
    ? applicationQuery.data.payload.companyInformation?.email
    : getDecodedJwt()?.email;

  const address = {
    country: addresses?.headOffice.country,
    emirate: addresses?.headOffice.emirate,
    addressLine: addresses?.headOffice.addressLine,
    poBox: addresses?.headOffice.poBox,
  };

  return (
    <GroupLayout {...groupLayoutProps}>
      <TextField id="CompanyDetailsTab_legalName" label={t('Company legal name')}>
        {legalName}
      </TextField>
      <TextField id="CompanyDetailsTab_businessNature" label={t('Business nature')}>
        <BusinessNatureField value={businessNature} />
      </TextField>
      <TextField id="CompanyDetailsTab_commercialName" label={t('Commercial name')}>
        {commercialName}
      </TextField>
      <TextField id="CompanyDetailsTab_businessIndustry" label={t('Business line')}>
        <BusinessLineField value={businessIndustry} />
      </TextField>
      <TextField id="CompanyDetailsTab_phoneNumber" label={t('Phone number')}>
        <bdi>{applyMask(phoneNumber)}</bdi>
      </TextField>
      <TextField id="CompanyDetailsTab_email" label={t('Email')}>
        {email}
      </TextField>
      {isSeveralIBANs ? (
        <>
          <TextField id="CompanyDetailsTab_deviceIBAN" label={t('Device account IBAN')}>
            {deviceIBAN}
          </TextField>
          <TextField id="CompanyDetailsTab_address" label={t('Address')}>
            <AddressField value={address} />
          </TextField>
          <TextField id="CompanyDetailsTab_ecommerceIBAN" label={t('E-Commerce account IBAN')}>
            {ecommerceIBAN}
          </TextField>
        </>
      ) : (
        <>
          <TextField id="CompanyDetailsTab_IBAN" label="IBAN">
            {deviceIBAN || ecommerceIBAN}
          </TextField>
          <TextField id="CompanyDetailsTab_address" label={t('Address')}>
            <AddressField value={address} />
          </TextField>
        </>
      )}
    </GroupLayout>
  );
};

export default CompanyDetailsTab;
