import common from './common';
import fillApplication from './fillApplication';
import stageResult from './stageResult';
import validation from './validation';
import contract from './contract';
import payment from './payment';
import message from './message';
import servicesConfig from './servicesConfig';
import * as agent from './agent';

export default {
  common,
  fillApplication,
  stageResult,
  validation,
  contract,
  payment,
  message,
  servicesConfig,
  ...agent,
};
