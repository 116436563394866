import React, { FC } from 'react';
import { Popover as AntdPopover, PopoverProps as AntdPopoverProps } from 'antd';
import classNames from 'classnames';
import { useDirection } from '~/hooks/i18nHooks';

import './styles.less';

const Popover: FC<AntdPopoverProps> = (props) => {
  const { overlayClassName, ...rest } = props;
  const dir = useDirection();
  return (
    <AntdPopover
      overlayClassName={classNames(overlayClassName, {
        'Popover-Overlay__rtl': dir === 'rtl',
      })}
      {...rest}
    />
  );
};

export default Popover;
