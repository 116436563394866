import React, { FC } from 'react';
import { SelectProps } from 'antd';
import { useTranslation } from 'react-i18next';
import Select from './Select';
import { AgentLeadStatus } from '~/types/AgentLead';
import { useIsTablet } from '~/hooks/useScreenSizes';

const status: Record<string, AgentLeadStatus> = {
  new: 'NEW',
  contacted: 'CONTACTED',
  terminated: 'TERMINATED',
  converted: 'CONVERTED',
};

const LeadStatusSelect: FC<SelectProps<string>> = (props) => {
  const { t } = useTranslation('leadStatus');
  const isTablet = useIsTablet();
  const options = Object.values(status).map((value: any) => ({
    label: t(value),
    value,
    key: value,
  }));

  return <Select showSearch={!isTablet} options={options} {...props} />;
};

export default LeadStatusSelect;
