import React, { FC } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { PickerProps } from 'antd/lib/date-picker/generatePicker';

import TimePicker from '../TimePicker';
import { DateTimeFormat } from '~/constants/dateTime';

interface Props {
  value?: any;
  onChange?: (value: any) => void;
  format?: string;
  [x: string]: any;
}

const TimeInput: FC<Props & Omit<PickerProps<Dayjs>, 'placeholder'>> = (props) => {
  const { value, onChange, disableDateRule, format = DateTimeFormat.TIME, ...rest } = props;
  return (
    <TimePicker
      value={parseValue(value, format)}
      onChange={parseOnChange(onChange, format)}
      format={format}
      size="large"
      {...rest}
      placeholder=""
    />
  );
};

const parseValue = (
  value: string | dayjs.Dayjs | undefined,
  format: string
): dayjs.Dayjs | undefined => {
  if (typeof value === 'string' && value) {
    return dayjs(value, format);
  }
  return undefined;
};

const parseOnChange =
  (onChange: ((value: any) => void) | undefined, format: string) => (value: dayjs.Dayjs | null) => {
    onChange?.(value?.format(format));
  };

export default TimeInput;
