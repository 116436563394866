import { produce } from 'immer';
import { WizardNavStepStateProps } from '~/layouts/WizardNavStep';
import { Application, Stage } from '~/types/Application';
import { isAgent } from '~/utils/getDecodedJwt';
import { useGetApplication } from './wizard';

type NavigationStageKey = 'services' | 'application' | 'contract' | 'payment' | 'completion';

type NavigationStageStatus = Record<NavigationStageKey, WizardNavStepStateProps>;

const selectableStageOrder: Stage[][] = [
  [
    Stage.fillApplication,
    Stage.waitingForScreening,
    Stage.screeningReject,
    Stage.screeningError,
    Stage.terminated,
  ],
  [Stage.contract],
  [Stage.payment],
  [
    Stage.completed,
    Stage.submit,
    Stage.successfulOnboarding,
    Stage.salesforceHardDecline,
    Stage.salesforceError,
    Stage.salesforceTechnicalError,
    Stage.salesforceSoftDecline,
  ],
];

// SALESFORCE_TECHNICAL_ERROR("salesforceTechnicalError"),

const isPrecedableOrEqualStage =
  (stage: Stage, actualStage: Stage | undefined) =>
  (calledOnStage: Stage): boolean | null => {
    if (!selectableStageOrder.find((stages) => stages.includes(stage))) return null;
    if (!actualStage) {
      const calledOnStageIndex = selectableStageOrder.findIndex((value) =>
        value.includes(calledOnStage)
      );
      const stageIndex = selectableStageOrder.findIndex((value) => value.includes(stage));
      if (calledOnStageIndex === -1 || stageIndex === -1) return null;
      const isPrecedable = calledOnStageIndex <= stageIndex;
      return isPrecedable;
    }
    if (actualStage) {
      const calledOnStageIndex = selectableStageOrder.findIndex((value) =>
        value.includes(calledOnStage)
      );
      const actualStageIndex = selectableStageOrder.findIndex((value) =>
        value.includes(actualStage)
      );
      if (calledOnStageIndex === -1 || actualStageIndex === -1) return null;
      const isPrecedable = calledOnStageIndex <= actualStageIndex;
      return isPrecedable;
    }
    return null;
  };

const isPrecedableStage =
  (stage: Stage, actualStage: Stage | undefined) =>
  (calledOnStage: Stage): boolean | null => {
    if (!selectableStageOrder.find((stages) => stages.includes(stage))) return null;
    if (!actualStage) {
      const calledOnStageIndex = selectableStageOrder.findIndex((value) =>
        value.includes(calledOnStage)
      );
      const stageIndex = selectableStageOrder.findIndex((value) => value.includes(stage));
      if (calledOnStageIndex === -1 || stageIndex === -1 || calledOnStageIndex === stageIndex)
        return null;
      const isPrecedable = calledOnStageIndex < stageIndex;
      return isPrecedable;
    }
    if (actualStage) {
      const calledOnStageIndex = selectableStageOrder.findIndex((value) =>
        value.includes(calledOnStage)
      );
      const actualStageIndex = selectableStageOrder.findIndex((value) =>
        value.includes(actualStage)
      );
      if (calledOnStageIndex === -1 || actualStageIndex === -1) return null;
      const isPrecedable = calledOnStageIndex < actualStageIndex;
      return isPrecedable;
    }
    return null;
  };

const isDisabled =
  (stage: Stage, actualStage: Stage | undefined) =>
  (calledOnStage: Stage) =>
  (defaultDisabled = true) => {
    if (isAgent()) {
      const isPrecedableOrEqual = isPrecedableOrEqualStage(stage, actualStage)(calledOnStage);
      if (isPrecedableOrEqual === null) return defaultDisabled;
      return !isPrecedableOrEqual;
    }
    return defaultDisabled;
  };

const isDone =
  (stage: Stage, actualStage: Stage | undefined) =>
  (calledOnStage: Stage) =>
  (defaultDone = false) => {
    if (calledOnStage === Stage.completed) {
      switch (actualStage ?? stage) {
        case Stage.successfulOnboarding:
        case Stage.completed: {
          return true;
        }
        default:
          break;
      }
    }
    const isPrecedable = isPrecedableStage(stage, actualStage)(calledOnStage);
    return isPrecedable === null ? defaultDone : isPrecedable;
  };

type CalledOnNav = 'services' | 'application' | 'contract' | 'payment' | 'completion';

const isWarning =
  (errorKeys: Application['errorKeys']) =>
  (stage: Stage, actualStage: Stage | undefined) =>
  (calledOnNav: CalledOnNav) =>
  (defaultWarning = false) => {
    switch (calledOnNav) {
      case 'services': {
        if (errorKeys?.find((err) => err.startsWith('services'))) return true;
        break;
      }
      case 'application': {
        if (errorKeys?.find((err) => err.startsWith('application'))) return true;
        break;
      }
      case 'contract': {
        if (errorKeys?.find((err) => err.startsWith('contract'))) return true;
        break;
      }
      case 'payment': {
        if (errorKeys?.find((err) => err.startsWith('payment'))) return true;
        break;
      }
      case 'completion': {
        switch (actualStage ?? stage) {
          case Stage.salesforceHardDecline:
          case Stage.salesforceError:
          case Stage.salesforceTechnicalError:
            return true;
          default:
            break;
        }
        break;
      }
      default:
        break;
    }
    return defaultWarning;
  };

export const useApplicationStageStatus = (): NavigationStageStatus | null => {
  const applicationQuery = useGetApplication();
  if (!applicationQuery.data) return null;
  const { stage, actualStage } = applicationQuery.data;
  const disabled = isDisabled(stage, actualStage);
  const done = isDone(stage, actualStage);
  const warning = isWarning(applicationQuery.data.errorKeys)(stage, actualStage);

  const initialStageStatus = (
    calledOnStage: Stage,
    calledOnNav: CalledOnNav
  ): WizardNavStepStateProps => ({
    disabled: disabled(calledOnStage)(),
    done: done(calledOnStage)(),
    warning: warning(calledOnNav)(),
    error: false,
    waiting: false,
  });

  const initialNavigationStageStatus = {
    services: initialStageStatus(Stage.fillApplication, 'services'),
    application: initialStageStatus(Stage.fillApplication, 'application'),
    contract: initialStageStatus(Stage.contract, 'contract'),
    payment: initialStageStatus(Stage.payment, 'payment'),
    completion: initialStageStatus(Stage.completed, 'completion'),
  };

  switch (stage) {
    case Stage.highRisk:
    case Stage.companyType: {
      return null;
    }
    case Stage.terminated:
    case Stage.fillApplication: {
      const { steps } = applicationQuery.data.stagePayload;
      const servicesHasWarning = !!applicationQuery.data.stagePayload.steps.find(
        (step) => step.key === 'services' && step.validationState === 'VALIDATION_ERROR'
      );
      return produce(initialNavigationStageStatus, (draft) => {
        draft.services.disabled = steps.find((s) => s.uiKey === 'services')?.state === 'DISABLED';
        draft.services.done = steps.find((s) => s.uiKey === 'services')?.validationState === 'OK';
        draft.services.warning = warning('services')(servicesHasWarning);

        draft.application.disabled =
          steps.find((s) => s.uiKey === 'application')?.state === 'DISABLED';
        draft.application.done =
          steps.find((s) => s.uiKey === 'application')?.validationState === 'OK';
      });
    }
    case Stage.waitingForScreening: {
      return produce(initialNavigationStageStatus, (draft) => {
        draft.services.done = true;
        draft.services.warning = false;
        draft.application.warning = false;
        draft.completion.active = false;
      });
    }
    case Stage.payment: {
      const paymentHasWarning = !!applicationQuery.data.stagePayload.paymentDetails?.errors?.[0];
      return produce(initialNavigationStageStatus, (draft) => {
        draft.payment.warning = warning('payment')(paymentHasWarning);
      });
    }
    case Stage.submit:
    case Stage.completed:
    case Stage.contract:
    case Stage.successfulOnboarding:
    case Stage.screeningError:
    case Stage.salesforceSoftDecline:
    case Stage.salesforceHardDecline:
    case Stage.salesforceReject:
    case Stage.salesforceError:
    case Stage.screeningReject: {
      return initialNavigationStageStatus;
    }
    default: {
      return initialNavigationStageStatus;
    }
  }
};
