import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Space, ExpandibleCardMobile, TextField, CountryField } from '~/components';
import { CheckThinIcon } from '~/img';
import DeleteButton from './DeleteButton';
import EditButton from './EditButton';
import { BusinessRowType } from './BusinessTable';

interface Props extends BusinessRowType {
  disableRowActions: boolean;
  hasBusinessesValidationWarnings: boolean;
}

const BusinessCardMobile: FC<Props> = (props) => {
  const {
    legalName,
    country,
    hasCompanyLicense,
    shares,
    onDelete,
    onEdit,
    disableRowActions,
    hasBusinessesValidationWarnings,
  } = props;
  const { t } = useTranslation('fillApplication');

  return (
    <StyledTableCardMobile className="BusinessCardMobile" span={12} visibleCount={2}>
      <TextField label={t('Company name')}>{legalName}</TextField>
      <TextField label={t('Shares, %')}>{shares}</TextField>
      <Space size={16} align="start">
        <TextField label={t('Country')}>
          <CountryField value={country} />
        </TextField>
        <TextField label={t('License')}>{hasCompanyLicense ? <CheckThinIcon /> : null}</TextField>
      </Space>
      <div className="BusinessCardMobile-ButtonsCol">
        <Space size={16} fullWidth={false}>
          {onEdit && (
            <EditButton
              className="BusinessCardMobile-Button"
              id="businessTable_EditButton"
              disabled={disableRowActions}
              hasValidationWarnings={hasBusinessesValidationWarnings}
              onClick={onEdit}
            />
          )}
          <DeleteButton
            className="BusinessCardMobile-Button"
            id="businessTable_DeleteButton"
            disabled={disableRowActions}
            onClick={onDelete}
          />
        </Space>
      </div>
    </StyledTableCardMobile>
  );
};

const StyledTableCardMobile = styled(ExpandibleCardMobile)`
  .BusinessCardMobile-ButtonsCol {
    display: flex;
    align-items: center;
    height: 100%;
    .anticon {
      color: inherit;
    }
    .BusinessCardMobile-Button {
      width: 32px;
      height: 32px;
      font-size: 16px;
    }
  }
`;

export default BusinessCardMobile;
