import React, { FC } from 'react';
import { SelectProps } from 'antd';

import { Select } from '~/components';
import { useHandbook } from '~/controllers/handbook';

const LegalTypeSelect: FC<SelectProps<any>> = (props) => {
  const handbook = useHandbook();
  const options = handbook.data?.handbooks
    ?.find((item) => item.key === 'legaltype')
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ?.values?.map((item) => ({ label: item.localizedValue, value: item.apiValue!, key: item.key }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));

  return <Select showSearch {...props} options={options} />;
};

export default LegalTypeSelect;
