import { useReduxState } from '~/hooks';

export const useTopBarLoader = () => {
  const [state, setState] = useReduxState('topBarLoader', { isLoading: false });
  const startLoading = () => {
    setState((prev) => ({ ...prev, isLoading: true }));
  };
  const stopLoading = () => {
    setState((prev) => ({ ...prev, isLoading: false }));
  };

  return { isLoading: state.isLoading, startLoading, stopLoading };
};
