import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import Form from '~/components/Form';

import Space from '~/components/Space';
import theme from '~/constants/theme';
import { PrimitiveFieldMap, ServiceField } from '~/types/Services';
import { NameFn } from '~/utils/buildNamePath';
import { isAgent } from '~/utils/getDecodedJwt';
import FieldResolver from './FieldResolver';
import ResetServiceFields from './ResetServiceFields';
import TerminalFeeFormItem, { TerminalFeeFormItemProps } from './TerminalFeeFormItem';
import TitleWithCollapse from './TitleWithCollapse';
import { isVisible } from './utils';

interface Props {
  productMapN: NameFn;
  productMapTempN: NameFn;
  fields: ServiceField[];
  fieldMap: PrimitiveFieldMap;
  terminalFeeCountName?: TerminalFeeFormItemProps['terminalFeeCountName'];
  serviceType?: TerminalFeeFormItemProps['serviceType'];
}

const FeesAndChargesFields: FC<Props> = (props) => {
  const { productMapN, productMapTempN, fields, fieldMap, terminalFeeCountName, serviceType } =
    props;
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.sm });
  const { t } = useTranslation('fillApplication');

  const currFields = useMemo(() => {
    return fields.filter((f) => f.tag === 'FEES_AND_CHARGES');
  }, [fields]);

  const showTotalPriceField = useMemo(() => {
    const terminalFee = currFields.find(
      (f) =>
        f.key === 'terminalFee' &&
        f.type === 'pricing' &&
        isAgent() &&
        f.requiresNumber &&
        serviceType &&
        terminalFeeCountName
    );
    return !!terminalFee;
  }, [currFields, serviceType, terminalFeeCountName]);

  return (
    <TitleWithCollapse withCollapse={isTablet} header={t('Fees and charges*')}>
      <Space size={24} direction="vertical" fullWidth>
        {currFields.map((f) =>
          isVisible(f) ? (
            f.key === 'terminalFee' &&
            f.type === 'pricing' &&
            isAgent() &&
            f.requiresNumber &&
            serviceType &&
            terminalFeeCountName ? (
              <TerminalFeeFormItem
                productMapN={productMapN}
                productMapTempN={productMapTempN}
                terminalFeeCountName={terminalFeeCountName}
                serviceType={serviceType}
                field={f}
              />
            ) : (
              <FieldResolver key={f.key} n={productMapN} field={f} fieldMap={fieldMap} />
            )
          ) : null
        )}
        {isAgent() && (
          <Form.ShouldUpdate name={terminalFeeCountName}>
            {(form) => {
              const count = terminalFeeCountName
                ? form.getFieldValue(terminalFeeCountName) || 1
                : undefined;
              return (
                <ResetServiceFields
                  id="feesAndCharges_resetButton"
                  n={productMapN}
                  showTotalPriceField={showTotalPriceField}
                  productMapTempN={productMapTempN}
                  fields={currFields}
                  count={count}
                />
              );
            }}
          </Form.ShouldUpdate>
        )}
      </Space>
    </TitleWithCollapse>
  );
};

export default FeesAndChargesFields;
