import React, { FC } from 'react';
import { Alert, AlertProps } from 'antd';

interface Props extends AlertProps {
  showInProd?: boolean;
}

const DebugAlert: FC<Props> = (props) => {
  const { showInProd = false, ...alertProps } = props;
  if (process.env.NODE_ENV === 'development' || showInProd) {
    return <Alert type="error" {...alertProps} />;
  }
  return null;
};

export default DebugAlert;
