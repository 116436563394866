import React from 'react';
import { get } from 'lodash';

import ShouldUpdate from './ShouldUpdate';
import { NamePath } from '~/utils/buildNamePath';

interface Props<V = any> {
  name: NamePath;
  visibleIf?: ((value: V) => boolean) | V;
  children?: any;
}

function Visible<V>(props: Props<V>) {
  const { children, name, visibleIf = true } = props;

  return (
    <ShouldUpdate name={name}>
      {(form) => {
        const value = get(form.getFieldsValue(), name);
        if (typeof visibleIf === 'function' && (visibleIf as (value: V) => boolean)(value))
          return <>{children}</>;
        if (visibleIf === get(form.getFieldsValue(), name)) return <>{children}</>;
        return null;
      }}
    </ShouldUpdate>
  );
}

export default Visible;
