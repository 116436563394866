import React, { CSSProperties, FC } from 'react';
import { CollapsePanelProps, Typography } from 'antd';
import styled from 'styled-components';
import Collapse from '~/components/Collapse';

interface Props {
  withCollapse?: boolean;
  header: CollapsePanelProps['header'];
}

const TitleWithCollapse: FC<Props> = (props) => {
  const { withCollapse, header, children } = props;
  if (withCollapse)
    return (
      <StyledCollapse ghost className="TitleWithCollapse" defaultActiveKey={['1']}>
        <Collapse.Panel key="1" header={header}>
          {children}
        </Collapse.Panel>
      </StyledCollapse>
    );

  return (
    <>
      <Typography.Title className="TitleWithCollapse-Title" style={bottomSpacing} level={5}>
        {header}
      </Typography.Title>
      {children}
    </>
  );
};

const bottomSpacing: CSSProperties = { marginBottom: 16 };

const StyledCollapse = styled(Collapse)`
  .ant-collapse-header {
    padding: 0 !important;
    text-transform: unset;
    font-size: 14px;
  }
  .ant-collapse-content-box {
    padding: 12px 0 0 0 !important;
  }
`;

export default TitleWithCollapse;
