import { MutationOptions, useMutation } from 'react-query';

import { payByCard, proceedPayment, sendEmailPayByLink } from '~/api/payment';
import { ApiError } from '~/types/ApiError';
import { useSubmitStepIsLoading } from './wizard';

export const useProceedPayment = () => {
  const [, setLoading] = useSubmitStepIsLoading();
  return useMutation(proceedPayment, {
    onMutate() {
      setLoading(true);
    },
    onSettled() {
      setLoading(false);
    },
    onError() {
      //
    },
  });
};

export const usePayByCard = (options?: MutationOptions<{ paymentFrameLink: string }, ApiError>) => {
  return useMutation(payByCard, options);
};

export const useSendEmailPayByLink = () => {
  return useMutation(sendEmailPayByLink);
};
