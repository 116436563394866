import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import Space from '~/components/Space';
import theme from '~/constants/theme';
import { PrimitiveFieldMap, ServiceField } from '~/types/Services';
import { NameFn } from '~/utils/buildNamePath';
import { isAgent, isMerchant } from '~/utils/getDecodedJwt';
import FieldResolver from './FieldResolver';
import PricingRowField from './PricingRowField';
import ResetServiceFields from './ResetServiceFields';
import TitleWithCollapse from './TitleWithCollapse';
import { isVisible } from './utils';

interface Props {
  n: NameFn;
  fields: ServiceField[];
  fieldMap: PrimitiveFieldMap;
}

const AdditionalServicesFields: FC<Props> = (props) => {
  const { n, fields, fieldMap } = props;
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.sm });
  const { t } = useTranslation('fillApplication');

  const currFields = useMemo(() => {
    return fields.filter((f) => f.tag === 'ADDITIONAL_SERVICES' && isVisible(f));
  }, [fields]);

  if (currFields.length === 0) return null;
  return (
    <TitleWithCollapse withCollapse={isTablet} header={t('Additional services*')}>
      <Space size={24} direction="vertical" fullWidth>
        {currFields.map((f) =>
          f.type === 'composite' && f.pricingField && isMerchant() ? (
            <PricingRowField
              field={f.pricingField}
              fieldMap={fieldMap}
              title={f.name}
              withCheckmark={f.booleanField?.value !== false}
              withCross={f.booleanField?.value === false}
              disabled={f.booleanField?.value === false}
            />
          ) : (
            <FieldResolver key={f.key} n={n} field={f} fieldMap={fieldMap} />
          )
        )}
        {isAgent() && (
          <ResetServiceFields id="additionalServices_resetButton" n={n} fields={currFields} />
        )}
      </Space>
    </TitleWithCollapse>
  );
};

export default AdditionalServicesFields;
