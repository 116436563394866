import React, { useEffect, useMemo } from 'react';
import { Spin } from 'antd';
import { FormProps } from 'antd/lib/form';
import { useDebouncedCallback } from 'use-debounce';
import produce from 'immer';
import { isEmpty, isEqual, mergeWith } from 'lodash';
import { useLocation } from 'react-router';

import Form from '../Form';
import { ApplicationPayload } from '~/types/ApplicationPayload';
import { useGetApplication, useSubmitStep } from '~/controllers/wizard';
import { useMutableCallback } from '~/hooks';
import { useCommonErrors, useValidator } from '~/controllers/validation';
import { FormItemContextProvider } from '../Form/FormItemContext';
import { useDraftIsStale, useGetDraft, useSaveDraft } from '~/controllers/draft';
import { useOptionalFields } from '~/hooks/useOptionalFields';
import { getRoles } from '~/utils/getDecodedJwt';
import { isReadOnly } from '~/utils/isReadOnly';
import { isSomeFieldsDisabled } from '~/utils/isSomeFieldsDisabled';
import { getStagePayload } from '~/utils/getStagePayload';

const SAVE_DRAFT_DEBOUNCE_MS = 1000;

interface WizardFormProps<Values> extends Omit<FormProps<Values>, 'initialValue'> {
  stepKey: keyof ApplicationPayload;
  validationFnName?: string;
  disabled?: boolean;
  onBeforeFinish?: (values: Values) => Promise<any>;
  parseBeforeSubmit?: (values: Values) => Values;
  handleDraftDataBeforeSave?: (values: Values, initialValues?: Values) => Values;
  setInitialValues?: (initialValue: Values) => Values;
  getOptionalFields?: (values: Values) => Record<string, string>;
  validationValues?: Values;
  withAutoSaveDraft?: boolean;
  shouldSaveDraft?: (changedValues?: any, values?: any) => boolean;
  withDefaultOnFinish?: boolean;
  withDefaultValidation?: boolean;
}

function WizardForm<Values extends Record<string, any> = ApplicationPayload>(
  props: WizardFormProps<Values>
): JSX.Element {
  const {
    stepKey,
    validationFnName,
    onValuesChange,
    onBeforeFinish,
    onFinishFailed,
    parseBeforeSubmit,
    handleDraftDataBeforeSave = (values, initialValues) => {
      return produce<any>(initialValues, (draft) => {
        // TODO: find out purpose of this code. I totally don't remember :(
        mergeWith(draft, values, (draftValue, value) => {
          if (Array.isArray(value)) {
            return value;
          }
          return undefined;
        });
      });
    },
    getOptionalFields,
    form: formFromProps,
    disabled,
    setInitialValues,
    shouldSaveDraft = () => true,
    withAutoSaveDraft = true,
    withDefaultOnFinish = true,
    withDefaultValidation = true,
    name,
    ...rest
  } = props;
  // location.pathname is also default 'formName' prop for submit button
  const location = useLocation();
  const actualFormName = name || location.pathname;

  const draftQuery = useGetDraft<Values>();
  const draftMutation = useSaveDraft();
  const [, handleCommonErrors] = useCommonErrors(actualFormName);
  const applicationQuery = useGetApplication();

  // Validation
  const [form] = Form.useForm<Values>(formFromProps);

  const getFormState = () => form.getFieldsValue();
  const validatorController = useValidator(getFormState, validationFnName);

  // initialValues
  const isFormNotReady = (draftQuery.isLoading && !draftQuery.data) || applicationQuery.isLoading;
  const initialValues = useMemo(() => {
    if (!isFormNotReady && applicationQuery.data) {
      const rawPayload =
        draftQuery.data ||
        ({
          [stepKey]: applicationQuery.data?.payload?.[stepKey] || {},
        } as Values);
      const result = setInitialValues ? setInitialValues(rawPayload) : rawPayload;
      return result;
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormNotReady]);

  //
  const optionalFieldsController = useOptionalFields({
    getOptionalFields,
    validationFnName,
    initialValues,
  });

  // Draft
  const { onFresh, onStale } = useDraftIsStale();

  const debouncedSaveChanges = useDebouncedCallback(async (formValues: any) => {
    const stepData = handleDraftDataBeforeSave(formValues, initialValues);
    if (!isEqual(stepData[stepKey], initialValues?.[stepKey])) {
      await draftMutation.mutateAsync({ stepData });
    }
    onFresh();
  }, SAVE_DRAFT_DEBOUNCE_MS);

  const handleValuesChange = useMutableCallback(async (changedValues: any, values: Values) => {
    if (typeof onValuesChange === 'function') {
      onValuesChange(changedValues, values);
    }
    if (withAutoSaveDraft && shouldSaveDraft(changedValues, values)) {
      onStale();
      await debouncedSaveChanges(values);
    }
    optionalFieldsController.debouncedUpdate(values);
    handleCommonErrors.clearError();
  });

  // Submit
  const submitMutation = useSubmitStep();
  const onFinish = async (values: Values) => {
    const result = parseBeforeSubmit ? parseBeforeSubmit(values) : values;
    if (onBeforeFinish) {
      await onBeforeFinish(result);
    }

    if (withDefaultOnFinish) {
      const fullPayload = { ...applicationQuery.data?.payload, ...values };
      const errors = validatorController.validateFn?.(fullPayload, getRoles());
      if (!isEmpty(errors)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const errorFields = Object.entries(errors!).map(([errName, errMessage]) => ({
          name: errName.split('.'),
          errors: [errMessage],
        }));
        onFinishFailed?.({ values: fullPayload, errorFields, outOfDate: false });
        handleCommonErrors.onError(errorFields);
        return Promise.reject(errors);
      }

      await submitMutation.mutateAsync(result);
    }
    return Promise.resolve();
  };

  useEffect(() => {
    return () => {
      handleCommonErrors.clearError();
    };
  }, [handleCommonErrors]);

  const stagePayload = getStagePayload.wizard(applicationQuery.data);

  if (isFormNotReady) return <Spin />;
  return (
    <FormItemContextProvider
      value={{
        defaultValidator: withDefaultValidation ? validatorController.validator : undefined,
        applicationPayload: applicationQuery.data?.payload,
        draftPayload: draftQuery.data,
        optionalFields: optionalFieldsController.optionalFields || {},
        errorFields: stagePayload?.activeStep.errorInformation?.errorFields,
        enabledFields: stagePayload?.activeStep.errorInformation?.enabledFields,
        disabled: isReadOnly(applicationQuery.data),
        disabledByDefault:
          disabled ||
          isReadOnly(applicationQuery.data) ||
          isSomeFieldsDisabled(applicationQuery.data),
      }}
    >
      <FormWithMemo
        onValuesChange={handleValuesChange}
        initialValues={initialValues}
        name={actualFormName}
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        {...rest}
      />
    </FormItemContextProvider>
  );
}

const FormWithMemo = React.memo(Form);

const { Item, List, useForm, Provider, Visible, ShouldUpdate } = Form;

WizardForm.Item = Item;
WizardForm.List = List;
WizardForm.useForm = useForm;
WizardForm.Provider = Provider;
WizardForm.Visible = Visible;
WizardForm.ShouldUpdate = ShouldUpdate;

export default WizardForm;
