export default {
  // Completed
  'Welcome to the Network Family!': 'مرحبًا بك في عائلة الشبكة!',
  "You're now fully set up to take payments through Network International.":
    'أنت الآن جاهز تمامًا لتلقي المدفوعات من خلال نتورك إنترناشيونال',
  'If you wish to discuss any aspects of your services, please contact us via the details within your welcome kit email.':
    'إذا كنت ترغب في مناقشة أي جوانب من خدماتك ، فيرجى الاتصال بنا عبر التفاصيل الموجودة في البريد الإلكتروني الخاص بمجموعة الترحيب.',
  // Error
  'Sorry, something went wrong': 'عذرا، هناك خطأ ما',
  'A technical error has occurred. Please re-submit your application.':
    'حدث خطأ تقني. يرجى إعادة تقديم طلبك.',
  'Try again': 'حاول مرة أخري',
  // Salesforce error
  'Application submission error': 'خطأ في تقديم الطلب',
  'There is a problem with application data.': 'توجد مشكلة في بيانات التطبيق.',
  'There is a problem with application data, please <1>contact us</1> to solve it.':
    'هناك مشكلة في بيانات التطبيق ، يرجى <1>الاتصال بنا</1> لحلها.',
  'Please <1>contact us</1> to get support and continue onboarding.':
    'يرجى <1>الاتصال بنا</1> للحصول على الدعم ومتابعة الإعداد.',
  "We're sorry to say that your application has not been successful this time":
    'يؤسفنا أن نقول إن طلبك لم ينجح هذه المرة',
  'Sorry, we have a technical problem right now, please try again later or <1>contact us</1>.':
    'عذرًا ، لدينا مشكلة فنية الآن ، يرجى المحاولة مرة أخرى لاحقًا أو <1>الاتصال بنا</1>.',
  'Sorry, we have a technical problem right now, please try again later.':
    'عذرا ، لدينا مشكلة فنية الآن ، يرجى المحاولة مرة أخرى في وقت لاحق.',
  'Go back to change the application data': 'ارجع لتغيير بيانات التطبيق',
  // Salesforce soft decline
  'We need more information': 'نحن بحاجة إلى مزيد من المعلومات',
  'Thank you for your application. We have reviewed the information submitted to us and require some clarifications.':
    'شكرا لطلبك. لقد راجعنا المعلومات المقدمة إلينا ونحتاج إلى بعض الإيضاحات.',
  "We request you to select 'Update Application' below and review the following details which you provided:":
    'نطلب منك تحديد "تحديث التطبيق" أدناه ومراجعة التفاصيل التالية التي قدمتها:',
  "We'll call you back soon for further information.":
    'سنتصل بك قريبًا للحصول على مزيد من المعلومات.',
  'Update Application': 'تحديث التطبيق',
  'Please contact us via our <1>Contact us page</1> if you need any support or have any questions.':
    'يرجى الاتصال بنا عبر <1>صفحة اتصل بنا</1> إذا كنت بحاجة إلى أي دعم أو لديك أي أسئلة.',
  // Screening error
  'Screening in progress': 'الفرز قيد التقدم',
  'We need a little more time to review all individuals you have listed. We will notify you by email to proceed with the application.':
    'نحتاج إلى مزيد من الوقت لمراجعة جميع الأفراد الذين قمت بإدراجهم في القائمة. سنخطرك عبر البريد الإلكتروني لمتابعة التطبيق.',
  'In the meantime, you can head to our website to view more about our <1>products and services</1>.':
    'في غضون ذلك ، يمكنك التوجه إلى موقعنا على الويب لعرض المزيد حول <1>منتجاتنا وخدماتنا</1>.',
  'If you require any support whilst you wait, please <1>contact us</1>.':
    'إذا كنت بحاجة إلى أي دعم أثناء الانتظار ، يرجى <1>الاتصال بنا</ 1>.',
  // Screening reject
  'We are sorry to say that your application has not been successful this time':
    'يؤسفنا أن نقول إن طلبك لم ينجح هذه المرة',
  'If you wish to discuss with us, please <1>contact us</1>.':
    'إذا كنت ترغب في المناقشة معنا ، يرجى <1>الاتصال بنا</1>.',
  // Submit
  'Your application {{salesforceApplicationNumber}} is under review':
    'طلبك {{salesforceApplicationNumber}} قيد المراجعة',
  'We aim to review all applications within 2 working days and we will notify you once your application has been approved.':
    'نهدف إلى مراجعة جميع الطلبات في غضون يومي عمل وسنخطرك بمجرد الموافقة على طلبك.',
  'Application details': 'تفاصيل التطبيق',
  // Successful onboarding
  'Application approved!': 'تمت الموافقة على الطلب!',
  'Welcome to the Network family.': 'مرحبًا بك في عائلة الشبكة.',
  'We will soon be sending you our welcome kit which will explain the next steps to enable you to start taking payments.':
    'سنرسل إليك قريبًا مجموعة الترحيب الخاصة بنا والتي ستوضح الخطوات التالية لتمكينك من البدء في تحصيل المدفوعات.',
  'In the meantime, if you wish to speak to us, please <1>contact us</1>.':
    'في غضون ذلك ، إذا كنت ترغب في التحدث إلينا ، يرجى <1>الاتصال بنا</ 1>.',
};
