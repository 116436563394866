import { useMutation } from 'react-query';
import FileSaver from 'file-saver';

import { getFile, getFileById } from '~/api/file';

export const useDownloadFile = () => {
  return useMutation(getFile);
};

export const useDownloadFileById = () => {
  const mutation = useMutation(getFileById);

  const download = async (id: string, name?: string) => {
    const { data } = await mutation.mutateAsync(id);
    FileSaver.saveAs(data, name);
  };

  return { ...mutation, download };
};
