import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled, { css } from 'styled-components';

import { AmountField } from '~/components';
import colors from '~/constants/colors';
import theme from '~/constants/theme';
import { useDirection } from '~/hooks/i18nHooks';
import { Amount } from '~/types/Amount';
import { DirectionProp } from '~/types/Direction';
import direction from '~/utils/direction';
import ServiceCheckbox from './ServiceCheckbox';

interface Props {
  inputKey: string;
  checked?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  name: string;
  header: string;
  imageUrl: string;
  setupFee: Amount | null;
  annualFee: Amount | null;
  bottomText1?: string;
  bottomText2?: string;
  bottomText3?: string;
}

const ServiceCard: FC<Props> = (props) => {
  const {
    inputKey,
    checked,
    onClick,
    disabled,
    name,
    header,
    imageUrl,
    setupFee,
    annualFee,
    bottomText1,
    bottomText2,
    bottomText3,
  } = props;

  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.sm });
  const { t } = useTranslation('fillApplication');
  const dir = useDirection();

  const checkbox = (
    <ServiceCheckbox
      inputKey={inputKey}
      disabled={disabled}
      checked={checked}
      onClick={onClick}
      name={name}
      header={header}
      withBorderBottom={false}
    />
  );

  return (
    <Container
      className="ServiceCard"
      checked={checked}
      onClick={onClick}
      disabled={disabled}
      dir={dir}
    >
      {!isTablet && checkbox}
      <div className="ServiceCard-Card">
        {isTablet && checkbox}
        <img src={imageUrl} alt={name} />
        <div className="ServiceCard-Fee">
          <span>{t('Setup fee')}</span>
          <AmountField amount={setupFee} maximumFractionDigits={0} />
        </div>
        <div className="ServiceCard-Fee">
          <span>{t('Annual fee')}</span>
          <AmountField amount={annualFee} maximumFractionDigits={0} />
        </div>
        {bottomText1 && <div className="ServiceCard-BottomText">{bottomText1}</div>}
        {bottomText2 && <div className="ServiceCard-BottomText">{bottomText2}</div>}
        {bottomText3 && <div className="ServiceCard-BottomText3">{bottomText3}</div>}
      </div>
    </Container>
  );
};

interface ContainerProps extends DirectionProp {
  checked?: boolean;
  disabled?: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 218px;
  cursor: pointer;
  ${({ disabled }) =>
    disabled
      ? css`
          pointer-events: none;
          cursor: default;
          opacity: 0.5;
        `
      : undefined}
  .ServiceCard-Checkbox {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 8px;
    min-height: 66px;
    strong {
      margin-right: -8px;
      span {
        color: ${colors.primaryColor};
        white-space: nowrap;
      }
    }
  }
  .ServiceCard-Card {
    padding: 8px;
    border-radius: ${theme.borderRadiusBase}px;
    box-shadow: ${({ checked }) =>
      checked
        ? `inset 0 0 0 2px ${colors.selectionColor}`
        : `inset 0 0 0 1px ${colors.borderColorBase}`};
    transition: box-shadow 200ms;
    height: 100%;
    img {
      width: 100%;
      aspect-ratio: 1.245;
      margin-bottom: 24px;
      border-radius: 6px;
    }
    .ServiceCard-Fee {
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 8px;
      .AmountField {
        white-space: nowrap;
        ${direction.margin.left(8)};
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
      }
    }
    .ServiceCard-BottomText {
      font-size: 11px;
      line-height: 16px;
      margin-bottom: 8px;
    }
    .ServiceCard-BottomText3 {
      font-size: 14px;
      line-height: 22px;
      margin-top: 24px;
    }
  }
  @media (max-width: ${theme.breakpoints.sm}px) {
    img {
      margin-top: 12px;
    }
  }
`;

export default ServiceCard;
