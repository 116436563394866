import React, { FC, useState } from 'react';
import { Input, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { get, set } from 'lodash';
import produce from 'immer';

import {
  Checkbox,
  CompanyTypeSelect,
  DateInput,
  DocumentInput,
  EmailFormItem,
  IndustrySelect,
  LegalTypeSelect,
  LtrInput,
} from '~/components';
import Form from '~/components/WizardForm';
import InputsGroupLayout from '~/layouts/InputsGroupLayout';
import buildNamePath from '~/utils/buildNamePath';

import { getDecodedJwt, isAgent, isMerchant } from '~/utils/getDecodedJwt';
import { Draft } from '~/types/Draft';
import Section from '~/layouts/Section ';
import { useGetApplication } from '~/controllers/wizard';
import BusinessLineSelect from '~/components/inputs/BusinessLineSelect';
import { CompanyTypeReq } from '~/types/CompanyType';
import { handleCompanyType } from '~/utils/handleCompanyType';
import { useHandbook } from '~/controllers/handbook';
import CustomFields from '~/components/CustomFields';

const CompanyInformation: FC = () => {
  const { t } = useTranslation('fillApplication');

  const applicationQuery = useGetApplication();
  const handbookQuery = useHandbook();
  const [initialValueState, setInitialValueState] = useState<Draft<'companyInformation'>>();
  const [form] = Form.useForm();

  const handbook = handbookQuery.data?.handbooks;
  const agent = isAgent();
  const showCompanyType = agent;
  const disableBusinessLineAndBusinessNature = isMerchant();

  const onValuesChange = (changedValues: CompanyTypeReq, formValues: any) => {
    if (!handbook) return;
    handleCompanyType(form, handbook, (name) => name)(changedValues, formValues);
  };

  if (!applicationQuery.data) return null;
  return (
    <Container className="CompanyInformation">
      <Form<Draft<'companyInformation'>>
        stepKey="companyInformation"
        setInitialValues={(initialValues) => {
          const { companyType } = applicationQuery.data.payload;
          const result = produce({ ...initialValues, companyType }, (draft) => {
            const decodedToken = getDecodedJwt();
            const companyLegalName = decodedToken?.company_legal_name;

            if (!get(draft, n(['companyLicense', 'legalName']))) {
              set(draft, n(['companyLicense', 'legalName']), companyLegalName);
            }
            if (!get(draft, n(['email']))) {
              set(draft, n(['email']), applicationQuery.data?.payload.initiatorEmail);
            }
          });
          setInitialValueState(result);
          return result;
        }}
        onValuesChange={onValuesChange}
        form={form}
      >
        <Typography.Title>{t('Company Information')}</Typography.Title>
        <Section formItemLast>
          <Typography.Title level={4}>{t('Company license').toUpperCase()}</Typography.Title>
          <Typography.Paragraph>
            {t('Please upload the company license photo.')}
          </Typography.Paragraph>
          <Form.Item name={n(['companyLicense', 'files'])}>
            <DocumentInput name="photos" variant="default" />
          </Form.Item>
          <Typography.Title level={5}>{t('Document data:')}</Typography.Title>
          <Typography.Paragraph>
            {t('Please share basic details about the company you represent.')}
          </Typography.Paragraph>

          <InputsGroupLayout>
            <Form.ShouldUpdate shouldUpdate={() => false}>
              {() => {
                const legalName = get(initialValueState, n(['companyLicense', 'legalName']));
                return (
                  <Form.Item
                    label={t('Company legal name')}
                    name={n(['companyLicense', 'legalName'])}
                  >
                    <Input disabled={!!legalName && !agent} />
                  </Form.Item>
                );
              }}
            </Form.ShouldUpdate>
            <Form.Item
              label={t('Date of establishment')}
              name={n(['companyLicense', 'dateOfEstablishment'])}
            >
              <DateInput disableDateRule="date <= today" />
            </Form.Item>
            <Form.Item label={t('Company legal type')} name={n(['companyLicense', 'legalType'])}>
              <LegalTypeSelect />
            </Form.Item>
            <Form.Item
              label={t('Company commercial name')}
              name={n(['companyLicense', 'commercialName'])}
            >
              <Input />
            </Form.Item>
            {showCompanyType && (
              <Form.Item name={['companyType', 'companyType']} label={t('Company type')}>
                <CompanyTypeSelect hint={false} />
              </Form.Item>
            )}
            <Form.Item name={['companyType', 'businessNature']} label={t('Business nature')}>
              <IndustrySelect hint={false} disabled={disableBusinessLineAndBusinessNature} />
            </Form.Item>
            <Form.ShouldUpdate name={['companyType', 'businessNature']}>
              {(newForm) => {
                const formValues = newForm.getFieldsValue();
                const businessNature = formValues?.companyType?.businessNature;
                return (
                  <Form.Item
                    name={['companyType', 'businessIndustry']}
                    label={t('Business line')}
                    disabled={!businessNature || disableBusinessLineAndBusinessNature}
                  >
                    <BusinessLineSelect businessNature={businessNature} />
                  </Form.Item>
                );
              }}
            </Form.ShouldUpdate>
            <Form.Item
              label={t('Company license number')}
              name={n(['companyLicense', 'licenseNumber'])}
            >
              <LtrInput />
            </Form.Item>
            <Form.Item
              label={t('License authority')}
              name={n(['companyLicense', 'licenseAuthority'])}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('License issue date')}
              name={n(['companyLicense', 'licenseIssueDate'])}
            >
              <DateInput disableDateRule="date <= today" />
            </Form.Item>
            <Form.Item
              label={t('License expiry date')}
              name={n(['companyLicense', 'licenseExpiryDate'])}
            >
              <DateInput disableDateRule="date > today" />
            </Form.Item>
            {agent && <EmailFormItem label={t('Company email')} name={n(['email'])} />}
          </InputsGroupLayout>
        </Section>

        <Section formItemLast>
          <Typography.Title level={4}>
            {t('Tax registration certificate').toUpperCase()}
          </Typography.Title>
          <Form.Item name={taxCertEnabledNamePath} valuePropName="checked" initialValue={false}>
            <Checkbox>{t('Yes, I have a Tax registration certificate.')}</Checkbox>
          </Form.Item>
          <Form.Visible name={taxCertEnabledNamePath}>
            <Typography.Paragraph>
              {t('Please upload the Tax registration certificate photo.')}
            </Typography.Paragraph>
            <Form.Item name={n(['taxRegistrationCertificate', 'files'])}>
              <DocumentInput name="photos" variant="default" />
            </Form.Item>

            <Typography.Title level={5}>{t('Document data:')}</Typography.Title>
            <InputsGroupLayout>
              <Form.Item
                label={t('Tax registration number')}
                name={n(['taxRegistrationCertificate', 'number'])}
              >
                <Input />
              </Form.Item>
            </InputsGroupLayout>
          </Form.Visible>
        </Section>

        {showCompanyType && (
          <Form.Item name={['companyType', 'mcc']} hidden>
            <Input />
          </Form.Item>
        )}
        <CustomFields />
      </Form>
    </Container>
  );
};

const n = buildNamePath('companyInformation');

const taxCertEnabledNamePath = n(['taxRegistrationCertificate', 'enabled']);

const Container = styled.div`
  h4 {
    margin-bottom: 24px;
  }
  .CompanyInformation-Checkbox {
    margin-bottom: 16px;
  }
`;

export default CompanyInformation;
