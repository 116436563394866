import React, { FC } from 'react';
import { Space, SpaceProps } from 'antd';
import styled from 'styled-components';

import { DirectionProp } from '~/types/Direction';
import direction from '~/utils/direction';
import { useDirection } from '~/hooks/i18nHooks';

const Left: FC = (props) => {
  const { children } = props;
  const dir = useDirection();

  return (
    <SpaceStyled size={16} direction="horizontal" align="center" dir={dir}>
      {children}
    </SpaceStyled>
  );
};

type UIProps = SpaceProps & DirectionProp;

const SpaceStyled = styled<FC<UIProps>>(Space as any)`
  ${direction.margin.right(13)};
`;

export default Left;
