import { Col, Row } from 'antd';
import produce from 'immer';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AssigneeSelect, Form } from '~/components';
import StatusSelect from '~/components/inputs/StatusSelect';
import { usePath } from '~/hooks';
import { DashboardStatus, MapItemType } from '~/types/AgentMapItem';
import { isTeamLead } from '~/utils/getDecodedJwt';
import DashboardStatusInput from '../../../inputs/DashboardStatusInput';
import MapItemTypeInput from '../MapItemTypeInput';
import CreatedDataFromItem from '~/components/inputs/CreatedDataFromItem';
import CreatedDataToItem from '~/components/inputs/CreatedDataToItem';

const MapFilters: FC<any> = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation('agent');
  const { changePath, params } = usePath();

  const handleChange = (changed: any, values: Record<string, any>) => {
    let result = values;
    if (changed.queryType && result.filterStatus) {
      form.setFields([{ name: 'filterStatus', value: undefined }]);
      result = produce(result, (draft) => {
        draft.filterStatus = undefined;
      });
    }
    changePath({ filter: result });
  };

  return (
    <StyledForm
      form={form}
      // layout="inline"
      onValuesChange={handleChange}
      initialValues={params.filter}
      size="middle"
    >
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item name="queryType" initialValue={MapItemType.ALL}>
            <MapItemTypeInput />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="dashboardStatus" initialValue={DashboardStatus.ALL}>
            <DashboardStatusInput />
          </Form.Item>
        </Col>
        <Col>
          <Form.ShouldUpdate name="queryType">
            {() => {
              const queryType = form.getFieldValue('queryType') as MapItemType | undefined;
              return (
                <Form.Item label={t('Status')} name="filterStatus">
                  <StatusSelect allowClear statusType={queryType} />
                </Form.Item>
              );
            }}
          </Form.ShouldUpdate>
        </Col>

        <Col>
          <CreatedDataFromItem
            name="filterCreatedDateFrom"
            filterCreatedDateTo="filterCreatedDateTo"
            label={t('Created date from')}
          />
        </Col>

        <Col>
          <CreatedDataToItem
            filterCreatedDateFrom="filterCreatedDateFrom"
            name="filterCreatedDateTo"
            label={t('Created date to')}
          />
        </Col>

        {isTeamLead() && (
          <Col>
            <Form.Item label={t('Assignee')} name="filterAssignee">
              <AssigneeSelect allowClear />
            </Form.Item>
          </Col>
        )}
      </Row>
    </StyledForm>
  );
};

const StyledForm = styled(Form)`
  margin-top: 24px;

  .ant-form-item {
    min-width: 230px;
  }
`;

export default MapFilters;
