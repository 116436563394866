import React, { CSSProperties, FC } from 'react';
import { useHandbook } from '~/controllers/handbook';

interface Props {
  value: string | undefined;
}

const CountryField: FC<Props> = (props) => {
  const { value } = props;

  const handbook = useHandbook();
  const valuesRecord: Record<string, string> = {};
  handbook.data?.handbooks
    ?.find((item) => item.key === 'entitycountryofestablishment')
    ?.values?.forEach((country) => {
      if (country.apiValue && country.localizedValue) {
        valuesRecord[country.apiValue] = country.localizedValue;
      }
    });

  if (handbook.isLoading) return <span style={loadingStyle}>{value}</span>;
  return <>{value ? valuesRecord[value] || value : value}</>;
};

const loadingStyle: CSSProperties = { opacity: 0.3 };

export default React.memo(CountryField, (prev, next) => prev.value === next.value);
