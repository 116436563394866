/* eslint-disable react/no-array-index-key */
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import { useMediaQuery } from 'react-responsive';

import { applyMask } from '~/components/inputs/PhoneNumberInput';
import { useGetApplication } from '~/controllers/wizard';
import { Person } from '~/types/Person';
import longdash from '~/constants/longdash';
import theme from '~/constants/theme';
import Space from '~/components/Space';
import RepresentativeCardMobile from './RepresentativeCardMobile';
import Table from '~/components/Table';

const RepresentativesTab: FC = () => {
  const { t } = useTranslation('fillApplication');
  const columns: ColumnsType<RepresentativeRowDataType> = useMemo(
    () => [
      {
        title: t('Full name'),
        dataIndex: 'fullName',
        key: 'fullName',
      },
      {
        title: t('Designation'),
        dataIndex: 'designation',
        key: 'designation',
      },
      {
        title: t('Email'),
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: t('Phone number'),
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
        render: (phoneNumber) => <bdi>{phoneNumber}</bdi>,
      },
    ],
    [t]
  );

  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.sm });
  const applicationQuery = useGetApplication();
  if (!applicationQuery.data) return null;
  const { authorizedRepresentative } = applicationQuery.data.payload;
  const dataSource = convertData(authorizedRepresentative);

  return !isTablet ? (
    <Table
      id="reviewAndSubmitRepresentativesTable"
      dataSource={dataSource}
      columns={columns}
      pagination={pagination}
    />
  ) : (
    <Space size={16} direction="vertical">
      {dataSource?.map((item, index) => {
        return <RepresentativeCardMobile key={`RepresentativesTab_${index}`} {...item} />;
      })}
    </Space>
  );
};

const pagination = { hideOnSinglePage: true };

export type RepresentativeRowDataType = {
  fullName: string;
  designation: string;
  email: string;
  phoneNumber: string;
};

const convertData = (data: Person[] | undefined): RepresentativeRowDataType[] | undefined => {
  return data?.map((item) => {
    return {
      fullName: [item.firstName, item.lastName].join(' '),
      designation: item.proof?.designation || longdash,
      email: item.email || longdash,
      phoneNumber: applyMask(item.phoneNumber),
    };
  });
};

export default RepresentativesTab;
