import { AgentApplicationStatus } from './AgentApplication';

export enum MapItemType {
  APPLICATION = 'APPLICATION',
  LEAD = 'LEAD',
  ALL = 'ALL',
}

export enum DashboardStatus {
  OPENED = 'OPENED',
  DONE = 'DONE',
  ALL = 'ALL',
}

export type AgentMapItem = {
  uuid: string;
  type: MapItemType;
  dashboardStatus: DashboardStatus;
  status: string;
  statusKey: AgentApplicationStatus;
  companyName: string;
  addressLine: string;
  creationDate: string;
  updatedDate?: string;
  assignee?: {
    id: string;
    fullName: string;
    email: string;
  };
  geolocation: {
    longitude: number;
    latitude: number;
  };
};
