import React, { FC } from 'react';
import styled from 'styled-components';

import { Alert as AntdAlert, AlertProps } from 'antd';
import { useDirection } from '~/hooks/i18nHooks';
import { DirectionProp } from '~/types/Direction';
import direction from '~/utils/direction';

const Alert: FC<AlertProps> = (props) => {
  const dir = useDirection();
  return <StyledAlert dir={dir} {...props} />;
};

const StyledAlert = styled<FC<AlertProps & DirectionProp>>(AntdAlert)`
  padding: 9px 12px;
  .anticon {
    ${direction.margin.right(8)};
    ${direction.margin.left(0)};
  }
  .UnorderedList ul {
    padding-left: 0;
  }
`;

export default Alert;
