import React, { FC } from 'react';
import { SelectProps } from 'antd';
import { useTranslation } from 'react-i18next';

import Select from './Select';
import { DayName } from '~/types/DayName';

interface Props extends Omit<SelectProps<any>, 'options'> {
  firstDayOfWeek?: 'MO' | 'SU';
  disableDays?: Record<DayName, boolean>;
}

const DaySelect: FC<Props> = (props) => {
  const { firstDayOfWeek, disableDays } = props;
  const { t } = useTranslation();

  return (
    <Select
      options={getDaysOfWeek(firstDayOfWeek).map((d) => ({
        label: t(d),
        value: d,
        disabled: disableDays ? !!disableDays[d] : undefined,
      }))}
      {...props}
    />
  );
};

const getDaysOfWeek = (firstDayOfWeek: 'MO' | 'SU' | undefined): DayName[] => {
  const [su, weekdays, sa] = [['SU'], ['MO', 'TU', 'WE', 'TH', 'FR'], ['SA']] as const;
  return firstDayOfWeek === 'MO' ? [...weekdays, ...sa, ...su] : [...su, ...weekdays, ...sa];
};

export default DaySelect;
