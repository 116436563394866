import FileSaver from 'file-saver';
import { useMutation } from 'react-query';

import {
  downloadContractUnderReview,
  generateContract,
  sendInvitation,
  uploadSignedContract,
} from '~/api/contract';

export const useGenerateContract = () => {
  return useMutation(generateContract);
};

export const useUploadSignedContract = () => {
  return useMutation(uploadSignedContract);
};

export const useSendInvitation = () => {
  return useMutation(sendInvitation);
};

export const useDownloadContractUnderReview = () => {
  const mutation = useMutation(downloadContractUnderReview);

  const download = async () => {
    const { blob, name } = await mutation.mutateAsync();
    FileSaver.saveAs(blob, name);
  };

  return { ...mutation, download };
};
