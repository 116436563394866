export default {
  // Completed
  'Welcome to the Network Family!': 'Welcome to the Network Family!',
  "You're now fully set up to take payments through Network International.":
    "You're now fully set up to take payments through Network International.",
  'If you wish to discuss any aspects of your services, please contact us via the details within your welcome kit email.':
    'If you wish to discuss any aspects of your services, please contact us via the details within your welcome kit email.',
  // Error
  'Sorry, something went wrong': 'Sorry, something went wrong',
  'A technical error has occurred. Please re-submit your application.':
    'A technical error has occurred. Please re-submit your application.',
  'Try again': 'Try again',
  // Salesforce error
  'Application submission error': 'Application submission error',
  'There is a problem with application data.': 'There is a problem with application data.',
  'There is a problem with application data, please <1>contact us</1> to solve it.':
    'There is a problem with application data, please <1>contact us</1> to solve it.',
  'Please <1>contact us</1> to get support and continue onboarding.':
    'Please <1>contact us</1> to get support and continue onboarding.',
  "We're sorry to say that your application has not been successful this time":
    "We're sorry to say that your application has not been successful this time",
  'Sorry, we have a technical problem right now, please try again later or <1>contact us</1>.':
    'Sorry, we have a technical problem right now, please try again later or <1>contact us</1>.',
  'Sorry, we have a technical problem right now, please try again later.':
    'Sorry, we have a technical problem right now, please try again later.',
  'Go back to change the application data': 'Go back to change the application data',
  // Salesforce soft decline
  'We need more information': 'We need more information',
  'Thank you for your application. We have reviewed the information submitted to us and require some clarifications.':
    'Thank you for your application. We have reviewed the information submitted to us and require some clarifications.',
  "We request you to select 'Update Application' below and review the following details which you provided:":
    "We request you to select 'Update Application' below and review the following details which you provided:",
  "We'll call you back soon for further information.":
    "We'll call you back soon for further information.",
  'Update Application': 'Update Application',
  'Please contact us via our <1>Contact us page</1> if you need any support or have any questions.':
    'Please contact us via our <1>Contact us page</1> if you need any support or have any questions.',
  // Screening error
  'Screening in progress': 'Screening in progress',
  'We need a little more time to review all individuals you have listed. We will notify you by email to proceed with the application.':
    'We need a little more time to review all individuals you have listed. We will notify you by email to proceed with the application.',
  'In the meantime, you can head to our website to view more about our <1>products and services</1>.':
    'In the meantime, you can head to our website to view more about our <1>products and services</1>.',
  'If you require any support whilst you wait, please <1>contact us</1>.':
    'If you require any support whilst you wait, please <1>contact us</1>.',
  // Screening reject
  'We are sorry to say that your application has not been successful this time':
    'We are sorry to say that your application has not been successful this time',
  'If you wish to discuss with us, please <1>contact us</1>.':
    'If you wish to discuss with us, please <1>contact us</1>.',
  // Submit
  'Your application {{salesforceApplicationNumber}} is under review':
    'Your application {{salesforceApplicationNumber}} is under review',
  'We aim to review all applications within 2 working days and we will notify you once your application has been approved.':
    'We aim to review all applications within 2 working days and we will notify you once your application has been approved.',
  'Application details': 'Application details',
  // Successful onboarding
  'Application approved!': 'Application approved!',
  'Welcome to the Network family.': 'Welcome to the Network family.',
  'We will soon be sending you our welcome kit which will explain the next steps to enable you to start taking payments.':
    "'We will soon be sending you our welcome kit which will explain the next steps to enable you to start taking payments.'",
  'In the meantime, if you wish to speak to us, please <1>contact us</1>.':
    'In the meantime, if you wish to speak to us, please <1>contact us</1>.',
};
