import React, { FC } from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import { useCommonErrors } from '~/controllers/validation';
import { useGetApplication } from '~/controllers/wizard';
import { Alert, Space, UnorderedList } from '~/components';
import { WarningCircleIcon } from '~/img';
import { Stage } from '~/types/Application';

interface Props {
  formName?: string;
}

const ApplicationCommonErrors: FC<Props> = (props) => {
  const { formName } = props;
  const { t } = useTranslation();

  const applicationQuery = useGetApplication();
  const resubmitMessagesRaw =
    (applicationQuery.data?.stage === Stage.fillApplication
      ? applicationQuery.data.stagePayload.activeStep.errorInformation?.errorMessages
      : applicationQuery.data?.stage === Stage.contract
      ? applicationQuery.data?.errorInformation?.errorMessages
      : applicationQuery.data?.stage === Stage.payment
      ? applicationQuery.data?.errorInformation?.errorMessages
      : undefined) || [];
  const resubmitMessages = resubmitMessagesRaw.filter(Boolean);
  const hasErrorMessage = !!resubmitMessages.length;

  const location = useLocation();
  const resultFormName = formName || location.pathname;

  const [commonErrors] = useCommonErrors(resultFormName);

  return (
    <>
      {(commonErrors?.[resultFormName] || hasErrorMessage) && (
        <Space direction="vertical" size={8}>
          {hasErrorMessage && (
            <Alert
              message={t('Please, fix application data')}
              description={
                resubmitMessages.length === 1 ? (
                  resubmitMessages[0]
                ) : (
                  <UnorderedList values={resubmitMessages} />
                )
              }
              type="warning"
              icon={<WarningCircleIcon />}
              showIcon
            />
          )}
          {commonErrors[resultFormName]?.map((item) => {
            const isNormalUseCase = item.name[0] === 'COMMON';
            const description = isNormalUseCase
              ? item.errors[0]
              : `${item.name.join('.')}: ${item.errors[0]}`;

            if (hasErrorMessage && item.name[0] === 'shareholders') return null; // shareholders screen has form items in drawer and they are hidden on submit by design.
            // Line above is prevent redundant warnings on submit during resubmit
            return (
              // item.errors has only one element. Array has used for Antd Forms compatibility reasons
              <Alert
                key={item.name.join('.')}
                message={isNormalUseCase ? t('Please, fix application data') : 'Internal warning'}
                description={description}
                type="warning"
                icon={<WarningCircleIcon />}
                showIcon
              />
            );
          })}
        </Space>
      )}
    </>
  );
};

export default ApplicationCommonErrors;
