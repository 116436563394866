import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import { TotalPrice } from '~/components';
import theme from '~/constants/theme';
import { useDraftIsStale } from '~/controllers/draft';
import { useGetServices } from '~/controllers/pricing';
import { useTotalPriceState } from '~/controllers/totalPrice';

const ServicesTotalPrice: FC = () => {
  const { isStale } = useDraftIsStale();
  const [totalPriceState] = useTotalPriceState();
  const pricing = totalPriceState?.data?.pricing;
  const isMobile = useMediaQuery({ maxWidth: theme.breakpoints.sm });
  const servicesQuery = useGetServices();

  const pricingRequestStatus = servicesQuery.data?.pricingRequest?.status;

  if (isMobile) return null;
  return (
    <TotalPrice
      pricing={pricing}
      isLoading={totalPriceState.isLoading}
      isStale={isStale}
      isError={totalPriceState.isError}
      requiresRecalculate={totalPriceState.requiresRecalculate}
      pricingRequestStatus={pricingRequestStatus}
    />
  );
};

export default ServicesTotalPrice;
