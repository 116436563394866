import React, { FC } from 'react';
import styled from 'styled-components';

import { Hint, SpaceBetweenField } from '.';
import colors from '~/constants/colors';
import { DirectionProp } from '~/types/Direction';
import direction from '~/utils/direction';
import { useDirection } from '~/hooks/i18nHooks';
import { CheckmarkIcon, CloseSharpIcon } from '~/img';

export interface PriceRowBaseProps {
  title: string | JSX.Element;
  hint?: string | null;
  description?: string | null;
  withCheckmark?: boolean;
  withCross?: boolean;
  disabled?: boolean;
}

const PriceRowBase: FC<PriceRowBaseProps> = (props) => {
  const { title, hint, description, withCheckmark, withCross, disabled = false, children } = props;
  const dir = useDirection();

  return (
    <SpaceBetweenFieldStyled disabled={disabled} dir={dir}>
      <SpaceBetweenField.Left>
        <div className="PriceRowBase-Title">
          {withCheckmark && <CheckmarkIcon className="PriceRowBase-CheckmarkIcon" />}
          {withCross && <CloseSharpIcon className="PriceRowBase-CloseSharpIcon" />}
          {title}
        </div>
        {hint && <Hint text={hint} />}
      </SpaceBetweenField.Left>
      <SpaceBetweenField.Right>
        {description && <div className="PriceRowBase-Description">{description}</div>}
        {children}
      </SpaceBetweenField.Right>
    </SpaceBetweenFieldStyled>
  );
};

interface UIProps extends DirectionProp {
  disabled: boolean;
}

const SpaceBetweenFieldStyled = styled<FC<UIProps>>(SpaceBetweenField as any)`
  .PriceRowBase-CheckmarkIcon {
    color: ${colors.successColor};
    font-size: 16px;
    ${direction.margin.right(8)};
  }
  .PriceRowBase-CloseSharpIcon {
    color: ${colors.disabledBlueTextColor};
    font-size: 16px;
    ${direction.margin.right(8)};
  }
  .PriceRowBase-Description {
    font-size: 12px;
  }
  color: ${({ disabled }) => (disabled ? colors.disabledBlueTextColor : undefined)};
`;

export default PriceRowBase;
