import { Spin } from 'antd';
import React, { FC } from 'react';
import FormDrawer from '~/components/FormDrawer';
import { LeadShowConverted } from '~/components/views/agent/leadForm/LeadShowConverted';
import { LeadShowOpen } from '~/components/views/agent/leadForm/LeadShowOpen';
import { LeadShowTerminated } from '~/components/views/agent/leadForm/LeadShowTerminated';
import { AgentLead } from '~/types/AgentLead';

interface Props {
  open: boolean;
  onClose: () => void;
  initialValues?: AgentLead;
}

const LeadShow: FC<Props> = (props) => {
  const { initialValues, open, onClose } = props;

  if (initialValues?.statusKey === 'NEW' || initialValues?.statusKey === 'CONTACTED')
    return <LeadShowOpen open={open} initialValues={initialValues} onClose={onClose} />;
  if (initialValues?.statusKey === 'TERMINATED')
    return <LeadShowTerminated open={open} initialValues={initialValues} onClose={onClose} />;
  if (initialValues?.statusKey === 'CONVERTED')
    return <LeadShowConverted open={open} initialValues={initialValues} onClose={onClose} />;

  return (
    <FormDrawer
      id="loadingLeadDrawer"
      visible={open}
      onClose={onClose}
      closable
      withContinueButton={false}
    >
      <Spin />
    </FormDrawer>
  );
};

export default LeadShow;
