import axios from '~/api/axios';
import { AgentApplicationStatus } from '~/types/AgentApplication';
import { AgentMapItem } from '~/types/AgentMapItem';

export interface GetMapItemsQueryParams {
  firstLatitude: number;
  firstLongitude: number;
  secondLatitude: number;
  secondLongitude: number;
  dashboardStatus?: 'OPENED' | 'DONE' | 'ALL';
  queryType?: 'APPLICATION' | 'LEAD' | 'ALL';
  filterStatus?: AgentApplicationStatus;
  filterAssignee?: string;
  filterDate?: string;
  filterCompanyOrAddress?: string;
}

export const getMapItemsQuerySignal =
  (signal: AbortSignal | undefined) => (params: GetMapItemsQueryParams) => {
    return axios
      .get<AgentMapItem[]>('/agent/queryMap', {
        params,
        signal,
      })
      .then((res) => res.data);
  };
