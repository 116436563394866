import React, { FC, useCallback, useEffect } from 'react';
import { FormInstance, Input, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { get } from 'lodash';
import produce from 'immer';
import styled from 'styled-components';

import {
  Checkbox,
  DateInput,
  Form,
  DocumentInput,
  PhoneNumberFormItem,
  CountryInput,
  Select,
  EmiratesIdInput,
  NationalitySelect,
} from '~/components';
import InputsGroupLayout from '~/layouts/InputsGroupLayout';
import Section from '~/layouts/Section ';
import { Person } from '~/types/Person';
import createUid from '~/utils/createUid';
import EmailFormItem from '../../inputs/EmailFormItem';
import theme from '~/constants/theme';
import AuthorizedRepresentativeSendInvitation from './AuthorizedRepresentativeSendInvitation';
import { AuthorizedRepresentativeSendInvitationPayload } from '~/types/AuthorizedRepresentativeSendInvitationPayload';
import { getDecodedJwt, isAgent, isUaePassFromUAE } from '~/utils/getDecodedJwt';
import i18n from '~/i18n';
import { useHandbook } from '~/controllers/handbook';
import CustomFields from '~/components/CustomFields';
import { NamePath } from '~/utils/buildNamePath';

interface Props {
  name: string | (string | number)[];
  form: FormInstance;
  defaultIsAuthorizedSignatory?: boolean;
  withFiles?: boolean;
  withProof?: boolean;
  withAgentSendInvitation?: boolean;
  withUaePass?: boolean;
  withCustomFields?: boolean;
}

const AuthorizedRepresentativeFormItems: FC<Props> = (props) => {
  const {
    name,
    form,
    defaultIsAuthorizedSignatory,
    withFiles = true,
    withProof = true,
    withAgentSendInvitation = false,
    withUaePass = false,
    withCustomFields = true,
  } = props;
  const { t } = useTranslation('fillApplication');
  const fromUAE = isUaePassFromUAE();

  useEffect(() => {
    setTimeout(() => {
      const formValues = form.getFieldsValue();
      const initialPerson: Person = get(formValues, name) || {};
      const patchedPerson = produce<Person>(initialPerson, (draft) => {
        if (!draft.uid) draft.uid = createUid();
        if (draft.isAuthorizedSignatory === undefined) {
          draft.isAuthorizedSignatory = !!defaultIsAuthorizedSignatory;
        }
        if (draft.isPep === undefined) draft.isPep = false;
      });
      form.setFields([{ name, value: patchedPerson }]);
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const n = useCallback((namePath: NamePath) => [name].flat().concat([namePath].flat()), [name]);

  const handbook = useHandbook();
  const decodedToken = getDecodedJwt();
  const uaePassNationality = handbook.data?.handbooks
    ?.find((item) => item.key === 'nationality')
    ?.values?.find(
      (item) =>
        item?.additionalParams?.uaepass &&
        decodedToken?.uaepass_nationality_en &&
        item?.additionalParams?.uaepass === decodedToken?.uaepass_nationality_en
    )?.apiValue;

  useEffect(() => {
    setTimeout(() => {
      if (withUaePass && uaePassNationality) {
        form.setFieldsValue(
          produce(form.getFieldsValue(), (draft: any) => {
            draft.authorizedRepresentative[0].nationality = uaePassNationality;
          })
        );
      }
    }, 0);
  }, [uaePassNationality, form, withUaePass]);

  return (
    <Container>
      <Form.Item name={n(['uid'])} hidden />
      <Section>
        <Typography.Title level={4}>{t('Personal data').toUpperCase()}</Typography.Title>
        <InputsGroupLayout>
          <Form.Item
            label={t('First name')}
            name={n(authorizedRepresentativeCommonNames.firstName)}
            forceDisabled={withUaePass}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('Last name')}
            name={n(authorizedRepresentativeCommonNames.lastName)}
            forceDisabled={withUaePass}
          >
            <Input />
          </Form.Item>
          <Form.Item label={t('Date of birth')} name={n(['birthDate'])}>
            <DateInput disableDateRule="date <= today" />
          </Form.Item>
          <Form.Item
            label={t('Nationality')}
            name={n(['nationality'])}
            initialValue={withUaePass ? uaePassNationality : undefined}
            forceDisabled={withUaePass}
          >
            <NationalitySelect />
          </Form.Item>
        </InputsGroupLayout>
        <Form.Item name={n(['isPep'])} valuePropName="checked" noStyle>
          <Checkbox>{t('Shareholder is a Politically Exposed Person (PEP)')}</Checkbox>
        </Form.Item>
        {defaultIsAuthorizedSignatory ? (
          <Form.Item name={n(['isAuthorizedSignatory'])} valuePropName="checked" noStyle />
        ) : (
          <Form.Item
            name={n(['isAuthorizedSignatory'])}
            valuePropName="checked"
            forceDisabled={withUaePass}
          >
            <Checkbox>{t('This person is authorised signatory')}</Checkbox>
          </Form.Item>
        )}
      </Section>

      <Section>
        <Typography.Title level={4}>{t('Passport').toUpperCase()}</Typography.Title>

        {withFiles && (
          <>
            <Typography.Paragraph>
              <Trans
                t={t}
                defaults="Please upload the <strong>passport main page</strong> photo and provide the details in the fields below."
              />
            </Typography.Paragraph>
            <Form.Item name={n([passport, 'files'])} forceDisabled={withUaePass && fromUAE}>
              <DocumentInput name="photos" variant="default" multiple={false} />
            </Form.Item>
          </>
        )}

        {withFiles && (
          <>
            <Typography.Paragraph>
              <Trans
                t={t}
                defaults="Please upload the <strong>visa page</strong> photo and provide the details in the fields below."
              />
            </Typography.Paragraph>
            <Form.Item name={n(['visa', 'files'])} forceDisabled={withUaePass && !fromUAE}>
              <DocumentInput name="photos" variant="default" multiple={false} />
            </Form.Item>
          </>
        )}

        <Typography.Title level={5}>{t('Document data:')}</Typography.Title>
        <InputsGroupLayout>
          <Form.Item
            label={t('Document number')}
            name={n([passport, 'documentNumber'])}
            forceDisabled={withUaePass && fromUAE}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('Passport expiry date')}
            name={n([passport, 'expiryDate'])}
            forceDisabled={withUaePass && fromUAE}
          >
            <DateInput disableDateRule="date > today" />
          </Form.Item>
          <Form.Item
            label={t('Visa expiry date')}
            name={n(['visa', 'expiryDate'])}
            forceDisabled={withUaePass && !fromUAE}
          >
            <DateInput disableDateRule="date > today" />
          </Form.Item>
        </InputsGroupLayout>
      </Section>

      <Section formItemLast>
        <Typography.Title level={4}>{t('Emirates ID').toUpperCase()}</Typography.Title>

        {withFiles && (
          <>
            <Typography.Paragraph>
              <Trans
                t={t}
                defaults="Please upload <strong>front side</strong> and <strong>back side</strong> Emirates ID photos."
              />
            </Typography.Paragraph>
            <Form.Item name={n([emiratesId, 'files'])} forceDisabled={withUaePass}>
              <DocumentInput name="photos" variant="default" maxCount={2} />
            </Form.Item>
          </>
        )}

        <Typography.Title level={5}>{t('Document data:')}</Typography.Title>
        <InputsGroupLayout>
          <Form.Item
            label={t('Emirates ID number')}
            name={n([emiratesId, 'documentNumber'])}
            forceDisabled={withUaePass}
          >
            <EmiratesIdInput />
          </Form.Item>
          <Form.Item
            label={t('Emirates ID expiry date')}
            name={n([emiratesId, 'expiryDate'])}
            forceDisabled={withUaePass}
          >
            <DateInput disableDateRule="date > today" />
          </Form.Item>
        </InputsGroupLayout>
      </Section>

      <Section formItemLast>
        <Typography.Title level={4}>
          {t('Residency & contact details').toUpperCase()}
        </Typography.Title>
        <InputsGroupLayout>
          <Form.Item label={t('Country')} name={n(['country'])}>
            <CountryInput />
          </Form.Item>
          <Form.Item label={t('City')} name={n(['city'])}>
            <Input autoComplete="none" />
          </Form.Item>
          <Form.Item label={t('Address line')} name={n(['addressLine'])}>
            <Input autoComplete="none" />
          </Form.Item>
          <Form.Item label={t('P.O. Box')} name={n(['poBox'])}>
            <Input autoComplete="none" />
          </Form.Item>
          <EmailFormItem name={n(['email'])} forceDisabled={withUaePass} />
          <PhoneNumberFormItem name={n(['phoneNumber'])} forceDisabled={withUaePass} />
        </InputsGroupLayout>
      </Section>

      {withProof && (
        <Form.Visible name={n(['isAuthorizedSignatory'])}>
          <Section formItemLast>
            <Typography.Title level={4}>
              {t('Authorised Signatory Proof').toUpperCase()}
            </Typography.Title>
            <InputsGroupLayout>
              <Form.Item
                label={t('Designation')}
                name={n(authorizedRepresentativeCommonNames.designation)}
              >
                <Input />
              </Form.Item>
            </InputsGroupLayout>
            <Form.Item label={t('Select the document type')} name={n([proof, 'documentType'])}>
              <Select
                className="AuthorizedRepresentativeFormItems-DocumentTypeSelect"
                options={getProofOptions()}
              />
            </Form.Item>
            {withFiles && (
              <Form.Visible name={n([proof, 'documentType'])} visibleIf={uploadPowerOfAttorney}>
                <Form.Item name={n([proof, 'files'])}>
                  <DocumentInput name="photos" variant="default" />
                </Form.Item>
              </Form.Visible>
            )}
          </Section>
        </Form.Visible>
      )}
      {isAgent() && withAgentSendInvitation && (
        <Section>
          <AuthorizedRepresentativeSendInvitation
            form={form}
            getPayload={getSendInvitationPayload(() => {
              const formValues = form.getFieldsValue();
              const person: Person = get(formValues, name) || {};
              return person;
            })}
          />
        </Section>
      )}
      {!!withCustomFields && <CustomFields nFn={n} />}
    </Container>
  );
};

const getSendInvitationPayload =
  (getPerson: () => Person) => (): Partial<AuthorizedRepresentativeSendInvitationPayload> => {
    const { email, phoneNumber, firstName, lastName } = getPerson();
    return { email, phoneNumber, firstName, lastName };
  };

const passport = 'passport';
const emiratesId = 'emiratesId';
const proof = 'proof';

export const authorizedRepresentativeCommonNames = {
  firstName: ['firstName'],
  lastName: ['lastName'],
  passport: [passport],
  emiratesId: [emiratesId],
  designation: [proof, 'designation'],
};

export const uploadPowerOfAttorney =
  'Upload a copy of the Power of Attorney showing this person as an authorised signatory';
const getProofOptions = () => [
  {
    label: i18n.t(
      'This person is listed on the Company License as part of the company ownership structure',
      { ns: 'fillApplication' }
    ),
    value:
      'This person is listed on the Company License as part of the company ownership structure',
  },
  {
    label: i18n.t(uploadPowerOfAttorney, { ns: 'fillApplication' }),
    value: uploadPowerOfAttorney,
  },
];

const Container = styled.div`
  @media (max-width: ${theme.breakpoints.md}px) {
    .AuthorizedRepresentativeFormItems-DocumentTypeSelect {
      max-width: calc(100vw - ${32 * 2}px);
    }
  }
  @media (max-width: ${theme.breakpoints.sm}px) {
    .AuthorizedRepresentativeFormItems-DocumentTypeSelect {
      max-width: calc(100vw - ${12 * 2 + 12 * 2}px);
    }
  }
`;

export default AuthorizedRepresentativeFormItems;
