import React, { FC } from 'react';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import colors from '~/constants/colors';
import theme from '~/constants/theme';
import { PlusIcon } from '~/img';
import OptionalLabel from './OptionalLabel';

interface Props {
  loading: boolean;
  optional?: boolean;
}

const DocumentInputContentMobile: FC<Props> = (props) => {
  const { loading, optional } = props;
  const { t } = useTranslation();
  return (
    <Container className="DocumentInputContentMobile">
      {loading ? (
        <Spin />
      ) : (
        <>
          <PlusIcon className="DocumentInputContentMobile-PlusIcon" />
          <span className="DocumentInputContentMobile-Text">{t('Upload')}</span>
          {optional && <OptionalLabel />}
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px;
  height: 64px;
  border: 1px solid ${colors.borderColorBase};
  border-radius: ${theme.borderRadiusBase}px;
  background: #fbfbfb;
  cursor: pointer;
  transition: opacity 0.3s ease;
  &:hover {
    opacity: 0.5;
  }
  .DocumentInputContentMobile-PlusIcon {
    font-size: 16px;
  }
  .DocumentInputContentMobile-Text {
    margin: 0 14px;
    font-size: 14px;
  }
`;

export default DocumentInputContentMobile;
