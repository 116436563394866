import cx from 'classnames';
import React, { FC } from 'react';
import styled from 'styled-components';
import { useDirection } from '~/hooks/i18nHooks';
import { DirectionProp } from '~/types/Direction';
import direction from '~/utils/direction';

import Hint, { HintProps } from './Hint';

interface Props extends HintProps {
  children: JSX.Element;
}

const TextHint: FC<Props> = (props) => {
  const { children, ...hintProps } = props;
  const { children: childrenText, className, ...restChildrenProps } = children.props;
  const dir = useDirection();

  return (
    <Container className="TextHint" dir={dir}>
      <children.type className={cx('TextHint-Children', className)} {...restChildrenProps}>
        {childrenText}
        <Hint {...hintProps} />
      </children.type>
    </Container>
  );
};

const Container = styled.div<DirectionProp>`
  .TextHint-Children {
    display: flex;
    align-items: center;
    .Hint {
      ${direction.margin.left(8)};
    }
  }
`;

export default TextHint;
