import React, { FC } from 'react';
import { SelectProps } from 'antd';
import { useTranslation } from 'react-i18next';
import Select from './Select';

const leadOptions = [
  { title: 'Company', sortingDataKey: 'companyName' },
  { title: 'Emirate', sortingDataKey: 'emirate' },
  { title: 'Risk level', sortingDataKey: 'riskLevel' },
  { title: 'Business line', sortingDataKey: 'businessLine' },
  { title: 'Created', sortingDataKey: 'created' },
  { title: 'Updated', sortingDataKey: 'updated' },
  { title: 'Status', sortingDataKey: 'status' },
];

const applicationOptions = [
  { title: 'Company', sortingDataKey: 'companyName' },
  { title: 'Emirate', sortingDataKey: 'emirate' },
  { title: 'Risk level', sortingDataKey: 'riskLevel' },
  { title: 'Business line', sortingDataKey: 'businessLine' },
  { title: 'Created', sortingDataKey: 'created' },
  { title: 'Updated', sortingDataKey: 'updated' },
  { title: 'Request', sortingDataKey: 'pricingRequestStatus' },
  { title: 'Status', sortingDataKey: 'status' },
];

const teamLeadOptions = [{ title: 'Assignee', sortingDataKey: 'assignee' }];

const SortingSelect: FC<SelectProps<any> & { type: string; isTeamLead: boolean }> = (props) => {
  const { type, isTeamLead, ...rest } = props;
  const { t } = useTranslation('agent');
  const options = (
    type === 'leads' ? leadOptions : type === 'applications' ? applicationOptions : []
  )
    .concat(isTeamLead ? teamLeadOptions : [])
    .map(({ title, sortingDataKey }) => [
      {
        label: `↑ ${t(title)}`,
        value: `${sortingDataKey}-asc`,
        key: `${sortingDataKey}-asc`,
      },
      {
        label: `↓ ${t(title)}`,
        value: `${sortingDataKey}-desc`,
        key: `${sortingDataKey}-desc`,
      },
    ])
    .flat();

  return <Select options={options} {...rest} />;
};

export default SortingSelect;
