import React, { FC } from 'react';
import { PrimitiveFieldMap, SchemaServiceField } from '~/types/Services';
import { NameFn } from '~/utils/buildNamePath';
import PaymentSchemaField from './PaymentSchemaField';
import PaymentSchemaFormItem from './PaymentSchemaFormItem';
import { isEditable } from './utils';

interface Props {
  n: NameFn;
  field: SchemaServiceField;
  fieldMap: PrimitiveFieldMap;
}

const PaymentSchemaResolver: FC<Props> = (props) => {
  const { n, field, fieldMap } = props;
  const isInput = isEditable(field);
  if (isInput) return <PaymentSchemaFormItem n={n} field={field} />;
  return <PaymentSchemaField field={field} fieldMap={fieldMap} />;
};

export default PaymentSchemaResolver;
