import React, { FC, ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';

import colors from '~/constants/colors';
import theme from '~/constants/theme';
import { LogoWithTextImg, LogoImg, LogoWithTextArImg } from '~/img';
import paths from '~/pages/paths';
import HeaderIdentityMenu from './HeaderIdentityMenu';
import HeaderBurgerMenu from './HeaderBurgerMenu';
import { Space } from '~/components';
import { isAgent } from '~/utils/getDecodedJwt';
import HeaderCompanyName from './HeaderCompanyName';
import { useHeaderHeight } from '~/hooks/useHeaderHeight';
import direction from '~/utils/direction';
import { useDirection, useSetLanguage } from '~/hooks/i18nHooks';
import { Direction } from '~/types/Direction';
import { ImgProps } from '~/img/utils';

interface Props {
  middleContent?: ReactNode;
  tabletContent?: ReactNode;
}

const Header: FC<Props> = (props) => {
  const { middleContent, tabletContent } = props;
  const history = useHistory();
  const dir = useDirection();

  const goHome = () => {
    if (process.env.NODE_ENV === 'development') {
      history.push(paths.home._);
    }
  };

  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.md });
  const isMobile = useMediaQuery({ maxWidth: theme.breakpoints.sm });
  const middleContentBottom = useMediaQuery({
    maxWidth: isAgent() ? theme.breakpoints.md : theme.breakpoints.sm,
  });
  const headerHeight = useHeaderHeight({ wizardHeader: !!middleContent });

  return (
    <HeaderContainer
      className="Header"
      headerHeight={headerHeight}
      dir={dir}
      middleContentBottom={middleContentBottom}
    >
      <div className="Header-Content">
        <div className="Header-LeftContainer Header-CornerContainer">
          {isTablet || isAgent() ? (
            <LogoWithI18n className="Header-LogoImg" onClick={goHome} />
          ) : (
            <LogoWithTextWithI18n className="Header-LogoWithTextImg" onClick={goHome} />
          )}
          {isAgent() && !isMobile && <HeaderCompanyName />}
        </div>
        {middleContentBottom ? null : middleContent}
        {isTablet ? (
          <div className="Header-RightContainer Header-CornerContainer">
            <Space size={24} fullWidth={false}>
              {isMobile ? tabletContent : null}
              <HeaderBurgerMenu />
            </Space>
          </div>
        ) : (
          <div className="Header-RightContainer Header-CornerContainer">
            <HeaderIdentityMenu />
          </div>
        )}
      </div>
      {middleContentBottom && middleContent ? (
        <div className="Header-MiddleContent__mobile">{middleContent}</div>
      ) : null}
    </HeaderContainer>
  );
};

const LogoWithTextWithI18n = (props: ImgProps) => {
  const { language } = useSetLanguage();
  return language === 'ar' ? <LogoWithTextArImg {...props} /> : <LogoWithTextImg {...props} />;
};

const LogoWithI18n = (props: ImgProps) => {
  const { language } = useSetLanguage();
  return language === 'ar' ? (
    <LogoWithTextArImg style={{ height: 25 }} {...props} />
  ) : (
    <LogoImg {...props} />
  );
};

interface UIProps {
  headerHeight: number;
  dir: Direction;
  middleContentBottom: boolean;
}

const HeaderContainer = styled.header<UIProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  padding: 16px;
  top: 0;
  z-index: 10;
  background-color: ${colors.layoutBodyBackground};
  // box-shadow applies by index.less
  transition: box-shadow 0.2s ease-out;
  height: ${({ headerHeight }) => headerHeight}px;
  .Header-Content {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: ${theme.breakpoints.xl}px;
    .Header-CornerContainer {
      display: flex;
      flex: 1;
      .HeaderCompanyName-LegalName {
        max-width: ${320 - (16 + 58)}px;
      }
    }
    .Header-RightContainer {
      justify-content: flex-end;
    }
    .Header-LeftContainer {
      display: flex;
      align-items: center;
      .HeaderCompanyName {
        ${direction.margin.left(21)};
      }
      .Header-LogoWithTextImg {
        height: ${theme.logoWithTextHeight}px;
      }
      .Header-LogoImg {
        height: ${theme.logoHeight}px;
      }
    }
  }
  .Header-BurgerMenu {
    color: ${colors.primaryColor};
    font-size: 36px;
    cursor: pointer;
  }
  .Header-MiddleContent__mobile {
    margin-top: 16px;
  }
  @media (max-width: 1480px) {
    .Header-Content {
      .Header-CornerContainer {
        .HeaderCompanyName-LegalName {
          max-width: calc(24vw - ${52 + 21 + 48}px);
        }
      }
    }
  }
  @media (max-width: ${theme.breakpoints.lg}px) {
    .Header-Content {
      .Header-CornerContainer {
        .HeaderCompanyName-LegalName {
          max-width: calc(31vw - ${52 + 21 + 48}px);
        }
      }
    }
  }
  @media (max-width: ${theme.breakpoints.md}px) {
    .Header-Content {
      .Header-CornerContainer {
        .HeaderCompanyName-LegalName {
          max-width: calc(100vw - ${32 + 52 + 21 + 200}px);
        }
      }
    }
  }
  @media (max-width: ${theme.breakpoints.md}px) {
    .Header-Content {
      .Header-CornerContainer {
        .HeaderCompanyName-LegalName {
          max-width: calc(100vw - ${32 + 52 + 21 + 200}px);
        }
      }
    }
  }
  ${({ middleContentBottom }) =>
    middleContentBottom
      ? css`
          .Header-Content {
            .Header-CornerContainer {
              flex: unset;
            }
          }
        `
      : undefined}
`;

export default Header;
