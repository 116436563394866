import { Typography } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';
import ShareholdersMultiple from '~/components/views/ShareholdersMultiple';
import ShareholdersSoleProprietor from '~/components/views/ShareholdersSoleProprietor';
import { useGetApplication } from '~/controllers/wizard';
import { getStagePayload } from '~/utils/getStagePayload';

const Shareholders: FC = () => {
  const applicationQuery = useGetApplication();
  const stagePayload = getStagePayload.wizard(applicationQuery.data);
  const activeStep = stagePayload?.activeStep;

  return (
    <Container>
      <Typography.Title>{activeStep?.stepName}</Typography.Title>
      {activeStep?.view === 'soleProprietorShareholders' ? (
        <ShareholdersSoleProprietor />
      ) : activeStep?.view === 'multipleShareholders' ? (
        <ShareholdersMultiple />
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  h4 {
    margin-bottom: 24px;
  }
  section {
    margin-top: 48px;
  }
`;

export default Shareholders;
