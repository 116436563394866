import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

import theme from '~/constants/theme';
import { useApplicationStageStatus } from '~/controllers/applicationStageStatus';
import { useGetApplication, useSelectApplicationStage } from '~/controllers/wizard';
import { useDirection } from '~/hooks/i18nHooks';
import {
  ApplicationIcon,
  ArrowRightIcon,
  PaymentIcon,
  ServicesIcon,
  ContractIcon,
  CompletionIcon,
} from '~/img';
import paths from '~/pages/paths';
import { Stage } from '~/types/Application';
import { DirectionProp } from '~/types/Direction';
import { isAgent } from '~/utils/getDecodedJwt';
import ApplicationNavigation from './ApplicationNavigation';
import WizardNavStep, { WizardNavStepProps } from './WizardNavStep';

const WizardTopNavigation: FC = () => {
  const { t } = useTranslation();
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.lg });
  const dir = useDirection();
  const applicationQuery = useGetApplication();
  const selectStage = useSelectApplicationStage();
  const stageStatuses = useApplicationStageStatus();

  const handleStepClick = (stage: Stage) => (isAgent() ? selectStage.select(stage) : undefined);
  const { stage, actualStage } = applicationQuery.data || {};

  if (stageStatuses === null) return null;

  const navSteps: WizardNavStepProps[] = [
    {
      route: paths.wizard.services,
      stepKey: 'services',
      icon: <ServicesIcon dir={dir} />,
      name: t('Services'),
      ...stageStatuses.services,
    },
    {
      route: paths.wizard.application,
      stepKey: 'application',
      icon: <ApplicationIcon dir={dir} />,
      name: t('Application'),
      subSteps: isTablet && stage === Stage.fillApplication ? <ApplicationNavigation /> : undefined,
      ...stageStatuses.application,
    },
    {
      route: paths.wizard.signContract,
      icon: <ContractIcon />,
      name: t('Contract'),
      onClick: handleStepClick(Stage.contract),
      ...stageStatuses.contract,
    },
    {
      route: paths.wizard.payment,
      icon: <PaymentIcon />,
      name: t('Payment'),
      onClick: handleStepClick(Stage.payment),
      ...stageStatuses.payment,
    },
    {
      route: paths.wizard.completion,
      icon: <CompletionIcon dir={dir} />,
      name: t('Completion'),
      onClick: actualStage ? handleStepClick(actualStage) : undefined,
      ...stageStatuses.completion,
    },
  ];

  return (
    <Container className="WizardTopNavigation" dir={dir}>
      {navSteps.map((item, index, arr) => {
        const isLast = index === arr.length - 1;
        return (
          <div key={item.route} className="WizardTopNavigation-StepContainer">
            <WizardNavStep {...item} />
            {!isLast && <ArrowRightIcon className="WizardTopNavigation-ArrowRightIcon" />}
          </div>
        );
      })}
    </Container>
  );
};

const Container = styled.div<DirectionProp>`
  display: flex;
  align-items: center;
  margin: 0 16px;
  .WizardTopNavigation-StepContainer {
    display: flex;
    align-items: center;
    .WizardTopNavigation-ArrowRightIcon {
      margin: 0 8px;
      color: #e5eaef;
      transform: scaleX(${({ dir }) => (dir === 'ltr' ? 1 : -1)});
    }
  }

  @media (max-width: ${theme.breakpoints.sm}px) {
    margin: 0 12px;
    .WizardTopNavigation-StepContainer {
      .WizardTopNavigation-ArrowRightIcon {
        margin: 0;
        opacity: 0;
        width: 16px;
      }
    }
  }
`;

export default WizardTopNavigation;
