import React, { DetailedHTMLProps } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';
import { Direction } from '~/types/Direction';

export type ImgProps = Omit<
  DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
  'src' | 'alt'
>;

export const createImg = (src: string, alt: string) => {
  return (props: ImgProps) => {
    // eslint-disable-next-line import/no-dynamic-require
    return <img src={src} alt={alt} {...props} />;
  };
};

export const createIcon =
  (rawIcon: IconComponentProps['component']) =>
  (props: IconComponentProps & { dir?: Direction }) => {
    const { style, dir, ...rest } = props;
    return (
      <Icon
        component={rawIcon}
        style={dir === 'rtl' ? { transform: 'scaleX(-1)', ...style } : style}
        {...rest}
        ref={undefined}
      />
    );
  };
