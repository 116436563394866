import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import { useMediaQuery } from 'react-responsive';

import WizardContinueButton from '~/layouts/WizardContinueButton';
import { Space, TotalPrice } from '~/components';
import NoteText from '~/components/NoteText';
import { useGetApplication, useSubmitStepIsLoading } from '~/controllers/wizard';
import ApplicationCommonErrors from '../ApplicationCommonErrors';
import theme from '~/constants/theme';
import { getDisablePayment } from '~/utils/getDisablePayment';

const PaymentRightContent: FC = () => {
  const { t } = useTranslation('payment');

  const location = useLocation();

  const [isLoading] = useSubmitStepIsLoading();

  const applicationQuery = useGetApplication();
  const stagePayload =
    applicationQuery.data?.stage === 'payment' ? applicationQuery.data.stagePayload : undefined;

  const pricing = stagePayload?.pricingDto.pricing;
  const pricingRequestStatus = stagePayload?.pricingRequest?.status;

  const disablePayment = getDisablePayment(applicationQuery.data);
  const isMobile = useMediaQuery({ maxWidth: theme.breakpoints.sm });

  return (
    <Container hidePricing={disablePayment}>
      {disablePayment && <div />}
      {!disablePayment && !isMobile && (
        <TotalPrice
          pricing={pricing}
          isLoading={applicationQuery.isLoading}
          isError={applicationQuery.isError}
          pricingRequestStatus={pricingRequestStatus}
        />
      )}
      <Space direction="vertical" size={24}>
        <ApplicationCommonErrors />
        <NoteText>
          {t(
            'This is the final step. We will notify you by email once your application is approved.'
          )}
        </NoteText>
        <WizardContinueButton
          id="payment_continueButton"
          formName={location.pathname}
          loading={isLoading}
        >
          {t('Submit', { ns: 'common' })}
        </WizardContinueButton>
      </Space>
    </Container>
  );
};

interface UIProps {
  hidePricing: boolean;
}

const Container = styled.div<UIProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  .TotalPrice {
    margin-bottom: 32px;
  }
`;

export default PaymentRightContent;
