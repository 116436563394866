import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Button, { ButtonProps } from '~/components/Button';
import colors from '~/constants/colors';
import { useDirection } from '~/hooks/i18nHooks';
import { ResetIcon } from '~/img';
import direction from '~/utils/direction';

const ResetToDefaultButton: FC<ButtonProps> = (props) => {
  const { t } = useTranslation('fillApplication');
  const dir = useDirection();

  return (
    <Container className="ResetToDefaultButton" dir={dir}>
      <Button
        className="ResetToDefaultButton-Button"
        type="text"
        size="small"
        icon={<ResetIcon />}
        {...props}
      >
        {t('Reset to default')}
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  .ant-btn {
    color: ${colors.primaryColor};
    ${direction.margin.right(-7)};
    &[disabled] {
      color: rgba(0, 0, 0, 0.25);
    }
  }
`;

export default ResetToDefaultButton;
