import React, { FC } from 'react';
import { Table as AntdTable, TableProps } from 'antd';
import styled from 'styled-components';

import { DirectionProp } from '~/types/Direction';
import { useDirection } from '~/hooks/i18nHooks';
import direction from '~/utils/direction';

function Table<RecordType = any>(props: TableProps<RecordType>) {
  const dir = useDirection();
  return <StyledTable {...props} dir={dir} />;
}

const StyledTable = styled<FC<DirectionProp & TableProps<any>>>(AntdTable as any)`
  .ant-table-cell {
    ${direction.textAlign.right};
  }
`;

export default Table;
