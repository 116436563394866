import React, { FC } from 'react';
import { Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import WizardContent from '~/layouts/WizardContent';
import { SubmitStageImg } from '~/img';
import features from '~/constants/features';
import DownloadContract from './DownloadContract';
import { useGetApplication } from '~/controllers/wizard';
import LeftLayout from '~/layouts/LeftLayout';
import BottomLeftActions from '~/layouts/BottomLeftActions';

const SubmitStage: FC = () => {
  const { t } = useTranslation('stageResult');
  const applicationQuery = useGetApplication();
  const salesforceApplicationNumber =
    applicationQuery.data?.payload.salesforceApplicationNumber || new Array(11).fill('_').join('');

  return (
    <WizardContent
      left={
        <LeftLayout>
          <BottomLeftActions contactUsUrl={features.contactUsLinkSubmit} />
        </LeftLayout>
      }
      content={
        <Container className="SubmitStage">
          <Typography.Title>
            {t('Your application {{salesforceApplicationNumber}} is under review', {
              salesforceApplicationNumber,
            })}
          </Typography.Title>
          <Typography.Paragraph>
            <p>
              {t(
                'We aim to review all applications within 2 working days and we will notify you once your application has been approved.'
              )}
            </p>
            <p>
              <Trans
                t={t}
                i18nKey="In the meantime, you can head to our website to view more about our <1>products and services</1>."
              >
                {'In the meantime, you can head to our website to view more about our '}
                <a href={features.homeLink} target="__blank">
                  products and services
                </a>
                .
              </Trans>
            </p>
            <p>
              <Trans
                t={t}
                i18nKey="If you require any support whilst you wait, please <1>contact us</1>."
              >
                If you require any support whilst you wait, please
                <a href={features.contactUsLinkSubmit} target="__blank">
                  contact us
                </a>
                .
              </Trans>
            </p>
          </Typography.Paragraph>
          <SubmitStageImg className="SubmitStage-Img" />
        </Container>
      }
      right={<DownloadContract />}
    />
  );
};

const Container = styled.div`
  .SubmitStage-Img {
    margin-top: 36px;
    border-radius: 14px;
    width: 100%;
  }
`;

export default SubmitStage;
