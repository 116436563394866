import { PaymentDetails } from '~/types/PaymentDetails';
import axios from './axios';

export const proceedPayment = (body: PaymentDetails) => {
  return axios.post<void>('/stages/payment/proceed', body).then((res) => res.data);
};

export const payByCard = () => {
  return axios
    .post<{ paymentFrameLink: string }>('/stages/payment/payByCard')
    .then((res) => res.data);
};

export const sendEmailPayByLink = (email: string) => {
  return axios
    .post<{ email: string; expiryDate: string }>('/stages/payment/payByLink', { email })
    .then((res) => res.data);
};
