import React, { FC } from 'react';
import { Drawer as AntdDrawer, Typography } from 'antd';
import { DrawerProps as AntdDrawerProps } from 'antd/lib/drawer';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { CloseIcon } from '~/img';
import colors from '~/constants/colors';
import theme from '~/constants/theme';
import { DirectionProp } from '~/types/Direction';
import { useDirection } from '~/hooks/i18nHooks';
import direction from '~/utils/direction';

export interface DrawerProps extends AntdDrawerProps {
  size?: 'default' | 'large';
  description?: string;
}

const Drawer: FC<DrawerProps> = (props) => {
  const { children, size = 'default', visible, title, description, ...rest } = props;

  const isMobile = useMediaQuery({ maxWidth: DRAWER_WIDTH });
  const dir = useDirection();

  return (
    <StyledDrawer
      destroyOnClose
      className="Drawer"
      closeIcon={<CloseIcon className="Drawer-CloseIcon" />}
      size={size}
      visible={visible}
      width={size === 'default' ? getWidth(isMobile) : undefined}
      dir={dir}
      {...rest}
    >
      {title && (
        <Typography.Title level={4} style={{ textTransform: 'uppercase' }}>
          {title}
        </Typography.Title>
      )}
      {description && (
        <Typography.Paragraph style={{ marginBottom: 44 }}>{description}</Typography.Paragraph>
      )}
      {children}
    </StyledDrawer>
  );
};

export const DRAWER_WIDTH = 432;

const getWidth = (isMobile: boolean) => {
  return isMobile ? '100vw' : DRAWER_WIDTH;
};

const StyledDrawer = styled<FC<DrawerProps & DirectionProp>>(AntdDrawer)`
  .ant-drawer-body {
    padding: 40px 32px;
    overflow-x: hidden;
  }
  .ant-drawer-mask {
    background-color: rgba(242, 245, 247, 0.5);
  }
  .ant-drawer-close {
    top: 16px;
    ${direction.right(8)};
    ${direction.left('unset')};
    @media (max-width: ${theme.breakpoints.sm}px) {
      top: 0;
      ${direction.right(0)};
    }
  }
  .Drawer-CloseIcon {
    color: ${colors.disabledBlueTextColor};
    font-size: 14px;
    padding: 2px;
    @media (max-width: ${theme.breakpoints.sm}px) {
      font-size: 20px;
    }
  }
  ${({ size }) =>
    size === 'default'
      ? css``
      : size === 'large'
      ? css`
          .ant-drawer-content-wrapper {
            width: calc(50vw + 427px) !important;
            .ant-drawer-body {
              padding: 56px 80px;
            }
            @media (max-width: ${theme.breakpoints.md}px) {
              width: 100% !important;
            }
            @media (max-width: ${theme.breakpoints.sm}px) {
              .ant-drawer-body {
                padding: 16px;
              }
            }
          }
        `
      : undefined}
`;

export default Drawer;
