import React, { FC } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

interface Props {
  visible: boolean;
  onClose: () => void;
}

const PayOnlineErrorModal: FC<Props> = (props) => {
  const { visible, onClose } = props;
  const { t } = useTranslation('payment');

  return (
    <Modal
      visible={visible}
      title={t('Something went wrong')}
      cancelButtonProps={cancelButtonProps}
      okText={t('Close and Try again')}
      onOk={onClose}
      centered
      width={416}
    >
      <Typography.Paragraph style={noMarginBottom}>
        <p>{t('An error has been occurred.')}</p>
        {t('Please try again later or choose another payment method.')}
      </Typography.Paragraph>
    </Modal>
  );
};

const cancelButtonProps = { style: { display: 'none' } };
const noMarginBottom = { marginBottom: 0 };

export default PayOnlineErrorModal;
