import { Radio } from 'antd';
import React, { FC } from 'react';

import Collapse from '~/components/Collapse';
import { PaymentMethod } from '~/types/PaymentMethods';

interface Props {
  key: PaymentMethod['type'];
  value: PaymentMethod['type'];
  header: JSX.Element | string;
  disabled: boolean;
  [collapseProps: string]: any;
}

const PaymentMethodCollapsePanel: FC<Props> = (props) => {
  const { key, value, header, disabled, children, ...collapseProps } = props;

  return (
    <Collapse.Panel
      key={value}
      showArrow={false}
      header={
        <Radio value={value} disabled={disabled}>
          {header}
        </Radio>
      }
      {...collapseProps}
    >
      {collapseProps.isActive ? children : null}
    </Collapse.Panel>
  );
};

export default PaymentMethodCollapsePanel;
