import React, { FC } from 'react';
import { Button as AntdButton, ButtonProps as AntdButtonProps } from 'antd';
import styled, { css } from 'styled-components';
import cc from 'color';

import colors from '~/constants/colors';
import theme from '~/constants/theme';
import { useDirection } from '~/hooks/i18nHooks';
import direction from '~/utils/direction';
import { Direction } from '~/types/Direction';

export interface ButtonProps extends AntdButtonProps {
  id: string; // id is required because autotests needs ids on clickable elements
  success?: boolean;
  extraLarge?: boolean;
  adaptive?: boolean;
}

const Button: FC<ButtonProps> = (props) => {
  const { children, adaptive, ...rest } = props;
  const dir = useDirection();

  return (
    <StyledButton className="Button" dir={dir as any} {...rest}>
      <span className="Button-Children">{children}</span>
    </StyledButton>
  );
};

const StyledButton = styled<FC<ButtonProps & { dir: Direction }>>(AntdButton as any)`
  &[dir='rtl'] {
    .anticon {
      padding-right: 0;
      padding-left: 8px;
    }
  }
  ${({ extraLarge }) =>
    extraLarge
      ? css`
          height: 80px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .anticon {
            font-size: 24px;
          }
          @media (max-width: ${theme.breakpoints.sm}px) {
            font-size: 14px;
            height: 56px;
            .anticon {
              font-size: 18px;
            }
          }
        `
      : css`
          display: flex;
          align-items: center;
          justify-content: center;
        `}
  ${({ success }) =>
    success
      ? css`
          border: 1px solid ${colors.textColor};
          color: ${colors.textColor};
          &:hover {
            border: 1px solid ${fadedTextColor};
            color: ${fadedTextColor};
          }
        `
      : css``}
      
  ${({ adaptive, dir }) =>
    adaptive
      ? css`
          @media (max-width: ${theme.breakpoints.sm}px) {
            .Button-Children {
              width: ${dir === 'rtl' ? 180 : 230}px;
              white-space: break-spaces;
              text-align: left;
              ${direction.textAlign.right};
            }
          }
        `
      : css``}

  .anticon {
    display: flex;
    // color: ${colors.blue[1100]};
  }
`;

const fadedTextColor = cc(colors.textColor).fade(0.3).string();

export default Button;
