import { RuleObject } from 'antd/lib/form';

import { basicValidation } from './basicValidation';
import i18n from '~/i18n';

export type Validator = RuleObject['validator'];

interface Options {
  allowArabic?: boolean;
  mustIncludesLetters?: boolean;
  disableFirstLastDotsRule?: boolean;
  disableFirstLastSpacesRule?: boolean;
  disableConsecutivelyDotsRule?: boolean;
}

const VALID_SPECIAL_SYMBOLS = [
  '!',
  '#',
  '$',
  '%',
  '&',
  '*',
  '+',
  '-',
  '/',
  '?',
  '^',
  '_',
  '{',
  '|',
  '}',
  ',',
  '-',
  '~',
  '.',
  ' ',
];

export const validateCharacters: (options?: Options) => Validator = (options) =>
  basicValidation((rule, value, callback) => {
    const o = options || {};
    const allowArabic = !!o.allowArabic;
    const mustIncludesLetters = !!o.mustIncludesLetters;
    const disableFirstLastDotsRule = !!o.disableFirstLastDotsRule;
    const disableFirstLastSpacesRule = !!o.disableFirstLastSpacesRule;
    const disableConsecutivelyDotsRule = !!o.disableConsecutivelyDotsRule;

    try {
      let haveLetters = false;
      for (let i = 0; i < value.length; i++) {
        const isFirst = i === 0;
        const isLast = i === value.length - 1;
        const currentChar = value[i];
        const prevChar = isFirst ? undefined : value[i - 1];
        if (!disableFirstLastDotsRule) {
          if ((isFirst && currentChar === '.') || (isLast && currentChar === '.')) {
            throw new Error(
              i18n.t("Character '.' (dot) should not be first or last character", {
                ns: 'validation',
              })
            );
          }
        }
        if (!disableFirstLastSpacesRule) {
          if ((isFirst && currentChar === ' ') || (isLast && currentChar === ' ')) {
            throw new Error(
              i18n.t("Character ' ' (space) should not be first or last character", {
                ns: 'validation',
              })
            );
          }
        }
        if (!disableConsecutivelyDotsRule) {
          if (prevChar === '.' && currentChar === '.') {
            throw new Error(
              i18n.t("Character '.' (dot) should not appear two or more times consecutively", {
                ns: 'validation',
              })
            );
          }
        }
        const code = currentChar.charCodeAt(0);
        if (code >= 1536 && code <= 1791 && !allowArabic) {
          throw new Error(i18n.t('Must include only latin letters', { ns: 'validation' }));
        }
        if (
          !(code > 47 && code < 58) && // numeric (0-9)
          !(code > 64 && code < 91) && // upper alpha (A-Z)
          !(code > 96 && code < 123) && // lower alpha (a-z)
          !(code >= 1536 && code <= 1791 && allowArabic) && // arabic alpha
          !VALID_SPECIAL_SYMBOLS.includes(currentChar)
        ) {
          throw new Error(i18n.t('Includes forbidden symbols', { ns: 'validation' }));
        }
        // numeric (0-9)
        if (mustIncludesLetters) {
          if (!(code > 47 && code < 58) && !haveLetters) {
            haveLetters = true;
          }
        }
      }

      if (mustIncludesLetters && !haveLetters) {
        throw new Error(i18n.t('Must includes letters', { ns: 'validation' }));
      }

      callback();
    } catch (err) {
      callback(err as string);
    }
  });
