import React, { FC } from 'react';
import { Avatar as AntdAvatar, Skeleton } from 'antd';
import styled from 'styled-components';
import { AvatarProps } from 'antd/lib/avatar';

import { UserAvatarIcon } from '~/img';
import useLoadImage from '~/hooks/useLoadImage';
import { FileType } from '~/types/files';
import colors from '~/constants/colors';

interface Props extends AvatarProps {
  loading?: boolean;
  size?: number | 'large' | 'small' | 'default';
  file?: FileType;
  id?: string;
}

const Avatar: FC<Props> = (props) => {
  const { loading, size, file, src, ...rest } = props;
  const imageLoad = useLoadImage(file);
  const resultLoading = imageLoad.loading || loading;
  const resultSrc = src || imageLoad.dataURL;

  return (
    <AvatarContainer className="Avatar">
      {resultLoading ? (
        <Skeleton.Avatar size={size} active />
      ) : (
        <AntdAvatar
          className="Avatar-AntdAvatar"
          size={40}
          icon={<UserAvatarIcon />}
          src={resultSrc}
          {...rest}
        />
      )}
    </AvatarContainer>
  );
};

const AvatarContainer = styled.div`
  .ant-avatar-image {
    box-shadow: 0px 0px 2px 0px rgb(34 60 80 / 20%);
  }
  .ant-skeleton-element {
    display: flex;
  }
  .Avatar-AntdAvatar {
    background-color: transparent;
    font-size: 40px !important;
    color: ${colors.primaryColor};
  }
`;

export default Avatar;
