/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '~/store';

export type StatePayload<T = any> = {
  key: string;
  data: T;
};
export type StateCallbackPayload<T = any> = {
  key: string;
  callback: (prev: T) => T;
};
const initialState: Record<string, any> = {};

const slice = createSlice({
  name: 'state',
  initialState,
  reducers: {
    setStateAction: (state, action: PayloadAction<StatePayload>) => {
      state[action.payload.key] = action.payload.data;
    },
    setStateWithCallbackAction: (state, action: PayloadAction<StateCallbackPayload>) => {
      const prevState = state[action.payload.key];
      let prev = null as any;
      if (Array.isArray(prevState)) {
        prev = [...prevState];
      } else {
        prev = { ...(state[action.payload.key] || {}) };
      }
      state[action.payload.key] = action.payload.callback(prev);
    },
    setStackInitialStateAction: (state, action: PayloadAction<StatePayload>) => {
      state[action.payload.key] = action.payload.data;
    },
  },
});

export const { setStateAction, setStackInitialStateAction, setStateWithCallbackAction } =
  slice.actions;

export const getState =
  <T = any>(key: string) =>
  (rootState: RootState): T =>
    rootState.state[key];
export default slice.reducer;
