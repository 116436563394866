import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import LeadForm, { formName } from '~/components/views/agent/leadForm/LeadForm';
import buildNamePath from '~/utils/buildNamePath';
import FormDrawer from '~/components/FormDrawer';
import { useSubmitLead, useCreateApplication, useAssignLead } from '~/controllers/agent';
import { AgentLead, AgentLeadPayload } from '~/types/AgentLead';
import { queryClient } from '~/index';
import CreatingApplicationModal from '~/components/views/agent/CreatingApplicationModal';
import { goToApplication } from '~/utils/goToApplication';
import { Form } from '~/components';
import LeadStatusTag from '~/components/StatusTag';
import { isTeamLead } from '~/utils/getDecodedJwt';
import { mapItemsQueryKey } from '~/controllers/agentMap';

interface Props {
  open: boolean;
  onClose: () => void;
  initialValues?: AgentLead;
}

export const LeadShowOpen: FC<Props> = (props) => {
  const { t } = useTranslation('agent');
  const { open, onClose, initialValues } = props;
  const submitLead = useSubmitLead();
  const createApplication = useCreateApplication();
  const assignLead = useAssignLead();
  const history = useHistory();
  const [form] = Form.useForm<AgentLeadPayload>();
  const [creatingApplicationOpen, setCreatingApplicationOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSave = (newValues: AgentLeadPayload, withCloseDrawer = true) => {
    return submitLead
      .mutateAsync(newValues)
      .then(async () => {
        if (isTeamLead())
          await assignLead.mutateAsync({
            leadId: newValues.leadId,
            agentId: newValues.assignee?.id ?? '',
          });

        if (withCloseDrawer) onClose();
        await queryClient.invalidateQueries('leads');
        await queryClient.invalidateQueries(mapItemsQueryKey);
      })
      .catch((e) => console.log('error', e));
  };

  const onConvertToApplication = () => {
    setLoading(true);
    return onSave(form.getFieldsValue(), false).then(() =>
      createApplication
        .mutateAsync({ leadId: initialValues?.leadId ?? '' })
        .then((application) => goToApplication(history, application?.uid))
        .catch(() => {})
        .finally(() => setLoading(false))
    );
  };

  const onTerminationSuccess = async () => {
    onClose();
    await queryClient.invalidateQueries('leads');
    await queryClient.invalidateQueries(mapItemsQueryKey);
  };

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(
      initialValues
        ? {
            leadId: initialValues.leadId,
            assignee: initialValues.assignee,
            ...initialValues.payload,
          }
        : {}
    );
  }, [form, initialValues]);

  return (
    <>
      <FormDrawer
        id="leadOpen"
        visible={open}
        onClose={onClose}
        title={
          <LeadStatusTag
            status={initialValues?.status}
            title={initialValues?.companyName}
            statusKey={initialValues?.statusKey}
          />
        }
        formName={formName}
        closable
        withContinueButton={false}
        withCustomButtons
        customButtonsProps={[
          {
            id: 'saveLeadButton',
            title: t('Save', { ns: 'common' }),
            type: 'default',
            htmlType: 'submit',
            loading: submitLead.isLoading || assignLead.isLoading,
            danger: true,
            form: formName,
          },
          {
            id: 'convertToApplicationButton',
            title: t('Convert to Application'),
            type: 'primary',
            loading,
            disabled: !loading && (submitLead.isLoading || assignLead.isLoading),
            form: formName,
            onClick: () => {
              form
                .validateFields()
                .then(() => setCreatingApplicationOpen(true))
                .catch((fields) => form.scrollToField(fields?.errorFields?.[0]?.name));
            },
          },
        ]}
      >
        <LeadForm
          form={form}
          onFinish={onSave}
          n={buildNamePath([])}
          status={initialValues?.statusKey}
          onTerminationSuccess={onTerminationSuccess}
        />
      </FormDrawer>
      <CreatingApplicationModal
        visible={creatingApplicationOpen}
        onClose={() => setCreatingApplicationOpen(false)}
        onConfirm={onConvertToApplication}
        loading={loading}
      />
    </>
  );
};
