/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC } from 'react';
import { InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';

import Form from '~/components/Form';
import DaySelect from '~/components/inputs/DaySelect';
import Select from '~/components/inputs/Select';
import TimeInput from '~/components/inputs/TimeInput';
import InputsGroupLayout from '~/layouts/InputsGroupLayout';
import { DayName } from '~/types/DayName';
import { NameFn } from '~/utils/buildNamePath';
import { useConfiguration } from '~/controllers/pricing';
import { ServiceField } from '~/types/Services';
import { validateNumber } from './validateNumber';
import { isNumeric } from '~/utils/isNumeric';

interface Props {
  n: NameFn;
  withCutOff?: boolean;
  fields: ServiceField[];
}

const SettlementFrequencyConfig: FC<Props> = (props) => {
  const { n, withCutOff, fields } = props;
  const { t } = useTranslation('servicesConfig');
  const config = useConfiguration(fields);

  return (
    <InputsGroupLayout>
      {config.renderField('settlementFrequency', ({ disabled, name }) => {
        return (
          <Form.Item name={n(name)} label={t('Settlement frequency')}>
            <Select
              options={[
                { label: t('Daily'), value: dependValues.Daily },
                { label: t('Weekly once'), value: dependValues['Weekly Once'] },
                { label: t('Weekly twice'), value: dependValues['Weekly twice'] },
                { label: t('Monthly'), value: 'Monthly' },
                { label: t('Fortnightly'), value: 'Fortnightly' },
              ]}
              disabled={disabled}
            />
          </Form.Item>
        );
      })}
      <div />
      {config.renderField('settlementFirstDay', ({ disabled, name }) => {
        return (
          <Form.ShouldUpdate
            names={[
              n(config.getName('settlementFrequency')),
              n(config.getName('settlementSecondDay')),
            ]}
          >
            {(form) => {
              const settlementFrequency = form.getFieldValue(
                n(config.getName('settlementFrequency'))
              );
              const settlementFirstDay: DayName | undefined = form.getFieldValue(
                n(config.getName('settlementFirstDay'))
              );
              const settlementSecondDay: DayName | undefined = form.getFieldValue(
                n(config.getName('settlementSecondDay'))
              );

              const requiredAndEditable =
                settlementFrequency === dependValues['Weekly twice'] ||
                settlementFrequency === dependValues['Weekly Once'];

              if (
                settlementFirstDay &&
                settlementFrequency !== dependValues['Weekly twice'] &&
                settlementFrequency !== dependValues['Weekly Once']
              ) {
                form.setFields([
                  { name: n(config.getName('settlementFirstDay')), value: undefined },
                ]);
              }
              return (
                <Form.Item
                  name={n(name)}
                  label={t('Settlement first day')}
                  rules={requiredAndEditable ? [{ required: true }] : undefined}
                >
                  <DaySelect
                    disabled={!requiredAndEditable || disabled}
                    disableDays={
                      settlementSecondDay
                        ? ({ [settlementSecondDay]: true } as Record<DayName, true>)
                        : undefined
                    }
                  />
                </Form.Item>
              );
            }}
          </Form.ShouldUpdate>
        );
      })}
      {config.renderField('settlementSecondDay', ({ disabled, name }) => {
        return (
          <Form.ShouldUpdate
            names={[
              n(config.getName('settlementFrequency')),
              n(config.getName('settlementFirstDay')),
            ]}
          >
            {(form) => {
              const settlementFrequency = form.getFieldValue(
                n(config.getName('settlementFrequency'))
              );
              const settlementFirstDay = form.getFieldValue(
                n(config.getName('settlementFirstDay'))
              );
              const settlementSecondDay = form.getFieldValue(n(name));

              const requiredAndEditable = settlementFrequency === dependValues['Weekly twice'];

              if (settlementSecondDay && settlementFrequency !== dependValues['Weekly twice']) {
                form.setFields([{ name: n(name), value: undefined }]);
              }
              return (
                <Form.Item
                  name={n(name)}
                  label={t('Settlement second day')}
                  rules={requiredAndEditable ? [{ required: true }] : undefined}
                >
                  <DaySelect
                    disabled={!requiredAndEditable || disabled}
                    disableDays={
                      settlementFirstDay
                        ? ({ [settlementFirstDay]: true } as Record<DayName, true>)
                        : undefined
                    }
                  />
                </Form.Item>
              );
            }}
          </Form.ShouldUpdate>
        );
      })}
      {withCutOff && (
        <>
          {config.renderField('flexiCutofDays', ({ disabled, name, field }) => {
            return (
              <Form.ShouldUpdate name={n(config.getName('settlementFrequency'))}>
                {(form) => {
                  const settlementFrequency = form.getFieldValue(
                    n(config.getName('settlementFrequency'))
                  );
                  const flexiCutofDays = form.getFieldValue(n(name));

                  const requiredAndEditable = settlementFrequency === dependValues.Daily;

                  if (isNumeric(flexiCutofDays) && settlementFrequency !== dependValues.Daily) {
                    form.setFields([{ name: n(name), value: undefined }]);
                  }
                  if (
                    !isNumeric(flexiCutofDays) &&
                    field.type === 'string' &&
                    field.defaultValue &&
                    settlementFrequency === dependValues.Daily
                  ) {
                    form.setFields([{ name: n(name), value: field.defaultValue }]);
                  }
                  return (
                    <Form.Item
                      name={n(name)}
                      label={t('Cut-off day')}
                      rules={
                        requiredAndEditable
                          ? [{ validator: validateNumber(cutOffDayValidation) }]
                          : undefined
                      }
                    >
                      <InputNumber disabled={!requiredAndEditable || disabled} />
                    </Form.Item>
                  );
                }}
              </Form.ShouldUpdate>
            );
          })}
          {config.renderField('flexiCutofTime', ({ disabled, name }) => {
            return (
              <Form.ShouldUpdate name={n(config.getName('settlementFrequency'))}>
                {(form) => {
                  const settlementFrequency = form.getFieldValue(
                    n(config.getName('settlementFrequency'))
                  );
                  const flexiCutofTime = form.getFieldValue(n(name));

                  const enabled = settlementFrequency === dependValues.Daily;

                  if (flexiCutofTime && settlementFrequency !== dependValues.Daily) {
                    form.setFields([{ name: n(name), value: undefined }]);
                  }
                  return (
                    <Form.Item name={n(name)} label={t('Cut-off time')}>
                      <TimeInput disabled={!enabled || disabled} />
                    </Form.Item>
                  );
                }}
              </Form.ShouldUpdate>
            );
          })}
        </>
      )}
    </InputsGroupLayout>
  );
};

const cutOffDayValidation = {
  min: 1,
  max: 31,
};

const dependValues = {
  'Weekly Once': 'Weekly Once',
  'Weekly twice': 'Weekly twice',
  Daily: 'Daily',
};

export default SettlementFrequencyConfig;
