import React, { FC } from 'react';
import { BooleanServiceField, PrimitiveFieldMap, SchemaServiceField } from '~/types/Services';
import PaymentSchemaCardBase from './PaymentSchemaCardBase';
import PricingRowField from './PricingRowField';

interface Props {
  field: SchemaServiceField;
  booleanField?: BooleanServiceField;
  fieldMap: PrimitiveFieldMap;
}

const PaymentSchemaField: FC<Props> = (props) => {
  const { field, booleanField, fieldMap } = props;

  const disabled = booleanField?.value === false;

  return (
    <PaymentSchemaCardBase uiData={field.uiData} disabled={disabled}>
      {field.fields.map((f, index, arr) => {
        const isLast = index === arr.length - 1;
        return (
          <div key={f.key} style={!isLast ? bottomSpacing : undefined}>
            <PricingRowField field={f} fieldMap={fieldMap} />
          </div>
        );
      })}
    </PaymentSchemaCardBase>
  );
};

const bottomSpacing = { marginBottom: 24 };

export default PaymentSchemaField;
