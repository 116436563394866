import React, { FC } from 'react';

import PricingRowFormItem from '~/components/PricingRowFormItem';
import { BooleanServiceField, SchemaServiceField } from '~/types/Services';
import { NameFn } from '~/utils/buildNamePath';
import PaymentSchemaCardBase from './PaymentSchemaCardBase';

interface Props {
  n: NameFn;
  field: SchemaServiceField;
  booleanField?: BooleanServiceField;
}

const PaymentSchemaFormItem: FC<Props> = (props) => {
  const { n, field, booleanField } = props;
  return (
    <PaymentSchemaCardBase n={n} uiData={field.uiData} booleanField={booleanField}>
      {field.fields.map((f, index, arr) => {
        const isLast = index === arr.length - 1;
        return (
          <div key={f.key} style={!isLast ? bottomSpacing : undefined}>
            <PricingRowFormItem key={f.key} n={n} pricingField={f} />
          </div>
        );
      })}
    </PaymentSchemaCardBase>
  );
};

const bottomSpacing = { marginBottom: 24 };

export default PaymentSchemaFormItem;
