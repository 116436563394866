import { Typography } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';
import { statusColor } from '~/components/views/agent/tableElements/cells/StatusCell';
import colors from '~/constants/colors';
import { useGetApplication } from '~/controllers/wizard';
import { useDirection } from '~/hooks/i18nHooks';
import { CircleIcon } from '~/img';
import direction from '~/utils/direction';
import { getDecodedJwt } from '~/utils/getDecodedJwt';

const HeaderCompanyName: FC = () => {
  const applicationQuery = useGetApplication();
  const decodedToken = getDecodedJwt();
  const status = applicationQuery.data?.label;
  const legalName =
    applicationQuery.data?.payload.companyInformation?.companyLicense?.legalName ||
    decodedToken?.company_legal_name;

  const dir = useDirection();

  return (
    <Container className="HeaderCompanyName" dir={dir}>
      <Typography.Text className="HeaderCompanyName-LegalName" ellipsis>
        {legalName}
      </Typography.Text>
      <CircleIcon style={{ color: status ? statusColor(status) : colors.blue[1100] }} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  .HeaderCompanyName-LegalName {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    ${direction.margin.right(8)};
  }
`;

export default HeaderCompanyName;
