import React, { FC } from 'react';
import DebugAlert from '~/components/DebugAlert';
import { PrimitiveFieldMap, PrimitiveServiceField } from '~/types/Services';
import { NameFn } from '~/utils/buildNamePath';
import BooleanResolver from './BooleanResolver';
import PricingResolver from './PricingResolver';
import SelectFormItem from './SelectFormItem';
import { withRole } from './utils';

interface Props {
  n: NameFn;
  field: PrimitiveServiceField;
  fieldMap: PrimitiveFieldMap;
}

const PrimitiveServiceFieldResolver: FC<Props> = (props) => {
  const { field, n, fieldMap } = props;
  if (!withRole(field.visible)) return null;

  switch (field.type) {
    case 'boolean':
      return <BooleanResolver n={n} field={field} />;
    case 'string':
      return <SelectFormItem n={n} field={field} />;
    case 'pricing':
      return <PricingResolver n={n} field={field} fieldMap={fieldMap} />;
    default:
      return (
        <DebugAlert
          message={`Field "${(field as any)?.key}" with type ${
            (field as any).type
          } is not supported`}
        />
      );
  }
};

export default PrimitiveServiceFieldResolver;
