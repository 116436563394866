import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

import paths from './paths';

import home from './home';
import merchantAuthCallback from './merchantAuthCallback';
import wizard from './wizard';
import services from './wizard/services';
import application from './wizard/application';
import signContract from './wizard/signContract';
import payment from './wizard/payment';
import completion from './wizard/completion';
import companyType from './companyType';
import letsStart from './letsStart';
import getInTouch from './getInTouch';
import waitingForScreening from './stageResult/waitingForScreening';
import error from './stageResult/error';
import screeningError from './stageResult/screeningError';
import screeningReject from './stageResult/screeningReject';
import salesforceSoftDecline from './stageResult/salesforceSoftDecline';
import salesforceHardDecline from './stageResult/salesforceHardDecline';
import submit from './stageResult/submit';
import salesforceError from './stageResult/salesforceError';
import salesforceTechnicalError from './stageResult/salesforceTechnicalError';
import successfulOnboarding from './stageResult/successfulOnboarding';
import completed from './stageResult/completed';
import unknownStage from './stageResult/unknownStage';
import agent from './agent';
import leads from './agent/leads';
import applications from './agent/applications';
import agentMap from './agent/map';
import redirectToApplication from './redirect/application';

import CompanyTypeLayout from '~/layouts/CompanyTypeLayout';
import WizardLayout from '~/layouts/WizardLayout';
import AgentLayout from '~/layouts/AgentLayout';
import merchantInviteAuthCallback from '~/pages/merchantInviteAuthCallback';
import agentAuthCallback from '~/pages/agentAuthCallback';
import { getShouldGoToDashboard } from '~/utils/getShouldGoToDashboard';

const pages = {
  auth: [
    {
      path: paths.auth.merchantAuthCallback,
      exact: true,
      component: merchantAuthCallback,
    },
    {
      path: paths.auth.merchantInviteAuthCallback,
      exact: true,
      component: merchantInviteAuthCallback,
    },
    {
      path: paths.auth.agentAuthCallback,
      exact: true,
      component: agentAuthCallback,
    },
  ],
  redirect: [
    {
      path: `${paths.redirect.application}/:applicationId`,
      exact: true,
      component: redirectToApplication,
    },
  ],
  wizard: [
    {
      path: paths.wizard._,
      exact: true,
      component: wizard,
    },
    {
      path: paths.wizard.services,
      exact: true,
      component: services,
    },
    {
      path: `${paths.wizard.application}`,
      exact: false,
      component: application,
    },
    {
      path: paths.wizard.signContract,
      exact: true,
      component: signContract,
    },
    {
      path: paths.wizard.payment,
      exact: true,
      component: payment,
    },
    {
      path: paths.wizard.completion,
      exact: true,
      component: completion,
    },
  ],
  stageResult: [
    {
      path: paths.stageResult.waitingForScreening,
      exact: true,
      component: waitingForScreening,
    },
    {
      path: paths.stageResult.error,
      exact: true,
      component: error,
    },
    {
      path: paths.stageResult.screeningError,
      exact: true,
      component: screeningError,
    },
    {
      path: paths.stageResult.screeningReject,
      exact: true,
      component: screeningReject,
    },
    {
      path: paths.stageResult.submit,
      exact: true,
      component: submit,
    },
    {
      path: paths.stageResult.salesforceError,
      exact: true,
      component: salesforceError,
    },
    {
      path: paths.stageResult.salesforceTechnicalError,
      exact: true,
      component: salesforceTechnicalError,
    },
    {
      path: paths.stageResult.salesforceSoftDecline,
      exact: true,
      component: salesforceSoftDecline,
    },
    {
      path: paths.stageResult.salesforceHardDecline,
      exact: true,
      component: salesforceHardDecline,
    },
    {
      path: paths.stageResult.successfulOnboarding,
      exact: true,
      component: successfulOnboarding,
    },
    {
      path: paths.stageResult.completed,
      exact: true,
      component: completed,
    },
    {
      path: paths.stageResult.unknownStage,
      exact: true,
      component: unknownStage,
    },
  ],
  app: [
    {
      path: paths.home._,
      exact: true,
      component: home,
    },
  ],
  companyType: [
    {
      path: paths.companyType._,
      exact: true,
      component: companyType,
    },
  ],
  letsStart: [
    {
      path: paths.letsStart._,
      exact: true,
      component: letsStart,
    },
  ],
  getInTouch: [
    {
      path: paths.getInTouch._,
      exact: true,
      component: getInTouch,
    },
  ],
  agent: [
    {
      path: paths.agent._,
      exact: true,
      component: agent,
    },
    {
      path: paths.agent.leads,
      exact: true,
      component: leads,
    },
    {
      path: paths.agent.applications,
      exact: true,
      component: applications,
    },
    {
      path: paths.agent.map,
      exact: true,
      component: agentMap,
    },
  ],
};

const Pages: FC = () => {
  return (
    <Switch>
      {getRoutes(pages.auth)}
      {getRoutes(pages.redirect)}
      {getRoutes(pages.app)}
      <Route path={paths.agent._}>
        {() =>
          getShouldGoToDashboard() ? (
            <AgentLayout>{getRoutes(pages.agent)}</AgentLayout>
          ) : (
            <Route component={home} />
          )
        }
      </Route>
      <Route path={paths.companyType._}>
        <CompanyTypeLayout image="default">{getRoutes(pages.companyType)}</CompanyTypeLayout>
      </Route>
      <Route path={paths.getInTouch._}>
        <CompanyTypeLayout image="default">{getRoutes(pages.getInTouch)}</CompanyTypeLayout>
      </Route>
      <Route path={paths.letsStart._}>
        <CompanyTypeLayout image="success">{getRoutes(pages.letsStart)}</CompanyTypeLayout>
      </Route>
      <Route path={paths.wizard._}>
        <WizardLayout>{getRoutes(pages.wizard)}</WizardLayout>
      </Route>
      <Route path={paths.stageResult._}>
        <WizardLayout>{getRoutes(pages.stageResult)}</WizardLayout>
      </Route>
    </Switch>
  );
};

type RouteConfig = {
  path: string;
  exact: boolean;
  component: any;
};

const getRoutes = (routes: RouteConfig[]) => {
  return routes.map((route) => {
    const { path, exact, component } = route;
    return <Route path={path} key={path} exact={exact} component={component} />;
  });
};

export default Pages;
