import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components';
import { getI18n } from 'react-i18next';
import TableHeader from '~/components/views/agent/tableElements/TableHeader';
import { AgentAssignee, AgentLead } from '~/types/AgentLead';
import { Select } from '~/components';
import { useAgents, useAssignApplication, useAssignLead } from '~/controllers/agent';
import { AgentApplication } from '~/types/AgentApplication';
import { queryClient } from '~/index';
import { DirectionProp } from '~/types/Direction';
import direction from '~/utils/direction';
import { useDirection } from '~/hooks/i18nHooks';

function assigneeCell(type: 'LEAD' | 'APPLICATION'): ColumnsType<any>[0] {
  return {
    title: () => (
      <TableHeader title={getI18n().t('Assignee', { ns: 'agent' })} sortingDataKey="assignee" />
    ),
    dataIndex: ['assignee'],
    key: 'assignee',
    render: (assignee: AgentAssignee, record: AgentLead | AgentApplication) => (
      <AssigneeSelect
        currentAssignee={assignee?.fullName}
        type={type}
        recordId={'leadId' in record && type === 'LEAD' ? record?.leadId : record?.applicationId}
        disabled={record.dashboardStatus === 'DONE'}
      />
    ),
    width: 120,
  };
}

interface Props {
  currentAssignee?: string;
  type: 'LEAD' | 'APPLICATION';
  recordId?: string;
  disabled: boolean;
}

const AssigneeSelect = (props: Props) => {
  const { currentAssignee, type, recordId, disabled } = props;
  const agents = useAgents();
  const assignLead = useAssignLead();
  const assignApplication = useAssignApplication();
  const dir = useDirection();

  const options = agents.data?.map(({ id, fullName }) => ({
    label: fullName,
    value: id,
    key: id,
  }));

  const onSelect = (agentId: string) => {
    if (type === 'LEAD' && recordId) {
      assignLead
        .mutateAsync({ leadId: recordId, agentId })
        .then(async () => {
          await queryClient.refetchQueries('leads');
        })
        .catch(() => {});
    }
    if (type === 'APPLICATION' && recordId) {
      assignApplication
        .mutateAsync({ applicationId: recordId, agentId })
        .then(async () => {
          await queryClient.refetchQueries('applications');
        })
        .catch(() => {});
    }
  };

  return (
    <Container dir={dir}>
      <Select
        id="assigneeCell"
        onClick={(e) => {
          e.stopPropagation();
        }}
        value={currentAssignee}
        loading={agents.isLoading}
        disabled={disabled || agents.isLoading}
        options={options}
        size="small"
        className="agentLeadTable-Assignee"
        onSelect={onSelect}
      />
    </Container>
  );
};

const Container = styled.div<DirectionProp>`
  display: flex;
  flex-direction: column;

  .agentLeadTable-Assignee {
    padding: 5px 0;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 0;
    background-color: transparent;
    box-shadow: 0 0 0;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border: 0;
    box-shadow: 0 0 0;
  }

  box-shadow: transparent;

  .ant-select-selection-item {
    ${direction.padding.right('-18px !important')};
    ${direction.padding.left('0px !important')};
  }

  .ant-select-selection-search {
    ${direction.left('-15px !important')};
    ${direction.right('unset !important')};
  }

  .ant-select-arrow {
    ${direction.right('unset')};
    ${direction.left('-11px')};
  }
`;

export default assigneeCell;
