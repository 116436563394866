import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '~/components';
import { useDownloadContractUnderReview } from '~/controllers/contract';

const DownloadContract: FC = () => {
  const { t } = useTranslation('stageResult');

  const downloadMutation = useDownloadContractUnderReview();

  const download = () => {
    void downloadMutation.download();
  };

  return (
    <Button
      id="applicationDetailsDownloadButton"
      type="default"
      loading={downloadMutation.isLoading}
      onClick={download}
    >
      {t('Application details')}
    </Button>
  );
};

export default DownloadContract;
