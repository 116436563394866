import React from 'react';
import styled from 'styled-components';

import colors from '~/constants/colors';

interface TitleProps {
  text: string;
  subtext?: string;
}

const CompanyInformationTitle = ({ text, subtext }: TitleProps) => {
  return (
    <Container className="CompanyInformationTitle">
      <span className="CompanyInformationTitle-Text">{text}</span>
      {subtext && <span className="CompanyInformationTitle-Subtext">{subtext}</span>}
    </Container>
  );
};

const Container = styled.h1`
  font-weight: 600;
  font-size: 38px;
  line-height: 1.25em;
  margin-top: 32px;
  margin-bottom: 64px;

  .CompanyInformationTitle-Text {
    display: block;
    color: ${colors.primaryColor};
  }
  .CompanyInformationTitle-Subtext {
  }
`;

export default CompanyInformationTitle;
