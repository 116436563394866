import React, { FC } from 'react';
import { ConfigProvider } from 'antd';
import styled from 'styled-components';
import { useGetValidationJs } from '~/controllers/validation';

import Header from './Header';
import WizardContentContainer from './WizardContentContainer';
import WizardTopNavigation from './WizardTopNavigation';
import { Scrollbars } from '~/components';
import TotalPriceMobileModule from '~/components/TotalPriceMobileModule';
import { getLayoutHeight, useHeaderHeight } from '~/hooks/useHeaderHeight';
import { useDirection } from '~/hooks/i18nHooks';

const WizardLayout: FC = (props) => {
  const { children } = props;
  useGetValidationJs();
  const dir = useDirection();

  const headerHeight = useHeaderHeight({ wizardHeader: true });

  return (
    <ConfigProvider componentSize="large">
      <Container className="WizardLayout">
        <Header
          middleContent={<WizardTopNavigation />}
          tabletContent={<TotalPriceMobileModule />}
        />
        <Scrollbars style={getLayoutHeight(headerHeight)}>
          <div className="WizardLayout-Wrapper" style={getLayoutHeight(headerHeight)} dir={dir}>
            <WizardContentContainer>{children}</WizardContentContainer>
          </div>
        </Scrollbars>
      </Container>
    </ConfigProvider>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;

  .WizardLayout-Wrapper {
    display: flex;
    flex: 1;
    justify-content: center;
  }
`;

export default WizardLayout;
