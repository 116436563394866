import React, { FC } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Questionnaire from '~/components/views/Questionnaire';

const QuestionnairePage: FC = () => {
  const { t } = useTranslation('fillApplication');

  return (
    <Container className="QuestionnairePage">
      <Typography.Title>{t('Company Questionnaire')}</Typography.Title>
      <Typography.Paragraph className="QuestionnairePage-Title">
        {t('Please share additional information about your business.')}
      </Typography.Paragraph>
      <Questionnaire />
    </Container>
  );
};

const Container = styled.div`
  h1 {
    margin-bottom: 16px;
  }
  .QuestionnairePage-Title {
    margin-bottom: 56px;
  }
`;

export default QuestionnairePage;
