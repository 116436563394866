import React, { CSSProperties, FC } from 'react';
import { useTranslation } from 'react-i18next';

import longdash from '~/constants/longdash';
import { Amount } from '~/types/Amount';
import Tag from '../Tag';

interface Props {
  amount: Amount | undefined | null;
  className?: string;
  abs?: boolean;
  maximumFractionDigits?: number;
  alwaysShowDecimalPart?: boolean;
  style?: CSSProperties;
  tag?: boolean;
  id?: string;
}

const AmountField: FC<Props> = (props) => {
  const {
    amount,
    className,
    abs,
    maximumFractionDigits = 3,
    alwaysShowDecimalPart,
    style,
    tag,
    id,
  } = props;
  const { i18n } = useTranslation();
  const language = i18n.language.slice(0, 2);
  const hasDecimalPart = String(amount?.value || 0).includes('.');

  const amountText = getAmountText({
    amount,
    language,
    abs,
    maximumFractionDigits: !hasDecimalPart && !alwaysShowDecimalPart ? 0 : maximumFractionDigits,
  });

  if (tag)
    return (
      <Tag id={id} className={`AmountField ${className || ''}`} style={style} color="blue">
        {amountText}
      </Tag>
    );
  return (
    <span id={id} className={`AmountField ${className || ''}`} style={style}>
      {amountText}
    </span>
  );
};

export const getAmountText = (props: {
  amount?: Amount | null;
  language?: string;
  abs?: boolean;
  maximumFractionDigits?: number;
}): string => {
  const { amount, language, abs, maximumFractionDigits } = props;
  let amountText: string = longdash;
  if (!amount || (amount.value !== 0 && !amount.value) || !amount.currency) {
    amountText = longdash;
  } else {
    const value = abs ? Math.abs(amount.value) : amount.value;
    amountText = value
      .toLocaleString(language || 'en', {
        style: 'currency',
        currency: amount.currency,
        maximumFractionDigits,
      })
      .replace(new RegExp(String.fromCharCode(160), 'g'), ' '); // remove &nbsp; from string
  }
  return amountText;
};

export default React.memo(AmountField);
