import { css } from 'styled-components';
import { Direction, DirectionProp } from '~/types/Direction';

const createProp =
  (ltrProp: string, rtlProp: string) =>
  (value: string | number) =>
  ({ dir }: DirectionProp) => {
    const resultValue = typeof value === 'number' ? `${value}px` : value;
    return dir === 'ltr'
      ? css`
          ${ltrProp}: ${resultValue};
        `
      : css`
          ${rtlProp}: ${resultValue};
        `;
  };

export default {
  margin: {
    left: createProp('margin-left', 'margin-right'),
    right: createProp('margin-right', 'margin-left'),
  },
  padding: {
    left: createProp('padding-left', 'padding-right'),
    right: createProp('padding-right', 'padding-left'),
  },
  border: {
    left: createProp('border-left', 'border-right'),
    right: createProp('border-right', 'border-left'),
  },
  borderRadius: {
    topLeft: createProp('border-top-left-radius', 'border-top-right-radius'),
    topRight: createProp('border-top-right-radius', 'border-top-left-radius'),
    bottomLeft: createProp('border-bottom-left-radius', 'border-bottom-right-radius'),
    bottomRight: createProp('border-bottom-right-radius', 'border-bottom-left-radius'),
  },
  left: createProp('left', 'right'),
  right: createProp('right', 'left'),
  textAlign: {
    right: ({ dir }: DirectionProp) => {
      return dir === 'rtl'
        ? css`
            text-align: right;
          `
        : undefined;
    },
  },
};

export const getRotateStyle = (isActive: boolean, dir: Direction) => {
  if (dir === 'ltr') return isActive ? { transform: 'rotate(90deg)' } : undefined;
  return isActive ? { transform: 'rotate(90deg)' } : { transform: 'rotate(180deg)' };
};
