import React, { FC } from 'react';
import Tag from './Tag';

interface Props {
  value: number | undefined | null;
  tag?: boolean;
}

const PercentField: FC<Props> = (props) => {
  const { value, tag } = props;

  if (typeof value !== 'number') return null;
  const percentString = `${value}%`;

  if (tag) return <Tag color="blue">{percentString}</Tag>;
  return <>{percentString}</>;
};

export default PercentField;
