import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import colors from '~/constants/colors';
import { useGoToDashboard } from '~/controllers/agent';
import { useDirection } from '~/hooks/i18nHooks';
import { DashboardIcon } from '~/img';
import { DirectionProp } from '~/types/Direction';
import direction from '~/utils/direction';

const GoToDashboardButton: FC = () => {
  const { t } = useTranslation();
  const goToDashboard = useGoToDashboard();
  const dir = useDirection();

  return (
    <Container
      id="goToDashboardButton"
      className="GoToDashboardButton"
      onClick={goToDashboard.go}
      disabled={goToDashboard.disabled}
      dir={dir}
    >
      <DashboardIcon className="GoToDashboardButton-Icon" />
      <span className="GoToDashboardButton-Text">{t('Dashboard')}</span>
    </Container>
  );
};

interface UIProps extends DirectionProp {
  disabled: boolean;
}

const Container = styled.div<UIProps>`
  display: flex;
  margin-top: auto;
  color: ${colors.blue[1100]};
  transition: opacity 0.2s ease;
  cursor: pointer;
  .GoToDashboardButton-Icon {
    font-size: 40px;
  }
  .GoToDashboardButton-Text {
    display: flex;
    ${direction.margin.left(8)};
    text-transform: uppercase;
    line-height: 40px;
    font-size: 14px;
  }
  &:hover,
  &:active {
    opacity: 0.7;
  }
  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.5;
          cursor: not-allowed;
        `
      : undefined}
`;

export default GoToDashboardButton;
