import InternalTabs from './Tabs';
import InternalTabPane from './TabPane';

type InternalTabsType = typeof InternalTabs;

interface TabsType extends InternalTabsType {
  TabPane: typeof InternalTabPane;
}

const Tabs = InternalTabs as TabsType;

Tabs.TabPane = InternalTabPane;

export default Tabs;
