import { DrawerProps, Menu } from 'antd';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import Drawer from '~/components/Drawer';
import colors from '~/constants/colors';
import features from '~/constants/features';
import theme from '~/constants/theme';
import { useGoToDashboard } from '~/controllers/agent';
import { useLogout } from '~/controllers/auth';
import { useDirection, useSetLanguage } from '~/hooks/i18nHooks';

import { LogOutIcon, MenuIcon, ContactUsIcon, DashboardIcon, GlobeIcon } from '~/img';
import { DirectionProp } from '~/types/Direction';
import direction from '~/utils/direction';
import { isAgent } from '~/utils/getDecodedJwt';
import HeaderCompanyName from './HeaderCompanyName';
import { getShouldGoToDashboard } from '~/utils/getShouldGoToDashboard';

const HeaderBurgerMenu: FC = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <StyledMenuIcon className="HeaderBurgerMenu" onClick={handleOpen} />
      <HeaderMenuDrawer open={open} handleClose={handleClose} />
    </>
  );
};

interface HeaderMenuDrawerProps {
  open: boolean;
  handleClose: () => void;
  headerComponent?: React.ReactNode;
}

export const HeaderMenuDrawer: FC<HeaderMenuDrawerProps> = (props) => {
  const { open, handleClose, headerComponent } = props;
  const [t] = useTranslation();

  const { handleLogout } = useLogout();
  const goToDashboard = useGoToDashboard();

  const { language, setLanguage, languages, languageNames } = useSetLanguage();
  const handleSelectLanguage = ({ key }: any) => {
    setLanguage(key);
  };

  const handleContactUs = () => {
    (window as any).open(features.contactUsLink, '_blank').focus();
  };

  const isMobile = useMediaQuery({ maxWidth: theme.breakpoints.sm });
  const dir = useDirection();

  return (
    <StyledDrawer
      visible={open}
      onClose={handleClose}
      width={280}
      placement={dir === 'rtl' ? 'left' : 'right'}
      dir={dir}
    >
      {isMobile && isAgent() && (headerComponent || <HeaderCompanyName />)}
      <Menu mode="inline" selectable={false} selectedKeys={[language]}>
        <Menu.SubMenu icon={<GlobeIcon />} title={t('Language')}>
          {languages.map((lng) => {
            return (
              <Menu.Item key={lng} onClick={handleSelectLanguage}>
                {languageNames[lng] || lng}
              </Menu.Item>
            );
          })}
        </Menu.SubMenu>
        {
          !isAgent() ? ( // merchant's menu
            <Menu.Item
              id="headerBurgerMenu-contactUs"
              icon={<ContactUsIcon className="HeaderBurgerMenu-ContactUsIcon" />}
              onClick={handleContactUs}
            >
              {t('Contact us')}
            </Menu.Item>
          ) : !getShouldGoToDashboard() ? ( // agent's menu on filling application screen
            <Menu.Item
              id="headerBurgerMenu-goToDashboard"
              icon={<DashboardIcon className="HeaderBurgerMenu-ContactUsIcon" />}
              onClick={goToDashboard.go}
              disabled={goToDashboard.disabled}
            >
              {t('Dashboard')}
            </Menu.Item>
          ) : null // agent's menu on dashboard (applications/leads/map) screen
        }
        <Menu.Item id="headerBurgerMenu-logoutAction" icon={<LogOutIcon />} onClick={handleLogout}>
          {t('Logout')}
        </Menu.Item>
      </Menu>
    </StyledDrawer>
  );
};

const StyledMenuIcon = styled(MenuIcon)`
  color: ${colors.primaryColor};
  font-size: 36px;
  cursor: pointer;
  transition: opacity 0.1s ease-in;
  &:hover,
  &:active {
    opacity: 0.5;
  }
`;

type UIProps = DrawerProps & DirectionProp;

const StyledDrawer = styled<FC<UIProps>>(Drawer)`
  .ant-drawer-body {
    padding-top: 100px;
  }
  .ant-drawer-close {
    top: unset;
    ${direction.right(0)};
    ${direction.left('unset')};
    transition: opacity 0.1s ease-in;
    &:hover,
    &:active {
      opacity: 0.5;
    }
  }
  .ant-menu-inline {
    box-shadow: none;
    border: none;
    .ant-menu-item,
    .ant-menu-submenu-title {
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin-bottom: 36px;
      &:hover,
      &:active {
        background: unset;
        .ant-menu-title-content,
        .ant-menu-item-icon {
          color: ${colors.primaryColor};
        }
      }
      .ant-menu-item-icon {
        font-size: 32px;
      }
      .ant-menu-title-content {
        font-size: 18px;
        ${direction.margin.left(10)};
        ${direction.margin.right(0)};
      }
      .HeaderBurgerMenu-ContactUsIcon {
        color: ${colors.blue[1100]};
      }
    }
    .ant-menu-submenu-title {
      margin-bottom: 8px;
    }
    .ant-menu-submenu {
      margin-bottom: 36px;
    }
    .ant-menu-submenu-selected {
      color: unset;
    }
    .ant-menu-submenu-arrow {
      ${direction.right(16)};
      ${direction.left('unset')};
    }
    .ant-menu-sub {
      background: transparent;
      padding-left: 42px;
      ${direction.padding.left(42)};
      ${direction.padding.right(0)};
      .ant-menu-item {
        margin-bottom: 0px;
        background: transparent;
        .ant-menu-title-content {
          ${direction.margin.left(0)};
        }
        &.ant-menu-item-selected {
          color: ${colors.blue[1100]} !important;
        }
        &:after {
          display: none;
        }
      }
    }
  }
  &[dir='rtl'] {
    .ant-menu-inline {
      direction: rtl;
      text-align: right;
      .ant-menu-item,
      .ant-menu-submenu-title {
        //
      }
    }
  }
  .HeaderCompanyName {
    margin-bottom: 32px;
  }
`;

export default HeaderBurgerMenu;
