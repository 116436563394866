import features from '~/constants/features';
import paths from '~/pages/paths';

export const redirectToExternalAuth = () => {
  const currentPath = `${window.location.pathname}`;
  const firstPath = currentPath.split('/')[1];
  if (firstPath === 'agent') {
    if (
      currentPath !== paths.auth.agentAuthCallback &&
      currentPath !== paths.auth.redirectAgentToKeyCloak
    ) {
      window.location.replace(
        `${paths.auth.redirectAgentToKeyCloak}?tenantId=${features.tenantId}`
      );
    }
  } else if (
    currentPath !== paths.auth.merchantAuthCallback &&
    currentPath !== paths.auth.redirectToKeyCloak
  ) {
    window.location.replace(`${paths.auth.redirectToKeyCloak}?tenantId=${features.tenantId}`);
  }
};
