import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { ConfigProvider } from 'antd';

import colors from '~/constants/colors';
import Header from '~/layouts/Header';
import theme from '~/constants/theme';

import companyTypeBackground from '~/img/companyTypeBackground.jpg';
import companyInformationSuccessBackground from '~/img/companyInformationSuccessBackground.jpg';
import { ContextProvider } from '~/controllers/CompanyTypeContext';
import { Scrollbars } from '~/components';
import { getLayoutHeight, useHeaderHeight } from '~/hooks/useHeaderHeight';
import { useDirection } from '~/hooks/i18nHooks';

interface Props {
  image: 'default' | 'success';
}

const CompanyTypeLayout: FC<Props> = (props) => {
  const { image, children } = props;
  const headerHeight = useHeaderHeight({ wizardHeader: false });
  const dir = useDirection();

  return (
    <ConfigProvider componentSize="large">
      <ContextProvider>
        <Header />
        <Scrollbars style={getLayoutHeight(headerHeight)}>
          <CenterContainer>
            <Container
              className="CompanyTypeLayout"
              image={image}
              dir={dir}
              style={getLayoutHeight(headerHeight)}
            >
              <div className="CompanyTypeLayout-Body">
                <div className="CompanyTypeLayout-FormContainer">{children}</div>
              </div>
            </Container>
          </CenterContainer>
        </Scrollbars>
      </ContextProvider>
    </ConfigProvider>
  );
};

const Container = styled.div<Props>`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 16px;
  max-width: 1472px;
  h1 {
    margin-top: 0;
    margin-bottom: 32px;
  }
  @media (max-width: ${theme.breakpoints.md}px) {
    padding: 0 8px;
  }
  .CompanyTypeLayout-Body {
    display: flex;
    height: calc(100% - 16px);
    background-color: ${colors.componentBackground};
    ${({ image }) =>
      image === 'default'
        ? css`
            background-image: url(${companyTypeBackground});
            background-position: right -125px top;
          `
        : image === 'success'
        ? css`
            background-image: url(${companyInformationSuccessBackground});
            background-position: right -230px top;
          `
        : undefined}
    background-size: auto 100%;
    border-radius: ${theme.borderRadiusBase}px;
    @media (max-width: ${theme.breakpoints.md}px) {
      border-radius: ${theme.borderRadiusSm}px;
      background-image: none;
    }

    .CompanyTypeLayout-FormContainer {
      width: 50%;
      min-width: 400px;
      background-color: ${colors.componentBackground};
      border-radius: ${theme.borderRadiusBase}px;
      padding: 60px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-self: stretch;
      form {
        max-width: 357px;
      }
      @media (max-width: ${theme.breakpoints.md}px) {
        width: 100%;
        min-width: auto;
        padding: 16px;
      }
    }
  }

  ${({ dir }) =>
    dir === 'rtl'
      ? css`
          .CompanyTypeLayout-Body {
            transform: scaleX(-1);
            flex-direction: row-reverse;
            .CompanyTypeLayout-FormContainer {
              transform: scaleX(-1);
            }
          }
        `
      : undefined}
`;

const CenterContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

export default CompanyTypeLayout;
