import React, { FC } from 'react';
import { Input, message, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { get } from 'lodash';
import { useLocation } from 'react-router';

import InputsGroupLayout from '~/layouts/InputsGroupLayout';
import { Form } from '..';
import WizardForm from '~/components/WizardForm';
import { useGetApplication, useSubmitStep } from '~/controllers/wizard';
import { getAuthorizedRepresentativeName } from '~/pages/wizard/application/authorizedRepresentative';
import { FormItemContextProvider } from '../Form/FormItemContext';
import { Person } from '~/types/Person';
import { ApplicationPayload } from '~/types/ApplicationPayload';
import AuthorizedRepresentativeFormItems from './authorizedRepresentative/AuthorizedRepresentativeFormItems';
import CustomFields from '~/components/CustomFields';

const stepKey = 'shareholders';
const ShareholdersSoleProprietor: FC = () => {
  const { t } = useTranslation('fillApplication');

  const applicationQuery = useGetApplication();
  const submitMutation = useSubmitStep();

  const [form] = Form.useForm();
  const [customForm] = Form.useForm();

  const onFinish = async () => {
    const person: Person | undefined = get(
      applicationQuery.data?.payload,
      getAuthorizedRepresentativeName(0)
    );
    const customFields = customForm.getFieldsValue().shareholders?.customFields;
    if (person) {
      const authorizedRepresentative: NonNullable<
        ApplicationPayload['shareholders']
      >['authorizedRepresentative'] = [
        {
          uid: person.uid,
          shares: 100,
        },
      ];
      await submitMutation.mutateAsync({
        shareholders: {
          authorizedRepresentative,
          customFields,
        },
      });
    } else {
      void message.warn(t('No authorized representative found'));
    }
  };

  const location = useLocation();

  return (
    <Container>
      <FormItemContextProvider value={{ disabledByDefault: true }}>
        <Typography.Paragraph>
          {t(
            'Since your Company Type was selected as a sole proprietor, the Shareholders section is disabled. You can go to the next step.'
          )}
        </Typography.Paragraph>
        <section>
          <Form
            onFinish={onFinish}
            name={location.pathname}
            form={form}
            initialValues={applicationQuery.data?.payload}
          >
            <AuthorizedRepresentativeFormItems
              form={form}
              name={getAuthorizedRepresentativeName(0)}
              withFiles={false}
              withProof={false}
              defaultIsAuthorizedSignatory
              withCustomFields={false}
            />
          </Form>
        </section>

        <section>
          <Typography.Title level={5}>{t('Ownership share:')}</Typography.Title>
          <Form>
            <InputsGroupLayout>
              <Form.Item
                label={t('Shares, %')}
                name={['shareholders', 'authorizedRepresentative', 'shares']}
                initialValue="100"
              >
                <Input />
              </Form.Item>
            </InputsGroupLayout>
          </Form>
        </section>
      </FormItemContextProvider>
      <WizardForm stepKey={stepKey} form={customForm}>
        <CustomFields />
      </WizardForm>
    </Container>
  );
};

const Container = styled.div`
  //
`;

export default ShareholdersSoleProprietor;
