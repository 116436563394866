import { uniqBy } from 'lodash';
import { useHistory } from 'react-router';

import { ApplicationStep } from '~/types/ApplicationStep';
import { ApplicationNavStepProps } from '~/layouts/ApplicationNavStep';
import paths from '~/pages/paths';
import { useGetApplication, useSelectStep } from './wizard';
import { getStagePayload } from '~/utils/getStagePayload';

export const useGetApplicationNavSteps = () => {
  const applicationQuery = useGetApplication();
  const stagePayload = getStagePayload.wizard(applicationQuery.data);
  const applicationSteps = stagePayload?.steps.find((item) => item.key === 'application')?.steps;

  const navSteps: Omit<ApplicationNavStepProps, 'index'>[] =
    uniqBy(
      applicationSteps?.filter((item) => item.state !== 'HIDDEN'),
      'uiKey'
    )?.map((item) => {
      const { stepName, key, state, uiKey, validationState } = item;
      return {
        name: stepName,
        stepKey: key,
        uiKey,
        route: `${paths.wizard.application}/${uiKey}`,
        done: validationState === 'OK',
        validationError: validationState === 'VALIDATION_ERROR',
        disabled: state === 'DISABLED',
      };
    }) || [];

  return { isLoading: applicationQuery.isLoading, data: navSteps };
};

export const useNavigateToStepByUiKey = () => {
  const selectStepMutation = useSelectStep();
  const history = useHistory();
  const navStepsQuery = useGetApplicationNavSteps();

  const navigate = async (uiKey: ApplicationStep['uiKey']) => {
    if (navStepsQuery.isLoading) return;
    if (selectStepMutation.isLoading) return;
    const step = navStepsQuery.data.find((item) => item.uiKey === uiKey);
    if (!step) return;
    try {
      await selectStepMutation.mutateAsync(step.stepKey);
      history.push(step.route);
    } catch (error) {
      //
    }
  };

  return { isLoading: navStepsQuery.isLoading, navigate };
};
