import React, { CSSProperties, FC } from 'react';
import { Tooltip, TooltipProps } from 'antd';
import styled from 'styled-components';

import colors from '~/constants/colors';
import { HintIcon } from '~/img';
import { useDirection } from '~/hooks/i18nHooks';
import { Direction } from '~/types/Direction';

export interface HintProps {
  text: TooltipProps['title'];
}

const Hint: FC<HintProps> = (props) => {
  const { text } = props;
  const dir = useDirection();
  return (
    <Container className="Hint">
      <Tooltip
        placement={dir === 'ltr' ? 'right' : 'left'}
        title={text}
        color={colors.blue[50]}
        overlayInnerStyle={tooltipStyle(dir)}
      >
        <HintIcon className="Hint-HintIcon" dir={dir} />
      </Tooltip>
    </Container>
  );
};

const tooltipStyle = (dir: Direction): CSSProperties => ({
  color: colors.textColor,
  padding: '8px 10px',
  direction: dir,
  textAlign: dir === 'rtl' ? 'right' : 'left',
});

const Container = styled.div`
  display: flex;
  .Hint-HintIcon {
    color: ${colors.blue[800]};
    font-size: 16px;
  }
`;

export default Hint;
