import React, { FC } from 'react';
import { SelectProps } from 'antd';
import { useHandbook } from '~/controllers/handbook';
import Select from './Select';

const BankNameSelect: FC<SelectProps<string>> = (props) => {
  const handbook = useHandbook();
  const options = handbook.data?.handbooks
    ?.find((item) => item.key === 'banks')
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ?.values?.map((item) => ({ label: item.localizedValue, value: item.apiValue!, key: item.key }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));

  return (
    <Select
      loading={handbook.isLoading}
      disabled={handbook.isLoading}
      showSearch
      options={options}
      {...props}
    />
  );
};

export default BankNameSelect;
