import React, { FC } from 'react';
import dayjs from 'dayjs';
import { Trans, useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { DateTimeFormat } from '~/constants/dateTime';
import { AgentMapItem, MapItemType } from '~/types/AgentMapItem';

import './styles.less';
import { statusColor } from '../../agent/tableElements/cells/StatusCell';

interface Props {
  record: AgentMapItem;
}

const LocationInfo: FC<Props> = (props) => {
  const { record } = props;
  const { t } = useTranslation('agent');
  return (
    <div className="LocationInfo">
      <div className="LocationInfo-CreationDate">
        <Trans t={t} i18nKey="<strong>{{type}}</strong> created {{date}}">
          <strong>
            {{
              type:
                record.type === MapItemType.LEAD
                  ? t('Lead')
                  : record.type === MapItemType.APPLICATION
                  ? t('Application')
                  : '',
            }}
          </strong>{' '}
          created {{ date: dayjs(record.creationDate).format(DateTimeFormat.DATE) }}
        </Trans>
      </div>
      <div className="LocationInfo-CompanyName">{record.companyName}</div>
      {record.addressLine && <div className="LocationInfo-AddressLine">{record.addressLine}</div>}
      {record.statusKey && record.status && (
        <Tag color={statusColor(record.statusKey)}>{record.status}</Tag>
      )}
    </div>
  );
};

export default LocationInfo;
