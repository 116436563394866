import { useMutation } from 'react-query';
import axios from './axios';
import { UaePassDocumentsImportResult } from '~/types/UaePassDocumets';

export const queryDocuments = () => {
  return axios
    .post<{ sessionKey?: string; uaepassAuthUrl?: string }>('/uaepass/documents/query', {})
    .then((res) => res.data);
};

export const useQueryDocuments = () => useMutation(queryDocuments);

export const sessionDocuments = (id: string) => {
  return axios
    .post<UaePassDocumentsImportResult>(`/uaepass/documents/session/${id}`, {})
    .then((res) => res.data);
};
