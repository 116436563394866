import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import LeadForm, { formName } from '~/components/views/agent/leadForm/LeadForm';
import buildNamePath from '~/utils/buildNamePath';
import FormDrawer from '~/components/FormDrawer';
import { AgentLead, AgentLeadPayload } from '~/types/AgentLead';
import { Form } from '~/components';
import LeadStatusTag from '~/components/StatusTag';
import { isTeamLead } from '~/utils/getDecodedJwt';
import { useActivateLead } from '~/controllers/agent';
import { queryClient } from '~/index';

interface Props {
  open: boolean;
  onClose: () => void;
  initialValues?: AgentLead;
}

export const LeadShowTerminated: FC<Props> = (props) => {
  const { t } = useTranslation('agent');
  const { open, onClose, initialValues } = props;
  const [form] = Form.useForm<AgentLeadPayload>();
  const activateLead = useActivateLead();

  const onActivateLead = (newValues: AgentLeadPayload) => {
    return activateLead
      .mutateAsync({ leadId: newValues.leadId })
      .then(async () => {
        onClose();
        await queryClient.refetchQueries('leads');
      })
      .catch((e) => console.log('error', e));
  };

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(
      initialValues
        ? {
            leadId: initialValues.leadId,
            assignee: initialValues.assignee,
            ...initialValues.payload,
          }
        : {}
    );
  }, [form, initialValues]);

  return (
    <FormDrawer
      id="leadTerminated"
      visible={open}
      onClose={onClose}
      title={
        <LeadStatusTag
          status={initialValues?.status}
          title={initialValues?.companyName}
          statusKey={initialValues?.statusKey}
        />
      }
      formName={formName}
      closable
      withContinueButton={false}
      withCustomButtons
      customButtonsProps={
        isTeamLead()
          ? [
              {
                id: 'activateLeadButton',
                title: t('Activate'),
                type: 'primary',
                htmlType: 'submit',
                loading: activateLead.isLoading,
                form: formName,
              },
            ]
          : []
      }
    >
      <LeadForm
        form={form}
        n={buildNamePath([])}
        status={initialValues?.statusKey}
        disabled
        terminationReason={initialValues?.terminationReason}
        terminationDate={initialValues?.terminationDate}
        onFinish={onActivateLead}
      />
    </FormDrawer>
  );
};
