import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { useDirection } from '~/hooks/i18nHooks';
import direction from '~/utils/direction';

interface Props {
  title?: string;
  values: ReactNode[];
}

const UnorderedList: FC<Props> = (props) => {
  const { title, values } = props;
  const dir = useDirection();

  return (
    <Container className="UnorderedList" dir={dir}>
      {title && <div className="UnorderedList-Title">{title}</div>}
      <ul>
        {values.map((item, index) => {
          // eslint-disable-next-line react/no-array-index-key
          return <li key={index}>{item}</li>;
        })}
      </ul>
    </Container>
  );
};

const Container = styled.div`
  .UnorderedList-Title {
    margin-bottom: 4px;
  }
  ul {
    ${direction.padding.left(16)};
  }
`;

export default UnorderedList;
