import React from 'react';
import { Form as AntdForm, FormInstance } from 'antd';
import { FormItemProps } from 'antd/lib/form';
import styled from 'styled-components';
import cx from 'classnames';
import { toPath } from 'lodash';

import theme from '~/constants/theme';
import { useFormItemContext } from './FormItemContext';
import colors from '~/constants/colors';
import { useSoftDeclineItemContext } from './useSoftDeclineItemContext';
import FloatLabel from './FloatLabel';
import { InputContextProvider } from './InputContext';

interface Props<Values> extends FormItemProps<Values> {
  placeholder?: any;
  label?: string;
  optional?: boolean;
  children?: JSX.Element | RenderChildren;
  disabled?: boolean;
  textarea?: boolean;
  forceDisabled?: boolean;
}

type FormItemType<Values = any> = (props: Props<Values>) => React.ReactElement;

function FormItem<Values = any>(props: Props<Values>): React.ReactElement {
  const {
    children,
    label,
    optional,
    rules = [],
    shouldUpdate,
    noStyle,
    disabled,
    className,
    textarea,
    forceDisabled,
    ...rest
  } = props;
  const { name } = rest;
  const formItemContext = useFormItemContext();
  const softDeclineContext = useSoftDeclineItemContext(name);

  const contextOptional = !!Object.keys(formItemContext.optionalFields || {}).find(
    (optionalNamePath) => {
      if (!name) return false;
      const resultOptionalNamePath = toPath(optionalNamePath).join('.');
      const resultItemNamePath = toPath([name].flat()).join('.');
      if (resultOptionalNamePath === resultItemNamePath) {
        return true;
      }
      return false;
    }
  );
  const resultOptional = optional || contextOptional;

  const hasSoftDeclineWarning = !forceDisabled && softDeclineContext.withWarning;

  const resultClassName = cx(className, {
    FormItem__withWarning: hasSoftDeclineWarning,
  });
  const resultDisabled =
    forceDisabled ||
    formItemContext.disabled ||
    (!softDeclineContext.editableBySoftDecline &&
      (formItemContext.disabledByDefault || softDeclineContext.disabled || disabled));
  const resultExtra = hasSoftDeclineWarning ? softDeclineContext.warningMessage : undefined;

  const defaultRule: typeof rules = formItemContext.defaultValidator
    ? [{ validator: formItemContext.defaultValidator }]
    : [];

  if (shouldUpdate) {
    return (
      <InputContextProvider value={{ optional: resultOptional }}>
        <AntdForm.Item shouldUpdate={shouldUpdate} noStyle>
          {(form) => (
            <AntdForm.Item
              rules={defaultRule.concat(rules)}
              noStyle={noStyle}
              extra={resultExtra}
              className={resultClassName}
              {...rest}
            >
              {typeof children === 'function' ? (children as any)?.(form) : children}
            </AntdForm.Item>
          )}
        </AntdForm.Item>
      </InputContextProvider>
    );
  }
  return (
    <InputContextProvider value={{ optional: resultOptional }}>
      <AntdForm.Item
        rules={defaultRule.concat(rules)}
        noStyle={noStyle}
        className={resultClassName}
        extra={resultExtra}
        {...rest}
      >
        <FloatLabel
          label={label}
          optional={resultOptional}
          disabled={resultDisabled}
          textarea={textarea}
        >
          {children as any}
        </FloatLabel>
      </AntdForm.Item>
    </InputContextProvider>
  );
}

export type RenderChildren<Values = any> = (form: FormInstance<Values>) => React.ReactNode;

export default styled(FormItem)`
  &.ant-form-item-with-help {
    margin-bottom: 0 !important;
  }
  .ant-form-item-explain {
    font-size: 12px;
    min-height: ${theme.formVerticalSpacing}px !important;
    transition: 0.2s ease-in color;
    &:not(.ant-form-item-explain-error, .ant-form-item-explain-warning, .ant-form-item-explain-success) {
      color: ${colors.textColor};
    }
  }
  &.ant-form-item-has-warning {
    .ant-upload-select-picture-card {
      border-color: ${colors.warningColor} !important;
    }
    .ant-upload-list-picture-card .ant-upload-list-item {
      border: 1px solid ${colors.warningColor};
    }
  }
  &:not(.ant-form-item-has-error, .FormItem__withWarning) {
    .ant-input:focus,
    .ant-input-number:focus,
    .ant-input-number-focused,
    .ant-picker-focused {
      border-color: ${colors.inputHoverBorderColor};
      box-shadow: 0 0 0 2px ${colors.inputHoverBorderColor};
    }
    .ant-select-focused .ant-select-selector {
      border-color: ${colors.inputHoverBorderColor} !important;
      box-shadow: 0 0 0 2px ${colors.inputHoverBorderColor} !important;
    }
  }
  &.FormItem__withWarning {
    // Regular input
    .ant-input:focus,
    .ant-input-number:focus,
    .ant-input-number-focused,
    .ant-picker-focused {
      border-color: ${colors.warningColor};
      box-shadow: 0 0 0 1px ${colors.warningColor};
    }
    input,
    .ant-input-number,
    .ant-select-selector,
    .ant-picker {
      border-color: ${colors.warningColor} !important;
    }
    .ant-select-focused .ant-select-selector {
      box-shadow: 0 0 0 1px ${colors.warningColor} !important;
    }
    // File input
    .ant-upload-select-picture-card,
    .DocumentInputContentMobile {
      border-color: ${colors.warningColor} !important;
    }
    .ant-upload-list-item-name {
      color: ${colors.warningColor};
    }
    .ant-upload-list-picture-card .ant-upload-list-item {
      border: 1px solid ${colors.warningColor};
    }
    .ant-form-item-extra {
      font-size: 12px;
      color: ${colors.warningColor};
    }
  }
` as FormItemType;
