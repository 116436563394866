import React, { FC } from 'react';
import { Badge, Popover } from 'antd';
import { isBoolean } from 'lodash';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import classNames from 'classnames';

import colors from '~/constants/colors';
import { primaryColor } from '~/constants/colors/defaults';
import {
  useIsAgreeWaringMessage,
  useIsAgreeWithServicesTermsAndConditions,
  useTotalPriceVisible,
} from '~/controllers/wizard';
import { ShoppingBasketIcon } from '~/img';

import AmountField from './fields/AmountField';
import Checkbox from './inputs/Checkbox';
import Space, { SpaceProps } from './Space';
import TextField from './fields/TextField';

import TotalPrice, { TotalPriceProps } from './TotalPrice';
import { useDirection } from '~/hooks/i18nHooks';
import { PricingRequestStatus } from '~/types/Services';
import { isMerchant } from '~/utils/getDecodedJwt';

interface Props extends TotalPriceProps {
  withTermsAndConditions?: boolean;
  pricingRequestStatus: PricingRequestStatus | undefined;
}

const TotalPriceMobile: FC<Props> = (props) => {
  const { pricing, isLoading, isStale, isError, withTermsAndConditions } = props;
  const { t } = useTranslation();

  const count: number = Object.entries(pricing || {}).filter(
    ([key]) => !(key === 'Total' || key === 'VAT 5%')
  ).length;

  const [open, setOpen] = useTotalPriceVisible();
  const [agree, setAgree] = useIsAgreeWithServicesTermsAndConditions();
  const warningMessage = useIsAgreeWaringMessage();

  const dir = useDirection();

  return (
    <Popover
      overlayClassName={classNames('TotalPriceMobile-PopoverOverlay', {
        'TotalPriceMobile-PopoverOverlay__rtl': dir === 'rtl',
      })}
      placement="bottom"
      trigger="click"
      visible={open}
      onVisibleChange={setOpen}
      content={
        <Space fullWidth={false} size={24} direction="vertical" dir={dir}>
          <TotalPrice {...props} />
          {withTermsAndConditions && isMerchant() && (
            <Checkbox
              id="totalPriceMobile_termsAndConditionsCheckbox"
              className="TotalPriceMobile-TermsCheckbox"
              disabled={!isBoolean(agree)}
              checked={agree}
              onChange={(e) => {
                setAgree(e.target.checked);
                if (warningMessage.message) {
                  warningMessage.clearMessage();
                }
              }}
              style={dir === 'rtl' ? { textAlign: 'right' } : undefined}
            >
              {t('I agree to the above Fees and Charges')}
            </Checkbox>
          )}
        </Space>
      }
    >
      <StyledSpace
        className="TotalPriceMobile"
        size={4}
        align="center"
        role="button"
        fullWidth={false}
        disabled1={!!(isLoading || isStale)}
        disabled2={!!isError}
        hideTotalPrice={open}
      >
        <TextField label={t('Total')}>
          <AmountField
            id="amountField_mainTotalAmount"
            amount={pricing?.Total.amount}
            alwaysShowDecimalPart
          />
        </TextField>
        <Badge
          count={count}
          color={colors.disabledBlueTextColor}
          offset={[dir === 'ltr' ? -3 : -32, 5]}
        >
          <ShoppingBasketIcon className="TotalPriceMobile-ShoppingBasketIcon" dir={dir} />
        </Badge>
      </StyledSpace>
    </Popover>
  );
};

interface ContainerProps extends SpaceProps {
  disabled1: boolean;
  disabled2: boolean;
  hideTotalPrice: boolean;
}

const StyledSpace = styled<FC<ContainerProps>>(Space)`
  height: 36px;
  cursor: pointer;
  .TextField {
    opacity: ${({ disabled1, disabled2 }) => (disabled1 || disabled2 ? 0.3 : 1)};
    filter: ${({ disabled1 }) => (disabled1 ? 'blur(3px)' : undefined)};
    filter: ${({ disabled2 }) => (disabled2 ? 'blur(7px)' : undefined)};
    opacity: ${({ hideTotalPrice }) => (hideTotalPrice ? 0 : 1)};
    transform: ${({ hideTotalPrice }) => (hideTotalPrice ? 'translate(0px, 20px)' : undefined)};
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    text-align: end;
    .TextField-Children {
      line-height: 16px;
    }
  }
  .TotalPriceMobile-ShoppingBasketIcon {
    color: ${primaryColor};
    font-size: 36px;
  }
`;

export default TotalPriceMobile;
