/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { FC, useState } from 'react';
import { Button, Dropdown, DropDownProps } from 'antd';
import styled from 'styled-components';

import { EllipsisVerticalIcon } from '~/img';

interface Props {
  overlay:
    | ((props: {
        visible: boolean;
        handleVisible: () => void;
        handleClose: () => void;
      }) => DropDownProps['overlay'])
    | DropDownProps['overlay'];
}

const RowActionsButton: FC<Props> = (props) => {
  const { overlay } = props;
  const [visible, onVisibleChange] = useState(false);
  const handleVisible = () => onVisibleChange(true);
  const handleClose = () => onVisibleChange(false);

  return (
    <div role="menu" onClick={(e) => e.stopPropagation()}>
      <Dropdown
        overlayStyle={{ zIndex: 0 }}
        trigger={['click']}
        visible={visible}
        placement="bottomRight"
        onVisibleChange={onVisibleChange}
        overlay={
          typeof overlay === 'function' ? overlay({ visible, handleVisible, handleClose }) : overlay
        }
      >
        <StyledButton icon={<EllipsisVerticalIcon />} type="text" shape="circle" size="middle" />
      </Dropdown>
    </div>
  );
};

const StyledButton = styled(Button)`
  margin-top: -9px;
  margin-bottom: -9px;
`;

export default RowActionsButton;
