import { isEqual } from 'lodash';
import { useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getState, setStateAction, setStateWithCallbackAction } from '~/slices/state';

export type SetReduxState<T> = (arg: T | ((prev: T) => T)) => void;

const useReduxState = <T = undefined>(key: string, initialState?: T): [T, SetReduxState<T>] => {
  const dispatch = useDispatch();
  const isSetInitialState = useRef(false);
  const state = useSelector(getState<T>(key));
  const resultState = isSetInitialState.current || state ? state : (initialState as T);

  const setReduxStateCallback: SetReduxState<T> = (arg) => {
    if (typeof arg === 'function') {
      const callback = arg as (prev: T) => T;
      dispatch(setStateWithCallbackAction({ key, callback }));
    } else {
      dispatch(setStateAction({ key, data: arg }));
    }
  };
  const setReduxStateRef = useRef<SetReduxState<T>>();
  setReduxStateRef.current = setReduxStateCallback;
  const setState: SetReduxState<T> = useCallback(
    (arg) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      setReduxStateRef.current && setReduxStateRef.current(arg);
    },
    [setReduxStateRef]
  );

  useEffect(() => {
    if (
      isSetInitialState.current === false &&
      (state !== undefined || isEqual(state, initialState))
    ) {
      isSetInitialState.current = true;
    }
  }, [state, initialState]);

  useEffect(() => {
    if (initialState !== undefined && isSetInitialState.current === false) {
      if (setState) {
        setState(initialState);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [resultState, setState];
};

export default useReduxState;
