import { Locale } from 'antd/lib/locale-provider';
import ar from 'antd/lib/locale/ar_EG';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getAllLanguages, updateLocale } from '~/api/locale';
import { getApplicationKey } from '~/controllers/wizard';
import { Direction } from '~/types/Direction';

export const useDirection = (): Direction => {
  const { i18n } = useTranslation();
  return i18n.dir();
};

// Used on app init to set "lang" tag at index.html
const html = document.querySelector('html');
const onLanguageChange = (lang: string) => {
  html?.setAttribute('lang', lang);
};

export const useSetHtmlLang = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language.slice(0, 2);
  useEffect(() => {
    onLanguageChange(lang);
  }, [lang]);
};

// Get locale
const locales: Record<string, Locale> = {
  ar,
};

export const useGetAntdLocale = (): Locale | undefined => {
  const { i18n } = useTranslation();
  const lang = i18n.language.slice(0, 2);
  return locales[lang];
};

// Get custom antd validation messages
export const useGetAntdValidateMessages = () => {
  const [t] = useTranslation('validation');
  return {
    required: t('Required'),
  };
};

// Change language
/*
const languageNames: Record<string, string> = {
  en: 'English',
  ar: 'عربي',
};
 */

/* eslint-disable */
// https://stackoverflow.com/a/45224904
const prettyCapitalizeFirstLetter =
  '\uD852\uDF62'.length === 1
    ? function (S: any) {
        'use-strict'; // Hooray! The browser uses UTF-32!
        return S.charAt(0).toLocaleUpperCase() + S.substring(1);
      }
    : function (S: any) {
        'use-strict';
        // The browser is using UCS16 to store UTF-16
        var code = S.charCodeAt(0) | 0;
        return code >= 0xd800 && code <= 0xdbff // Detect surrogate pair
          ? S.slice(0, 2).toLocaleUpperCase() + S.substring(2)
          : S.charAt(0).toLocaleUpperCase() + S.substring(1);
      };
/* eslint-enable */

export const useSetLanguage = () => {
  const { i18n } = useTranslation();
  const language = i18n.language.slice(0, 2);
  const localeMutation = useUpdateLocale();
  const allLanguages = useGetAllLanguages();

  const queryClient = useQueryClient();

  const validLanguagesNames: Record<string, string> = {};
  let validLanguages = (allLanguages.data?.languages || []).map((lang) => {
    return lang.substring(0, 2);
  });
  validLanguages = Array.from(new Set(validLanguages));
  validLanguages = validLanguages.filter((lang) => {
    const nativeNames = new (Intl as any).DisplayNames([lang], {
      type: 'language',
    });
    const nativeName = nativeNames.of(lang);
    if (nativeName) {
      validLanguagesNames[lang] = prettyCapitalizeFirstLetter(nativeName);
    }
    return !!nativeName;
  });

  return {
    setLanguage: (lang: string) => {
      if (validLanguagesNames[lang]) {
        void i18n.changeLanguage(lang);
      }
      localeMutation.mutate();
      void queryClient.invalidateQueries(getApplicationKey);
      void queryClient.invalidateQueries('services');
      onLanguageChange(lang);
    },
    language,
    languageNames: validLanguagesNames,
    languages: validLanguages.sort((a, b) => (a > b ? 1 : -1)).sort((a) => (a === 'en' ? -1 : 1)),
    isLoading: allLanguages.isLoading,
  };
};

export const useUpdateLocale = () => {
  return useMutation(updateLocale);
};

export const allLocalesQueryKey = 'allLocales';

export const useGetAllLanguages = () => {
  return useQuery(allLocalesQueryKey, getAllLanguages, { staleTime: Infinity, enabled: false });
};
