import { FormInstance } from 'antd';
import produce from 'immer';
import { get, isEqual, set } from 'lodash';

import { Amount } from '~/types/Amount';
import { CustomPricing, ProductMap } from '~/types/CustomPricing';
import { PricingValue } from '~/types/PricingValue';
import {
  PrimitiveFieldMap,
  PrimitiveServiceField,
  RoleFlags,
  ServiceField,
  Services,
} from '~/types/Services';
import { ServicesTotalPrice } from '~/types/ServicesTotalPriceType';
import { NamePath } from '~/utils/buildNamePath';
import { isAgent, isMerchant } from '~/utils/getDecodedJwt';
import { ServicesDraft } from './ServicesForm';

export const getCheckboxState = (
  formValues: ServicesDraft,
  serviceItem: Services['services'][number]
) => {
  const enabled = get(formValues, ['services', serviceItem.key, 'enabled']);
  const selectedServices = formValues.services as ServicesTotalPrice;
  const selectedArr = Object.entries(selectedServices || {})
    .filter(([, s]) => s.enabled)
    .map(([key]) => key);
  const selectedAtLeastOneLocal = selectedArr.length >= 1;

  const numberOfDevicesInput = serviceItem.fields.find(
    (fee) =>
      fee.key === 'terminalFee' &&
      fee.type !== 'composite' &&
      fee.type !== 'schema' &&
      fee.requiresNumber &&
      withRole(fee.visible)
  );
  const isApplications = serviceItem.key === 'nGeniusGo';

  const isCheckboxDisabled =
    selectedAtLeastOneLocal &&
    !enabled &&
    !selectedArr.filter((s) => (serviceItem.compatibleWith || []).includes(s)).length;

  return { enabled, numberOfDevicesInput, isApplications, isCheckboxDisabled };
};

export const getCustomPricing = ({
  fields,
  initialValues = {},
}: {
  fields: ServiceField[];
  initialValues?: CustomPricing;
}): CustomPricing => {
  const setPrimitivePricing = (draft: CustomPricing, fee: PrimitiveServiceField) => {
    switch (fee.type) {
      case 'pricing': {
        set(draft, [fee.key, 'type'], 'pricing');
        const value = fee.value.customValue || fee.value.defaultValue;
        if (value?.amount && get(draft, [fee.key, 'amount']) === undefined) {
          set(draft, [fee.key, 'amount'], value.amount);
        }
        if (value?.percent && get(draft, [fee.key, 'percent']) === undefined) {
          set(draft, [fee.key, 'percent'], value.percent);
        }
        break;
      }
      case 'boolean':
      case 'string': {
        set(draft, [fee.key, 'type'], fee.type);

        if (get(draft, [fee.key, 'value']) === undefined) {
          set(draft, [fee.key, 'value'], fee.value);
        }
        break;
      }
      default:
        console.log('Unsupported fee.type found: ', (fee as any).type);
        break;
    }
  };

  return produce(initialValues, (draft) => {
    fields.forEach((fee) => {
      switch (fee.type) {
        case 'pricing':
        case 'boolean':
        case 'string': {
          setPrimitivePricing(draft, fee);
          break;
        }
        case 'schema': {
          fee.fields?.forEach((subFee) => {
            setPrimitivePricing(draft, subFee);
          });
          break;
        }
        case 'composite': {
          if (fee.booleanField) {
            const subFee = fee.booleanField;
            setPrimitivePricing(draft, subFee);
          }
          if (fee.pricingField) {
            const subFee = fee.pricingField;
            setPrimitivePricing(draft, subFee);
          }
          if (fee.schemaField) {
            fee.schemaField.fields.forEach((subFee) => {
              setPrimitivePricing(draft, subFee);
            });
          }
          fee.fields?.forEach((subFee) => {
            setPrimitivePricing(draft, subFee);
          });
          break;
        }
        default:
          console.log('Unsupported fee.type found: ', (fee as any).type);
          break;
      }
    });
  });
};

export const insertOmittedData = ({
  fields,
  initialValues = {},
}: {
  fields: ServiceField[];
  initialValues?: CustomPricing;
}): CustomPricing => {
  const setPrimitivePricing = (draft: CustomPricing, fee: PrimitiveServiceField) => {
    const visible = withRole(fee.visible);
    const isOmittedData = !visible; // it means that data is not presented in the pricing form

    switch (fee.type) {
      case 'pricing': {
        set(draft, [fee.key, 'type'], 'pricing');

        if (isOmittedData) {
          const value = fee.value.defaultValue;
          if (value?.amount) {
            set(draft, [fee.key, 'amount'], value.amount);
          }
          if (value?.percent) {
            set(draft, [fee.key, 'percent'], value.percent);
          }
        }
        break;
      }
      case 'boolean':
      case 'string': {
        set(draft, [fee.key, 'type'], fee.type);

        if (isOmittedData) {
          set(draft, [fee.key, 'value'], fee.value);
        } else if (fee.type === 'string' && get(draft, [fee.key, 'value']) === undefined) {
          set(draft, [fee.key, 'value'], '');
        }
        break;
      }
      default:
        console.log('Unsupported fee.type found: ', (fee as any).type);
        break;
    }
  };

  return produce(initialValues, (draft) => {
    fields.forEach((fee) => {
      switch (fee.type) {
        case 'pricing':
        case 'boolean':
        case 'string': {
          setPrimitivePricing(draft, fee);
          break;
        }
        case 'schema': {
          fee.fields?.forEach((subFee) => {
            setPrimitivePricing(draft, subFee);
          });
          break;
        }
        case 'composite': {
          if (fee.booleanField) {
            const subFee = fee.booleanField;
            setPrimitivePricing(draft, subFee);
          }
          if (fee.pricingField) {
            const subFee = fee.pricingField;
            setPrimitivePricing(draft, subFee);
          }
          if (fee.schemaField) {
            fee.schemaField.fields.forEach((subFee) => {
              setPrimitivePricing(draft, subFee);
            });
          }
          fee.fields?.forEach((subFee) => {
            setPrimitivePricing(draft, subFee);
          });
          break;
        }
        default:
          console.log('Unsupported fee.type found: ', (fee as any).type);
          break;
      }
    });
  });
};

export const withRole = (value: RoleFlags) => {
  if (value.agent && isAgent()) return true;
  if (value.merchant && isMerchant()) return true;
  return false;
};

export const isVisible = (field: ServiceField) => {
  switch (field.type) {
    case 'composite': {
      if (field.pricingField && withRole(field.pricingField.visible)) return true;
      if (field.booleanField && withRole(field.booleanField.visible)) return true;
      if (field.schemaField && field.schemaField.fields.find((f) => withRole(f.visible)))
        return true;
      if (field.fields && field.fields.find((f) => withRole(f.visible))) return true;
      return false;
    }
    case 'schema': {
      if (field.fields && field.fields.find((f) => withRole(f.visible))) return true;
      return false;
    }
    default:
      return withRole(field.visible);
  }
};

export const isEditable = (field: ServiceField) => {
  switch (field.type) {
    case 'composite': {
      if (field.pricingField && withRole(field.pricingField.editable)) return true;
      if (field.booleanField && withRole(field.booleanField.editable)) return true;
      if (field.schemaField && field.schemaField.fields.find((f) => withRole(f.editable)))
        return true;
      if (field.fields && field.fields.find((f) => withRole(f.editable))) return true;
      return false;
    }
    case 'schema': {
      if (field.fields && field.fields.find((f) => withRole(f.editable))) return true;
      return false;
    }
    case 'pricing': {
      if (field.editable.agent && isAgent()) return true;
      return false;
    }
    default:
      return withRole(field.editable);
  }
};

export const shouldShowInput = (field: ServiceField) => {
  // according to mockup always show input for agent
  return isAgent() || isEditable(field);
};

export const isServicesChanged = (changedValues: any) => {
  const servicesChanged = Object.keys(changedValues).includes('services');
  return servicesChanged;
};

export const isPricingChanged = (args: {
  services: Services;
  currProductMap: ProductMap | undefined;
}): boolean => {
  const { services, currProductMap } = args;
  let hasChanges = false;
  if (!currProductMap) return true;
  const serviceKeys = services.services.map((s) => s.key);
  serviceKeys.forEach((serviceKey) => {
    const currFields = services.services.find((s) => s.key === serviceKey)?.fields;
    if (currFields && !hasChanges) {
      const defaultPricing = getCustomPricing({ fields: currFields });
      const currPricing = currProductMap[serviceKey] || {};
      const fullCurrPricing = insertOmittedData({
        fields: currFields,
        initialValues: currPricing,
      });
      console.log(defaultPricing, fullCurrPricing);
      if (!isEqual(defaultPricing, fullCurrPricing)) {
        hasChanges = true;
      }
    }
  });
  return hasChanges;
};

export const removeProductMap = (values: ServicesDraft) => {
  return produce(values, (draft) => {
    delete draft.productMap;
    delete draft.additionalConfigurations;
    if (draft.productMapTemp) {
      delete draft.productMapTemp;
    }
  });
};

export type ServiceInput = {
  name: NamePath;
  value: Amount | number | boolean | string | undefined;
};

export const getChangedFields = (
  formValues: Record<string, any>,
  inputs: ServiceInput[]
): ServiceInput[] => {
  const changedFields: ServiceInput[] = [];
  inputs.forEach((input) => {
    const inputValue = get(formValues, input.name);
    if (!isEqual(inputValue, input.value)) {
      changedFields.push(input);
    }
  });
  return changedFields;
};

export const getPricingField = (args: { fieldMap: PrimitiveFieldMap; key: string }) => {
  const { fieldMap, key } = args;
  const currField = fieldMap[key];
  if (currField.type === 'pricing') {
    return currField;
  }
  return undefined;
};

export const getInitialTotalFeeValue = (args: { serviceKey: string; allValues: ServicesDraft }) => {
  const { serviceKey, allValues } = args;
  const terminalFeeName = ['productMap', serviceKey, 'terminalFee'];
  const countName = ['services', serviceKey, 'terminalFee'];
  const terminalFeeValue = get(allValues, terminalFeeName);
  const count = get(allValues, countName);
  const newTotalFeeValue = terminalFeeValue && getTotalFeePricingValue(terminalFeeValue, count);
  return newTotalFeeValue;
};

export const handleNumberOfDevicesChange =
  (form: FormInstance<ServicesDraft>) =>
  (changedValues: Partial<ServicesDraft>, allValues: ServicesDraft) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    changedValues.services &&
      Object.keys(changedValues.services).forEach((serviceKey) => {
        const terminalFeeName = ['productMap', serviceKey, 'terminalFee'];
        const totalFeeName = ['productMapTemp', serviceKey, 'terminalFee'];
        const countName = ['services', serviceKey, 'terminalFee'];
        const terminalFeeValue = get(allValues, terminalFeeName);
        const count = get(allValues, countName);
        const newTotalFeeValue =
          terminalFeeValue && getTotalFeePricingValue(terminalFeeValue, count);

        form.setFields([{ name: totalFeeName, value: newTotalFeeValue }]);
      });
  };

export const handleTotalFeeChange =
  (form: FormInstance<ServicesDraft>) =>
  (changedValues: Partial<ServicesDraft>, allValues: ServicesDraft) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    changedValues.productMapTemp &&
      Object.keys(changedValues.productMapTemp).forEach((serviceKey) => {
        const terminalFeeName = ['productMap', serviceKey, 'terminalFee'];
        const totalFeeName = ['productMapTemp', serviceKey, 'terminalFee'];
        const countName = ['services', serviceKey, 'terminalFee'];
        const totalFeeValue = get(allValues, totalFeeName);
        const count = get(allValues, countName);
        const newTerminalFeeValue = getTerminalFeePricingValue(totalFeeValue, count);

        form.setFields([{ name: terminalFeeName, value: newTerminalFeeValue }]);
      });
  };

export const getTotalFeePricingValue = (
  defaultValue: PricingValue,
  count: number
): PricingValue => {
  return produce(defaultValue, (draft) => {
    if (draft.amount) {
      draft.amount.value *= count;
      draft.amount.value = Math.round(draft.amount.value * 100) / 100;
    }
  });
};

export const getTerminalFeePricingValue = (
  totalValue: PricingValue,
  count: number
): PricingValue => {
  return produce(totalValue, (draft) => {
    if (draft.amount) {
      draft.amount.value /= count;
    }
  });
};
