import React, { FC } from 'react';
import PriceRowBase from '~/components/PriceRowBase';
import PricingValueField from '~/components/PricingValueField';
import { PricingServiceField, PrimitiveFieldMap } from '~/types/Services';
import { getPricingField } from './utils';

interface Props {
  field: PricingServiceField;
  fieldMap: PrimitiveFieldMap;
  title?: string;
  withCheckmark?: boolean;
  withCross?: boolean;
  disabled?: boolean;
}

const PricingRowField: FC<Props> = (props) => {
  const {
    field,
    fieldMap,
    title,
    withCheckmark = false,
    withCross = false,
    disabled = false,
  } = props;
  const defaultAmount = field.value.defaultValue.amount;

  return (
    <PriceRowBase
      key={field.key}
      title={title || field.name}
      hint={field.hint}
      description={field.description}
      withCheckmark={withCheckmark}
      withCross={withCross}
      disabled={disabled}
    >
      <PricingValueField
        percent={field.value.defaultValue.percent}
        amount={defaultAmount}
        customPercent={
          field.percentFrom
            ? getPricingField({ fieldMap, key: field.percentFrom })?.value.customValue?.percent
            : field.value.customValue?.percent
        }
        customAmount={
          field.amountFrom
            ? getPricingField({ fieldMap, key: field.amountFrom })?.value.customValue?.amount
            : field.value.customValue?.amount
        }
        showFreeIfZeroAmount
        disabled={disabled}
      />
    </PriceRowBase>
  );
};

export default PricingRowField;
