import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components';
import { getI18n } from 'react-i18next';
import TableHeader from '~/components/views/agent/tableElements/TableHeader';
import { LocationIcon } from '~/img';
import colors from '~/constants/colors';
import { HandbookRes } from '~/types/Handbook';
import { CellText } from '~/components/views/agent/tableElements/cells/CellText';
import direction from '~/utils/direction';

function emirateCell(handbook?: HandbookRes): ColumnsType<any>[0] {
  return {
    title: () => (
      <TableHeader title={getI18n().t('Emirate', { ns: 'agent' })} sortingDataKey="emirate" />
    ),
    dataIndex: 'emirate',
    key: 'emirate',
    render: (emirate) => {
      const emirateLocalized = handbook?.handbooks
        ?.find((item) => item.key === 'city')
        ?.values?.find((item) => item.apiValue === emirate)?.localizedValue;
      if (!emirate) return null;
      return (
        <Container dir={getI18n().dir()}>
          <LocationIcon className="agentTableLocationIcon" />
          <CellText className="agentTableEmirateTitle">{emirateLocalized}</CellText>
        </Container>
      );
    },
    width: 160,
  };
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .agentTableLocationIcon {
    color: ${colors.borderColorBase};
  }

  .agentTableEmirateTitle {
    ${direction.margin.left(5)};
  }
`;

export default emirateCell;
