import React, { FC } from 'react';
import { InputNumber, InputNumberProps } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useDirection } from '~/hooks/i18nHooks';
import { Amount } from '~/types/Amount';
import direction from '~/utils/direction';
import DebugAlert from '../DebugAlert';
import FloatLabel from '../Form/FloatLabel';

interface Props extends Omit<InputNumberProps<any>, 'value' | 'onChange'> {
  label?: string;
  value?: Amount;
  onChange?: (value: Amount) => void;
  precision?: number;
}

const AmountInput: FC<Props> = (props) => {
  const { value, onChange, label, disabled, precision = 2, ...rest } = props;
  const dir = useDirection();
  const { i18n } = useTranslation();
  const language = i18n.language.slice(0, 2);
  if (!value) return <DebugAlert message="Amount input is empty" />;
  return (
    <Container className="AmountInput" dir={dir}>
      <strong className="AmountInput-Currency">
        {getCurrencyLocalizedName(value.currency, language)}
      </strong>
      <FloatLabel
        label={label}
        value={value.value}
        onChange={(v) => onChange?.(updateValue(value)(v))}
        disabled={disabled}
      >
        <InputNumber<number> precision={precision} {...rest} />
      </FloatLabel>
    </Container>
  );
};

const getCurrencyLocalizedName = (currency: string, language?: string): string | undefined => {
  return new Intl.NumberFormat(language || 'en', {
    style: 'currency',
    currency,
  })
    ?.formatToParts(0)
    ?.find(({ type }) => type === 'currency')?.value;
};

const updateValue =
  (record: Amount) =>
  (value: number): Amount => {
    return { ...record, value };
  };

const Container = styled.div`
  display: flex;
  align-items: center;
  .AmountInput-Currency {
    ${direction.margin.right(8)};
    font-size: 14px;
  }
`;

export default AmountInput;
