import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import UaePassImportButton from '~/components/UaePassImportButton';
import { Alert } from '~/components';
import { useAuthorizedRepresentativeContext } from '~/controllers/wizard';
import { isLoggedInWithUaePass } from '~/utils/getDecodedJwt';
import { CheckmarkIcon } from '~/img';
import colors from '~/constants/colors';
import { UAEPASS_DISABLE } from '~/env';

const UaePassImportDocumentAlert = () => {
  const { t } = useTranslation('fillApplication');
  const location = useLocation();
  const isUaePass = isLoggedInWithUaePass();

  const [authorizedRepresentativeContext] = useAuthorizedRepresentativeContext();
  const { onUaePassImport, activeTab, isSoleProprietor, ar1ImportStatus, ar2ImportStatus } =
    authorizedRepresentativeContext || {};
  const isAuthorizedRepresentative = location.pathname.includes('authorizedRepresentative');

  const uaePassStatus = activeTab === 'first' ? ar1ImportStatus : ar2ImportStatus;

  if (
    !isAuthorizedRepresentative ||
    !uaePassStatus ||
    !isUaePass ||
    !authorizedRepresentativeContext ||
    UAEPASS_DISABLE
  )
    return null;

  if (uaePassStatus === 'COMPLETE')
    return (
      <Alert
        message={t('Documents uploaded from UAE PASS')}
        showIcon
        type="info"
        icon={<CheckmarkIcon style={{ color: colors.successColor }} />}
      />
    );

  if (uaePassStatus === 'REJECTED')
    return (
      <Alert
        message={t(
          "We couldn't receive your information from UAE PASS, please login again without UAE PASS to enter your personal data manually"
        )}
        type="warning"
      />
    );

  return (
    <StyledAlert
      message=""
      description={
        <>
          <Typography.Title level={4}>{t('Upload data from UAE PASS')}</Typography.Title>
          <Typography.Paragraph>
            {t(
              'Since you are authorized representative of this company and login through UAE PASS, your data will be prepopulated from UAE PASS instead of filling this form. Please give consent for uploading the following documents:'
            )}
          </Typography.Paragraph>
          <Typography.Paragraph>
            • {t('Passport')} <br /> • {t('Emirates ID')} <br /> • {t('Visa')} <br />
          </Typography.Paragraph>
          {isSoleProprietor && (
            <Typography.Paragraph>
              {t(
                'Additionally if you need to add POA as a signatory proof, you can add it to your UAE PASS app by requesting from Ministry of Justice department'
              )}
            </Typography.Paragraph>
          )}
          <UaePassImportButton
            id="uaepass_import"
            onClick={
              onUaePassImport &&
              (() => {
                onUaePassImport(authorizedRepresentativeContext);
              })
            }
          />
        </>
      }
    />
  );
};

const StyledAlert = styled(Alert)`
  margin-bottom: 18px;

  div.ant-typography {
    font-size: 14px;
    line-height: 16px;
  }
`;

export default UaePassImportDocumentAlert;
