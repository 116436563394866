import React from 'react';
import styled from 'styled-components';
import { ColumnsType } from 'antd/lib/table';
import { getI18n } from 'react-i18next';
import TableHeader from '~/components/views/agent/tableElements/TableHeader';
import { CircleIcon } from '~/img';
import colors from '~/constants/colors';
import { AgentLead } from '~/types/AgentLead';
import { AgentApplication } from '~/types/AgentApplication';
import { CellText } from '~/components/views/agent/tableElements/cells/CellText';
import direction from '~/utils/direction';

function statusCell(): ColumnsType<any>[0] {
  return {
    title: () => (
      <TableHeader title={getI18n().t('Status', { ns: 'agent' })} sortingDataKey="status" />
    ),
    dataIndex: 'statusKey',
    key: 'status',
    render: (status, record: AgentLead | AgentApplication) => (
      <Container dir={getI18n().dir()}>
        <CircleIcon style={{ color: statusColor(status) }} />
        <CellText className="agentTableStatus">{record?.status}</CellText>
      </Container>
    ),
    width: 160,
  };
}

export const statusColor = (status: AgentLead['statusKey'] | AgentApplication['statusKey']) => {
  switch (status) {
    case 'NEW':
      return colors.agentStatusOrange;
    case 'fillingData':
    case 'CONTACTED':
    case 'onReview':
      return colors.agentStatusBlue;
    case 'CONVERTED':
    case 'draft':
      return colors.agentStatusPurple;
    case 'approval':
    case 'onboarding':
    case 'completed':
    case 'posPreparation':
    case 'posInstallation':
      return colors.agentStatusGreen;
    case 'duplicate':
    case 'terminated':
    case 'screeningFailed':
    case 'hardRejected':
    case 'rejected':
    case 'TERMINATED':
    default:
      return colors.agentStatusDark;
  }
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .agentTableStatus {
    ${direction.margin.left(5)};
  }
`;

export default statusCell;
