import React, { FC } from 'react';
import { SelectProps } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useHandbook } from '~/controllers/handbook';
import Select from './Select';
import { Hint } from '..';
import direction from '~/utils/direction';
import { useDirection } from '~/hooks/i18nHooks';

const IndustrySelect: FC<SelectProps<string> & { hint?: boolean }> = (props) => {
  const { hint, ...rest } = props;
  const handbook = useHandbook();
  const dir = useDirection();

  const options = handbook.data?.handbooks
    ?.find((item) => item.key === 'businessNature')
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ?.values?.map((item) => ({ label: item.localizedValue, value: item.apiValue!, key: item.key }));

  const { t } = useTranslation();

  return (
    <Container dir={dir}>
      <Select
        loading={handbook.isLoading}
        disabled={handbook.isLoading}
        showSearch
        options={options}
        {...rest}
      />
      {hint && (
        <Hint
          text={t(
            'Please select the nature of your business from the list which most closely defines your primary trading purpose.'
          )}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;

  .Hint {
    ${direction.margin.left(16)};
  }
`;

export default IndustrySelect;
