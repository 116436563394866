import React, { useContext, useMemo } from 'react';
import { withRole } from '~/components/views/ServicesView/utils';
import { PaymentMethodName } from '~/types/PaymentMethods';
import { isMerchant } from '~/utils/getDecodedJwt';
import { getStagePayload } from '~/utils/getStagePayload';
import { isResubmit } from '~/utils/isResubmit';
import { useGetServices } from './pricing';
import { useGetApplication } from './wizard';

interface ContextType {
  disabledFields: Record<string, true>;
  disableServiceSelection: boolean;
}

export const ServicesContext = React.createContext<ContextType>({
  disabledFields: {},
  disableServiceSelection: false,
});

export const useServicesContext = () => useContext(ServicesContext);

export const ServicesContextProvider = (props: any) => {
  const { children } = props;

  const servicesQuery = useGetServices();
  const applicationQuery = useGetApplication();

  const stagePayload = getStagePayload.wizard(applicationQuery.data);
  const paymentMethod = applicationQuery.data?.payload.paymentDetails?.paymentMethod;
  const isPayedOnline =
    paymentMethod === PaymentMethodName.payByCard || paymentMethod === PaymentMethodName.payByLink;

  const fieldsAffectsPrice = useMemo(() => {
    const result: Record<string, true> = {};
    servicesQuery.data?.services.forEach((service) => {
      service.fields.forEach((f) => {
        if (f.type === 'pricing' && f.affectsPrice && withRole(f.visible) && withRole(f.editable)) {
          const name = ['productMap', service.key, f.key].join('.');
          result[name] = true;
        }
      });
    });
    return result;
  }, [servicesQuery.data]);

  const pricingRequestStatus = servicesQuery.data?.pricingRequest?.status;
  const isMerchantHasRequestedCustomPricing =
    isMerchant() && (pricingRequestStatus === 'SAVED' || pricingRequestStatus === 'APPLIED');

  const errorFields = stagePayload?.activeStep.errorInformation?.errorFields;
  const resubmitAnyFields = useMemo(() => {
    return !!errorFields?.find((errorField) =>
      Object.keys(errorField).find((errName) => errName === 'ANY_FIELD')
    );
  }, [errorFields]);

  const disableInputsAffectsPricing = resubmitAnyFields
    ? false || isPayedOnline
    : isPayedOnline || isMerchantHasRequestedCustomPricing || isResubmit(applicationQuery.data);

  return (
    <ServicesContext.Provider
      value={{
        disabledFields: disableInputsAffectsPricing ? fieldsAffectsPrice : {},
        disableServiceSelection: disableInputsAffectsPricing,
      }}
    >
      {children}
    </ServicesContext.Provider>
  );
};
