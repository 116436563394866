import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import Form from '~/components/Form';
import Select from '~/components/inputs/Select';
import theme from '~/constants/theme';
import InputsGroupLayout from '~/layouts/InputsGroupLayout';
import { ServiceField } from '~/types/Services';
import { NameFn } from '~/utils/buildNamePath';
import RefundCategorySelect from './RefundCategorySelect';
import SettlementFrequencyConfig from './SettlementFrequencyConfig';
import TitleWithCollapse from './TitleWithCollapse';
import { useConfiguration } from '~/controllers/pricing';

interface Props {
  n: NameFn;
  fields: ServiceField[];
}

const PosConfiguration: FC<Props> = (props) => {
  const { n, fields } = props;
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.sm });
  const { t } = useTranslation('servicesConfig');
  const config = useConfiguration(fields);

  return (
    <TitleWithCollapse withCollapse={isTablet} header={t('Configurations')}>
      <InputsGroupLayout>
        {config.renderField('terminalBrand', ({ disabled, name }) => {
          return (
            <Form.Item name={n(name)} label={t('Terminal brand')}>
              <Select
                options={[
                  { label: 'NGenius', value: 'N_Genius' },
                  { label: 'Verifone', value: 'Verifone' },
                  { label: 'Ingenico', value: 'Ingenico' },
                  { label: 'Un Attended Kiosks', value: 'Un_Attended_Kiosks' },
                  { label: '3C YOMANI', value: '3C YOMANI' },
                  { label: '3C YOXIMO', value: '3C YOXIMO' },
                  { label: 'MOSAMBEE', value: 'MOSAMBEE' },
                ]}
                disabled={disabled}
              />
            </Form.Item>
          );
        })}
        {config.renderField('terminalModel', ({ disabled, name }) => {
          return (
            <Form.Item name={n(name)} label={t('Terminal model')}>
              <Select options={[]} disabled={disabled} />
            </Form.Item>
          );
        })}
      </InputsGroupLayout>
      <SettlementFrequencyConfig n={n} fields={fields} />
      {config.renderField('refundCategory', ({ disabled, name }) => {
        return (
          <Form.Item name={n(name)} label={t('Refund category')}>
            <RefundCategorySelect disabled={disabled} />
          </Form.Item>
        );
      })}
    </TitleWithCollapse>
  );
};

export default PosConfiguration;
