import React, { FC } from 'react';
import styled from 'styled-components';
import colors from '~/constants/colors';
import theme from '~/constants/theme';

const ButtonCard: FC = (props) => {
  const { children } = props;

  return <Container className="ButtonCard">{children}</Container>;
};

const Container = styled.div`
  height: 80px;
  width: 100%;
  padding: 16px;
  display: flex;
  flex: 1;
  border: 1px solid ${colors.borderColorBase};
  border-radius: ${theme.borderRadiusBase}px;
  @media (max-width: ${theme.breakpoints.sm}px) {
    height: unset;
  }
`;

export default ButtonCard;
