import React, { FC } from 'react';

import Form from '~/components/Form';
import Select from '~/components/inputs/Select';
import { StringServiceField } from '~/types/Services';
import { NameFn } from '~/utils/buildNamePath';
import { withRole } from './utils';

interface Props {
  n: NameFn;
  field: StringServiceField;
}

const SelectFormItem: FC<Props> = (props) => {
  const { n, field } = props;
  return (
    <Form.Item name={n([field.key, 'value'])} noStyle label={field.name}>
      <Select
        options={field.possibleValues?.map((v) => ({
          label: v.localizedValue,
          value: v.value,
        }))}
        disabled={!withRole(field.editable)}
      />
    </Form.Item>
  );
};

export default SelectFormItem;
