import React, { FC } from 'react';
import { Input, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import produce from 'immer';
import { get, set } from 'lodash';

import Form from '~/components/WizardForm';
import InputsGroupLayout from '~/layouts/InputsGroupLayout';
import Section from '~/layouts/Section ';
import { BankNameSelect, Checkbox, DocumentInput } from '~/components';
import { useGetApplication } from '~/controllers/wizard';
import LtrInput from '~/components/inputs/LtrInput';
import CustomFields from '~/components/CustomFields';

const stepKey = 'bankAccount';
const n = (namePath: string | string[]) => [stepKey].concat([namePath].flat());

const BankAccount: FC = () => {
  const { t } = useTranslation('fillApplication');

  const applicationQuery = useGetApplication();
  if (!applicationQuery.data) return null;
  const hasDevice =
    !!applicationQuery.data?.payload.services?.nGeniusPos?.enabled ||
    !!applicationQuery.data?.payload.services?.nGeniusGo?.enabled;
  const hasEcommerce = !!applicationQuery.data?.payload.services?.eCommerce?.enabled;

  return (
    <Container className="BankAccount">
      <Form
        stepKey={stepKey}
        setInitialValues={(initialValues) => {
          return produce(initialValues, (draft) => {
            if (typeof get(draft, isSameAsDevicesAccountPath) !== 'boolean' || !hasDevice) {
              set(draft, isSameAsDevicesAccountPath, false);
            }
          });
        }}
      >
        <Typography.Title>{t('Bank accounts')}</Typography.Title>
        {hasDevice && (
          <Section formItemLast>
            <Typography.Title level={4}>{t('Account for devices').toUpperCase()}</Typography.Title>
            <Typography.Paragraph>
              {t('Please upload scan of bank statement with IBAN number.')}
            </Typography.Paragraph>
            <Form.Item name={n(['devicesAccount', 'files'])}>
              <DocumentInput name="photos" variant="default" />
            </Form.Item>
            <InputsGroupLayout>
              <Form.Item label={t('Bank name')} name={n(['devicesAccount', 'bankName'])}>
                <BankNameSelect />
              </Form.Item>
              <Form.Item label="IBAN" name={n(['devicesAccount', 'IBAN'])}>
                <LtrInput />
              </Form.Item>
              <Form.Item label={t('Account name')} name={n(['devicesAccount', 'accountName'])}>
                <Input />
              </Form.Item>
              <Form.Item label={t('Account number')} name={n(['devicesAccount', 'accountNumber'])}>
                <LtrInput />
              </Form.Item>
            </InputsGroupLayout>
          </Section>
        )}
        {hasEcommerce && (
          <Section formItemLast>
            <Typography.Title level={4}>
              {t('Account for E-commerce').toUpperCase()}
            </Typography.Title>
            {hasDevice ? (
              <Form.Item name={isSameAsDevicesAccountPath} valuePropName="checked">
                <Checkbox>{t('Use the same account')}</Checkbox>
              </Form.Item>
            ) : (
              <Form.Item name={isSameAsDevicesAccountPath} noStyle />
            )}
            <Form.Visible name={isSameAsDevicesAccountPath} visibleIf={false}>
              <Typography.Paragraph>
                {t('Please upload scan of bank statement with IBAN number.')}
              </Typography.Paragraph>
              <Form.Item name={n(['ecommerceAccount', 'files'])}>
                <DocumentInput name="photos" variant="default" />
              </Form.Item>
              <InputsGroupLayout>
                <Form.Item label={t('Bank name')} name={n(['ecommerceAccount', 'bankName'])}>
                  <BankNameSelect />
                </Form.Item>
                <Form.Item label="IBAN" name={n(['ecommerceAccount', 'IBAN'])}>
                  <LtrInput />
                </Form.Item>
                <Form.Item label={t('Account name')} name={n(['ecommerceAccount', 'accountName'])}>
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t('Account number')}
                  name={n(['ecommerceAccount', 'accountNumber'])}
                >
                  <LtrInput />
                </Form.Item>
              </InputsGroupLayout>
            </Form.Visible>
          </Section>
        )}
        <CustomFields />
      </Form>
    </Container>
  );
};

const isSameAsDevicesAccountPath = n(['ecommerceAccount', 'isSameAsDevicesAccount']);

const Container = styled.div`
  //
`;

export default BankAccount;
