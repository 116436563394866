import { FormInstance, Popconfirm } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useAuthorizedRepresentativeSendInvitation,
  useGetApplication,
  useSaveStepController,
} from '~/controllers/wizard';
import { CheckThinIcon } from '~/img';
import { AuthorizedRepresentativeSendInvitationPayload } from '~/types/AuthorizedRepresentativeSendInvitationPayload';
import { isReadOnly } from '~/utils/isReadOnly';
import Button from '../../Button';

interface Props {
  getPayload: () => Partial<AuthorizedRepresentativeSendInvitationPayload>;
  form: FormInstance<any>;
}

const AuthorizedRepresentativeSendInvitation: FC<Props> = (props) => {
  const { getPayload, form } = props;
  const { t } = useTranslation('fillApplication');

  const sendInvitationMutation = useAuthorizedRepresentativeSendInvitation();
  const applicationQuery = useGetApplication();
  const saveController = useSaveStepController(form);
  const uneditableStep = isReadOnly(applicationQuery.data);

  const handleSend = () => {
    const payload = getPayload();
    try {
      const { email, phoneNumber, firstName, lastName } = payload;
      if (!email || !phoneNumber || !firstName || !lastName) return Promise.reject();
      sendInvitationMutation.mutate(payload as AuthorizedRepresentativeSendInvitationPayload);
    } catch (error) {
      console.log(error);
    }
    return Promise.resolve();
  };

  const handleClick = () => {
    return saveController.handleSave({ onAfterSave: handleSend });
  };

  const sendButton = (
    <Button
      id="sendInvitationButton"
      onClick={sendInvitationMutation.isSuccess ? undefined : handleClick}
      loading={sendInvitationMutation.isLoading}
      success={sendInvitationMutation.isSuccess}
      icon={sendInvitationMutation.isSuccess ? <CheckThinIcon /> : undefined}
      disabled={uneditableStep}
    >
      {sendInvitationMutation.isSuccess ? t('Invitation sent') : t('Send invitation')}
    </Button>
  );

  if (sendInvitationMutation.isSuccess) {
    return (
      <Popconfirm title={t('Do you want to resend an invitation?')} onConfirm={handleClick}>
        {sendButton}
      </Popconfirm>
    );
  }
  return sendButton;
};

export default AuthorizedRepresentativeSendInvitation;
