import React, { FC, useEffect } from 'react';
import { Path } from 'path-parser';
import { useHistory, useLocation } from 'react-router';
import { goToApplication } from '~/utils/goToApplication';
import paths from '../paths';
import colors from '~/constants/colors';

const path = new Path(`${paths.redirect.application}/:applicationId`);

const RedirectToApplication: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const { applicationId } = path.partialTest(location.pathname) || {};
  useEffect(() => {
    if (applicationId) {
      goToApplication(history, applicationId);
    }
  }, [applicationId, history]);

  return (
    <div style={{ color: colors.disabledBlueTextColor }}>
      Redirecting to application {applicationId}
    </div>
  );
};

export default RedirectToApplication;
