import React, { FC } from 'react';
import LibScrollbar, { positionValues, ScrollbarProps } from 'react-custom-scrollbars';

const Scrollbars: FC<ScrollbarProps> = (props) => {
  const { children } = props;

  return (
    <LibScrollbar
      onUpdate={onScroll}
      renderTrackHorizontal={(p) => (
        <div {...p} style={{ display: 'none' }} className="track-horizontal" />
      )}
      renderView={(p) => (
        <div {...p} style={{ ...p.style, overflowX: 'hidden', direction: 'ltr' }} />
      )}
      {...props}
    >
      {children}
    </LibScrollbar>
  );
};

const html = document.querySelector('html');
export const onScroll = (position: positionValues) => {
  html?.setAttribute('data-scroll', position.scrollTop.toString());
};

export default Scrollbars;
