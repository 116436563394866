import React from 'react';
import { Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components';
import { getI18n } from 'react-i18next';
import TableHeader from '~/components/views/agent/tableElements/TableHeader';
import colors from '~/constants/colors';
import { AgentLead } from '~/types/AgentLead';
import { AgentApplication } from '~/types/AgentApplication';

function riskLevelCell(): ColumnsType<any>[0] {
  return {
    title: () => (
      <TableHeader title={getI18n().t('Risk level', { ns: 'agent' })} sortingDataKey="riskLevel" />
    ),
    key: 'riskLevel',
    dataIndex: 'riskLevel',
    render: (tag: AgentLead['riskLevel'] | AgentApplication['riskLevel']) => (
      <Container>
        {tag === 'HIGH_RISK' ? (
          <Tag
            color={colors.tableHighRiskTagBackground}
            key={tag}
            className="agentTableHighRiskTag"
          >
            HIGH RISK
          </Tag>
        ) : tag === 'NORMAL' ? (
          <Tag color={colors.tableNormalTagBackground} key={tag} className="agentTableNormalTag">
            NORMAL
          </Tag>
        ) : undefined}
      </Container>
    ),
    width: 120,
  };
}

const Container = styled.div`
  span {
    font-weight: 500;
  }

  .agentTableNormalTag {
    color: ${colors.tableNormalTagText};
  }

  .agentTableHighRiskTag {
    color: ${colors.tableHighRiskTagText};
  }
`;

export default riskLevelCell;
