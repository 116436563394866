import React, { FC } from 'react';

import Space from '~/components/Space';
import { PrimitiveFieldMap, ServiceField } from '~/types/Services';
import { NameFn } from '~/utils/buildNamePath';
import CompositeFieldResolver from './CompositeFieldResolver';
import PrimitiveServiceFieldResolver from './PrimitiveFieldResolver';
import PaymentSchemaResolver from './PaymentSchemaResolver';
import { isVisible } from './utils';

interface Props {
  n: NameFn;
  field: ServiceField;
  fieldMap: PrimitiveFieldMap;
}

const FieldResolver: FC<Props> = (props) => {
  const { n, field, fieldMap } = props;

  switch (field.type) {
    case 'composite': {
      const subFields = field.fields?.filter(isVisible);
      return (
        <Space direction="vertical" size={24}>
          <CompositeFieldResolver n={n} field={field} fieldMap={fieldMap} />
          {!!subFields?.length && (
            <Space direction="vertical" size={24}>
              {subFields.map((f) => (
                <PrimitiveServiceFieldResolver key={f.key} n={n} field={f} fieldMap={fieldMap} />
              ))}
            </Space>
          )}
        </Space>
      );
    }
    case 'schema': {
      return <PaymentSchemaResolver n={n} field={field} fieldMap={fieldMap} />;
    }
    default:
      return <PrimitiveServiceFieldResolver n={n} field={field} fieldMap={fieldMap} />;
  }
};

export default FieldResolver;
