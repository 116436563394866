import { uniqBy } from 'lodash';
import { SoftDeclineError } from '~/types/SoftDeclineError';
import { isAgent } from '~/utils/getDecodedJwt';

export const getErrorMessages = (errors: SoftDeclineError[]) => {
  const errorMessageFieldName = isAgent() ? 'agentErrorMessage' : 'errorMessage';
  return uniqBy(errors, errorMessageFieldName).map((item) => {
    return item[errorMessageFieldName];
  });
};
