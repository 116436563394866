import React, { FC } from 'react';
import { Layout, ConfigProvider } from 'antd';
import styled from 'styled-components';

import { useInitApp } from './controllers/auth';
import Pages from './pages';
import TopBarLoader from './components/TopBarLoader';
import {
  useDirection,
  useGetAntdLocale,
  useGetAntdValidateMessages,
  useSetHtmlLang,
} from './hooks/i18nHooks';

const App: FC = () => {
  const locale = useGetAntdLocale();
  const direction = useDirection();
  const validateMessages = useGetAntdValidateMessages();
  useSetHtmlLang();

  const isAppReady = useInitApp();

  if (!isAppReady) return null;
  return (
    <ConfigProvider form={{ validateMessages }} locale={locale} direction={direction}>
      <StyledLayout className="App">
        <TopBarLoader />
        <Pages />
      </StyledLayout>
    </ConfigProvider>
  );
};

const StyledLayout = styled(Layout)`
  min-height: 100%;
`;

export default App;
