import qs from 'qs';
import { ApiError } from '~/types/ApiError';

import { GetTokenReq, GetTokenRes, TokenSpecRes } from '~/types/auth';
import axios from './axios';

interface AuthConstructor {
  tokenHeaderKey: string;
  tokenLocalStorageKey: string;
  refreshTokenLocalStorageKey: string;
  clientIdLocalStorageKey: string;
  realmLocalStorageKey: string;
  logoutUrlStorageKey: string;
  agentApplicationIdHeaderKey: string;
}

class Auth {
  public tokenHeaderKey: string;

  private tokenLocalStorageKey: string;

  private refreshTokenLocalStorageKey: string;

  private clientIdLocalStorageKey: string;

  private realmLocalStorageKey: string;

  private logoutUrlStorageKey: string;

  public agentApplicationIdHeaderKey: string;

  constructor({
    tokenHeaderKey,
    tokenLocalStorageKey,
    refreshTokenLocalStorageKey,
    clientIdLocalStorageKey,
    realmLocalStorageKey,
    logoutUrlStorageKey,
    agentApplicationIdHeaderKey,
  }: AuthConstructor) {
    this.tokenHeaderKey = tokenHeaderKey;
    this.tokenLocalStorageKey = tokenLocalStorageKey;
    this.refreshTokenLocalStorageKey = refreshTokenLocalStorageKey;
    this.clientIdLocalStorageKey = clientIdLocalStorageKey;
    this.realmLocalStorageKey = realmLocalStorageKey;
    this.logoutUrlStorageKey = logoutUrlStorageKey;
    this.agentApplicationIdHeaderKey = agentApplicationIdHeaderKey;
  }

  public authMethod = 'authMethod';

  setSession(
    accessToken: string,
    refreshToken: string,
    clientId: string,
    realm: string,
    logoutUrl: string
  ) {
    localStorage.setItem(this.tokenLocalStorageKey, `Bearer ${accessToken}`);
    localStorage.setItem(this.refreshTokenLocalStorageKey, refreshToken);
    localStorage.setItem(this.clientIdLocalStorageKey, clientId);
    localStorage.setItem(this.realmLocalStorageKey, realm);
    localStorage.setItem(this.logoutUrlStorageKey, logoutUrl);
    window.dispatchEvent(new Event('local-storage'));
  }

  setTokenFromHeaders(headers: any) {
    localStorage.setItem(this.tokenLocalStorageKey, `Bearer ${headers[this.tokenHeaderKey]}`);
    window.dispatchEvent(new Event('local-storage'));
  }

  getToken() {
    return localStorage.getItem(this.tokenLocalStorageKey);
  }

  setAgentApplicationId(applicationId: string) {
    sessionStorage.setItem(this.agentApplicationIdHeaderKey, applicationId);
  }

  getAgentApplicationId() {
    return sessionStorage.getItem(this.agentApplicationIdHeaderKey);
  }

  clearAgentApplicationId() {
    sessionStorage.removeItem(this.agentApplicationIdHeaderKey);
  }

  getSession() {
    return {
      token: localStorage.getItem(this.tokenLocalStorageKey),
      refreshToken: localStorage.getItem(this.refreshTokenLocalStorageKey),
      clientId: localStorage.getItem(this.clientIdLocalStorageKey),
      realm: localStorage.getItem(this.realmLocalStorageKey),
      logoutUrl: localStorage.getItem(this.logoutUrlStorageKey),
      agentApplicationId: sessionStorage.getItem(this.agentApplicationIdHeaderKey),
    };
  }

  clearSession() {
    localStorage.removeItem(this.tokenLocalStorageKey);
    localStorage.removeItem(this.refreshTokenLocalStorageKey);
    localStorage.removeItem(this.clientIdLocalStorageKey);
    localStorage.removeItem(this.realmLocalStorageKey);
    localStorage.removeItem(this.logoutUrlStorageKey);
  }
}

export const auth = new Auth({
  tokenHeaderKey: 'Authorization',
  tokenLocalStorageKey: 'token',
  refreshTokenLocalStorageKey: 'refreshToken',
  clientIdLocalStorageKey: 'clientId',
  realmLocalStorageKey: 'realm',
  logoutUrlStorageKey: 'logoutUrl',
  agentApplicationIdHeaderKey: 'Application-Id',
});

export const validateSession = () => {
  // further checks will be performed at axios.ts file in response interceptors
  return axios.get<void>('auth/merchant/check').catch((err: any) => {
    if ((err as ApiError).response?.status === 401) {
      auth.clearSession();
    }
    return err;
  });
};

export const getTokenSpec = (flowKey: string) => {
  return axios.get<TokenSpecRes>(`auth/merchant/tokenspec/${flowKey}`).then((res) => res.data);
};

export const getToken = (props: { url: TokenSpecRes['url']; data: GetTokenReq }) => {
  const { url, data } = props;
  return axios.post<GetTokenRes>(url, qs.stringify(data)).then((res) => res.data);
};

export const logout = (props: { url: TokenSpecRes['url']; data: any }) => {
  const { url, data } = props;
  return axios.post(url, qs.stringify(data)).then((res) => res.data);
};
