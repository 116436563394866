import React, { FC, useState } from 'react';
import { Input, Switch, Typography } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs from 'dayjs';

import {
  AssigneeSelect,
  Button,
  Checkbox,
  CompanyTypeSelect,
  DocumentInput,
  EmirateSelect,
  Form,
  PhoneNumberFormItem,
} from '~/components';
import InputsGroupLayout from '~/layouts/InputsGroupLayout';
import { NameFn } from '~/utils/buildNamePath';
import Section from '~/layouts/Section ';
import IndustrySelect from '~/components/inputs/IndustrySelect';
import BusinessLineSelect from '~/components/inputs/BusinessLineSelect';
import PinLocation from '~/components/PinLocation';
import EmailFormItem from '../../../inputs/EmailFormItem';
import { useHandbook } from '~/controllers/handbook';
import { CompanyTypeReq } from '~/types/CompanyType';
import { AgentLeadPayload, AgentLeadStatus } from '~/types/AgentLead';
import { DeleteIcon } from '~/img';
import TerminationDrawer from '~/components/views/agent/TerminationDrawer';
import { getDecodedJwt, isTeamLead } from '~/utils/getDecodedJwt';
import { useTerminateLead } from '~/controllers/agent';
import { handleCompanyType } from '~/utils/handleCompanyType';
import { DateTimeFormat } from '~/constants/dateTime';
import phoneValidation from '~/utils/phoneValidation';
import { validateCharacters } from '~/utils/validateCharacters';
import { maxLength } from '~/utils/lengthValidation';
import { textOnlyValidation } from '~/utils/textOnlyValidation';

interface Props {
  onFinish?: (values: AgentLeadPayload) => any;
  n: NameFn;
  disabled?: boolean;
  form: FormInstance<AgentLeadPayload>;
  status?: AgentLeadStatus;
  onTerminationSuccess?: () => void;
  terminationReason?: string;
  terminationDate?: string;
}

const LeadForm: FC<Props> = (props) => {
  const {
    onFinish,
    n,
    disabled = false,
    form,
    status,
    onTerminationSuccess,
    terminationReason,
    terminationDate,
  } = props;
  const { t } = useTranslation('agent');
  const [terminatedOpen, setTerminatedOpen] = useState(false);

  const handbookQuery = useHandbook();
  const terminateLead = useTerminateLead();

  const handbook = handbookQuery.data?.handbooks;

  const isFlagDisplayed =
    status !== 'CONTACTED' && status !== 'CONVERTED' && status !== 'TERMINATED';
  const isTerminateButtonDisplayed = status === 'NEW' || status === 'CONTACTED';
  const isTerminationReasonDisplayed = status === 'TERMINATED';

  const onValuesChange = (changedValues: CompanyTypeReq, formValues: any) => {
    if (!handbook) return;
    handleCompanyType(form, handbook, n)(changedValues, formValues);
  };

  return (
    <>
      <Container className="LeadAdd">
        <Form form={form} name={formName} onFinish={onFinish} onValuesChange={onValuesChange}>
          {isTerminationReasonDisplayed && (
            <Section>
              <Typography.Title level={4}>{t('Termination reason').toUpperCase()}</Typography.Title>
              <Input.TextArea
                rows={4}
                value={terminationReason}
                disabled={disabled}
                className="LeadAdd-TerminationReason"
              />
              <Typography.Paragraph>
                {t('Termination date')} {dayjs(terminationDate).format(DateTimeFormat.DATE_TIME)}
              </Typography.Paragraph>
            </Section>
          )}
          <Section>
            <InputsGroupLayout>
              <Form.Item
                name={n(['customerType'])}
                label={t('Customer type')}
                initialValue="Small and Medium Enterprise"
                disabled
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('Lead source')}
                name={n(['leadSource'])}
                initialValue="F2F Onboarding"
                disabled
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              {isTeamLead() ? (
                <Form.Item
                  label={t('Assignee')}
                  name={n(['assignee', 'id'])}
                  disabled={disabled}
                  rules={[{ required: true }]}
                >
                  <AssigneeSelect />
                </Form.Item>
              ) : (
                <Form.Item
                  label={t('Assignee')}
                  name={n(['assignee', 'fullName'])}
                  initialValue={getDecodedJwt()?.name}
                  disabled
                  optional
                >
                  <Input />
                </Form.Item>
              )}
              <Form.Item name={n(['team'])} initialValue="SME Merchant Team" disabled hidden />
              <Form.Item name={n(['leadId'])} hidden />
            </InputsGroupLayout>
          </Section>
          <Section>
            <Typography.Title level={4}>
              {t('Company basic details').toUpperCase()}
            </Typography.Title>

            <Typography.Paragraph>
              <Trans ns="agent" defaults="Please specify company business nature" />
            </Typography.Paragraph>
            <InputsGroupLayout>
              <Form.Item
                label={t('Company Name')}
                name={n(['companyName'])}
                rules={[
                  {
                    required: true,
                  },
                  { validator: validateCharacters({ mustIncludesLetters: true }) },
                  { validator: maxLength(100) },
                ]}
                disabled={disabled}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={n(['companyType', 'companyType'])}
                label={t('Company type')}
                rules={[{ required: true, message: t('Please select company type') }]}
                disabled={disabled}
              >
                <CompanyTypeSelect hint={false} />
              </Form.Item>
              <Form.Item
                name={n(['companyType', 'businessNature'])}
                label={t('Business nature')}
                rules={[{ required: true, message: t('Please select industry') }]}
                disabled={disabled}
              >
                <IndustrySelect hint={false} />
              </Form.Item>
              <Form.ShouldUpdate name={n(['companyType', 'businessNature'])}>
                {(newForm) => {
                  const formValues = newForm.getFieldsValue();
                  const businessNature = formValues?.companyType?.businessNature;
                  return (
                    <Form.Item
                      name={n(['companyType', 'businessIndustry'])}
                      label={t('Business line')}
                      rules={[{ required: true, message: t('Please select business line') }]}
                      disabled={!businessNature || disabled}
                    >
                      <BusinessLineSelect businessNature={businessNature} />
                    </Form.Item>
                  );
                }}
              </Form.ShouldUpdate>

              <Form.Item name={n(['companyType', 'mcc'])} hidden disabled={disabled}>
                <Input />
              </Form.Item>
            </InputsGroupLayout>
          </Section>
          <Section>
            <Typography.Title level={4}>
              {t('Company contact person').toUpperCase()}
            </Typography.Title>
            <Typography.Paragraph>
              <Trans ns="agent" defaults="Please specify company contact person" />
            </Typography.Paragraph>

            <Form.ShouldUpdate name={n(['isContacted'])}>
              {() => {
                const isContacted = form.getFieldValue(n(['isContacted']));
                return (
                  <InputsGroupLayout>
                    <Form.Item
                      label={t('First name')}
                      name={n(['contactPerson', 'firstName'])}
                      disabled={disabled}
                      optional={!isContacted}
                      rules={[
                        { required: isContacted },
                        {
                          validator: textOnlyValidation({
                            withInnerSpaces: true,
                          }),
                        },
                        { validator: maxLength(40) },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label={t('Last name')}
                      name={n(['contactPerson', 'lastName'])}
                      disabled={disabled}
                      optional={!isContacted}
                      rules={[
                        { required: isContacted },
                        {
                          validator: textOnlyValidation({
                            withInnerSpaces: true,
                          }),
                        },
                        { validator: maxLength(60) },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <EmailFormItem
                      name={n(['contactPerson', 'email'])}
                      disabled={disabled}
                      optional={!isContacted}
                      rules={[
                        { required: isContacted },
                        { type: 'email', message: t('Invalid email', { ns: 'validation' }) },
                      ]}
                    />
                    <PhoneNumberFormItem
                      name={n(['contactPerson', 'phoneNumber'])}
                      disabled={disabled}
                      optional={!isContacted}
                      rules={[{ required: isContacted }, { validator: phoneValidation }]}
                    />
                  </InputsGroupLayout>
                );
              }}
            </Form.ShouldUpdate>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
              <Form.Item
                name={n(['isContacted'])}
                valuePropName="checked"
                initialValue={false}
                disabled={disabled}
                hidden={!isFlagDisplayed}
              >
                <Switch style={{ marginRight: '10px' }} />
              </Form.Item>
              {isFlagDisplayed && (
                <Typography.Paragraph>
                  <strong>{t('Flag as Contacted')}</strong>
                </Typography.Paragraph>
              )}
            </div>
          </Section>
          <Section>
            <Typography.Title level={4}>{t('Address').toUpperCase()}</Typography.Title>
            <InputsGroupLayout>
              <Form.Item
                label={t('Address line')}
                name={n(['addresses', 'headOffice', 'addressLine'])}
                disabled={disabled}
                optional
              >
                <Input autoComplete="none" />
              </Form.Item>
              <Form.Item
                name={n(['addresses', 'headOffice', 'geolocation'])}
                disabled={disabled}
                optional
              >
                <PinLocation id="leadPinLocation" />
              </Form.Item>
              <Form.Item
                label={t('Emirate')}
                name={n(['addresses', 'headOffice', 'emirate'])}
                disabled={disabled}
                optional
              >
                <EmirateSelect />
              </Form.Item>
            </InputsGroupLayout>
            <Typography.Paragraph>
              <Trans ns="agent" defaults="Please upload a photo of outside the outlet" />
            </Typography.Paragraph>
            <Form.Item
              name={n(['addresses', 'photos', 'inside', 'files'])}
              disabled={disabled}
              optional
            >
              <DocumentInput name="photos" variant="default" maxCount={2} />
            </Form.Item>
            <Typography.Paragraph>
              <Trans ns="agent" defaults="Please upload a photo of inside the outlet" />
            </Typography.Paragraph>
            <Form.Item
              name={n(['addresses', 'photos', 'outside', 'files'])}
              disabled={disabled}
              optional
            >
              <DocumentInput name="photos" variant="default" maxCount={2} />
            </Form.Item>
          </Section>
          <Section>
            <Typography.Title level={4}>{t('Products').toUpperCase()}</Typography.Title>
            <Typography.Paragraph>
              <Trans ns="agent" defaults="Select the products that merchant is interested in" />
            </Typography.Paragraph>
            {[
              {
                id: 'leadAddProductPOS',
                name: n(['products', 'pos']),
                title: t('N-Genius POS'),
                isDisabled: (formValues: AgentLeadPayload) => !!formValues?.products?.['soft-pos'],
              },
              {
                id: 'leadAddProductECommerce',
                name: n(['products', 'e-commerce']),
                title: t('E-commerce'),
                isDisabled: (formValues: AgentLeadPayload) => !!formValues?.products?.['soft-pos'],
              },
              {
                id: 'leadAddProductSoftPOS',
                name: n(['products', 'soft-pos']),
                title: t('Soft POS'),
                isDisabled: (formValues: AgentLeadPayload) =>
                  !!formValues?.products?.pos || !!formValues?.products?.['e-commerce'],
              },
              {
                id: 'leadAddProductMyService',
                name: n(['products', 'my-service']),
                title: t('My Service'),
                isDisabled: () => false,
              },
            ].map(({ id, name, title, isDisabled }) => {
              return (
                <Form.ShouldUpdate
                  key={id}
                  shouldUpdate={(prev, next) =>
                    Object.values(prev.products || {}).filter((enabled) => enabled).length !==
                    Object.values(next.products || {}).filter((enabled) => enabled).length
                  }
                >
                  {(newForm) => {
                    const formValues = newForm.getFieldsValue();
                    return (
                      <Form.Item
                        name={name}
                        valuePropName="checked"
                        className="LeadForm-AddProduct"
                        initialValue={false}
                        disabled={disabled || isDisabled(formValues)}
                      >
                        <Checkbox>{title}</Checkbox>
                      </Form.Item>
                    );
                  }}
                </Form.ShouldUpdate>
              );
            })}
          </Section>
          <Section formItemLast>
            <Typography.Title level={4}>{t('Referral code').toUpperCase()}</Typography.Title>
            <InputsGroupLayout>
              <Form.Item
                label={t('Partner referral code')}
                name={n(['referralCode'])}
                disabled={disabled}
                optional
              >
                <Input />
              </Form.Item>
            </InputsGroupLayout>
          </Section>
          {isTerminateButtonDisplayed && (
            <Button
              id="terminateLeadButton"
              danger
              block
              type="default"
              icon={<DeleteIcon />}
              onClick={() => setTerminatedOpen(true)}
              className="LeadAdd-TerminationButton"
            >
              {t('Terminate Lead')}
            </Button>
          )}
        </Form>
      </Container>
      <TerminationDrawer
        visible={terminatedOpen}
        companyName={form?.getFieldsValue()?.companyName}
        onTerminate={async (drawerTerminationReason) => {
          const record = form?.getFieldsValue();
          if (record?.leadId) {
            await terminateLead.mutateAsync({
              id: record.leadId,
              terminationReason: drawerTerminationReason,
            });
          }
        }}
        onClose={() => setTerminatedOpen(false)}
        onSuccess={onTerminationSuccess}
      />
    </>
  );
};
export const formName = 'leadForm';

const Container = styled.div`
  .LeadForm-AddProduct {
    margin-bottom: 8px;

    span {
      font-weight: 500;
    }
  }

  .LeadAdd-TerminationReason {
    margin-bottom: 8px;
  }

  .LeadAdd-TerminationButton {
    margin-bottom: 24px;
  }
`;

export default LeadForm;
