import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Form } from '~/components';
import LeadForm, { formName } from '~/components/views/agent/leadForm/LeadForm';
import buildNamePath from '~/utils/buildNamePath';
import FormDrawer from '~/components/FormDrawer';
import { useAssignLead, useCreateApplication, useCreateLead } from '~/controllers/agent';
import { AgentLeadPayload } from '~/types/AgentLead';
import { queryClient } from '~/index';
import CreatingApplicationModal from '~/components/views/agent/CreatingApplicationModal';
import { goToApplication } from '~/utils/goToApplication';
import { isTeamLead } from '~/utils/getDecodedJwt';
import { mapItemsQueryKey } from '~/controllers/agentMap';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const LeadCreate: FC<Props> = (props) => {
  const { t } = useTranslation('agent');
  const { open, onClose } = props;
  const createLead = useCreateLead();
  const createApplication = useCreateApplication();
  const assignLead = useAssignLead();
  const [form] = Form.useForm<AgentLeadPayload>();
  const history = useHistory();
  const [creatingApplicationOpen, setCreatingApplicationOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onCreate = (newValues: AgentLeadPayload, withCloseDrawer = true) => {
    return createLead
      .mutateAsync(newValues)
      .then(async (newLead) => {
        if (isTeamLead())
          await assignLead.mutateAsync({
            leadId: newLead.leadId,
            agentId: newValues.assignee?.id ?? '',
          });

        if (withCloseDrawer) onClose();
        form.resetFields();
        await queryClient.invalidateQueries('leads');
        await queryClient.invalidateQueries(mapItemsQueryKey);
        return newLead;
      })
      .catch((e) => console.log('error', e));
  };

  const onConvertToApplication = () => {
    setLoading(true);
    return onCreate(form.getFieldsValue(), false).then((newLead) =>
      createApplication
        .mutateAsync({ leadId: newLead?.leadId ?? '' })
        .then((application) => goToApplication(history, application?.uid))
        .catch(() => {})
        .finally(() => setLoading(false))
    );
  };

  return (
    <>
      <FormDrawer
        id="leadCreate"
        visible={open}
        onClose={() => {
          // delete if you need to save the last values entered before closing the form
          form.resetFields();
          onClose();
        }}
        title={t('New Lead')}
        formName={formName}
        closable
        withCustomButtons
        withContinueButton={false}
        customButtonsProps={[
          {
            id: 'createLeadButton',
            title: t('Create Lead'),
            type: 'default',
            htmlType: 'submit',
            loading: createLead.isLoading || assignLead.isLoading,
            danger: true,
            form: formName,
          },
          {
            id: 'convertToApplicationButton',
            title: t('Convert to Application'),
            type: 'primary',
            loading,
            disabled: !loading && (createLead.isLoading || assignLead.isLoading),
            form: formName,
            onClick: () => {
              form
                .validateFields()
                .then(() => setCreatingApplicationOpen(true))
                .catch((fields) => form.scrollToField(fields?.errorFields?.[0]?.name));
            },
          },
        ]}
      >
        <LeadForm form={form} onFinish={onCreate} n={buildNamePath([])} />
      </FormDrawer>
      <CreatingApplicationModal
        visible={creatingApplicationOpen}
        onClose={() => setCreatingApplicationOpen(false)}
        onConfirm={onConvertToApplication}
        loading={loading}
      />
    </>
  );
};
