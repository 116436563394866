import React, { FC } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import WizardContent from '~/layouts/WizardContent';
import RightContent from './ServicesRightContent';
import ServicesForm from './ServicesForm';
import BottomLeftActions from '~/layouts/BottomLeftActions';
import features from '~/constants/features';
import LeftLayout from '~/layouts/LeftLayout';
import { useGetServices } from '~/controllers/pricing';
import Alert from '~/components/Alert';
import { isAgent } from '~/utils/getDecodedJwt';

export const servicesFormName = 'services';
export const servicesStepKey = servicesFormName;

const SelectServices: FC = () => {
  const { t } = useTranslation('fillApplication');
  const servicesQuery = useGetServices();

  return (
    <StyledWizardContent
      left={
        <LeftLayout>
          {servicesQuery.data?.pricingRequest?.message && isAgent() && (
            <Alert
              type="info"
              className="customPricingCompanyRequest"
              message={t('Company request')}
              description={servicesQuery.data.pricingRequest.message}
            />
          )}
          <BottomLeftActions contactUsUrl={features.contactUsLinkServices} />
        </LeftLayout>
      }
      content={
        <>
          <Typography.Title level={1}>{t('Services')}</Typography.Title>
          <ServicesForm />
        </>
      }
      right={<RightContent />}
      className="SelectServices"
    />
  );
};

const StyledWizardContent = styled(WizardContent)`
  h1 {
    margin-bottom: 16px;
  }
`;

export default SelectServices;
