import React, { FC } from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';

import colors from '~/constants/colors';
import ApplicationNavStep from './ApplicationNavStep';
import { useGetApplicationNavSteps } from '~/controllers/navigation';
import { useDirection } from '~/hooks/i18nHooks';

const ApplicationNavigation: FC = () => {
  const navStepsQuery = useGetApplicationNavSteps();
  const dir = useDirection();

  return (
    <Container className="ApplicationNavigation" dir={dir}>
      {navStepsQuery.isLoading ? (
        <Spin />
      ) : (
        navStepsQuery.data.map((item, index, arr) => {
          const isLast = index === arr.length - 1;
          return (
            <div key={item.stepKey} className="ApplicationNavigation-StepContainer">
              <ApplicationNavStep index={index + 1} {...item} />
              {!isLast && <div className="ApplicationNavigation-Divider" />}
            </div>
          );
        })
      )}
    </Container>
  );
};

const Container = styled.div`
  position: sticky;
  top: 66px;
  display: flex;
  flex-direction: column;
  .ApplicationNavigation-StepContainer {
    display: flex;
    flex-direction: column;
    .ApplicationNavigation-Divider {
      display: flex;
      border-left: 1px solid ${colors.borderColorBase};
      height: 8px;
      width: 1px;
      margin: 8px;
    }
  }
`;

export default ApplicationNavigation;
