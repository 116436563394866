export enum PaymentMethodName {
  noPayment = 'noPayment',
  payByCard = 'payByCard',
  payByLink = 'payByLink',
  paymentAfterApproval = 'paymentAfterApproval',
  chequeDeposit = 'chequeDeposit',
  cashDeposit = 'cashDeposit',
  directDebit = 'directDebit',
  bankTransfer = 'bankTransfer',
}

export type PaymentMethod =
  | {
      key: PaymentMethodName.noPayment;
      type: PaymentMethodName.noPayment;
    }
  | {
      key: PaymentMethodName.payByCard;
      type: PaymentMethodName.payByCard;
    }
  | {
      key: PaymentMethodName.payByLink;
      type: PaymentMethodName.payByLink;
    }
  | {
      key: PaymentMethodName.paymentAfterApproval;
      type: PaymentMethodName.paymentAfterApproval;
    }
  | {
      key: PaymentMethodName.chequeDeposit;
      type: PaymentMethodName.chequeDeposit;
    }
  | {
      key: PaymentMethodName.cashDeposit;
      type: PaymentMethodName.cashDeposit;
    }
  | DirectDebitPaymentType
  | BankTransferPaymentType;

export type DirectDebitPaymentType = {
  key: PaymentMethodName.directDebit;
  type: PaymentMethodName.directDebit;
  items: {
    bankCode: string;
    iban: string;
  }[];
};

export type BankTransferPaymentType = {
  key: PaymentMethodName.bankTransfer;
  type: PaymentMethodName.bankTransfer;
  accountName: string;
  bankName: string;
  iban: string;
  swiftCode: string;
};
