import React, { FC } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import WizardContent from '~/layouts/WizardContent';
import WizardContinueButton from '~/layouts/WizardContinueButton';
import { useSetApplicationStage } from '~/controllers/wizard';
import LeftLayout from '~/layouts/LeftLayout';
import BottomLeftActions from '~/layouts/BottomLeftActions';
import features from '~/constants/features';

const ErrorScreen: FC = () => {
  const { t } = useTranslation('stageResult');

  const setStageMutation = useSetApplicationStage();

  return (
    <WizardContent
      left={
        <LeftLayout>
          <BottomLeftActions contactUsUrl={features.contactUsLinkError} />
        </LeftLayout>
      }
      content={
        <Container className="ErrorScreen">
          <Typography.Title>{t('Sorry, something went wrong')}</Typography.Title>
          <Typography.Paragraph>
            {t('A technical error has occurred. Please re-submit your application.')}
          </Typography.Paragraph>
        </Container>
      }
      right={
        <WizardContinueButton
          id="errorStage_tryAgainButton"
          onClick={() => setStageMutation.mutate('fillApplication')}
          loading={setStageMutation.isLoading}
        >
          {t('Try again')}
        </WizardContinueButton>
      }
    />
  );
};

const Container = styled.div`
  //
`;

export default ErrorScreen;
