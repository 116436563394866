import React from 'react';
import styled from 'styled-components';
import { usePath } from '~/hooks';
import { ArrowSortIcon } from '~/img';
import colors from '~/constants/colors';

interface Props {
  title: string;
  sortingDataKey?: string;
}

const TableHeader = ({ title, sortingDataKey }: Props) => {
  const { params, changePath } = usePath();
  const sortOrder = params?.filter?.sortOrder;
  const sortField = params?.filter?.sortField;

  const clickOnSort = () => {
    if (sortOrder === 'asc' && sortingDataKey === sortField) {
      changePath({ filter: { sortField: sortingDataKey, sortOrder: 'desc' } });
    } else if (sortOrder === 'desc' && sortingDataKey === sortField) {
      changePath({ filter: { sortField: undefined, sortOrder: undefined } });
    } else {
      changePath({ filter: { sortField: sortingDataKey, sortOrder: 'asc' } });
    }
  };

  return (
    <Container onClick={sortingDataKey ? clickOnSort : () => {}}>
      <div className="Sorting-Title">{title}</div>
      {!!sortingDataKey &&
        (sortOrder === 'asc' && sortingDataKey === sortField ? (
          <ArrowSortIcon className="Sorting-ArrowIconAcs" />
        ) : sortOrder === 'desc' && sortingDataKey === sortField ? (
          <ArrowSortIcon className="Sorting-ArrowIconDesc" />
        ) : (
          <ArrowSortIcon className="Sorting-ArrowIconOff" />
        ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  cursor: pointer;
  padding: 7px 10px;

  .Sorting-Title {
    margin-right: 8px;
  }

  .Sorting-ArrowIconAcs {
    color: ${colors.textColor};
    margin: 2px 0;
  }

  .Sorting-ArrowIconDesc {
    transform: rotate(180deg);
    color: ${colors.textColor};
  }

  .Sorting-ArrowIconOff {
    margin: 2px 0;
    color: ${colors.componentBackground};
  }
`;

export default TableHeader;
