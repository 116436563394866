export const agent = {
  applications: 'Applications',
  leads: 'Leads',
  map: 'Map',
  menu: 'Menu',
  searchPlaceholder: 'Search by Company name or Address',
  OPENED: 'Opened',
  DONE: 'Done',
  ALL: 'All',
  APPLICATION: 'Application',
  LEAD: 'Lead',
  Status: 'Status',
  Emirate: 'Emirate',
  CreatedDate: 'Created date',
  'Created date from': 'Created date from',
  'Created date to': 'Created date to',
  Assignee: 'Assignee',
  Filter: 'Filter',
  'Sort by': 'Sort by',
  Terminate: 'Terminate',
  'Flag as Contacted': 'Flag as Contacted',
  'Create Application': 'Create Application',
  Confirm: 'Confirm',
  'Are you ready to create Merchant Application?': 'Are you ready to create Merchant Application?',
  'Merchant onboarding application will be created for this merchant. Please confirm to proceed.':
    'Merchant onboarding application will be created for this merchant. Please confirm to proceed.',
  'Are you sure?': 'Are you sure?',
  'Please define the reason why you would like to terminate merchant onboarding process.':
    'Please define the reason why you would like to terminate merchant onboarding process.',
  'Reason for termination': 'Reason for termination',
  'Termination reason': 'Termination reason',
  'Termination date': 'Termination date',
  Save: 'Save',
  'Convert to Application': 'Convert to Application',
  'New Lead': 'New Lead',
  'Create Lead': 'Create Lead',
  'Lead source': 'Lead source',
  'Customer type': 'Customer type',
  'Company basic details': 'Company basic details',
  'Company Name': 'Company Name',
  'Company type': 'Company type',
  'Company contact person': 'Company contact person',
  'Business nature': 'Business nature',
  'Please specify company business nature': 'Please specify company business nature',
  'Please specify company contact person': 'Please specify company contact person',
  'Please upload a photo of outside the outlet': 'Please upload a photo of outside the outlet',
  'Please upload a photo of inside the outlet': 'Please upload a photo of inside the outlet',
  'Select the products that merchant is interested in':
    'Select the products that merchant is interested in',
  'Please select company type': 'Please select company type',
  'Please select industry': 'Please select industry',
  'Please select business line': 'Please select business line',
  'First name': 'First name',
  'Last name': 'Last name',
  Address: 'Address',
  'Address line': 'Address line',
  Products: 'Products',
  'N-Genius POS': 'N-Genius POS',
  'E-commerce': 'E-commerce',
  'Soft POS': 'Soft POS',
  'My Service': 'My Service',
  'Referral code': 'Referral code',
  'Partner referral code': 'Partner referral code',
  'Terminate Lead': 'Terminate Lead',
  'Terminate Application': 'Terminate Application',
  'Go to Application': 'Go to Application',
  Activate: 'Activate',
  'Business line': 'Business line',
  Company: 'Company',
  Contact: 'Contact',
  Created: 'Created',
  Request: 'Request',
  'Risk level': 'Risk level',
  Updated: 'Updated',
  '<strong>{{type}}</strong> created {{date}}': '<strong>{{type}}</strong> created {{date}}',
  '"{{companyName}}" company will be deleted.': '"{{companyName}}" company will be deleted.',
};

export const leadStatus = {
  NEW: 'New',
  CONTACTED: 'Contacted',
  TERMINATED: 'Terminated',
  CONVERTED: 'Converted to Application',
};

export const applicationStatus = {
  duplicate: 'Duplicate',
  draft: 'Draft',
  terminated: 'Terminated',
  fillingData: 'Filling Data',
  rejected: 'Rejected',
  screeningFailed: 'Screening failed',
  onReview: 'On review',
  approval: 'Approval ',
  onboarding: 'Onboarding',
  posPreparation: 'POS Preparation',
  posInstallation: 'POS Installation',
  completed: 'Completed',
  hardRejected: 'Hard Rejected',
  screening: 'Screening',
  contract: 'Contract',
  payment: 'Payment',
  submissionFailure: 'Submission failure',
};
