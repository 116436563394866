import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CardMobile, TextField } from '~/components';
import GroupLayout from '~/layouts/GroupLayout';
import { RepresentativeRowDataType } from './RepresentativesTab';

const RepresentativeCardMobile: FC<RepresentativeRowDataType> = (props) => {
  const { fullName, designation, email, phoneNumber } = props;
  const { t } = useTranslation('fillApplication');

  return (
    <CardMobile>
      <GroupLayout gutter={[16, 16]} xs={12} sm={12}>
        <TextField label={t('Full name')}>{fullName}</TextField>
        <TextField label={t('Designation')}>{designation}</TextField>
        <TextField label={t('Phone number')}>
          <bdi>{phoneNumber}</bdi>
        </TextField>
        <TextField label={t('Email')}>{email}</TextField>
      </GroupLayout>
    </CardMobile>
  );
};

export default RepresentativeCardMobile;
