import { Skeleton } from 'antd';
import React, { CSSProperties, FC } from 'react';
import { useHandbook } from '~/controllers/handbook';

interface Props {
  value: string | undefined;
}

const BankNameField: FC<Props> = (props) => {
  const { value } = props;

  const handbook = useHandbook();
  const valuesRecord: Record<string, string> = {};
  handbook.data?.handbooks
    ?.find((item) => item.key === 'banks')
    ?.values?.forEach((bank) => {
      if (bank.apiValue && bank.localizedValue) {
        valuesRecord[bank.apiValue] = bank.localizedValue;
      }
    });

  if (handbook.isLoading) return <Skeleton.Button style={loadingStyle} />;
  return <>{value ? valuesRecord[value] || value : value}</>;
};

const loadingStyle: CSSProperties = { height: 22 };

export default React.memo(BankNameField, (prev, next) => prev.value === next.value);
