import React, { FC } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import WizardContent from '~/layouts/WizardContent';
import PaymentRightContent from './PaymentRightContent';
import LeftLayout from '~/layouts/LeftLayout';
import BottomLeftActions from '~/layouts/BottomLeftActions';
import features from '~/constants/features';
import PaymentView from './PaymentView';

const PaymentScreen: FC = () => {
  const { t } = useTranslation();

  return (
    <WizardContent
      content={
        <>
          <Typography.Title>{t('Payment')}</Typography.Title>
          <PaymentView />
        </>
      }
      left={
        <LeftLayout>
          <BottomLeftActions contactUsUrl={features.contactUsLinkPayment} />
        </LeftLayout>
      }
      right={<PaymentRightContent />}
    />
  );
};

export default PaymentScreen;
