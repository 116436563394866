import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useGetApplication, useSubmitStepIsLoading } from '~/controllers/wizard';
import { useDraftIsStale } from '~/controllers/draft';
import theme from '~/constants/theme';
import { isReadOnly } from '~/utils/isReadOnly';
import Button, { ButtonProps } from '~/components/Button';

interface Props extends ButtonProps {
  formName?: string;
  children?: React.ReactNode;
}

const WizardContinueButton: FC<Props> = (props) => {
  const { formName, disabled, children, ...rest } = props;
  const { t } = useTranslation();
  const [isLoading] = useSubmitStepIsLoading();
  const { isStale } = useDraftIsStale();

  const applicationQuery = useGetApplication();
  const uneditableStep = isReadOnly(applicationQuery.data);

  return (
    <StyledButton
      className="WizardContinueButton"
      htmlType="submit"
      form={formName}
      size="large"
      type="primary"
      block
      loading={isLoading}
      disabled={disabled || isStale || uneditableStep}
      {...rest}
    >
      {children || t('Continue')}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  @media (max-width: ${theme.breakpoints.xs}px) {
    width: 100% !important;
  }
`;

export default WizardContinueButton;
