import React, { FC } from 'react';
import styled from 'styled-components';
import cx from 'classnames';

import colors from '~/constants/colors';
import theme from '~/constants/theme';
import companyTypeBackground from '~/img/companyTypeBackground.jpg';
import direction from '~/utils/direction';
import { useDirection } from '~/hooks/i18nHooks';

interface Props {
  header?: JSX.Element;
  className?: string;
}

const CompanyTypeContentLayout: FC<Props> = (props) => {
  const { className, header, children } = props;
  const dir = useDirection();

  return (
    <Container className={cx('CompanyTypeContentLayout', className)} dir={dir}>
      {header}
      <div className="CompanyTypeContentLayout-ContentAndImageContainer">
        <div className="CompanyTypeContentLayout-Content">{children}</div>
        <div className="CompanyTypeContentLayout-Image" />
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .CompanyTypeContentLayout-ContentAndImageContainer {
    display: flex;
    flex: 1;
    max-height: 700px;
    .CompanyTypeContentLayout-Image {
      display: none;
    }
  }
  @media (max-width: ${theme.breakpoints.md}px) {
    .CompanyTypeContentLayout-ContentAndImageContainer {
      .CompanyTypeContentLayout-Content {
        width: 40%;
      }
      .CompanyTypeContentLayout-Image {
        display: flex;
        flex: 1;
        ${direction.margin.left(40)};
        min-heigh: 380px !important;
        max-height: 700px;
        background-color: ${colors.componentBackground};
        background-image: url(${companyTypeBackground});
        background-size: auto 100%;
        background-position: right -50px top 0px;
        border-radius: 14px;
      }
    }
  }
  @media (max-width: 650px) {
    .CompanyTypeContentLayout-ContentAndImageContainer {
      .CompanyTypeContentLayout-Content {
        width: 100%;
      }
      .CompanyTypeContentLayout-Image {
        display: none;
      }
    }
  }
`;

export default CompanyTypeContentLayout;
