import React, { FC } from 'react';
import { Space, Spin, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import colors from '~/constants/colors';

import { UploadIcon } from '~/img';
import humanFileSize from '~/utils/humanFileSize';
import OptionalLabel from './OptionalLabel';
import direction from '~/utils/direction';
import { DirectionProp } from '~/types/Direction';
import { useDirection } from '~/hooks/i18nHooks';

interface Props {
  maxSizeB: number;
  id?: string;
  loading: boolean;
  optional?: boolean;
}

const DocumentInputContent: FC<Props> = (props) => {
  const { maxSizeB, id, loading, optional } = props;
  const { t } = useTranslation();
  const dir = useDirection();

  if (loading) return <Spin />;

  return (
    <Container id={id} className="DocumentInputContent" dir={dir}>
      <Space size={12} className="DocumentInputContent-TopText">
        <UploadIcon className="DocumentInputContent-UploadIcon" />
        <Typography.Paragraph className="DocumentInputContent-Paragraph">
          <Trans t={t} defaults="Drag & Drop your files here or <strong>Browse</strong>" />
          {optional && <OptionalLabel />}
        </Typography.Paragraph>
      </Space>
      <div className="DocumentInputContent-BottomText">
        {t('Files supported: JPG, JPEG, DOC, DOCX, PNG, PDF, RTF, GIF. Max size {{size}}', {
          size: humanFileSize(maxSizeB, true, 0),
        })}
      </div>
    </Container>
  );
};

const Container = styled.div<DirectionProp>`
  padding: 16px;
  .DocumentInputContent-TopText {
    .DocumentInputContent-UploadIcon {
      font-size: 24px;
      color: ${colors.grey[600]};
    }
    .DocumentInputContent-Paragraph {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      strong {
        ${direction.margin.left(4)};
      }
      .OptionalLabel {
        ${direction.margin.left(16)};
      }
    }
  }
  .DocumentInputContent-BottomText {
    color: ${colors.grey[600]};
    font-size: 10px;
    line-height: 16px;
    margin-top: 8px;
  }
`;

export default DocumentInputContent;
