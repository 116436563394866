import styled from 'styled-components';

import { Checkbox as AntdCheckbox } from 'antd';

import theme from '~/constants/theme';

const Checkbox = styled(AntdCheckbox)`
  .ant-checkbox-inner {
    border-radius: ${theme.checkboxBorderRadius}px;
  }
`;

export default Checkbox;
