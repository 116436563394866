import React, { FC } from 'react';

import { isAgent } from '~/utils/getDecodedJwt';
import ContactUs from './ContactUs';
import GoToDashboardButton from './GoToDashboardButton';

interface Props {
  contactUsUrl: string;
}

const BottomLeftActions: FC<Props> = (props) => {
  const { contactUsUrl } = props;
  return isAgent() ? <GoToDashboardButton /> : <ContactUs url={contactUsUrl} />;
};

export default BottomLeftActions;
