import React, { FC } from 'react';
import { Modal, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  visible: boolean;
  onClose: () => void;
  onConfirm: () => void;
  loading?: boolean;
}

const CreatingApplicationModal: FC<Props> = (props) => {
  const { t } = useTranslation('agent');
  const { visible, onClose, onConfirm, loading = false } = props;

  return (
    <StyledModal
      visible={visible}
      cancelButtonProps={{
        onClick: onClose,
        disabled: loading,
      }}
      okButtonProps={{
        onClick: onConfirm,
        loading,
      }}
      okText={t('Confirm', { ns: 'common' })}
      centered
      closable={false}
    >
      <Typography.Title level={5}>
        {t('Are you ready to create Merchant Application?')}
      </Typography.Title>
      <Typography.Paragraph>
        {t(
          'Merchant onboarding application will be created for this merchant. Please confirm to proceed.'
        )}
      </Typography.Paragraph>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 32px;
  }
`;

export default CreatingApplicationModal;
