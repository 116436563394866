import React, { FC } from 'react';
import { Input, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

import Button from '~/components/Button';
import Form from '~/components/Form';
import EmailFormItem from '~/components/inputs/EmailFormItem';
import InputsGroupLayout from '~/layouts/InputsGroupLayout';
import { useSendEmailPayByLink } from '~/controllers/payment';
import { useGetApplication } from '~/controllers/wizard';
import DateInput from '~/components/inputs/DateInput';
import { isReadOnly } from '~/utils/isReadOnly';

const PayByLink: FC = () => {
  const { t } = useTranslation('payment');
  const [form] = Form.useForm();

  const applicationQuery = useGetApplication();
  const readOnly = isReadOnly(applicationQuery.data);
  const stagePayload =
    applicationQuery?.data?.stage === 'payment' ? applicationQuery?.data?.stagePayload : undefined;
  const alreadyPaid =
    !!stagePayload?.alreadyPaid && stagePayload.paymentDetails?.paymentMethod === 'payByLink';
  const authorizedSignatoryEmail = applicationQuery.data?.payload.authorizedSignatory?.email;

  const sendEmailMutation = useSendEmailPayByLink();
  const lastEmail = sendEmailMutation.data?.email;

  const sendLink = async ({ email }: { email: string }) => {
    await sendEmailMutation.mutateAsync(email);
    form.resetFields();
  };

  if (alreadyPaid)
    return (
      <>
        <Typography.Paragraph>{t('Transaction successful.')}</Typography.Paragraph>
        <br />
        <Form.Item
          name={['paymentReference']}
          label={t('Payment reference')}
          rules={[{ required: true }]}
        >
          <Input disabled />
        </Form.Item>
        <InputsGroupLayout>
          <Form.Item name={['paymentDate']} label={t('Payment date')} rules={[{ required: true }]}>
            <DateInput disabled />
          </Form.Item>
        </InputsGroupLayout>
      </>
    );
  return (
    <>
      <Typography.Paragraph>
        {lastEmail ? (
          <Trans t={t} i18nKey="A payment link was sent to email <strong>{{ lastEmail }}</strong>">
            A payment link was sent to email <strong>{{ lastEmail }}</strong>
          </Trans>
        ) : (
          <Trans t={t} i18nKey="A payment link will be sent to the specified email">
            A payment link will be sent to the specified email
          </Trans>
        )}
      </Typography.Paragraph>
      <Form form={form} onFinish={sendLink} name="payByLink">
        <InputsGroupLayout>
          <EmailFormItem
            name="email"
            rules={[{ required: true }, { type: 'email' }]}
            initialValue={authorizedSignatoryEmail}
          />
          <Button
            id="payByLink_sendEmail"
            onClick={form.submit}
            loading={sendEmailMutation.isLoading}
            disabled={readOnly}
          >
            {lastEmail ? t('Resend a link') : t('Send a link')}
          </Button>
        </InputsGroupLayout>
      </Form>
    </>
  );
};

export default PayByLink;
