import { FormProps, message, Typography } from 'antd';
import produce from 'immer';
import { get, isEmpty, set } from 'lodash';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce/lib';
import { CardMobile, Collapse, Space } from '~/components';
import SaveWizardStep from '~/components/SaveWizardStep';
import Form from '~/components/WizardForm';
import colors from '~/constants/colors';
import theme from '~/constants/theme';
import {
  useApplyCustomPricingController,
  useCustomPricingDrawerOpen,
  useFieldMap,
  useGetServices,
} from '~/controllers/pricing';
import { ServicesContextProvider } from '~/controllers/ServicesContext';
import { useTotalPriceController } from '~/controllers/totalPrice';
import { useGetApplication, useIsAtLeastOneServiceSelected } from '~/controllers/wizard';
import { useMutableCallback } from '~/hooks';
import { ApplicationPayload } from '~/types/ApplicationPayload';
import { CustomPricing, ProductMap } from '~/types/CustomPricing';
import { ServicesKeys } from '~/types/Services';
import buildNamePath from '~/utils/buildNamePath';
import { isAgent } from '~/utils/getDecodedJwt';
import AdditionalServicesFields from './AdditionalServicesFields';
import EcomConfiguration from './EcomConfiguration';
import FeesAndChargesFields from './FeesAndChargesFields';
import GeneralConfigurationFields from './GeneralConfigurationFields';
import LongDescription from './LongDescription';
import NumberOfDevicesFormItem from './NumberOfDevicesFormItem';
import PaymentSchemaFields from './PaymentSchemaFields';
import PosConfiguration from './PosConfiguration';
import { SAVE_STEP_FORM } from './RequestCustomPricing';
import { servicesFormName, servicesStepKey } from './SelectServices';
import ServiceCheckbox from './ServiceCheckbox';
import { APPLY_CUSTOM_PRICING_FORM } from './ServicesRightContent';
import SoftPosConfiguration from './SoftPosConfiguration';
import {
  getCheckboxState,
  getCustomPricing,
  getInitialTotalFeeValue,
  handleNumberOfDevicesChange,
  handleTotalFeeChange,
  isServicesChanged,
  isVisible,
  removeProductMap,
} from './utils';
import WebsiteConfiguration from './WebsiteConfiguration';
import Alert from '../../Alert';

const CALCULATE_TOTAL_PRICE_DEBOUNCE_MS = 1000;

type PanelErrorType = Record<ServicesKeys, boolean>;
const initialPanelErrorState: PanelErrorType = {
  nGeniusPos: false,
  eCommerce: false,
  nGeniusGo: false,
  myService: false,
};

export type ServicesDraft = {
  services: Partial<ApplicationPayload['services']>;
  productMap?: ProductMap;
  additionalConfigurations?: CustomPricing;
  productMapTemp?: any; // Prop to store some temporary data
  isAgreeWithServicesTermsAndConditions: boolean;
};

interface Props {
  hideServiceSelection?: boolean;
  hideDisabledServices?: boolean;
  disabled?: boolean;
}

const ServicesForm: FC<Props> = (props) => {
  const { hideServiceSelection, hideDisabledServices, disabled } = props;
  const { t } = useTranslation('fillApplication');
  const [form] = Form.useForm<ServicesDraft>();
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.md });
  const isMobile = useMediaQuery({ maxWidth: theme.breakpoints.sm });

  const servicesQuery = useGetServices();
  const fieldMap = useFieldMap();
  const applyPricingMutation = useApplyCustomPricingController(form);
  const [, handleIsSelected] = useIsAtLeastOneServiceSelected();
  const isServiceSingle = servicesQuery.data?.services.length === 1;

  const application = useGetApplication();
  const isResubmit = application.data?.payload?.salesforceApplicationNumber;
  const predefineProducts = application.data?.payload?.products;

  // Validation
  const [isPanelError, setPanelError] = useState<PanelErrorType>(initialPanelErrorState);
  const onFinishFailed: FormProps['onFinishFailed'] = ({ errorFields }) => {
    const result = produce(initialPanelErrorState, (draft) => {
      errorFields.forEach(({ name }) => {
        const serviceKey = name[1] as ServicesKeys;
        draft[serviceKey] = true;
      });
    });
    setPanelError(result);
  };

  // Total price calculation
  const totalPriceController = useTotalPriceController();
  const debouncedRecalculate = useDebouncedCallback((formValues: ServicesDraft) => {
    totalPriceController.mutate(formValues);
  }, CALCULATE_TOTAL_PRICE_DEBOUNCE_MS);

  const handleValuesChange = useMutableCallback((changedValues) => {
    // need to wait till form items will be rendered and form state become consistent
    setTimeout(() => {
      const allFormValues = form.getFieldsValue();
      handleTotalFeeChange(form)(changedValues, allFormValues);
      handleNumberOfDevicesChange(form)(changedValues, allFormValues);
    }, 0);

    //
    if (!isServicesChanged(changedValues)) return;
    if (isPanelError !== initialPanelErrorState) {
      setPanelError(initialPanelErrorState);
    }
    // need to wait till form items will be rendered and form state become consistent
    setTimeout(() => {
      const allFormValues = form.getFieldsValue();
      handleIsSelected(allFormValues);
      debouncedRecalculate(allFormValues);
    }, 0);
  });

  const [, setPricingRequestDrawerOpen] = useCustomPricingDrawerOpen();

  const handlePricingRequestDrawer = () => {
    setPricingRequestDrawerOpen(true);
    return Promise.resolve();
  };

  const savePricing = (): Promise<any> => {
    return applyPricingMutation.mutateAsync();
  };

  if (!servicesQuery.data) return null;
  if (!fieldMap) return null;
  return (
    <ServicesContextProvider>
      <Container>
        <SaveWizardStep
          name={APPLY_CUSTOM_PRICING_FORM}
          mainForm={form}
          onBeforeSave={applyPricingMutation.mutateAsync}
          parseBeforeSave={removeProductMap}
          onAfterSave={() => {
            void message.success(t('Saved', { ns: 'common' }));
            return Promise.resolve();
          }}
        />
        <SaveWizardStep
          name={SAVE_STEP_FORM}
          mainForm={form}
          onBeforeSave={savePricing}
          onAfterSave={handlePricingRequestDrawer}
          parseBeforeSave={removeProductMap}
        />
        <Form<ServicesDraft>
          disabled={disabled}
          stepKey={servicesStepKey}
          name={servicesFormName}
          form={form}
          onBeforeFinish={savePricing}
          onValuesChange={handleValuesChange}
          onFinishFailed={onFinishFailed}
          handleDraftDataBeforeSave={removeProductMap}
          shouldSaveDraft={isServicesChanged}
          parseBeforeSubmit={(values) => {
            return removeProductMap({
              ...values,
              // form can only by submitted if user has agreed with Terms and Conditions, so it's always true when parseBeforeSubmit called
              isAgreeWithServicesTermsAndConditions: true,
            });
          }}
          setInitialValues={(initialValues) => {
            const newInitialValues = produce(initialValues, (draft) => {
              servicesQuery.data.services.forEach((item) => {
                item.fields.forEach((fee) => {
                  if (
                    typeof get(draft, ['services', item.key, fee.key]) !== 'number' &&
                    fee.type !== 'composite' &&
                    fee.type !== 'schema' &&
                    fee.requiresNumber
                  ) {
                    set(draft, ['services', item.key, fee.key], 1);
                  }
                });
                set(draft, ['services', item.key, 'type'], item.type);

                if (isServiceSingle) {
                  set(draft, ['services', item.key, 'enabled'], true);
                }

                set(
                  draft,
                  ['productMap', item.key],
                  getCustomPricing({
                    fields: item.fields,
                    initialValues: get(draft, ['productMap', item.key]),
                  })
                );

                set(
                  draft,
                  ['productMapTemp', item.key, 'terminalFee'],
                  getInitialTotalFeeValue({
                    serviceKey: item.key,
                    allValues: draft,
                  })
                );
                if (isEmpty(initialValues.services) && predefineProducts?.[item.type]) {
                  set(draft, ['services', item.key, 'enabled'], true);
                }
              });

              if (servicesQuery.data.additionalConfigurations) {
                const fields = servicesQuery.data.additionalConfigurations;
                set(
                  draft,
                  ['additionalConfigurations'],
                  getCustomPricing({
                    fields,
                    initialValues: get(draft, ['additionalConfigurations']),
                  })
                );
              }
            });
            handleIsSelected(newInitialValues);
            debouncedRecalculate(newInitialValues);
            return newInitialValues;
          }}
        >
          {isTablet && servicesQuery.data?.pricingRequest?.message && isAgent() && (
            <Alert
              type="info"
              message={t('Company request')}
              description={servicesQuery.data.pricingRequest.message}
              className="ServicesForm-CardMobile customPricingCompanyRequest"
            />
          )}

          {!hideServiceSelection && !isMobile && !isServiceSingle ? (
            <Space className="ServicesForm-Space" size={20}>
              {servicesQuery.data.services.map((item) => {
                return (
                  <Form.ShouldUpdate
                    key={item.key}
                    shouldUpdate={(prev, next) =>
                      Object.entries(prev.services || {}).filter(([, s]: any[]) => s.enabled)
                        .length !==
                      Object.entries(next.services || {}).filter(([, s]: any[]) => s.enabled).length
                    }
                  >
                    {() => {
                      const formValues = form.getFieldsValue();
                      const { enabled, isCheckboxDisabled, numberOfDevicesInput, isApplications } =
                        getCheckboxState(formValues, item);

                      return (
                        <Space size={16} direction="vertical">
                          <Form.Item
                            name={['services', item.key, 'enabled']}
                            valuePropName="checked"
                            noStyle
                          >
                            <ServiceCheckbox
                              inputKey={item.key}
                              name={item.name}
                              header={item.header}
                              disabled={isCheckboxDisabled || !!isResubmit}
                            />
                          </Form.Item>
                          {numberOfDevicesInput && (
                            <NumberOfDevicesFormItem
                              name={['services', item.key, numberOfDevicesInput.key]}
                              disabled={isCheckboxDisabled || !enabled}
                              label={
                                isApplications
                                  ? t('Number of applications')
                                  : t('Number of devices')
                              }
                            />
                          )}
                        </Space>
                      );
                    }}
                  </Form.ShouldUpdate>
                );
              })}
            </Space>
          ) : (
            servicesQuery.data?.services.map((item) => {
              return <Form.Item key={item.key} name={['services', item.key, 'enabled']} noStyle />;
            })
          )}

          {servicesQuery.data?.services.map((item) => {
            return <Form.Item key={item.key} name={['services', item.key, 'type']} noStyle />;
          })}

          {servicesQuery.data?.services.map((item) => {
            return (
              <Form.ShouldUpdate key={item.key} name={['services', item.key, 'enabled']}>
                {() => {
                  const formValues = form.getFieldsValue();
                  const { enabled, isCheckboxDisabled, numberOfDevicesInput, isApplications } =
                    getCheckboxState(formValues, item);

                  if (hideDisabledServices && !enabled) return null;
                  const content = (
                    <>
                      {!isAgent() && (
                        <LongDescription
                          longDescription={item.longDescription}
                          aboutServiceUrl={item.aboutServiceUrl}
                        />
                      )}

                      <Space size={32} direction="vertical">
                        <WebsiteConfiguration
                          n={buildNamePath(['productMap', item.key])}
                          fields={item.fields}
                        />

                        {isAgent() &&
                          (item.type === 'pos' ? (
                            <PosConfiguration
                              n={buildNamePath(['productMap', item.key])}
                              fields={item.fields}
                            />
                          ) : item.type === 'e-commerce' ? (
                            <EcomConfiguration
                              n={buildNamePath(['productMap', item.key])}
                              fields={item.fields}
                            />
                          ) : item.type === 'soft-pos' ? (
                            <SoftPosConfiguration
                              n={buildNamePath(['productMap', item.key])}
                              fields={item.fields}
                            />
                          ) : null)}

                        <FeesAndChargesFields
                          productMapN={buildNamePath(['productMap', item.key])}
                          productMapTempN={buildNamePath(['productMapTemp', item.key])}
                          fields={item.fields}
                          fieldMap={fieldMap[item.key]}
                          terminalFeeCountName={['services', item.key, 'terminalFee']}
                          serviceType={item.type !== 'e-commerce' ? item.type : undefined}
                        />
                        <AdditionalServicesFields
                          n={buildNamePath(['productMap', item.key])}
                          fields={item.fields}
                          fieldMap={fieldMap[item.key]}
                        />
                        <PaymentSchemaFields
                          n={buildNamePath(['productMap', item.key])}
                          fields={item.fields}
                          fieldMap={fieldMap[item.key]}
                        />
                        <Space direction="vertical" size={16}>
                          <div>{t('*These fees are subject to 5% VAT.')}</div>
                          {item.key === 'eCommerce' && (
                            <div>
                              {t(
                                'Acceptance of Canada and USA cards require extra approval. Are you selling to Canada or USA? Request a call with sales.'
                              )}
                            </div>
                          )}
                        </Space>
                      </Space>
                    </>
                  );

                  if (isServiceSingle) {
                    return (
                      <>
                        <Typography.Title level={4}>{item.name.toUpperCase()}</Typography.Title>
                        {content}
                      </>
                    );
                  }
                  if (isMobile)
                    return (
                      <CardMobile className="ServicesForm-CardMobile">
                        <Space size={24} direction="vertical">
                          <Space size={24} direction="vertical">
                            <Form.Item
                              name={['services', item.key, 'enabled']}
                              valuePropName="checked"
                              noStyle
                            >
                              <ServiceCheckbox
                                inputKey={item.key}
                                name={item.name}
                                header={item.header}
                                disabled={isCheckboxDisabled || !!isResubmit}
                                hideCheckbox={hideServiceSelection}
                              />
                            </Form.Item>
                            {numberOfDevicesInput && (
                              <NumberOfDevicesFormItem
                                name={['services', item.key, numberOfDevicesInput.key]}
                                disabled={isCheckboxDisabled || !enabled}
                                hideButtons={hideServiceSelection}
                                label={
                                  isApplications
                                    ? t('Number of applications')
                                    : t('Number of devices')
                                }
                              />
                            )}
                          </Space>
                          {enabled && content}
                        </Space>
                      </CardMobile>
                    );
                  return (
                    <Collapse ghost className="ServicesForm-Collapse" defaultActiveKey={[item.key]}>
                      <Collapse.Panel
                        key={item.key}
                        header={item.name}
                        collapsible={enabled ? 'header' : 'disabled'}
                        error={isPanelError[item.key]}
                      >
                        {enabled && content}
                      </Collapse.Panel>
                    </Collapse>
                  );
                }}
              </Form.ShouldUpdate>
            );
          })}

          {servicesQuery.data.additionalConfigurations.find(isVisible) && (
            <Collapse
              ghost
              className="ServicesForm-Collapse"
              defaultActiveKey={['additionalConfigurations']}
            >
              <Collapse.Panel
                key="additionalConfigurations"
                header={t('General configurations')}
                collapsible="header"
              >
                <GeneralConfigurationFields
                  n={buildNamePath(['additionalConfigurations'])}
                  fields={servicesQuery.data.additionalConfigurations}
                />
              </Collapse.Panel>
            </Collapse>
          )}
        </Form>
      </Container>
    </ServicesContextProvider>
  );
};

const Container = styled.div`
  .ServicesForm-Collapse {
    .Collapse-Icon {
      vertical-align: 2.5px !important;
    }
    .ant-collapse-header {
      font-size: 24px;
      padding: 16px 0;
      text-transform: unset;
    }
    .ant-collapse-content-box {
      padding: 0 0 40px 0 !important;
    }
  }
  .ServicesForm-Space {
    position: sticky;
    top: 0;
    background-color: ${colors.componentBackground};
    z-index: 3;
    margin-bottom: 40px;
    align-items: flex-start;
    padding-bottom: 4px;
    box-shadow: 0px 2px 2px 1px ${colors.componentBackground};
    .ant-space-item {
      display: flex;
      flex: 1;
      height: 100%;
    }
  }
  .ServicesForm-Title {
    margin-top: 0 !important;
    margin-bottom: 16px;
  }
  .ServicesForm-CardMobile {
    margin-bottom: 24px;
  }
  @media (max-width: ${theme.breakpoints.sm}px) {
    .ServicesForm-LongDescription {
      margin-bottom: 0;
    }
  }
`;

export default ServicesForm;
