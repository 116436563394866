import React, { FC } from 'react';

import { CompositeServiceField } from '~/types/Services';
import buildNamePath, { NameFn } from '~/utils/buildNamePath';
import PriceInput from './PriceInput';
import PriceRowBase from './PriceRowBase';
import BooleanFormItem from './views/ServicesView/BooleanFormItem';
import PaymentSchemaFormItem from './views/ServicesView/PaymentSchemaFormItem';
import { withRole } from './views/ServicesView/utils';

interface Props {
  n: NameFn;
  title: string;
  booleanField: NonNullable<CompositeServiceField['booleanField']>;
  pricingField?: NonNullable<CompositeServiceField['pricingField']>;
  schemaField?: NonNullable<CompositeServiceField['schemaField']>;
}

const CompositeRowFormItem: FC<Props> = (props) => {
  const { title, booleanField, pricingField, schemaField, n } = props;

  const booleanInput = <BooleanFormItem n={n} field={booleanField} title={title} />;

  if (schemaField) {
    return <PaymentSchemaFormItem n={n} field={schemaField} booleanField={booleanField} />;
  }
  if (pricingField) {
    return (
      <PriceRowBase
        title={booleanInput}
        hint={pricingField.hint}
        description={pricingField.description}
      >
        <PriceInput
          n={buildNamePath(n([pricingField.key]))}
          amountFromName={
            pricingField.amountFrom ? [n(pricingField.amountFrom), 'amount'].flat() : undefined
          }
          percentFromName={
            pricingField.percentFrom ? [n(pricingField.percentFrom), 'percent'].flat() : undefined
          }
          disabled={!withRole(pricingField.editable)}
          defaultValue={pricingField.value.defaultValue}
          validation={pricingField.validation}
          affectsPrice={pricingField.affectsPrice}
          savedCustomValue={pricingField.value.customValue}
        />
      </PriceRowBase>
    );
  }
  return null;
};

export default CompositeRowFormItem;
