export default {
  // components
  Email: 'Email',
  'For example abc@example.com': 'For example abc@example.com',
  'Phone number': 'Phone number',
  'For example <bdi>+971551234567</bdi>': 'For example <bdi>+971551234567</bdi>',
  '{{label}} (optional)': '{{label}} (optional)',
  'Contact us': 'Contact us',
  Dashboard: 'Dashboard',
  'Please, fix application data': 'Please, fix application data',
  Continue: 'Continue',
  Total: 'Total',
  'VAT 5%': 'VAT 5%',
  'Pricing has changed': 'Pricing has changed',
  'Save changes to see total price': 'Save changes to see total price',
  Logout: 'Logout',
  Services: 'Services',
  Application: 'Application',
  Contract: 'Contract',
  Payment: 'Payment',
  Completion: 'Completion',
  'Drag & Drop your files here or <strong>Browse</strong>':
    'Drag & Drop your files here or <strong>Browse</strong>',
  'Files supported: JPG, JPEG, DOC, DOCX, PNG, PDF, RTF, GIF. Max size {{size}}':
    'Files supported: JPG, JPEG, DOC, DOCX, PNG, PDF, RTF, GIF. Max size {{size}}',
  'I agree to the above Fees and Charges': 'I agree to the above Fees and Charges',
  Optional: 'Optional',
  Yes: 'Yes',
  No: 'No',
  'Discard changes?': 'Discard changes?',
  Close: 'Close',
  Save: 'Save',
  Upload: 'Upload',
  Language: 'Language',
  Cancel: 'Cancel',
  Submit: 'Submit',
  Copied: 'Copied',
  // Days of week
  MO: 'Monday',
  TU: 'Tuesday',
  WE: 'Wednesday',
  TH: 'Thursday',
  FR: 'Friday',
  SA: 'Saturday',
  SU: 'Sunday',
  // Company type page
  'Get started': 'Get started',
  'with Network International': 'with Network International',
  'Now we know about you, please tell us a little about your business.':
    'Now we know about you, please tell us a little about your business.',
  'I agree to the <1>Terms and Conditions</1> and <3>Privacy Policy</3>':
    'I agree to the <1>Terms and Conditions</1> and <3>Privacy Policy</3>',
  'Company type': 'Company type',
  'Business nature': 'Business nature',
  'Business line': 'Business line',
  Confirm: 'Confirm',
  'Please confirm that your company is not an Aggregator or Marketplace':
    'Please confirm that your company is not an Aggregator or Marketplace',
  'Agree and Continue': 'Agree and Continue',
  'Reselect Company Type': 'Reselect Company Type',
  '<strong>Marketplace</strong> – you operate a platform where multiple merchants come together to sell their own goods or services through one location.':
    '<strong>Marketplace</strong> – you operate a platform where multiple merchants come together to sell their own goods or services through one location.',
  '<strong>Aggregator</strong> – your business allows 3rd party merchants to use the payment services which you have signed up for.':
    '<strong>Aggregator</strong> – your business allows 3rd party merchants to use the payment services which you have signed up for.',
  '<strong>Small and Medium Enterprise</strong> – you operate your own business selling your own goods or services.':
    '<strong>Small and Medium Enterprise</strong> – you operate your own business selling your own goods or services.',
  'Please select the nature of your business from the list which most closely defines your primary trading purpose.':
    'Please select the nature of your business from the list which most closely defines your primary trading purpose.',
  'Please select the business line which most closely fits your primary trading purpose.':
    'Please select the business line which most closely fits your primary trading purpose.',
  // Get in touch page
  'Get in touch': 'Get in touch',
  'Thank you for the information provided so far.':
    'Thank you for the information provided so far.',
  'Due to your business type, we will need one of our dedicated team to take you through the application process to ensure the right services for you.':
    'Due to your business type, we will need one of our dedicated team to take you through the application process to ensure the right services for you.',
  'Please contact us via our <1>Contact us page</1> to arrange a call back.':
    'Please contact us via our <1>Contact us page</1> to arrange a call back.',
  // Lets start
  'Excellent!': 'Excellent!',
  'Now we know a little bit more about you and your business, we can now move onto the full application process.':
    'Now we know a little bit more about you and your business, we can now move onto the full application process.',
  'This process should take no more than 30 minutes.':
    'This process should take no more than 30 minutes.',
  'You can also leave at any point and return by using the same email address you provided to us at the start.':
    'You can also leave at any point and return by using the same email address you provided to us at the start.',
  'Let’s start!': 'Let’s start!',
  'For successful completion you will need the following documents:':
    'For successful completion you will need the following documents:',
  'Copy of Company or Trade License': 'Copy of Company or Trade License',
  'Passport copy of authorised signatory with Visa':
    'Passport copy of authorised signatory with Visa',
  'Emirates ID and documents of all partners in business':
    'Emirates ID and documents of all partners in business',
  'Copy of Memorandum/POA for authorised signatory':
    'Copy of Memorandum/POA for authorised signatory',
  'Copy of Bank Statement with IBAN number': 'Copy of Bank Statement with IBAN number',
  'Tax registration certificate': 'Tax registration certificate',
  'Download File': 'Download File',
};
