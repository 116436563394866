/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import produce from 'immer';

import { useCommonErrors } from './validation';
import { getDraft, saveDraft } from '~/api/draft';
import { ApiError } from '~/types/ApiError';
import { SaveDraftBody } from '~/types/Draft';
import { useGetApplication, useUpdateGetApplicationCache } from './wizard';
import { useMutableCallback, useReduxState } from '~/hooks';
import { getApplication } from '~/api/wizard';
import { getStagePayload } from '~/utils/getStagePayload';
import { Stage } from '~/types/Application';

const getDraftKey = (applicationId: string, stepKey: string) => ['drafts', applicationId, stepKey];

export const useDraftIsStale = () => {
  const [isStale, setStale] = useReduxState<boolean>('draftIsStale', false);
  const onFresh = useMutableCallback(() => {
    setStale(false);
  });
  const onStale = useMutableCallback(() => {
    setStale(true);
  });
  return { isStale, onFresh, onStale };
};

export function useGetDraft<D>(stepKey?: string) {
  const applicationQuery = useGetApplication();
  const resultStepKey = useMemo(() => {
    if (stepKey) return stepKey;
    const stagePayload = getStagePayload.wizard(applicationQuery.data);
    if (stagePayload) {
      return stagePayload.activeStep.key;
    }
    return null;
  }, [applicationQuery.data, stepKey]);

  const applicationId = applicationQuery.data?.uid;

  return useQuery<D, ApiError>(
    getDraftKey(applicationId!, resultStepKey!),
    () => getDraft<D>(resultStepKey!),
    {
      enabled: !!resultStepKey && !!applicationId,
    }
  );
}

export function useSaveDraft<D = any>() {
  const queryClient = useQueryClient();
  const updateCache = useUpdateGetApplicationCache();
  const applicationQuery = useGetApplication();
  const stepKey = useMemo(() => {
    const stagePayload = getStagePayload.wizard(applicationQuery.data);
    if (stagePayload) {
      return stagePayload.activeStep.key;
    }
    return null;
  }, [applicationQuery.data]);
  const applicationId = applicationQuery.data?.uid;

  const [, commonErrorsHandler] = useCommonErrors();

  const [lastData, setLastData] = useState<D>();
  const updateStepStatusOnDraftCreation = async (data: D) => {
    const stagePayload = getStagePayload.wizard(applicationQuery.data);
    if (stagePayload && applicationQuery.data) {
      if (stagePayload.activeStep.validationState === 'OK') {
        if (!lastData && !!data) {
          const prevApplicationData = applicationQuery.data;
          const newApplicationData = await getApplication();
          if (
            newApplicationData.stage === Stage.fillApplication &&
            prevApplicationData.stage === Stage.fillApplication
          ) {
            const persistedStageApplicationData = produce(newApplicationData, (draft) => {
              draft.stagePayload.activeStep = prevApplicationData.stagePayload.activeStep;
            });
            updateCache(persistedStageApplicationData);
          }
        }
      }
    }
    setLastData(data);
  };

  return useMutation<D, ApiError, { stepData: SaveDraftBody['stepData'] }>(
    (props: { stepData: SaveDraftBody['stepData'] }) =>
      saveDraft({ stepKey: stepKey!, stepData: props.stepData }),
    {
      onMutate() {
        commonErrorsHandler.clearError();
      },
      onSuccess(res) {
        void updateStepStatusOnDraftCreation(res);
        if (applicationId && stepKey) {
          queryClient.setQueryData(getDraftKey(applicationId, stepKey), res);
        }
      },
    }
  );
}
