import { RuleObject } from 'antd/lib/form';

import i18n from '~/i18n';

export type Validator = RuleObject['validator'];

export const basicValidation: (v: Validator) => Validator = (v) => (rule, value, callback) => {
  if (rule.required && !value) {
    callback(i18n.t('Required', { ns: 'validation' }));
  } else if (value) {
    void v?.(rule, value, callback);
  } else {
    callback();
  }
};
