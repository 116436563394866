import React, { FC } from 'react';
import { isEmpty } from 'lodash';
import { Spin } from 'antd';

import SelectServicesInitial from '~/components/views/ServicesView/SelectServicesInitial';
import SelectServices from '~/components/views/ServicesView/SelectServices';
import { useGetApplication } from '~/controllers/wizard';
import { useGetServices } from '~/controllers/pricing';
import { Draft } from '~/types/Draft';
import { useGetDraft } from '~/controllers/draft';

const Services: FC<void> = () => {
  const draftQuery = useGetDraft<Draft<'services'>>();
  const applicationQuery = useGetApplication();
  const servicesQuery = useGetServices();
  const predefineProducts = applicationQuery.data?.payload?.products;
  const atLeastOnePredefineProductIsSelected =
    predefineProducts && Object.values(predefineProducts).some((value) => value);

  if (draftQuery.isLoading || applicationQuery.isLoading) return <Spin />;
  if (
    !isEmpty(draftQuery.data?.services) ||
    !isEmpty(applicationQuery.data?.payload.services) ||
    servicesQuery.data?.services.length === 1 ||
    atLeastOnePredefineProductIsSelected
  ) {
    return <SelectServices />;
  }
  return <SelectServicesInitial />;
};

export default Services;
