import { useContext, createContext } from 'react';
import { RuleObject } from 'antd/lib/form';

import { ApplicationPayload } from '~/types/ApplicationPayload';
import { Draft } from '~/types/Draft';

interface ContextType {
  defaultValidator?: RuleObject['validator'];
  disabled?: boolean;
  disabledByDefault?: boolean;
  optionalFields?: Record<string, string>;
  applicationPayload?: ApplicationPayload;
  draftPayload?: Draft<any>;
  errorFields?: { [namePath: string]: string | null }[];
  enabledFields?: string[];
}

const Context = createContext<ContextType>({});
export const FormItemContextProvider = Context.Provider;
export const useFormItemContext = () => useContext(Context);
