import React, { FC } from 'react';
import { Input, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';

import Form from '~/components/WizardForm';
import { LtrInput } from '~/components';
import buildNamePath from '~/utils/buildNamePath';
import InputsGroupLayout from '~/layouts/InputsGroupLayout';

interface Props {
  hasEcom: boolean;
}

const CompanyInformationInputs: FC<Props> = (props) => {
  const { hasEcom } = props;
  const { t } = useTranslation('fillApplication');

  return (
    <InputsGroupLayout>
      <Form.Item name={n(['companyInformation', 'website'])} label={t('Website URL')}>
        <LtrInput />
      </Form.Item>
      <Form.Item name={n(['companyInformation', 'primaryLocation'])} label={t('Primary location')}>
        <Input />
      </Form.Item>
      <Form.Item name={n(['companyInformation', 'tradeArea'])} label={t('Trade area')}>
        <Input />
      </Form.Item>
      <Form.Item name={n(['companyInformation', 'yearsInBusiness'])} label={t('Years in business')}>
        <InputNumber />
      </Form.Item>
      <Form.Item
        name={n(['companyInformation', 'numberOfEmployees'])}
        label={t('Number of employees')}
      >
        <InputNumber />
      </Form.Item>
      <Form.Item name={n(['companyInformation', 'marketShare'])} label={t('Market share, %')}>
        <InputNumber />
      </Form.Item>
      {hasEcom && (
        <Form.Item
          name={n(['companyInformation', 'numberOfTransactions'])}
          label={t('Number of transactions')}
        >
          <InputNumber />
        </Form.Item>
      )}
    </InputsGroupLayout>
  );
};

const n = buildNamePath('additionalInformation');

export default CompanyInformationInputs;
