import React, { FC } from 'react';
import { Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import WizardContent from '~/layouts/WizardContent';
import features from '~/constants/features';
import { SalesforceSoftDeclineImg } from '~/img';
import DeclineReasonsList from './DeclineReasonsList';
import UpdateApplicationButton from './UpdateApplicationButton';
import LeftLayout from '~/layouts/LeftLayout';
import BottomLeftActions from '~/layouts/BottomLeftActions';
import { useGetApplication } from '~/controllers/wizard';
import { getErrorMessages } from './utils';

const SalesforceSoftDecline: FC = () => {
  const { t } = useTranslation('stageResult');

  const applicationQuery = useGetApplication();
  const softDeclineStagePayload =
    applicationQuery.data?.stage === 'salesforceSoftDecline'
      ? applicationQuery.data.stagePayload
      : undefined;

  return (
    <WizardContent
      left={
        <LeftLayout>
          <BottomLeftActions contactUsUrl={features.contactUsLinkSoftDecline} />
        </LeftLayout>
      }
      content={
        <Container className="SalesforceSoftDecline">
          <Typography.Title>{t('We need more information')}</Typography.Title>
          <Typography.Paragraph>
            <p>
              {t(
                'Thank you for your application. We have reviewed the information submitted to us and require some clarifications.'
              )}
            </p>
            {softDeclineStagePayload?.allowToResubmit ? (
              <>
                <p>
                  {t(
                    "We request you to select 'Update Application' below and review the following details which you provided:"
                  )}
                </p>
                <p>
                  <DeclineReasonsList errors={softDeclineStagePayload.errors} />
                </p>
                <p>
                  <Trans
                    t={t}
                    i18nKey="Please contact us via our <1>Contact us page</1> if you need any support or have any questions."
                  >
                    {'Please contact us via our '}
                    <a href={features.contactUsLinkSoftDecline} target="__blank">
                      Contact us page
                    </a>
                    {' if you need any support or have any questions.'}
                  </Trans>
                </p>
              </>
            ) : softDeclineStagePayload?.errors.length === undefined ? (
              <p>{t("We'll call you back soon for further information.")}</p>
            ) : softDeclineStagePayload.errors.length === 1 ? (
              getErrorMessages(softDeclineStagePayload.errors).map((message) => {
                return <p key={message}>{message}</p>;
              })
            ) : softDeclineStagePayload.errors.length > 1 ? (
              <DeclineReasonsList errors={softDeclineStagePayload.errors} />
            ) : null}
          </Typography.Paragraph>
          <SalesforceSoftDeclineImg className="SalesforceSoftDecline-Img" />
        </Container>
      }
      right={softDeclineStagePayload?.allowToResubmit && <UpdateApplicationButton />}
    />
  );
};

const Container = styled.div`
  .SalesforceSoftDecline-Img {
    margin-top: 36px;
    border-radius: 14px;
    width: 100%;
  }
`;

export default SalesforceSoftDecline;
