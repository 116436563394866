export default {
  Configurations: 'Configurations',
  'Terminal brand': 'Terminal brand',
  'Terminal model': 'Terminal model',
  'Refund category': 'Refund category',
  'Payment type accepted': 'Payment type accepted',
  // Settlement
  'Settlement frequency': 'Settlement frequency',
  'Settlement first day': 'Settlement first day',
  'Settlement second day': 'Settlement second day',
  'Cut-off day': 'Cut-off day',
  'Cut-off time': 'Cut-off time',
  Daily: 'Daily',
  'Weekly once': 'Weekly once',
  'Weekly twice': 'Weekly twice',
  Monthly: 'Monthly',
  Fortnightly: 'Fortnightly',
};
