import React, { FC } from 'react';
import { Col, Input, Modal, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { Button, CompanyTypeSelect, Form } from '~/components';

import CompanyTypeTitle from '~/layouts/CompanyTypeTitle';
import { CompanyTypeReq } from '~/types/CompanyType';
import { useCompanyTypeContext } from '~/controllers/CompanyTypeContext';
import { useCompanyTypeMutation } from '~/controllers/companyType';
import paths from '../paths';
import buildNamePath from '~/utils/buildNamePath';
import IndustrySelect from '~/components/inputs/IndustrySelect';
import BusinessLineSelect from '~/components/inputs/BusinessLineSelect';
import { useHandbook } from '~/controllers/handbook';
import CompanyTypeContentLayout from '~/layouts/CompanyTypeContentLayout';
import theme from '~/constants/theme';
import { handleCompanyType } from '~/utils/handleCompanyType';

const CompanyType: FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const companyTypeMutation = useCompanyTypeMutation();
  const context = useCompanyTypeContext();
  const handbook = useHandbook();

  const smallAndMediumEnterprise = handbook.data?.handbooks
    ?.find((item) => item.key === 'companyType')
    ?.values?.find((item) => item.key === 'companyType.small_medium_enterprise')?.apiValue;

  const onValuesChange = (changedValues: CompanyTypeReq, formValues: any) => {
    const handbookData = handbook?.data?.handbooks;
    if (!handbookData) return;
    handleCompanyType(form, handbookData, (name) => name)(changedValues, formValues);
  };

  const onFinish = async (values: CompanyTypeReq) => {
    const submit = async () => {
      try {
        const response = await companyTypeMutation.mutateAsync(values);
        if (response.stage === 'highRisk') {
          history.replace(paths.getInTouch._);
        } else {
          history.replace(paths.letsStart._);
        }
      } catch (error) {
        history.replace(paths.letsStart._); // FIXME handle error!
      }
    };

    if (values.companyType?.companyType === smallAndMediumEnterprise) {
      Modal.confirm({
        title: t('Confirm'),
        content: t('Please confirm that your company is not an Aggregator or Marketplace'),
        icon: null,
        centered: true,
        okText: t('Agree and Continue'),
        okButtonProps: {
          id: 'companyType_confirmButton',
        },
        cancelText: t('Reselect Company Type'),
        cancelButtonProps: { id: 'companyType_cancelButton', type: 'default' },
        onOk: () => {
          submit() // run async
            .then(() => {})
            .catch(() => {});
        },
        onCancel() {},
        width: 450,
      });
    } else {
      await submit();
    }
  };

  return (
    <StyledCompanyTypeContentLayout
      className="CompanyType"
      header={
        <div className="CompanyType-Header">
          <CompanyTypeTitle text={t('Get started')} subtext={t('with Network International')} />
          <Typography.Paragraph className="CompanyType-Paragraph">
            {t('Now we know about you, please tell us a little about your business.')}
          </Typography.Paragraph>
        </div>
      }
    >
      <div className="CompanyType-FormSpaceBetween">
        <Form layout="vertical" form={form} onValuesChange={onValuesChange} onFinish={onFinish}>
          <Form.Item
            name={n(['companyType'])}
            label={t('Company type')}
            rules={[{ required: true }]}
            className="CompanyType-FormItem"
          >
            <CompanyTypeSelect hint />
          </Form.Item>
          <Form.Item
            name={n(['businessNature'])}
            label={t('Business nature')}
            rules={[{ required: true }]}
            className="CompanyType-FormItem"
          >
            <IndustrySelect hint />
          </Form.Item>
          <Form.ShouldUpdate name={n('businessNature')}>
            {() => {
              const formValues = form.getFieldsValue();
              const businessNature = formValues?.companyType?.businessNature;
              return (
                <Form.Item
                  name={n(['businessIndustry'])}
                  label={t('Business line')}
                  rules={[{ required: true }]}
                  className="CompanyType-FormItem"
                >
                  <BusinessLineSelect
                    businessNature={businessNature}
                    disabled={!businessNature}
                    hint
                  />
                </Form.Item>
              );
            }}
          </Form.ShouldUpdate>
          <Form.Item name={n(['mcc'])} hidden>
            <Input />
          </Form.Item>
          <Row gutter={16}>
            <Col className="CompanyType-ButtonCol">
              <Form.Item noStyle>
                <Button
                  id="companyTypeButton_getStarted"
                  type="primary"
                  htmlType="submit"
                  block
                  disabled={!context.agreed}
                  loading={companyTypeMutation.isLoading}
                >
                  {t('Get started')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </StyledCompanyTypeContentLayout>
  );
};

const StyledCompanyTypeContentLayout = styled(CompanyTypeContentLayout)`
  .CompanyType-Header {
    .CompanyType-Paragraph {
      margin-bottom: 64px;
    }
  }
  .CompanyType-FormSpaceBetween {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .ant-select {
      width: 325px;
    }
    .CompanyType-CompanyTypeBackgroundImg {
      display: none;
    }
  }
  @media (max-width: ${theme.breakpoints.md}px) {
    .CompanyType-FormSpaceBetween {
      .ant-select {
        max-width: calc(100% - 32px);
      }
      .CompanyType-ButtonCol {
        width: calc(100% - 32px);
      }
    }
  }
  @media (max-width: ${theme.breakpoints.xs}px) {
    .CompanyType-FormSpaceBetween {
      .ant-select {
        max-width: calc(100vw - ${8 * 2 + 16 * 2 + 32}px);
      }
    }
  }
`;

const n = buildNamePath(['companyType']);

export default CompanyType;
