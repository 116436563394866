export default {
  // components
  Email: 'بريد الالكتروني',
  'For example abc@example.com': 'على سبيل المثال abc@example.com',
  'Phone number': 'رقم الهاتف',
  'For example <bdi>+971551234567</bdi>': 'علي سبيل المثال<bdi>+971551234567</bdi>',
  '{{label}} (optional)': '{{label}} (اختياري)',
  'Contact us': 'اتصل بنا',
  Dashboard: 'لوحة القيادة',
  'Please, fix application data': 'من فضلك ، أصلح بيانات التطبيق',
  Continue: 'يكمل',
  Total: 'المجموع',
  'VAT 5%': 'ضريبة القيمة المضافة 5٪',
  'Pricing has changed': 'لقد تغير التسعير',
  'Save changes to see total price': 'حفظ التغييرات لمعرفة السعر الإجمالي',
  Logout: 'تسجيل خروج',
  Services: 'خدمات',
  Application: 'تطبيق',
  Contract: 'اتفافية',
  Payment: 'قسط',
  Completion: 'إكمال',
  'Drag & Drop your files here or <strong>Browse</strong>':
    'اسحب ملفاتك وأفلتها هنا أو <strong>تصفح</ strong>',
  'Files supported: JPG, JPEG, DOC, DOCX, PNG, PDF, RTF, GIF. Max size {{size}}':
    'الملفات المدعومة: JPG ، JPEG ، DOC ، DOCX ، PNG ، PDF ، RTF ، GIF. أقصى حجم {{size}}',
  'I agree to the above Fees and Charges': 'أوافق على الرسوم والنفقات المذكورة أعلاه',
  Optional: 'اختياري',
  Yes: 'نعم',
  No: 'لا',
  'Discard changes?': 'تجاهل التغييرات؟',
  Close: 'يغلق',
  Save: 'يحفظ',
  Upload: 'تحميل',
  Language: 'لغة',
  Cancel: 'يلغي',
  Submit: 'إرسال',
  Copied: 'نسخ',
  // Days of week
  MO: 'الاثنين',
  TU: 'يوم الثلاثاء',
  WE: 'الأربعاء',
  TH: 'يوم الخميس',
  FR: 'جمعة',
  SA: 'السبت',
  SU: 'الأحد',
  // Company type page
  'Get started': 'البدء',
  'with Network International': 'مع نتورك إنترناشيونال',
  'Now we know about you, please tell us a little about your business.':
    'الآن نحن نعرف عنك ، يرجى إخبارنا قليلاً عن عملك.',
  'I agree to the <1>Terms and Conditions</1> and <3>Privacy Policy</3>':
    'أوافق على <1>البنود والشروط</1> و <3>سياسة الخصوصية</3>',
  'Company type': 'نوع الشركة',
  'Business nature': 'طبيعة العمل',
  'Business line': 'خط الأعمال',
  Confirm: 'يتأكد',
  'Please confirm that your company is not an Aggregator or Marketplace':
    'يُرجى تأكيد أن شركتك ليست مجمِّعًا أو سوقًا',
  'Agree and Continue': 'الموافقة والمتابعة',
  'Reselect Company Type': 'أعد تحديد نوع الشركة',
  '<strong>Marketplace</strong> – you operate a platform where multiple merchants come together to sell their own goods or services through one location.':
    '<strong>السوق</strong> - أنت تدير منصة يجتمع فيها العديد من التجار لبيع سلعهم أو خدماتهم من خلال موقع واحد.',
  '<strong>Aggregator</strong> – your business allows 3rd party merchants to use the payment services which you have signed up for.':
    '<strong>المجمع</strong> - يتيح نشاطك التجاري للتجار الخارجيين استخدام خدمات الدفع التي اشتركت فيها.',
  '<strong>Small and Medium Enterprise</strong> – you operate your own business selling your own goods or services.':
    '<strong>المؤسسة الصغيرة والمتوسطة</strong> - أنت تدير نشاطك التجاري الخاص لبيع السلع أو الخدمات الخاصة بك.',
  'Please select the nature of your business from the list which most closely defines your primary trading purpose.':
    'يرجى تحديد طبيعة عملك من القائمة التي تحدد بشكل وثيق هدفك التجاري الأساسي.',
  'Please select the business line which most closely fits your primary trading purpose.':
    'يرجى تحديد خط العمل الأكثر ملاءمة لغرض التداول الأساسي الخاص بك.',
  // Get in touch page
  'Get in touch': 'ابقى على تواصل',
  'Thank you for the information provided so far.': 'شكرا لك على المعلومات المقدمة حتى الآن.',
  'Due to your business type, we will need one of our dedicated team to take you through the application process to ensure the right services for you.':
    'نظرًا لنوع عملك ، سنحتاج إلى أحد فريقنا المخصص ليأخذك خلال عملية التقديم لضمان الخدمات المناسبة لك.',
  'Please contact us via our <1>Contact us page</1> to arrange a call back.':
    'يرجى الاتصال بنا عبر <1>صفحة اتصل بنا</1> لترتيب معاودة الاتصال.',
  // Lets start
  'Excellent!': 'ممتاز!',
  'Now we know a little bit more about you and your business, we can now move onto the full application process.':
    'الآن نحن نعرف المزيد عنك وعن عملك ، يمكننا الآن الانتقال إلى عملية التقديم الكاملة.',
  'This process should take no more than 30 minutes.':
    'يجب ألا تستغرق هذه العملية أكثر من 30 دقيقة.',
  'You can also leave at any point and return by using the same email address you provided to us at the start.':
    'يمكنك أيضًا المغادرة في أي وقت والعودة باستخدام نفس عنوان البريد الإلكتروني الذي قدمته لنا في البداية.',
  'Let’s start!': 'لنبدأ!',
  'For successful completion you will need the following documents:':
    'لإكمالها بنجاح ، ستحتاج إلى المستندات التالية:',
  'Copy of Company or Trade License': 'نسخة من الشركة أو الرخصة التجارية',
  'Passport copy of authorised signatory with Visa': 'نسخة من جواز سفر المفوض بالتوقيع مع التأشيرة',
  'Emirates ID and documents of all partners in business':
    'هوية الإمارات ووثائق جميع الشركاء في الأعمال',
  'Copy of Memorandum/POA for authorised signatory': 'نسخة من مذكرة / توكيل رسمي للمفوض بالتوقيع',
  'Copy of Bank Statement with IBAN number': 'نسخة من كشف حساب مصرفي برقم IBAN',
  'Tax registration certificate': 'شهادة التسجيل الضريبي',
  'Download File': 'تحميل الملف',
};
