/* eslint-disable react/no-array-index-key */
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import { useMediaQuery } from 'react-responsive';

import { ApplicationPayload } from '~/types/ApplicationPayload';
import longdash from '~/constants/longdash';
import theme from '~/constants/theme';
import Space from '~/components/Space';
import CardMobile from '~/components/CardMobile';
import GroupLayout from '~/layouts/GroupLayout';
import TextField from '~/components/fields/TextField';
import Table from '~/components/Table';
import CountryField from '~/components/fields/CountryField';

interface Props {
  payload: ApplicationPayload;
}

const ShareholdersBusinessTable: FC<Props> = (props) => {
  const { payload } = props;
  const { t } = useTranslation('fillApplication');

  const columns = useMemo<ColumnsType<RowDataType>>(
    () => [
      {
        title: t('Company name'),
        dataIndex: 'legalName',
        key: 'legalName',
        width: '33.333%',
      },
      {
        title: t('Country of incorporation'),
        dataIndex: 'country',
        key: 'country',
        width: '33.333%',
        render: (country) => <CountryField value={country} />,
      },
      {
        title: t('Shares, %'),
        dataIndex: 'shares',
        key: 'shares',
        width: '33.333%',
      },
    ],
    [t]
  );

  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.sm });
  const dataSource = convertData(payload);

  if (!payload) return null;
  return !isTablet ? (
    <Table
      id="reviewAndSubmitShareholderBusinessTable"
      dataSource={convertData(payload)}
      columns={columns}
      pagination={pagination}
    />
  ) : (
    <Space size={16} direction="vertical">
      {dataSource?.map((item, index) => {
        return (
          <CardMobile key={`ShareholdersBusinessTable_${index}`}>
            <GroupLayout gutter={[16, 16]} sm={8} xs={8}>
              <TextField label={t('Company name')}>{item.legalName}</TextField>
              <TextField label={t('Country')}>
                <CountryField value={item.country} />
              </TextField>
              <TextField label={t('Shares, %')}>{item.shares}</TextField>
            </GroupLayout>
          </CardMobile>
        );
      })}
    </Space>
  );
};

const pagination = { hideOnSinglePage: true };

type RowDataType = {
  legalName: string;
  country: string;
  shares: number;
};

const convertData = (data: ApplicationPayload | undefined): RowDataType[] | undefined => {
  return data?.shareholders?.businesses?.map((item) => {
    const business = item.data;
    return {
      legalName: business.companyLicense?.legalName || longdash,
      country: business?.country || longdash,
      shares: item.shares,
    };
  });
};

export default ShareholdersBusinessTable;
