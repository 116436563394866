import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import UnorderedList from '~/components/UnorderedList';
import { useDirection } from '~/hooks/i18nHooks';
import direction from '~/utils/direction';

const PepCategories: FC = () => {
  const { t } = useTranslation('fillApplication');
  const dir = useDirection();

  return (
    <Ol dir={dir}>
      <li>
        {t(
          'A natural person who is or has been entrusted with prominent public functions including:'
        )}
        <UnorderedList
          values={[
            t('a head of state, head of government, minister or deputy or assistant minister;'),
            t('a senior government official;'),
            t('a member of parliament;'),
            t('a senior politician;'),
            t('an important political party official;'),
            t('a senior judicial official;'),
            t('a member of a court of auditors or the board of a central bank;'),
            t(
              'an ambassador, charge d’affaires or other high-ranking officer in a diplomatic service;'
            ),
            t('a high-ranking officer in an armed force;'),
            t(
              'a senior member of an administrative, management or supervisory body of a state-owned enterprise;'
            ),
          ]}
        />
      </li>
      <li>
        {t('Any of the following family members of the above, including:')}
        <UnorderedList
          values={[
            t('a spouse;'),
            t('a partner considered by national law as equivalent to a spouse;'),
            t('a child'),
          ]}
        />
      </li>
    </Ol>
  );
};

const Ol = styled.ol`
  ${direction.padding.left(18)};
  ${direction.padding.right(0)};
  margin-bottom: 0;
  & > li:not(:nth-child(1)) {
    margin-top: 16px;
  }
  ul {
    margin-top: 12px;
    ${direction.padding.left(6)};
    ${direction.padding.right(0)};
    list-style-type: disc;
  }
`;

export default PepCategories;
