import React, { FC } from 'react';

import { Space as AntdSpace, SpaceProps as AntdSpaceProps } from 'antd';
import styled from 'styled-components';

export interface SpaceProps extends AntdSpaceProps {
  fullWidth?: boolean;
}

const Space: FC<SpaceProps> = (props) => {
  const { fullWidth = true, ...rest } = props;
  return <AntdSpaceStyled fullWidth={fullWidth} {...rest} />;
};

const AntdSpaceStyled = styled<FC<SpaceProps>>(AntdSpace)`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : undefined)};
`;

export default Space;
