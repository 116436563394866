import React, { FC } from 'react';
import { isEqual, isUndefined } from 'lodash';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Amount } from '~/types/Amount';
import { AmountField } from '.';
import PercentField from './PercentField';
import Tag from './Tag';
import Space from './Space';
import { PricingValue } from '~/types/PricingValue';
import Popover from './Popover';
import colors from '~/constants/colors';

interface Props {
  percent?: number;
  amount?: Amount;
  customPercent?: number;
  customAmount?: Amount;
  disabled?: boolean;
  showFreeIfZeroAmount?: boolean;
}

const PricingValueField: FC<Props> = (props) => {
  const {
    percent,
    amount,
    customPercent,
    customAmount,
    disabled = false,
    showFreeIfZeroAmount = false,
  } = props;
  const { t } = useTranslation('fillApplication');
  const tag = isCustomPricing(
    { percent, amount },
    { percent: customPercent, amount: customAmount }
  );

  const showFree = (customAmount ?? amount)?.value === 0 && showFreeIfZeroAmount;

  const content = (
    <strong style={{ color: colors.textColor }}>
      <PercentField value={customPercent ?? percent} />
      {!isUndefined(percent) && !isUndefined(amount) && ` + `}
      {amount && !showFree && <AmountField amount={customAmount ?? amount} />}
      {showFree ? t('Free') : null}
    </strong>
  );
  const defaultPricingContent = tag ? (
    <Space size={4} direction="vertical" fullWidth={false}>
      <span>{t('Default pricing:')}</span>
      <strong>
        <PercentField value={percent} />
        {!isUndefined(percent) && !isUndefined(amount) && ` + `}
        {amount && <AmountField amount={amount} />}
      </strong>
    </Space>
  ) : null;

  if (tag)
    return (
      <Popover content={defaultPricingContent}>
        <Tag color={disabled ? 'default' : 'blue'} disabled={disabled} style={reduceRowSpacing}>
          {content}
        </Tag>
      </Popover>
    );
  return <Container disabled={disabled}>{content}</Container>;
};

interface UIProps {
  disabled: boolean;
}

const Container = styled.div<UIProps>`
  color: ${({ disabled }) => (disabled ? colors.disabledBlueTextColor : undefined)};
`;

const isCustomPricing = (defaultValue?: PricingValue, customValue?: PricingValue) => {
  if (typeof customValue?.percent !== 'number' && !customValue?.amount) return false;
  if (isEqual(defaultValue, customValue)) return false;
  return true;
};

const reduceRowSpacing = { marginTop: -4, marginBottom: -4, cursor: 'pointer' };

export default PricingValueField;
