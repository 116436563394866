import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import FormDrawer from '~/components/FormDrawer';
import theme from '~/constants/theme';

interface Props {
  visible: boolean;
  onClose: () => void;
  onSuccess: () => void;
  paymentFrameLink: string | undefined;
}

const PayByCardFrameDrawer: FC<Props> = (props) => {
  const { visible, onClose, onSuccess, paymentFrameLink } = props;
  const { t } = useTranslation('payment');

  useEffect(() => {
    const onMessage = (e: MessageEvent) => {
      if (e.data === 'paymentComplete') {
        // window.removeEventListener('message', onMessage); // remove immediately to avoid double invocation somehow
        onSuccess();
      }
    };

    console.log('adding event listener');
    window.addEventListener('message', onMessage, false);
    return () => {
      console.log('removing event listener');
      window.removeEventListener('message', onMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledFormDrawer
      id="payByCardDrawer"
      visible={visible}
      title={t('Pay online now')}
      onClose={onClose}
      withContinueButton={false}
      formName="payByCard"
    >
      {paymentFrameLink && (
        <iframe src={paymentFrameLink} title={t('Pay by card')} allowFullScreen />
      )}
    </StyledFormDrawer>
  );
};

const StyledFormDrawer = styled(FormDrawer)`
  iframe {
    border: none;
    width: 100%;
    height: 100%;
  }
  @media (max-width: ${theme.breakpoints.md}px) {
    iframe {
      height: calc(100vh - 230px);
    }
  }
  @media (max-width: ${theme.breakpoints.sm}px) {
    iframe {
      height: calc(100vh - 160px);
    }
  }
`;

export default PayByCardFrameDrawer;
