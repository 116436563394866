import { Typography } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Form from '~/components/Form';
import Checkbox from '~/components/inputs/Checkbox';
import LtrInput from '~/components/inputs/LtrInput';
import Select from '~/components/inputs/Select';
import theme from '~/constants/theme';
import { useConfiguration } from '~/controllers/pricing';
import InputsGroupLayout from '~/layouts/InputsGroupLayout';
import { ServiceField } from '~/types/Services';
import { NameFn } from '~/utils/buildNamePath';
import urlValidation from '~/utils/urlValidation';
import FieldResolver from './FieldResolver';

interface Props {
  n: NameFn;
  fields: ServiceField[];
}

const WebsiteConfiguration: FC<Props> = (props) => {
  const { n, fields } = props;
  const { t } = useTranslation('fillApplication');

  const config = useConfiguration(fields, 'WEBSITE_REQUIREMENTS');

  if (Object.keys(config.configMap).length === 0) return null;
  return (
    <Container>
      <Typography.Title className="ServicesForm-Title" level={5}>
        {t('Website requirements')}
      </Typography.Title>
      <InputsGroupLayout>
        {config.renderField('website', ({ disabled, name }) => {
          return (
            <Form.Item
              label={t('Website URL')}
              name={n(name)}
              rules={[{ required: true }, { validator: urlValidation(true) }]}
            >
              <LtrInput disabled={disabled} />
            </Form.Item>
          );
        })}
        <div />
        {config.renderField('enableWebIntegration', ({ disabled, name }) => {
          return (
            <Form.Item name={n(name)} valuePropName="checked" noStyle>
              <Checkbox disabled={disabled}>{t('I need web integration')}</Checkbox>
            </Form.Item>
          );
        })}
        <div />
        {config.renderField('integrationPlugin', ({ disabled, name, options }) => {
          return (
            <div>
              <Form.Visible<boolean> name={n(['enableWebIntegration', 'value'])}>
                <Form.Item label={t('Select plugin')} name={n(name)} rules={[{ required: true }]}>
                  <Select options={options} disabled={disabled} />
                </Form.Item>
              </Form.Visible>
              <Form.Visible<boolean>
                name={n(['enableWebIntegration', 'value'])}
                visibleIf={(value) => !value}
              >
                <Form.Item name={n(name)} initialValue="" hidden />
              </Form.Visible>
            </div>
          );
        })}
        {config.renderField('integrationMethod', ({ disabled, name, options }) => {
          return (
            <div>
              <Form.Visible<boolean> name={n(['enableWebIntegration', 'value'])}>
                <Form.Item label={t('Select method')} name={n(name)} rules={[{ required: true }]}>
                  <Select options={options} disabled={disabled} />
                </Form.Item>
              </Form.Visible>
              <Form.Visible<boolean>
                name={n(['enableWebIntegration', 'value'])}
                visibleIf={(value) => !value}
              >
                <Form.Item name={n(name)} initialValue="" hidden />
              </Form.Visible>
            </div>
          );
        })}
        {config.renderField('enablePayByLink', ({ field }) => {
          return <FieldResolver n={n} field={field} fieldMap={{}} />;
        })}
      </InputsGroupLayout>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 8px;
  .ant-checkbox-wrapper {
    margin-bottom: 24px;
  }
  @media (max-width: ${theme.breakpoints.sm}px) {
    margin-bottom: 0px;
    .ant-checkbox-wrapper {
      margin-bottom: 0px;
    }
  }
`;

export default WebsiteConfiguration;
