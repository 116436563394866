import React, { FC } from 'react';
import PricingRowFormItem from '~/components/PricingRowFormItem';
import { PricingServiceField, PrimitiveFieldMap } from '~/types/Services';
import { NameFn } from '~/utils/buildNamePath';
import PricingRowField from './PricingRowField';
import { shouldShowInput } from './utils';

interface Props {
  n: NameFn;
  field: PricingServiceField;
  fieldMap: PrimitiveFieldMap;
}

const PricingResolver: FC<Props> = (props) => {
  const { n, field, fieldMap } = props;
  const isInput = shouldShowInput(field);
  if (isInput) {
    return <PricingRowFormItem n={n} pricingField={field} />;
  }
  return <PricingRowField field={field} fieldMap={fieldMap} />;
};

export default PricingResolver;
