import React, { FC } from 'react';
import { Button, ButtonProps } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDirection } from '~/hooks/i18nHooks';
import { UaePassLogoIcon } from '~/img';
import colors from '~/constants/colors';
import { UAEPASS_DISABLE } from '~/env';

const UaePassImportButton: FC<ButtonProps> = (props) => {
  const { t } = useTranslation('fillApplication');
  const dir = useDirection();

  const { title } = props;

  if (UAEPASS_DISABLE) return null;

  return (
    <StyledButton icon={<UaePassLogoIcon />} dir={dir} {...props}>
      <span className="UaePassButton-Text">{title || t('Consent on UAE PASS')}</span>
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: ${colors.uaePassButtonBackground};

  :hover,
  :focus,
  :active {
    background: ${colors.uaePassButtonBackground};
    border-color: ${colors.uaePassButtonBackground};
    opacity: 0.7;
  }

  .anticon {
    font-size: 25px;
    display: flex;
    color: #ffffff;
  }

  .UaePassButton-Text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    margin-left: 0 !important;
    color: #ffffff;
  }
`;

export default UaePassImportButton;
