import { CheckboxProps } from 'antd';
import React, { FC } from 'react';
import Checkbox from '~/components/inputs/Checkbox';

interface Props extends Omit<CheckboxProps, 'value' | 'onChange'> {
  title: string;
  value?: boolean;
  onChange?: (value: boolean) => void;
}

const ProductCheckbox: FC<Props> = (props) => {
  const { title, value, onChange, ...rest } = props;
  return (
    <Checkbox checked={value} onChange={(e) => onChange?.(e.target.checked)} {...rest}>
      {title}
    </Checkbox>
  );
};

export default ProductCheckbox;
