const paths = {
  redirect: {
    application: '/redirect/application',
  },
  auth: {
    _: '/auth',
    redirectToKeyCloak: `/api/v1/auth/merchant/redirect/marketplace-signup`,
    merchantAuthCallback: '/merchantAuthCallback',
    merchantInviteAuthCallback: '/merchantInviteAuthCallback',
    redirectAgentToKeyCloak: `/api/v1/auth/merchant/redirect/agent-signup`,
    agentAuthCallback: '/agentAuthCallback',
  },
  home: {
    _: '/',
  },
  companyType: {
    _: '/companyType',
  },
  letsStart: {
    _: '/letsStart',
  },
  getInTouch: {
    _: '/getInTouch',
  },
  wizard: {
    _: '/wizard',
    services: '/wizard/services',
    application: '/wizard/application',
    signContract: '/wizard/signContract',
    payment: '/wizard/payment',
    completion: '/stageResult',
  },
  stageResult: {
    _: '/stageResult',
    waitingForScreening: '/stageResult/waitingForScreening',
    error: '/stageResult/error',
    screeningError: '/stageResult/screeningError',
    screeningReject: '/stageResult/screeningReject',
    salesforceSoftDecline: '/stageResult/salesforceSoftDecline',
    salesforceHardDecline: '/stageResult/salesforceHardDecline',
    submit: '/stageResult/submit',
    salesforceError: '/stageResult/salesforceError',
    salesforceTechnicalError: '/stageResult/salesforceTechnicalError',
    completed: '/stageResult/completed',
    successfulOnboarding: '/stageResult/successfulOnboarding',
    unknownStage: '/stageResult/unknownStage',
  },
  agent: {
    _: '/agent',
    leads: '/agent/leads',
    applications: '/agent/applications',
    map: '/agent/map',
  },
};

export default paths;
