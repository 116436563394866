import jwtDecode from 'jwt-decode';
import { auth } from '~/api/auth';
import { JwtData } from '~/types/JwtData';
import { Roles } from '~/types/Roles';

export const getDecodedJwt = () => {
  const rawToken = auth.getToken();
  const decodedToken = rawToken ? jwtDecode<Partial<JwtData>>(rawToken) : null;
  return decodedToken;
};

export const getRoles = () => {
  const token = getDecodedJwt();
  const agentRoles: string[] = token?.resource_access?.['marketplace-signup-agent']?.roles || [];
  const merchantRoles: string[] = token?.resource_access?.['marketplace-signup']?.roles || [];
  return (agentRoles.length ? agentRoles : merchantRoles.length ? merchantRoles : []) as Roles;
};

export const isMerchant = () => {
  return getRoles().includes('merchant');
};

export const isAgent = () => {
  return getRoles().includes('agent');
};

export const isTeamLead = () => {
  return getRoles().includes('team-lead');
};

export const getSub = () => {
  const token = getDecodedJwt();
  return token?.sub;
};

export const isLoggedInWithUaePass = () => {
  const token = getDecodedJwt();
  return token?.identity_provider === 'oidc-uae-pass';
};

export const getUaePassEmail = () => {
  const token = getDecodedJwt();
  return token?.uaepass_email;
};

export const isUaePassFromUAE = () => {
  const token = getDecodedJwt();
  const isUaePass = isLoggedInWithUaePass();
  return isUaePass && token?.uaepass_nationality_en === 'ARE';
};

let uaePassProfileChangeHash = localStorage.getItem('uaepass_profile_change');

export const isUaePassProfileChange = () => {
  const token = getDecodedJwt();
  const currentHash = token?.uaepass_profile_change;

  if (!currentHash) {
    return false;
  }

  if (!uaePassProfileChangeHash || uaePassProfileChangeHash !== currentHash) {
    uaePassProfileChangeHash = currentHash;
    return true;
  }
  return false;
};

export const resetUaePassProfileChange = () => {
  if (uaePassProfileChangeHash) {
    localStorage.setItem('uaepass_profile_change', uaePassProfileChangeHash);
  }
};
