import React, { FC, useState } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { get } from 'lodash';
import FiltersButton from '~/components/FiltersButton';
import { usePath } from '~/hooks';
import { AssigneeSelect, Button, Form } from '~/components';

import { DashboardStatus, MapItemType } from '~/types/AgentMapItem';
import DashboardStatusInput from '~/components/inputs/DashboardStatusInput';
import CreatedDataFromItem from '~/components/inputs/CreatedDataFromItem';
import CreatedDataToItem from '~/components/inputs/CreatedDataToItem';
import { isTeamLead } from '~/utils/getDecodedJwt';

import Drawer from '~/components/Drawer';
import MapItemTypeInput from '~/components/views/agentMap/MapItemTypeInput';
import StatusSelect from '~/components/inputs/StatusSelect';

const MapFiltersButton = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <FiltersButton onClick={handleOpen} />
      <MapFiltersDrawer onClose={handleClose} visible={open} />
    </>
  );
};

interface MapFiltersDrawerProps {
  visible: boolean;
  onClose: () => void;
}

const MapFiltersDrawer: FC<MapFiltersDrawerProps> = (props) => {
  const { visible, onClose } = props;
  const { t } = useTranslation('agent');
  const [form] = Form.useForm<any>();
  const { changePath, params } = usePath();

  const handleResult = (values: Record<string, any>) => {
    changePath({ filter: values });
    // changeMode();
  };

  const handleChange = (changed: any) => {
    if (get(changed, 'queryType')) {
      form.setFields([{ name: 'filterStatus', value: undefined }]);
    }
  };

  return (
    <StyledDrawer
      visible={visible}
      onClose={() => {
        form.resetFields();
        onClose();
      }}
      closable
      maskClosable={false}
    >
      <Typography.Title level={1}>{t('Filter')}</Typography.Title>
      <Form
        form={form}
        onFinish={(data) => {
          handleResult(data);
          onClose();
        }}
        onValuesChange={handleChange}
        size="middle"
        initialValues={params.filter}
      >
        <Form.Item name="queryType" initialValue={MapItemType.ALL}>
          <MapItemTypeInput />
        </Form.Item>

        <Form.Item name="dashboardStatus" initialValue={DashboardStatus.ALL}>
          <DashboardStatusInput />
        </Form.Item>

        <Form.ShouldUpdate name="queryType">
          {() => {
            const queryType = form.getFieldValue('queryType') as MapItemType | undefined;
            return (
              <Form.Item label={t('Status')} name="filterStatus">
                <StatusSelect allowClear statusType={queryType} />
              </Form.Item>
            );
          }}
        </Form.ShouldUpdate>

        <CreatedDataFromItem
          name="filterCreatedDateFrom"
          filterCreatedDateTo="filterCreatedDateTo"
          label={t('Created date from')}
        />

        <CreatedDataToItem
          filterCreatedDateFrom="filterCreatedDateFrom"
          name="filterCreatedDateTo"
          label={t('Created date to')}
        />
        {isTeamLead() && (
          <Form.Item label={t('Assignee')} name="filterAssignee">
            <AssigneeSelect allowClear />
          </Form.Item>
        )}
        <Button
          id="saveFilters"
          htmlType="submit"
          className="filterDrawer-SaveButton"
          type="primary"
          disabled={!(() => form.isFieldsTouched())}
        >
          {t('Save', { ns: 'common' })}
        </Button>
      </Form>
    </StyledDrawer>
  );
};

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    position: relative;

    .filterDrawer-SaveButton {
      width: 100%;
    }
  }
`;

export default MapFiltersButton;
