import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { getI18n } from 'react-i18next';
import TableHeader from '~/components/views/agent/tableElements/TableHeader';
import { CellText } from '~/components/views/agent/tableElements/cells/CellText';

function referralCodeCell(): ColumnsType<any>[0] {
  return {
    title: <TableHeader title={getI18n().t('Referral code', { ns: 'agent' })} />,
    dataIndex: ['payload', 'referralCode'],
    key: 'referralCode',
    render: (referralCode) => <CellText>{referralCode}</CellText>,
    width: 150,
  };
}

export default referralCodeCell;
