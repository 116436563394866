import React, { FC } from 'react';
import { Input, Radio, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useGetApplication } from '~/controllers/wizard';
import { getStagePayload } from '~/utils/getStagePayload';
import Form from '~/components/WizardForm';
import buildNamePath, { NameFn } from '~/utils/buildNamePath';
import { DateInput } from '~/components/index';

interface Props {
  nFn?: NameFn;
}

const key = 'customFields';
const CustomFields: FC<Props> = (props) => {
  const { nFn } = props;
  const { t } = useTranslation('fillApplication');
  const applicationQuery = useGetApplication();
  const activeStep = getStagePayload.wizard(applicationQuery.data)?.activeStep;
  const customFields = activeStep?.customFields;
  const activeStepName = activeStep?.uiKey;

  if (!customFields || !activeStepName) return null;
  const n = nFn ?? buildNamePath([activeStepName]);

  return (
    <>
      <Typography.Title level={4} style={{ marginTop: 24 }}>
        {t('Additional information').toUpperCase()}
      </Typography.Title>
      {customFields.map(({ type, name: label, uiKey }) => {
        switch (type) {
          case 'string':
            return (
              <Form.Item label={label} name={n([key, uiKey])}>
                <Input.TextArea autoSize={{ minRows: 1 }} />
              </Form.Item>
            );
          case 'date':
            return (
              <Form.Item label={label} name={n([key, uiKey])}>
                <DateInput />
              </Form.Item>
            );
          case 'boolean':
            return (
              <>
                <Typography.Paragraph style={{ marginBottom: 0 }}>{label}</Typography.Paragraph>
                <Form.Item name={n([key, uiKey])}>
                  <Radio.Group>
                    <Radio value>{t('Yes')}</Radio>
                    <Radio value={false}>{t('No')}</Radio>
                  </Radio.Group>
                </Form.Item>
              </>
            );
          default:
            return null;
        }
      })}
    </>
  );
};

export default CustomFields;
