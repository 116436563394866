import React, { FC } from 'react';
import { Col, ColProps, Row } from 'antd';
import { Gutter } from 'antd/lib/grid/row';

export interface GroupLayoutProps {
  gutter?: Gutter | [Gutter, Gutter];
  xs?: ColProps['xs'];
  sm?: ColProps['sm'];
  className?: string;
}

const GroupLayout: FC<GroupLayoutProps> = (props) => {
  const { children, gutter, xs = 24, sm = 12, className } = props;

  return (
    <Row gutter={gutter} className={className}>
      {getChildrenArr(children).map((item, index) => {
        if (!item) return null;
        if (item.type === React.Fragment) {
          return getChildrenArr(item.props.children).map(
            (fragmentItem: any, fragmentIndex: number) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Col key={fragmentIndex} xs={xs} sm={sm}>
                  {fragmentItem}
                </Col>
              );
            }
          );
        }
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Col key={index} xs={xs} sm={sm}>
            {item}
          </Col>
        );
      })}
    </Row>
  );
};

const getChildrenArr = (children: any): JSX.Element[] => {
  return [children].flat(1) as JSX.Element[];
};

export default GroupLayout;
