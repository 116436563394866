import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { getI18n } from 'react-i18next';
import TableHeader from '~/components/views/agent/tableElements/TableHeader';
import { CellText } from '~/components/views/agent/tableElements/cells/CellText';

function companyNameCell(): ColumnsType<any>[0] {
  return {
    title: () => (
      <TableHeader title={getI18n().t('Company', { ns: 'agent' })} sortingDataKey="companyName" />
    ),
    dataIndex: 'companyName',
    key: 'companyName',
    render: (companyName) => <CellText>{companyName}</CellText>,
    width: 155,
  };
}

export default companyNameCell;
