import axios from './axios';
import { Application, Stage } from '~/types/Application';
import { AuthorizedRepresentativeSendInvitationPayload } from '~/types/AuthorizedRepresentativeSendInvitationPayload';

// import application from './mock/application.json';

export const getApplication = () => {
  // return Promise.resolve<Application>(application as any as Application);
  return axios.get<Application>('/merchant/application').then((res) => res.data);
};

export const selectStage = (stage: Stage) => {
  return axios
    .get<Application>('/agent/application', { params: { stage } })
    .then((res) => res.data);
};

export const selectStep = (stepKey: string) => {
  return axios
    .post<Application>('/stages/fillApplication/selectStep', { stepKey })
    .then((res) => res.data);
};

export const submitStep = (stepData?: any) => {
  return axios
    .post<Application>('/stages/fillApplication/submitStep', { stepData })
    .then((res) => res.data);
};

export const saveStep = (stepData?: any) => {
  return axios
    .post<Application>('/stages/fillApplication/saveStep', { stepData })
    .then((res) => res.data);
};

export const setApplicationStage = (stage: 'fillApplication') => {
  return axios
    .post<Application>('/application/setApplicationStage', stage, {
      headers: { 'Content-Type': 'text/plain' },
    })
    .then((res) => res.data);
};

export const submitSalesforceTechnicalError = () => {
  return axios.post<Application>('/stages/salesforceTechnicalError/submit').then((res) => res.data);
};

export const submitSalesforceError = () => {
  return axios.post<Application>('/stages/salesforceError/submit').then((res) => res.data);
};

export const softDeclineGoFixApplication = () => {
  return axios.post<Application>('/stages/salesforceSoftDecline').then((res) => res.data);
};

export const sendInvitation = (body: AuthorizedRepresentativeSendInvitationPayload) => {
  return axios.post<void>('/stages/fillApplication/sendInvitation', body);
};

export const salesforceErrorReturnToFillApplication = () => {
  return axios
    .post<Application>('/stages/salesforceError/returnToFillApplication')
    .then((res) => res.data);
};

export const salesforceTechnicalErrorReturnToFillApplication = () => {
  return axios
    .post<Application>('/stages/salesforceTechnicalError/returnToFillApplication')
    .then((res) => res.data);
};
