import React, { FC } from 'react';
import styled from 'styled-components';
import colors from '~/constants/colors';

interface Props extends Partial<UiProps> {
  className?: string;
}

const Circle: FC<Props> = (props) => {
  const {
    className,
    size = 14,
    backgroundColor = colors.disabledBlueBackground,
    borderColor,
    children,
    color = colors.disabledBlueTextColor,
  } = props;
  return (
    <Container
      className={`Circle ${className}`}
      size={size}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      color={color}
    >
      {children}
    </Container>
  );
};

interface UiProps {
  size: number;
  backgroundColor: string;
  borderColor?: string;
  color: string;
}

const Container = styled.div<UiProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ size }) => size}px;
  aspect-ratio: 1;
  border-radius: ${({ size }) => size / 2}px;
  border-width: 1.5px;
  border-style: solid;
  border-color: ${({ borderColor, backgroundColor }) => borderColor || backgroundColor};
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
`;

export default Circle;
