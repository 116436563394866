import { startsWith } from 'lodash';
import { RuleObject } from 'antd/lib/form';
import { basicValidation } from './basicValidation';
import i18n from '~/i18n';

export type Validator = RuleObject['validator'];

const urlValidation: (httpsOnly?: boolean) => Validator = (httpsOnly = true) =>
  basicValidation((rule, value, callback) => {
    try {
      const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$', // fragment locator
        'i'
      );
      if ((httpsOnly === undefined ? true : httpsOnly) && !startsWith(value, 'https')) {
        throw new Error(i18n.t('Must be like https://example.com', { ns: 'validation' }));
      }
      if (!pattern.test(value)) {
        throw new Error(i18n.t('Must be like https://example.com', { ns: 'validation' }));
      }
      callback();
    } catch (err) {
      console.log((err as any).message as string);
      callback((err as any).message as string);
    }
  });

export default urlValidation;
