import React, { FC, useEffect, useState } from 'react';
import { Radio, RadioChangeEvent, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Collapse, Form } from '~/components';
import { FormItemContextProvider } from '~/components/Form/FormItemContext';
import { ApplicationPayload } from '~/types/ApplicationPayload';
import { ImageResponse } from '~/types/files';
import {
  BankTransferPaymentType,
  DirectDebitPaymentType,
  PaymentMethod,
  PaymentMethodName,
} from '~/types/PaymentMethods';
import { PaymentStagePayload } from '~/types/PaymentStagePayload';
import BankTransfer from './BankTransfer';
import DirectDebit from './DirectDebit';
import PayByCard from './PayByCard';
import PayByLink from './PayByLink';
import PaymentMethodCollapsePanel from './PaymentMethodCollapsePanel';
import { getErrorFields, isPaymentMethodDisabled } from './utils';

export interface PaymentMethodsFormProps {
  formName: string;
  onFinish: (
    paymentMethod: PaymentMethod['type'] | undefined
  ) => (formData: {
    paymentReference: string;
    paymentDate: string;
    invoices?: ImageResponse[];
  }) => Promise<any>;
  stagePayload: PaymentStagePayload;
  applicationPayload: ApplicationPayload;
  readOnly: boolean;
  alreadyPaid: boolean;
}

const PaymentMethodsForm: FC<PaymentMethodsFormProps> = (props) => {
  const { formName, onFinish, readOnly, applicationPayload, alreadyPaid, stagePayload } = props;
  const { t } = useTranslation('payment');
  const [form] = Form.useForm();
  const allowedPaymentMethods = stagePayload?.paymentMethods.map((item) => item.type);
  const methodChangeAllowed = stagePayload.methodChangingAllowed;

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod['type'] | undefined>(
    stagePayload.paymentDetails?.paymentMethod
  );
  const handlePaymentMethod = (e: RadioChangeEvent) => {
    setPaymentMethod(e.target.value);
  };

  // update form fields with updated payment details
  useEffect(() => {
    if (stagePayload.paymentDetails?.paymentMethod === 'payByCard') {
      console.log('updating card payment details');
      form.setFieldsValue({
        paymentReference: stagePayload?.paymentDetails?.paymentReference,
        paymentDate: stagePayload?.paymentDetails?.paymentDate,
      });
    }
  }, [
    form,
    stagePayload.paymentDetails?.paymentDate,
    stagePayload.paymentDetails?.paymentMethod,
    stagePayload.paymentDetails?.paymentReference,
  ]);

  return (
    <FormItemContextProvider
      value={{
        applicationPayload,
        errorFields: getErrorFields(stagePayload.paymentDetails?.errors),
        disabledByDefault: readOnly,
      }}
    >
      <Container>
        <Typography.Paragraph>
          {t('Please select the preferable payment method.')}
          <br />
          <br />
        </Typography.Paragraph>
        <Form
          name={formName}
          form={form}
          onFinish={onFinish(paymentMethod)}
          initialValues={stagePayload.paymentDetails}
        >
          <Radio.Group onChange={handlePaymentMethod} value={paymentMethod}>
            <Collapse activeKey={paymentMethod}>
              {allowedPaymentMethods?.includes(PaymentMethodName.payByCard) && (
                <PaymentMethodCollapsePanel
                  key={PaymentMethodName.payByCard}
                  value={PaymentMethodName.payByCard}
                  header={t('Pay online now')}
                  disabled={isPaymentMethodDisabled({
                    stagePayload,
                    paymentMethod: PaymentMethodName.payByCard,
                    methodChangeAllowed,
                    alreadyPaid,
                    readOnly,
                  })}
                >
                  <PayByCard />
                </PaymentMethodCollapsePanel>
              )}
              {allowedPaymentMethods?.includes(PaymentMethodName.bankTransfer) && (
                <PaymentMethodCollapsePanel
                  key={PaymentMethodName.bankTransfer}
                  value={PaymentMethodName.bankTransfer}
                  header={t('Bank transfer')}
                  disabled={isPaymentMethodDisabled({
                    stagePayload,
                    paymentMethod: PaymentMethodName.bankTransfer,
                    methodChangeAllowed,
                    alreadyPaid,
                    readOnly,
                  })}
                >
                  <BankTransfer
                    record={
                      stagePayload.paymentMethods.find(
                        (item) => item.type === PaymentMethodName.bankTransfer
                      ) as BankTransferPaymentType
                    }
                  />
                </PaymentMethodCollapsePanel>
              )}
              {allowedPaymentMethods?.includes(PaymentMethodName.chequeDeposit) && (
                <PaymentMethodCollapsePanel
                  key={PaymentMethodName.chequeDeposit}
                  value={PaymentMethodName.chequeDeposit}
                  header={t('Pay sales representative by cheque')}
                  disabled={isPaymentMethodDisabled({
                    stagePayload,
                    paymentMethod: PaymentMethodName.chequeDeposit,
                    methodChangeAllowed,
                    alreadyPaid,
                    readOnly,
                  })}
                >
                  <BankTransfer
                    record={
                      stagePayload.paymentMethods.find(
                        (item) => item.type === PaymentMethodName.bankTransfer
                      ) as BankTransferPaymentType
                    }
                  />
                </PaymentMethodCollapsePanel>
              )}
              {allowedPaymentMethods?.includes(PaymentMethodName.cashDeposit) && (
                <PaymentMethodCollapsePanel
                  key={PaymentMethodName.cashDeposit}
                  value={PaymentMethodName.cashDeposit}
                  header={t('Cash deposit slip')}
                  disabled={isPaymentMethodDisabled({
                    stagePayload,
                    paymentMethod: PaymentMethodName.cashDeposit,
                    methodChangeAllowed,
                    alreadyPaid,
                    readOnly,
                  })}
                >
                  <BankTransfer
                    record={
                      stagePayload.paymentMethods.find(
                        (item) => item.type === PaymentMethodName.bankTransfer
                      ) as BankTransferPaymentType
                    }
                  />
                </PaymentMethodCollapsePanel>
              )}
              {allowedPaymentMethods?.includes(PaymentMethodName.directDebit) && (
                <PaymentMethodCollapsePanel
                  key={PaymentMethodName.directDebit}
                  value={PaymentMethodName.directDebit}
                  header={t('Direct Debit from Emirates Islamic and ENBD bank accounts')}
                  disabled={isPaymentMethodDisabled({
                    stagePayload,
                    paymentMethod: PaymentMethodName.directDebit,
                    methodChangeAllowed,
                    alreadyPaid,
                    readOnly,
                  })}
                >
                  <DirectDebit
                    record={
                      (
                        stagePayload.paymentMethods.find(
                          (pm) => pm.key === PaymentMethodName.directDebit
                        ) as DirectDebitPaymentType
                      ).items
                    }
                  />
                </PaymentMethodCollapsePanel>
              )}
              {allowedPaymentMethods?.includes(PaymentMethodName.payByLink) && (
                <PaymentMethodCollapsePanel
                  key={PaymentMethodName.payByLink}
                  value={PaymentMethodName.payByLink}
                  header={t('Pay by link')}
                  disabled={isPaymentMethodDisabled({
                    stagePayload,
                    paymentMethod: PaymentMethodName.payByLink,
                    methodChangeAllowed,
                    alreadyPaid,
                    readOnly,
                  })}
                >
                  <PayByLink />
                </PaymentMethodCollapsePanel>
              )}
              {allowedPaymentMethods?.includes(PaymentMethodName.paymentAfterApproval) && (
                <PaymentMethodCollapsePanel
                  key={PaymentMethodName.paymentAfterApproval}
                  value={PaymentMethodName.paymentAfterApproval}
                  header={t('Payment after approval')}
                  disabled={isPaymentMethodDisabled({
                    stagePayload,
                    paymentMethod: PaymentMethodName.paymentAfterApproval,
                    methodChangeAllowed,
                    alreadyPaid,
                    readOnly,
                  })}
                >
                  {t('Customer will pay after approval.')}
                </PaymentMethodCollapsePanel>
              )}
            </Collapse>
          </Radio.Group>
        </Form>
      </Container>
    </FormItemContextProvider>
  );
};

const Container = styled.div`
  .ant-radio-group {
    width: 100%;
  }

  .ant-collapse-header {
    text-transform: none;
  }
`;

export default PaymentMethodsForm;
