import useReduxState from '~/hooks/useReduxState';
import { CachedImage } from '~/types/files';

export const cachedImageBase: CachedImage = {
  isLoading: false,
  error: null,
  antdImage: null,
  apiImage: null,
};

const useImageCache = () => {
  return useReduxState<{
    [apiImageId: string]: CachedImage;
  }>('imagesCache', {});
};

export default useImageCache;
