import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { Checkbox } from '~/components';
import colors from '~/constants/colors';
import theme from '~/constants/theme';
import { DirectionProp } from '~/types/Direction';
import { useDirection } from '~/hooks/i18nHooks';
import direction from '~/utils/direction';
import { useServicesContext } from '~/controllers/ServicesContext';

interface Props extends ContainerProps {
  inputKey: string;
  checked?: boolean;
  disabled?: boolean;
  hideCheckbox?: boolean;
  onClick?: () => void;
  onChange?: (e: CheckboxChangeEvent) => void;
  name: string;
  header: string;
}

const ServiceCheckbox: FC<Props> = (props) => {
  const {
    inputKey,
    checked,
    onClick,
    onChange,
    name,
    header,
    withBorderBottom = true,
    disabled,
    hideCheckbox,
  } = props;

  const dir = useDirection();
  const servicesContext = useServicesContext();

  return (
    <Container
      className="ServiceCheckbox"
      checked={checked}
      onClick={onClick}
      withBorderBottom={withBorderBottom}
      hideCheckbox={hideCheckbox}
      dir={dir}
    >
      <Checkbox
        id={`service_checkbox_${inputKey}`}
        className="ServiceCheckbox-Checkbox"
        checked={checked}
        onClick={onClick}
        onChange={onChange}
        disabled={disabled || servicesContext.disableServiceSelection}
      >
        {header.split(name).map((str) => {
          if (!str) return null;
          return (
            <strong key={str}>
              {str}
              <span>{name}</span>
            </strong>
          );
        })}
      </Checkbox>
    </Container>
  );
};

interface ContainerProps {
  checked?: boolean;
  hideCheckbox?: boolean;
  withBorderBottom?: boolean;
}

const Container = styled.div<ContainerProps & DirectionProp>`
  padding-top: 12px;
  ${({ withBorderBottom, checked }) =>
    withBorderBottom
      ? css`
          border-bottom: 2px solid ${checked ? colors.selectionColor : colors.borderColorBase};
          .ServiceCheckbox-Checkbox {
            ${direction.margin.left(-8)};
          }
        `
      : css``};
  .ServiceCheckbox-Checkbox {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 8px;
    min-height: 66px;
    strong {
      ${direction.margin.right(-8)};
      span {
        color: ${colors.primaryColor};
        white-space: nowrap;
      }
    }
    .ant-checkbox {
      display: ${({ hideCheckbox }) => (hideCheckbox ? 'none' : undefined)};
      ${direction.margin.left(12)};
    }
  }
  @media (max-width: ${theme.breakpoints.sm}px) {
    padding-top: 0;
    border-bottom: unset;
    .ServiceCheckbox-Checkbox {
      margin-bottom: 0;
      margin-left: 0;
      flex: 1;
      justify-content: space-between;
      align-items: flex-start;
      min-height: unset;
      .ant-checkbox {
        top: 0;
        .ant-checkbox-inner,
        input {
          width: 24px;
          height: 24px;
          border-radius: 4px;
        }
        .ant-checkbox-inner::after {
          left: calc(50% - 5px);
        }
      }
      span:not(.ant-checkbox) {
        flex: 1;
        padding-left: 0;
      }
    }
  }
`;

export default ServiceCheckbox;
