/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useReducer } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { isEmpty } from 'lodash';
import { AxiosResponse } from 'axios';
import { useDebouncedCallback } from 'use-debounce/lib';

import { usePath, useDeepEffect } from '~/hooks';

export type QueryData<T = any> = {
  data: T;
  total: number;
};

type UseQueryType = typeof useQuery;
type UseQueryParams = Parameters<UseQueryType>;

const usePageQuery = <T = any>(
  key: UseQueryParams[0] | string,
  req: (params: ReturnType<typeof getHookQueryParams>) => Promise<AxiosResponse>,
  options: UseQueryParams[2] = {}
) => {
  const { params: pathParamsRaw } = usePath();
  const params = getHookQueryParams(pathParamsRaw);
  const { page, perPage, ...restParams } = params;

  const queryClient = useQueryClient();
  const resultKey = typeof key === 'string' ? [key, page] : key;

  const queryFn = () => req(params).then(handleAxiosResponse);
  const { data, ...rest } = useQuery(resultKey, queryFn, {
    keepPreviousData: true,
    enabled: !!pathParamsRaw.perPage,
    ...(options as any),
    onSuccess(...args) {
      dispatch({ type: 'prefetch' });
      options?.onSuccess?.(...args);
    },
  });

  const [, dispatch] = useReducer(
    (state: any, action: { type: any }) => {
      switch (action.type) {
        case 'prefetch': {
          const hasMore = page * perPage < (data?.total || 0);
          if (typeof key === 'string' && hasMore && !isEmpty(data?.data) && pathParamsRaw.perPage) {
            const nextPage: number = (page as number) + 1;
            void queryClient.prefetchQuery([key, nextPage], () =>
              req({ ...params, page: nextPage }).then(handleAxiosResponse)
            );
          }
          return { mounted: true };
        }
        case 'refetch': {
          if (pathParamsRaw.perPage && options?.enabled !== false && state.mounted) {
            void queryClient.prefetchQuery(resultKey, () =>
              req({ ...params }).then(handleAxiosResponse)
            );
          }
          return { mounted: true };
        }
        default:
          return null;
      }
    },
    { mounted: false }
  );

  const debouncedRefetch = useDebouncedCallback(() => dispatch({ type: 'refetch' }), 1000);
  useDeepEffect(() => {
    debouncedRefetch();
  }, [params.perPage, restParams]);

  return { ...(data as QueryData<T>), ...rest };
};

const getHookQueryParams = (
  params: Record<string, any>,
  queryParams: Record<string, any> = {}
): Record<string, any> => {
  const { page = 1, perPage = usePath.defaultPageSize, filter = {} } = params;
  return {
    page: queryParams.page || page,
    perPage: queryParams.perPage || perPage,
    ...filter,
  };
};

const handleAxiosResponse = <T = any>(res: AxiosResponse<T>): QueryData<T> => {
  return {
    data: res.data,
    total: parseInt(res.headers['content-range']?.split('/').pop() ?? '0', 10),
  };
};

export default usePageQuery;
