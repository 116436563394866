import React, { FC } from 'react';
import { Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import WizardContent from '~/layouts/WizardContent';
import features from '~/constants/features';
import LeftLayout from '~/layouts/LeftLayout';
import BottomLeftActions from '~/layouts/BottomLeftActions';
import { isMerchant } from '~/utils/getDecodedJwt';
import { Button } from '~/components';
import { useSalesforceErrorReturnToFillApplication } from '~/controllers/wizard';

const SalesforceError: FC = () => {
  const { t } = useTranslation('stageResult');
  const returnToFillApplicationMutation = useSalesforceErrorReturnToFillApplication();

  return (
    <WizardContent
      left={
        <LeftLayout>
          <BottomLeftActions contactUsUrl={features.contactUsLinkSalesforceError} />
        </LeftLayout>
      }
      content={
        <Container className="SalesforceError">
          <Typography.Title>{t('Application submission error')}</Typography.Title>
          <Typography.Paragraph>
            {isMerchant() ? (
              <p>
                <Trans
                  t={t}
                  i18nKey="There is a problem with application data, please <1>contact us</1> to solve it."
                >
                  {'There is a problem with application data, please '}
                  <a href={features.contactUsLinkSalesforceError} target="__blank">
                    contact us
                  </a>
                  {' to solve it.'}
                </Trans>
              </p>
            ) : (
              <p>{t('There is a problem with application data.')}</p>
            )}
          </Typography.Paragraph>
        </Container>
      }
      right={
        <Button
          id="salesforceError_tryAgainButton"
          type="primary"
          loading={returnToFillApplicationMutation.isLoading}
          onClick={() => returnToFillApplicationMutation.mutate()}
          style={{ whiteSpace: 'break-spaces', height: 'auto' }}
        >
          {t('Go back to change the application data')}
        </Button>
      }
    />
  );
};

const Container = styled.div`
  .SalesforceError-Img {
    margin-top: 36px;
    border-radius: 14px;
    width: 100%;
  }
`;

export default SalesforceError;
