import InternalCollapse from './Collapse';
import InternalCollapsePanel from './CollapsePanel';

type InternalCollapseType = typeof InternalCollapse;

interface CollapseType extends InternalCollapseType {
  Panel: typeof InternalCollapsePanel;
}

const Collapse = InternalCollapse as CollapseType;

Collapse.Panel = InternalCollapsePanel;

export default Collapse;
