import React, { FC } from 'react';
import { Input, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, Form } from '~/components';
import Drawer, { DRAWER_WIDTH } from '~/components/Drawer';
import { useTerminateLead } from '~/controllers/agent';

interface Props {
  visible: boolean;
  companyName: string | undefined;
  onTerminate: (terminationReason: string) => Promise<any>;
  onSuccess?: () => void;
  onClose: () => void;
}

const TerminationDrawer: FC<Props> = (props) => {
  const { visible, onClose, companyName, onSuccess = () => {}, onTerminate } = props;
  const { t } = useTranslation('agent');
  const terminateLead = useTerminateLead();

  const [form] = Form.useForm<{ terminationReason: string }>();

  return (
    <StyledDrawer visible={visible} onClose={onClose}>
      <Typography.Title level={4}>{t('Are you sure?').toUpperCase()}</Typography.Title>
      <Typography.Paragraph>
        {t(`"{{companyName}}" company will be deleted.`, {
          companyName,
        })}
      </Typography.Paragraph>
      <Typography.Paragraph>
        {t('Please define the reason why you would like to terminate merchant onboarding process.')}
      </Typography.Paragraph>

      <Form
        form={form}
        onFinish={({ terminationReason }) => {
          onTerminate(terminationReason)
            .then(() => {
              form.resetFields();
              onClose();
              onSuccess();
            })
            .catch(() => {});
        }}
      >
        <div className="agentLeadTableActions-FormItems">
          <Form.Item name="terminationReason" rules={[{ required: true }]}>
            <Input.TextArea rows={5} placeholder={t('Reason for termination')} />
          </Form.Item>
        </div>

        <Button
          id="terminationSubmitButton"
          htmlType="submit"
          className="agentLeadTableActions-TerminateButton"
          type="primary"
          loading={terminateLead.isLoading}
        >
          {t('Save', { ns: 'common' })}
        </Button>
      </Form>
    </StyledDrawer>
  );
};

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    position: relative;
    .agentLeadTableActions-FormItems {
      padding-bottom: 16px;
    }
    .agentLeadTableActions-TerminateButton {
      width: 368px;
      @media (max-width: ${DRAWER_WIDTH}px) {
        width: calc(100vw - 64px);
      }
    }
  }
`;

export default TerminationDrawer;
