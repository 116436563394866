import React, { FC } from 'react';
import { ConfigProvider, Menu, Typography } from 'antd';
import styled from 'styled-components';
import Scrollbars from 'react-custom-scrollbars';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import theme from '~/constants/theme';
import { LogoImg } from '~/img';
import HeaderIdentityMenu from '~/layouts/HeaderIdentityMenu';
import AgentMobileMenu, { BOTTOM_MENU_HEIGHT } from '~/layouts/AgentMobileMenu';
import colors from '~/constants/colors';
import paths from '~/pages/paths';
import { getDecodedJwt } from '~/utils/getDecodedJwt';
import { onScroll } from '~/components/Scrollbars';
import { useAgentMenuItem } from '~/hooks';
import { getLayoutHeight, useHeaderHeight } from '~/hooks/useHeaderHeight';
import direction from '~/utils/direction';
import { useDirection } from '~/hooks/i18nHooks';
import { useIsMobile } from '~/hooks/useScreenSizes';

const AgentHeader: FC = () => {
  const history = useHistory();
  const { t } = useTranslation('agent');
  const dir = useDirection();
  const decodedToken = getDecodedJwt();
  const currentMenuItem = useAgentMenuItem();
  const goHome = () => history.push(paths.agent._);

  const menuContent = (
    <Menu
      mode="horizontal"
      selectedKeys={[currentMenuItem]}
      style={{ flex: 1, minWidth: '300px' }}
      inlineCollapsed={false}
    >
      <Menu.Item
        key="applications"
        id="MenuItem_applications"
        onClick={() => history.push(paths.agent.applications)}
      >
        {t('applications')}
      </Menu.Item>
      <Menu.Item key="leads" id="MenuItem_leads" onClick={() => history.push(paths.agent.leads)}>
        {t('leads')}
      </Menu.Item>
      <Menu.Item key="map" id="MenuItem_map" onClick={() => history.push(paths.agent.map)}>
        {t('map')}
      </Menu.Item>
    </Menu>
  );

  return (
    <HeaderContainer className="Header" dir={dir}>
      <div className="Header-Wrapper">
        <LogoImg className="Header-LogoImg" onClick={goHome} />
        <div className="Header-Content">
          {menuContent}
          <div className="Header-AgentInfo">
            <Typography.Text className="Header-Company">
              {decodedToken?.team ?? 'Network International Sales Team'}
            </Typography.Text>
            <Typography.Text>{decodedToken?.name}</Typography.Text>
          </div>
        </div>
        <HeaderIdentityMenu />
      </div>
    </HeaderContainer>
  );
};

const AgentLayout: FC = (props) => {
  const { children } = props;
  const headerHeight = useHeaderHeight({ wizardHeader: false });
  const location = useLocation();
  const withWrapper = location.pathname !== paths.agent.map;
  const isMobile = useIsMobile();
  const scrollbarHeight = isMobile ? BOTTOM_MENU_HEIGHT : headerHeight;
  return (
    <ConfigProvider componentSize="large">
      <Container className="AgentLayout" withWrapper={withWrapper}>
        {!isMobile ? <AgentHeader /> : null}
        {withWrapper ? (
          <Scrollbars onUpdate={onScroll} style={getLayoutHeight(scrollbarHeight)}>
            <div className="AgentLayout-Wrapper">
              <div className="AgentLayout-Container">{children}</div>
            </div>
          </Scrollbars>
        ) : (
          children
        )}
        {isMobile && <AgentMobileMenu />}
      </Container>
    </ConfigProvider>
  );
};

const Container = styled.div<{ withWrapper: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  min-height: ${({ withWrapper }) => (withWrapper ? `100vh` : `100%`)};
  background-color: ${colors.componentBackground};

  .AgentLayout-Wrapper {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: ${theme.paddingBase}px;

    .AgentLayout-Container {
      display: flex;
      flex: 1;
      width: 100%;
      max-width: ${theme.breakpoints.xl}px;
    }
  }
`;

const HeaderContainer = styled.header`
  display: flex;
  flex-direction: row;
  position: sticky;
  justify-content: center;
  padding: ${theme.paddingBase}px;
  top: 0;
  z-index: 10;
  background-color: ${colors.componentBackground};
  box-shadow: 0 0 5px ${colors.grey[100]};
  transition: box-shadow 0.2s ease-out;
  width: 100%;

  .Header-Wrapper {
    //border: solid 1px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: ${theme.breakpoints.xl}px;

    .Header-Content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 ${theme.paddingBase}px 0 ${theme.paddingBase}px;
      width: 100%;
      //border: solid 1px;

      .Header-AgentInfo {
        display: flex;
        flex-direction: row;

        .Header-Company {
          text-align: ${({ dir }) => (dir === 'ltr' ? 'right' : 'left')};
          font-weight: 600;
          ${direction.padding.right('1vw')};
          ${direction.margin.right('1vw')};
          ${direction.border.right(`solid 1px ${colors.grey[200]}`)};
        }
      }
    }

    .Header-LogoImg {
      height: 35px;
    }

    .ant-menu {
      border: 0;
    }

    .ant-menu-item::after {
      border-bottom-width: 4px; //!
      bottom: -${theme.paddingBase}px;
    }

    .ant-menu-item:hover::after {
      border-bottom-width: 4px;
    }

    .ant-menu-item:hover {
      color: ${colors.textColor};
    }

    .ant-menu-item-selected {
      color: ${colors.textColor};
      font-weight: 600;
    }

    .Header-BurgerMenu {
      color: ${colors.primaryColor};
      font-size: 36px;
      cursor: pointer;
    }

    .Header-MiddleContent__mobile {
      margin-top: 16px;
    }
  }
`;

export default AgentLayout;
