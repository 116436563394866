import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { TableProps } from 'antd/lib/table/Table';
import colors from '~/constants/colors';
import { pinCell } from '~/components/views/agent/tableElements/cells';
import { usePinItem } from '~/controllers/agent';
import { useDeepEffect, usePath } from '~/hooks';
import Table from '~/components/Table';
import direction from '~/utils/direction';
import { useDirection } from '~/hooks/i18nHooks';
import { useIsTablet } from '~/hooks/useScreenSizes';
import AgentCardsTable from '~/components/views/agent/tableElements/AgentCardsTable';

interface Props<D> extends TableProps<D> {
  type: 'LEAD' | 'APPLICATION';
  totalPages: number;
  tableRefetch?: () => any;
}

function AgentTable<D extends { pinned: boolean; [x: string]: any }>(props: Props<D>) {
  const {
    totalPages,
    tableRefetch = () => {},
    type,
    columns = [],
    rowClassName,
    dataSource,
    onRow,
    ...rest
  } = props;
  const { pagination, changePath } = usePath();

  const pin = usePinItem();
  const clickOnPin = async (id: string, pinned: boolean) => {
    await pin.mutateAsync({ type, id, pinned });
    changePath({ filter: {}, page: 1 });
    await tableRefetch();
  };
  const pinColumn = pinCell(clickOnPin);
  const newColumns = [pinColumn, ...columns];

  useDeepEffect(() => {
    changePath({ page: 1, perPage: 10 });
  }, []);

  const dir = useDirection();
  const isTablet = useIsTablet();
  const customPagination = { size: 'small' as const, ...pagination, total: totalPages };

  if (!isTablet)
    return (
      <Container dir={dir}>
        <Table
          {...rest}
          dataSource={dataSource}
          columns={newColumns as any}
          rowClassName={(record, index, indent) =>
            classNames(
              { 'ant-table-row-selected': record.pinned },
              typeof rowClassName === 'function'
                ? rowClassName(record, index, indent)
                : rowClassName
            )
          }
          pagination={customPagination}
          onRow={onRow}
        />
      </Container>
    );

  return (
    <AgentCardsTable
      type={type}
      dataSource={dataSource}
      pagination={customPagination}
      pinCell={() => pinColumn}
      onCard={(record) => onRow?.(record).onClick}
    />
  );
}

const Container = styled.div`

  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;

  .ant-table table {
    width: 100%;
    border-collapse: collapse;
  }

  .ant-table-thead > tr > th {
    padding: 0;
    border-bottom: 1px solid ${colors.borderColorBase};
    border-collapse: collapse;
  }

  .ant-table-thead > tr > th:first-child {
    ${direction.border.right(0)}
  }

  .ant-table-thead > tr > th::before {
    display: none;
  }

  .ant-table-tbody > tr > td {
    padding: 7px 10px;
    height: 67px;
  }

  .ant-table-row td:first-child, .ant-table-content th:first-child {
    background: ${colors.componentBackground} !important;
    ${direction.border.right(`1px solid ${colors.borderColorBase}`)}
    border-bottom: 0;
  }

  .ant-table-row td:last-child {
    ${direction.border.right(`1px solid ${colors.borderColorBase}`)}
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: ${colors.grey[50]};
  }

  // .ant-table-tbody > tr {
  //   cursor: pointer;
  // }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid ${colors.borderColorBase};
  }


  .ant-table-tbody > tr > td:first-child {
    padding: 0;
  }

  .ant-pagination-item-active {
    background-color: ${colors.blue[200]};
    border-color: ${colors.blue[200]};
    border-radius: 3px;
  }

  .ant-pagination-item-active a,
  .ant-pagination-item:hover a,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: ${colors.textColor};
    font-weight: 500;
  }

  .ant-pagination-prev span,
  .ant-pagination-next span,
  .ant-pagination-jump-next span,
  .ant-pagination-jump-prev span {
    ${({ dir }) => (dir === 'rtl' ? `transform: scaleX(-1)` : '')};
  }

  .ant-select-selector:after {
    content: '';
  }
}
`;

export default AgentTable;
