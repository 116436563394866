import React, { FC } from 'react';
import { Modal, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { UAEPASS_DISABLE } from '~/env';

interface Props {
  visible: boolean;
}

const UaePassProgressModal: FC<Props> = ({ visible }) => {
  const { t } = useTranslation('contract');
  if (UAEPASS_DISABLE) return null;
  return (
    <Modal visible={visible} centered footer={null} width="auto">
      <div style={{ display: 'flex' }}>
        <Spin style={{ display: 'flex', alignSelf: 'center' }} />
        <span style={{ marginLeft: 10, display: 'flex' }}>
          {t('We are fetching information from the UAE PASS.')}
          <br />
          {t('Use your mobile phone to confirm credentials sharing.')}
        </span>
      </div>
    </Modal>
  );
};

export default UaePassProgressModal;
