import React, { FC } from 'react';
import { Collapse as AntdCollapse, CollapsePanelProps as AntdCollapsePanelProps } from 'antd';
import styled from 'styled-components';
import colors from '~/constants/colors';

interface ImplicitProps extends AntdCollapsePanelProps {
  onItemClick?: (key: string) => void;
  panelKey: string;
  isActive: boolean;
}

interface Props extends AntdCollapsePanelProps {
  error?: boolean;
}

const CollapsePanel: FC<Props> = (props) => {
  const { collapsible, isActive } = props as any as ImplicitProps;

  return (
    <AntdCollapse.Panel
      {...props}
      {...{ isActive: collapsible === 'disabled' ? false : isActive }}
    />
  );
};

export default styled(CollapsePanel)`
  .ant-collapse-header {
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    font-weight: 600;
    color: ${({ error }) => (error ? `${colors.errorColor} !important` : undefined)};
  }
`;
