import React from 'react';
import dayjs from 'dayjs';
import { ColumnsType } from 'antd/lib/table';
import { getI18n } from 'react-i18next';
import TableHeader from '~/components/views/agent/tableElements/TableHeader';

function updatedCell(): ColumnsType<any>[0] {
  return {
    title: () => (
      <TableHeader title={getI18n().t('Updated', { ns: 'agent' })} sortingDataKey="updated" />
    ),
    dataIndex: 'updated',
    key: 'updated',
    render: (date: string) => <span key={date}>{dayjs(date).format('DD/MM/YYYY')}</span>,
    width: 120,
  };
}

export default updatedCell;
