import React, { FC, useEffect, useRef, useState } from 'react';
import GoogleMapReact from 'google-map-react';

import { GOOGLE_MAPS_API_KEY } from '~/env';
import { useViewportCountryCache } from './controller';
import features from '~/constants/features';

const GoogleMap: FC<
  Omit<GoogleMapReact.Props, 'bootstrapURLKeys' | 'defaultCenter' | 'defaultZoom'> & {
    defaultCountry?: string;
  }
> = (props) => {
  const { defaultCountry = features.defaultCountry, center, zoom, ...rest } = props;

  const resultDefaultCountry = defaultCountry;
  const [countryCoordsCache, setCountryCoordsCache] = useViewportCountryCache(resultDefaultCountry);
  const shouldRequestDefaultCountryCoords = !countryCoordsCache && !isValidCoords(center);
  const shouldSetViewportToCachedCountry = countryCoordsCache && !isValidCoords(center);

  const [isMapReady, setIsMapReady] = useState(false);

  const handleSetCountryCoordsRef = useRef<(country: string) => void>();
  const handleApiLoaded = (map: any, maps: any) => {
    if (shouldSetViewportToCachedCountry) {
      map.fitBounds(countryCoordsCache);
    }

    const geocoder = new maps.Geocoder();
    const handleSetCountryCoords = (country: string) => {
      if (geocoder) {
        geocoder.geocode({ address: `Country: ${country}` }, (res: any) => {
          if (!res) return;
          const [{ geometry }] = res;
          setCountryCoordsCache(geometry.viewport);
          map.fitBounds(geometry.viewport);
        });
      }
    };
    handleSetCountryCoordsRef.current = handleSetCountryCoords;
    setIsMapReady(true);
  };

  useEffect(() => {
    if (
      shouldRequestDefaultCountryCoords &&
      resultDefaultCountry &&
      isMapReady &&
      handleSetCountryCoordsRef.current
    ) {
      handleSetCountryCoordsRef.current(resultDefaultCountry);
    }
  }, [isMapReady, resultDefaultCountry, shouldRequestDefaultCountryCoords]);

  if (!GOOGLE_MAPS_API_KEY) return null;
  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
      defaultCenter={features.mapDefaultCenter}
      center={isValidCoords(center) ? center : undefined}
      defaultZoom={5}
      zoom={zoom}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      {...rest}
    />
  );
};

export const isValidCoords = (coords: any) => {
  if (!coords) return false;
  return !Object.values(coords).every((item) => item === 0);
};

export default GoogleMap;
