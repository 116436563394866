import { Spin } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import features from '~/constants/features';
import theme from '~/constants/theme';
import { useGetApplication } from '~/controllers/wizard';
import BottomLeftActions from '~/layouts/BottomLeftActions';

const WaitingForScreening: FC = () => {
  const { t } = useTranslation('stageResult');

  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.md });

  useGetApplication({
    refetchInterval: 5000,
  });

  return (
    <>
      <Container>
        <div className="WaitingForScreening-Content">
          <Spin size="large" />
          <strong className="WaitingForScreening-Text">{t('Screening in progress')}</strong>
        </div>
        {!isTablet && (
          <div className="WaitingForScreening-LeftContent">
            <BottomLeftActions contactUsUrl={features.contactUsLinkScreeningError} />
          </div>
        )}
      </Container>
    </>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
  .WaitingForScreening-Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100%;
    .WaitingForScreening-Text {
      margin-top: 8px;
    }
  }
  .WaitingForScreening-LeftContent {
    position: absolute;
    left: 44px;
    bottom: 32px;
  }
`;

export default WaitingForScreening;
