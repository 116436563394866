export default {
  Configurations: 'التكوينات',
  'Terminal brand': 'العلامة التجارية الطرفية',
  'Terminal model': 'نموذج المحطة',
  'Refund category': 'فئة الاسترداد',
  'Payment type accepted': 'نوع الدفع مقبول',
  // Settlement
  'Settlement frequency': 'تردد التسوية',
  'Settlement first day': 'اليوم الأول للتسوية',
  'Settlement second day': 'التسوية اليوم الثاني',
  'Cut-off day': 'قطع اليوم',
  'Cut-off time': 'وقت مستقطع',
  Daily: 'يوميًا',
  'Weekly once': 'أسبوعيًا مرة',
  'Weekly twice': 'أسبوعيًا مرتين',
  Monthly: 'شهريا',
  Fortnightly: 'كل أسبوعين',
};
