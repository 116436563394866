import { Rule } from 'antd/lib/form';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { NamePath } from '~/utils/buildNamePath';
import { Form, PhoneNumberInput } from '..';

interface Props {
  name: NamePath;
  label?: string;
  disabled?: boolean;
  rules?: Rule[];
  [itemProps: string]: any;
}

const PhoneNumberFormItem: FC<Props> = (props) => {
  const { name, label, disabled, rules, ...itemProps } = props;
  const { t } = useTranslation();

  return (
    <Form.Item name={name} shouldUpdate noStyle rules={rules}>
      {(form) => {
        const hasError = (form.getFieldError(name) || []).length > 0;
        return (
          <Form.Item
            label={label || t('Phone number')}
            name={name}
            help={
              hasError ? undefined : (
                <Trans t={t} i18nKey="For example <bdi>+971551234567</bdi>">
                  For example <bdi>+971551234567</bdi>
                </Trans>
              )
            }
            disabled={disabled}
            rules={rules}
            {...itemProps}
          >
            <PhoneNumberInput />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

export default PhoneNumberFormItem;
