import React, { CSSProperties, FC } from 'react';
import { useHandbook } from '~/controllers/handbook';

interface Props {
  value: string | undefined;
}

const BusinessLineField: FC<Props> = (props) => {
  const { value } = props;

  const handbook = useHandbook();
  const valuesRecord: Record<string, string> = {};
  handbook.data?.handbooks
    ?.find((item) => item.key === 'businessNature')
    ?.values?.forEach((businessNature) => {
      businessNature.values?.forEach((businessLine) => {
        businessLine.values?.forEach((businessLineValue) => {
          if (businessLineValue.apiValue && businessLineValue.localizedValue) {
            valuesRecord[businessLineValue.apiValue] = businessLineValue.localizedValue;
          }
        });
      });
    });

  if (handbook.isLoading) return <span style={loadingStyle}>{value}</span>;
  return <>{value ? valuesRecord[value] || value : value}</>;
};

const loadingStyle: CSSProperties = { opacity: 0.3 };

export default React.memo(BusinessLineField, (prev, next) => prev.value === next.value);
