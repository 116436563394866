import React, { FC, useMemo } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Form from '~/components/WizardForm';
import Section from '~/layouts/Section ';
import { useGetApplication } from '~/controllers/wizard';
import PepCategories from './PepCategories';
import CompanyInformationInputs from './CompanyInformationInputs';
import AnnualTurnoverInputs from './AnnualTurnoverInputs';
import SanctionsQuestionsInputs from './SanctionsQuestionsInputs';
import PepInputs from './PepInputs';
import CustomFields from '~/components/CustomFields';

const Questionnaire: FC = () => {
  const { t } = useTranslation('fillApplication');

  const applicationQuery = useGetApplication();

  const hasPep = useMemo(() => {
    const payload = applicationQuery.data?.payload;
    if (payload?.authorizedRepresentative?.find((item) => item.isPep)) {
      return true;
    }
    if (payload?.shareholders?.individuals?.find((item) => item.data.isPep)) {
      return true;
    }
    if (payload?.shareholders?.beneficialOwners?.find((item) => item.data.isPep)) {
      return true;
    }
    return false;
  }, [applicationQuery.data]);
  const hasEcom = useMemo(() => {
    const payload = applicationQuery.data?.payload;
    return !!payload?.services?.eCommerce.enabled;
  }, [applicationQuery.data]);

  const showPepSection = !hasPep;

  const [form] = Form.useForm();

  return (
    <Container className="Questionnaire">
      <Form form={form} stepKey="additionalInformation">
        <Section formItemLast>
          <Typography.Title level={4}>{t('Company information').toUpperCase()}</Typography.Title>
          <CompanyInformationInputs hasEcom={hasEcom} />
        </Section>

        <Section>
          <Typography.Title level={4}>
            {t('Annual turnover / expected annual turnover').toUpperCase()}
          </Typography.Title>
          <AnnualTurnoverInputs />
        </Section>

        {showPepSection && (
          <section>
            <Typography.Title level={5}>
              {t('Do you hold any position from the below list of categories?')}
            </Typography.Title>
            <PepCategories />
            <PepInputs />
          </section>
        )}

        <Section>
          <SanctionsQuestionsInputs form={form} />
        </Section>
        <CustomFields />
      </Form>
    </Container>
  );
};

const Container = styled.div`
  //
`;

export default Questionnaire;
