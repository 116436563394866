import React, { FC } from 'react';
import styled from 'styled-components';
import Space from '~/components/Space';
import colors from '~/constants/colors';
import theme from '~/constants/theme';
import { useDirection } from '~/hooks/i18nHooks';
import {
  AlipayIcon,
  DinersIcon,
  JcbIcon,
  MastercardIcon,
  MercuryImg,
  MirIcon,
  RupayIcon,
  UnionPayIcon,
  VisaIcon,
  WeChatPayIcon,
} from '~/img';
import { DirectionProp } from '~/types/Direction';
import { BooleanServiceField, SchemaUiData } from '~/types/Services';
import { NameFn } from '~/utils/buildNamePath';
import direction from '~/utils/direction';
import BooleanFormItem from './BooleanFormItem';

export type PaymentSchemaCardBaseProps = {
  uiData: SchemaUiData[];
  disabled?: boolean;
  n?: NameFn;
  booleanField?: BooleanServiceField;
};

const PaymentSchemaCardBase: FC<PaymentSchemaCardBaseProps> = (props) => {
  const { n, uiData, booleanField, children, disabled = false } = props;

  const dir = useDirection();
  return (
    <Container className="PaymentSchemaCardBase" dir={dir} disabled={disabled}>
      <Space
        className="PaymentSchemaCardBase-Left"
        direction="vertical"
        size={16}
        fullWidth={false}
      >
        {uiData.map((ud, index) => {
          const isFirst = index === 0;
          return (
            <div key={ud.key} className="PaymentSchemaCardBase-UiData">
              <div className="PaymentSchemaCardBase-ImageContainer">{getIcon(ud.imageId)}</div>
              {booleanField && n && isFirst ? (
                <BooleanFormItem n={n} field={booleanField} title={ud.name} />
              ) : (
                <div>{ud.name}</div>
              )}
            </div>
          );
        })}
      </Space>
      <div className="PaymentSchemaCardBase-Right">{children}</div>
    </Container>
  );
};

const getIcon = (imageId: SchemaUiData['imageId']) => {
  switch (imageId) {
    case 'paymentSchemaAliPay':
      return <AlipayIcon />;
    case 'paymentSchemaDiners':
      return <DinersIcon />;
    case 'paymentSchemaJcb':
      return <JcbIcon />;
    case 'paymentSchemaMasterCard':
      return <MastercardIcon />;
    case 'paymentSchemaMercury':
      return <MercuryImg />;
    case 'paymentSchemaMir':
      return <MirIcon />;
    case 'paymentSchemaUpi':
      return <UnionPayIcon />;
    case 'paymentSchemaVisa':
      return <VisaIcon />;
    case 'paymentSchemaWeChat':
      return <WeChatPayIcon />;
    case 'paymentSchemaRupay':
      return <RupayIcon />;
    default:
      return null;
  }
};

interface UIProps extends DirectionProp {
  disabled: boolean;
}

const Container = styled.div<UIProps>`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 16px;
  ${direction.padding.right(16)};
  border-radius: 14px;
  background-color: ${colors.grey[50]};
  .PaymentSchemaCardBase-Left {
    .PaymentSchemaCardBase-UiData {
      display: flex;
      align-items: center;
      ${direction.margin.right(16)};
      .PaymentSchemaCardBase-ImageContainer {
        font-size: 58px;
        width: 74px;
        max-width: 74px;
        height: 56px;
        overflow: hidden;
        ${direction.margin.right(16)};
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        align-items: center;
        padding: 8px;
        border-radius: 7px;
        background-color: ${colors.componentBackground};
        opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
      }
    }
  }
  .PaymentSchemaCardBase-Right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    .PriceRowBase-Title {
      font-size: 12px;
    }
  }
  @media (max-width: ${theme.breakpoints.sm}px) {
    border-radius: 10px;
    // padding: 6px;
    flex-direction: column;
    .PaymentSchemaCardBase-Left {
      margin-bottom: 16px;
      .PaymentSchemaCardBase-ImageContainer {
        border-radius: 5px;
        width: 42px;
        height: 32px;
        font-size: 28px;
        ${direction.margin.right(4)};
        img {
          width: 80%;
        }
      }
    }
    .PaymentSchemaCardBase-Right {
      align-items: unset;
    }
  }
`;

export default PaymentSchemaCardBase;
