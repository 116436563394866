import React, { FC } from 'react';
import styled from 'styled-components';
import { SoftDeclineError } from '~/types/SoftDeclineError';
import { getErrorMessages } from './utils';

interface Props {
  errors: SoftDeclineError[];
}

const DeclineReasonsList: FC<Props> = (props) => {
  const { errors } = props;
  const errorMessages = getErrorMessages(errors);
  return (
    <Ul>
      {errorMessages.map((message) => {
        return <li key={message}>{message}</li>;
      })}
    </Ul>
  );
};

const Ul = styled.ul`
  list-style-type: disc !important;
`;

export default DeclineReasonsList;
