import { isBoolean } from 'lodash';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { Alert, Checkbox, Space } from '~/components';
import Button from '~/components/Button';
import ApplicationCommonErrors from '~/components/views/ApplicationCommonErrors';
import theme from '~/constants/theme';
import { useApplyCustomPricingIsLoading } from '~/controllers/pricing';
import {
  useGetApplication,
  useIsAgreeWaringMessage,
  useIsAgreeWithServicesTermsAndConditions,
  useIsAtLeastOneServiceSelected,
  useSaveStepIsLoading,
  useTotalPriceVisible,
} from '~/controllers/wizard';
import WizardContinueButton from '~/layouts/WizardContinueButton';
import { isAgent, isMerchant } from '~/utils/getDecodedJwt';
import { isReadOnly } from '~/utils/isReadOnly';
import RequestCustomPricing from './RequestCustomPricing';
import { servicesFormName } from './SelectServices';
import ServicesTotalPrice from './ServicesTotalPrice';

const ServicesRightContent: FC = () => {
  const { t } = useTranslation();
  const [agree, setAgree] = useIsAgreeWithServicesTermsAndConditions();
  const [isSaving] = useSaveStepIsLoading();
  const warningMessage = useIsAgreeWaringMessage();
  const [, setTotalPriceModalVisible] = useTotalPriceVisible();
  const [isLoadingCustomPricing] = useApplyCustomPricingIsLoading();
  const [selectedAtLeastOne] = useIsAtLeastOneServiceSelected();
  const applicationQuery = useGetApplication();
  const uneditableStep = isReadOnly(applicationQuery.data);

  const isMobile = useMediaQuery({ maxWidth: theme.breakpoints.sm });

  return (
    <Container className="ServicesRightContent">
      <div>
        <ServicesTotalPrice />
        {!isMobile && warningMessage.showAgreement && (
          <Checkbox
            id="services_termsAndConditionsCheckbox"
            className="ServicesRightContent-TermsCheckbox"
            disabled={!isBoolean(agree)}
            checked={agree}
            onChange={(e) => {
              setAgree(e.target.checked);
              if (warningMessage.message) {
                warningMessage.clearMessage();
              }
            }}
          >
            {t('I agree to the above Fees and Charges')}
          </Checkbox>
        )}
      </div>
      <Space className="ServicesRightContent-BottomSpace" direction="vertical" size={24}>
        {warningMessage.message && !agree && (
          <Alert type="warning" showIcon message={warningMessage.message} />
        )}
        <ApplicationCommonErrors />
        {isAgent() && (
          <Button
            id="savePricingButton"
            htmlType="submit"
            form={APPLY_CUSTOM_PRICING_FORM}
            loading={isSaving || isLoadingCustomPricing}
            type="default"
            block
            disabled={uneditableStep || !selectedAtLeastOne}
          >
            {t('Save')}
          </Button>
        )}
        {isMerchant() && <RequestCustomPricing />}
        <WizardContinueButton
          id="servicesContinueButton"
          disabled={!selectedAtLeastOne}
          formName={agree ? servicesFormName : undefined}
          onClick={() => {
            if (!agree) {
              setTotalPriceModalVisible(true);
              warningMessage.setMessage();
            }
          }}
        />
      </Space>
    </Container>
  );
};

export const APPLY_CUSTOM_PRICING_FORM = 'applyCustomPricingForm';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  .ServicesRightContent-TermsCheckbox {
    margin-top: 48px;
    margin-bottom: 24px;
  }
  .ServicesRightContent-BottomSpace {
    margin-top: 24px;
  }
`;

export default ServicesRightContent;
