import React, { FC } from 'react';
import { Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import WizardContent from '~/layouts/WizardContent';
import features from '~/constants/features';
import LeftLayout from '~/layouts/LeftLayout';
import BottomLeftActions from '~/layouts/BottomLeftActions';

const ScreeningReject: FC = () => {
  const { t } = useTranslation('stageResult');

  return (
    <WizardContent
      left={
        <LeftLayout>
          <BottomLeftActions contactUsUrl={features.contactUsLinkScreeningDecline} />
        </LeftLayout>
      }
      content={
        <Container className="ScreeningReject">
          <Typography.Title>
            {t('We are sorry to say that your application has not been successful this time')}
          </Typography.Title>
          <Typography.Paragraph>
            <Trans t={t} i18nKey="If you wish to discuss with us, please <1>contact us</1>.">
              {'If you wish to discuss with us, please '}
              <a href={features.contactUsLinkScreeningDecline} target="__blank">
                contact us
              </a>
              .
            </Trans>
          </Typography.Paragraph>
        </Container>
      }
      right={null}
    />
  );
};

const Container = styled.div`
  //
`;

export default ScreeningReject;
