import React, { FC } from 'react';
import { Typography } from 'antd';
import { ParagraphProps } from 'antd/lib/typography/Paragraph';

export const CellText: FC<ParagraphProps & { rows?: number }> = (props) => {
  const { rows, ...rest } = props;
  return (
    <Typography.Paragraph
      ellipsis={{ rows: rows ?? 2, tooltip: true }}
      style={{ marginBottom: 0, wordWrap: 'break-word', wordBreak: 'break-word' }}
      {...rest}
    />
  );
};
