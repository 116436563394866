import React, { FC } from 'react';
import CompositeRowFormItem from '~/components/CompositeRowFormItem';
import DebugAlert from '~/components/DebugAlert';
import { CompositeServiceField, PrimitiveFieldMap } from '~/types/Services';
import { NameFn } from '~/utils/buildNamePath';
import { isAgent } from '~/utils/getDecodedJwt';
import PaymentSchemaField from './PaymentSchemaField';
import PricingRowField from './PricingRowField';
import PrimitiveServiceFieldResolver from './PrimitiveFieldResolver';
import PaymentSchemaResolver from './PaymentSchemaResolver';
import { isEditable, isVisible } from './utils';
import BooleanResolver from './BooleanResolver';

interface Props {
  n: NameFn;
  field: CompositeServiceField;
  fieldMap: PrimitiveFieldMap;
}

const CompositeFieldResolver: FC<Props> = (props) => {
  const { field, n, fieldMap } = props;

  if (field.pricingField && field.schemaField)
    return (
      <DebugAlert
        message={`Composite field "${field.key}" can't includes pricing and schema fields simultaneously`}
      />
    );
  if (field.pricingField && field.booleanField) {
    if (!isEditable(field.pricingField) && !isEditable(field.booleanField)) return null;
    if (isAgent() || isEditable(field.pricingField) || isEditable(field.booleanField)) {
      return (
        <CompositeRowFormItem
          key={field.key}
          n={n}
          title={field.name}
          booleanField={field.booleanField}
          pricingField={field.pricingField}
        />
      );
    }
    return <PricingRowField field={field.pricingField} fieldMap={fieldMap} title={field.name} />;
  }
  if (field.schemaField && field.booleanField) {
    if (!isVisible(field.schemaField) && !isVisible(field.booleanField)) return null;
    if (isAgent() || isEditable(field.schemaField) || isEditable(field.booleanField)) {
      return (
        <CompositeRowFormItem
          key={field.key}
          n={n}
          title={field.name}
          booleanField={field.booleanField}
          schemaField={field.schemaField}
        />
      );
    }
    return (
      <PaymentSchemaField
        field={field.schemaField}
        booleanField={field.booleanField}
        fieldMap={fieldMap}
      />
    );
  }
  if (field.booleanField) {
    return <BooleanResolver n={n} field={field.booleanField} title={field.name} />;
  }
  if (field.pricingField) {
    return <PrimitiveServiceFieldResolver n={n} field={field.pricingField} fieldMap={fieldMap} />;
  }
  if (field.schemaField) {
    return <PaymentSchemaResolver n={n} field={field.schemaField} fieldMap={fieldMap} />;
  }
  return null;
};

export default CompositeFieldResolver;
