import React, { FC, MouseEventHandler } from 'react';
import {
  Card as AntdCard,
  CardProps as AntdCardProps,
  Col,
  Empty,
  Pagination,
  Row,
  TablePaginationConfig,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components';
import colors from '~/constants/colors';
import theme from '~/constants/theme';
import { AgentLead } from '~/types/AgentLead';
import { AgentApplication } from '~/types/AgentApplication';
import {
  assigneeCell,
  businessLineCell,
  emirateCell,
  requestCell,
  riskLevelCell,
  statusCell,
} from '~/components/views/agent/tableElements/cells';
import { useHandbook } from '~/controllers/handbook';
import { isTeamLead } from '~/utils/getDecodedJwt';
import { ArrowRightIcon } from '~/img';
import direction, { getRotateStyle } from '~/utils/direction';
import { useDirection } from '~/hooks/i18nHooks';
import { CellText } from '~/components/views/agent/tableElements/cells/CellText';
import { DirectionProp } from '~/types/Direction';
import { useIsMobile } from '~/hooks/useScreenSizes';

interface Props<D> {
  dataSource?: readonly D[];
  pagination?: TablePaginationConfig;
  type: 'LEAD' | 'APPLICATION';
  pinCell: () => ColumnsType<any>[0];
  onCard?: (record: D) => MouseEventHandler | undefined;
}

const AgentCardsTable: FC<Props<any>> = (props) => {
  const { dataSource, pagination, type, pinCell, onCard } = props;
  const { data: handbook } = useHandbook();
  const dir = useDirection();
  const isMobile = useIsMobile();

  if (!dataSource || dataSource?.length === 0)
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  return (
    <Container dir={dir}>
      {dataSource?.map((record: AgentApplication | AgentLead) => {
        const { emirate, companyName, pinned, applicationId, statusKey } = record;
        return (
          <div
            role="button"
            tabIndex={0}
            key={`${applicationId}${statusKey}${companyName}${'leadId' in record && record.leadId}`}
            onClick={onCard?.(record)}
            onKeyDown={() => {}}
          >
            <StyledCard pinned={pinned} onClick={() => onCard?.(record)} dir={dir}>
              <Row justify="space-between" align="middle">
                <Col sm={7} xs={18} className={!isMobile ? 'Card-LeftContent' : undefined}>
                  <Row justify="space-between" align="middle">
                    <Col span={2}>{render(pinCell(), pinned, record)}</Col>
                    <Col span={22}>
                      <CellText rows={1} className="Card-CardTitle">
                        {companyName}
                      </CellText>
                      {render(emirateCell(handbook), emirate, record)}
                    </Col>
                  </Row>
                </Col>
                <Col sm={16} xs={3}>
                  {type === 'LEAD' ? (
                    <AgentCardRightContentLead record={record as AgentLead} />
                  ) : (
                    <AgentCardRightContentApplication record={record as AgentApplication} />
                  )}
                </Col>
                <Col sm={1} xs={3} className="Card-ArrowDownContainer">
                  <ArrowRightIcon
                    className="Card-ArrowDownIcon"
                    style={getRotateStyle(false, dir)}
                  />
                </Col>
              </Row>
            </StyledCard>
          </div>
        );
      })}
      <div className="PaginationContainer">
        <Pagination {...pagination} />
      </div>
    </Container>
  );
};

const AgentCardRightContentLead: FC<{ record: AgentLead }> = (props) => {
  const { record } = props;
  const { riskLevel, businessLine, statusKey, assignee } = record;
  const { data: handbook } = useHandbook();
  const isMobile = useIsMobile();

  if (isMobile) return null;

  const BASE_SPAN = 6; // sum of all span in <col> should be === 24
  return (
    <Row gutter={gutter} justify="space-between" align="middle">
      <Col span={1} />
      <Col span={BASE_SPAN - 1}>{render(riskLevelCell(), riskLevel, record)}</Col>
      <Col span={isTeamLead() ? BASE_SPAN : 2 * BASE_SPAN}>
        {render(businessLineCell(handbook), businessLine, record)}
      </Col>
      <Col span={BASE_SPAN}>{render(statusCell(), statusKey, record)}</Col>
      {isTeamLead() && <Col span={BASE_SPAN}>{render(assigneeCell('LEAD'), assignee, record)}</Col>}
    </Row>
  );
};

const AgentCardRightContentApplication: FC<{ record: AgentApplication }> = (props) => {
  const { record } = props;
  const { riskLevel, businessLine, statusKey, assignee, pricingRequestStatus } = record;
  const { data: handbook } = useHandbook();
  const isMobile = useIsMobile();

  if (isMobile) return <>{render(requestCell(), pricingRequestStatus, record)}</>;

  const BASE_SPAN = 5; // sum of all span in <col> should be === 24
  return (
    <Row gutter={gutter} justify="space-between" align="middle">
      <Col span={1} />
      <Col span={BASE_SPAN}>{render(riskLevelCell(), riskLevel, record)}</Col>
      <Col span={1 + (isTeamLead() ? BASE_SPAN : 2 * BASE_SPAN)}>
        {render(businessLineCell(handbook), businessLine, record)}
      </Col>
      <Col span={BASE_SPAN}>{render(statusCell(), statusKey, record)}</Col>
      {isTeamLead() && (
        <Col span={BASE_SPAN}>{render(assigneeCell('APPLICATION'), assignee, record)}</Col>
      )}
      <Col span={2}>{render(requestCell(), pricingRequestStatus, record)}</Col>
    </Row>
  );
};

const gutter = 16;
const render = (f: ColumnsType<any>[0], value: any, record: AgentApplication | AgentLead) =>
  f.render?.(value, record, 0);

const Container = styled.div`
  width: 100%;

  .PaginationContainer {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .ant-pagination-item-active {
    background-color: ${colors.blue[200]};
    border-color: ${colors.blue[200]};
    border-radius: 3px;
  }

  .ant-pagination-prev span,
  .ant-pagination-next span,
  .ant-pagination-jump-next span,
  .ant-pagination-jump-prev span {
    ${({ dir }) => (dir === 'rtl' ? `transform: scaleX(-1)` : '')};
  }

  .ant-pagination-jump-next .ant-pagination-item-ellipsis,
  .ant-pagination-jump-prev .ant-pagination-item-ellipsis {
    overflow: hidden;
  }

  .ant-pagination-item-active a,
  .ant-pagination-item:hover a,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: ${colors.textColor};
    font-weight: 500;
  }

  .ant-select-selector:after {
    content: '';
  }
`;

interface UIProps {
  pinned: boolean;
}

const StyledCard = styled<FC<AntdCardProps & UIProps & DirectionProp>>(AntdCard as any)`
  margin-bottom: ${theme.paddingBase}px;
  background-color: ${({ pinned }) => (pinned ? `${colors.grey[50]}` : undefined)};
  border-color: ${colors.grey[300]};
  cursor: pointer;

  .ant-row {
    height: 48px;
  }

  .ant-card-body {
    border-bottom: unset;
    padding: ${theme.paddingBase}px;
    font-weight: 400;

    .ant-card-head-title {
      padding: 0;
    }
  }

  .Card-ArrowDownIcon {
    color: ${colors.grey[300]};
  }

  .Card-LeftContent {
    ${direction.border.right(`dashed 1px ${colors.grey[300]}`)};
  }

  .Card-ArrowDownContainer {
    display: flex;
    justify-content: end;
  }

  .Card-CardTitle {
    font-weight: 600;
  }
`;

export default AgentCardsTable;
