import React, { FC } from 'react';
import styled from 'styled-components';
import colors from '~/constants/colors';
import theme from '~/constants/theme';

const WizardContentContainer: FC = (props) => {
  const { children } = props;
  return <Container>{children}</Container>;
};

const Container = styled.section`
  background-color: ${colors.componentBackground};
  border-radius: 14px;
  display: flex;
  flex: 1;
  margin: 0 16px 16px 16px;
  padding: 16px;
  max-width: ${theme.breakpoints.xl}px;
  @media (max-width: ${theme.breakpoints.xs}px) {
    margin: 0 12px 12px 12px;
    padding: 24px 12px;
    border-radius: ${theme.borderRadiusSm}px;
  }
`;

export default WizardContentContainer;
