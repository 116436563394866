import React, { FC } from 'react';
import { useLocation } from 'react-router';
import styled, { css } from 'styled-components';

import { useDraftIsStale } from '~/controllers/draft';
import { useGetServices } from '~/controllers/pricing';
import { useTotalPriceState } from '~/controllers/totalPrice';
import { useGetApplication } from '~/controllers/wizard';
import paths from '~/pages/paths';
import TotalPriceMobile from './TotalPriceMobile';
import { getDisablePayment } from '~/utils/getDisablePayment';

const TotalPriceMobileModule: FC = () => {
  const { isStale } = useDraftIsStale();
  const [totalPriceState] = useTotalPriceState();
  const applicationQuery = useGetApplication();
  const location = useLocation();
  const isPaymentStage =
    location.pathname === paths.wizard.payment && applicationQuery.data?.stage === 'payment';
  const servicesQuery = useGetServices(!isPaymentStage);
  const disablePayment = getDisablePayment(applicationQuery.data);

  const pricing =
    location.pathname === paths.wizard.payment && applicationQuery.data?.stage === 'payment'
      ? applicationQuery.data.stagePayload.pricingDto.pricing
      : totalPriceState?.data?.pricing;

  const pricingRequestStatus =
    location.pathname === paths.wizard.payment && applicationQuery.data?.stage === 'payment'
      ? applicationQuery.data.stagePayload.pricingRequest?.status
      : servicesQuery.data?.pricingRequest?.status;

  const needPricing =
    location.pathname === paths.wizard.services ||
    (location.pathname === paths.wizard.payment && !disablePayment) ||
    location.pathname === `${paths.wizard.application}/reviewAndSubmit`;
  const withTermsAndConditions = location.pathname === paths.wizard.services;

  return (
    <Container hidePricing={!needPricing}>
      <TotalPriceMobile
        pricing={pricing}
        isStale={isStale}
        isError={totalPriceState.isError}
        isLoading={totalPriceState.isLoading}
        requiresRecalculate={totalPriceState.requiresRecalculate}
        withTermsAndConditions={withTermsAndConditions}
        pricingRequestStatus={pricingRequestStatus}
      />
    </Container>
  );
};

interface UIProps {
  hidePricing: boolean;
}

const Container = styled.div<UIProps>`
  display: flex;
  transition: opacity 0.2s ease-in-out;
  ${({ hidePricing }) =>
    hidePricing
      ? css`
          opacity: 0;
          pointer-events: none;
        `
      : css``}
`;

export default TotalPriceMobileModule;
