import React, { FC, useEffect, useState } from 'react';
import { get, isEqual } from 'lodash';
import styled from 'styled-components';

import FormItem, { RenderChildren } from './FormItem';
import { NamePath } from '~/utils/buildNamePath';

interface Props {
  name?: NamePath;
  names?: NamePath[];
  deepCompare?: boolean;
  shouldUpdate?: (prev: any, next: any) => boolean;
  children?: RenderChildren;
}

const ShouldUpdate: FC<Props> = (props) => {
  const { children, name, names, deepCompare, shouldUpdate } = props;

  const [, setEnforceRerender] = useState(false);
  useEffect(() => {
    setEnforceRerender((prev) => !prev);
  }, []);

  return (
    <ClearStylesFormItem
      shouldUpdate={(prev, next) => {
        if (shouldUpdate) {
          return shouldUpdate(prev, next);
        }
        if (name) {
          return isChanged(name, deepCompare)(prev, next);
        }
        if (names) {
          let hasChanged = false;
          names.forEach((currentName) => {
            if (isChanged(currentName, deepCompare)(prev, next)) {
              hasChanged = true;
            }
          });
          if (hasChanged) return true;
        }
        return false;
      }}
      style={clearStyles}
    >
      {children}
    </ClearStylesFormItem>
  );
};

const isChanged =
  (name: NamePath, deepCompare?: boolean) =>
  (prev: any, next: any): boolean => {
    if (!name) return false;
    const p = get(prev, name);
    const n = get(next, name);
    if (deepCompare) {
      return isEqual(p, n);
    }
    return p !== n;
  };

const clearStyles = { marginBottom: 0, minHeight: 0 };

const ClearStylesFormItem = styled(FormItem)`
  & > .ant-form-item {
    margin-bottom: 0px !important;
  }
  .ant-form-item-control-input {
    min-height: 0px !important;
  }
`;

export default ShouldUpdate;
