import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Space, ExpandibleCardMobile, TextField } from '~/components';
import { CheckThinIcon } from '~/img';
import DeleteButton from './DeleteButton';
import EditButton from './EditButton';
import { IndividualRowType } from './IndividualTable';

interface Props extends IndividualRowType {
  disableRowActions: boolean;
  hasIndividualsValidationWarnings: boolean;
}

const IndividualCardMobile: FC<Props> = (props) => {
  const {
    firstName,
    lastName,
    shares,
    hasPassport,
    hasEmiratesId,
    onDelete,
    onEdit,
    disableRowActions,
    hasIndividualsValidationWarnings,
  } = props;
  const { t } = useTranslation('fillApplication');

  return (
    <StyledTableCardMobile className="IndividualCardMobile">
      <TextField label={t('First name')}>{firstName}</TextField>
      <TextField label={t('Last name')}>{lastName}</TextField>
      <TextField label={t('Shares, %')}>{shares}</TextField>
      <TextField label={t('Passport')}>{hasPassport ? <CheckThinIcon /> : null}</TextField>
      <TextField label={t('Emirates ID')}>{hasEmiratesId ? <CheckThinIcon /> : null}</TextField>
      <div className="IndividualCardMobile-ButtonsCol">
        <Space size={16} fullWidth={false}>
          {onEdit && (
            <EditButton
              id="individualTable_EditButton"
              className="IndividualCardMobile-Button"
              disabled={disableRowActions}
              hasValidationWarnings={hasIndividualsValidationWarnings}
              onClick={onEdit}
            />
          )}
          <DeleteButton
            id="individualTable_DeleteButton"
            className="IndividualCardMobile-Button"
            disabled={disableRowActions}
            onClick={onDelete}
          />
        </Space>
      </div>
    </StyledTableCardMobile>
  );
};

const StyledTableCardMobile = styled(ExpandibleCardMobile)`
  .IndividualCardMobile-ButtonsCol {
    display: flex;
    align-items: center;
    height: 100%;
    .anticon {
      color: inherit;
    }
    .IndividualCardMobile-Button {
      width: 32px;
      height: 32px;
      font-size: 16px;
    }
  }
`;

export default IndividualCardMobile;
