/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import React, { FC, useState } from 'react';
import { Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import produce from 'immer';
import { useMediaQuery } from 'react-responsive';

import theme from '~/constants/theme';
import { ServicesBannerArImg, ServicesBannerImg } from '~/img';
import WizardContent from '~/layouts/WizardContent';
import WizardContinueButton from '~/layouts/WizardContinueButton';
import { useGetServices } from '~/controllers/pricing';
import ServiceCard from './ServiceCard';
import { ServicesTotalPrice } from '~/types/ServicesTotalPriceType';
import { useSaveDraft } from '~/controllers/draft';
import { PricingServiceField } from '~/types/Services';
import TextHint from '~/components/TextHint';
import BottomLeftActions from '~/layouts/BottomLeftActions';
import LeftLayout from '~/layouts/LeftLayout';
import features from '~/constants/features';
import { useDirection } from '~/hooks/i18nHooks';
import { useIsAtLeastOneServiceSelected } from '~/controllers/wizard';

const SelectServicesInitial: FC = () => {
  const { t } = useTranslation('fillApplication');
  const dir = useDirection();

  const servicesQuery = useGetServices();
  const draftMutation = useSaveDraft();
  useIsAtLeastOneServiceSelected(); // initialize redux state here to avoid bug NI-392

  const [selectedServices, setSelectedServices] = useState<ServicesTotalPrice>({
    nGeniusPos: {
      enabled: false,
    },
    eCommerce: {
      enabled: false,
    },
    nGeniusGo: {
      enabled: false,
    },
    myService: {
      enabled: false,
    },
  });

  const selectedArr = Object.entries(selectedServices)
    .filter(([, item]) => item.enabled)
    .map(([key]) => key);
  const selectedAtLeastOne = selectedArr.length >= 1;

  const onContinue = () => {
    if (selectedAtLeastOne) {
      draftMutation.mutate({
        stepData: {
          services: selectedServices,
        },
      });
    }
  };

  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.sm });

  return (
    <StyledWizardContent
      left={
        <LeftLayout>
          <BottomLeftActions contactUsUrl={features.contactUsLinkServices} />
        </LeftLayout>
      }
      content={
        <>
          {!isTablet &&
            (dir === 'ltr' ? (
              <ServicesBannerImg className="SelectServicesInitial-ServicesBannerImg" />
            ) : (
              <ServicesBannerArImg className="SelectServicesInitial-ServicesBannerImg" />
            ))}
          <Typography.Title level={1}>{t('Select services')}</Typography.Title>
          <TextHint
            text={
              <Typography.Paragraph style={noBottomSpacing}>
                <p>{t('Please select how you would like to take payments.')}</p>
                <p>{t('You can select POS, E-Commerce, or both together.')}</p>
                <p>
                  {t(
                    'However, if you are looking for SoftPOS we can currently only on-board you for this service on its own.'
                  )}
                </p>
                {t(
                  'If you are looking for SoftPOS with POS or E-Commerce, please complete the application and once on-boarded, you can request for additional services.'
                )}
              </Typography.Paragraph>
            }
          >
            <Typography.Paragraph>
              {t('Please select services and options that better fits your business case.')}
            </Typography.Paragraph>
          </TextHint>
          <Space
            className="SelectServicesInitial-Space"
            size={20}
            direction={isTablet ? 'vertical' : 'horizontal'}
          >
            {servicesQuery.data?.services.map((item) => {
              const [bottomText1, bottomText2] = item.shortDescription.split('\\n');
              const { enabled } = selectedServices[item.key];

              return (
                <ServiceCard
                  key={item.key}
                  disabled={
                    selectedAtLeastOne &&
                    !enabled &&
                    !selectedArr.filter((s) => (item.compatibleWith || []).includes(s)).length
                  }
                  checked={enabled}
                  onClick={() =>
                    setSelectedServices(
                      produce((draft) => {
                        draft[item.key].enabled = !draft[item.key].enabled;
                        draft[item.key].type = item.type;
                      })
                    )
                  }
                  inputKey={item.key}
                  name={item.name}
                  header={item.header}
                  imageUrl={item.imageUrl}
                  setupFee={
                    (
                      item.fields.find(
                        (fee) => fee.key === 'setupFee' && fee.type === 'pricing'
                      ) as PricingServiceField | undefined
                    )?.value.defaultValue.amount || null
                  }
                  annualFee={
                    item.key === 'nGeniusPos' || item.key === 'nGeniusGo'
                      ? (
                          item.fields.find(
                            (fee) => fee.key === 'terminalFee' && fee.type === 'pricing'
                          ) as PricingServiceField | undefined
                        )?.value.defaultValue.amount || null
                      : (
                          item.fields.find((fee) => fee.key === 'annualFee') as
                            | PricingServiceField
                            | undefined
                        )?.value.defaultValue.amount || null
                  }
                  bottomText1={bottomText1}
                  bottomText2={bottomText2}
                  bottomText3={item.additionalBannerDescription}
                />
              );
            })}
          </Space>
        </>
      }
      right={
        <WizardContinueButton
          id="servicesInitial_continueButton"
          disabled={!selectedAtLeastOne}
          loading={draftMutation.isLoading}
          onClick={onContinue}
        />
      }
      className="SelectServicesInitial"
    />
  );
};

const noBottomSpacing = { marginBottom: 0 };

const StyledWizardContent = styled(WizardContent)`
  .SelectServicesInitial-ServicesBannerImg {
    width: 100%;
    border-radius: ${theme.borderRadiusBase}px;
    margin-bottom: 56px;
  }
  h1 {
    margin-bottom: 16px;
  }
  .SelectServicesInitial-Space {
    width: 100%;
    .ant-space-item {
      display: flex;
      flex: 1;
      height: 100%;
    }
  }
  .SelectServicesInitial-TooltipText {
    margin-bottom: 0;
  }
`;

export default SelectServicesInitial;
