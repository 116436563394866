import React, { FC, useState } from 'react';
import { Typography } from 'antd';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';

import { Form } from '~/components';
import { useProceedPayment } from '~/controllers/payment';
import { useGetApplication } from '~/controllers/wizard';
import paths from '~/pages/paths';
import { ApiError } from '~/types/ApiError';
import { ImageResponse } from '~/types/files';
import { PaymentMethod, PaymentMethodName } from '~/types/PaymentMethods';
import { getStagePayload } from '~/utils/getStagePayload';
import { onApiError } from '~/utils/handleError';
import { isReadOnly } from '~/utils/isReadOnly';
import PaymentMethodsForm from './PaymentMethodsForm';
import SalesforceErrorModal from './SalesforceErrorModal';
import { getDisablePayment } from '~/utils/getDisablePayment';

const PaymentView: FC = () => {
  const { t } = useTranslation('payment');
  const location = useLocation();
  const formName = location.pathname;

  const applicationQuery = useGetApplication();

  // check existing payment details
  const readOnly = isReadOnly(applicationQuery.data);
  const stagePayload = getStagePayload.payment(applicationQuery.data);
  const alreadyPaid = !!stagePayload?.alreadyPaid;
  const disablePayment = getDisablePayment(applicationQuery.data);
  const isNoNeedToPay =
    stagePayload?.pricingDto.pricing?.Total.amount.value === 0 || disablePayment;

  const isPayByLink = stagePayload?.paymentDetails?.paymentMethod === PaymentMethodName.payByLink;
  const isPayByCard = stagePayload?.paymentDetails?.paymentMethod === PaymentMethodName.payByCard;
  const isResubmit = !!stagePayload?.paymentDetails?.errors?.length;
  const hidePaymentForm =
    !isResubmit && ((alreadyPaid && !isPayByLink && !isPayByCard) || isNoNeedToPay);
  const alreadyPaidSuccessfully = alreadyPaid && !isPayByLink && !isPayByCard && !isResubmit;

  const [salesforceErrorMessage, setSalesforceErrorMessage] = useState<string | null>(null);
  const proceedMutation = useProceedPayment();
  const history = useHistory();

  const handleSubmit =
    (paymentMethod: PaymentMethod['type'] | undefined) =>
    async (formData: {
      paymentReference: string;
      paymentDate: string;
      invoices?: ImageResponse[];
    }) => {
      console.log(`handleSubmit; actualPaymentMethod=${paymentMethod}`);
      if (!paymentMethod) return;
      try {
        if (formData && !isEmpty(formData)) {
          await proceedMutation.mutateAsync({ paymentMethod, ...formData });
        } else {
          await proceedMutation.mutateAsync({ paymentMethod });
        }
        history.push(paths.home._);
      } catch (error) {
        onApiError(error as any);
      }
    };

  const handleAlreadyPaidSubmit = async () => {
    console.log('handleAlreadyPaidSubmit');
    try {
      if (stagePayload?.paymentDetails) {
        await proceedMutation.mutateAsync(stagePayload.paymentDetails);
        history.push(paths.home._);
      } else {
        await proceedMutation.mutateAsync({
          paymentMethod: PaymentMethodName.noPayment,
        });
        history.push(paths.home._);
      }
    } catch (error) {
      const err = error as ApiError;
      const errMessage = err.response?.data.description;
      if (errMessage) {
        setSalesforceErrorMessage(errMessage);
      }
    }
  };

  if (!stagePayload) {
    return null;
  }

  return (
    <>
      <SalesforceErrorModal salesforceErrorMessage={salesforceErrorMessage} />
      <>
        {alreadyPaidSuccessfully && (
          <Typography.Paragraph>{t('Payment is done successfully.')}</Typography.Paragraph>
        )}
        {isNoNeedToPay && (
          <Typography.Paragraph>{t('Lucky you! Payment is not required.')}</Typography.Paragraph>
        )}
        {hidePaymentForm ? (
          <Form name={formName} onFinish={handleAlreadyPaidSubmit} />
        ) : (
          <PaymentMethodsForm
            formName={formName}
            onFinish={handleSubmit}
            stagePayload={stagePayload}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            applicationPayload={applicationQuery.data!.payload}
            // flags
            readOnly={readOnly}
            alreadyPaid={alreadyPaid}
          />
        )}
      </>
    </>
  );
};

export default PaymentView;
