export default {
  'Please note, any individual provided in this section will be screened.':
    'Please note, any individual provided in this section will be screened.',
  // Services initial page
  'Select services': 'Select services',
  'Please select how you would like to take payments.':
    'Please select how you would like to take payments.',
  'You can select POS, E-Commerce, or both together.':
    'You can select POS, E-Commerce, or both together.',
  'However, if you are looking for SoftPOS we can currently only on-board you for this service on its own.':
    'However, if you are looking for SoftPOS we can currently only on-board you for this service on its own.',
  'If you are looking for SoftPOS with POS or E-Commerce, please complete the application and once on-boarded, you can request for additional services.':
    'If you are looking for SoftPOS with POS or E-Commerce, please complete the application and once on-boarded, you can request for additional services.',
  'Please select services and options that better fits your business case.':
    'Please select services and options that better fits your business case.',
  'Setup fee': 'Setup fee',
  'Annual fee': 'Annual fee',
  'Agreement to the above Fees and Charges required':
    'Agreement to the above Fees and Charges required',
  // Services page
  Services: 'Services',
  'Number of applications': 'Number of applications',
  'Number of devices': 'Number of devices',
  'Read more': 'Read more',
  'Website requirements': 'Website requirements',
  'Website URL': 'Website URL',
  'I need web integration': 'I need web integration',
  'Select plugin': 'Select plugin',
  'Select method': 'Select method',
  'Fees and charges*': 'Fees and charges*',
  'Additional services*': 'Additional services*',
  Free: 'Free',
  'Payment Schemes*': 'Payment Schemes*',
  '*These fees are subject to 5% VAT.': '*These fees are subject to 5% VAT.',
  'Acceptance of Canada and USA cards require extra approval. Are you selling to Canada or USA? Request a call with sales.':
    'Acceptance of Canada and USA cards require extra approval. Are you selling to Canada or USA? Request a call with sales.',
  'Reset to default': 'Reset to default',
  'Default pricing:': 'Default pricing:',
  'Callback is requested': 'Callback is requested',
  'Special pricing applied': 'Special pricing applied',
  'Total for all applications': 'Total for all applications',
  'Total for all terminals': 'Total for all terminals',
  'General configurations': 'General configurations',
  'Request a callback': 'Request a callback',
  'Please enter you message in the field below. We will contact you as soon as possible.':
    'Please enter you message in the field below. We will contact you as soon as possible.',
  'Your message': 'Your message',
  'Contact me': 'Contact me',
  'Company request': 'Company request',
  // Company information step
  'Company Information': 'Company Information',
  'Company license': 'Company license',
  'Please upload the company license photo.': 'Please upload the company license photo.',
  'Document data:': 'Document data:',
  'Please share basic details about the company you represent.':
    'Please share basic details about the company you represent.',
  'Date of establishment': 'Date of establishment',
  'Company legal type': 'Company legal type',
  'Company commercial name': 'Company commercial name',
  'Company license number': 'Company license number',
  'License authority': 'License authority',
  'License issue date': 'License issue date',
  'License expiry date': 'License expiry date',
  'Company email': 'Company email',
  'Tax registration certificate': 'Tax registration certificate',
  'Yes, I have a Tax registration certificate.': 'Yes, I have a Tax registration certificate.',
  'Please upload the Tax registration certificate photo.':
    'Please upload the Tax registration certificate photo.',
  'Tax registration number': 'Tax registration number',
  // Addresses step
  Address: 'Address',
  'Head office': 'Head office',
  'This must be the location where you will take payments and where we can reach you.':
    'This must be the location where you will take payments and where we can reach you.',
  Country: 'Country',
  'Address line': 'Address line',
  'P.O. Box': 'P.O. Box',
  'Office phone number': 'Office phone number',
  'Fax number': 'Fax number',
  Emirate: 'Emirate',
  'Type of property occupied': 'Type of property occupied',
  'Please upload your <strong>RERA registration</strong> or <strong>Lease Agreement</strong> documents.':
    'Please upload your <strong>RERA registration</strong> or <strong>Lease Agreement</strong> documents.',
  'Please upload a photo of <strong>outside</strong> the outlet.':
    'Please upload a photo of <strong>outside</strong> the outlet.',
  'Please upload a photo of <strong>inside</strong> the outlet.':
    'Please upload a photo of <strong>inside</strong> the outlet.',
  '<strong>Do you have any branches?</strong>': '<strong>Do you have any branches?</strong>',
  'We are currently only able to on-board one location / branch where you can take payments. However, you can continue with this process and we will be happy to add more locations / branches once you are onboarded.':
    'We are currently only able to on-board one location / branch where you can take payments. However, you can continue with this process and we will be happy to add more locations / branches once you are onboarded.',
  'Alternatively, you can stop the process now and request a member of our team to contact you using the contact us form <1>by link<1/>.':
    'Alternatively, you can stop the process now and request a member of our team to contact you using the contact us form <1>by link<1/>.',
  Rented: 'Rented',
  Owned: 'Owned',
  'Pin location': 'Pin location',
  'Location pinned': 'Location pinned',
  Location: 'Location',
  'Please pin your company location on the map.': 'Please pin your company location on the map.',
  Save: 'Save',
  // Authorized Representative step
  'Authorized Signatory': 'Authorized Signatory',
  'Please provide the details of the person who is an authorised representative of your company.':
    'Please provide the details of the person who is an authorised representative of your company.',
  'Some of the fields were pre populated. Feel free to edit this data.':
    'Some of the fields were pre populated. Feel free to edit this data.',
  'Please provide the details of at least one person who is an authorised representative of your company.':
    'Please provide the details of at least one person who is an authorised representative of your company.',
  'At least one of these representatives must have the authority and proof to act as an authorised signatory for your company.':
    'At least one of these representatives must have the authority and proof to act as an authorised signatory for your company.',
  'Authorised Representative {{number}}': 'Authorised Representative {{number}}',
  'Authorized Representative cannot be deleted': 'Authorized Representative cannot be deleted',
  'This representative is used as shareholder in "Shareholders" step':
    'This representative is used as shareholder in "Shareholders" step',
  'Remove this representative': 'Remove this representative',
  'Add Authorised representative': 'Add Authorised representative',
  'This person is authorised signatory': 'This person is authorised signatory',
  'Shareholder is a Politically Exposed Person (PEP)':
    'Shareholder is a Politically Exposed Person (PEP)',
  'Please upload the <strong>passport main page</strong> photo and provide the details in the fields below.':
    'Please upload the <strong>passport main page</strong> photo and provide the details in the fields below.',
  'Please upload the <strong>visa page</strong> photo and provide the details in the fields below.':
    'Please upload the <strong>visa page</strong> photo and provide the details in the fields below.',
  Designation: 'Designation',
  'Upload a copy of the Power of Attorney showing this person as an authorised signatory':
    'Upload a copy of the Power of Attorney showing this person as an authorised signatory',
  'This person is listed on the Company License as part of the company ownership structure':
    'This person is listed on the Company License as part of the company ownership structure',
  // Shareholders sole proprietor step
  'No authorized representative found': 'No authorized representative found',
  'Since your Company Type was selected as a sole proprietor, the Shareholders section is disabled. You can go to the next step.':
    'Since your Company Type was selected as a sole proprietor, the Shareholders section is disabled. You can go to the next step.',
  'Ownership share:': 'Ownership share:',
  'Shares, %': 'Shares, %',
  // Shareholders multiple step
  'Shareholder Proof Document': 'Shareholder Proof Document',
  'Select the document type': 'Select the document type',
  'Memorandum of Association': 'Memorandum of Association',
  'Partnership deed': 'Partnership deed',
  'Add shareholders': 'Add shareholders',
  'Please add all shareholders with total share of 25% or more.':
    'Please add all shareholders with total share of 25% or more.',
  'Shareholders are listed in company license': 'Shareholders are listed in company license',
  'Upload Copy of Memorandum of Association or Partnership deed':
    'Upload Copy of Memorandum of Association or Partnership deed',
  'Individual shareholders': 'Individual shareholders',
  'Legal entity shareholders': 'Legal entity shareholders',
  'First name': 'First name',
  'Last name': 'Last name',
  'Full name': 'Full name',
  'Commercial name': 'Commercial name',
  'Business line': 'Business line',
  'Company name': 'Company name',
  Passport: 'Passport',
  'Emirates ID': 'Emirates ID',
  'Edit Individual shareholder': 'Edit Individual shareholder',
  'Add new Individual shareholder': 'Add new Individual shareholder',
  'Personal data': 'Personal data',
  'Date of birth': 'Date of birth',
  Nationality: 'Nationality',
  License: 'License',
  'Document number': 'Document number',
  'Passport expiry date': 'Passport expiry date',
  'Visa expiry date': 'Visa expiry date',
  'Please upload <strong>front side</strong> and <strong>back side</strong> Emirates ID photos.':
    'Please upload <strong>front side</strong> and <strong>back side</strong> Emirates ID photos.',
  'Emirates ID number': 'Emirates ID number',
  'Emirates ID expiry date': 'Emirates ID expiry date',
  'Residency & contact details': 'Residency & contact details',
  City: 'City',
  Shares: 'Shares',
  'Legal name': 'Legal name',
  'Country of incorporation': 'Country of incorporation',
  'Add Legal entity shareholder': 'Add Legal entity shareholder',
  'Please share basic details about the company.': 'Please share basic details about the company.',
  'Company legal name': 'Company legal name',
  'Company location': 'Company location',
  'Edit Legal Entity shareholder': 'Edit Legal Entity shareholder',
  'Beneficial owners': 'Beneficial owners',
  'Because shares are held by a Legal entity as disclosed in the above section, please provide the details of Beneficial owners of the entity.':
    'Because shares are held by a Legal entity as disclosed in the above section, please provide the details of Beneficial owners of the entity.',
  'Add beneficial owner': 'Add beneficial owner',
  'Select authorised representative as a shareholder':
    'Select authorised representative as a shareholder',
  'Please select person from the list of shareholders to pre populate the data or fill the form to add new person.':
    'Please select person from the list of shareholders to pre populate the data or fill the form to add new person.',
  'Please specify ownership share': 'Please specify ownership share',
  'Please specify ownership share for this person':
    'Please specify ownership share for this person',
  'Additional information': 'Additional information',
  'In case of complex ownership structure please provide comments and/or schemas that might be relevant.':
    'In case of complex ownership structure please provide comments and/or schemas that might be relevant.',
  'Enter your comments': 'Enter your comments',
  'Edit beneficial owner': 'Edit beneficial owner',
  'Authorised Signatory Proof': 'Authorised Signatory Proof',
  // Questionnaire step
  'Company Questionnaire': 'Company Questionnaire',
  'Please share additional information about your business.':
    'Please share additional information about your business.',
  'Company information': 'Company information',
  'Annual turnover / expected annual turnover': 'Annual turnover / expected annual turnover',
  'Do you hold any position from the below list of categories?':
    'Do you hold any position from the below list of categories?',
  'Primary location': 'Primary location',
  Industry: 'Industry',
  'Trade area': 'Trade area',
  'Years in business': 'Years in business',
  'Number of employees': 'Number of employees',
  'Market share, %': 'Market share, %',
  'Number of transactions': 'Number of transactions',
  'Expected card sales': 'Expected card sales',
  'Total value in AED of expected card and digital payments':
    'Total value in AED of expected card and digital payments',
  '1st year': '1st year',
  '2nd year': '2nd year',
  'Expected non-card sales': 'Expected non-card sales',
  'Total value in AED of non card and digital transactions (e.g. cash and cheque)':
    'Total value in AED of non card and digital transactions (e.g. cash and cheque)',
  'Total expected sales': 'Total expected sales',
  'This is the total value in AED of card and non card sales':
    'This is the total value in AED of card and non card sales',
  'Total expected turnover after two years: <1></1>':
    'Total expected turnover after two years: <1></1>',
  'A natural person who is or has been entrusted with prominent public functions including:':
    'A natural person who is or has been entrusted with prominent public functions including:',
  'a head of state, head of government, minister or deputy or assistant minister;':
    'a head of state, head of government, minister or deputy or assistant minister;',
  'a senior government official;': 'a senior government official;',
  'a member of parliament;': 'a member of parliament;',
  'a senior politician;': 'a senior politician;',
  'an important political party official;': 'an important political party official;',
  'a senior judicial official;': 'a senior judicial official;',
  'a member of a court of auditors or the board of a central bank;':
    'a member of a court of auditors or the board of a central bank;',
  'an ambassador, charge d’affaires or other high-ranking officer in a diplomatic service;':
    'an ambassador, charge d’affaires or other high-ranking officer in a diplomatic service;',
  'a high-ranking officer in an armed force;': 'a high-ranking officer in an armed force;',
  'a senior member of an administrative, management or supervisory body of a state-owned enterprise;':
    'a senior member of an administrative, management or supervisory body of a state-owned enterprise;',
  'Any of the following family members of the above, including:':
    'Any of the following family members of the above, including:',
  'a spouse;': 'a spouse;',
  'a partner considered by national law as equivalent to a spouse;':
    'a partner considered by national law as equivalent to a spouse;',
  'a child': 'a child',
  Yes: 'Yes',
  No: 'No',
  'Indicate at least one <1>Authorized Representative</1> or <3>Individual Shareholder</3> as a PEP person.':
    'Indicate at least one <1>Authorized Representative</1> or <3>Individual Shareholder</3> as a PEP person.',
  'Does your company have any direct/indirect dealings involving a Sanctioned Country* or any Sanctioned Party**?':
    'Does your company have any direct/indirect dealings involving a Sanctioned Country* or any Sanctioned Party**?',
  'Is your company owned by any person/entity that is incorporated/based in, or resident in a Sanctioned Country/Territory?':
    'Is your company owned by any person/entity that is incorporated/based in, or resident in a Sanctioned Country/Territory?',
  'Does your business have any offices, investment activities or planned activities in any sanctioned country or have an ownership interest in a Sanctioned Party?':
    'Does your business have any offices, investment activities or planned activities in any sanctioned country or have an ownership interest in a Sanctioned Party?',
  '*Sanctioned Countries - Iran, Syria, Cuba, North Korea)/Territory (Crimea) and any other countries/territories as updated time to time by the relevant authorities.':
    '*Sanctioned Countries - Iran, Syria, Cuba, North Korea)/Territory (Crimea) and any other countries/territories as updated time to time by the relevant authorities.',
  '**A sanctioned party includes any person or entity designated for sanction reasons by United Nations Security council, The European Union, The Office of Foreign Assets Control (OFAC), UK HMT and Central Bank of UAE Blacklist.':
    '**A sanctioned party includes any person or entity designated for sanction reasons by United Nations Security council, The European Union, The Office of Foreign Assets Control (OFAC), UK HMT and Central Bank of UAE Blacklist.',
  'Specify the name of the Sanctioned Countries/Territories:':
    'Specify the name of the Sanctioned Countries/Territories:',
  'Enter text here': 'Enter text here',
  'Brief description of business activities conducted in the Sanctioned Countries/Territories:':
    'Brief description of business activities conducted in the Sanctioned Countries/Territories:',
  'What goods/products are involved, what they are used for, the country of origin and destination?':
    'What goods/products are involved, what they are used for, the country of origin and destination?',
  'Going forward, would you expect the dealings with Sanctioned Countries/Territories to increase/decrease/remain the same?':
    'Going forward, would you expect the dealings with Sanctioned Countries/Territories to increase/decrease/remain the same?',
  // Bank account step
  'Bank accounts': 'Bank accounts',
  'Account for devices': 'Account for devices',
  'Please upload scan of bank statement with IBAN number.':
    'Please upload scan of bank statement with IBAN number.',
  'Bank name': 'Bank name',
  'Account name': 'Account name',
  'Account number': 'Account number',
  'Account for E-commerce': 'Account for E-commerce',
  'Use the same account': 'Use the same account',
  // Review & Submit step
  'Review & Submit': 'Review & Submit',
  'Please select each tab to review the information before submitting the application.':
    'Please select each tab to review the information before submitting the application.',
  'To make changes, go to the appropriate section.':
    'To make changes, go to the appropriate section.',
  'Company details': 'Company details',
  Representatives: 'Representatives',
  Shareholders: 'Shareholders',
  'Business nature': 'Business nature',
  Email: 'Email',
  'Phone number': 'Phone number',
  'Device account IBAN': 'Device account IBAN',
  'E-Commerce account IBAN': 'E-Commerce account IBAN',
  Residency: 'Residency',
  'Submitting application': 'Submitting application',
  'Please make sure that you verified the information in the tabs. You will not be able to change the application data after submitting.':
    'Please make sure that you verified the information in the tabs. You will not be able to change the application data after submitting.',
  'Do you want to continue?': 'Do you want to continue?',
  'Correct application data': 'Correct application data',
  'Complete all steps to submit application': 'Complete all steps to submit application',
  'Submit application': 'Submit application',
  'Upload data from UAE PASS': 'Upload data from UAE PASS',
  'Consent on UAE PASS': 'Consent on UAE PASS',
  'Since you are authorized representative of this company and login through UAE PASS, your data will be prepopulated from UAE PASS instead of filling this form. Please give consent for uploading the following documents:':
    'Since you are authorized representative of this company and login through UAE PASS, your data will be prepopulated from UAE PASS instead of filling this form. Please give consent for uploading the following documents:',
  Visa: 'Visa',
  'Additionally if you need to add POA as a signatory proof, you can add it to your UAE PASS app by requesting from Ministry of Justice department':
    'Additionally if you need to add POA as a signatory proof, you can add it to your UAE PASS app by requesting from Ministry of Justice department',
  'Import UAE PASS': 'Import UAE PASS',
  'Documents uploaded from UAE PASS': 'Documents uploaded from UAE PASS',
  "We couldn't receive your information from UAE PASS, please login again without UAE PASS to enter your personal data manually":
    "We couldn't receive your information from UAE PASS, please login again without UAE PASS to enter your personal data manually",
  'Invitation sent': 'Invitation sent',
  'Send invitation': 'Send invitation',
};
