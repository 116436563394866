import React, { FC } from 'react';
import { Popconfirm, Typography } from 'antd';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import Drawer, { DrawerProps } from './Drawer';
import Button, { ButtonProps } from './Button';
import theme from '~/constants/theme';
import direction from '~/utils/direction';
import { useDirection } from '~/hooks/i18nHooks';

interface Props extends Omit<DrawerProps, 'onClose'> {
  id: string;
  title?: React.ReactNode;
  description?: string;
  formName?: string;
  loading?: boolean;
  continueButtonProps?: ButtonProps;
  withContinueButton?: boolean;
  onClose?: () => void;
  withCustomButtons?: boolean;
  customButtonsProps?: ButtonProps[];
}

const FormDrawer: FC<Props> = (props) => {
  const {
    id,
    title,
    description,
    children,
    size = 'large',
    onClose,
    formName,
    loading,
    continueButtonProps = {},
    withContinueButton = true,
    width,
    withCustomButtons = false,
    customButtonsProps = [],
    closable = false,
    ...rest
  } = props;

  const { t } = useTranslation();
  const dir = useDirection();

  const customButtonRender = () => {
    return customButtonsProps.map(
      ({ id: buttonId, title: buttonTitle, className, ...buttonRest }) => (
        <Button
          key={buttonId}
          id={buttonId}
          type="default"
          block
          className={cx(className, 'FormDrawer-Close')}
          {...buttonRest}
        >
          {buttonTitle}
        </Button>
      )
    );
  };

  return (
    <StyledDrawer
      id={id}
      className="FormDrawer"
      size={size}
      withContinueButton={withContinueButton}
      customButtonsProps={customButtonsProps}
      onClose={onClose}
      maskClosable={false}
      closable={closable}
      placement={dir === 'ltr' ? 'right' : 'left'}
      dir={dir}
      {...rest}
    >
      <div className="FormDrawer-Container">
        <div className="FormDrawer-Content">
          {title && <Typography.Title className="FormDrawer-Title">{title}</Typography.Title>}
          {description && <Typography.Paragraph>{description}</Typography.Paragraph>}
          {children}
        </div>
        {formName && (
          <div className="FormDrawer-Corner">
            <div className="FormDrawer-Buttons">
              {withCustomButtons ? (
                customButtonRender()
              ) : (
                <>
                  <Popconfirm
                    title={t('Discard changes?')}
                    onConfirm={onClose}
                    okText={t('Yes')}
                    cancelText={t('No')}
                    okButtonProps={{ id: `${id}_PopconfirmOkButton` }}
                    cancelButtonProps={{ id: `${id}_PopconfirmCancelButton` }}
                  >
                    <Button
                      id={`${id}_CloseButton`}
                      className="FormDrawer-Close"
                      danger
                      block
                      type="default"
                    >
                      {t('Close')}
                    </Button>
                  </Popconfirm>
                  {withContinueButton && (
                    <Button
                      id={`${id}_SaveButton`}
                      className="FormDrawer-ContinueButton"
                      type="primary"
                      block
                      loading={loading}
                      {...continueButtonProps}
                      htmlType="submit"
                      form={formName}
                    >
                      {t('Save')}
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </StyledDrawer>
  );
};

const StyledDrawer = styled<FC<Props>>(Drawer as any)`
  .FormDrawer-Container {
    display: flex;
    min-height: 100%;
    .FormDrawer-Content {
      display: flex;
      flex: 1;
      flex-direction: column;
      width: 42px; // prevents ui bugs with select input
    }
    .FormDrawer-Corner {
      position: relative;
      display: flex;
      flex-direction: column;
      flex: 1;
      ${direction.padding.left(80)};
      max-width: 357px;
      .FormDrawer-Buttons {
        position: sticky;
        ${({ withContinueButton, customButtonsProps }) =>
          withContinueButton || (customButtonsProps && customButtonsProps.length >= 2)
            ? css`
                top: calc(100vh - 232px);
                .FormDrawer-Close {
                  margin-bottom: 24px;
                }
              `
            : css`
                top: calc(100vh - 160px);
              `}
      }
    }
  }
  @media (max-width: ${theme.breakpoints.md}px) {
    .FormDrawer-Container {
      flex-direction: column;
      .FormDrawer-Content {
        width: unset;
      }
      .FormDrawer-Corner {
        flex: 0;
        ${direction.padding.left(0)};
        max-width: 100%;
      }
    }
  }

  .FormDrawer-Title {
    word-break: break-word;
    @media (max-width: ${theme.breakpoints.sm}px) {
      width: 90%;
    }
  }
`;

export default FormDrawer;
