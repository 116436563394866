import InternalSpaceBetweenField from './SpaceBetweenField';
import InternalLeft from './Left';
import InternalRight from './Right';

type InternalSpaceBetweenFieldType = typeof InternalSpaceBetweenField;

interface SpaceBetweenFieldType extends InternalSpaceBetweenFieldType {
  Left: typeof InternalLeft;
  Right: typeof InternalRight;
}

const SpaceBetweenField = InternalSpaceBetweenField as SpaceBetweenFieldType;

SpaceBetweenField.Left = InternalLeft;
SpaceBetweenField.Right = InternalRight;

export default SpaceBetweenField;
