import React, { FC } from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import CompanyTypeTitle from '~/layouts/CompanyTypeTitle';
import features from '~/constants/features';

const GetInTouch: FC = () => {
  const { t } = useTranslation();

  return (
    <Container className="GetInTouch">
      <CompanyTypeTitle text={t('Get in touch')} />
      <Typography.Paragraph className="GetInTouch-Paragraph">
        <p>{t('Thank you for the information provided so far.')}</p>
        <p>
          {t(
            'Due to your business type, we will need one of our dedicated team to take you through the application process to ensure the right services for you.'
          )}
        </p>
        <Trans i18nKey="Please contact us via our <1>Contact us page</1> to arrange a call back.">
          {'Please contact us via our '}
          <a href={features.contactUsLinkIndustry} rel="noreferrer" target="_blank">
            Contact us page
          </a>
          {' to arrange a call back.'}
        </Trans>
      </Typography.Paragraph>
    </Container>
  );
};

const Container = styled.div`
  .GetInTouch-Paragraph {
    margin-bottom: 40px;
  }
`;

export default GetInTouch;
