import React, { FC } from 'react';
import { Collapse as AntdCollapse, CollapseProps as AntdCollapseProps } from 'antd';
import styled from 'styled-components';

import { ArrowRightIcon } from '~/img';
import theme from '~/constants/theme';
import direction, { getRotateStyle } from '~/utils/direction';
import { DirectionProp } from '~/types/Direction';
import { useDirection } from '~/hooks/i18nHooks';

const Collapse: FC<AntdCollapseProps> = (props) => {
  const dir = useDirection();

  return (
    <StyledAntdCollapse
      expandIcon={({ isActive }) => {
        return <ArrowRightIcon className="Collapse-Icon" style={getRotateStyle(!!isActive, dir)} />;
      }}
      dir={dir}
      {...props}
    />
  );
};

type UIProps = AntdCollapseProps & DirectionProp;

const StyledAntdCollapse = styled<FC<UIProps>>(AntdCollapse)`
  .Collapse-Icon {
    font-size: 16px;
    transition: transform 0.24s;
    ${direction.margin.right('8px !important')};
    ${direction.margin.left('0 !important')};
  }
  @media (max-width: ${theme.breakpoints.sm}px) {
    .Collapse-Icon {
      ${direction.margin.right('8px !important')};
    }
  }
`;

export default Collapse;
