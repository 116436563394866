import React, { FC } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSoftDeclineGoFixApplication } from '~/controllers/wizard';

const UpdateApplicationButton: FC = () => {
  const { t } = useTranslation('stageResult');
  const goFixApplicationMutation = useSoftDeclineGoFixApplication();
  return (
    <Button
      id="updateApplication_updateButton"
      type="primary"
      onClick={() => goFixApplicationMutation.mutate()}
      loading={goFixApplicationMutation.isLoading}
    >
      {t('Update Application')}
    </Button>
  );
};

export default UpdateApplicationButton;
