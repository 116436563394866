import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import colors from '~/constants/colors';
import { useDirection } from '~/hooks/i18nHooks';
import { ContactUsIcon } from '~/img';
import direction from '~/utils/direction';

interface Props {
  url: string;
}

const ContactUs: FC<Props> = ({ url }: Props) => {
  const { t } = useTranslation();
  const dir = useDirection();

  return (
    <Link id="contactUsButton" className="ContactUs" href={url} target="_blank" dir={dir}>
      <ContactUsIcon className="ContactUs-Icon" dir={dir} />
      <span className="ContactUs-Text">{t('Contact us')}</span>
    </Link>
  );
};

const Link = styled.a`
  display: flex;
  margin-top: auto;
  color: ${colors.blue[1100]};
  .ContactUs-Icon {
    font-size: 40px;
  }
  .ContactUs-Text {
    display: flex;
    ${direction.margin.left(8)}
    text-transform: uppercase;
    line-height: 40px;
    font-size: 14px;
  }
`;

export default ContactUs;
