export default {
  'Step data must be changed before pressing "Continue" button':
    'يجب تغيير بيانات الخطوة قبل الضغط على زر "متابعة"',
  'Before application submit, please ensure a payment link is sent to merchant and payment is received. To get the latest payment status please update the page. Once payment is successful you will see payment reference in the "Pay-by-link" section.':
    'قبل تقديم الطلب ، يرجى التأكد من إرسال رابط الدفع إلى التاجر واستلام الدفع. للحصول على أحدث حالة دفع يرجى تحديث الصفحة. بمجرد نجاح الدفع ، سترى مرجع الدفع في قسم "الدفع عن طريق الرابط".',
  'Application has been updated. Please refresh the page to see changes.':
    'تم تحديث التطبيق. يرجى تحديث الصفحة لرؤية التغييرات.',
  'Rental amount can not be changed at this stage': 'لا يمكن تغيير مبلغ الإيجار في هذه المرحلة',
  'The application has already been assigned to another team':
    'تم بالفعل تعيين التطبيق إلى فريق آخر',
  'Session has expired': 'انتهت الجلسة',
  'Internet connection is lost. Please check your connection':
    'تم فقد الاتصال بالإنترنت. يرجى التحقق من اتصالك',
};
