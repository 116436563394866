import { RuleObject } from 'antd/lib/form';
import { getI18n } from 'react-i18next';
import { Amount } from '~/types/Amount';

export const validateAmount: ({
  min,
  max,
}: {
  min?: number;
  max?: number;
}) => RuleObject['validator'] =
  ({ min, max }) =>
  (rule, value: Amount | undefined, callback) => {
    if (!(typeof value?.value === 'number' && typeof value?.currency === 'string')) {
      callback(getI18n().t('Required'));
      return;
    }
    if (typeof min === 'number') {
      if (value.value < min) {
        callback(getI18n().t('Min is {{minNumber}}', { ns: 'validation', minNumber: min }));
        return;
      }
    }
    if (typeof max === 'number') {
      if (value.value > max) {
        callback(getI18n().t('Max is {{maxNumber}}', { ns: 'validation', maxNumber: max }));
      }
    }
    callback();
  };
