export default {
  'Lucky you! Payment is not required.': 'كنت محظوظا! الدفع غير مطلوب.',
  'Payment is done successfully.': 'تم الدفع بنجاح.',
  'Please select the preferable payment method.': 'الرجاء تحديد طريقة الدفع المفضلة.',
  'Pay online now': 'ادفع عبر الإنترنت الآن',
  'Bank transfer': 'حوالة بنكية',
  'Pay sales representative by cheque': 'دفع مندوب المبيعات بشيك',
  'Cash deposit slip': 'قسيمة الإيداع النقدي',
  'Direct Debit from Emirates Islamic and ENBD bank accounts':
    'الخصم المباشر من حسابات مصرف الإمارات الإسلامي وبنك الإمارات دبي الوطني',
  'Pay by link': 'الدفع عن طريق الرابط',
  'Payment after approval': 'الدفع بعد الموافقة',
  'Customer will pay after approval.': 'سيدفع العميل بعد الموافقة.',
  'Payment reference': 'إشارة دفع',
  'Payment date': 'يوم الدفع او الاستحقاق',
  'Payment by card is available.': 'الدفع عن طريق البطاقة متاح.',
  Proceed: 'يتابع',
  'Payment requisites': 'شروط الدفع',
  'Please quote the company legal name as a payment reference.':
    'يرجى ذكر الاسم القانوني للشركة كمرجع للدفع.',
  'Bank transfer payment please remit to:': 'دفعة التحويل المصرفي يرجى التحويل إلى:',
  'Account holder: {{accountName}}': 'صاحب الحساب: {{accountName}}',
  'IBAN: {{iban}}': '{{iban}} :IBAN',
  'Bank name: {{bankName}}': 'اسم البنك: {{bankName}}',
  'Copy requisites': 'متطلبات النسخ',
  'Upload payment confirmation': 'تحميل تأكيد الدفع',
  'You can pay by Direct Debit with your bank account:':
    'يمكنك الدفع عن طريق الخصم المباشر باستخدام حسابك المصرفي:',
  'Transaction successful.': 'عملية ناجحة.',
  'A payment link was sent to email <strong>{{ lastEmail }}</strong>':
    'تم إرسال رابط الدفع إلى البريد الإلكتروني <strong>{{ lastEmail }}</strong>',
  'A payment link will be sent to the specified email':
    'سيتم إرسال رابط الدفع إلى البريد الإلكتروني المحدد',
  'Resend a link': 'إعادة إرسال ارتباط',
  'Send a link': 'أرسل ارتباطًا',
  'This is the final step. We will notify you by email once your application is approved.':
    'هذه هي الخطوة النهائية. سنخطرك عبر البريد الإلكتروني بمجرد الموافقة على طلبك.',
  'Something went wrong': 'هناك خطأ ما',
  'Close and Try again': 'أغلق وحاول مرة أخرى',
  'An error has been occurred.': 'لقد حدث خطأ.',
  'Please try again later or choose another payment method.':
    'يرجى المحاولة مرة أخرى لاحقًا أو اختيار طريقة دفع أخرى.',
};
