import React, { FC } from 'react';
import { Menu } from 'antd';
import { RowActionsButton } from '~/components';

interface Props {
  actions: { key: string; title: string; disabled?: boolean; action: () => any }[];
}

const AgentTableActions: FC<Props> = (props) => {
  const { actions } = props;
  return (
    <RowActionsButton
      overlay={() => {
        return (
          <Menu mode="inline" className="menu-shadow" selectable={false} style={{ zIndex: 0 }}>
            {actions.map(({ key, action, disabled, title }) => (
              <Menu.Item key={key} id={key} onClick={action} disabled={disabled}>
                {title}
              </Menu.Item>
            ))}
          </Menu>
        );
      }}
    />
  );
};
export default AgentTableActions;
