import { Application } from '~/types/Application';
import { getStagePayload } from './getStagePayload';

export const isResubmit = (application: Application | undefined): boolean => {
  const stagePayload = getStagePayload.wizard(application);
  if (!stagePayload) return false;
  return (
    !!stagePayload.activeStep.errorInformation &&
    stagePayload.activeStep.validationState === 'VALIDATION_ERROR'
  );
};
