import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

import { Tabs } from '~/components';
import CardMobile from '~/components/CardMobile';
import Collapse from '~/components/Collapse';
import theme from '~/constants/theme';
import CompanyDetailsTab from './CompanyDetailsTab';
import RepresentativesTab from './RepresentativesTab';
import ServicesTab from './ServicesTab';
import ShareholdersTab from './ShareholdersTab';

const ReviewAndSubmitTabs: FC = () => {
  const { t } = useTranslation('fillApplication');
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.sm });

  return (
    <Container>
      {!isTablet ? (
        <Tabs className="ReviewAndSubmitTabs" defaultActiveKey="companyDetails" sticky centered>
          <Tabs.TabPane
            tab={<span id={tabsIds.companyDetailsTab}>{t('Company details')}</span>}
            key={tabsIds.companyDetailsTab}
          >
            <CompanyDetailsTab gutter={[16, 24]} />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<span id={tabsIds.representativesTab}>{t('Representatives')}</span>}
            key={tabsIds.representativesTab}
          >
            <RepresentativesTab />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<span id={tabsIds.shareholdersTab}>{t('Shareholders')}</span>}
            key={tabsIds.shareholdersTab}
          >
            <ShareholdersTab />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<span id={tabsIds.servicesTab}>{t('Services')}</span>}
            key={tabsIds.servicesTab}
          >
            <ServicesTab />
          </Tabs.TabPane>
        </Tabs>
      ) : (
        <Collapse defaultActiveKey={['1', '2', '3', '4']} ghost>
          <Collapse.Panel
            className="ReviewAndSubmitTabs-CollapsePanel"
            header={<span id={tabsIds.companyDetailsTab}>{t('Company details')}</span>}
            key="1"
          >
            <CardMobile>
              <CompanyDetailsTab gutter={[16, 16]} xs={12} sm={12} />
            </CardMobile>
          </Collapse.Panel>
          <Collapse.Panel
            id={tabsIds.representativesTab}
            className="ReviewAndSubmitTabs-CollapsePanel"
            header={t('Representatives')}
            key="2"
          >
            <RepresentativesTab />
          </Collapse.Panel>
          <Collapse.Panel
            id={tabsIds.shareholdersTab}
            className="ReviewAndSubmitTabs-CollapsePanel"
            header={t('Shareholders')}
            key="3"
          >
            <ShareholdersTab />
          </Collapse.Panel>
          <Collapse.Panel
            id={tabsIds.servicesTab}
            className="ReviewAndSubmitTabs-CollapsePanel"
            header={t('Services')}
            key="4"
          >
            <ServicesTab />
          </Collapse.Panel>
        </Collapse>
      )}
    </Container>
  );
};

const tabsIds = {
  companyDetailsTab: 'companyDetailsTab',
  representativesTab: 'representativesTab',
  shareholdersTab: 'shareholdersTab',
  servicesTab: 'servicesTab',
};

const Container = styled.div`
  .ant-tabs-nav {
    margin-bottom: 40px;
  }
  .ant-collapse {
    .ReviewAndSubmitTabs-CollapsePanel {
      margin-top: 40px;
    }
    .ReviewAndSubmitTabs-CollapsePanel > .ant-collapse-header {
      padding: 0 !important;
    }
    .ReviewAndSubmitTabs-CollapsePanel > .ant-collapse-content > .ant-collapse-content-box {
      padding: 16px 0 0 0 !important;
    }
    .TextField {
      word-break: break-word;
    }
  }
`;

export default ReviewAndSubmitTabs;
