export default {
  Contract: 'Contract',
  'To generate the contract please press the button below.':
    'To generate the contract please press the button below.',
  'Generate contract': 'Generate contract',
  'Review the generated document and upload the signed contract photos in the area below.':
    'Review the generated document and upload the signed contract photos in the area below.',
  'Download contract': 'Download contract',
  'Sign and submit': 'Sign and submit',
  'Authorised signatory': 'Authorised signatory',
  'Choose another person': 'Choose another person',
  'Who will sign the contract?': 'Who will sign the contract?',
  'Select authorised signatory': 'Select authorised signatory',
  'We will send the invitation link to log in to the Portal.':
    'We will send the invitation link to log in to the Portal.',
  'There is no contract data': 'There is no contract data',
};
