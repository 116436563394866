/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMemo } from 'react';
import { Business } from '~/types/Business';
import { Person } from '~/types/Person';
import { useHandbook } from './handbook';
import { useGetApplication } from './wizard';

const useGetSanctionedValues = () => {
  const handbook = useHandbook();

  const sanctionedNationalities: string[] =
    handbook.data?.handbooks
      ?.find((item) => item.key === 'nationality')
      ?.values?.filter((item) => item.additionalParams?.isSanctionCountry)
      ?.map((item) => item.apiValue!) || [];

  const sanctionedCountries: string[] =
    handbook.data?.handbooks
      ?.find((item) => item.key === 'entitycountryofestablishment')
      ?.values?.filter((item) => item.additionalParams?.isSanctionCountry)
      ?.map((item) => item.apiValue!) || [];

  const sanctionedValues = sanctionedNationalities.concat(sanctionedCountries);

  const sanctionedMap = useMemo(() => {
    const result: Record<string, true> = {};
    sanctionedValues.forEach((value) => {
      result[value] = true;
    });
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sanctionedValues.length]);

  return { isLoading: handbook.isLoading, data: sanctionedMap };
};

export const useIsAuthorizedSignatoryFromSanctionedCountry = () => {
  const sanctionedValues = useGetSanctionedValues();

  const applicationQuery = useGetApplication();
  const authorizedSignatories: Person[] | undefined =
    applicationQuery.data?.payload.authorizedRepresentative?.filter(
      (item) => item.isAuthorizedSignatory
    );

  const data = (() => {
    if (!sanctionedValues.isLoading && authorizedSignatories) {
      return !!authorizedSignatories.find(
        (person) =>
          sanctionedValues.data[person.nationality] || sanctionedValues.data[person.country]
      );
    }
    return undefined;
  })();

  const isLoading = sanctionedValues.isLoading || applicationQuery.isLoading;
  return { isLoading, data };
};

export const useIsShareholderFromSanctionedCountry = () => {
  const sanctionedValues = useGetSanctionedValues();

  const applicationQuery = useGetApplication();
  const shareholders = applicationQuery.data?.payload.shareholders;
  const individualShareholders: Person[] | undefined = shareholders?.individuals?.map(
    (item) => item.data as Person
  );
  const individualNationalities = individualShareholders?.map((item) => item.nationality) || [];
  const individualCountries = individualShareholders?.map((item) => item.country) || [];
  const individualValues = individualNationalities.concat(individualCountries);

  const businessShareholders: Business[] | undefined =
    shareholders?.businesses?.map((item) => item.data as Business) || [];
  const businessValues = businessShareholders?.map((item) => item.country);

  const data = (() => {
    if (!sanctionedValues.isLoading && !applicationQuery.isLoading) {
      const isIndividualSanctioned = !!individualValues.find((item) => sanctionedValues.data[item]);
      const isBusinessSanctioned = !!businessValues.find((item) => sanctionedValues.data[item]);
      return isIndividualSanctioned || isBusinessSanctioned;
    }
    return undefined;
  })();

  const isLoading = sanctionedValues.isLoading || applicationQuery.isLoading;
  return { isLoading, data };
};

export const useIsSomeoneSanctioned = () => {
  const isAuthorizedSignatoryFromSanctionedCountry =
    useIsAuthorizedSignatoryFromSanctionedCountry();
  const isShareholderFromSanctionedCountry = useIsShareholderFromSanctionedCountry();

  return {
    isLoading:
      isAuthorizedSignatoryFromSanctionedCountry.isLoading ||
      isShareholderFromSanctionedCountry.isLoading,
    data:
      isAuthorizedSignatoryFromSanctionedCountry.data || isShareholderFromSanctionedCountry.data,
  };
};
