import { InputNumber, InputNumberProps } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';

import { useDirection } from '~/hooks/i18nHooks';
import direction from '~/utils/direction';
import FloatLabel from '../Form/FloatLabel';

interface Props extends Omit<InputNumberProps<any>, 'value' | 'onChange'> {
  label?: string;
  value?: number;
  onChange?: (value: number) => void;
  precision?: number;
}

const PercentInput: FC<Props> = (props) => {
  const { value, onChange, label, disabled, precision = 2, ...rest } = props;
  const dir = useDirection();

  return (
    <Container className="PercentInput" dir={dir}>
      <strong className="PercentInput-Percent">%</strong>
      <FloatLabel label={label} value={value} onChange={onChange} disabled={disabled}>
        <InputNumber<number> precision={precision} {...rest} />
      </FloatLabel>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  .PercentInput-Percent {
    ${direction.margin.right(8)};
    font-size: 14px;
  }
`;

export default PercentInput;
