import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import RadioGroupInput from '~/components/inputs/RadioGroupInput';
import { DashboardStatus } from '~/types/AgentMapItem';

interface Props {
  value?: any;
  onChange?: (value: any) => void;
}

const DashboardStatusInput: FC<Props> = (props): JSX.Element => {
  const { t } = useTranslation('agent');

  return (
    <RadioGroupInput
      options={[
        {
          label: t(DashboardStatus.OPENED),
          value: DashboardStatus.OPENED,
        },
        {
          label: t(DashboardStatus.DONE),
          value: DashboardStatus.DONE,
        },
        {
          label: t(DashboardStatus.ALL),
          value: DashboardStatus.ALL,
        },
      ]}
      {...props}
    />
  );
};

export default DashboardStatusInput;
