/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/no-array-index-key */
import React, { FC, Fragment, useState } from 'react';
import { InputNumber, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import produce from 'immer';
import { get, merge, uniqBy } from 'lodash';

import { Button, Checkbox, Form } from '~/components';
import FormDrawer from '~/components/FormDrawer';
import { useGetApplication } from '~/controllers/wizard';
import { PersonCheckIcon } from '~/img';

import { useValidator } from '~/controllers/validation';
import buildNamePath from '~/utils/buildNamePath';
import { FormItemContextProvider } from '~/components/Form/FormItemContext';
import { Shareholders } from '~/types/Shareholders';
import { FormItemInjectedProps } from '~/types/FormItemInjectedProps';
import { Draft } from '~/types/Draft';
import { hasSoftDeclineFields } from '~/utils/softDeclinesFields';
import { isShareholderEditButtonDisabled } from './utils';

const AuthorizedRepresentativeAdd: FC<Partial<FormItemInjectedProps<Shareholders>>> = (props) => {
  const { value, onChange } = props;
  const shareholdersFormState = { shareholders: value };

  const [form] = Form.useForm<Draft<'shareholders'>>();
  const getLatestShareholdersFormState = () => {
    return produce(shareholdersFormState, (draft) => {
      merge(draft, form.getFieldsValue());
    });
  };

  const validatorController = useValidator(getLatestShareholdersFormState);

  const { t } = useTranslation('fillApplication');
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    form.setFieldsValue({});
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const applicationQuery = useGetApplication();
  const hasIndividualsValidationWarnings = hasSoftDeclineFields(
    applicationQuery.data,
    'shareholders.individuals[]'
  );

  if (!applicationQuery.data) return null;
  const applicationAuthorizedRepresentative =
    applicationQuery.data?.payload.authorizedRepresentative;

  const onFinish = () => {
    const newShareholdersFormValues = filterEmptyItems(getLatestShareholdersFormState());
    if (newShareholdersFormValues.shareholders) {
      onChange?.(newShareholdersFormValues.shareholders as any);
      handleClose();
    }
  };

  return (
    <Container className="AuthorizedRepresentativeAdd">
      <FormItemContextProvider value={{ defaultValidator: validatorController.validator }}>
        <Button
          id="selectAuthorizedRepresentativeButton"
          extraLarge
          adaptive
          icon={<PersonCheckIcon />}
          disabled={isShareholderEditButtonDisabled(
            applicationQuery.data,
            hasIndividualsValidationWarnings
          )}
          onClick={handleOpen}
        >
          {t('Select authorised representative as a shareholder')}
        </Button>
        <FormDrawer
          id="authorizedRepresentativeAddDrawer"
          visible={open}
          onClose={handleClose}
          title={t('Select authorised representative as a shareholder')}
          description={t(
            'Please select person from the list of shareholders to pre populate the data or fill the form to add new person.'
          )}
          formName={formName}
          destroyOnClose
        >
          <Form
            form={form}
            name={formName}
            onFinish={onFinish}
            // initialValues={shareholdersFormState} // https://github.com/ant-design/ant-design/issues/22171#issuecomment-654357445
            preserve={false}
          >
            {applicationAuthorizedRepresentative?.map((item, index) => {
              return (
                <Fragment key={index}>
                  <Form.Item
                    name={n(['authorizedRepresentative', index, 'enabled'])}
                    valuePropName="checked"
                    noStyle
                    initialValue={getInitialValue(shareholdersFormState, item.uid, 'enabled')}
                  >
                    <Checkbox className="AuthorizedRepresentativeAdd-Checkbox">
                      <strong>{`${item.firstName} ${item.lastName}`}</strong>
                    </Checkbox>
                  </Form.Item>
                  <Form.Visible name={n(['authorizedRepresentative', index, 'enabled'])}>
                    <Typography.Paragraph>
                      {t('Please specify ownership share for this person')}
                    </Typography.Paragraph>
                    <Form.Item
                      name={n(['authorizedRepresentative', index, 'uid'])}
                      initialValue={item.uid}
                      hidden
                    />
                    <Form.Item
                      name={n(['authorizedRepresentative', index, 'shares'])}
                      label={t('Shares, %')}
                      initialValue={getInitialValue(shareholdersFormState, item.uid, 'shares')}
                    >
                      <InputNumber className="AuthorizedRepresentativeAdd-SharesInput" max={100} />
                    </Form.Item>
                  </Form.Visible>
                </Fragment>
              );
            })}
          </Form>
        </FormDrawer>
      </FormItemContextProvider>
    </Container>
  );
};

const getInitialValue = (data: Draft<'shareholders'>, uid: string, name: string): any => {
  return get(data, n('authorizedRepresentative'))?.find((item: any) => item.uid === uid)?.[name];
};

const filterEmptyItems = (data: Draft<'shareholders'>): Draft<'shareholders'> => {
  if (!data.shareholders?.authorizedRepresentative) return data;
  return produce(data, (draft) => {
    draft.shareholders!.authorizedRepresentative =
      draft.shareholders!.authorizedRepresentative!.filter((item) => (item as any)?.enabled);
    draft.shareholders!.authorizedRepresentative = uniqBy(
      draft.shareholders!.authorizedRepresentative,
      'uid'
    );
  });
};

const n = buildNamePath('shareholders');

const formName = 'selectAuthorizedRepresentative';

const Container = styled.div`
  .AuthorizedRepresentativeAdd-Checkbox {
    margin-bottom: 16px;
  }
  .AuthorizedRepresentativeAdd-SharesInput {
    max-width: 323px;
    width: 100%;
  }
`;

export default AuthorizedRepresentativeAdd;
