/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useMemo, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { isEmpty } from 'lodash';
import produce from 'immer';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { CheckThinIcon } from '~/img';
import { useGetApplication } from '~/controllers/wizard';
import { ApplicationPayload } from '~/types/ApplicationPayload';

import DeleteButton from './DeleteButton';
import EditButton from './EditButton';
import BusinessAdd from './BusinessAdd';
import BusinessEdit from './BusinessEdit';
import { Draft } from '~/types/Draft';
import { FormItemInjectedProps } from '~/types/FormItemInjectedProps';
import { Shareholders } from '~/types/Shareholders';
import { hasSoftDeclineFields } from '~/utils/softDeclinesFields';
import theme from '~/constants/theme';
import BusinessCardMobile from './BusinessCardMobile';
import Space from '~/components/Space';
import Table from '~/components/Table';
import CountryField from '~/components/fields/CountryField';
import longdash from '~/constants/longdash';
import { isShareholderEditButtonDisabled } from './utils';

const BusinessTable: FC<Partial<FormItemInjectedProps<Shareholders>>> = (props) => {
  const { value, onChange } = props as FormItemInjectedProps<Shareholders>;
  const shareholdersFormState = { shareholders: value };

  const { t } = useTranslation('fillApplication');

  const applicationQuery = useGetApplication();
  const hasBusinessesValidationWarnings = hasSoftDeclineFields(
    applicationQuery.data,
    'shareholders.businesses[]'
  );

  const businessColumns: ColumnsType<BusinessRowType> = useMemo(
    () => [
      {
        title: t('Legal name'),
        dataIndex: 'legalName',
        key: 'legalName',
      },
      {
        title: t('Country of incorporation'),
        dataIndex: 'country',
        key: 'country',
        render: (country) => <CountryField value={country} />,
      },
      {
        title: t('Company license'),
        dataIndex: 'hasCompanyLicense',
        key: 'hasCompanyLicense',
        render: (hasCompanyLicense: boolean) => {
          if (hasCompanyLicense) return <CheckThinIcon />;
          return null;
        },
      },
      {
        title: t('Shares, %'),
        dataIndex: 'shares',
        key: 'shares',
      },
      {
        title: '',
        key: 'action',
        fixed: 'right',
        render: (_, { onEdit, onDelete }) => (
          <div style={actionsStyle}>
            <Space size={16}>
              {onEdit && (
                <EditButton
                  id="businessTable_EditButton"
                  disabled={isShareholderEditButtonDisabled(
                    applicationQuery.data,
                    hasBusinessesValidationWarnings
                  )}
                  hasValidationWarnings={hasBusinessesValidationWarnings}
                  onClick={onEdit}
                />
              )}
              <DeleteButton
                id="businessTable_DeleteButton"
                disabled={isShareholderEditButtonDisabled(
                  applicationQuery.data,
                  hasBusinessesValidationWarnings
                )}
                onClick={onDelete}
              />
            </Space>
          </div>
        ),
      },
    ],
    [hasBusinessesValidationWarnings, applicationQuery.data, t]
  );

  const [editBusinessId, setBusinessEditId] = useState('');
  const openBusinessEdit = (uid: string) => {
    setBusinessEditId(uid);
  };
  const closeBusinessEdit = () => {
    setBusinessEditId('');
  };

  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.sm });

  if (!applicationQuery.data) {
    return null;
  }
  const payload = applicationQuery.data?.payload;
  const businessDataSource = getAllBusinessData(
    payload,
    shareholdersFormState,
    onChange,
    openBusinessEdit
  );

  return (
    <>
      <BusinessEdit uid={editBusinessId} onClose={closeBusinessEdit} {...props} />
      <Space size={24} direction="vertical">
        {!isEmpty(businessDataSource) && (
          <>
            {!isTablet ? (
              <Table
                id="businessesTable"
                columns={businessColumns}
                dataSource={businessDataSource}
                pagination={pagination}
                rowKey="uid"
              />
            ) : (
              <Space size={12} direction="vertical">
                {businessDataSource.map((item) => (
                  <BusinessCardMobile
                    key={item.uid}
                    disableRowActions={isShareholderEditButtonDisabled(
                      applicationQuery.data,
                      hasBusinessesValidationWarnings
                    )}
                    hasBusinessesValidationWarnings={hasBusinessesValidationWarnings}
                    {...item}
                  />
                ))}
              </Space>
            )}
          </>
        )}
        <BusinessAdd {...props} />
      </Space>
    </>
  );
};

export type BusinessRowType = {
  uid: string;
  legalName: string | undefined;
  country: string | undefined;
  hasCompanyLicense: boolean;
  shares: number | undefined;
  onDelete: () => void;
  onEdit?: () => void;
};

const getAllBusinessData = (
  payload: ApplicationPayload | undefined,
  data: Draft<'shareholders'> | undefined,
  onFormChange: (value: Shareholders) => void,
  openEdit: (uid: string) => void
): BusinessRowType[] => {
  return (
    data?.shareholders?.businesses?.map((item) => {
      return {
        uid: item?.data?.uid as string,
        legalName: item?.data?.companyLicense?.legalName,
        country: item?.data?.country || longdash,
        hasCompanyLicense: !isEmpty(item?.data?.companyLicense?.files),
        shares: item?.shares,
        onDelete: () =>
          onFormChange(
            produce(data, (draft) => {
              draft.shareholders!.businesses = draft.shareholders!.businesses!.filter(
                (a) => a?.data?.uid !== item?.data?.uid
              );
              if (draft.shareholders!.businesses.length === 0) {
                // beneficial owners and additional data not required if lack of businesses
                draft.shareholders!.beneficialOwners = [];
                delete draft.shareholders!.additionalInfo;
              }
            }).shareholders as Shareholders
          ),
        onEdit: () => item?.data?.uid && openEdit(item.data.uid),
      };
    }) || []
  );
};

const pagination = { hideOnSinglePage: true };

const actionsStyle = { display: 'flex', justifyContent: 'flex-end' };

export default BusinessTable;
