import { RcFile, UploadFile } from 'antd/lib/upload/interface';

import useDeepEffect from './useDeepEffect';
import { getFileById } from '~/api/file';
import useMutableCallback from './useMutableCallback';
import useImageCache, { cachedImageBase } from './useImageCache';
import { CachedImage, ImageResponse } from '~/types/files';

const useFileList = (apiImages?: ImageResponse[] | undefined): UploadFile<any>[] => {
  const [imageCache, setImageCache] = useImageCache();

  const loadImage = useMutableCallback(async (apiImage: ImageResponse) => {
    const { id, originalName: name, mimeType } = apiImage;
    const setImageById = (data: CachedImage) => setImageCache((prev) => ({ ...prev, [id]: data }));

    if (imageCache[id]?.antdImage && !imageCache[id].error && !imageCache[id].isLoading) return;

    setImageById({ ...cachedImageBase, isLoading: true });

    try {
      const { data } = await getFileById(id);

      setImageById({
        isLoading: false,
        error: null,
        antdImage: blobToAntdFile(data, name || id, id, mimeType),
        apiImage,
      });
    } catch (err) {
      const error: any = err;
      setImageById({
        isLoading: false,
        error: error.message,
        antdImage: null,
        apiImage: null,
      });
    }
  });

  useDeepEffect(() => {
    apiImages?.forEach((item) => void loadImage(item));
  }, [apiImages]);

  const fileList =
    apiImages?.map((item) => imageCache[item.id]?.antdImage || apiImageToTempAndtImage(item)) || [];
  return fileList;
};

export const blobToAntdFile = (
  image: Blob,
  name: string,
  id: string,
  mimeType: string
): UploadFile => {
  const blobToFile = (theBlob: Blob, fileName: string): RcFile => {
    const b: any = new Blob([theBlob], { type: mimeType }); // override mime type
    // A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    // Cast to a File() type
    // eslint-disable-next-line
    return <RcFile>b;
  };
  return rcFileToAndtImage(blobToFile(image, name), id, mimeType);
};

// NOTE: file2Obj function is taken from antd@4.16.3 and may potential source of bug if antd will be updated
export const rcFileToAndtImage = (file: RcFile, id: string, mimeType: string): UploadFile => {
  const thumbUrl = URL.createObjectURL(file);
  return {
    lastModified: file.lastModified,
    lastModifiedDate: file.lastModifiedDate,
    name: file.name,
    size: file.size,
    type: mimeType,
    uid: id,
    percent: 0,
    thumbUrl,
    status: 'success',
    originFileObj: file,
  };
};

const apiImageToTempAndtImage = (apiImage: ImageResponse): UploadFile => {
  return {
    uid: apiImage.id,
    name: apiImage.originalName,
    type: apiImage.mimeType,
  };
};

export default useFileList;
