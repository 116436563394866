import { Application } from '~/types/Application';
import { getStagePayload } from './getStagePayload';

export const isSomeFieldsDisabled = (application: Application | undefined) => {
  const stagePayload = getStagePayload.wizard(application);
  if (!stagePayload) return false;
  const { activeStep } = stagePayload;
  const isSomeFieldsDisabledStep = activeStep.state === 'UPDATE_ENABLED_FIELDS';
  return isSomeFieldsDisabledStep;
};
