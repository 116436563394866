import React, { FC } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { DirectDebitPaymentType } from '~/types/PaymentMethods';
import BankNameField from '~/components/fields/BankNameField';

interface Props {
  record: DirectDebitPaymentType['items'];
}

const DirectDebit: FC<Props> = (props) => {
  const { record } = props;
  const { t } = useTranslation('payment');

  return (
    <>
      <Typography.Paragraph>
        <strong>{t('You can pay by Direct Debit with your bank account:')}</strong>
      </Typography.Paragraph>
      {record.map(({ bankCode, iban }) => {
        return (
          <div key={bankCode}>
            <span id="directDebit_bankNameField">
              <BankNameField value={bankCode} />
            </span>{' '}
            <span id="directDebit_IBAN">{iban}</span>
          </div>
        );
      })}
    </>
  );
};

export default DirectDebit;
