import React, { FC } from 'react';
import { NamePath } from 'antd/lib/form/interface';
import dayjs from 'dayjs';
import { DateInput, Form } from '~/components';

export interface CreatedDataFromItemProps {
  label?: string;
  name: NamePath;
  filterCreatedDateFrom: NamePath;
}

const CreatedDataToItem: FC<CreatedDataFromItemProps> = (props) => {
  const { filterCreatedDateFrom, name, label } = props;
  const today = dayjs();
  return (
    <Form.ShouldUpdate name={filterCreatedDateFrom}>
      {(form) => {
        const selectedDateFromString = form.getFieldValue(filterCreatedDateFrom);
        const selectedDateFrom = selectedDateFromString ? dayjs(selectedDateFromString) : undefined;
        return (
          <Form.Item label={label} name={name}>
            <DateInput
              disableDateRule={(date) => {
                if (selectedDateFrom && date.isBefore(selectedDateFrom)) return true;
                if (date.isAfter(today) && date.format('DD/MM/YYYY') !== today.format('DD/MM/YYYY'))
                  return true;
                return false;
              }}
              size="middle"
              inputReadOnly
              allowClear
            />
          </Form.Item>
        );
      }}
    </Form.ShouldUpdate>
  );
};

export default CreatedDataToItem;
