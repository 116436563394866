import React, { FC } from 'react';
import styled from 'styled-components';

import Hint from '~/components/Hint';
import colors from '~/constants/colors';
import { useDirection } from '~/hooks/i18nHooks';
import { CheckmarkIcon, CloseSharpIcon } from '~/img';
import { DirectionProp } from '~/types/Direction';
import { BooleanServiceField } from '~/types/Services';
import direction from '~/utils/direction';

interface Props {
  field: BooleanServiceField;
  title?: string;
}

const BooleanField: FC<Props> = (props) => {
  const { field, title } = props;
  const currValue = field.value ?? field.defaultValue;
  const dir = useDirection();

  return (
    <Container className="BooleanField" dir={dir} disabled={currValue === false}>
      <div className="BooleanField-Title">
        {currValue ? (
          <CheckmarkIcon className="BooleanField-CheckmarkIcon" />
        ) : (
          <CloseSharpIcon className="BooleanField-CloseSharpIcon" />
        )}
        {field.name || title}
      </div>
      {field.hint && <Hint text={field.hint} />}
    </Container>
  );
};

interface UIProps extends DirectionProp {
  disabled: boolean;
}

const Container = styled.div<UIProps>`
  display: flex;
  align-items: center;
  .BooleanField-Title {
    display: flex;
    align-items: center;
    ${direction.margin.right(16)};
    .BooleanField-CheckmarkIcon {
      color: ${colors.successColor};
      font-size: 16px;
      ${direction.margin.right(8)};
    }
    .BooleanField-CloseSharpIcon {
      color: ${colors.disabledBlueTextColor};
      font-size: 16px;
      ${direction.margin.right(8)};
    }
  }
  color: ${({ disabled }) => (disabled ? colors.disabledBlueTextColor : undefined)};
`;

export default BooleanField;
