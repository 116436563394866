import React, { memo, ReactNode } from 'react';
import GoogleMap from '~/components/GoogleMap';

interface Props {
  markers: ReactNode[] | undefined;
}

const MapMemorized = (props: Props) => {
  const { markers } = props;
  return <GoogleMap options={mapOptions}>{markers}</GoogleMap>;
};

const mapOptions = {
  fullscreenControl: false,
  zoomControl: true,
  zoomControlOptions: {
    position: 8,
  },
};

export default memo(MapMemorized, (prev, next) => prev.markers === next.markers);
