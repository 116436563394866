/* eslint-disable @typescript-eslint/no-floating-promises */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import languages from './languages';

import features from '~/constants/features';
import branding from '~/constants/branding';

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: features.i18n.fallbackLng,
    lng: features.i18n.defaultLng,
    resources: languages,
    supportedLngs: features.i18n.userLanguages.map(({ code }: any) => code),
    debug: false,
    defaultNS: 'common',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      defaultVariables: {
        BRANDING_NAME: branding.BRANDING_NAME,
        BRANDING_NAME_AR: branding.BRANDING_NAME_AR,
      },
    },
    keySeparator: false, // default: '.'
    react: {
      bindI18n: 'languageChanged', // default: 'languageChanged'
      bindI18nStore: '', // default: ''
      transEmptyNodeValue: '', // default: ''
      transSupportBasicHtmlNodes: true, // default: true
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'bdi'], // default: ['br', 'strong', 'i']
      useSuspense: false, // default: true,
    },
  });

export default i18n;
