import { RuleObject } from 'antd/lib/form';
import { getI18n } from 'react-i18next';
import { isNumeric } from '~/utils/isNumeric';

export const validateNumber: ({
  min,
  max,
}: {
  min?: number;
  max?: number;
}) => RuleObject['validator'] =
  ({ min, max }) =>
  (rule, value: number | null | undefined, callback) => {
    if (!isNumeric(value)) {
      callback(getI18n().t('Required'));
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const numValue = typeof value === 'string' ? parseFloat(value) : value!;
    if (typeof min === 'number') {
      if (numValue < min) {
        callback(getI18n().t('Min is {{minNumber}}', { ns: 'validation', minNumber: min }));
        return;
      }
    }
    if (typeof max === 'number') {
      if (numValue > max) {
        callback(getI18n().t('Max is {{maxNumber}}', { ns: 'validation', maxNumber: max }));
      }
    }
    callback();
  };
