/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useRef, useCallback } from 'react';

function useMutableCallback<T extends (...args: any[]) => any>(callback: T) {
  const changePathRef = useRef(callback);
  changePathRef.current = callback;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(((...args: any[]) => changePathRef.current(...args)) as T, []);
}

export default useMutableCallback;
