import lodash from 'lodash';

// Lodash is also have "defaultsDeep" function, but it's not fully overriding arrays from default by new value,
// it's overriding changed values by index.
// For example:
// default value is ['one', 'two', 'three']
// target value is ['FOUR', 'FIVE']
// Lodash's "defaultsDeep" returns ['FOUR', 'FIVE', 'three']
// function below returns ['FOUR', 'FIVE']

const defaultsDeep = (targetObj, defaultObj) => {
  const result = {};
  Object.keys(defaultObj).forEach((key) => {
    const targetValue = targetObj?.[key];
    const defaultValue = defaultObj[key];
    if (lodash.isPlainObject(defaultValue)) {
      result[key] = defaultsDeep(targetValue, defaultValue);
    } else {
      result[key] =
        targetValue === undefined
          ? defaultValue
          : Array.isArray(targetValue)
          ? targetValue
          : typeof targetValue === 'boolean'
          ? targetValue
          : targetValue || defaultValue;
    }
  });
  return JSON.parse(JSON.stringify(result));
};

export default defaultsDeep;
