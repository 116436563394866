import { toPath } from 'lodash';
import React, { FC } from 'react';
import { useServicesContext } from '~/controllers/ServicesContext';
import { PricingServiceField } from '~/types/Services';
import buildNamePath, { NameFn } from '~/utils/buildNamePath';
import { useFormItemContext } from './Form/FormItemContext';
import PriceInput from './PriceInput';
import PriceRowBase from './PriceRowBase';
import { withRole } from './views/ServicesView/utils';

interface Props {
  n: NameFn;
  pricingField: PricingServiceField;
}

const PricingRowFormItem: FC<Props> = (props) => {
  const { pricingField, n } = props;
  const formItemContext = useFormItemContext();
  const servicesContext = useServicesContext();
  const inputNameString = toPath(n([pricingField.key])).join('.');
  const unableToChangeTotalPrice = servicesContext.disabledFields[inputNameString];

  return (
    <PriceRowBase
      title={pricingField.name}
      hint={pricingField.hint}
      description={pricingField.description}
    >
      <PriceInput
        n={buildNamePath(n([pricingField.key]))}
        amountFromName={
          pricingField.amountFrom ? [n(pricingField.amountFrom), 'amount'].flat() : undefined
        }
        percentFromName={
          pricingField.percentFrom ? [n(pricingField.percentFrom), 'percent'].flat() : undefined
        }
        disabled={
          !withRole(pricingField.editable) ||
          formItemContext.disabledByDefault ||
          unableToChangeTotalPrice
        }
        defaultValue={pricingField.value.defaultValue}
        validation={pricingField.validation}
        affectsPrice={pricingField.affectsPrice}
        savedCustomValue={pricingField.value.customValue}
      />
    </PriceRowBase>
  );
};

export default PricingRowFormItem;
