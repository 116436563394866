/* eslint-disable no-template-curly-in-string */
declare global {
  interface Window {
    ENV: any;
  }
}

export const API_URL = process.env.REACT_APP_API_URL || '/api/v1';

export const GOOGLE_MAPS_API_KEY =
  window.ENV.GOOGLE_MAPS_API_KEY === '${GOOGLE_MAPS_API_KEY}'
    ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    : window.ENV.GOOGLE_MAPS_API_KEY;

export const UAEPASS_DISABLE =
  window.ENV.UAEPASS_DISABLE === '${UAEPASS_DISABLE}'
    ? process.env.REACT_APP_UAEPASS_DISABLE
    : window.ENV.UAEPASS_DISABLE;
