import { useHistory } from 'react-router';
import {
  MutationFunction,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormInstance, Modal } from 'antd';

import {
  isMerchant,
  isUaePassProfileChange,
  resetUaePassProfileChange,
} from '~/utils/getDecodedJwt';
import { getActiveStepPath } from '~/utils/getActiveStepPath';
import { useReduxState } from '~/hooks';
import {
  getApplication,
  salesforceErrorReturnToFillApplication,
  salesforceTechnicalErrorReturnToFillApplication,
  saveStep,
  selectStage,
  selectStep,
  sendInvitation,
  setApplicationStage,
  softDeclineGoFixApplication,
  submitSalesforceError,
  submitSalesforceTechnicalError,
  submitStep,
} from '~/api/wizard';
import { ApiError } from '~/types/ApiError';
import { Application, Stage } from '~/types/Application';
import { ApplicationPayload } from '~/types/ApplicationPayload';
import { useGetDraft } from './draft';
import { Draft } from '~/types/Draft';
import { useTopBarLoader } from '~/hooks/useTopBarLoader';
import { ServicesDraft } from '~/components/views/ServicesView/ServicesForm';
import { scrollToFirstError } from '~/components/Form';
import { useCommonErrors } from '~/controllers/validation';
import { AuthorizedRepresentativeContext } from '~/types/AuthorizedRepresentativeContext';

export const getApplicationKey = 'getApplication';

export const useUaePassProfileChangeCheck = () => {
  const { t } = useTranslation();
  const selectStepMutation = useSelectStepInternal();

  return (res: Application) => {
    // show UAE PASS warning!
    const shouldShow =
      res.stage === 'contract' ||
      res.stage === 'payment' ||
      res.stage === 'fillApplication' ||
      res.stage === 'salesforceSoftDecline';
    if (shouldShow && isUaePassProfileChange()) {
      let shouldNavigate = false;
      if (res.stage === 'fillApplication') {
        const payload = res.stagePayload as any;
        if (payload?.activeStep.key !== 'application.authorizedSignatory') {
          const targetStep = payload.steps
            .find((step: any) => step.key === 'application')
            ?.steps?.find((step: any) => step.key === 'application.authorizedSignatory');
          if (targetStep?.state === 'VISIBLE') {
            shouldNavigate = true;
          }
        }
      }
      if (shouldNavigate) {
        Modal.warn({
          title: t('UAE PASS profile has changed'),
          content: t(
            'Please confirm your email/mobile number changes in UAE PASS on the application.'
          ),
          icon: null,
          centered: true,
          okText: t('Navigate and confirm'),
          okButtonProps: {
            id: 'uaepass_profileChange_navigate',
          },
          onOk: async () => {
            await selectStepMutation.mutateAsync('application.authorizedSignatory');
            resetUaePassProfileChange();
          },
          width: 450,
        });
      } else {
        Modal.warn({
          title: t('UAE PASS profile has changed'),
          content: t(
            'Your email/mobile number information has been changed in UAE PASS, please be sure that your information in NI-Agreement is accurate.'
          ),
          icon: null,
          centered: true,
          okText: t('Agree and Continue'),
          okButtonProps: {
            id: 'uaepass_profileChange_agree',
          },
          onOk: () => {
            resetUaePassProfileChange();
          },
          width: 450,
        });
      }
    }
  };
};

export const useGetApplication = (options: UseQueryOptions<Application, ApiError> = {}) => {
  const history = useHistory();
  const checkUaePassProfileChange = useUaePassProfileChangeCheck();

  const applicationQuery = useQuery<Application, ApiError>(getApplicationKey, getApplication, {
    staleTime: Infinity,
    ...options,
    onSuccess(res) {
      if (options.onSuccess) {
        options.onSuccess(res);
      } else {
        history.push(getActiveStepPath(res));
      }
      checkUaePassProfileChange(res);
    },
  });

  return applicationQuery;
};

export const useUpdateGetApplicationCache = () => {
  const queryClient = useQueryClient();
  const checkUaePassProfileChange = useUaePassProfileChangeCheck();
  return (value: Application) => {
    queryClient.setQueryData<Application>(getApplicationKey, () => value);
    checkUaePassProfileChange(value);
  };
};

export const useSelectApplicationStage = () => {
  const updateCache = useUpdateGetApplicationCache();
  const { startLoading, stopLoading } = useTopBarLoader();

  return {
    select: (stage: Stage) => {
      return async () => {
        startLoading();
        try {
          const res = await selectStage(stage);
          updateCache(res);
        } catch (error) {
          //
        }
        stopLoading();
      };
    },
  };
};

const useUpdateGetApplicationCacheInternal = () => {
  const queryClient = useQueryClient();
  return (value: Application) => {
    queryClient.setQueryData<Application>(getApplicationKey, () => value);
  };
};

const useSelectStepInternal = () => {
  const updateCache = useUpdateGetApplicationCacheInternal();
  const { startLoading, stopLoading } = useTopBarLoader();

  return useMutation(selectStep, {
    onMutate() {
      startLoading();
    },
    onSuccess(res) {
      updateCache(res);
    },
    onSettled() {
      stopLoading();
    },
  });
};

export const useSelectStep = () => {
  const updateCache = useUpdateGetApplicationCache();
  const { startLoading, stopLoading } = useTopBarLoader();

  return useMutation(selectStep, {
    onMutate() {
      startLoading();
    },
    onSuccess(res) {
      updateCache(res);
    },
    onSettled() {
      stopLoading();
    },
  });
};

export const useSubmitStepIsLoading = () => {
  return useReduxState<boolean>('submitStateIsLoading', false);
};

export const useSubmitStep = () => {
  const updateCache = useUpdateGetApplicationCache();
  const [, setState] = useSubmitStepIsLoading();
  const history = useHistory();

  return useMutation(submitStep, {
    onMutate() {
      setState(true);
    },
    onSuccess(res) {
      updateCache(res);
      history.push(getActiveStepPath(res));
    },
    onSettled() {
      setState(false);
    },
  });
};

export const useSaveStepIsLoading = () => {
  return useReduxState<boolean>('saveStateIsLoading', false);
};

export const useSaveStep = () => {
  const updateCache = useUpdateGetApplicationCache();
  const [, setState] = useSaveStepIsLoading();

  return useMutation(saveStep, {
    onMutate() {
      setState(true);
    },
    onSuccess(res) {
      updateCache(res);
    },
    onSettled() {
      setState(false);
    },
  });
};

export const useSaveStepController = (form: FormInstance<any>) => {
  const saveMutation = useSaveStep();

  const getFormState = () => form.getFieldsValue();
  const [, handleCommonErrors] = useCommonErrors();

  const handleSave = async (args: {
    onBeforeSave?: (values: any) => Promise<any>;
    onAfterSave?: (values: any) => Promise<any>;
    onFinishFailed?: (values: { values: any; errorFields: any; outOfDate: boolean }) => any;
    parseBeforeSave?: (values: any) => any;
  }) => {
    const { onBeforeSave, onAfterSave, onFinishFailed, parseBeforeSave } = args;

    const values = getFormState();
    const result = parseBeforeSave ? parseBeforeSave(values) : values;
    try {
      await onBeforeSave?.(values);
      await form.validateFields();
      await saveMutation.mutateAsync(result);
      await onAfterSave?.(values);
    } catch (error) {
      onFinishFailed?.(error as any);
      if (Array.isArray((error as any).errorFields)) {
        const { errorFields } = error as any;
        form.scrollToField(errorFields[0].name, scrollToFirstError);
      }
      const errorDetails: Record<string, string> = (error as any)?.response?.data?.details;
      if (errorDetails) {
        const errorFields = Object.entries(errorDetails).map(([errName, errMessage]) => ({
          name: errName.split('.'),
          errors: [errMessage],
        }));
        handleCommonErrors.onError(errorFields);
      }
      return Promise.reject();
    }
    return Promise.resolve();
  };

  return { ...saveMutation, handleSave, mutate: null, mutateAsync: null };
};

export const useApplicationFormData = <D extends Draft<any> = any>(
  payloadKey: keyof ApplicationPayload
): { isLoading: boolean; data: D | undefined } => {
  const applicationQuery = useGetApplication();
  const draftQuery = useGetDraft<D>();

  const scopedData = draftQuery.data?.[payloadKey] || applicationQuery.data?.payload?.[payloadKey];

  return {
    isLoading: applicationQuery.isLoading || draftQuery.isLoading,
    data: scopedData
      ? ({
          [payloadKey]: scopedData,
        } as D)
      : undefined,
  };
};

export const useIsAgreeWithServicesTermsAndConditions = (): [
  boolean | undefined,
  (value: boolean) => void
] => {
  const [state, setState] = useReduxState<boolean | undefined>(
    'isAgreeWithServicesTermsAndConditions'
  );

  const { showAgreement } = useIsAgreeWaringMessage();

  // set default value
  const applicationQuery = useGetApplication();
  const savedIsAgree = applicationQuery.data
    ? !!applicationQuery.data.payload.isAgreeWithServicesTermsAndConditions
    : undefined;

  useEffect(() => {
    if (!showAgreement) {
      setState(true);
    } else if (typeof savedIsAgree === 'boolean' && typeof state !== 'boolean') {
      setState(savedIsAgree);
    }
  }, [savedIsAgree, setState, state, showAgreement]);

  return [state, setState];
};

export const useIsAgreeWaringMessage = () => {
  const [message, setState] = useReduxState<string | null>('isAgreeWaringMessage', null);
  const { t } = useTranslation('fillApplication');

  const showAgreement = isMerchant();
  const setMessage = () => {
    setState(t('Agreement to the above Fees and Charges required'));
  };
  const clearMessage = () => {
    setState(null);
  };
  return { message, setMessage, clearMessage, showAgreement };
};

export const useIsAtLeastOneServiceSelected = (): [
  boolean,
  (formValues: ServicesDraft) => void
] => {
  const [state, setState] = useReduxState('selectedAtLeastOne', false);
  const handleIsChanged = (formValues: ServicesDraft) => {
    const selectedProducts = formValues.services;
    const selectedAtLeastOne = !selectedProducts
      ? false
      : Object.entries(selectedProducts).filter(([, value]) => value.enabled).length >= 1;
    setState(() => selectedAtLeastOne);
  };
  return [state, handleIsChanged];
};

export const useTotalPriceVisible = () => {
  return useReduxState('totalPriceVisible', false);
};

export const useSetApplicationStage = () => {
  const updateCache = useUpdateGetApplicationCache();
  const [, setState] = useSubmitStepIsLoading();
  const history = useHistory();

  return useMutation(setApplicationStage, {
    onMutate() {
      setState(true);
    },
    onSuccess(res) {
      updateCache(res);
      history.push(getActiveStepPath(res));
    },
    onSettled() {
      setState(false);
    },
  });
};

export const useAuthorizedRepresentativeSendInvitation = () => {
  return useMutation(sendInvitation);
};

const useResubmit = <TVariables = void>(mutationFn: MutationFunction<Application, TVariables>) => {
  const updateCache = useUpdateGetApplicationCache();
  const { startLoading, stopLoading } = useTopBarLoader();
  const history = useHistory();

  return useMutation(mutationFn, {
    onMutate() {
      startLoading();
    },
    onSuccess(res) {
      updateCache(res);
      history.push(getActiveStepPath(res));
    },
    onSettled() {
      stopLoading();
    },
  });
};

export const useSubmitSalesforceTechnicalError = () => {
  return useResubmit(submitSalesforceTechnicalError);
};

export const useSubmitSalesforceError = () => {
  return useResubmit(submitSalesforceError);
};

export const useSoftDeclineGoFixApplication = () => {
  return useResubmit(softDeclineGoFixApplication);
};

export const useSalesforceErrorReturnToFillApplication = () => {
  const history = useHistory();
  const updateCache = useUpdateGetApplicationCache();

  return useMutation(salesforceErrorReturnToFillApplication, {
    onSuccess(res) {
      updateCache(res);
      history.push(getActiveStepPath(res));
    },
  });
};

export const useSalesforceTechnicalErrorReturnToFillApplication = () => {
  const history = useHistory();
  const updateCache = useUpdateGetApplicationCache();

  return useMutation(salesforceTechnicalErrorReturnToFillApplication, {
    onSuccess(res) {
      updateCache(res);
      history.push(getActiveStepPath(res));
    },
  });
};

export const useAuthorizedRepresentativeContext = () => {
  return useReduxState<AuthorizedRepresentativeContext | undefined>(
    'authorizedRepresentativeContext',
    undefined
  );
};
