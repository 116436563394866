import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import colors from '~/constants/colors';

const OptionalLabel: FC = () => {
  const { t } = useTranslation();
  return <Container className="OptionalLabel">{t('Optional')}</Container>;
};

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  border-radius: 4px;
  padding: 0 4px;
  background: ${colors.borderColorBase};
  color: ${colors.layoutBodyBackground};
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
`;

export default OptionalLabel;
