import React, { FC, useEffect, useState } from 'react';
import { Popover, Space } from 'antd';
import styled, { css } from 'styled-components';
import { useHistory, useLocation } from 'react-router';
import { withSize } from 'react-sizeme';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';

import theme from '~/constants/theme';
import colors from '~/constants/colors';
import { CheckmarkIcon, WarningIcon } from '~/img';
import { useSelectStep } from '~/controllers/wizard';
import { Circle, SizeMeHOC } from '~/components';
import direction from '~/utils/direction';
import { DirectionProp } from '~/types/Direction';
import { useDirection } from '~/hooks/i18nHooks';

export interface WizardNavStepProps extends WizardNavStepStateProps {
  route: string;
  stepKey?: 'services' | 'application';
  onClick?: () => void;
  icon: JSX.Element;
  name: string;
  subSteps?: JSX.Element;
}

export interface WizardNavStepStateProps {
  active?: boolean;
  disabled: boolean;
  done: boolean;
  warning: boolean;
  error: boolean;
  waiting: boolean;
}

const WizardNavStep: FC<WizardNavStepProps> = (props) => {
  const {
    route,
    onClick,
    stepKey,
    icon,
    name,
    disabled,
    done,
    active,
    warning,
    error,
    waiting,
    subSteps,
  } = props;
  const history = useHistory();
  const location = useLocation();

  const selectStepMutation = useSelectStep();

  const handleNavClick = async () => {
    if (subSteps) {
      //
    } else if (onClick) {
      if (!disabled) {
        onClick();
      }
    } else if (!disabled && stepKey) {
      try {
        await selectStepMutation.mutateAsync(stepKey);
        history.push(route);
      } catch (err) {
        //
      }
    }
  };

  const [textWidth, onWidthChange] = useState<number | null>(null);
  const isMobile = useMediaQuery({ maxWidth: theme.breakpoints.sm });

  const dir = useDirection();

  const content = (
    <Container
      id={`navTo:${route}`}
      className="WizardNavStep"
      disabled={disabled}
      done={done}
      error={error}
      warning={warning}
      waiting={waiting}
      active={active ?? location.pathname.startsWith(route)}
      onClick={handleNavClick}
      textWidth={textWidth}
      restWidth={isMobile ? 46 : 58}
      dir={dir}
    >
      {done && (
        <Circle
          className="WizardNavStep-StatusIcon"
          size={14}
          backgroundColor={colors.successColor}
          color={colors.componentBackground}
        >
          <CheckmarkIcon />
        </Circle>
      )}
      {waiting && (
        <Circle
          className="WizardNavStep-StatusIcon"
          size={14}
          backgroundColor={colors.disabledBlueTextColor}
          color={colors.componentBackground}
        >
          -
        </Circle>
      )}
      {warning && (
        <Circle
          className="WizardNavStep-StatusIcon"
          size={14}
          backgroundColor={colors.warningColor}
          color={colors.componentBackground}
        >
          <WarningIcon />
        </Circle>
      )}
      <Space size={8}>
        <icon.type className="WizardNavStep-Icon" {...icon.props} />
        <SizeMeHOC onWidthChange={onWidthChange}>
          <div className="WizardNavStep-Text">{name}</div>
        </SizeMeHOC>
      </Space>
    </Container>
  );

  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  if (subSteps) {
    return (
      <Popover
        overlayClassName={classNames('WizardNavStep-PopoverOverlay', {
          'WizardNavStep-PopoverOverlay__rtl': dir === 'rtl',
        })}
        placement="bottom"
        content={subSteps}
        trigger="click"
        onVisibleChange={!disabled ? setOpen : undefined}
        visible={open}
      >
        {content}
      </Popover>
    );
  }
  return content;
};

type WizardStepStylesProps = { textWidth?: number | null; restWidth: number };

const Container = styled.nav<WizardNavStepStateProps & WizardStepStylesProps & DirectionProp>`
  position: relative;
  display: flex;
  align-items: center;
  height: 48px;
  width: ${({ textWidth, restWidth }) => (textWidth ? `${restWidth + textWidth}px` : 'unset')};
  padding: 0 12px;
  border-radius: ${theme.borderRadiusBase}px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.05);

  transition: width 0.2s ease;

  .ant-space {
    overflow: hidden;
  }
  .ant-space-item {
    display: flex;
  }
  .WizardNavStep-Icon {
    font-size: 24px;
  }
  .WizardNavStep-StatusIcon {
    position: absolute;
    top: -4px;
    ${direction.right(-4)};
    font-size: 8px;
  }
  .WizardNavStep-Text {
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    white-space: nowrap;
    opacity: 1;
    transition: opacity 0.2s ease;
  }

  ${({ disabled }) =>
    disabled
      ? css`
          background-color: ${colors.disabledBlueBackground};
          color: ${colors.disabledBlueTextColor};
          cursor: default;
        `
      : css`
          background-color: ${colors.componentBackground};
          color: ${colors.textColor};
          cursor: pointer;
        `}

  ${({ active }) =>
    active
      ? css`
          box-shadow: inset 0 0 0 2px ${colors.borderColorBase};
        `
      : css`
          //
        `}


  @media (max-width: ${theme.breakpoints.lg}px) {
    ${({ active }) =>
      !active
        ? css`
            width: 48px;
            .ant-space {
              gap: 0 !important;
            }
            .WizardNavStep-Text {
              opacity: 0;
            }
          `
        : css`
            //
          `}
  }

  @media (max-width: ${theme.breakpoints.sm}px) {
    height: 40px;
    padding: 0 8px;
    .WizardNavStep-Icon {
      font-size: 20px;
    }
    .WizardNavStep-Text {
      font-size: 12px;
      line-height: 14px;
    }
    ${({ active }) =>
      !active
        ? css`
            width: 40px;
            .WizardNavStep-Icon {
              ${direction.margin.left(2)};
            }
          `
        : css`
            //
          `}
  }
`;

export default withSize({ refreshMode: 'debounce' })(WizardNavStep);
