import React, { FC } from 'react';
import { Menu, Dropdown, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Avatar, LanguageSelector } from '~/components';
import { LogOutIcon } from '~/img';
import { useLogout } from '~/controllers/auth';
import { useDirection } from '~/hooks/i18nHooks';

const Overlay: FC = () => {
  const [t] = useTranslation();
  const dir = useDirection();

  const { handleLogout } = useLogout();

  return (
    <>
      <Menu mode="inline" selectable={false} className="menu-shadow">
        <Menu.Item
          id="headerIdentityMenu-logoutAction"
          icon={<LogOutIcon dir={dir} />}
          onClick={handleLogout}
        >
          {t('Logout')}
        </Menu.Item>
      </Menu>
    </>
  );
};

const HeaderIdentityMenu: FC = () => {
  return (
    <Space size={24} align="center">
      <LanguageSelector />
      <Container className="HeaderIdentityMenu">
        <Dropdown trigger={['click']} overlay={<Overlay />} placement="bottomRight" arrow>
          <Space className="HeaderIdentityMenu-DropdownArea" size={14}>
            <Avatar id="headerIdentityMenu" size={30} />
          </Space>
        </Dropdown>
      </Container>
    </Space>
  );
};

const Container = styled.div`
  cursor: pointer;
`;

export default HeaderIdentityMenu;
