import React from 'react';
import { Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import styled, { css } from 'styled-components';
import { getI18n } from 'react-i18next';
import TableHeader from '~/components/views/agent/tableElements/TableHeader';
import { AgentLead } from '~/types/AgentLead';
import { EmailIcon, PhoneIcon } from '~/img';
import { useCopy } from '~/hooks/clipboard';
import { CellText } from '~/components/views/agent/tableElements/cells/CellText';

function contactCell(): ColumnsType<any>[0] {
  return {
    title: () => <TableHeader title={getI18n().t('Contact', { ns: 'agent' })} />,
    dataIndex: ['payload', 'contactPerson'],
    key: 'contactPerson',
    render: ({
      firstName,
      lastName,
      email,
      phoneNumber,
    }: AgentLead['payload']['contactPerson']) => {
      return (
        <Container>
          <CellText
            className="agentTableContactsName"
            rows={1}
            key={`${firstName} ${lastName}`}
          >{`${firstName ?? ''} ${lastName ?? ''}`}</CellText>
          <div className="agentTableContacts">
            <ContactButton contactValue={email} type="EMAIL" />
            <ContactButton contactValue={phoneNumber} type="PHONE" />
          </div>
        </Container>
      );
    },
    width: 120,
  };
}

interface Props {
  contactValue?: string;
  type: 'EMAIL' | 'PHONE';
}

const ContactButton = ({ contactValue, type }: Props) => {
  const copy = useCopy();

  return (
    <Tooltip title={contactValue || undefined}>
      <ContactContainer
        onClick={(e) => {
          if (contactValue) {
            void copy(contactValue);
          }
          e.stopPropagation();
        }}
        isActive={!!contactValue}
      >
        {type === 'EMAIL' ? (
          <EmailIcon className="agentTableContactIcon" />
        ) : (
          <PhoneIcon className="agentTableContactIcon" />
        )}
      </ContactContainer>
    </Tooltip>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  .agentTableContactsName {
    width: 120px;
  }

  .agentTableContacts {
    display: flex;
    flex-direction: row;
  }

  .agentTableContactIcon {
    font-size: 26px;
    margin-right: 8px;
  }
`;

const ContactContainer = styled.div<{ isActive: boolean }>`
  ${({ isActive }) =>
    isActive
      ? css`
          cursor: pointer;
        `
      : undefined}
  .agentTableContactIcon {
    ${({ isActive }) =>
      !isActive
        ? css`
            opacity: 0.4;
          `
        : undefined}
  }
`;
export default contactCell;
