import React, { FC } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import WizardContent from '~/layouts/WizardContent';
import { CompletedImg } from '~/img';
import LeftLayout from '~/layouts/LeftLayout';
import BottomLeftActions from '~/layouts/BottomLeftActions';
import features from '~/constants/features';

const Completed: FC = () => {
  const { t } = useTranslation('stageResult');

  return (
    <WizardContent
      left={
        <LeftLayout>
          <BottomLeftActions contactUsUrl={features.contactUsLink} />
        </LeftLayout>
      }
      content={
        <Container className="Completed">
          <Typography.Title>{t('Welcome to the Network Family!')}</Typography.Title>
          <Typography.Paragraph>
            <p>{t("You're now fully set up to take payments through Network International.")}</p>
            <p>
              {t(
                'If you wish to discuss any aspects of your services, please contact us via the details within your welcome kit email.'
              )}
            </p>
          </Typography.Paragraph>
          <CompletedImg className="Completed-Img" />
        </Container>
      }
      right={null}
    />
  );
};

const Container = styled.div`
  .Completed-Img {
    margin-top: 36px;
    border-radius: 14px;
    width: 100%;
  }
`;

export default Completed;
