import React, { FC } from 'react';
import { SelectProps } from 'antd';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import { useHandbook } from '~/controllers/handbook';
import Select from './Select';
import { Hint } from '..';
import direction from '~/utils/direction';
import { useDirection } from '~/hooks/i18nHooks';
import { isAgent } from '~/utils/getDecodedJwt';

const CompanyTypeSelect: FC<SelectProps<string> & { hint?: boolean }> = (props) => {
  const { hint, ...rest } = props;
  const handbook = useHandbook();
  const dir = useDirection();

  const options = handbook.data?.handbooks
    ?.find((item) => item.key === 'companyType')
    ?.values?.filter((item) =>
      item.additionalParams?.onlyForAgent === undefined
        ? true
        : item.additionalParams.onlyForAgent === 'true' && isAgent()
    )
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ?.map((item) => ({ label: item.localizedValue, value: item.apiValue!, key: item.key }));

  return (
    <Container dir={dir}>
      <Select
        loading={handbook.isLoading}
        disabled={handbook.isLoading}
        showSearch
        options={options}
        {...rest}
      />
      {hint && <Hint text={<HintContent />} />}
    </Container>
  );
};

const HintContent: FC = () => {
  const { t } = useTranslation();
  return (
    <div>
      <p>
        <Trans
          t={t}
          defaults="<strong>Marketplace</strong> – you operate a platform where multiple merchants come together to sell their own goods or services through one location."
        />
      </p>
      <p>
        <Trans
          t={t}
          defaults="<strong>Aggregator</strong> – your business allows 3rd party merchants to use the payment services which you have signed up for."
        />
      </p>
      <Trans
        t={t}
        defaults="<strong>Small and Medium Enterprise</strong> – you operate your own business selling your own goods or services."
      />
    </div>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;

  .Hint {
    ${direction.margin.left(16)};
  }
`;

export default CompanyTypeSelect;
