import React, { useContext, useState } from 'react';

interface ContextType {
  email?: string;
  agreed: boolean;

  setEmail: (email: string) => void;
  setAgreed: (agreed: boolean) => void;
}

export const Context = React.createContext<ContextType>({
  email: undefined,
  agreed: true,
  setEmail: () => {},
  setAgreed: () => {},
});

export const useCompanyTypeContext = () => useContext(Context);

interface State {
  email?: string;
  agreed: boolean;
}

export const ContextProvider = (props: any) => {
  const { children } = props;

  const setEmail = (email: string) => {
    setState({ ...state, email });
  };

  const setAgreed = (agreed: boolean) => {
    setState({ ...state, agreed });
  };

  const [state, setState] = useState<State>({ agreed: true });

  return (
    <Context.Provider
      value={{
        ...state,
        setEmail,
        setAgreed,
      }}
    >
      {children}
    </Context.Provider>
  );
};
