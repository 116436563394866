import { isEmpty } from 'lodash';
import axios from './axios';
import { PricingDto } from '~/types/PricingDto';
import { Services } from '~/types/Services';
import { ServicesTotalPriceRequestBody } from '~/types/ServicesTotalPriceType';
import { CustomPricing, ProductMap } from '~/types/CustomPricing';

// import services from './mock/services.json';

export const getServices = () => {
  // return Promise.resolve<Services>(services as any as Services);
  return axios.get<Services>('/stages/fillApplication/services').then((res) => res.data);
};

export const calculateTotalPrice = (body: ServicesTotalPriceRequestBody): Promise<PricingDto> => {
  if (isEmpty(body.selectedProducts)) {
    return Promise.resolve({});
  }
  return axios
    .post<PricingDto>('/stages/fillApplication/services/totalPrice', body)
    .then((res) => res.data);
};

export const applyCustomPricing = (body: {
  productMap: ProductMap;
  additionalConfigurations: CustomPricing;
}) => {
  return axios.post<Services>('/stages/fillApplication/pricing', body).then((res) => res.data);
};

export const requestCustomPricing = (message: string) => {
  return axios
    .post<void>('/stages/fillApplication/pricing/request', { message })
    .then((res) => res.data);
};
