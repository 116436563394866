import React, { FC } from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  ApplicationStatusSelect,
  EmirateSelect,
  Form,
  LeadStatusSelect,
  AssigneeSelect,
} from '~/components';
import { usePath, useAgentMenuItem } from '~/hooks';
import { isTeamLead } from '~/utils/getDecodedJwt';
import CreatedDataFromItem from '~/components/inputs/CreatedDataFromItem';
import CreatedDataToItem from '~/components/inputs/CreatedDataToItem';
import DashboardStatusInput from '~/components/inputs/DashboardStatusInput';
import { DashboardStatus } from '~/types/AgentMapItem';

const AgentFilters: FC<any> = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation('agent');
  const { changePath, params } = usePath();
  const currentMenuItem = useAgentMenuItem();

  const handleChange = (changed: any, values: Record<string, any>) => {
    changePath({ filter: values });
  };

  return (
    <StyledForm
      form={form}
      // layout="inline"
      onValuesChange={handleChange}
      initialValues={params.filter}
      size="middle"
    >
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item name="dashboardStatus" initialValue={DashboardStatus.ALL}>
            <DashboardStatusInput />
          </Form.Item>
        </Col>

        <Col>
          <Form.Item label={t('Status')} name="filterStatus">
            {currentMenuItem === 'leads' ? (
              <LeadStatusSelect allowClear />
            ) : currentMenuItem === 'applications' ? (
              <ApplicationStatusSelect allowClear />
            ) : undefined}
          </Form.Item>
        </Col>

        <Col>
          <Form.Item label={t('Emirate')} name="filterEmirate">
            <EmirateSelect allowClear />
          </Form.Item>
        </Col>

        <Col>
          <CreatedDataFromItem
            name="filterCreatedDateFrom"
            filterCreatedDateTo="filterCreatedDateTo"
            label={t('Created date from')}
          />
        </Col>

        <Col>
          <CreatedDataToItem
            filterCreatedDateFrom="filterCreatedDateFrom"
            name="filterCreatedDateTo"
            label={t('Created date to')}
          />
        </Col>

        {isTeamLead() && (
          <Col>
            <Form.Item label={t('Assignee')} name="filterAssignee">
              <AssigneeSelect allowClear />
            </Form.Item>
          </Col>
        )}
      </Row>
    </StyledForm>
  );
};

const StyledForm = styled(Form)`
  margin-top: 24px;
  .ant-form-item {
    min-width: 230px;
  }
`;

export default AgentFilters;
