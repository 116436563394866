import { Input } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import Form from '~/components/Form';
import theme from '~/constants/theme';
import { ServiceField } from '~/types/Services';
import { NameFn } from '~/utils/buildNamePath';
import RefundCategorySelect from './RefundCategorySelect';
import SettlementFrequencyConfig from './SettlementFrequencyConfig';
import TitleWithCollapse from './TitleWithCollapse';
import { useConfiguration } from '~/controllers/pricing';
import FieldResolver from './FieldResolver';

interface Props {
  n: NameFn;
  fields: ServiceField[];
}

const EcomConfiguration: FC<Props> = (props) => {
  const { n, fields } = props;
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.sm });
  const { t } = useTranslation('servicesConfig');
  const config = useConfiguration(fields);

  return (
    <TitleWithCollapse withCollapse={isTablet} header={t('Configurations')}>
      <SettlementFrequencyConfig n={n} withCutOff fields={fields} />
      {config.renderField('refundCategory', ({ disabled, name }) => {
        return (
          <Form.Item name={n(name)} label={t('Refund category')}>
            <RefundCategorySelect disabled={disabled} />
          </Form.Item>
        );
      })}
      {config.renderField('paymentTypeAccepted', ({ disabled, name }) => {
        return (
          <Form.Item name={n(name)} label={t('Payment type accepted')} rules={[{ required: true }]}>
            <Input disabled={disabled} />
          </Form.Item>
        );
      })}
      {config.renderField('amountCap', ({ field }) => {
        return <FieldResolver n={n} field={field} fieldMap={{}} />;
      })}
    </TitleWithCollapse>
  );
};

export default EcomConfiguration;
