import { Input, InputProps } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';
import { useDirection } from '~/hooks/i18nHooks';

const LtrInput: FC<InputProps> = (props) => {
  const dir = useDirection();
  return <StyledInput dir={dir} {...props} />;
};

const StyledInput = styled(Input)`
  &[dir='rtl'] {
    direction: ltr;
    text-align: right;
  }
`;

export default LtrInput;
