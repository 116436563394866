import { auth } from '~/api/auth';
import paths from '~/pages/paths';

export const goToApplication = (history: any, applicationId: string) => {
  auth.setAgentApplicationId(applicationId);
  history.push(paths.home._);
};

export const openNewApplicationTab = (applicationId: string) => {
  window.open(`${paths.redirect.application}/${applicationId}`, '_blank')?.focus();
};
