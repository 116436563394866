import { RuleObject } from 'antd/lib/form';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { basicValidation } from './basicValidation';
import i18n from '~/i18n';

export type Validator = RuleObject['validator'];

const phoneValidation: Validator = basicValidation((rule, value, callback) => {
  try {
    const phoneNumber = parsePhoneNumberFromString(value);
    if (!phoneNumber?.isPossible) {
      throw new Error(i18n.t('Impossible number', { ns: 'validation' }));
    }
    if (!phoneNumber?.isValid()) {
      throw new Error(i18n.t('Invalid number', { ns: 'validation' }));
    }
    callback();
  } catch (err) {
    console.log((err as any).message as string);
    callback((err as any).message as string);
  }
});

export default phoneValidation;
