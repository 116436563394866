import React from 'react';
import { Form as AntdForm } from 'antd';
import { FormInstance, FormProps } from 'antd/lib/form';
import { isEmpty } from 'lodash';
import styled from 'styled-components';

import FormItem from './FormItem';
import theme from '~/constants/theme';
import ShouldUpdate from './ShouldUpdate';
import Visible from './Visible';

const StyledForm = styled(AntdForm)`
  .ant-form-item {
    margin-bottom: ${theme.formVerticalSpacing}px;
  }
`;

const Form = (props: FormProps): any => {
  const { form, onValuesChange, ...rest } = props;

  const resetAllErrors = (formInstance: FormInstance) => {
    const fieldsWithErrors = formInstance.getFieldsError().filter((item) => !isEmpty(item.errors));
    formInstance.setFields(fieldsWithErrors.map((item) => ({ name: item.name, errors: [] })));
  };

  const handleValuesChange = (changedValues: any, values: any) => {
    if (typeof onValuesChange === 'function') {
      onValuesChange(changedValues, values);
    }
    if (form) {
      resetAllErrors(form);
    }
  };

  return (
    <StyledForm
      form={form}
      validateTrigger="onBlur"
      onValuesChange={handleValuesChange}
      scrollToFirstError={scrollToFirstError}
      {...rest}
    />
  );
};

export const scrollToFirstError = { behavior: 'smooth', block: 'center' } as const;

const { List, useForm, Provider } = AntdForm;

Form.Item = FormItem;
Form.List = List;
Form.useForm = useForm;
Form.Provider = Provider;
Form.ShouldUpdate = ShouldUpdate;
Form.Visible = Visible;

export default Form;
