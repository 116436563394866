import { useMutation } from 'react-query';

import { calculateTotalPrice } from '~/api/pricing';
import { useReduxState } from '~/hooks';
import { Draft } from '~/types/Draft';
import { PricingDto } from '~/types/PricingDto';
import { Services } from '~/types/Services';

import { ServicesTotalPriceRequestBody } from '~/types/ServicesTotalPriceType';
import { useGetServices } from './pricing';

const totalPriceKey = ['services', 'totalPrice'];

export const useCalculateTotalPrice = () => {
  const [, setState] = useTotalPriceState();
  return useMutation(totalPriceKey, calculateTotalPrice, {
    onMutate() {
      setState((prev) => ({ ...prev, isLoading: true, isError: false }));
    },
    onSuccess(res) {
      setState((prev) => ({
        ...prev,
        isLoading: false,
        data: res,
        isError: false,
        requiresRecalculate: false,
      }));
    },
    onError() {
      setState((prev) => ({ ...prev, isLoading: false, data: undefined, isError: true }));
    },
  });
};

export const useTotalPriceState = () => {
  return useReduxState<{
    data: PricingDto | undefined;
    isLoading: boolean;
    isError: boolean;
    requiresRecalculate: boolean;
  }>('totalPrice', {
    data: undefined,
    isLoading: false,
    isError: false,
    requiresRecalculate: false,
  });
};

export const useTotalPriceController = () => {
  const calculateTotalPriceMutation = useCalculateTotalPrice();
  const servicesQuery = useGetServices();
  const mutate = (formValues: Draft<'services'>) => {
    if (!servicesQuery.data) return null;
    return calculateTotalPriceMutation.mutate(
      getSelectedProducts(formValues, servicesQuery.data.services)
    );
  };
  const mutateAsync = (formValues: Draft<'services'>) => {
    if (!servicesQuery.data) return null;
    return calculateTotalPriceMutation.mutateAsync(
      getSelectedProducts(formValues, servicesQuery.data.services)
    );
  };

  return { mutate, mutateAsync };
};

const getSelectedProducts = (
  data: Draft<'services'> | undefined,
  services: Services['services']
): ServicesTotalPriceRequestBody => {
  const result: ServicesTotalPriceRequestBody = {
    selectedProducts: {},
  };
  if (data?.services?.nGeniusPos?.enabled && services.find((item) => item.key === 'nGeniusPos')) {
    result.selectedProducts.nGeniusPos = [];
    const numberOfDevices = data.services.nGeniusPos.terminalFee;
    if (numberOfDevices) {
      const params = getRequiresNumberParams('nGeniusPos', services, numberOfDevices);
      result.selectedProducts.nGeniusPos.push(...params);
    }
  }
  if (data?.services?.eCommerce?.enabled && services.find((item) => item.key === 'eCommerce')) {
    result.selectedProducts.eCommerce = [];
    const numberOfDevices = 1;
    const params = getRequiresNumberParams('eCommerce', services, numberOfDevices);
    result.selectedProducts.eCommerce.push(...params);
  }
  if (data?.services?.nGeniusGo?.enabled && services.find((item) => item.key === 'nGeniusGo')) {
    result.selectedProducts.nGeniusGo = [];
    const numberOfDevices = data.services.nGeniusGo.terminalFee;
    if (numberOfDevices) {
      const params = getRequiresNumberParams('nGeniusGo', services, numberOfDevices);
      result.selectedProducts.nGeniusGo.push(...params);
    }
  }
  if (data?.services?.myService && services.find((item) => item.key === 'myService')) {
    result.selectedProducts.myService = [];
    const numberOfDevices = data.services.myService.terminalFee;
    if (numberOfDevices) {
      const params = getRequiresNumberParams('myService', services, numberOfDevices);
      result.selectedProducts.myService.push(...params);
    }
  }
  return result;
};

const getRequiresNumberParams = (
  serviceName: string,
  services: Services['services'],
  deviceNumber: number
) => {
  const fieldNamesWithRequiresNumber = services
    .find((item) => item.key === serviceName)
    ?.fields?.filter((field) => ('requiresNumber' in field ? field.requiresNumber : false))
    ?.map((field) => field.key);

  if (!fieldNamesWithRequiresNumber) return [];

  return fieldNamesWithRequiresNumber?.map((fieldName) => ({
    key: fieldName,
    input: deviceNumber,
  }));
};
