export default {
  Contract: 'اتفافية',
  'To generate the contract please press the button below.':
    'لإنشاء العقد يرجى الضغط على الزر أدناه.',
  'Generate contract': 'توليد العقد',
  'Review the generated document and upload the signed contract photos in the area below.':
    'راجع المستند الذي تم إنشاؤه وقم بتحميل صور العقد الموقعة في المنطقة أدناه.',
  'Download contract': 'تحميل العقد',
  'Sign and submit': 'تسجيل وإرسال',
  'Authorised signatory': 'المفوض بالتوقيع',
  'Choose another person': 'اختر شخصًا آخر',
  'Who will sign the contract?': 'من سيوقع العقد؟',
  'Select authorised signatory': 'حدد المفوض بالتوقيع',
  'We will send the invitation link to log in to the Portal.':
    'سنرسل رابط الدعوة لتسجيل الدخول إلى البوابة.',
  'There is no contract data': 'لا توجد بيانات العقد',
};
