import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Input, Spin, Typography } from 'antd';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import LeadAdd from '~/components/views/agent/LeadAdd';
import colors from '~/constants/colors';
import { usePath } from '~/hooks';
import direction from '~/utils/direction';
import { useDirection } from '~/hooks/i18nHooks';
import { useIsMobile, useIsTablet } from '~/hooks/useScreenSizes';
import theme from '~/constants/theme';

export interface PageHeaderProps {
  heading: string;
  isFetching?: boolean;
}

const PageHeader: FC<PageHeaderProps> = (props) => {
  const { heading = '???', isFetching, children } = props;
  const { t } = useTranslation('agent');
  const dir = useDirection();
  const {
    changePath,
    params: { filter },
  } = usePath();

  const searchPlaceholder = t('searchPlaceholder');
  const queryParam = 'filterCompanyOrAddress';
  const onSearch = (name: string) => changePath({ filter: { [queryParam]: name } });
  const [state, setState] = useState<string>((filter && filter[queryParam]) || '');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setState(text);
    debouncedHandleSearch(text);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSearch = useCallback(
    debounce((text: string) => {
      if (onSearch) onSearch(text);
    }, 500),
    [onSearch]
  );

  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  return (
    <PageHeaderContainer className="PageHeader" dir={dir}>
      <div className="PageHeader-TopFiltersWrapper">
        <div className="PageHeader-Heading">
          <Typography.Title level={3}>{heading}</Typography.Title>
          {isFetching ? <FetchingIndicator /> : <div style={{ width: 30 }} />}
        </div>
        <div className="PageHeader-Search">
          {!isMobile && <LeadAdd />}
          <Input.Search
            id="PageHeader_SearchInput"
            className="PageHeader-SearchInput"
            size="middle"
            value={state}
            placeholder={searchPlaceholder}
            onChange={handleChange}
            allowClear
          />
          {isTablet && !isMobile && children}
        </div>
      </div>
      {!isTablet && children}
      {isMobile && (
        <div className="PageHeader-MobileSubRow">
          <LeadAdd />
          {children}
        </div>
      )}
    </PageHeaderContainer>
  );
};

const FetchingIndicator = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;
  return <Spin indicator={antIcon} />;
};

const PageHeaderContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 24px 0;

  .PageHeader-TopFiltersWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media (max-width: ${theme.breakpoints.sm}px) {
      flex-direction: column;
    }

    .PageHeader-Heading {
      font-size: 28px;
      display: flex;
      align-items: baseline;

      ${direction.margin.right('2vw')}
      .ant-typography {
        margin: 0;
        color: ${colors.blue[1100]};
      }

      .ant-spin-spinning {
        ${direction.margin.left(12)}
      }
    }

    .PageHeader-Search {
      display: flex;
      flex: 1;
      justify-content: flex-end;

      .PageHeader-SearchInput {
        max-width: 350px;
        @media (max-width: ${theme.breakpoints.sm}px) {
          max-width: 100%;
          margin: 24px 0;
        }

        input {
          font-size: 12px;
        }

        .ant-input-affix-wrapper:hover,
        button:hover {
          border-color: ${colors.inputHoverBorderColor};
        }

        .ant-input-affix-wrapper::before {
          content: '.';
        }

        .ant-input-affix-wrapper {
          padding-top: 5px;
          border-radius: unset;
          ${direction.borderRadius.topLeft(theme.borderRadiusBase)};
          ${direction.borderRadius.bottomLeft(theme.borderRadiusBase)};

          @media (max-width: ${theme.breakpoints.md}px) {
            padding-top: 4px;
          }
        }

        .ant-input-affix-wrapper-focused,
        .ant-input-affix-wrapper:focus,
        button:focus {
          border-color: ${colors.inputHoverBorderColor};
          box-shadow: 0 0 0 2px ${colors.inputHoverBorderColor};
        }

        .ant-input-group-addon {
          left: unset;
          ${direction.left(-1)};
          background-color: transparent;
        }

        .ant-input-group-addon button {
          border-radius: unset;
          ${direction.borderRadius.topRight(theme.borderRadiusBase)};
          ${direction.borderRadius.bottomRight(theme.borderRadiusBase)};
        }
      }
    }

    .PageHeader-Search > *:not(:last-child) {
      ${direction.margin.right(20)}
    }
  }

  .PageHeader-MobileSubRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export default PageHeader;
