import React, { FC } from 'react';
import styled from 'styled-components';

import { Button } from '~/components';
import { FormItemInjectedProps } from '~/types/FormItemInjectedProps';

interface Props extends Partial<FormItemInjectedProps> {
  type: 'increase' | 'decrease';
  min?: number;
  max?: number;
  disabled?: boolean;
}

const IncreaseDecreaseButton: FC<Props> = (props) => {
  const { type, disabled, min, max } = props;
  const { value = 0, onChange } = props as FormItemInjectedProps<number | undefined>;
  const handleChange = () => {
    if (type === 'increase') {
      onChange?.(value + 1);
    }
    if (type === 'decrease') {
      onChange?.(value - 1);
    }
  };

  const resultDisabled = (() => {
    if (type === 'increase') {
      if (max && value >= max) return true;
    }
    if (type === 'decrease') {
      if (min && value <= min) return true;
    }
    return disabled;
  })();

  return (
    <Container>
      <Button
        id={`numberOfDevices_${type}`}
        size="middle"
        type="text"
        onClick={handleChange}
        disabled={resultDisabled}
      >
        {type === 'increase' ? '+' : type === 'decrease' ? '-' : null}
      </Button>
    </Container>
  );
};

const CONTROL_HEIGHT = 32;
const CONTROL_BORDER_RADIUS = 4;

const Container = styled.div`
  height: ${CONTROL_HEIGHT}px;
  width: ${CONTROL_HEIGHT}px;
  button {
    padding: 0;
    line-height: 1;
    font-weight: 600;
    height: ${CONTROL_HEIGHT}px;
    width: ${CONTROL_HEIGHT}px;
    background-color: #f8f8f8;
    border-radius: ${CONTROL_BORDER_RADIUS}px;
  }
  .ant-btn-text[disabled] {
    background-color: #f8f8f8;
  }
  .ant-btn:not([disabled]):hover {
    background-color: #f1f1f1;
  }
`;

export default IncreaseDecreaseButton;
