import React, { FC } from 'react';
import { Typography } from 'antd';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import WizardContent from '~/layouts/WizardContent';
import { useGetApplication } from '~/controllers/wizard';
import LeftLayout from '~/layouts/LeftLayout';
import BottomLeftActions from '~/layouts/BottomLeftActions';
import features from '~/constants/features';

const UnknownStage: FC = () => {
  const applicationQuery = useGetApplication();

  return (
    <WizardContent
      left={
        <LeftLayout>
          <BottomLeftActions contactUsUrl={features.contactUsLinkError} />
        </LeftLayout>
      }
      content={
        <Container className="UnknownStage">
          <Typography.Title>
            <Trans>This is an unknown stage</Trans>
          </Typography.Title>
          <Typography.Paragraph>
            {`There is no page for stage "${applicationQuery.data?.stage || 'unknown'}"`}
          </Typography.Paragraph>
        </Container>
      }
      right={null}
    />
  );
};

const Container = styled.div`
  //
`;

export default UnknownStage;
