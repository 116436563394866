import { FC } from 'react';
import { useHistory } from 'react-router';
import { useGetApplication } from '~/controllers/wizard';
import { getActiveStepPath } from '~/utils/getActiveStepPath';
import paths from '~/pages/paths';
import { getShouldGoToDashboard } from '~/utils/getShouldGoToDashboard';

const HomeScreen: FC<void> = () => {
  const history = useHistory();
  const shouldGoToDashboard = getShouldGoToDashboard();

  useGetApplication({
    onSuccess(data) {
      history.push(getActiveStepPath(data));
    },
    staleTime: undefined,
    enabled: !shouldGoToDashboard,
  });
  if (shouldGoToDashboard) history.push(paths.agent._);

  return null;
};

export default HomeScreen;
