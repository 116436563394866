/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, useEffect, useState } from 'react';
import useUrlState from '@ahooksjs/use-url-state';
import { useHistory } from 'react-router';
import { Spin } from 'antd';
import styled from 'styled-components';

import { auth, getToken, getTokenSpec } from '~/api/auth';
import { useMutableCallback } from '~/hooks';
import paths from '~/pages/paths';
import features from '~/constants/features';

export interface AuthProps {
  flowKey: string;
  pathOnSuccess?: string;
}

const AuthCallback: FC<AuthProps> = (props) => {
  const { flowKey, pathOnSuccess = paths.home._ } = props;
  const [urlState] = useUrlState<{ code: string; session_state: string }>();
  const { code, session_state } = urlState;
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);

  const onSuccess = useMutableCallback(() => {
    history.push(pathOnSuccess);
  });
  const onError = useMutableCallback(() => {
    history.replace(paths.home._);
  });

  useEffect(() => {
    void (async () => {
      setLoading(true);
      try {
        auth.clearSession(); // clear session before getting new token
        const { url, clientId, redirectUrl, logoutUrl } = await getTokenSpec(flowKey);
        const { access_token, refresh_token } = await getToken({
          url,
          data: {
            grant_type: 'authorization_code',
            client_id: clientId,
            redirect_uri: redirectUrl,
            session_state,
            code,
          },
        });
        auth.setSession(access_token, refresh_token, clientId, features.realm, logoutUrl);
        onSuccess();
      } catch (error) {
        onError();
      }
      setLoading(false);
    })();
  }, [code, session_state, onSuccess, flowKey, onError]);

  return <Container>{isLoading ? <Spin size="large" /> : null}</Container>;
};

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export default AuthCallback;
