import React, { FC } from 'react';
import { SelectProps } from 'antd';
import styled from 'styled-components';
import Select from '~/components/inputs/Select';

const RefundCategorySelect: FC<SelectProps<any>> = (props) => {
  return (
    <StyledSelect
      options={[
        {
          label:
            'C - Debit ‘Gross refund amount’ and charge additional ‘Refund fee’ to the merchants',
          value: 'C',
        },
        { label: 'G - Debit ‘Gross amount’ to the Merchant (Without MSF)', value: 'G' },
        {
          label: 'N - Debit ‘Net refund amount’ (Gross refund amount – MSF) as BAU',
          value: 'N',
        },
        {
          label:
            "R - Debit 'Net refund amount' (Gross refund amount – MSF) and charge ‘Refund fee’ to the merchants",
          value: 'R',
        },
        {
          label: 'Z - Charge MSF on Refunds',
          value: 'Z',
        },
      ]}
      {...props}
    />
  );
};

const StyledSelect = styled(Select)`
  .ant-select-selection-item {
    width: 0;
  }
`;

export default RefundCategorySelect;
