import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Typography } from 'antd';
import FiltersButton from '~/components/FiltersButton';
import { useAgentMenuItem, usePath } from '~/hooks';
import {
  ApplicationStatusSelect,
  AssigneeSelect,
  Button,
  EmirateSelect,
  Form,
  LeadStatusSelect,
  SortingSelect,
} from '~/components';
import { DashboardStatus } from '~/types/AgentMapItem';
import DashboardStatusInput from '~/components/inputs/DashboardStatusInput';
import CreatedDataFromItem from '~/components/inputs/CreatedDataFromItem';
import CreatedDataToItem from '~/components/inputs/CreatedDataToItem';
import { isTeamLead } from '~/utils/getDecodedJwt';

import Drawer from '~/components/Drawer';

const AgentFiltersButton = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <FiltersButton onClick={handleOpen} />
      <AgentFiltersDrawer onClose={handleClose} visible={open} />
    </>
  );
};

interface AgentFiltersDrawerProps {
  visible: boolean;
  onClose: () => void;
}

const AgentFiltersDrawer: FC<AgentFiltersDrawerProps> = (props) => {
  const { visible, onClose } = props;
  const { t } = useTranslation('agent');
  const currentMenuItem = useAgentMenuItem();
  const [form] = Form.useForm<any>();
  const { changePath, params } = usePath();

  const handleResult = (values: Record<string, any>) => {
    const { sorting, ...filter } = values;
    const sortBy = sorting?.split('-');
    changePath({ filter: { ...filter, ...{ sortField: sortBy?.[0], sortOrder: sortBy?.[1] } } });
  };
  const sortField = params?.filter?.sortField;
  const sortOrder = params?.filter?.sortOrder;
  const sortingInitialValue = sortField && sortOrder ? `${sortField}-${sortOrder}` : undefined;

  return (
    <StyledDrawer
      visible={visible}
      onClose={() => {
        form.resetFields();
        onClose();
      }}
      closable
      maskClosable={false}
    >
      <Typography.Title level={1}>{t('Filter')}</Typography.Title>
      <Form
        form={form}
        onFinish={(data) => {
          handleResult(data);
          onClose();
        }}
        size="middle"
        initialValues={params.filter}
      >
        <Form.Item name="dashboardStatus" initialValue={DashboardStatus.ALL}>
          <DashboardStatusInput />
        </Form.Item>

        <Form.Item label={t('Status')} name="filterStatus">
          {currentMenuItem === 'leads' ? (
            <LeadStatusSelect allowClear />
          ) : currentMenuItem === 'applications' ? (
            <ApplicationStatusSelect allowClear />
          ) : undefined}
        </Form.Item>

        <Form.Item label={t('Emirate')} name="filterEmirate">
          <EmirateSelect allowClear />
        </Form.Item>

        <CreatedDataFromItem
          name="filterCreatedDateFrom"
          filterCreatedDateTo="filterCreatedDateTo"
          label={t('Created date from')}
        />

        <CreatedDataToItem
          filterCreatedDateFrom="filterCreatedDateFrom"
          name="filterCreatedDateTo"
          label={t('Created date to')}
        />
        {isTeamLead() && (
          <Form.Item label={t('Assignee')} name="filterAssignee">
            <AssigneeSelect allowClear />
          </Form.Item>
        )}
        <Form.Item label={t('Sort by')} name="sorting" initialValue={sortingInitialValue}>
          <SortingSelect type={currentMenuItem} isTeamLead={isTeamLead()} allowClear />
        </Form.Item>
        <Button
          id="saveFilters"
          htmlType="submit"
          className="filterDrawer-SaveButton"
          type="primary"
          disabled={!(() => form.isFieldsTouched())}
          // loading={terminateLead.isLoading}
        >
          {t('Save', { ns: 'common' })}
        </Button>
      </Form>
    </StyledDrawer>
  );
};

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    position: relative;

    .filterDrawer-SaveButton {
      width: 100%;
    }
  }
`;

export default AgentFiltersButton;
