import styled from 'styled-components';

import colors from '~/constants/colors';
import theme from '~/constants/theme';

const CardMobile = styled.div`
  display: flex;
  border: 1px solid ${colors.borderColorBase};
  border-radius: ${theme.borderRadiusBase}px;
  padding: 8px;
  width: 100%;
  .ant-row {
    flex: 1;
  }
`;

export default CardMobile;
