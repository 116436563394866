import React, { FC } from 'react';
import produce from 'immer';
import { merge } from 'lodash';
import { useTranslation } from 'react-i18next';

import FormDrawer from '~/components/FormDrawer';
import { ApplicationPayload } from '~/types/ApplicationPayload';

import buildNamePath from '~/utils/buildNamePath';
import IndividualForm, { formName } from './IndividualForm';
import { shareholdersKey } from '.';
import { FormItemInjectedProps } from '~/types/FormItemInjectedProps';
import { Shareholders } from '~/types/Shareholders';

interface Props extends Partial<FormItemInjectedProps<Shareholders>> {
  uid: string;
  onClose: () => void;
}

const IndividualEdit: FC<Props> = (props) => {
  const { uid, onClose } = props;
  const { value, onChange } = props as FormItemInjectedProps<Shareholders>;
  const shareholdersFormState = { shareholders: value };

  const { t } = useTranslation('fillApplication');

  const currentIndex =
    shareholdersFormState.shareholders?.individuals?.findIndex((item) => item?.data?.uid === uid) ||
    0;

  const onFinish = (newValues: ApplicationPayload) => {
    const updatedData = produce(shareholdersFormState, (draft) => {
      merge(draft, newValues);
    });
    onChange?.(updatedData.shareholders);
    onClose();
  };

  return (
    <FormDrawer
      id="individualEditDrawer"
      visible={!!uid}
      onClose={onClose}
      title={t('Edit Individual shareholder')}
      formName={formName}
    >
      <IndividualForm
        onFinish={onFinish}
        n={buildNamePath([shareholdersKey, 'individuals', currentIndex])}
        initialValues={shareholdersFormState}
      />
    </FormDrawer>
  );
};

export default IndividualEdit;
