import { useDebouncedCallback } from 'use-debounce';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import { useGetApplication } from '~/controllers/wizard';
import optionalFieldsFns from '~/utils/optionalFields';
import { getStagePayload } from '~/utils/getStagePayload';

interface Props {
  getOptionalFields?: (payload: any) => Record<string, string>;
  validationFnName?: string;
  initialValues?: any;
}

export const useOptionalFields = (props?: Props) => {
  const { getOptionalFields, validationFnName, initialValues } = props || {};
  const [optionalFields, setOptionalFields] = useState<Record<string, string> | null>(null);

  const applicationQuery = useGetApplication();
  const stagePayload = getStagePayload.wizard(applicationQuery.data);

  const resultScope = validationFnName || stagePayload?.activeStep.view || '';
  const resultGetOptionalFields = getOptionalFields || (optionalFieldsFns as any)[resultScope];

  const update = (formValues: any) => {
    if (!resultGetOptionalFields) return;
    const applicationPayload = { ...(applicationQuery.data?.payload || {}), ...(formValues || {}) };
    const fields = resultGetOptionalFields(applicationPayload) || {};
    setOptionalFields(fields);
  };
  const debouncedUpdate = useDebouncedCallback(update, 500);

  useEffect(() => {
    if (optionalFields === null && initialValues && !isEmpty(initialValues)) {
      update(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  return { optionalFields, update, debouncedUpdate };
};
