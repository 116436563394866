import React, { FC, useState } from 'react';
import { Form, Modal, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { Select } from '~/components';
import { useGetApplication } from '~/controllers/wizard';
import longdash from '~/constants/longdash';

interface Props {
  visible: boolean;
  onClose: (selection: number) => void;
}

const SelectAuthorizedRepresentativeModal: FC<Props> = (props) => {
  const { visible, onClose } = props;
  const { t } = useTranslation('fillApplication');
  const applicationQuery = useGetApplication();

  const authorizedRepresentatives = applicationQuery.data?.payload.authorizedRepresentative;
  const options = authorizedRepresentatives?.map((item, index) => ({
    label: `${item.firstName} ${item.lastName} (${item.email || 'no email'}) ${longdash} ${
      item.proof?.designation
    }`,
    value: index,
  }));

  const [selection, setSelection] = useState<any>(0);

  const onOk = () => {
    if (selection !== undefined) {
      onClose(selection);
    }
  };

  return (
    <Modal
      visible={visible}
      onOk={onOk}
      cancelButtonProps={{ style: { display: 'none' } }}
      okText={t('Confirm', { ns: 'common' })}
      title={t('Select authorized representative')}
      centered
    >
      <Form initialValues={{ index: 0 }}>
        <Form.Item name={['index']}>
          <Select
            options={options}
            loading={applicationQuery.isLoading}
            value={selection}
            onChange={(value) => {
              setSelection(value);
            }}
          />
        </Form.Item>
      </Form>

      <Typography.Paragraph>
        {t(
          "Selected authorized representative's data will be replaced with your UAE PASS profile data."
        )}
      </Typography.Paragraph>
    </Modal>
  );
};

export default SelectAuthorizedRepresentativeModal;
