import { message as showMessage } from 'antd';
import { ArgsProps } from 'antd/lib/message';
import { getI18n } from 'react-i18next';

import { auth } from '~/api/auth';
import createUid from '~/utils/createUid';
import { ApiError } from '~/types/ApiError';
import { history, queryClient } from '..';
import paths from '~/pages/paths';

const defaultDuration = 5;

const closableErrorMessage = (type: ArgsProps['type'], config: Omit<ArgsProps, 'type'>) => {
  const uid = createUid();
  return showMessage.open({
    type,
    key: uid,
    onClick(e) {
      showMessage.destroy(uid);
      config?.onClick?.(e);
    },
    style: { cursor: 'pointer', ...(config.style || {}) },
    ...config,
  });
};

export const showErrorMessage = (content = 'Error', duration = defaultDuration) => {
  void closableErrorMessage('error', { content, duration });
};

export const showWarningMessage = (content = 'Warning', duration = defaultDuration) => {
  void closableErrorMessage('warning', { content, duration });
};

export const onApiError = (error: ApiError) => {
  const status = error.response?.status;
  const code = error.response?.data?.code;
  const message = error.response?.data?.description;

  const networkError = error.message === 'Network Error' || (!error?.response && !!error?.request);

  const t = (text: string) => getI18n().t(text, { ns: 'message' });

  switch (code) {
    case 'EMPTY_SUBMIT_REQUEST': {
      void showWarningMessage(t('Step data must be changed before pressing "Continue" button'), 20);
      break;
    }
    case 'NO_COMPLETED_ORDERS': {
      void showWarningMessage(
        t(
          "Before application submit, please ensure a payment link is sent to merchant and payment is received. To get the latest payment status please update the page. Once payment is successful you will see payment reference in the 'Pay-by-link' section."
        ),
        20
      );
      break;
    }
    case 'BAD_STEP_DATA': {
      if (message === 'Inappropriate data for current step') {
        void showWarningMessage(
          t('Application has been updated. Please refresh the page to see changes.'),
          20
        );
      }
      break;
    }
    case 'RENTAL_AMOUNT_CHANGE_NOT_ALLOWED': {
      if (message === 'Rental amount can not be changed at this stage') {
        void showErrorMessage(t('Rental amount can not be changed at this stage'), 20);
      }
      break;
    }
    case 'INVALID_APPLICATION_ID': {
      auth.clearAgentApplicationId();
      history.push(paths.home._);
      break;
    }
    case 'NO_ACCESS': {
      history.push(paths.home._);
      break;
    }
    case 'TEAM_RESTRICTION_EXCEPTION': {
      void showErrorMessage(t('The application has already been assigned to another team'), 20);
      void queryClient.refetchQueries('leads');
      void queryClient.refetchQueries('applications');
      break;
    }
    default: {
      if (status === 401) {
        void showErrorMessage(t('Session has expired'), 20);
      } else if (networkError) {
        void showErrorMessage(t('Internet connection is lost. Please check your connection'), 20);
      } else {
        void showErrorMessage(message as string);
      }
    }
  }
};
