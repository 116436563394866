import { useTranslation } from 'react-i18next';
/* eslint-disable @typescript-eslint/unbound-method */
import { useState, useMemo } from 'react';
import { RuleObject } from 'antd/lib/form';
import { useQuery } from 'react-query';
import lodash from 'lodash';
import * as libphonenumber from 'libphonenumber-js';
import dayjs from 'dayjs';
import ibanLib from 'iban';
import { useLocation } from 'react-router';

import { useMutableCallback, useReduxState } from '~/hooks';
import { useGetApplication } from '~/controllers/wizard';
import { getValidationJs } from '~/api/validation';
import validation from './valid';
import { getRoles } from '~/utils/getDecodedJwt';
import { ApplicationPayload } from '~/types/ApplicationPayload';
import { Roles } from '~/types/Roles';
import { Stage } from '~/types/Application';
import { useHandbook } from './handbook';

const LOCAL_VALIDATION = process.env.NODE_ENV === 'development';

const appendOrUpdateScript = (js: string) => {
  const scriptId = 'validation.js';
  const existingScript = document.getElementById(scriptId);
  if (existingScript) {
    existingScript.innerHTML = js;
  } else {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = scriptId;
    script.innerHTML = js;
    document.body.appendChild(script);
  }
};

interface WindowWithValidation extends Window {
  validation?: (dependencies: {
    libphonenumberLib: typeof libphonenumber;
    lodashLib: typeof lodash;
    temporalLib: typeof dayjs;
  }) => Record<
    | 'services'
    | 'companyInformation'
    | 'addresses'
    | 'multipleAuthorizedSignatory'
    | 'soleProprietorAuthorizedSignatory'
    | 'multipleShareholders'
    | 'additionalInformation'
    | 'bankAccount',
    (applicationPayload: ApplicationPayload, roles: Roles) => Record<string, string>
  >;
}

export const useGetValidationJs = () => {
  const query = useQuery('validationJs', getValidationJs, {
    onSuccess(res) {
      appendOrUpdateScript(res);
    },
    staleTime: Infinity,
  });

  const { t } = useTranslation('validation');

  const handbookQuery = useHandbook();

  const dependencies = {
    lodashLib: lodash,
    libphonenumberLib: libphonenumber,
    temporalLib: dayjs,
    ibanLib,
    t,
    handbook: handbookQuery.data?.handbooks,
  };

  const w = window as WindowWithValidation;
  const v = LOCAL_VALIDATION ? validation(dependencies) : w.validation?.(dependencies);
  const data = {
    services: v?.services,
    companyInformation: v?.companyInformation,
    addresses: v?.addresses,
    multipleAuthorizedSignatory: v?.multipleAuthorizedSignatory,
    soleProprietorAuthorizedSignatory: v?.soleProprietorAuthorizedSignatory,
    multipleShareholders: v?.multipleShareholders,
    additionalInformation: v?.additionalInformation,
    bankAccount: v?.bankAccount,
  } as Record<string, (args: any) => any>;

  return { ...query, data };
};

type FormState = Record<string, any> | undefined;

export const useValidator = (
  formStateOrGetState: FormState | (() => FormState),
  validationFuncName?: string
) => {
  const validationQuery = useGetValidationJs();
  const applicationQuery = useGetApplication();

  const handbookQuery = useHandbook();
  const handbook = handbookQuery.data?.handbooks;

  const validateFn:
    | ((applicationPayload: ApplicationPayload, roles: Roles) => Record<string, string>)
    | undefined = useMemo(() => {
    if (applicationQuery.data?.stage === Stage.fillApplication) {
      const validationResultFuncName =
        validationFuncName || applicationQuery.data?.stagePayload.activeStep.view;
      const validate: any = validationResultFuncName
        ? validationQuery.data[validationResultFuncName]
        : undefined;
      return validate
        ? (applicationPayload: ApplicationPayload, roles: Roles): Record<string, string> => {
            return validate(applicationPayload, roles, handbook);
          }
        : undefined;
    }
    return undefined;
  }, [applicationQuery.data, validationFuncName, validationQuery.data, handbook]);

  const [isError, setIsError] = useState(false);

  const validator: RuleObject['validator'] = (rule, value, callback) => {
    const noError = () => {
      callback();
      setIsError(false);
    };
    const hasError = (message: string) => {
      callback(message);
      setIsError(true);
    };
    if (!applicationQuery.data) {
      noError();
      return;
    }

    if (validateFn) {
      const fieldName: string = (rule as any).field;
      const formState: FormState =
        typeof formStateOrGetState === 'function' ? formStateOrGetState() : formStateOrGetState;

      const applicationPayload = { ...applicationQuery.data.payload, ...(formState || {}) };
      const validationErrors = validateFn(applicationPayload, getRoles());
      const currentFieldErrorMessage = validationErrors[fieldName];
      if (currentFieldErrorMessage) {
        hasError(currentFieldErrorMessage);
      } else {
        noError();
      }
    } else {
      console.warn(`no validation function found`);
      noError();
    }
  };

  const mutableValidator = useMutableCallback(validator);

  return {
    validator: mutableValidator,
    validateFn,
    isLoading: validationQuery.isLoading || applicationQuery.isLoading,
    isError,
  };
};

type FormErrorType = { errors: string[]; name: string[] };
type CommonErrorState = Record<string, FormErrorType[] | undefined>;
type HandlersType = {
  onError: (errors: FormErrorType[]) => void;
  clearError: () => void;
};

export const useCommonErrors = (formName?: string): [CommonErrorState, HandlersType] => {
  const [state, setState] = useReduxState<CommonErrorState>('commonFormErrors', {});
  const location = useLocation();
  const actualFormName = formName || location.pathname;

  const handlers: HandlersType = useMemo(() => {
    return {
      onError: (errors) => {
        setState((prev) => ({ ...prev, [actualFormName]: errors }));
      },
      clearError: () => {
        if (state[actualFormName]) {
          setState((prev) => ({ ...prev, [actualFormName]: undefined }));
        }
      },
    };
  }, [actualFormName, setState, state]);

  return [state, handlers];
};
