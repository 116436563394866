import React, { FC, useEffect, useState } from 'react';
import { Modal, Skeleton, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router';

import { Alert, Button, DocumentInput, Form, Space } from '~/components';
import { useGenerateContract, useUploadSignedContract } from '~/controllers/contract';
import { ContractGenerationIcon, WarningCircleIcon } from '~/img';
import WizardContent from '~/layouts/WizardContent';
import Section from '~/layouts/Section ';
import SaveContract from './SaveContract';
import { ImageResponse } from '~/types/files';
import WizardContinueButton from '~/layouts/WizardContinueButton';
import paths from '~/pages/paths';
import { useGetApplication } from '~/controllers/wizard';
import SendInvitationModal from './SendInvitationModal';
import longdash from '~/constants/longdash';
import LeftLayout from '~/layouts/LeftLayout';
import BottomLeftActions from '~/layouts/BottomLeftActions';
import features from '~/constants/features';
import ApplicationCommonErrors from '../ApplicationCommonErrors';
import { isReadOnly } from '~/utils/isReadOnly';
import { getSub, isLoggedInWithUaePass } from '~/utils/getDecodedJwt';
import UaePassButton from '~/components/UaePassButton';
import { fetchDocumentFromUaePass, signWithUaePass } from '~/api/contract';
import UaePassProgressModal from './UaePassProgressModal';
import SaveSignedContract from './SaveSignedContract';

const SignContract: FC = () => {
  const { t } = useTranslation('contract');
  const applicationQuery = useGetApplication();
  const generateContractMutation = useGenerateContract();
  const uploadContractMutation = useUploadSignedContract();
  const location = useLocation();
  const history = useHistory();

  const readOnly = isReadOnly(applicationQuery.data);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    void applicationQuery.refetch();
  };

  const authorizedSignatories =
    applicationQuery.data?.payload.authorizedRepresentative?.filter(
      (item) => item.isAuthorizedSignatory
    ) || [];
  const isMultipleSignatories = authorizedSignatories ? authorizedSignatories.length > 1 : null;

  const previouslySelectedAuthorizedSignatory = applicationQuery.data?.payload.authorizedSignatory;

  const selectedAuthorizedRepresentativeIndex = previouslySelectedAuthorizedSignatory
    ? (applicationQuery.data?.payload.authorizedRepresentative || []).findIndex(
        (ar) => ar.email === previouslySelectedAuthorizedSignatory.email
      )
    : !isMultipleSignatories && authorizedSignatories.length > 0
    ? (applicationQuery.data?.payload.authorizedRepresentative || []).findIndex(
        (ar) => ar.email === authorizedSignatories[0].email
      )
    : -1;

  const selectedAuthorizedRepresentative =
    selectedAuthorizedRepresentativeIndex >= 0 ||
    !applicationQuery.data?.payload.authorizedRepresentative
      ? undefined
      : applicationQuery.data?.payload.authorizedRepresentative[
          selectedAuthorizedRepresentativeIndex
        ];

  const selectedSignatoryUaePassSub =
    (selectedAuthorizedRepresentativeIndex >= 0 &&
      applicationQuery.data?.payload.uaepassDocumentSharing &&
      applicationQuery.data?.payload.uaepassDocumentSharing[
        selectedAuthorizedRepresentativeIndex === 0 ? '0' : '1'
      ]) ||
    undefined;
  console.log(
    `selectedAuthorizedRepresentativeIndex=${selectedAuthorizedRepresentativeIndex}, selectedSignatoryUaePassSub:`,
    selectedSignatoryUaePassSub
  );

  const [authorizedSignatoryEmail, setAuthorizedSignatoryEmail] = useState<string | undefined>(
    selectedAuthorizedRepresentative?.email
  );

  const resultingAuthorizedRepresentative =
    applicationQuery.data?.payload.authorizedRepresentative &&
    authorizedSignatoryEmail &&
    applicationQuery.data?.payload.authorizedRepresentative.find(
      (ar) => ar.email === authorizedSignatoryEmail
    );

  const handleGenerateClick = () => {
    if (isMultipleSignatories === null) return;
    if (isMultipleSignatories && !resultingAuthorizedRepresentative) {
      handleOpen();
    } else {
      generateContractMutation.mutate();
    }
  };

  const onFinish = async (values: { data: ImageResponse | ImageResponse[] }) => {
    console.log(`values.data:`, values.data);
    try {
      if (Array.isArray(values.data)) {
        await uploadContractMutation.mutateAsync(values.data);
      } else {
        await uploadContractMutation.mutateAsync([values.data]);
      }
      history.push(paths.home._);
    } catch (error) {
      //
    }
  };

  const signatorySection = (
    <>
      {resultingAuthorizedRepresentative && (
        <Section formItemLast>
          <Typography.Title level={5}>{t('Authorised signatory')}</Typography.Title>
          <Typography.Paragraph>
            {`${resultingAuthorizedRepresentative.firstName} ` +
              `${resultingAuthorizedRepresentative.lastName} ` +
              `(${resultingAuthorizedRepresentative.email}) ` +
              `${longdash} ` +
              `${resultingAuthorizedRepresentative.proof?.designation}`}
          </Typography.Paragraph>
          <Button
            id="signContract_choosePerson"
            onClick={handleOpen}
            type="default"
            size="large"
            disabled={readOnly}
          >
            {t('Choose another person')}
          </Button>
        </Section>
      )}
    </>
  );

  const isUaePass = isLoggedInWithUaePass();

  const [isSendingToUaePass, setSendingToUaePass] = useState(false);
  const [isUaePassError, setUaePassError] = useState(false);

  const isUaePassAllowed = isUaePass && selectedSignatoryUaePassSub === getSub();
  const isSigningForbidden = !isUaePass && selectedSignatoryUaePassSub !== undefined;

  useEffect(() => {
    if (isUaePassError) {
      Modal.error({
        content: (
          <div style={{ paddingLeft: 40 }}>
            {t(
              "Failed to fetch information from UAE PASS. Press on 'Close' button below to reload this page and try again."
            )}
          </div>
        ),
        onOk: () => {
          window.location.reload();
        },
        centered: true,
        okText: t('Close'),
      });
    }
  }, [isUaePassError, t]);

  const handleSignWithUaePassClick = () => {
    setSendingToUaePass(true);
    signWithUaePass()
      .then((response: any) => {
        console.log(response.redirectUrl);
        window.location.href = response.redirectUrl;
      })
      .catch(() => {
        setUaePassError(true);
      });
  };

  const uaePassSignatureProcessId = applicationQuery.data?.payload.uaePassSignatureProcess?.id;
  const isSignatureProcessInProgress = !!uaePassSignatureProcessId;
  const [uaePassDocument, setUaePassDocument] = useState();

  useEffect(() => {
    if (uaePassSignatureProcessId && setUaePassDocument) {
      fetchDocumentFromUaePass()
        .then((response) => {
          console.log(response);
          setUaePassDocument(response);
        })
        .catch(() => {
          setUaePassError(true);
        });
    }
  }, [uaePassSignatureProcessId]);

  const showContinue =
    uaePassDocument || (!isSignatureProcessInProgress && !isUaePassAllowed && !isSigningForbidden);

  return (
    <>
      <SendInvitationModal
        visible={open}
        onClose={handleClose}
        onInvitationSent={generateContractMutation.mutate}
        authorizedSignatoryEmail={authorizedSignatoryEmail}
        setAuthorizedSignatoryEmail={setAuthorizedSignatoryEmail}
      />
      <WizardContent
        content={
          <Container>
            <Typography.Title>{t('Contract')}</Typography.Title>
            {uaePassDocument ? (
              <Section formItemLast>
                <Typography.Paragraph>
                  {t('Great! You have successfull signed the contract.')}
                </Typography.Paragraph>
                <>
                  <Form
                    name={location.pathname}
                    onFinish={onFinish}
                    initialValues={{
                      data: uaePassDocument,
                    }}
                  >
                    <Form.Item name="data" rules={[{ required: true }]} hidden>
                      <DocumentInput name="photos" variant="default" disabled={readOnly} />
                    </Form.Item>
                  </Form>
                  <SaveSignedContract value={uaePassDocument} />
                </>
              </Section>
            ) : isSignatureProcessInProgress ? (
              <>
                <UaePassProgressModal visible={!isUaePassError} />
                <Skeleton />
              </>
            ) : !generateContractMutation.data ? (
              <Section formItemLast>
                <Typography.Paragraph>
                  {t('To generate the contract please press the button below.')}
                </Typography.Paragraph>
                {signatorySection}
                <Space direction="vertical" size={24}>
                  <Button
                    id="signContract_generateContract"
                    extraLarge
                    icon={<ContractGenerationIcon />}
                    onClick={handleGenerateClick}
                    loading={generateContractMutation.isLoading || applicationQuery.isLoading}
                    disabled={applicationQuery.isLoading || readOnly}
                  >
                    {t('Generate contract')}
                  </Button>
                </Space>
              </Section>
            ) : (
              <>
                <Section formItemLast>
                  <Typography.Paragraph className="SignContract-Subtitle">
                    {t(
                      'Review the generated document and upload the signed contract photos in the area below.'
                    )}
                  </Typography.Paragraph>
                  {signatorySection}
                  <Typography.Title level={5}>{t('Download contract')}</Typography.Title>
                  <SaveContract
                    name={generateContractMutation.data.name}
                    data={generateContractMutation.data.blob}
                  />
                </Section>
                <section>
                  <Typography.Title level={5}>{t('Sign and submit')}</Typography.Title>
                  {isUaePassAllowed ? (
                    <>
                      <Space direction="vertical" size={24}>
                        <UaePassButton
                          id="signContract_signWithUaePass"
                          onClick={handleSignWithUaePassClick}
                          title={t('Sign with UAE PASS')}
                        />
                      </Space>
                      {isSendingToUaePass && <UaePassProgressModal visible />}
                    </>
                  ) : isSigningForbidden ? (
                    <Alert
                      message={t('Not allowed to sign')}
                      description={t(
                        'Contract should be signed with UAE PASS! Login via UAE PASS to sign document.'
                      )}
                      type="warning"
                      icon={<WarningCircleIcon />}
                      showIcon
                    />
                  ) : (
                    <Form name={location.pathname} onFinish={onFinish}>
                      <Form.Item name="data" rules={[{ required: true }]}>
                        <DocumentInput name="photos" variant="default" disabled={readOnly} />
                      </Form.Item>
                    </Form>
                  )}
                </section>
              </>
            )}
          </Container>
        }
        left={
          <LeftLayout>
            <BottomLeftActions contactUsUrl={features.contactUsLinkContract} />
          </LeftLayout>
        }
        right={
          <Space direction="vertical" size={24}>
            <ApplicationCommonErrors />
            {showContinue && (
              <WizardContinueButton
                id="contract_continueButton"
                formName={location.pathname}
                loading={uploadContractMutation.isLoading}
              />
            )}
          </Space>
        }
      />
    </>
  );
};

const Container = styled.div`
  h1 {
    margin-bottom: 16px;
  }
  .SignContract-Subtitle {
    margin-bottom: 24px;
  }
`;

export default SignContract;
