import axios from '~/api/axios';
import { AgentApplication } from '~/types/AgentApplication';
import { AgentAssignee, AgentLead, AgentLeadPayload } from '~/types/AgentLead';
import { Application } from '~/types/Application';

export const getLeads = (params: any) => {
  const { page, ...rest } = params;
  return axios.get<AgentLead[]>('/agent/leads/query', {
    params: {
      ...rest,
      pageNumber: page,
    },
  });
};

export const getLeadById = (id: string) => {
  return axios.get<AgentLead>(`/agent/leads/${id}`).then((res) => res.data);
};

export const createLead = (body: any) => {
  return axios.post<AgentLead>('/agent/leads', body).then((res) => res.data);
};

export const getApplications = (params: any) => {
  const { page, ...rest } = params;
  return axios.get<AgentApplication[]>('/agent/applications/query', {
    params: {
      ...rest,
      pageNumber: page,
    },
  });
};

export const pinItem = (body: { type: 'APPLICATION' | 'LEAD'; id: string; pinned: boolean }) => {
  return axios.post<void>('/agent/pins', body).then((res) => res.data);
};

export const submitLead = (body: AgentLeadPayload) => {
  return axios.post<void>('/agent/leads/submitLead', body).then((res) => res.data);
};

export const createApplication = (body: { leadId: string }) => {
  return axios
    .post<Application>('/agent/leads/createApplication', undefined, { params: body })
    .then((res) => res.data);
};

export const terminateLead = (body: { id: string; terminationReason: string }) => {
  return axios.post<void>('/agent/leads/terminateLead', body).then((res) => res.data);
};

export const terminateApplication = (body: { id: string; terminationReason: string }) => {
  return axios.post<void>('/agent/applications/terminateApplication', body).then((res) => res.data);
};

export const activateLead = (body: { leadId: string }) => {
  return axios
    .post<Application>('/agent/leads/activateLead', undefined, { params: body })
    .then((res) => res.data);
};

export const getAgents = () => {
  return axios.get<AgentAssignee[]>(`/agent/`).then((res) => res.data);
};

export const assignLead = (body: { leadId: string; agentId: string }) => {
  return axios
    .post<void>('/agent/leads/assign', undefined, { params: body })
    .then((res) => res.data);
};

export const assignApplication = (body: { applicationId: string; agentId: string }) => {
  return axios
    .post<void>('/agent/applications/assign', undefined, { params: body })
    .then((res) => res.data);
};
