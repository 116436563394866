import React, { FC } from 'react';
import { Tag as AntdTag, TagProps as AntdTagProps } from 'antd';
import styled, { css } from 'styled-components';

import { useDirection } from '~/hooks/i18nHooks';
import { DirectionProp } from '~/types/Direction';
import direction from '~/utils/direction';
import colors from '~/constants/colors';
import theme from '~/constants/theme';

interface Props extends AntdTagProps {
  marginRight?: number;
  disabled?: boolean;
}

const Tag: FC<Props> = (props) => {
  const { marginRight, disabled = false, ...rest } = props;
  const dir = useDirection();

  return <StyledTag marginRight={marginRight} disabled={disabled} {...rest} dir={dir} />;
};

const StyledTag = styled<FC<Props & DirectionProp>>(AntdTag as any)`
  display: flex;
  align-items: center;
  border-radius: ${theme.borderRadiusSm}px;
  font-size: 14px;
  height: 24px;
  ${({ marginRight = 0 }) => css`
    ${direction.margin.right(marginRight)};
    ${direction.margin.left(0)};
  `}
  ${({ color }) =>
    color === 'blue'
      ? css`
          color: ${colors.blue[800]};
        `
      : undefined}
  color: ${({ disabled }) => (disabled ? colors.disabledBlueTextColor : undefined)};
`;

export default Tag;
