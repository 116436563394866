import { ApplicationPayload } from './ApplicationPayload';
import { ApplicationStep } from './ApplicationStep';
import { SoftDeclineError } from './SoftDeclineError';
import { PaymentStagePayload } from './PaymentStagePayload';
import { AgentLead } from './AgentLead';
import { AgentApplication } from './AgentApplication';

type ApplicationLabel = AgentLead['statusKey'] | AgentApplication['statusKey'];

export enum Stage {
  companyType = 'companyType',
  fillApplication = 'fillApplication',
  highRisk = 'highRisk',
  waitingForScreening = 'waitingForScreening',
  error = 'error',
  screeningError = 'screeningError',
  contract = 'contract',
  payment = 'payment',
  screeningReject = 'screeningReject',
  submit = 'submit',
  successfulOnboarding = 'successfulOnboarding',
  salesforceReject = 'salesforceReject',
  salesforceSoftDecline = 'salesforceSoftDecline',
  salesforceHardDecline = 'salesforceHardDecline',
  salesforceTechnicalError = 'salesforceTechnicalError',
  salesforceError = 'salesforceError',
  terminated = 'terminated',
  completed = 'completed',
}

type ApplicationBase<S, StagePayload = undefined> = {
  uid: string;
  label: ApplicationLabel;
  processUid: string;
  stage: S;
  actualStage?: Stage;
  payload: ApplicationPayload;
  stagePayload: StagePayload;
  readOnly: boolean;
  errorKeys?: string[];
  errorInformation?: {
    errorFields?: { [namePath: string]: string | null }[];
    errorMessages: string[];
    type: 'ContractStagePayload';
  };
  disablePaymentStep?: boolean;
};

export type Application =
  | ApplicationBase<Stage.companyType>
  | ApplicationBase<
      Stage.fillApplication,
      {
        activeStep: ApplicationStep;
        steps: ApplicationStep[];
        type: 'wizard' | 'agent-wizard';
      }
    >
  | ApplicationBase<
      Stage.terminated,
      {
        activeStep: ApplicationStep;
        steps: ApplicationStep[];
        type: 'wizard' | 'agent-wizard';
        terminationInfo: {
          terminationDate: string;
          terminationReason: string;
        };
      }
    >
  | ApplicationBase<Stage.highRisk>
  | ApplicationBase<Stage.waitingForScreening>
  | ApplicationBase<Stage.error>
  | ApplicationBase<Stage.screeningError>
  | ApplicationBase<
      Stage.contract,
      {
        errorInformation?: {
          errorFields?: { [namePath: string]: string | null }[];
          errorMessages: string[];
          type: 'ContractStagePayload';
        };
      }
    >
  | ApplicationBase<Stage.payment, PaymentStagePayload>
  | ApplicationBase<Stage.screeningReject>
  | ApplicationBase<Stage.submit>
  | ApplicationBase<Stage.successfulOnboarding>
  | ApplicationBase<Stage.salesforceReject>
  | ApplicationBase<
      Stage.salesforceSoftDecline,
      {
        allowToResubmit?: boolean;
        errorsHistory: { created: string; errorMessages: string[] }[];
        errors: SoftDeclineError[];
        type: string;
      }
    >
  | ApplicationBase<Stage.salesforceHardDecline>
  | ApplicationBase<Stage.salesforceTechnicalError>
  | ApplicationBase<Stage.salesforceError>
  | ApplicationBase<Stage.completed>;
