export default {
  Required: 'مطلوب',
  'Required at least 1': 'مطلوب على الأقل 1',
  'Impossible number': 'عدد مستحيل',
  'Invalid number': 'رقم غير صالح',
  'Invalid email': 'بريد إلكتروني خاطئ',
  'Must contain digits only': 'يجب أن يحتوي على أرقام فقط',
  'Must be a string': 'يجب أن يكون سلسلة',
  'Person must be older 18 years old': 'يجب أن يكون عمر الشخص أكبر من 18 عامًا',
  'Must be {{days}} days before': 'يجب أن يكون قبل {{days}} يوم',
  'Must be {{days}} days after': 'يجب أن يكون بعد {{days}} يوم',
  'Must be like https://example.com': 'يجب أن يكون مثل https://example.com',
  'Must be at least {{count}} character': 'يجب ألا يقل عدد الأحرف عن {{count}}',
  'Must be at least {{count}} characters': 'يجب ألا يقل عدد الأحرف عن {{count}}',
  'Max length is {{count}} character': 'أقصى طول هو {{count}} حرف',
  'Max length is {{count}} characters': 'الحد الأقصى للطول هو {{count}} حرفًا',
  'Must be {{count}} character': 'يجب أن يكون عدد الأحرف {{count}}',
  'Must be {{count}} characters': 'يجب أن يكون عدد الأحرف {{count}}',
  'Min is {{minNumber}}': 'الحد الأدنى هو {{minNumber}}',
  'Max is {{maxNumber}}': 'الحد الأقصى هو {{maxNumber}}',
  'Must be more than {{num}}': 'يجب أن يكون أكثر من {{num}}',
  'Must be less than {{num}}': 'يجب أن يكون أقل من {{num}}',
  'Total shares is {{totalShares}}': 'إجمالي المشاركات {{totalShares}}',
  'Invalid IBAN': 'رقم الحساب بصيغة IBAN غير صالح',
  'Must starts with {{countryCode}}': 'يجب أن يبدأ بـ {{countryCode}}',
  'Impossible Emirates ID. Check for typos carefully':
    'هوية إماراتية مستحيلة. تحقق من الأخطاء المطبعية بعناية',
  "Character '.' (dot) should not be first or last character":
    "حرف '.' (النقطة) يجب ألا تكون الحرف الأول أو الأخير",
  "Character ' ' (space) should not be first or last character":
    'يجب ألا يكون الحرف "" (مسافة) الحرف الأول أو الأخير',
  "Character '.' (dot) should not appear two or more times consecutively":
    "حرف '.' (نقطة) يجب ألا تظهر مرتين أو أكثر على التوالي",
  "Character ' ' (space) should not appear two or more times consecutively":
    "حرف ' ' (مسافة) يجب ألا تظهر مرتين أو أكثر على التوالي",
  'Includes forbidden symbols': 'يشمل الرموز الممنوعة',
  'Max length is 255 characters': 'الحد الأقصى للطول هو 255 حرفًا',
  "Character ' ' (space) is forbidden": "الحرف '' (مسافة) ممنوع",
  'Should contain only letters (A-Z) and digits (0-9)':
    'يجب أن يحتوي على الأحرف (A-Z) والأرقام (0-9) فقط',
  'A-Z (hyphen) and 0-9 symbols are allowed only': 'يُسمح فقط بالرموز A-Z (واصلة) و0-9',
  'Must be unique. Check another representative': 'يجب أن يكون فريدًا. تحقق من ممثل آخر',
  'Must be unique. Check another shareholder': 'يجب أن يكون فريدًا. تحقق من مساهم آخر',
  'Mark at least one person as an authorized signatory':
    'وضع علامة على شخص واحد على الأقل كمفوض بالتوقيع',
  'Upload Copy of Memorandum of Association or Partnership deed':
    'تحميل نسخة من عقد التأسيس أو عقد الشراكة',
  'Total shares must be at least {{minShares}}%': 'يجب ألا يقل إجمالي المشاركات عن {{minShares}}٪',
  'Edit persons at corresponding pages': 'تحرير الأشخاص في الصفحات المقابلة',
  'Must includes letters': 'يجب أن يتضمن الحروف',
  'Must includes only letters': 'يجب أن يتضمن الحروف فقط',
  'Must include only latin letters': 'يجب أن يتضمن أحرف لاتينية فقط',
};
