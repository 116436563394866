import React, { FC, useState } from 'react';
import { Button, Popover } from 'antd';
import styled from 'styled-components';
import classNames from 'classnames';

import colors from '~/constants/colors';
import { ArrowDownIcon, GlobeIcon } from '~/img';
import LanguageMenu from './LanguageMenu';
import { useDirection } from '~/hooks/i18nHooks';

import './styles.less';

const LanguageSelector: FC = () => {
  const [open, setOpen] = useState(false);
  const dir = useDirection();

  return (
    <Popover
      trigger="click"
      visible={open}
      onVisibleChange={setOpen}
      placement={dir === 'ltr' ? 'bottomRight' : 'bottomLeft'}
      content={<LanguageMenu onChange={() => setOpen(false)} />}
      overlayClassName={classNames('LanguageSelector-PopoverOverlay', {
        'LanguageSelector-PopoverOverlay__rtl': dir === 'rtl',
      })}
    >
      <StyledButton className="LanguageSelector" shape="round" size="middle" dir={dir}>
        <GlobeIcon className="LanguageSelector-GlobeIcon" />
        <ArrowDownIcon className="LanguageSelector-ArrowDownIcon" />
      </StyledButton>
    </Popover>
  );
};

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  border-width: 0;
  border-color: ${colors.borderColorBase};
  background: transparent;
  box-shadow: none;
  padding: 9px;
  margin: -9px;
  color: ${colors.textColor};
  height: 42px;
  &:hover,
  &:focus,
  &:active {
    border-color: ${colors.borderColorBase};
    box-shadow: 0px 0px 0.5px 0.5px ${colors.borderColorBase};
    color: ${colors.textColor};
    background-color: transparent;
  }

  .anticon {
    display: flex;
  }
  .LanguageSelector-GlobeIcon {
    font-size: 32px;
  }
  .LanguageSelector-ArrowDownIcon {
    font-size: 12px;
  }

  &[dir='rtl'] {
    .LanguageSelector-ArrowDownIcon {
      margin-left: 0 !important;
      margin-right: 8px;
    }
  }
`;

export default LanguageSelector;
