import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { ButtonProps as AntdButtonProps } from 'antd/lib/button/button';

import { Button } from '~/components';
import { FilterIcon, FilterEmptyIcon } from '~/img';
import { usePath } from '~/hooks';
import { DashboardStatus, MapItemType } from '~/types/AgentMapItem';

const FiltersButton: FC<AntdButtonProps> = (props) => {
  const { t } = useTranslation('agent');

  const { params } = usePath();
  const { dashboardStatus, queryType, filterCompanyOrAddress, ...rest } = params.filter ?? {};
  const emptyFilters =
    dashboardStatus !== DashboardStatus.DONE &&
    dashboardStatus !== DashboardStatus.OPENED &&
    queryType !== MapItemType.LEAD &&
    queryType !== MapItemType.APPLICATION &&
    isEmpty(rest);

  return (
    <Button
      id="addLeadButton"
      size="middle"
      icon={emptyFilters ? <FilterEmptyIcon /> : <FilterIcon />}
      {...props}
    >
      {t('Filter')}
    </Button>
  );
};

export default FiltersButton;
