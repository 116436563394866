import React, { FC, useEffect, useRef, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';

import colors from '~/constants/colors';
import { useTopBarLoader } from '~/hooks/useTopBarLoader';

const TopBarLoader: FC = () => {
  const { isLoading } = useTopBarLoader();
  const ref = useRef<LoadBarMethods>(null);

  const [isStarted, setStarted] = useState(false);
  useEffect(() => {
    if (isLoading && !isStarted) {
      ref.current?.continuousStart();
      setStarted(true);
    }
    if (!isLoading && isStarted) {
      ref.current?.complete();
      setStarted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return <LoadingBar ref={ref} color={colors.primaryColor} height={3} />;
};

// https://www.npmjs.com/package/react-top-loading-bar
// Built-in Methods
type LoadBarMethods = {
  add: (value: number) => void;
  decrease: (value: number) => void;
  continuousStart: (startingValue?: number, refreshRate?: number) => void;
  staticStart: (startingValue?: number) => void;
  complete: () => void;
};

export default TopBarLoader;
