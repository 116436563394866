import React, { FC, useMemo, useState } from 'react';
import { Modal, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useGetApplication, useSubmitStep } from '~/controllers/wizard';

import WizardContinueButton from '~/layouts/WizardContinueButton';
import { WarningCircleIcon } from '~/img';
import { Space, Alert } from '~/components';
import ServicesTotalPrice from '../ServicesView/ServicesTotalPrice';
import { getStagePayload } from '~/utils/getStagePayload';

const ReviewAndSubmitRightContent: FC = () => {
  const { t } = useTranslation('fillApplication');

  const submitMutation = useSubmitStep();
  const applicationQuery = useGetApplication();
  const allDone = useMemo(() => {
    const stagePayload = getStagePayload.wizard(applicationQuery.data);
    if (stagePayload) {
      const servicesDone =
        stagePayload.steps.find((item) => item.key === 'services')?.validationState === 'OK';

      const applicationDone = stagePayload.steps
        .find((item) => item.key === 'application')
        ?.steps.filter((item) => item.state !== 'HIDDEN')
        .filter((item) => item.key !== 'application.reviewAndSubmit')
        .every((item) => item.validationState === 'OK');

      return servicesDone && applicationDone;
    }
    return false;
  }, [applicationQuery.data]);

  const [showWarning, setShowWarning] = useState(false);

  const onSubmit = () => {
    setShowWarning(false);
    Modal.confirm({
      title: t('Submitting application'),
      content: (
        <Typography.Paragraph>
          <p>
            {t(
              'Please make sure that you verified the information in the tabs. You will not be able to change the application data after submitting.'
            )}
          </p>
          <p>{t('Do you want to continue?')}</p>
        </Typography.Paragraph>
      ),
      okText: t('Continue', { ns: 'common' }),
      cancelText: t('Cancel', { ns: 'common' }),
      icon: null,
      onOk() {
        return submitMutation.mutateAsync({});
      },
    });
  };

  return (
    <Container>
      <ServicesTotalPrice />
      <Space direction="vertical" size={24}>
        {showWarning && (
          <Alert
            message={t('Correct application data')}
            description={t('Complete all steps to submit application')}
            type="warning"
            icon={<WarningCircleIcon />}
            showIcon
          />
        )}
        <WizardContinueButton
          id="submitApplicationButton"
          onClick={allDone ? onSubmit : () => setShowWarning(true)}
        >
          {t('Submit application')}
        </WizardContinueButton>
      </Space>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  .TotalPrice {
    margin-bottom: 56px;
  }
`;

export default ReviewAndSubmitRightContent;
