import React, { FC } from 'react';
import { Space, SpaceProps } from 'antd';
import styled from 'styled-components';

import { useDirection } from '~/hooks/i18nHooks';
import { DirectionProp } from '~/types/Direction';
import direction from '~/utils/direction';

const Right: FC = (props) => {
  const { children } = props;
  const dir = useDirection();

  return (
    <SpaceStyled size={16} direction="horizontal" align="center" dir={dir}>
      {children}
    </SpaceStyled>
  );
};

type UIProps = SpaceProps & DirectionProp;

const SpaceStyled = styled<FC<UIProps>>(Space as any)`
  ${direction.margin.left(13)};
  text-align: ${({ dir }) => (dir === 'ltr' ? 'right' : 'left')};
`;

export default Right;
