import { Empty } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Redirect, Route, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import NoteText from '~/components/NoteText';
import ApplicationCommonErrors from '~/components/views/ApplicationCommonErrors';
import ReviewAndSubmitRightContent from '~/components/views/ReviewAndSubmit/ReviewAndSubmitRightContent';
import features from '~/constants/features';
import { useGetApplication } from '~/controllers/wizard';
import ApplicationNavigation from '~/layouts/ApplicationNavigation';
import BottomLeftActions from '~/layouts/BottomLeftActions';
import LeftLayout from '~/layouts/LeftLayout';
import WizardContent from '~/layouts/WizardContent';
import WizardContinueButton from '~/layouts/WizardContinueButton';
import paths from '~/pages/paths';
import { getStagePayload } from '~/utils/getStagePayload';
import additionalInformation from './additionalInformation';
import addresses from './addresses';
import authorizedRepresentative from './authorizedRepresentative';
import bankAccount from './bankAccount';
import companyInformation from './companyInformation';
import reviewAndSubmit from './reviewAndSubmit';
import shareholders from './shareholders';
import UaePassImportDocumentAlert from '~/components/views/authorizedRepresentative/uaePassImportDocument/UaePassImportAlert';
import theme from '~/constants/theme';

const views: Record<string, React.ComponentType> = {
  companyInformation,
  addresses,
  authorizedRepresentative,
  shareholders,
  additionalInformation,
  bankAccount,
  reviewAndSubmit,
};

const Application: FC<void> = () => {
  const { t } = useTranslation('fillApplication');
  const location = useLocation();
  const applicationQuery = useGetApplication();

  const stagePayload = getStagePayload.wizard(applicationQuery.data);
  const applicationSteps = stagePayload?.steps.find((item) => item.view === 'application')?.steps;

  const isReviewAndSubmit = location.pathname.includes('reviewAndSubmit');
  const isAuthorizedRepresentative = location.pathname.includes('authorizedRepresentative');

  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.lg });

  return (
    <WizardContent
      left={
        <LeftLayout>
          <ApplicationNavigation />
          <BottomLeftActions contactUsUrl={features.contactUsLinkWizardApplication} />
        </LeftLayout>
      }
      content={
        <>
          {applicationSteps?.[0] && paths.wizard.application === location.pathname && (
            <Redirect
              exact
              from={paths.wizard.application}
              to={`${paths.wizard.application}/${applicationSteps?.[0].uiKey}`}
            />
          )}
          {applicationSteps?.map((item) => {
            if (item.state === 'HIDDEN') return null;
            return (
              <Route
                key={item.key}
                path={`${paths.wizard.application}/${item.uiKey}` as any as string}
                component={views[item.uiKey] || Empty}
              />
            );
          })}
        </>
      }
      right={
        isReviewAndSubmit ? (
          <ReviewAndSubmitRightContent />
        ) : (
          <RightContainer>
            <div className="upperContainer">{!isTablet && <UaePassImportDocumentAlert />}</div>
            <ApplicationCommonErrors />
            {isAuthorizedRepresentative && (
              <NoteText>
                {t('Please note, any individual provided in this section will be screened.')}
              </NoteText>
            )}
            <WizardContinueButton
              id="applicationSteps_continueButton"
              formName={location.pathname}
            />
          </RightContainer>
        )
      }
    />
  );
};

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;

  .upperContainer {
    height: 100%;
  }

  .WizardContinueButton,
  .NoteText {
    margin-top: 24px;
  }
`;

export default Application;
