import React, { FC } from 'react';
import { Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import WizardContent from '~/layouts/WizardContent';
import { ScreeningErrorImg } from '~/img';
import features from '~/constants/features';
import LeftLayout from '~/layouts/LeftLayout';
import BottomLeftActions from '~/layouts/BottomLeftActions';

const ScreeningError: FC = () => {
  const { t } = useTranslation('stageResult');

  return (
    <WizardContent
      left={
        <LeftLayout>
          <BottomLeftActions contactUsUrl={features.contactUsLinkScreeningError} />
        </LeftLayout>
      }
      content={
        <Container className="ScreeningError">
          <Typography.Title>{t('Screening in progress')}</Typography.Title>
          <Typography.Paragraph>
            <p>
              {t(
                'We need a little more time to review all individuals you have listed. We will notify you by email to proceed with the application.'
              )}
            </p>
            <p>
              <Trans
                t={t}
                i18nKey="In the meantime, you can head to our website to view more about our <1>products and services</1>."
              >
                {'In the meantime, you can head to our website to view more about our '}
                <a href={features.homeLink} target="__blank">
                  products and services
                </a>
                .
              </Trans>
            </p>
            <p>
              <Trans
                t={t}
                i18nKey="If you require any support whilst you wait, please <1>contact us</1>."
              >
                {'If you require any support whilst you wait, please '}
                <a href={features.contactUsLinkScreeningError} target="__blank">
                  contact us
                </a>
                .
              </Trans>
            </p>
          </Typography.Paragraph>
          <ScreeningErrorImg className="ScreeningError-Img" />
        </Container>
      }
      right={null}
    />
  );
};

const Container = styled.div`
  .ScreeningError-Img {
    margin-top: 36px;
    border-radius: 14px;
    width: 100%;
  }
`;

export default ScreeningError;
