import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { getI18n } from 'react-i18next';
import styled from 'styled-components';
import TableHeader from '~/components/views/agent/tableElements/TableHeader';
import { RequestApprovedIcon, RequestActiveIcon, RequestReviewIcon } from '~/img';
import colors from '~/constants/colors';
import { PricingRequestStatus } from '~/types/Services';

function requestCell(): ColumnsType<any>[0] {
  return {
    title: () => (
      <TableHeader
        title={getI18n().t('Request', { ns: 'agent' })}
        sortingDataKey="pricingRequestStatus"
      />
    ),
    dataIndex: 'pricingRequestStatus',
    key: 'pricingRequestStatus',
    render: (status: PricingRequestStatus | undefined) => {
      return (
        <Container>
          {status === 'APPLIED' ? (
            <RequestApprovedIcon className="agentTableRequestIcon" />
          ) : status === 'SAVED' ? (
            <RequestReviewIcon className="agentTableRequestIcon" />
          ) : status === 'REQUESTED' ? (
            <RequestActiveIcon className="agentTableRequestIcon" />
          ) : undefined}
        </Container>
      );
    },
    width: 100,
  };
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .agentTableRequestIcon {
    color: ${colors.textColor};
    font-size: 20px;
  }
`;

export default requestCell;
