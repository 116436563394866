import { UploadFile } from 'antd/lib/upload/interface';
import { useState } from 'react';

import { getFile } from '~/api/file';
import { useDeepEffect } from '~/hooks';
import { FileType } from '~/types/files';
import { blobToAntdFile } from './useFileList';

type LoadImageType = {
  dataURL: string | undefined;
  loading: boolean;
  error: null | string;
  antdImage: UploadFile | undefined;
};

const initialState: LoadImageType = {
  dataURL: undefined,
  loading: false,
  error: null,
  antdImage: undefined,
};

const useLoadImage = (file: FileType | undefined): LoadImageType => {
  const [state, setState] = useState<LoadImageType>(initialState);

  useDeepEffect(() => {
    void (async () => {
      if (!file) {
        setState(initialState);
        return;
      }
      const isRaw = Boolean(file.rawFile);
      const actualFile = file.rawFile || file;
      setState({ ...initialState, loading: true });
      try {
        if (!actualFile || !isImage(actualFile)) {
          throw new Error('This file is not an image');
        }
        let image = null;
        if (isRaw) {
          image = actualFile;
        } else {
          const { data } = await getFile(actualFile);
          image = data;
        }
        const dataURL = URL.createObjectURL(image);

        setState({
          dataURL,
          loading: false,
          error: null,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          antdImage: blobToAntdFile(image, file.name, file.id, file.mimeType!),
        });
      } catch (err) {
        const error: any = err;
        setState({
          dataURL: undefined,
          loading: false,
          error: error.message || 'Unknown error',
          antdImage: undefined,
        });
      }
    })();
  }, [file]);

  return state;
};

const isImage = (file: FileType) => {
  return file && (file.type || file.mimeType || '').startsWith('image');
};

export default useLoadImage;
