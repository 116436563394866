/* eslint-disable react/no-array-index-key */
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import { useMediaQuery } from 'react-responsive';

import { ApplicationPayload } from '~/types/ApplicationPayload';
import longdash from '~/constants/longdash';
import theme from '~/constants/theme';
import Space from '~/components/Space';
import CardMobile from '~/components/CardMobile';
import GroupLayout from '~/layouts/GroupLayout';
import TextField from '~/components/fields/TextField';
import Table from '~/components/Table';
import CountryField from '~/components/fields/CountryField';

interface Props {
  payload: ApplicationPayload;
}

const ShareholdersIndividualTable: FC<Props> = (props) => {
  const { payload } = props;
  const { t } = useTranslation('fillApplication');

  const columns = useMemo<ColumnsType<RowDataType>>(
    () => [
      {
        title: t('Full name'),
        dataIndex: 'fullName',
        key: 'fullName',
        width: '33.333%',
      },
      {
        title: t('Residency'),
        dataIndex: 'country',
        key: 'country',
        width: '33.333%',
        render: (country) => <CountryField value={country} />,
      },
      {
        title: t('Shares, %'),
        dataIndex: 'shares',
        key: 'shares',
        width: '33.333%',
      },
    ],
    [t]
  );

  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.sm });

  if (!payload) return null;
  const dataSource = convertData(payload);

  return !isTablet ? (
    <Table
      id="reviewAndSubmitShareholderIndividualTable"
      dataSource={dataSource}
      columns={columns}
      pagination={pagination}
    />
  ) : (
    <Space size={16} direction="vertical">
      {dataSource?.map((item, index) => {
        return (
          <CardMobile key={`ShareholdersIndividualTable_${index}`}>
            <GroupLayout gutter={[16, 16]} sm={8} xs={8}>
              <TextField label={t('Full name')}>{item.fullName}</TextField>
              <TextField label={t('Residency')}>
                <CountryField value={item.country} />
              </TextField>
              <TextField label={t('Shares, %')}>{item.shares}</TextField>
            </GroupLayout>
          </CardMobile>
        );
      })}
    </Space>
  );
};

const pagination = { hideOnSinglePage: true };

type RowDataType = {
  fullName: string;
  country: string;
  shares: number;
};

const convertData = (data: ApplicationPayload | undefined): RowDataType[] | undefined => {
  const authorizedRepresentativeShareholders: RowDataType[] =
    data?.shareholders?.authorizedRepresentative?.map((item) => {
      const person = data.authorizedRepresentative?.find((p) => p.uid === item.uid);
      return {
        fullName: [person?.firstName, person?.lastName].join(' '),
        country: person?.country || longdash,
        shares: item.shares,
      };
    }) || [];

  const individualShareholders: RowDataType[] =
    data?.shareholders?.individuals?.map((item) => {
      const person = item.data;
      return {
        fullName: [person?.firstName, person?.lastName].join(' '),
        country: person?.country || longdash,
        shares: item.shares,
      };
    }) || [];

  return authorizedRepresentativeShareholders.concat(individualShareholders);
};

export default ShareholdersIndividualTable;
