import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';

import { ApiError } from '~/types/ApiError';
import { API_URL } from '~/env';
import { auth } from './auth';
import { redirectToExternalAuth } from '~/utils/redirectToExternalAuth';
import features from '~/constants/features';
import i18n from '~/i18n';

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    'Tenant-Id': features.tenantId,
  },
  paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'comma' }),
});

instance.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
  const result = { ...config };
  const token = auth.getToken();
  const agentApplicationId = auth.getAgentApplicationId();
  if (!result.headers) {
    result.headers = {};
  }
  if (token) {
    result.headers[auth.tokenHeaderKey] = token;
  }
  if (agentApplicationId) {
    result.headers[auth.agentApplicationIdHeaderKey] = agentApplicationId;
  }
  if (i18n.language) {
    result.headers['Accept-Language'] = i18n.language;
  }
  return result;
});

instance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error: ApiError) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response?.status === 401) {
      switch (error.config.url) {
        // case paths.auth.redirectToKeyCloak:
        //   break;
        default: {
          setTimeout(() => {
            redirectToExternalAuth();
          }, 1000);
        }
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
