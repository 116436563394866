import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { copy } from '~/utils/clipboard';

export const useCopy = () => {
  const { t } = useTranslation();
  const onSuccess = () => {
    void message.success(t('Copied'), 1);
  };

  return (value: string) => copy(value, onSuccess);
};
