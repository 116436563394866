import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '~/components/Alert';
import colors from '~/constants/colors';
import { CheckmarkIcon } from '~/img';
import { PricingRequestStatus } from '~/types/Services';
import { isMerchant } from '~/utils/getDecodedJwt';

interface Props {
  status: PricingRequestStatus;
}

const CustomPricingStatusAlert: FC<Props> = (props) => {
  const { status } = props;
  const { t } = useTranslation('fillApplication');

  switch (status) {
    case 'REQUESTED': {
      if (!isMerchant()) return null;
      return (
        <Alert
          className="customPricingStatusAlert_Requested"
          message={t('Callback is requested')}
          showIcon
          type="info"
        />
      );
    }
    case 'SAVED':
    case 'APPLIED': {
      return (
        <Alert
          className="customPricingStatusAlert_Applied"
          message={t('Special pricing applied')}
          showIcon
          type="info"
          icon={<CheckmarkIcon style={{ color: colors.successColor }} />}
        />
      );
    }
    default:
      return null;
  }
};

export default React.memo(CustomPricingStatusAlert);
