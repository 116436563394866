import React, { FC } from 'react';
import { Input, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { Button, DateInput, DocumentInput, Form } from '~/components';
import InputsGroupLayout from '~/layouts/InputsGroupLayout';
import Section from '~/layouts/Section ';
import { useCopy } from '~/hooks/clipboard';
import { BankTransferPaymentType } from '~/types/PaymentMethods';

interface Props {
  record: BankTransferPaymentType;
}

const BankTransfer: FC<Props> = (props) => {
  const { record } = props;
  const { accountName, iban, bankName } = record;
  const { t } = useTranslation('payment');
  const copy = useCopy();

  return (
    <>
      <Section formItemLast>
        <Typography.Title level={4} style={marginBottom16}>
          {t('Payment requisites')}
        </Typography.Title>
        <Typography.Paragraph>
          {t('Please quote the company legal name as a payment reference.')}
        </Typography.Paragraph>
        <div>{t('Bank transfer payment please remit to:')}</div>
        <div>{t('Account holder: {{accountName}}', { accountName })}</div>
        <div>{t('IBAN: {{iban}}', { iban })}</div>
        <div style={marginBottom16}>{t('Bank name: {{bankName}}', { bankName })}</div>
        <Button
          id="bankTransfer_copyRequisites"
          type="default"
          onClick={() =>
            void copy(`
              ${t('Account holder: {{accountName}}', { accountName })}
              ${t('IBAN: {{iban}}', { iban })}
              ${t('Bank name: {{bankName}}', { bankName })}
            `)
          }
        >
          {t('Copy requisites')}
        </Button>
      </Section>
      <Typography.Title level={4} style={marginBottom16}>
        {t('Upload payment confirmation')}
      </Typography.Title>
      <Form.Item name={['invoices']} rules={[{ required: true }]}>
        <DocumentInput name="invoices" variant="default" />
      </Form.Item>
      <InputsGroupLayout>
        <Form.Item
          name={['paymentReference']}
          label={t('Payment reference')}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name={['paymentDate']} label={t('Payment date')} rules={[{ required: true }]}>
          <DateInput />
        </Form.Item>
      </InputsGroupLayout>
    </>
  );
};

const marginBottom16 = { marginBottom: 16 };

export default BankTransfer;
