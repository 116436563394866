import { set } from 'lodash';
import React, { FC, useMemo } from 'react';

import Space from '~/components/Space';
import { PrimitiveFieldMap, Services } from '~/types/Services';
import { NameFn } from '~/utils/buildNamePath';
import FieldResolver from './FieldResolver';
import { isVisible } from './utils';

interface Props {
  n: NameFn;
  fields: Services['additionalConfigurations'];
}

const GeneralConfigurationFields: FC<Props> = (props) => {
  const { n, fields } = props;

  const fieldMap = useMemo(() => {
    const result: PrimitiveFieldMap = {};
    fields.forEach((f) => {
      switch (f.type) {
        case 'composite': {
          if (f.booleanField) {
            set(result, [f.booleanField.key], f.booleanField);
          }
          if (f.pricingField) {
            set(result, [f.pricingField.key], f.pricingField);
          }
          if (f.schemaField) {
            set(result, [f.schemaField.key], f.schemaField);
          }
          f.fields?.forEach((ff) => {
            set(result, [ff.key], ff);
          });
          break;
        }
        default: {
          set(result, [f.key], f);
        }
      }
    });
    return result;
  }, [fields]);

  return (
    <Space size={24} direction="vertical" fullWidth>
      {fields.map((f) =>
        isVisible(f) ? <FieldResolver key={f.key} n={n} field={f} fieldMap={fieldMap} /> : null
      )}
    </Space>
  );
};

export default GeneralConfigurationFields;
