import React, { FC, useMemo } from 'react';
import { Input, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  CountryInput,
  DocumentInput,
  EmirateSelect,
  PhoneNumberFormItem,
  Select,
} from '~/components';
import Form from '~/components/WizardForm';
import PinLocation from '~/components/PinLocation';
import features from '~/constants/features';
import InputsGroupLayout from '~/layouts/InputsGroupLayout';
import Section from '~/layouts/Section ';
import { isAgent } from '~/utils/getDecodedJwt';
import CustomFields from '~/components/CustomFields';

const n = (namePath: string | string[]) => ['addresses'].concat([namePath].flat());

const Addresses: FC = () => {
  const { t } = useTranslation('fillApplication');

  const typeOfProperty = useMemo(() => {
    return [
      {
        label: t('Rented'),
        value: 'RENTED',
      },
      {
        label: t('Owned'),
        value: 'OWNED',
      },
    ];
  }, [t]);

  return (
    <Container className="Addresses">
      <Form stepKey="addresses">
        <Typography.Title>{t('Address')}</Typography.Title>
        <Section formItemLast>
          <Typography.Title level={4}>{t('Head office').toUpperCase()}</Typography.Title>
          <Typography.Paragraph>
            {t(
              'This must be the location where you will take payments and where we can reach you.'
            )}
          </Typography.Paragraph>
          <InputsGroupLayout>
            <Form.Item
              label={t('Country')}
              name={n(['headOffice', 'country'])}
              initialValue={features.defaultCountrySalesforce}
            >
              <CountryInput disabled={!isAgent()} />
            </Form.Item>
            <Form.Item name={n(['headOffice', 'geolocation'])}>
              <PinLocation id="applicationPinLocation" />
            </Form.Item>
            <Form.Item label={t('Address line')} name={n(['headOffice', 'addressLine'])}>
              <Input />
            </Form.Item>
            <Form.Item label={t('P.O. Box')} name={n(['headOffice', 'poBox'])}>
              <Input />
            </Form.Item>
            <PhoneNumberFormItem
              label={t('Office phone number')}
              name={n(['headOffice', 'phoneNumber'])}
            />
            <PhoneNumberFormItem label={t('Fax number')} name={n(['headOffice', 'faxNumber'])} />
            <Form.Item label={t('Emirate')} name={n(['headOffice', 'emirate'])}>
              <EmirateSelect />
            </Form.Item>
            <Form.Item
              label={t('Type of property occupied')}
              name={n(['headOffice', 'propertyType'])}
            >
              <Select options={typeOfProperty} />
            </Form.Item>
          </InputsGroupLayout>
        </Section>

        <Section formItemLast>
          <Typography.Paragraph>
            <Trans
              t={t}
              defaults="Please upload your <strong>RERA registration</strong> or <strong>Lease Agreement</strong> documents."
            />
          </Typography.Paragraph>
          <Form.Item name={n(['documents', 'files'])}>
            <DocumentInput name="photos" variant="default" />
          </Form.Item>
        </Section>

        <Section formItemLast>
          <Typography.Paragraph>
            <Trans t={t} defaults="Please upload a photo of <strong>outside</strong> the outlet." />
          </Typography.Paragraph>
          <Form.Item name={n(['photos', 'outside', 'files'])}>
            <DocumentInput name="photos" variant="default" />
          </Form.Item>
        </Section>

        <Section formItemLast>
          <Typography.Paragraph>
            <Trans t={t} defaults="Please upload a photo of <strong>inside</strong> the outlet." />
          </Typography.Paragraph>
          <Form.Item name={n(['photos', 'inside', 'files'])}>
            <DocumentInput name="photos" variant="default" />
          </Form.Item>
        </Section>

        <section>
          <Typography.Paragraph>
            <Trans t={t} defaults="<strong>Do you have any branches?</strong>" />
          </Typography.Paragraph>
          <Typography.Paragraph>
            {t(
              'We are currently only able to on-board one location / branch where you can take payments. However, you can continue with this process and we will be happy to add more locations / branches once you are onboarded.'
            )}
          </Typography.Paragraph>
          <Typography.Paragraph>
            <Trans
              t={t}
              i18nKey="Alternatively, you can stop the process now and request a member of our team to contact you using the contact us form <1>by link<1/>."
            >
              {
                'Alternatively, you can stop the process now and request a member of our team to contact you using the contact us form '
              }
              <a href={features.contactUsLinkBranch} target="__blank">
                by link
              </a>
              .
            </Trans>
          </Typography.Paragraph>
        </section>
        <CustomFields />
      </Form>
    </Container>
  );
};

const Container = styled.div`
  .Addresses-Checkbox {
    margin-bottom: 16px;
  }
`;

export default Addresses;
