import React, { FC } from 'react';
import styled from 'styled-components';

const SpaceBetweenField: FC<{ className?: string }> = (props) => {
  const { children, className } = props;
  return <Container className={`SpaceBetweenField ${className}`}>{children}</Container>;
};

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: baseline;
`;

export default SpaceBetweenField;
