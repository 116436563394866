import { ApplicationStep } from '~/types/ApplicationStep';

import axios from './axios';
import { SaveDraftBody } from '~/types/Draft';

export const getDraft = <D = any>(stepKey: ApplicationStep['key']) => {
  return axios
    .get<D>('/merchant/application-draft', { params: { stepKey } })
    .then((res) => res.data);
};

export const saveDraft = (body: SaveDraftBody) => {
  return axios.post('/merchant/application-draft', { ...body }).then((res) => res.data);
};
