import { RuleObject } from 'antd/lib/form';
import { basicValidation } from './basicValidation';
import i18n from '~/i18n';

export type Validator = RuleObject['validator'];

const m = {
  minLength(len: number) {
    const characters = len === 1 ? 'character' : 'characters';
    return i18n.t('Must be at least {{len}} {{characters}}', { len, characters, ns: 'validation' });
  },
  maxLength(len: number) {
    const characters = len === 1 ? 'character' : 'characters';
    return i18n.t('Max length is {{len}} {{characters}}', { len, characters, ns: 'validation' });
  },
};

export const minLength: (len: number) => Validator = (len) =>
  basicValidation((rule, value, callback) => {
    try {
      if (typeof value !== 'string') {
        throw new Error(i18n.t('Must be a string', { ns: 'validation' }));
      }
      if (!(value.length >= len)) {
        throw new Error(m.minLength(len));
      }
      callback();
    } catch (error) {
      const err: any = error;
      callback(err.message);
    }
  });

export const maxLength: (len: number) => Validator = (len) =>
  basicValidation((rule, value, callback) => {
    try {
      if (typeof value !== 'string') {
        throw new Error(i18n.t('Must be a string', { ns: 'validation' }));
      }
      if (!(value.length <= len)) {
        throw new Error(m.maxLength(len));
      }
      callback();
    } catch (error) {
      const err: any = error;
      callback(err.message);
    }
  });
