import React, { FC } from 'react';
import styled from 'styled-components';

import longdash from '~/constants/longdash';
import theme from '~/constants/theme';

interface Props {
  label: string;
  emptyText?: any;
  id?: string;
}

const TextField: FC<Props> = (props) => {
  const { label, children, emptyText = longdash, id } = props;
  return (
    <Container className="TextField" id={id ? `TextField_${id}` : undefined}>
      <strong className="TextField-Label">{label}</strong>
      <div className="TextField-Children">{children || emptyText}</div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  .TextField-Label {
    margin-bottom: 8px;
  }
  .TextField-Children {
    word-break: break-word;
  }
  @media (max-width: ${theme.breakpoints.sm}px) {
    font-size: 14px;
    .TextField-Label {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 4px;
    }
  }
`;

export default TextField;
