import React, { FC, useState } from 'react';
import { useMutation } from 'react-query';
import { Upload, Modal } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { RcFile, UploadFile, UploadProps } from 'antd/lib/upload/interface';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { uploadImage } from '~/api/file';
import useFileList, { rcFileToAndtImage } from '~/hooks/useFileList';
import useImageCache from '~/hooks/useImageCache';
import { ImageResponse } from '~/types/files';
import colors from '~/constants/colors';
import features from '~/constants/features';
import DocumentInputContent from './DocumentInputContent';
import humanFileSize from '~/utils/humanFileSize';
import theme from '~/constants/theme';
import DocumentInputContentMobile from './DocumentInputContentMobile';
import { useInputContext } from '~/components/Form/InputContext';

interface Props extends Omit<UploadProps, 'onChange'> {
  label?: string;
  name: string;
  onChange?: (values: ImageResponse | ImageResponse[] | undefined) => void;
  value?: ImageResponse | ImageResponse[];
  maxCount?: number;
  maxSizeB?: number;
  multiple?: boolean;
  variant?: 'default' | 'drawer';
  [x: string]: any;
}

const DocumentInput: FC<Props> = (props) => {
  const {
    name,
    label,
    onChange,
    value = [],
    maxCount = features.documentFileInput.maxCount,
    maxSizeB = features.documentFileInput.maxSizeB,
    multiple = true,
    variant = 'default',
    id,
    disabled,
    className,
    ...rest
  } = props;

  const [previewState, setPreviewState] = useState(previewInitialState);
  const [errorMessage, setErrorMessage] = useState('');

  const fileList = useFileList([value].flat(1));
  const imagePost = useMutation(uploadImage);
  const [, setImageCache] = useImageCache();
  const inputContext = useInputContext();

  const { t } = useTranslation('common');

  const handlers = {
    // Upload
    beforeUpload: async (rcFile: RcFile) => {
      setErrorMessage('');
      if (rcFile.size > maxSizeB) {
        setErrorMessage(
          `Your file is ${humanFileSize(rcFile.size)}, but ${humanFileSize(maxSizeB)} is allowed`
        );
        return false;
      }
      const res = await imagePost.mutateAsync(rcFile);
      setImageCache((prev) => ({
        ...prev,
        [res.id]: {
          isLoading: false,
          error: null,
          antdImage: rcFileToAndtImage(rcFile, res.id, res.mimeType),
          apiImage: res,
        },
      }));
      onChange?.(multiple ? (value as ImageResponse[]).concat([res]) : res);
      return false;
    },
    onRemove: (file: UploadFile<any>) => {
      onChange?.(
        multiple ? (value as ImageResponse[]).filter((item) => item.id !== file.uid) : undefined
      );
      return false;
    },
    // Preview
    onPreview: (file: UploadFile<any>) => {
      const imageUrl = file.thumbUrl;
      if (imageUrl) {
        setPreviewState({
          open: true,
          name: file.name,
          imageUrl,
          type: file.type ?? '',
        });
      }
      return false;
    },
    onClose: () => {
      setPreviewState(previewInitialState);
    },
  };

  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.md });

  const isLimitReached = typeof maxCount === 'number' && maxCount === fileList?.length;
  const isNonMultipleLimitReached = multiple === false && fileList?.length === 1;

  return (
    <Container
      className="DocumentInput"
      variant={variant}
      disabled={!!disabled}
      multiple={multiple}
    >
      <Upload
        id={id}
        accept={features.documentFileInput.allowedFileTypes.join(', ')}
        name={name}
        listType={isTablet ? 'text' : 'picture-card'}
        disabled={imagePost.isLoading || disabled}
        fileList={fileList as any}
        beforeUpload={handlers.beforeUpload}
        onRemove={handlers.onRemove}
        onPreview={handlers.onPreview}
        maxCount={maxCount}
        multiple={multiple}
        isImageUrl={(file): boolean => {
          return !!file.type?.startsWith('image/');
        }}
        className={cx('DocumentInput-Upload', className)}
        {...rest}
      >
        {isLimitReached || isNonMultipleLimitReached ? null : isTablet ? (
          <DocumentInputContentMobile
            loading={imagePost.isLoading}
            optional={inputContext.optional}
          />
        ) : (
          <DocumentInputContent
            maxSizeB={maxSizeB}
            loading={imagePost.isLoading}
            optional={inputContext.optional}
          />
        )}
      </Upload>
      <Modal
        visible={previewState.open}
        title={previewState.name}
        footer={null}
        onCancel={handlers.onClose}
        centered
      >
        {!previewState.type.includes('image') ? (
          <a type={previewState.type} href={previewState.imageUrl} download>
            {t('Download File')}
          </a>
        ) : (
          <img alt={previewState.name} style={fullWidth} src={previewState.imageUrl} />
        )}
      </Modal>
      {errorMessage && (
        <div className="DocumentInput-ErrorMessage" role="alert">
          {errorMessage}
        </div>
      )}
    </Container>
  );
};

const previewInitialState = {
  open: false,
  name: '',
  imageUrl: '',
  type: '',
};

const fullWidth = { width: '100%' };

interface UIProps {
  variant: Props['variant'];
  multiple: boolean;
  disabled: boolean;
}

const Container = styled.div<UIProps>`
  display: flex;
  .DocumentInput-Upload {
    width: 100%;
  }
  .ant-upload-list {
    display: flex;
    ${({ multiple }) =>
      multiple
        ? css`
            @media (min-width: ${theme.breakpoints.md}px) {
              flex-direction: row-reverse;
              flex-wrap: wrap-reverse;
            }
          `
        : css`
            flex-direction: row;
            flex-wrap: wrap;
          `}
  }
  .ant-upload-select {
    width: 100%;
  }
  .ant-upload-list-text {
    flex-direction: column;
    max-width: 400px;
    @media (max-width: ${theme.breakpoints.xs}px) {
      max-width: calc(100vw - 46px);
      .ant-upload-list-text-container {
        width: 100%;
      }
    }
    .ant-upload-list-item-info {
      border-radius: ${theme.borderRadiusSm}px;
    }
    .ant-upload-list-item-card-actions-btn {
      opacity: 1;
    }
    .ant-upload-list-item-list-type-text {
      margin-right: -8px;
      &:hover {
        .ant-upload-list-item-info {
          background-color: unset;
        }
      }
    }
  }
  .ant-upload.ant-upload-select-picture-card {
    border: 2px dashed ${colors.grey[700]};
    background: #fbfbfb;
    flex: 1;
    min-width: 220px;
    &:hover: {
      border-color: ${colors.grey[200]} !important;
    }
  }
  .ant-upload-list-picture-card .ant-upload-list-item {
    height: 104px;
    width: 104px;
  }
  .ant-upload-picture-card-wrapper {
    position: relative;
  }
  .ant-upload-disabled {
    opacity: 0.5;
  }
  .ant-upload-list-picture-card-container {
    opacity: ${({ disabled }) => (disabled ? 0.5 : undefined)};
  }
  .DocumentInput-ErrorMessage {
    color: ${colors.errorColor};
    font-size: 12px;
    position: absolute;
    bottom: -8px;
  }
  ${({ variant }) =>
    variant === 'drawer' &&
    css`
      .ant-upload-list-picture-card-container {
        width: calc(50% - 4px);
        height: unset;
        aspect-ratio: 1;
        &:nth-child(2n) {
          margin-right: 0px;
        }
      }
      .ant-upload.ant-upload-select-picture-card {
        width: calc(50% - 4px);
        height: unset;
        aspect-ratio: 1;
        margin-right: 0px;
      }
    `}
`;

export type DocumentInputOnChangeEvent = UploadChangeParam<UploadFile<any>> & {
  value: ImageResponse;
};

export default DocumentInput;
