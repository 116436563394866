import React, { FC } from 'react';
import { BooleanServiceField } from '~/types/Services';
import { NameFn } from '~/utils/buildNamePath';
import BooleanField from './BooleanField';
import BooleanFormItem from './BooleanFormItem';
import { shouldShowInput } from './utils';

interface Props {
  n: NameFn;
  field: BooleanServiceField;
  title?: string;
}

const BooleanResolver: FC<Props> = (props) => {
  const { n, field, title } = props;
  const isInput = shouldShowInput(field);
  if (isInput) {
    return <BooleanFormItem n={n} field={field} title={title} />;
  }
  return <BooleanField field={field} title={title} />;
};

export default BooleanResolver;
