import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Alert, AmountField, SpaceBetweenField } from '~/components';
import theme from '~/constants/theme';
import { useDirection } from '~/hooks/i18nHooks';
import { DirectionProp } from '~/types/Direction';
import { PricingDto } from '~/types/PricingDto';
import { PricingRequestStatus } from '~/types/Services';
import CustomPricingStatusAlert from './views/ServicesView/CustomPricingStatusAlert';

export interface TotalPriceProps {
  pricing?: PricingDto['pricing'];
  isError?: boolean;
  isLoading?: boolean;
  isStale?: boolean;
  requiresRecalculate?: boolean;
  pricingRequestStatus: PricingRequestStatus | undefined;
}

const TotalPrice: FC<TotalPriceProps> = (props) => {
  const { pricing, isError, isLoading, isStale, requiresRecalculate, pricingRequestStatus } = props;
  const { t } = useTranslation();
  const dir = useDirection();

  return (
    <Container
      className="TotalPrice"
      disabled1={!!(isLoading || isStale || requiresRecalculate)}
      disabled2={!!isError}
      dir={dir}
      requiresRecalculate={!!requiresRecalculate}
    >
      <div className="TotalPrice-RequiresRecalculate">
        <Alert
          type="warning"
          message={t('Pricing has changed')}
          description={t('Save changes to see total price')}
        />
      </div>

      <div className="TotalPrice-Filters">
        <SpaceBetweenField className="TotalPrice-Total">
          <SpaceBetweenField.Left>
            <strong>{t('Total')}</strong>
          </SpaceBetweenField.Left>
          <SpaceBetweenField.Right>
            <strong>
              <AmountField
                id="amountField_totalAmount"
                amount={pricing?.Total.amount}
                alwaysShowDecimalPart
              />
            </strong>
          </SpaceBetweenField.Right>
        </SpaceBetweenField>

        <SpaceBetweenField className="TotalPrice-Tax">
          <SpaceBetweenField.Left>
            <strong>{t('VAT 5%')}</strong>
          </SpaceBetweenField.Left>
          <SpaceBetweenField.Right>
            <strong>
              <AmountField
                id="amountField_VAT5"
                amount={pricing?.['VAT 5%'].amount}
                alwaysShowDecimalPart
              />
            </strong>
          </SpaceBetweenField.Right>
        </SpaceBetweenField>
        {Object.entries(pricing || {})
          .filter(([key]) => !(key === 'Total' || key === 'VAT 5%'))
          .map(([key, value], index) => {
            return (
              <SpaceBetweenField key={key} className="TotalPrice-Rest">
                <SpaceBetweenField.Left>{key}</SpaceBetweenField.Left>
                <SpaceBetweenField.Right>
                  <AmountField
                    id={`amountFieldAdditional_${index}`}
                    amount={value.amount}
                    alwaysShowDecimalPart
                  />
                </SpaceBetweenField.Right>
              </SpaceBetweenField>
            );
          })}
      </div>

      {pricingRequestStatus && (
        <div className="TotalPrice-CustomPricingStatusContainer">
          <CustomPricingStatusAlert status={pricingRequestStatus} />
        </div>
      )}
    </Container>
  );
};

interface ContainerProps extends DirectionProp {
  disabled1: boolean;
  disabled2: boolean;
  requiresRecalculate: boolean;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  min-height: 116px;
  .TotalPrice-Filters {
    opacity: ${({ disabled1, disabled2 }) => (disabled1 || disabled2 ? 0.3 : 1)};
    filter: ${({ disabled1 }) => (disabled1 ? 'blur(3px)' : undefined)};
    filter: ${({ disabled2 }) => (disabled2 ? 'blur(7px)' : undefined)};
  }
  .TotalPrice-Total {
    font-size: 16px;
    margin-bottom: 8px;
  }
  .TotalPrice-Tax {
    margin-bottom: 24px;
  }
  .TotalPrice-Rest {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .TotalPrice-RequiresRecalculate {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 16px;
    opacity: ${({ requiresRecalculate }) => (requiresRecalculate ? 1 : 0)};
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .TotalPrice-CustomPricingStatusContainer {
    margin-top: 24px;
  }
  @media (max-width: ${theme.breakpoints.sm}px) {
    min-height: 0;
  }
  @media (max-width: ${theme.breakpoints.xs}px) {
    .TotalPrice-Tax {
      margin-bottom: 12px;
      line-height: 20px;
    }
    .TotalPrice-Rest {
      line-height: 16px;
    }
  }
`;

export default TotalPrice;
