import React, { FC } from 'react';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Button from '~/components/Button';
import Drawer from '~/components/Drawer';
import Form from '~/components/Form';
import PhoneNumberFormItem from '~/components/inputs/PhoneNumberFormItem';
import { getDecodedJwt } from '~/utils/getDecodedJwt';
import { useCustomPricingDrawerOpen, useRequestCustomPricing } from '~/controllers/pricing';
import { useSaveStepIsLoading } from '~/controllers/wizard';

const RequestCustomPricing: FC = () => {
  const { t } = useTranslation('fillApplication');
  const [isSaving] = useSaveStepIsLoading();
  const [open, setOpen] = useCustomPricingDrawerOpen();
  const handleClose = () => setOpen(false);

  const requestCustomPricingMutation = useRequestCustomPricing({ onSuccess: handleClose });
  const handleFinish = ({ message }: { message: string }) => {
    void requestCustomPricingMutation.mutateAsync(message);
  };

  return (
    <>
      <Button
        id="requestACallbackButton"
        type="default"
        block
        htmlType="submit"
        form={SAVE_STEP_FORM}
        loading={isSaving}
      >
        {t('Request a callback')}
      </Button>
      <Drawer
        title={t('Request a callback')}
        description={t(
          'Please enter you message in the field below. We will contact you as soon as possible.'
        )}
        visible={open}
        onClose={handleClose}
      >
        <Form
          name="requestCustomPricingForm"
          initialValues={{ phoneNumber: getDecodedJwt()?.phone_number }}
          onFinish={handleFinish}
        >
          <PhoneNumberFormItem name="phoneNumber" disabled />
          <Form.Item name="message" label={t('Your message')} rules={[{ required: true }]} textarea>
            <Input.TextArea rows={5} maxLength={150} />
          </Form.Item>

          <ButtonContainer>
            <Button
              id="requestACallbackButton_contactMe"
              className="RequestCustomPricing-Button"
              htmlType="submit"
              type="primary"
              loading={requestCustomPricingMutation.isLoading}
            >
              {t('Contact me')}
            </Button>
          </ButtonContainer>
        </Form>
      </Drawer>
    </>
  );
};

export const SAVE_STEP_FORM = 'saveStepForm';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 36px;
  .RequestCustomPricing-Button {
    padding-left: 60px;
    padding-right: 60px;
  }
`;

export default RequestCustomPricing;
