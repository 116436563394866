import { Coords } from 'google-map-react';

import useMutableCallback from '~/hooks/useMutableCallback';
import useReduxState from '~/hooks/useReduxState';

type ViewportCoords = { northeast: Coords; southwest: Coords };
type ViewportCountryState = {
  [country: string]: ViewportCoords;
};
export const useViewportCountryCache = (
  country: string | undefined
): [ViewportCoords | undefined, (vp: ViewportCoords) => void] => {
  const [state, setState] = useReduxState<ViewportCountryState>('viewportCountry', {});
  const setCountryCoords = useMutableCallback((viewport: ViewportCoords) => {
    if (country) setState((prev) => ({ ...prev, [country]: viewport }));
  });
  const viewportCoords = country ? state[country] : undefined;
  return [viewportCoords, setCountryCoords];
};
