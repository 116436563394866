import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { NamePath } from '~/utils/buildNamePath';
import { Form } from '..';
import LtrInput from './LtrInput';

interface Props {
  name: NamePath;
  label?: string;
  disabled?: boolean;
  [itemProps: string]: any;
}

const EmailFormItem: FC<Props> = (props) => {
  const { name, label, disabled, ...itemProps } = props;
  const { t } = useTranslation();

  return (
    <Form.Item shouldUpdate>
      {(form) => {
        const hasError = (form.getFieldError(name) || []).length > 0;
        return (
          <Form.Item
            label={label || t('Email')}
            name={name}
            help={hasError ? undefined : t('For example abc@example.com')}
            disabled={disabled}
            {...itemProps}
          >
            <LtrInput />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

export default EmailFormItem;
