import { FormInstance } from 'antd';
import { get } from 'lodash';
import { CompanyTypeReq } from '~/types/CompanyType';
import { Handbook } from '~/types/Handbook';
import { NameFn } from './buildNamePath';

export const handleCompanyType =
  (form: FormInstance<any>, handbook: Handbook, n: NameFn) =>
  (changedValues: CompanyTypeReq, formValues: any) => {
    if (get(changedValues, n(['companyType', 'businessNature']))) {
      form.setFields([
        { name: n(['companyType', 'businessIndustry']), value: undefined },
        { name: n(['companyType', 'mcc']), value: undefined },
      ]);
    }
    if (get(changedValues, n(['companyType', 'businessIndustry']))) {
      const businessNature = formValues?.companyType?.businessNature;
      const businessIndustry = formValues?.companyType?.businessIndustry;

      if (handbook && businessNature && businessIndustry) {
        form.setFields([
          {
            name: n(['companyType', 'mcc']),
            value: getMcc(handbook)({ businessNature, businessIndustry }),
          },
        ]);
      }
    }
  };

const getMcc =
  (handbook: Handbook) =>
  ({ businessNature, businessIndustry }: { businessNature: string; businessIndustry: string }) => {
    return handbook
      .find((item) => item.key === 'businessNature')
      ?.values?.find((item) => item.apiValue === businessNature)
      ?.values?.find((item) => item.key.includes('businessLine'))
      ?.values?.find((item) => item.apiValue === businessIndustry)?.additionalParams?.mcc;
  };
