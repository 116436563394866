import React, { FC } from 'react';
import { Menu, Spin } from 'antd';

import { useSetLanguage } from '~/hooks/i18nHooks';

interface Props {
  onChange?: (value: string) => void;
}

const LanguageMenu: FC<Props> = (props) => {
  const { onChange } = props;
  const { language, setLanguage, languages, languageNames, isLoading } = useSetLanguage();

  const handleMenuItemClick = ({ key }: any) => {
    setLanguage(key);
    onChange?.(key);
  };

  if (isLoading) return Loading;
  return (
    <Menu
      mode="inline"
      selectedKeys={[language]}
      selectable={false}
      onClick={handleMenuItemClick}
      className="menu-shadow"
    >
      {languages.map((lng) => {
        return <Menu.Item key={lng}>{languageNames[lng] || lng}</Menu.Item>;
      })}
    </Menu>
  );
};

const Loading = (
  <div style={{ padding: 24 }}>
    <Spin />
  </div>
);

export default LanguageMenu;
