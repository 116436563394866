import { get, isEqual } from 'lodash';
import { useMemo } from 'react';

import { NamePath } from '~/utils/buildNamePath';
import { isAgent } from '~/utils/getDecodedJwt';
import { useFormItemContext } from './FormItemContext';

export const useSoftDeclineItemContext = (name: NamePath | undefined) => {
  const formItemContext = useFormItemContext();

  const nameString = useMemo(() => {
    return [name]
      .flat()
      .map((item) => {
        if (typeof item === 'number') return '[]';
        return item;
      })
      .join('.')
      .replace('.[]', '[]');
  }, [name]);

  const warningMessage = useMemo(() => {
    if (!formItemContext.errorFields) return undefined;
    const warningObj = formItemContext.errorFields?.find((item) =>
      Object.keys(item).includes(nameString)
    );
    if (!warningObj) return undefined;
    return Object.values(warningObj)[0];
  }, [nameString, formItemContext.errorFields]);

  const isIncludedByArray = useMemo(() => {
    let isIncluded = false;
    if (formItemContext.errorFields) {
      isIncluded =
        formItemContext.errorFields.filter((item) =>
          Object.keys(item).find(
            (namePath) => namePath.endsWith('[]') && nameString.startsWith(namePath)
          )
        ).length > 0;
    }
    if (formItemContext.enabledFields) {
      isIncluded =
        isIncluded ||
        formItemContext.enabledFields.filter(
          (item) => item.endsWith('[]') && nameString.startsWith(item)
        ).length > 0;
    }
    return isIncluded;
  }, [nameString, formItemContext.errorFields, formItemContext.enabledFields]);

  const itemIsEnabled = formItemContext.enabledFields?.includes(nameString);

  const itemWithWarningMessage = !!warningMessage || warningMessage === null;
  const isFormWithErrorFields = !!formItemContext.errorFields;
  const isItemNotEditable =
    !itemIsEnabled &&
    isFormWithErrorFields &&
    !itemWithWarningMessage &&
    !isIncludedByArray &&
    !isAgent();
  const isItemEditableBySoftDecline = itemIsEnabled || isIncludedByArray || itemWithWarningMessage;
  const applicationPayloadValue = name ? get(formItemContext.applicationPayload, name) : undefined;
  const draftPayloadValue = name ? get(formItemContext.draftPayload, name) : undefined;
  const isValueUpdated = formItemContext.draftPayload
    ? !isEqual(applicationPayloadValue, draftPayloadValue)
    : false;

  return {
    warningMessage,
    isValueUpdated,
    withWarning: !!itemWithWarningMessage && !isValueUpdated,
    disabled: isItemNotEditable,
    editableBySoftDecline: isItemEditableBySoftDecline,
  };
};
