import React from 'react';
import { Form as AntdForm, FormInstance } from 'antd';
import { useSaveStep } from '~/controllers/wizard';
import { scrollToFirstError } from './Form';

interface Props {
  name: string;
  mainForm: FormInstance;
  onBeforeSave?: (values: any) => Promise<any>;
  onAfterSave?: (values: any) => Promise<any>;
  onFinishFailed?: (values: { values: any; errorFields: any; outOfDate: boolean }) => any;
  parseBeforeSave?: (values: any) => any;
}

const SaveWizardStep = (props: Props): JSX.Element => {
  const { name, mainForm, onBeforeSave, onAfterSave, onFinishFailed, parseBeforeSave } = props;
  const saveMutation = useSaveStep();

  const getFormState = () => mainForm.getFieldsValue();

  const handleSave = async () => {
    const values = getFormState();
    const result = parseBeforeSave ? parseBeforeSave(values) : values;
    try {
      await onBeforeSave?.(values);
      await mainForm.validateFields();
      await saveMutation.mutateAsync(result);
      await onAfterSave?.(values);
    } catch (error) {
      onFinishFailed?.(error as any);
      if (Array.isArray((error as any).errorFields)) {
        const { errorFields } = error as any;
        mainForm.scrollToField(errorFields[0].name, scrollToFirstError);
      }
      return Promise.reject();
    }
    return Promise.resolve();
  };

  return <AntdForm name={name} onFinish={handleSave} />;
};

export default SaveWizardStep;
