import React, { FC } from 'react';
import { Button, Typography } from 'antd';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import CompanyTypeTitle from '~/layouts/CompanyTypeTitle';
import paths from '~/pages/paths';
import { UnorderedList } from '~/components';
import colors from '~/constants/colors';

const LetsStart: FC = () => {
  const history = useHistory();
  const onClick = () => {
    history.push(paths.home._);
  };

  const { t } = useTranslation();

  return (
    <Container className="CompanyInformationSuccess">
      <CompanyTypeTitle text={t('Get started')} subtext={t('with Network International')} />
      <Typography.Paragraph className="CompanyInformationSuccess-Paragraph">
        <p>{t('Excellent!')}</p>
        <p>
          {t(
            'Now we know a little bit more about you and your business, we can now move onto the full application process.'
          )}
        </p>
        <p>{t('This process should take no more than 30 minutes.')}</p>
        {t(
          'You can also leave at any point and return by using the same email address you provided to us at the start.'
        )}
      </Typography.Paragraph>
      <Button
        id="letsStartButton"
        className="CompanyInformationSuccess-Button"
        size="large"
        type="primary"
        onClick={onClick}
      >
        {t('Let’s start!')}
      </Button>
      <UnorderedList
        title={t('For successful completion you will need the following documents:')}
        values={[
          t('Copy of Company or Trade License'),
          t('Passport copy of authorised signatory with Visa'),
          t('Emirates ID and documents of all partners in business'),
          t('Copy of Memorandum/POA for authorised signatory'),
          t('Copy of Bank Statement with IBAN number'),
          t('Tax registration certificate'),
        ]}
      />
    </Container>
  );
};

const Container = styled.div`
  .CompanyInformationSuccess-Paragraph {
    margin-bottom: 40px;
  }
  .CompanyInformationSuccess-Button {
    margin-bottom: 35px;
  }
  .UnorderedList {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
    color: ${colors.disabledBlueTextColor};
  }
`;

export default LetsStart;
