import React, { FC } from 'react';
import { Tag, Typography } from 'antd';
import styled from 'styled-components';
import { AgentLeadStatus } from '~/types/AgentLead';
import { statusColor } from '~/components/views/agent/tableElements/cells/StatusCell';
import theme from '~/constants/theme';

interface Props {
  title?: string;
  statusKey?: AgentLeadStatus;
  status?: string;
}

const LeadStatusTag: FC<Props> = (props) => {
  const { title, statusKey, status } = props;
  return (
    <Container>
      <Typography.Title>{title}</Typography.Title>
      {statusKey && <Tag color={statusColor(statusKey)}>{status}</Tag>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .ant-typography {
    margin: 0 24px 0 0;
  }

  .ant-tag {
    border-radius: 12px;
    font-weight: 400;
  }

  @media (max-width: ${theme.breakpoints.sm}px) {
    flex-direction: column;
    align-items: flex-start;
    .ant-tag {
      margin: 12px 0;
    }
  }
`;

export default LeadStatusTag;
