/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import { Col, ColProps, Row, RowProps } from 'antd';

import colors from '~/constants/colors';
import { ArrowRightIcon } from '~/img';
import CardMobile from './CardMobile';

interface Props {
  className?: string;
  gutter?: RowProps['gutter'];
  span?: ColProps['span'];
  visibleCount?: number;
  expandable?: boolean;
}

const ExpandibleCardMobile: FC<Props> = (props) => {
  const {
    children,
    className,
    gutter = [8, 12],
    span = 8,
    visibleCount = 3,
    expandable = true,
  } = props;
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <Container open={open} className={cx('ExpandibleCardMobile', className)}>
      <Row gutter={gutter}>
        {Array.isArray(children) &&
          children.map((ch, index) => {
            if (!open && index >= visibleCount && expandable) return null;
            return (
              <Col key={`TableCardMobile_${index}`} span={span}>
                {ch}
              </Col>
            );
          })}
      </Row>
      {expandable && (
        <div role="button" onClick={toggle} className="ExpandibleCardMobile-OpenContainer">
          <ArrowRightIcon className="ExpandibleCardMobile-CheckIcon" />
        </div>
      )}
    </Container>
  );
};

interface UIProps {
  open: boolean;
  [x: string]: any;
}

const Container = styled<FC<UIProps>>(CardMobile as any)`
  .ant-row {
    flex: 1;
  }
  .TextField {
    .anticon {
      color: ${colors.blue[1100]};
    }
  }
  .ExpandibleCardMobile-OpenContainer {
    padding: 8px;
    margin: -8px;
    .ExpandibleCardMobile-CheckIcon {
      transition: transform 0.24s;
      color: ${colors.blue[1100]};
      transform: ${({ open }) => (open ? 'rotate(-90deg)' : 'rotate(90deg)')};
    }
  }
`;

export default ExpandibleCardMobile;
