import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import FileSaver from 'file-saver';
import { message, Typography } from 'antd';

import { Button, ButtonCard } from '~/components';
import { DownloadIcon, PdfIcon } from '~/img';
import humanFileSize from '~/utils/humanFileSize';
import colors from '~/constants/colors';
import theme from '~/constants/theme';
import direction from '~/utils/direction';
import { useDirection } from '~/hooks/i18nHooks';

interface Props {
  name: string;
  data: Blob | undefined;
}

const SaveContract: FC<Props> = (props) => {
  const { name, data } = props;

  const { t } = useTranslation('contract');
  const dir = useDirection();

  const handleSaveClick = () => {
    if (data) {
      FileSaver.saveAs(data, name);
    } else {
      void message.warn('There is no contract data');
    }
  };

  return (
    <ButtonCard>
      <Container dir={dir}>
        <div className="SaveContract-AboutFileContainer">
          <div className="SaveContract-FileNameContainer">
            <PdfIcon className="SaveContract-Icon" />
            <Typography.Text ellipsis id="saveContract_fileName">
              {name}
            </Typography.Text>
          </div>
          {data && <span className="SaveContract-FileSize">({humanFileSize(data.size)})</span>}
        </div>
        <Button
          id="saveContract_downloadButton"
          onClick={handleSaveClick}
          icon={<DownloadIcon />}
          type="default"
        >
          {t('Download contract')}
        </Button>
      </Container>
    </ButtonCard>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  .SaveContract-AboutFileContainer {
    display: flex;
    .SaveContract-FileNameContainer {
      display: flex;
      align-items: center;
      max-width: 377px;
      @media (max-width: ${theme.breakpoints.xl + 32}px) {
        max-width: calc(50vw - 342px);
      }
      @media (max-width: ${theme.breakpoints.lg}px) {
        max-width: calc(70vw - 360px);
      }
      .SaveContract-Icon {
        ${direction.margin.right(8)};
      }
    }
    .SaveContract-FileSize {
      color: ${colors.disabledBlueTextColor};
      margin-right: 8px;
    }
  }
  @media (max-width: ${theme.breakpoints.sm}px) {
    flex-direction: column;
    .SaveContract-AboutFileContainer {
      max-width: calc(100vw - 100px);
      margin-bottom: 16px;
      .SaveContract-FileNameContainer {
        max-width: calc(100vw - 160px);
      }
    }
  }
`;

export default SaveContract;
