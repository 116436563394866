import React, { FC } from 'react';
import { Input, InputNumber, Typography } from 'antd';
import { get, merge } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import produce from 'immer';

import {
  Checkbox,
  CountryInput,
  DateInput,
  DocumentInput,
  EmiratesIdInput,
  Form,
  NationalitySelect,
  PhoneNumberFormItem,
} from '~/components';
import InputsGroupLayout from '~/layouts/InputsGroupLayout';
import { ApplicationPayload } from '~/types/ApplicationPayload';
import { NameFn } from '~/utils/buildNamePath';
import createUid from '~/utils/createUid';
import Section from '~/layouts/Section ';
import { useValidator } from '~/controllers/validation';
import { FormItemContextProvider } from '~/components/Form/FormItemContext';
import { useGetApplication } from '~/controllers/wizard';
import { useGetDraft } from '~/controllers/draft';
import { Draft } from '~/types/Draft';
import { useOptionalFields } from '~/hooks/useOptionalFields';
import { isReadOnly } from '~/utils/isReadOnly';
import { getStagePayload } from '~/utils/getStagePayload';
import CustomFields from '~/components/CustomFields';

interface Props {
  n: NameFn;
  onFinish: (values: ApplicationPayload) => any;
  initialValues?: any | undefined;
}

const IndividualForm: FC<Props> = (props) => {
  const { onFinish, initialValues, n } = props;
  const { t } = useTranslation('fillApplication');

  const [form] = Form.useForm<ApplicationPayload>();
  const getFormState = () => {
    return produce(initialValues || {}, (draft: any) => {
      merge(draft, form.getFieldsValue());
    });
  };
  const validatorController = useValidator(getFormState);
  const applicationQuery = useGetApplication();
  const draftQuery = useGetDraft<Draft<any>>();

  const optionalFieldsController = useOptionalFields({ initialValues });

  const stagePayload = getStagePayload.wizard(applicationQuery.data);

  return (
    <FormItemContextProvider
      value={{
        defaultValidator: validatorController.validator,
        applicationPayload: applicationQuery.data?.payload,
        draftPayload: draftQuery.data,
        optionalFields: optionalFieldsController.optionalFields || {},
        errorFields: stagePayload?.activeStep.errorInformation?.errorFields,
        enabledFields: stagePayload?.activeStep.errorInformation?.enabledFields,
        disabledByDefault: isReadOnly(applicationQuery.data),
        disabled: isReadOnly(applicationQuery.data),
      }}
    >
      <Container className="IndividualAdd">
        <Form
          form={form}
          name={formName}
          onFinish={onFinish}
          onValuesChange={(_, newValues) => {
            optionalFieldsController.debouncedUpdate(newValues);
          }}
          initialValues={initialValues}
        >
          <Form.Item
            name={n(['data', 'uid'])}
            hidden
            initialValue={get(initialValues, n(['data', 'uid'])) || createUid()}
          />
          <Section>
            <Typography.Title level={4}>{t('Personal data').toUpperCase()}</Typography.Title>
            <InputsGroupLayout>
              <Form.Item label={t('First name')} name={n(['data', 'firstName'])}>
                <Input />
              </Form.Item>
              <Form.Item label={t('Last name')} name={n(['data', 'lastName'])}>
                <Input />
              </Form.Item>
              <Form.Item label={t('Date of birth')} name={n(['data', 'birthDate'])}>
                <DateInput disableDateRule="date <= today" />
              </Form.Item>
              <Form.Item label={t('Nationality')} name={n(['data', 'nationality'])}>
                <NationalitySelect />
              </Form.Item>
            </InputsGroupLayout>
            <Form.Item name={n(['data', 'isPep'])} valuePropName="checked">
              <Checkbox>{t('Shareholder is a Politically Exposed Person (PEP)')}</Checkbox>
            </Form.Item>
          </Section>

          <Section>
            <Typography.Title level={4}>{t('Passport').toUpperCase()}</Typography.Title>

            <Typography.Paragraph>
              <Trans
                t={t}
                defaults="Please upload the <strong>passport main page</strong> photo and provide the details in the fields below."
              />
            </Typography.Paragraph>
            <Form.Item name={n(['data', passport, 'files'])}>
              <DocumentInput name="photos" variant="default" multiple={false} />
            </Form.Item>

            <Typography.Paragraph>
              <Trans
                t={t}
                defaults="Please upload the <strong>visa page</strong> photo and provide the details in the fields below."
              />
            </Typography.Paragraph>
            <Form.Item name={n(['data', 'visa', 'files'])}>
              <DocumentInput name="photos" variant="default" multiple={false} />
            </Form.Item>

            <Typography.Title level={5}>{t('Document data:')}</Typography.Title>
            <InputsGroupLayout>
              <Form.Item
                label={t('Document number')}
                name={n(['data', passport, 'documentNumber'])}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('Passport expiry date')}
                name={n(['data', passport, 'expiryDate'])}
              >
                <DateInput disableDateRule="date > today" />
              </Form.Item>
              <Form.Item label={t('Visa expiry date')} name={n(['data', 'visa', 'expiryDate'])}>
                <DateInput disableDateRule="date > today" />
              </Form.Item>
            </InputsGroupLayout>
          </Section>

          <Section formItemLast>
            <Typography.Title level={4}>{t('Emirates ID').toUpperCase()}</Typography.Title>

            <Typography.Paragraph>
              <Trans
                t={t}
                defaults="Please upload <strong>front side</strong> and <strong>back side</strong> Emirates ID photos."
              />
            </Typography.Paragraph>
            <Form.Item name={n(['data', emiratesId, 'files'])}>
              <DocumentInput name="photos" variant="default" maxCount={2} />
            </Form.Item>

            <Typography.Title level={5}>{t('Document data:')}</Typography.Title>
            <InputsGroupLayout>
              <Form.Item
                label={t('Emirates ID number')}
                name={n(['data', emiratesId, 'documentNumber'])}
              >
                <EmiratesIdInput />
              </Form.Item>
              <Form.Item
                label={t('Emirates ID expiry date')}
                name={n(['data', emiratesId, 'expiryDate'])}
              >
                <DateInput disableDateRule="date > today" />
              </Form.Item>
            </InputsGroupLayout>
          </Section>

          <Section formItemLast>
            <Typography.Title level={4}>
              {t('Residency & contact details').toUpperCase()}
            </Typography.Title>
            <InputsGroupLayout>
              <Form.Item label={t('Country')} name={n(['data', 'country'])}>
                <CountryInput />
              </Form.Item>
              <Form.Item label={t('City')} name={n(['data', 'city'])}>
                <Input autoComplete="none" />
              </Form.Item>
              <Form.Item label={t('Address line')} name={n(['data', 'addressLine'])}>
                <Input autoComplete="none" />
              </Form.Item>
              <Form.Item label={t('P.O. Box')} name={n(['data', 'poBox'])}>
                <Input autoComplete="none" />
              </Form.Item>
              <PhoneNumberFormItem name={n(['data', 'phoneNumber'])} />
            </InputsGroupLayout>
          </Section>

          <section>
            <CustomFields nFn={(name) => n(['data', ...[name].flat()])} />
          </section>

          <section>
            <Typography.Title level={4}>{t('Shares').toUpperCase()}</Typography.Title>
            <Typography.Paragraph>{t('Please specify ownership share')}</Typography.Paragraph>
            <InputsGroupLayout>
              <Form.Item label={t('Shares, %')} name={n('shares')}>
                <InputNumber className="IndividualAdd-SharesInput" max={100} />
              </Form.Item>
            </InputsGroupLayout>
          </section>
        </Form>
      </Container>
    </FormItemContextProvider>
  );
};

export const formName = 'addNewIndividualShareholder';

const passport = 'passport';
const emiratesId = 'emiratesId';

const Container = styled.div`
  .IndividualAdd-SharesInput {
    width: 100%;
  }
`;

export default IndividualForm;
