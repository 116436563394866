import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ColumnsType } from 'antd/lib/table';
import classNames from 'classnames';
import { useGetApplicationsList, useTerminateApplication } from '~/controllers/agent';
import PageHeader from '~/components/views/agent/PageHeader';
import AgentTable from '~/components/views/agent/tableElements/AgentTable';
import {
  companyNameCell,
  emirateCell,
  businessLineCell,
  createdCell,
  updatedCell,
  statusCell,
  riskLevelCell,
  requestCell,
  assigneeCell,
} from '~/components/views/agent/tableElements/cells';
import { AgentApplication } from '~/types/AgentApplication';
import { openNewApplicationTab } from '~/utils/goToApplication';
import { getDecodedJwt, isTeamLead } from '~/utils/getDecodedJwt';
import AgentTableActions from '~/components/views/agent/AgentTableActions';
import TerminationDrawer from './TerminationDrawer';
import AgentFilters from './filters/AgentFilters';
import { useHandbook } from '~/controllers/handbook';
import AgentFiltersButton from '~/components/views/agent/filters/AgentFiltersButton';
import { useIsTablet } from '~/hooks/useScreenSizes';
import ApplicationShow from '~/components/views/agent/applicationForm/ApplicationShow';

const AgentApplicationTable = () => {
  const { data, isFetching, total, refetch } = useGetApplicationsList();
  const { t } = useTranslation('agent');
  const terminateApplication = useTerminateApplication();
  const [openApplication, setOpenApplication] = useState(false);
  const [terminatedOpen, setTerminatedOpen] = useState(false);
  const [savedRecord, setSavedRecord] = useState<AgentApplication | undefined>(undefined);
  const { data: handbook } = useHandbook();

  const columns = useMemo<ColumnsType<AgentApplication>>(
    () => [
      companyNameCell(),
      emirateCell(handbook),
      riskLevelCell(),
      businessLineCell(handbook),
      createdCell(),
      updatedCell(),
      requestCell(),
      statusCell(),
      ...(isTeamLead() ? [assigneeCell('APPLICATION')] : []),
      {
        title: '',
        key: 'actions',
        render: (_, record) => (
          <AgentTableActions
            actions={[
              {
                key: 'agentApplicationTableActions-Terminate',
                title: t('Terminate'),
                disabled: record?.statusKey === 'terminated',
                action: () => {
                  setSavedRecord(record);
                  setTerminatedOpen(true);
                },
              },
            ]}
          />
        ),
        width: 30,
      },
    ],
    [t, handbook]
  );

  const currUserEmail = getDecodedJwt()?.email;
  const isTablet = useIsTablet();

  return (
    <Container>
      <PageHeader heading={t('applications')} isFetching={isFetching}>
        {!isTablet ? <AgentFilters /> : <AgentFiltersButton />}
      </PageHeader>
      <AgentTable<AgentApplication>
        id="agentApplicationTable"
        type="APPLICATION"
        rowKey={(record) => record.applicationId}
        dataSource={data}
        columns={columns}
        tableRefetch={refetch}
        totalPages={total}
        rowClassName={(record) =>
          classNames({
            'AgentApplicationTable-RowPointer': record.assignee?.email === currUserEmail,
          })
        }
        onRow={(record) => ({
          onClick: () => {
            if (isTablet) {
              setSavedRecord(record);
              setOpenApplication(true);
            } else if (record.assignee?.email === currUserEmail) {
              openNewApplicationTab(record.applicationId);
            }
          },
        })}
      />
      <ApplicationShow
        record={savedRecord}
        open={openApplication}
        onClose={() => setOpenApplication(false)}
      />
      <TerminationDrawer
        visible={terminatedOpen}
        companyName={savedRecord?.companyName}
        onTerminate={async (terminationReason) => {
          if (savedRecord?.applicationId) {
            await terminateApplication.mutateAsync({
              id: savedRecord.applicationId,
              terminationReason,
            });
            await refetch();
          }
        }}
        onClose={() => {
          setTerminatedOpen(false);
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;

  .AgentApplicationTable-RowPointer {
    cursor: pointer;
  }
`;

export default AgentApplicationTable;
