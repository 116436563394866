import React, { FC } from 'react';
import { NamePath } from 'antd/lib/form/interface';
import { useTranslation } from 'react-i18next';
import { toPath } from 'lodash';

import DebugAlert from '~/components/DebugAlert';
import Form from '~/components/Form';
import PriceInput from '~/components/PriceInput';
import PriceRowBase from '~/components/PriceRowBase';
import { PricingServiceField, ServicesTypes } from '~/types/Services';
import buildNamePath, { NameFn } from '~/utils/buildNamePath';
import { getTotalFeePricingValue, withRole } from './utils';
import { useFormItemContext } from '~/components/Form/FormItemContext';
import { useServicesContext } from '~/controllers/ServicesContext';

export interface TerminalFeeFormItemProps {
  productMapN: NameFn;
  productMapTempN: NameFn;
  terminalFeeCountName: NamePath;
  serviceType: Exclude<ServicesTypes, 'e-commerce'>;
  field: PricingServiceField;
}

const TerminalFeeFormItem: FC<TerminalFeeFormItemProps> = (props) => {
  const { productMapN, productMapTempN, terminalFeeCountName, serviceType, field } = props;
  const { t } = useTranslation('fillApplication');
  const totalFeeName = productMapTempN(['terminalFee']);
  const formItemContext = useFormItemContext();
  const servicesContext = useServicesContext();
  const inputNameString = toPath(productMapN([field.key])).join('.');
  const unableToChangeTotalPrice = servicesContext.disabledFields[inputNameString];

  return (
    <>
      <Form.Item name={productMapN(['terminalFee', 'type'])} hidden />
      <Form.Item name={productMapN(['terminalFee', 'amount'])} hidden />
      <Form.ShouldUpdate name={terminalFeeCountName}>
        {(form) => {
          const count: number = form.getFieldValue(terminalFeeCountName) || 1;

          return (
            <PriceRowBase
              title={
                serviceType === 'pos' ? (
                  t('Total for all terminals')
                ) : serviceType === 'soft-pos' || serviceType === 'my-service' ? (
                  t('Total for all applications')
                ) : (
                  <DebugAlert message={`Unknown service type: ${serviceType}`} />
                )
              }
            >
              <PriceInput
                n={buildNamePath(totalFeeName)}
                disabled={
                  !withRole(field.editable) ||
                  formItemContext.disabledByDefault ||
                  unableToChangeTotalPrice
                }
                defaultValue={getTotalFeePricingValue(field.value.defaultValue, count)}
                savedCustomValue={
                  field.value.customValue
                    ? getTotalFeePricingValue(field.value.customValue, count)
                    : undefined
                }
                validation={field.validation}
                affectsPrice={field.affectsPrice}
              />
            </PriceRowBase>
          );
        }}
      </Form.ShouldUpdate>
    </>
  );
};

export default TerminalFeeFormItem;
