import React, { FC, useEffect } from 'react';
import { SizeMeProps, withSize } from 'react-sizeme';

import { useMutableCallback } from '~/hooks';

interface Props extends Partial<SizeMeProps> {
  onWidthChange?: (width: SizeMeProps['size']['width']) => void;
  onHeightChange?: (width: SizeMeProps['size']['height']) => void; // don't call as long as "monitorHeight: false" in withSize
}

const SizeMeHOC: FC<Props> = (props) => {
  const { children, size, onWidthChange, onHeightChange } = props;

  const width = size?.width;
  const height = size?.height;

  const onWidthChangeCallback = useMutableCallback((w: SizeMeProps['size']['width']) =>
    onWidthChange?.(w)
  );
  const onHeightChangeCallback = useMutableCallback((w: SizeMeProps['size']['height']) =>
    onHeightChange?.(w)
  );

  useEffect(() => {
    if (width !== undefined) {
      onWidthChangeCallback(width);
    }
  }, [onWidthChangeCallback, width]);
  useEffect(() => {
    if (height !== undefined) {
      onHeightChangeCallback(height);
    }
  }, [onHeightChangeCallback, height]);

  return <>{children}</>;
};

export default withSize({ refreshMode: 'debounce', monitorHeight: false })(SizeMeHOC);
