import { PaymentMethod } from '~/types/PaymentMethods';
import { PaymentStagePayload } from '~/types/PaymentStagePayload';

export const getErrorFields = (errors: string[] | undefined) => {
  if (!errors) return undefined;
  const errorFields: { [namePath: string]: string | null }[] = [];
  errors.forEach((errorMessage) => {
    if (errorMessage === 'Please upload a clear copy of payment of proof.') {
      errorFields.push({ invoices: null });
    }
    if (
      errorMessage === "Please check your payment information, it doesn't match with the documents."
    ) {
      errorFields.push({ invoices: null });
      errorFields.push({
        paymentDate: 'Please make sure this information corresponds to documents.',
      });
      errorFields.push({
        paymentReference: 'Please make sure this information corresponds to documents.',
      });
    }
  });
  return errorFields;
};

export const isPaymentMethodDisabled = (props: {
  stagePayload: PaymentStagePayload | undefined;
  paymentMethod: PaymentMethod['type'];
  methodChangeAllowed: boolean;
  alreadyPaid: boolean;
  readOnly: boolean;
}): boolean => {
  const { stagePayload, paymentMethod, methodChangeAllowed, alreadyPaid, readOnly } = props;
  if (!stagePayload) return false;
  if (!stagePayload.paymentDetails) return false;
  const payloadPaymentMethod = stagePayload.paymentDetails.paymentMethod;
  if (payloadPaymentMethod === paymentMethod) {
    return false;
  }
  if (alreadyPaid && payloadPaymentMethod !== paymentMethod) return true;
  return !methodChangeAllowed || readOnly;
};
