const defaults = {
  i18n: {
    userLanguages: [
      { code: 'en', name: 'English US' },
      { code: 'ar', name: 'عربي' },
    ],
    defaultLng: undefined, // Language to use (overrides language detection).
    fallbackLng: 'en', // Language to use if translations in user language are not available.
  },
  homeLink: 'https://www.network.ae/',
  contactUsLink: 'https://www.network.ae/en/contactus/enquirypopup',
  contactUsLinkServices:
    'https://www.network.ae/en/contactus/enquirypopup?utm_source=SO-form&utm_medium=industry&subject=Oboarding%20assistance%20required',
  contactUsLinkWizardApplication:
    'https://www.network.ae/en/contactus/enquirypopup?utm_source=SO-form&utm_medium=industry&subject=Oboarding%20assistance%20required',
  contactUsLinkContract: 'https://www.network.ae/en/contactus/enquirypopup',
  contactUsLinkPayment: 'https://www.network.ae/en/contactus/enquirypopup',
  contactUsLinkScreeningError: 'https://www.network.ae/en/contactus/enquirypopup',
  contactUsLinkScreeningDecline: 'https://www.network.ae/en/contactus/enquirypopup',
  contactUsLinkError: 'https://www.network.ae/en/contactus/enquirypopup',
  contactUsLinkIndustry:
    'https://www.network.ae/en/contactus/enquirypopup?utm_source=SO-form&utm_medium=industry&subject=Oboarding%20assistance%20required',
  contactUsLinkBranch:
    'https://www.network.ae/en/contactus/enquirypopup?utm_source=SO-form&utm_medium=onboard%20multiple%20location&subject=Need%20additional%20Branch',
  contactUsLinkSubmit:
    'https://www.network.ae/en/contactus/enquirypopup?utm_source=SO-form&utm_medium=form%20completed&subject=Application%20is%20submitted',
  contactUsLinkSalesforceError:
    'https://www.network.ae/en/contactus/enquirypopup?utm_source=SO-form&utm_medium=%20industry&%20subject=Oboarding%20assistance%20required',
  contactUsLinkSalesforceTechnicalError:
    'https://www.network.ae/en/contactus/enquirypopup?utm_source=SO-form&utm_medium=%20industry&%20subject=Oboarding%20assistance%20required',
  contactUsLinkSoftDecline:
    'https://www.network.ae/en/contactus/enquirypopup?utm_source=SO-form&utm_medium=form%20completed&subject=Application%20is%20submitted',
  contactUsLinkHardDecline:
    'https://www.network.ae/en/contactus/enquirypopup?utm_source=SO-form&utm_medium=form%20completed&subject=Application%20is%20rejected',
  documentFileInput: {
    maxSizeB: 10 * 1024 * 1024,
    allowedFileTypes: ['.jpeg', '.jpg', '.png', '.pdf', '.doc', '.docx', '.gif', '.rtf'],
    maxCount: 50,
  },
  defaultCountry: 'AE',
  defaultCountrySalesforce: 'United Arab Emirates', // TODO: country data refactoring
  mapDefaultCenter: { lat: 23.424076, lng: 53.847818 }, // AE
  tenantId: 'ni',
  realm: 'marketplace-signup',
};

module.exports = defaults;
