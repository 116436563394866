import React, { FC } from 'react';
import styled from 'styled-components';

import colors from '~/constants/colors';

const NoteText: FC = (props) => {
  const { children } = props;
  return <Container className="NoteText">{children}</Container>;
};

const Container = styled.span`
  color: ${colors.disabledBlueTextColor};
`;

export default NoteText;
