import { useMutation } from 'react-query';
import { companyType } from '~/api/companyType';
import { useUpdateGetApplicationCache } from './wizard';

export const useCompanyTypeMutation = () => {
  const updateCache = useUpdateGetApplicationCache();

  return useMutation(companyType, {
    onSuccess(res) {
      updateCache(res);
    },
  });
};
