import React, { FC } from 'react';
import { Button as AntdButton, ButtonProps as AntdButtonProps } from 'antd';
import { EditIcon } from '~/img';
import colors from '~/constants/colors';
import { useDirection } from '~/hooks/i18nHooks';

interface Props extends AntdButtonProps {
  disabled: boolean;
  hasValidationWarnings: boolean;
}

const EditButton: FC<Props> = (props) => {
  const { hasValidationWarnings, ...rest } = props;
  const dir = useDirection();
  return (
    <AntdButton
      size="middle"
      type="default"
      style={hasValidationWarnings ? waningStyle : undefined}
      icon={<EditIcon dir={dir} />}
      {...rest}
    />
  );
};

const waningStyle = { color: colors.warningColor, borderColor: colors.warningColor };

export default EditButton;
