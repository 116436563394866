import React, { FC } from 'react';
import { Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import WizardContent from '~/layouts/WizardContent';
import { SuccessfulOnboardingImg } from '~/img';
import features from '~/constants/features';
import LeftLayout from '~/layouts/LeftLayout';
import BottomLeftActions from '~/layouts/BottomLeftActions';

const SuccessfulOnboarding: FC = () => {
  const { t } = useTranslation('stageResult');

  return (
    <WizardContent
      left={
        <LeftLayout>
          <BottomLeftActions contactUsUrl={features.contactUsLink} />
        </LeftLayout>
      }
      content={
        <Container className="SuccessfulOnboarding">
          <Typography.Title>{t('Application approved!')}</Typography.Title>
          <Typography.Paragraph>
            <p>{t('Welcome to the Network family.')}</p>
            <p>
              {t(
                'We will soon be sending you our welcome kit which will explain the next steps to enable you to start taking payments.'
              )}
            </p>
            <p>
              <Trans
                t={t}
                i18nKey="In the meantime, if you wish to speak to us, please <1>contact us</1>."
              >
                {'In the meantime, if you wish to speak to us, please '}
                <a href={features.contactUsLink} target="__blank">
                  contact us
                </a>
                .
              </Trans>
            </p>
          </Typography.Paragraph>
          <SuccessfulOnboardingImg className="SuccessfulOnboarding-Img" />
        </Container>
      }
      right={null}
    />
  );
};

const Container = styled.div`
  .SuccessfulOnboarding-Img {
    margin-top: 36px;
    border-radius: 14px;
    width: 100%;
  }
`;

export default SuccessfulOnboarding;
