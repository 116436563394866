import React, { FC } from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Form from '~/components/WizardForm';
import { DocumentInput, Checkbox, Collapse, Select } from '~/components';
import ShareTables from './ShareTables';
import i18n from '~/i18n';

const ShareholdersMultiple: FC = () => {
  const { t } = useTranslation('fillApplication');

  const [form] = Form.useForm();
  return (
    <Container className="ShareholdersMultiple">
      <Form form={form} stepKey="shareholders" handleDraftDataBeforeSave={(v) => v}>
        <Collapse className="ShareholdersMultiple-Collapse" defaultActiveKey={['1']} ghost>
          <Collapse.Panel header={t('Shareholder Proof Document')} key="1">
            <Form.Item name={n(['proof', 'documentType'])} label={t('Select the document type')}>
              <Select options={getProofDocumentOptions()} />
            </Form.Item>
            <Form.Visible
              name={n(['proof', 'documentType'])}
              visibleIf={copyMemorandumOrPartnership}
            >
              <div className="ShareholdersMultiple-CheckboxItem">
                <Form.Item
                  name={n(['proof', 'memorandumOfAssociation', 'checked'])}
                  valuePropName="checked"
                  noStyle
                >
                  <Checkbox>
                    <strong>{t('Memorandum of Association')}</strong>
                  </Checkbox>
                </Form.Item>
              </div>

              <Form.Visible name={n(['proof', 'memorandumOfAssociation', 'checked'])}>
                <Form.Item name={n(['proof', 'memorandumOfAssociation', 'files'])}>
                  <DocumentInput name="photos" variant="default" />
                </Form.Item>
              </Form.Visible>

              <div className="ShareholdersMultiple-CheckboxItem">
                <Form.Item
                  name={n(['proof', 'partnershipDeed', 'checked'])}
                  valuePropName="checked"
                  noStyle
                >
                  <Checkbox>
                    <strong>{t('Partnership deed')}</strong>
                  </Checkbox>
                </Form.Item>
              </div>

              <Form.Visible name={n(['proof', 'partnershipDeed', 'checked'])}>
                <Form.Item name={n(['proof', 'partnershipDeed', 'files'])}>
                  <DocumentInput name="photos" variant="default" />
                </Form.Item>
              </Form.Visible>
            </Form.Visible>
          </Collapse.Panel>
        </Collapse>

        <Typography.Title level={4}>{t('Add shareholders').toUpperCase()}</Typography.Title>
        <Typography.Paragraph>
          {t('Please add all shareholders with total share of 25% or more.')}
        </Typography.Paragraph>

        <ShareTables />

        <Form.Item hidden name={['shareholders', 'authorizedRepresentative']} />
        <Form.Item hidden name={['shareholders', 'individuals']} />
        <Form.Item hidden name={['shareholders', 'beneficialOwners']} />
        <Form.Item hidden name={['shareholders', 'businesses']} />
      </Form>
    </Container>
  );
};

export const shareholdersKey = 'shareholders';

const n = (namePath: string | string[]) => [shareholdersKey].concat([namePath].flat());

const copyMemorandumOrPartnership = 'Upload Copy of Memorandum of Association or Partnership deed';
const getProofDocumentOptions = () => [
  {
    label: i18n.t('Shareholders are listed in company license', { ns: 'fillApplication' }),
    value: 'Shareholders are listed in company license',
  },
  {
    label: i18n.t(copyMemorandumOrPartnership, { ns: 'fillApplication' }),
    value: copyMemorandumOrPartnership,
  },
];

const Container = styled.div`
  .ShareholdersMultiple-Collapse {
    margin: -16px;
    margin-bottom: 32px;
    .ant-select-selector {
      max-width: calc(100vw - ${16 * 2 + 8 * 2}px);
    }
  }
  .ShareholdersMultiple-CheckboxItem {
    width: 100%;
    margin-bottom: 16px;
  }
`;

export default ShareholdersMultiple;
