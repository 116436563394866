import React, { FC } from 'react';
import { SelectProps, Select as AntdSelect } from 'antd';
import { useTranslation } from 'react-i18next';
import { AgentApplicationStatus } from '~/types/AgentApplication';
import { AgentLeadStatus } from '~/types/AgentLead';
import { MapItemType } from '~/types/AgentMapItem';
import { useIsTablet } from '~/hooks/useScreenSizes';

const applicationStatus: Record<string, AgentApplicationStatus> = {
  duplicate: 'duplicate',
  draft: 'draft',
  terminated: 'terminated',
  fillingData: 'fillingData',
  rejected: 'rejected',
  screeningFailed: 'screeningFailed',
  onReview: 'onReview',
  approval: 'approval',
  onboarding: 'onboarding',
  posPreparation: 'posPreparation',
  posInstallation: 'posInstallation',
  completed: 'completed',
  hardRejected: 'hardRejected',
  screening: 'screening',
  contract: 'contract',
  payment: 'payment',
  submissionFailure: 'submissionFailure',
};

const leadStatus: Record<string, AgentLeadStatus> = {
  new: 'NEW',
  contacted: 'CONTACTED',
  terminated: 'TERMINATED',
  converted: 'CONVERTED',
};

const applicationOptionsElements = (t: any) =>
  Object.values(applicationStatus).map((value) => (
    <AntdSelect.Option key={value} value={value}>
      {t(value, { ns: 'applicationStatus' })}
    </AntdSelect.Option>
  ));

const leadOptionsElements = (t: any) =>
  Object.values(leadStatus).map((value) => (
    <AntdSelect.Option key={value} value={value}>
      {t(value, { ns: 'leadStatus' })}
    </AntdSelect.Option>
  ));

interface Props extends SelectProps<string> {
  statusType?: MapItemType;
}

const StatusSelect: FC<Props> = (props) => {
  const { statusType = MapItemType.ALL, ...rest } = props;
  const { t } = useTranslation('agent');
  const isTablet = useIsTablet();

  return (
    <AntdSelect mode="multiple" showSearch={!isTablet} {...rest}>
      {statusType === MapItemType.ALL ? (
        <>
          <AntdSelect.OptGroup label={t('APPLICATION')}>
            {applicationOptionsElements(t)}
          </AntdSelect.OptGroup>
          <AntdSelect.OptGroup label={t('LEAD')}>{leadOptionsElements(t)}</AntdSelect.OptGroup>
        </>
      ) : statusType === MapItemType.APPLICATION ? (
        applicationOptionsElements(t)
      ) : statusType === MapItemType.LEAD ? (
        leadOptionsElements(t)
      ) : null}
    </AntdSelect>
  );
};

export default StatusSelect;
