import { RuleObject } from 'antd/lib/form';

import { basicValidation } from './basicValidation';
import i18n from '~/i18n';

export type Validator = RuleObject['validator'];

interface Options {
  allowArabic?: boolean;
  withInnerSpaces?: boolean;
}

export const textOnlyValidation: (options?: Options) => Validator = (options) =>
  basicValidation((rule, value, callback) => {
    const o = options || {};
    const allowArabic = !!o.allowArabic;
    const withInnerSpaces = !!o.withInnerSpaces;

    try {
      for (let i = 0; i < value.length; i++) {
        const currentChar = value[i];
        const code = currentChar.charCodeAt(0);
        if (withInnerSpaces && currentChar === ' ') {
          const isFirst = i === 0;
          const isLast = i === value.length - 1;
          if (isFirst || isLast) {
            throw new Error(
              i18n.t("Character ' ' (space) should not be first or last character", {
                ns: 'validation',
              })
            );
          }
          const prevChar = isFirst ? undefined : value[i - 1];
          if (prevChar === ' ') {
            throw new Error(
              i18n.t("Character ' ' (space) should not appear two or more times consecutively", {
                ns: 'validation',
              })
            );
          }
        } else if (code >= 1536 && code <= 1791 && !allowArabic) {
          throw new Error(i18n.t('Must include only latin letters', { ns: 'validation' }));
        } else if (
          !(code > 64 && code < 91) && // upper alpha (A-Z)
          !(code > 96 && code < 123) && // lower alpha (a-z)
          !(code >= 1536 && code <= 1791 && allowArabic) // arabic alpha
        ) {
          throw new Error(i18n.t('Must includes only letters', { ns: 'validation' }));
        }
      }

      callback();
    } catch (err) {
      callback(err as string);
    }
  });
