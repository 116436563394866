import React, { FC } from 'react';
import GroupLayout from './GroupLayout';

const InputsGroupLayout: FC = (props) => {
  const { children } = props;

  return <GroupLayout gutter={20}>{children}</GroupLayout>;
};

export default InputsGroupLayout;
