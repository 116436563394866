import { useQuery, useQueryClient } from 'react-query';
import { useDebounce } from 'use-debounce/lib';
import { getMapItemsQuerySignal } from '~/api/agentMap';
import { useDeepEffect, usePath, useMutableCallback } from '~/hooks';
import { AgentMapItem } from '~/types/AgentMapItem';

export const mapItemsQueryKey = 'mapItems';

export const useGetMapItems = () => {
  const {
    params: { filter = {} },
  } = usePath();

  const query = useQuery(
    mapItemsQueryKey,
    ({ signal }) =>
      getMapItemsQuerySignal(signal)({
        firstLatitude: 27.169402286757215,
        firstLongitude: 49.61177641979541,
        secondLatitude: 21.45554122235795,
        secondLongitude: 60.332922385857415,
        ...filter,
      }),
    {
      select(res) {
        const uniqCoordsOnly: Record<string, AgentMapItem> = {};
        res.forEach((item) => {
          const coordsStr = `${item.geolocation.latitude}_${item.geolocation.longitude}`;
          uniqCoordsOnly[coordsStr] = item;
        });
        return Object.values(uniqCoordsOnly);
      },
    }
  );

  const queryClient = useQueryClient();

  const [debouncedRefetch] = useDebounce(() => {
    void query.refetch();
  }, 1000);
  const onFilterChange = useMutableCallback(() => {
    if (query.isRefetching) {
      void queryClient.cancelQueries(mapItemsQueryKey);
    }
    debouncedRefetch();
  });
  useDeepEffect(() => {
    onFilterChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return query;
};
