export default {
  'Lucky you! Payment is not required.': 'Lucky you! Payment is not required.',
  'Payment is done successfully.': 'Payment is done successfully.',
  'Please select the preferable payment method.': 'Please select the preferable payment method.',
  'Pay online now': 'Pay online now',
  'Bank transfer': 'Bank transfer',
  'Pay sales representative by cheque': 'Pay sales representative by cheque',
  'Cash deposit slip': 'Cash deposit slip',
  'Direct Debit from Emirates Islamic and ENBD bank accounts':
    'Direct Debit from Emirates Islamic and ENBD bank accounts',
  'Pay by link': 'Pay by link',
  'Payment after approval': 'Payment after approval',
  'Customer will pay after approval.': 'Customer will pay after approval.',
  //
  'Payment reference': 'Payment reference',
  'Payment date': 'Payment date',
  'Payment by card is available.': 'Payment by card is available.',
  Proceed: 'Proceed',
  //
  'Payment requisites': 'Payment requisites',
  'Please quote the company legal name as a payment reference.':
    'Please quote the company legal name as a payment reference.',
  'Bank transfer payment please remit to:': 'Bank transfer payment please remit to:',
  'Account holder: {{accountName}}': 'Account holder: {{accountName}}',
  'IBAN: {{iban}}': 'IBAN: {{iban}}',
  'Bank name: {{bankName}}': 'Bank name: {{bankName}}',
  'Copy requisites': 'Copy requisites',
  'Upload payment confirmation': 'Upload payment confirmation',
  //
  'You can pay by Direct Debit with your bank account:':
    'You can pay by Direct Debit with your bank account:',
  //
  'Transaction successful.': 'Transaction successful.',
  'A payment link was sent to email <strong>{{ lastEmail }}</strong>':
    'A payment link was sent to email <strong>{{ lastEmail }}</strong>',
  'A payment link will be sent to the specified email':
    'A payment link will be sent to the specified email',
  'Resend a link': 'Resend a link',
  'Send a link': 'Send a link',
  //
  'This is the final step. We will notify you by email once your application is approved.':
    'This is the final step. We will notify you by email once your application is approved.',
  //
  'Something went wrong': 'Something went wrong',
  'Close and Try again': 'Close and Try again',
  'An error has been occurred.': 'An error has been occurred.',
  'Please try again later or choose another payment method.':
    'Please try again later or choose another payment method.',
};
