import React, { FC } from 'react';
import { Space } from 'antd';
import { useHistory, useLocation } from 'react-router';
import styled, { css } from 'styled-components';
import colors from '~/constants/colors';
import { CheckmarkIcon } from '~/img';
import { useSelectStep } from '~/controllers/wizard';
import { ApplicationStep } from '~/types/ApplicationStep';

export interface ApplicationNavStepProps extends ApplicationNavStepStateProps {
  index: number;
  name: string;
  stepKey: string;
  uiKey: ApplicationStep['uiKey'];
  route: string;
  onClick?: () => void;
}

interface ApplicationNavStepStateProps {
  disabled: boolean;
  done: boolean;
  validationError: boolean;
  active?: boolean;
}

const ApplicationNavStep: FC<ApplicationNavStepProps> = (props) => {
  const { index, name, route, stepKey, onClick, disabled, done, active, validationError } = props;
  const history = useHistory();
  const location = useLocation();
  const selectStepMutation = useSelectStep();

  const handleNavClick = async () => {
    if (onClick) {
      onClick();
    } else if (!disabled) {
      try {
        await selectStepMutation.mutateAsync(stepKey);
        history.push(route);
      } catch (error) {
        //
      }
    }
  };

  return (
    <Container
      id={`ApplicationNavStep_${route}`}
      className="ApplicationNavStep"
      onClick={handleNavClick}
      disabled={disabled}
      done={done}
      validationError={validationError}
      active={active ?? location.pathname === route}
    >
      <Space size={8} align="center">
        <div className="ApplicationNavStep-Index">
          {done ? (
            <CheckmarkIcon className="ApplicationNavStep-CheckmarkIcon" />
          ) : (
            <span>{index}</span>
          )}
        </div>
        <div className="ApplicationNavStep-Text">{name}</div>
      </Space>
    </Container>
  );
};

const Container = styled.nav<ApplicationNavStepStateProps>`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: flex;
  .ApplicationNavStep-Index {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 17px;
    aspect-ratio: 1;
    border-radius: 9px;
    border-width: 1.5px;
    border-style: solid;
    span {
      display: flex;
      font-weight: 600;
      font-size: 10px;
      line-height: 15px;
      vertical-align: middle;
    }
    .ApplicationNavStep-CheckmarkIcon {
      color: ${colors.componentBackground};
      font-size: 9px;
    }
    ${({ disabled }) =>
      disabled
        ? css`
            border-color: ${colors.disabledBlueTextColor};
            color: ${colors.disabledBlueTextColor} !important;
          `
        : css`
            border-color: ${colors.blue[1100]};
          `}
    ${({ active }) =>
      active
        ? css`
            background-color: ${colors.blue[1100]};
            color: ${colors.componentBackground};
          `
        : css`
            background-color: ${colors.componentBackground};
            color: ${colors.blue[1100]};
          `}
    ${({ done }) =>
      done
        ? css`
            background-color: ${colors.successColor};
            border-color: ${colors.successColor};
            color: ${colors.componentBackground};
          `
        : css`
            //
          `}
    ${({ validationError }) =>
      validationError
        ? css`
            background-color: ${colors.warningColor};
            border-color: ${colors.warningColor};
            color: ${colors.componentBackground};
          `
        : css`
            //
          `}
  }
  .ApplicationNavStep-Text {
    ${({ active }) =>
      active
        ? css`
            font-weight: 600;
          `
        : css``}

    ${({ disabled }) =>
      disabled
        ? css`
            color: ${colors.disabledBlueTextColor};
          `
        : css`
            //
          `}
  }
`;

export default ApplicationNavStep;
