import { isEqual } from 'lodash';
import React, { CSSProperties, FC } from 'react';
import { useHandbook } from '~/controllers/handbook';
import { useDirection } from '~/hooks/i18nHooks';

interface Props {
  value:
    | {
        country?: string;
        emirate?: string;
        addressLine?: string;
        poBox?: string;
      }
    | undefined;
}

const AddressField: FC<Props> = (props) => {
  const { value } = props;

  const dir = useDirection();
  const handbook = useHandbook();
  const countriesRecord: Record<string, string> = {};
  const emiratesRecord: Record<string, string> = {};
  handbook.data?.handbooks
    ?.find((item) => item.key === 'entitycountryofestablishment')
    ?.values?.forEach((country) => {
      if (country.apiValue && country.localizedValue) {
        countriesRecord[country.apiValue] = country.localizedValue;
      }
    });
  handbook.data?.handbooks
    ?.find((item) => item.key === 'city')
    ?.values?.forEach((emirate) => {
      if (emirate.apiValue && emirate.localizedValue) {
        emiratesRecord[emirate.apiValue] = emirate.localizedValue;
      }
    });

  const addressArr = [
    value?.poBox,
    value?.addressLine,
    getValue(value?.emirate, emiratesRecord),
    getValue(value?.country, countriesRecord),
  ].filter(Boolean);

  const addressString = dir === 'ltr' ? addressArr.join(', ') : addressArr.join(' ، ');

  if (handbook.isLoading) return <span style={loadingStyle}>{addressString}</span>;
  return <>{addressString}</>;
};

const getValue = (
  value: string | undefined,
  mapping: Record<string, string>
): string | undefined => {
  return value ? mapping[value] || value : value;
};

const loadingStyle: CSSProperties = { opacity: 0.3 };

export default React.memo(AddressField, (prev, next) => isEqual(prev.value, next.value));
