import React, { FC } from 'react';
import Form from '~/components/Form';
import { BooleanServiceField } from '~/types/Services';
import { NameFn } from '~/utils/buildNamePath';
import ProductCheckbox from './ProductCheckbox';
import { withRole } from './utils';

interface Props {
  n: NameFn;
  field: BooleanServiceField;
  title?: string;
}

const BooleanFormItem: FC<Props> = (props) => {
  const { n, title, field } = props;
  return (
    <Form.Item name={n([field.key, 'value'])} noStyle>
      <ProductCheckbox title={title || field.name} disabled={!withRole(field.editable)} />
    </Form.Item>
  );
};

export default BooleanFormItem;
