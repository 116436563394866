import React, { FC } from 'react';
import { Modal, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { Form, Select } from '~/components';
import { useGetApplication } from '~/controllers/wizard';
import { useSendInvitation } from '~/controllers/contract';
import longdash from '~/constants/longdash';

interface Props {
  visible: boolean;
  onClose: () => void;
  onInvitationSent: () => void;
  authorizedSignatoryEmail: string | undefined;
  setAuthorizedSignatoryEmail: (email: string) => void;
}

const SendInvitationModal: FC<Props> = (props) => {
  const {
    visible,
    onClose,
    onInvitationSent,
    authorizedSignatoryEmail,
    setAuthorizedSignatoryEmail,
  } = props;
  const { t } = useTranslation('contract');
  const sendInvitationMutation = useSendInvitation();
  const applicationQuery = useGetApplication();

  const authorizedSignatory = applicationQuery.data?.payload.authorizedRepresentative?.filter(
    (item) => item.isAuthorizedSignatory
  );
  const options = authorizedSignatory?.map((item) => ({
    label: `${item.firstName} ${item.lastName} (${item.email || 'no email'}) ${longdash} ${
      item.proof?.designation
    }`,
    value: item.email || item.uid,
    disabled: !item.email,
  }));

  const onFinish = async (value: { email: string }) => {
    await sendInvitationMutation.mutateAsync(value.email);
    setAuthorizedSignatoryEmail(value.email);
    onClose();
    onInvitationSent();
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      okButtonProps={{
        form: 'sendInvitationForm',
        htmlType: 'submit',
        loading: sendInvitationMutation.isLoading,
      }}
      okText={t('Confirm', { ns: 'common' })}
      cancelText={t('Cancel', { ns: 'common' })}
      title={t('Who will sign the contract?')}
      centered
    >
      <Form
        name="sendInvitationForm"
        onFinish={onFinish}
        initialValues={{ email: authorizedSignatoryEmail }}
      >
        <Form.Item name={['email']} label={t('Select authorised signatory')}>
          <Select options={options} loading={applicationQuery.isLoading} />
        </Form.Item>
      </Form>
      <Typography.Paragraph>
        {t('We will send the invitation link to log in to the Portal.')}
      </Typography.Paragraph>
    </Modal>
  );
};

export default SendInvitationModal;
