import React, { FC } from 'react';
import { Input, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import Form from '~/components/WizardForm';
import buildNamePath from '~/utils/buildNamePath';

const SanctionsAdditionalQuestionsInputs: FC = () => {
  const { t } = useTranslation('fillApplication');

  return (
    <>
      <Typography.Paragraph>
        {t('Specify the name of the Sanctioned Countries/Territories:')}
      </Typography.Paragraph>
      <Form.Item name={n(['sanctions', 'sanctionedCountries'])}>
        <Input.TextArea autoSize={textareaAutoSize} placeholder={t('Enter text here')} />
      </Form.Item>

      <Typography.Paragraph>
        {t(
          'Brief description of business activities conducted in the Sanctioned Countries/Territories:'
        )}
      </Typography.Paragraph>
      <Form.Item name={n(['sanctions', 'businessActivities'])}>
        <Input.TextArea autoSize={textareaAutoSize} placeholder={t('Enter text here')} />
      </Form.Item>

      <Typography.Paragraph>
        {t(
          'What goods/products are involved, what they are used for, the country of origin and destination?'
        )}
      </Typography.Paragraph>
      <Form.Item name={n(['sanctions', 'products'])}>
        <Input.TextArea autoSize={textareaAutoSize} placeholder={t('Enter text here')} />
      </Form.Item>

      <Typography.Paragraph>
        {t(
          'Going forward, would you expect the dealings with Sanctioned Countries/Territories to increase/decrease/remain the same?'
        )}
      </Typography.Paragraph>
      <Form.Item name={n(['sanctions', 'expectedDealings'])}>
        <Input.TextArea autoSize={textareaAutoSize} placeholder={t('Enter text here')} />
      </Form.Item>
    </>
  );
};

const n = buildNamePath('additionalInformation');

const textareaAutoSize = { minRows: 2 };

export default SanctionsAdditionalQuestionsInputs;
