import React, { FC, ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

import theme from '~/constants/theme';

interface Props {
  left: ReactNode;
  content: ReactNode;
  right: ReactNode;
  className?: string;
}

const WizardContent: FC<Props> = (props) => {
  const { left, content, right, className } = props;
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.lg });

  return (
    <Container className={className}>
      <div className="WizardContent-Corner WizardContent-CornerLeft">{left}</div>
      <div className="WizardContent-Content">
        {content}
        {isTablet && <div className="WizardContent-BottomButton">{right}</div>}
      </div>
      {!isTablet && <div className="WizardContent-Corner WizardContent-CornerRight">{right}</div>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 32px 0;
  .WizardContent-Corner {
    position: sticky;
    top: 55px;
    height: calc(100vh - 192px);
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 44px 0;
    max-width: 357px;
  }
  .WizardContent-CornerRight {
    justify-content: flex-end;
  }
  .WizardContent-Content {
    display: flex;
    flex-direction: column;
    width: 700px;
  }

  .WizardContent-BottomButton {
    margin-top: 28px;
    button {
      width: unset;
      min-width: calc(50% - 10px);
    }
  }

  @media (max-width: ${theme.breakpoints.xl + 32}px) {
    .WizardContent-Content {
      width: 50vw;
    }
  }

  @media (max-width: ${theme.breakpoints.lg}px) {
    .WizardContent-Content {
      width: calc(70vw - 32px);
    }
    .WizardContent-CornerLeft {
      width: calc(30vw - 32px);
    }
    .WizardContent-CornerRight {
      display: none;
    }
  }
  @media (max-width: ${theme.breakpoints.md}px) {
    padding: 0;
    .WizardContent-CornerLeft {
      display: none;
    }
    .WizardContent-Content {
      width: 100%;
    }
  }
`;

export default WizardContent;
