import React, { FC, useState } from 'react';
import { Col, Row, Typography } from 'antd';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import colors from '~/constants/colors';
import theme from '~/constants/theme';
import useAgentMenuItem from '../hooks/useAgentMenuItem';
import { AgentMenuIcon, ApplicationsMenuIcon, LeadsMenuIcon, MapMenuIcon } from '~/img';
import paths from '~/pages/paths';
import { HeaderMenuDrawer } from '~/layouts/HeaderBurgerMenu';

interface AgentMobileMenuLayoutProps {
  items: JSX.Element[];
}

const AgentMobileMenuLayout: FC<AgentMobileMenuLayoutProps> = (props) => {
  const { items } = props;
  const span = 24 / (items.length || 1);

  return (
    <Container>
      <Row justify="center" align="middle" className="MenuRow">
        {items.map((item) => (
          <Col key={item.key} span={span}>
            {item}
          </Col>
        ))}
      </Row>
    </Container>
  );
};

interface MenuItemProps {
  icon: JSX.Element;
  title: string;
  path?: string;
  onClick: () => void;
}

const MenuItem: FC<MenuItemProps> = (props) => {
  const { icon, title, path, onClick } = props;
  const currentMenuItem = useAgentMenuItem();
  const isMenuItemSelected = currentMenuItem === path;
  return (
    <MenuItemContainer active={isMenuItemSelected} onClick={onClick}>
      <icon.type className="MenuItem-Icon" {...icon.props} />
      <Typography.Text className="MenuItem-Title">{title}</Typography.Text>
    </MenuItemContainer>
  );
};

const AgentMobileMenu: FC = () => {
  const [t] = useTranslation('agent');
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <AgentMobileMenuLayout
      items={[
        <MenuItem
          key="applications"
          icon={<ApplicationsMenuIcon />}
          title={t('applications')}
          path="applications"
          onClick={() => history.push(paths.agent.applications)}
        />,
        <MenuItem
          key="leads"
          icon={<LeadsMenuIcon />}
          title={t('leads')}
          path="leads"
          onClick={() => history.push(paths.agent.leads)}
        />,
        <MenuItem
          key="map"
          icon={<MapMenuIcon />}
          title={t('map')}
          path="map"
          onClick={() => history.push(paths.agent.map)}
        />,
        <>
          <MenuItem
            key="menu"
            icon={<AgentMenuIcon />}
            title={t('menu')}
            path="menu"
            onClick={handleOpen}
          />
          <HeaderMenuDrawer open={open} handleClose={handleClose} headerComponent={<div />} />
        </>,
      ]}
    />
  );
};

export const BOTTOM_MENU_HEIGHT = 64;

const Container = styled.nav`
  box-shadow: 0px 1px 8px ${colors.grey[300]};
  border-radius: ${theme.borderRadiusBase}px ${theme.borderRadiusBase}px 0px 0px;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: ${BOTTOM_MENU_HEIGHT}px;
  justify-content: center;
  align-items: center;
  background-color: ${colors.componentBackground};

  .MenuRow {
    height: 100%;
    margin: 0 16px;
  }
`;

const MenuItemContainer = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .MenuItem-Icon {
    font-size: 25px;
    cursor: pointer;
    ${({ active }) =>
      active
        ? css`
            color: ${colors.primaryColor};
          `
        : css`
            color: ${colors.textColor};
          `}
  }

  .MenuItem-Title {
    margin-top: 5px;
    font-size: 12px;
    ${({ active }) =>
      active
        ? css`
            color: ${colors.primaryColor};
          `
        : css`
            color: ${colors.textColor};
          `}
  }
`;

export default AgentMobileMenu;
