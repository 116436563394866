import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormProps, Modal, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import produce from 'immer';
import { queryDocuments, sessionDocuments } from '~/api/uaepass';
import { Alert, Button, Form, Space, Tabs } from '~/components';
import AuthorizedRepresentativeFormItems, {
  uploadPowerOfAttorney,
} from '~/components/views/authorizedRepresentative/AuthorizedRepresentativeFormItems';
import WizardForm from '~/components/WizardForm';
import theme from '~/constants/theme';
import { useDraftIsStale, useGetDraft, useSaveDraft } from '~/controllers/draft';
import { useAuthorizedRepresentativeContext, useGetApplication } from '~/controllers/wizard';
import { SignatureIcon, WarningCircleIcon } from '~/img';
import { Draft } from '~/types/Draft';
import { Person } from '~/types/Person';
import {
  getDecodedJwt,
  getUaePassEmail,
  isAgent,
  isLoggedInWithUaePass,
} from '~/utils/getDecodedJwt';
import { getStagePayload } from '~/utils/getStagePayload';
import { isSomeFieldsDisabled } from '~/utils/isSomeFieldsDisabled';
import UaePassImportDocumentAlert from '~/components/views/authorizedRepresentative/uaePassImportDocument/UaePassImportAlert';
import { delay } from '~/utils/delay';
import UaePassProgressModal from '~/components/views/contract/UaePassProgressModal';
import useLocalStorage from '~/hooks/useLocalStorage';
import { onApiError, showErrorMessage } from '~/utils/handleError';
import { UaePassDocumentsImportResult } from '~/types/UaePassDocumets';
import { AuthorizedRepresentativeContext } from '~/types/AuthorizedRepresentativeContext';
import SelectAuthorizedRepresentativeModal from '~/components/views/authorizedRepresentative/SelectAuthorizedRepresentativeModal';

const AuthorizedSignatory: FC = () => {
  const applicationQuery = useGetApplication();

  const [form] = Form.useForm<Draft<'authorizedRepresentative'>>();
  const getFormState = () => form.getFieldsValue();
  const draftMutation = useSaveDraft();
  const { onFresh, onStale } = useDraftIsStale();
  const shareholdersDraftQuery = useGetDraft<Draft<'shareholders'>>('application.shareholders');

  const { t } = useTranslation('fillApplication');

  const isUaePass = isLoggedInWithUaePass();
  const [needUaePassAuth, setNeedUaePassAuth] = useLocalStorage('uaePassAuthenticate', false);

  const stagePayload = getStagePayload.wizard(applicationQuery.data);
  const isFormWithValidationWarnings = !!stagePayload?.activeStep.errorInformation?.errorFields;
  const activeStep = stagePayload?.activeStep;
  const activeView = activeStep?.view;

  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.lg });

  const [activeTab, setActiveTab] = useState<string>();
  const [isSecondShow, setSecondShow] = useState(false);
  const handleShowSecond = () => {
    setSecondShow(true);
    const state = getFormState();
    form.setFieldsValue({
      authorizedRepresentative: [
        state.authorizedRepresentative?.[0] || ({} as Person),
        {} as Person,
      ],
    });
  };
  const handleRemoveSecond = async () => {
    setSecondShow(false);
    onStale();
    const state = getFormState();
    const newValues = {
      authorizedRepresentative: [state.authorizedRepresentative?.[0] || ({} as Person)],
    };
    form.setFieldsValue(newValues);
    setTabsErrors((prev) => [prev[0], false]);
    try {
      await draftMutation.mutateAsync({ stepData: newValues });
    } catch (error) {
      //
    }
    onFresh();
  };

  const [isUaePassDocumentImporting, setIsUaePassDocumentImporting] = useState(false);
  const [authorizedRepresentativeContext, setAuthorizedRepresentativeContext] =
    useAuthorizedRepresentativeContext();

  const setActiveTabCallback = useCallback(
    (tab: string) => {
      setActiveTab(tab);
      setAuthorizedRepresentativeContext((prev) =>
        prev ? { ...prev, activeTab: tab } : undefined
      );
    },
    [setActiveTab, setAuthorizedRepresentativeContext]
  );

  const onTabsChange = (activeKey: string) => {
    setActiveTabCallback(activeKey);
  };

  // init UI depending on current application's data and user's info

  const [tabsErrors, setTabsErrors] = useState<[boolean, boolean]>([false, false]);

  const onFinishFailed: FormProps['onFinishFailed'] = ({ errorFields }) => {
    let firstTabError = false;
    let secondTabError = false;
    errorFields.forEach(({ name }) => {
      if (name[1] === 0) {
        firstTabError = true;
      }
      if (name[1] === 1) {
        secondTabError = true;
      }
    });
    setTabsErrors([firstTabError, secondTabError]);
  };

  const onValuesChange = () => {
    if (tabsErrors[0] || tabsErrors[1]) {
      setTabsErrors([false, false]);
    }
  };

  const isSecondRepresentativeShareholder = (() => {
    const state = getFormState();
    const secondRepresentativeId = state.authorizedRepresentative?.[1]?.uid;
    if (secondRepresentativeId) {
      const shareholderRepresentativesIds =
        applicationQuery.data?.payload.shareholders?.authorizedRepresentative?.map(
          (item) => item.uid
        ) || [];
      const shareholderDraftRepresentativesIds =
        shareholdersDraftQuery.data?.shareholders?.authorizedRepresentative?.map(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          (item) => item.uid!
        ) || [];

      const resultIds = shareholdersDraftQuery.data
        ? shareholderDraftRepresentativesIds
        : shareholderRepresentativesIds;
      return resultIds.includes(secondRepresentativeId);
    }
    return false;
  })();

  const onUaePassImport = (context: AuthorizedRepresentativeContext) => {
    setIsUaePassDocumentImporting(true);
    queryDocuments()
      .then(async ({ sessionKey, uaepassAuthUrl }) => {
        /* eslint-disable no-await-in-loop */
        if (sessionKey) {
          setNeedUaePassAuth(false);
          /* eslint no-constant-condition: ["error", { "checkLoops": false }] */
          let result: UaePassDocumentsImportResult;
          while (true) {
            result = await sessionDocuments(sessionKey);
            if (result?.state !== 'INITIAL') break;
            await delay(5);
          }

          const isAr1Import = context?.ar1ImportStatus === 'INITIAL';
          const isAr2Import = context?.ar2ImportStatus === 'INITIAL';

          setAuthorizedRepresentativeContext((prev) => {
            return prev
              ? {
                  ...prev,
                  ar1ImportStatus: isAr1Import ? result?.state : prev.ar1ImportStatus,
                  ar2ImportStatus: isAr2Import ? result?.state : prev.ar2ImportStatus,
                }
              : undefined;
          });
          setIsUaePassDocumentImporting(false);
          if (result?.state === 'COMPLETE' && result?.documents) {
            onStale();
            const state = getFormState();
            const ar = (state.authorizedRepresentative ?? [])[isAr1Import ? 0 : 1];
            const newAr = produce(ar, (draft: Person) => {
              result?.documents?.forEach(({ type, file, expiryDate, number }) => {
                if (type === 'Passport') {
                  draft.passport.documentNumber = number;
                  draft.passport.expiryDate = expiryDate;
                  draft.passport.files = file;
                }
                if (type === 'EmiratesId') {
                  draft.emiratesId.documentNumber = number;
                  draft.emiratesId.expiryDate = expiryDate;
                  draft.emiratesId.files = [file];
                }
                if (type === 'ResidenceVisa') {
                  draft.visa.expiryDate = expiryDate;
                  draft.visa.files = file;
                }
                if (
                  type === 'POA' &&
                  draft.isAuthorizedSignatory &&
                  (!draft.proof ||
                    !draft?.proof?.documentType ||
                    draft?.proof?.documentType === uploadPowerOfAttorney)
                ) {
                  if (!draft.proof) draft.proof = {};
                  draft.proof.documentType = uploadPowerOfAttorney;
                  draft.proof.files = [...(draft.proof?.files ?? []), file];
                }
              });
            }) as Person;
            const newValues = {
              authorizedRepresentative: state.authorizedRepresentative ?? [],
            };
            newValues.authorizedRepresentative[isAr1Import ? 0 : 1] = newAr;
            form.setFieldsValue(newValues);
            setTabsErrors((prev) => [prev[0], false]);
            try {
              await draftMutation.mutateAsync({ stepData: newValues });
            } catch (error) {
              //
            }
            onFresh();
          }
        }
        if (uaepassAuthUrl) {
          if (needUaePassAuth) {
            showErrorMessage();
            setNeedUaePassAuth(false);
            setIsUaePassDocumentImporting(false);
            return;
          }
          setNeedUaePassAuth(true);
          window.location.href = uaepassAuthUrl;
        }
      })
      .catch((error) => {
        onApiError(error);
        setIsUaePassDocumentImporting(false);
        setNeedUaePassAuth(false);
      });
  };

  // this method checks UAE PASS state and decides which tab should be in which
  // state and which authorized representative should be imported from UAE PASS
  const makeInitialContext = () => {
    const initialContext: AuthorizedRepresentativeContext = {
      onUaePassImport,
      activeTab: 'first',
    };

    if (isUaePass) {
      const authorizedRepresentativeData = applicationQuery.data?.payload.authorizedRepresentative;
      const uaepassDocumentSharingData = applicationQuery.data?.payload.uaepassDocumentSharing;

      const ar1Email = authorizedRepresentativeData && authorizedRepresentativeData[0]?.email;
      const ar2Email = authorizedRepresentativeData && authorizedRepresentativeData[1]?.email;
      const ar1Imported =
        uaepassDocumentSharingData && typeof uaepassDocumentSharingData['0'] !== 'undefined';
      const ar2Imported =
        uaepassDocumentSharingData && typeof uaepassDocumentSharingData['1'] !== 'undefined';
      const ar1IsEmpty = !authorizedRepresentativeData || !authorizedRepresentativeData[0];
      const ar2IsEmpty = !authorizedRepresentativeData || !authorizedRepresentativeData[1];

      const uaePassEmail = getUaePassEmail();

      const currentUserIsAlreadyImported =
        (ar1Imported && ar1Email === uaePassEmail) || (ar2Imported && ar2Email === uaePassEmail);

      initialContext.ar1ImportStatus = ar1Imported ? 'COMPLETE' : undefined;
      initialContext.ar2ImportStatus = ar2Imported ? 'COMPLETE' : undefined;

      if (!currentUserIsAlreadyImported && (!ar1Imported || !ar2Imported)) {
        if (uaePassEmail === ar1Email) {
          // show import for the first tab
          initialContext.ar1ImportStatus = 'INITIAL';
        } else if (uaePassEmail === ar2Email) {
          // open second tab and show import for the second tab
          initialContext.ar2ImportStatus = 'INITIAL';
        } else if (ar1IsEmpty) {
          // show import for the first tab
          initialContext.ar1ImportStatus = 'INITIAL';
        } else if (ar2IsEmpty) {
          // open second tab, add second AR and show import for the second tab
          initialContext.ar2ImportStatus = 'INITIAL';
        } else if (ar1Imported) {
          // open second tag, show import for the second tab and show informational warning/alert
          initialContext.ar2ImportStatus = 'INITIAL';
        } else if (ar2Imported) {
          // show import for the first tab and show informational warning/alert
          initialContext.ar1ImportStatus = 'INITIAL';
        }
      }
    }
    return initialContext;
  };
  // const makeInitialContextCallback = useCallback(makeInitialContext, [applicationQuery, isUaePass]);

  const [showSelectDialog, setShowSelectDialog] = useState<boolean>(false);

  const onSelectedAuthorizedRepresentative = (index: number) => {
    setShowSelectDialog(false);
    if (index === 0) {
      setAuthorizedRepresentativeContext((prev) => {
        return prev ? { ...prev, ar1ImportStatus: 'INITIAL' } : undefined;
      });
      setActiveTabCallback('first');
    } else {
      setAuthorizedRepresentativeContext((prev) => {
        return prev ? { ...prev, ar2ImportStatus: 'INITIAL' } : undefined;
      });
      setActiveTabCallback('second');
    }

    const state = getFormState();
    if (!state.authorizedRepresentative) {
      return;
    }

    const ar = state.authorizedRepresentative[index];
    const newAr = produce(ar, (draft: any) => {
      // update values of first AR from UAE PASS
      const jwtToken = getDecodedJwt();

      draft.email = jwtToken?.uaepass_email;
      draft.phoneNumber = jwtToken?.uaepass_phone_number;
      draft.firstName = jwtToken?.uaepass_firstname_en;
      draft.lastName = jwtToken?.uaepass_lastname_en;
      draft.isAuthorizedSignatory = true;

      // remove documents depending on the nationality
      draft.emiratesId = undefined;
      if (jwtToken?.uaepass_nationality_en === 'ARE') {
        draft.passport = undefined;
        draft.visa = undefined;
      } else {
        draft.visa = undefined;
      }
    }) as Person;
    const newValues = {
      authorizedRepresentative: state.authorizedRepresentative ?? [],
    };
    newValues.authorizedRepresentative[index] = newAr;
    form.setFieldsValue(newValues);
  };

  // initializes authorized-representative context that is used by the right panel to show
  useEffect(
    () => {
      const initialContext: AuthorizedRepresentativeContext = {
        onUaePassImport,
        activeTab: activeTab || 'first',
      };

      if (isUaePass) {
        const authorizedRepresentativeData =
          applicationQuery.data?.payload.authorizedRepresentative;
        const uaepassDocumentSharingData = applicationQuery.data?.payload.uaepassDocumentSharing;

        const ar1Email = authorizedRepresentativeData && authorizedRepresentativeData[0]?.email;
        const ar2Email = authorizedRepresentativeData && authorizedRepresentativeData[1]?.email;
        const ar1Imported =
          uaepassDocumentSharingData && typeof uaepassDocumentSharingData['0'] !== 'undefined';
        const ar2Imported =
          uaepassDocumentSharingData && typeof uaepassDocumentSharingData['1'] !== 'undefined';
        const ar1IsEmpty = !authorizedRepresentativeData || !authorizedRepresentativeData[0];
        const ar2IsEmpty = !authorizedRepresentativeData || !authorizedRepresentativeData[1];

        const uaePassEmail = getUaePassEmail();

        const currentUserIsAlreadyImported =
          (ar1Imported && ar1Email === uaePassEmail) || (ar2Imported && ar2Email === uaePassEmail);

        initialContext.ar1ImportStatus = ar1Imported ? 'COMPLETE' : undefined;
        initialContext.ar2ImportStatus = ar2Imported ? 'COMPLETE' : undefined;

        if (!currentUserIsAlreadyImported && (!ar1Imported || !ar2Imported)) {
          if (uaePassEmail === ar1Email) {
            // show import for the first tab
            initialContext.ar1ImportStatus = 'INITIAL';
          } else if (uaePassEmail === ar2Email) {
            // open second tab and show import for the second tab
            initialContext.ar2ImportStatus = 'INITIAL';
          } else if (ar1IsEmpty) {
            // show import for the first tab
            initialContext.ar1ImportStatus = 'INITIAL';
          } else if (ar2IsEmpty) {
            // open second tab, add second AR and show import for the second tab
            initialContext.ar2ImportStatus = 'INITIAL';
          } else if (ar1Imported) {
            // open second tag, show import for the second tab and show informational warning/alert
            initialContext.ar2ImportStatus = 'INITIAL';
            const ar2 = authorizedRepresentativeData && authorizedRepresentativeData[1];
            const ar2Name = `${ar2?.firstName || ''} ${ar2?.lastName || ''}`;
            Modal.warning({
              title: t('Data will be replaced'),
              content: t(
                'Second authorized representative {{ar2Name}} data will be replaced with your UAE PASS profile data.',
                { ar2Name }
              ),
            });
          } else if (ar2Imported) {
            // show import on the first tab and show informational warning/alert
            initialContext.ar1ImportStatus = 'INITIAL';
            const ar1 = authorizedRepresentativeData && authorizedRepresentativeData[0];
            const ar1Name = `${ar1?.firstName || ''} ${ar1?.lastName || ''}`;
            Modal.warning({
              title: t('Data will be replaced'),
              content: t(
                'First authorized representative {{ar1Name}} data will be replaced with your UAE PASS profile data.',
                { ar1Name }
              ),
            });
          } else {
            // show dialog to choose authorized representative from
            setShowSelectDialog(true);
          }
        }
      }
      console.log(`initialContext:`, initialContext);
      setAuthorizedRepresentativeContext(initialContext);

      if (needUaePassAuth) {
        onUaePassImport(initialContext);
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [setAuthorizedRepresentativeContext]
  );

  // this method is used by form to init form values once form is initially loaded
  // this method updates from with either default or UAE PASS data if needed
  const setInitialValues = (initialValues: Draft<'authorizedRepresentative'> | undefined) => {
    let values = initialValues;
    if (Number(values?.authorizedRepresentative?.length) >= 2) {
      setSecondShow(true);
    }

    if (isAgent()) {
      return initialValues as Draft<'authorizedRepresentative'>;
    }

    if (!values || !values.authorizedRepresentative || !values.authorizedRepresentative.length) {
      const decodedToken = getDecodedJwt();
      const email = decodedToken?.email;
      const phoneNumber = decodedToken?.phone_number;
      const firstName = decodedToken?.given_name;
      const lastName = decodedToken?.family_name;

      values = {
        authorizedRepresentative: [
          {
            email,
            phoneNumber,
            firstName,
            lastName,
            isPep: false,
          },
        ],
      };
    }
    if (!values.authorizedRepresentative) {
      return values;
    }

    const initialContext = makeInitialContext();
    if (initialContext.ar1ImportStatus === 'INITIAL') {
      // update values of first AR from UAE PASS
      const jwtToken = getDecodedJwt();
      const ar = values.authorizedRepresentative[0];
      ar.email = jwtToken?.uaepass_email;
      ar.phoneNumber = jwtToken?.uaepass_phone_number;
      ar.firstName = jwtToken?.uaepass_firstname_en;
      ar.lastName = jwtToken?.uaepass_lastname_en;
      ar.isAuthorizedSignatory = true;

      // remove documents depending on the nationality
      ar.emiratesId = undefined;
      if (jwtToken?.uaepass_nationality_en === 'ARE') {
        ar.passport = undefined;
        ar.visa = undefined;
      } else {
        ar.visa = undefined;
      }
    } else if (initialContext.ar2ImportStatus === 'INITIAL') {
      // update values of second AR from UAE PASS
      if (values.authorizedRepresentative.length === 1) {
        values.authorizedRepresentative.push({});
      }
      const jwtToken = getDecodedJwt();
      const ar = values.authorizedRepresentative[1];
      ar.email = jwtToken?.uaepass_email;
      ar.phoneNumber = jwtToken?.uaepass_phone_number;
      ar.firstName = jwtToken?.uaepass_firstname_en;
      ar.lastName = jwtToken?.uaepass_lastname_en;
      ar.isAuthorizedSignatory = true;

      // remove documents depending on the nationality
      ar.emiratesId = undefined;
      if (jwtToken?.uaepass_nationality_en === 'ARE') {
        ar.passport = undefined;
        ar.visa = undefined;
      } else {
        ar.visa = undefined;
      }

      setSecondShow(true);
      setActiveTabCallback('second');
    }

    return values;
  };

  return (
    <Container className="AuthorizedSignatory">
      {activeView === 'soleProprietorAuthorizedSignatory' ? (
        <>
          <Typography.Title>
            {stagePayload?.activeStep.stepName || t('Authorized Signatory')}
          </Typography.Title>
          <Typography.Paragraph>
            {t(
              'Please provide the details of the person who is an authorised representative of your company.'
            )}
          </Typography.Paragraph>
          <Typography.Paragraph>
            {t('Some of the fields were pre populated. Feel free to edit this data.')}
          </Typography.Paragraph>
          <WizardForm<Draft<'authorizedRepresentative'>>
            stepKey="authorizedRepresentative"
            form={form}
            onValuesChange={onValuesChange}
            onFinishFailed={onFinishFailed}
            setInitialValues={setInitialValues}
          >
            {!!isTablet && <UaePassImportDocumentAlert />}
            <AuthorizedRepresentativeFormItems
              form={form}
              name={getAuthorizedRepresentativeName(0)}
              defaultIsAuthorizedSignatory
              withAgentSendInvitation
              withUaePass={isUaePass}
            />
          </WizardForm>
        </>
      ) : activeView === 'multipleAuthorizedSignatory' ? (
        <>
          <Typography.Title>
            {stagePayload?.activeStep.stepName || t('Authorized Signatory')}
          </Typography.Title>
          <Typography.Paragraph>
            {t(
              'Please provide the details of at least one person who is an authorised representative of your company.'
            )}
          </Typography.Paragraph>
          <Typography.Paragraph>
            {t(
              'At least one of these representatives must have the authority and proof to act as an authorised signatory for your company.'
            )}
          </Typography.Paragraph>
          <Typography.Paragraph>
            {t('Some of the fields were pre populated. Feel free to edit this data.')}
          </Typography.Paragraph>
          <WizardForm<any>
            stepKey="authorizedRepresentative"
            form={form}
            onValuesChange={onValuesChange}
            onFinishFailed={onFinishFailed}
            setInitialValues={setInitialValues}
          >
            <Tabs
              activeKey={activeTab}
              defaultActiveKey="first"
              sticky
              centered
              onChange={onTabsChange}
            >
              <Tabs.TabPane
                tab={
                  <span
                    id="authorizedRepresentative_1"
                    data-error={tabsErrors[0] ? 'true' : 'false'}
                  >
                    <SignatureIcon />
                    {t('Authorised Representative {{number}}', { number: 1 })}
                  </span>
                }
                key="first"
              >
                <div className="AuthorizedSignatory-TabContent">
                  {!!isTablet && <UaePassImportDocumentAlert />}
                  <AuthorizedRepresentativeFormItems
                    form={form}
                    name={getAuthorizedRepresentativeName(0)}
                    withAgentSendInvitation
                    withUaePass={!!authorizedRepresentativeContext?.ar1ImportStatus}
                  />
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <span
                    id="authorizedRepresentative_2"
                    data-error={tabsErrors[1] ? 'true' : 'false'}
                  >
                    <SignatureIcon />
                    {t('Authorised Representative {{number}}', { number: 2 })}
                  </span>
                }
                key="second"
              >
                <div className="AuthorizedSignatory-TabContent">
                  {isSecondShow ? (
                    <>
                      <AuthorizedRepresentativeFormItems
                        form={form}
                        name={getAuthorizedRepresentativeName(1)}
                        withAgentSendInvitation
                        withUaePass={!!authorizedRepresentativeContext?.ar2ImportStatus}
                      />
                      <Space size={16} direction="vertical">
                        {isSecondRepresentativeShareholder && (
                          <Alert
                            type="warning"
                            icon={<WarningCircleIcon />}
                            showIcon
                            message={t('Authorized Representative cannot be deleted')}
                            description={t(
                              'This representative is used as shareholder in "Shareholders" step'
                            )}
                          />
                        )}
                        {!authorizedRepresentativeContext?.ar2ImportStatus &&
                          !isSecondRepresentativeShareholder && (
                            <Button
                              id="removeAuthorisedRepresentativeButton"
                              block
                              type="default"
                              onClick={handleRemoveSecond}
                              disabled={
                                isSecondRepresentativeShareholder ||
                                isFormWithValidationWarnings ||
                                isSomeFieldsDisabled(applicationQuery.data)
                              }
                            >
                              {t('Remove this representative')}
                            </Button>
                          )}
                      </Space>
                    </>
                  ) : (
                    <Button
                      id="addAuthorisedRepresentativeButton"
                      block
                      type="default"
                      disabled={
                        isFormWithValidationWarnings || isSomeFieldsDisabled(applicationQuery.data)
                      }
                      onClick={handleShowSecond}
                    >
                      {t('Add Authorised representative')}
                    </Button>
                  )}
                </div>
              </Tabs.TabPane>
            </Tabs>
          </WizardForm>
        </>
      ) : null}
      <UaePassProgressModal visible={isUaePassDocumentImporting} />
      <SelectAuthorizedRepresentativeModal
        visible={showSelectDialog}
        onClose={onSelectedAuthorizedRepresentative}
      />
    </Container>
  );
};

export const getAuthorizedRepresentativeName = (index: number) => [
  'authorizedRepresentative',
  index,
];

const Container = styled.div`
  h4 {
    margin-bottom: 24px;
  }

  section {
    margin-top: 48px;
  }

  @media (max-width: ${theme.breakpoints.md}px) {
    section {
      margin-top: 24px;
    }

    .AuthorizedSignatory-TabContent {
      width: calc(100vw - ${16 * 2 + 8 * 2}px);
    }
  }
`;

export default AuthorizedSignatory;
