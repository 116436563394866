import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import produce from 'immer';
import { merge } from 'lodash';

import { Button } from '~/components';
import FormDrawer from '~/components/FormDrawer';
import { PlusIcon } from '~/img';
import { ApplicationPayload } from '~/types/ApplicationPayload';
import IndividualForm, { formName } from './IndividualForm';
import buildNamePath from '~/utils/buildNamePath';
import { shareholdersKey } from '.';
import { FormItemInjectedProps } from '~/types/FormItemInjectedProps';
import { Shareholders } from '~/types/Shareholders';
import { useGetApplication } from '~/controllers/wizard';
import { hasSoftDeclineFields } from '~/utils/softDeclinesFields';
import { isShareholderEditButtonDisabled } from './utils';

const BeneficialOwnerAdd: FC<Partial<FormItemInjectedProps<Shareholders>>> = (props) => {
  const { value, onChange } = props as FormItemInjectedProps<Shareholders>;
  const shareholdersFormState = { shareholders: value };

  const { t } = useTranslation('fillApplication');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const newShareholderIndex = shareholdersFormState.shareholders?.beneficialOwners?.length || 0;

  const onFinish = (newValues: ApplicationPayload) => {
    const updatedData = produce(shareholdersFormState, (draft) => {
      merge(draft, newValues);
    });
    onChange?.(updatedData.shareholders);
    handleClose();
  };

  const applicationQuery = useGetApplication();
  const hasBeneficialOwnersValidationWarnings = hasSoftDeclineFields(
    applicationQuery.data,
    'shareholders.beneficialOwners[]'
  );

  return (
    <>
      <Button
        id="addNewBeneficialOwnerButton"
        extraLarge
        icon={<PlusIcon />}
        disabled={isShareholderEditButtonDisabled(
          applicationQuery.data,
          hasBeneficialOwnersValidationWarnings
        )}
        onClick={handleOpen}
      >
        {t('Add beneficial owner')}
      </Button>
      <FormDrawer
        id="beneficialOwnerAddDrawer"
        visible={open}
        onClose={handleClose}
        title={t('Add beneficial owner')}
        formName={formName}
      >
        <IndividualForm
          onFinish={onFinish}
          initialValues={shareholdersFormState}
          n={buildNamePath([shareholdersKey, 'beneficialOwners', newShareholderIndex])}
        />
      </FormDrawer>
    </>
  );
};

export default BeneficialOwnerAdd;
