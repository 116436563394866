import { useQueryClient } from 'react-query';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { auth, logout, validateSession } from '~/api/auth';
import paths from '~/pages/paths';
import { ApiError } from '~/types/ApiError';
import { redirectToExternalAuth } from '~/utils/redirectToExternalAuth';
import { isAgent } from '~/utils/getDecodedJwt';
import { getLocale } from '~/api/locale';
import { allLocalesQueryKey, useGetAllLanguages, useSetLanguage } from '~/hooks/i18nHooks';

const removePreloader = (): void => {
  const ele = document.getElementById('ipl-progress-indicator');
  if (ele) {
    // fade out
    ele.classList.add('available');
    setTimeout(() => {
      // remove from DOM
      ele.outerHTML = '';
    }, 500);
  }
};

export const useInitApp = () => {
  const [isAppReady, setAppReady] = useState(false);
  const { setLanguage, language: i18nLanguage } = useSetLanguage();
  useGetAllLanguages();
  const history = useHistory();
  const queryClient = useQueryClient();

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (
      currentPath === paths.auth.merchantAuthCallback ||
      currentPath === paths.auth.merchantInviteAuthCallback ||
      currentPath === paths.auth.agentAuthCallback
    ) {
      void queryClient.prefetchQuery(allLocalesQueryKey);
      removePreloader();
      setAppReady(true);
    } else if (auth.getToken()) {
      void (async () => {
        try {
          await validateSession();
          const backendLocale = await getLocale();
          void queryClient.prefetchQuery(allLocalesQueryKey);

          if (i18nLanguage !== backendLocale) {
            setLanguage(i18nLanguage);
          }
          removePreloader();
          setAppReady(true);
        } catch (err) {
          const error = err as ApiError;
          console.log(`token is bad; redirecting to auth; error:`, error);
          if (error.response?.status === 401) {
            auth.clearSession();
            redirectToExternalAuth();
          }
          if (error.response?.data?.code === 'INVALID_APPLICATION_ID') {
            removePreloader();
            setAppReady(true);
            auth.clearAgentApplicationId();
            history.push(paths.home._);
          }
        }
      })();
    } else {
      console.log(`no token is set; redirecting to auth`);
      redirectToExternalAuth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isAppReady;
};

export const useLogout = () => {
  const history = useHistory();

  const handleLogout = async () => {
    const agent = isAgent();
    const session = auth.getSession();
    try {
      await logout({
        url:
          session.logoutUrl ||
          `https://${window.location.host}/auth/realms/${session.realm}/protocol/openid-connect/logout`,
        data: {
          client_id: session.clientId,
          refresh_token: session.refreshToken,
        },
      });
    } catch (e) {
      console.error('failed to logout; error:', e);
    }
    auth.clearSession();
    auth.clearAgentApplicationId();
    if (agent) {
      history.push('/agent');
    } else {
      history.push('/');
    }
  };

  return { handleLogout };
};
