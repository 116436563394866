/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import {
  activateLead,
  assignApplication,
  assignLead,
  createApplication,
  createLead,
  getAgents,
  getApplications,
  getLeadById,
  getLeads,
  pinItem,
  submitLead,
  terminateApplication,
  terminateLead,
} from '~/api/agent';
import { auth } from '~/api/auth';
import { usePageQuery } from '~/hooks';
import paths from '~/pages/paths';
import { AgentLead } from '~/types/AgentLead';
import { useDraftIsStale } from './draft';
import { useSubmitStepIsLoading } from './wizard';

export const useGetLeads = () => {
  return usePageQuery<AgentLead[]>('leads', getLeads);
};

export const useGetLeadById = (id: string | undefined) => {
  return useQuery<AgentLead>(['leads', id], () => getLeadById(id!), { enabled: !!id });
};

export const useGetApplicationsList = () => {
  return usePageQuery('applications', getApplications);
};

export const useCreateLead = () => {
  return useMutation(createLead);
};

export const usePinItem = () => {
  return useMutation(pinItem);
};

export const useCreateApplication = () => {
  return useMutation(createApplication);
};

export const useTerminateLead = () => {
  return useMutation(terminateLead);
};

export const useTerminateApplication = () => {
  return useMutation(terminateApplication);
};

export const useSubmitLead = () => {
  return useMutation(submitLead);
};

export const useActivateLead = () => {
  return useMutation(activateLead);
};

export const useAgents = () => {
  return useQuery('agentAssignee', getAgents, { staleTime: Infinity });
};

export const useAssignLead = () => {
  return useMutation(assignLead);
};

export const useAssignApplication = () => {
  return useMutation(assignApplication);
};

export const useGoToDashboard = () => {
  const history = useHistory();
  const [isLoading] = useSubmitStepIsLoading();
  const { isStale } = useDraftIsStale();
  const disabled = isLoading || isStale;
  const queryClient = useQueryClient();

  return {
    go: () => {
      if (disabled) return;
      auth.clearAgentApplicationId();
      void queryClient.invalidateQueries('services');
      history.push(paths.agent.applications);
    },
    disabled,
  };
};
