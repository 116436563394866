import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '~/components';
import { PlusIcon } from '~/img';
import { LeadCreate } from '~/components/views/agent/leadForm/LeadCreate';

const LeadAdd = () => {
  const { t } = useTranslation('agent');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button
        id="addLeadButton"
        type="primary"
        size="middle"
        icon={<PlusIcon />}
        onClick={handleOpen}
      >
        {t('New Lead')}
      </Button>
      <LeadCreate open={open} onClose={handleClose} />
    </>
  );
};

export default LeadAdd;
