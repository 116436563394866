import { useRef, useCallback } from 'react';
import { isEqual } from 'lodash';
import { Path } from 'path-parser';
import cleanupFormValues from '~/utils/cleanupFormValues';
import { history } from '~/index';

const path = new Path('/agent/:route?filter&order&sort&displayMode&page&perPage');

const defaultPageSize = 10;

type Params = {
  params: Record<string, any>;
  changePath: (newParams: any) => void;
  setPath: (newParams: any) => void;
  pagination: {
    defaultCurrent: number;
    defaultPageSize: number;
    hideOnSinglePage: boolean;
    onChange: (page: number, perPage?: number) => void;
  };
};

const usePath = (): Params => {
  const { pathname, search } = history.location;
  const pathParams = path.partialTest(pathname + search) || {};

  const params = pathParams.filter
    ? { ...pathParams, filter: JSON.parse(pathParams.filter) }
    : pathParams;

  const changePathCallback = (newParams: any) => {
    const resultNewParams = { ...newParams };
    if (params.filter || newParams.filter) {
      const newFilter = { ...params.filter, ...newParams.filter };
      if (!isEqual(params.filter, newFilter)) {
        resultNewParams.page = 1;
      }
      resultNewParams.filter = JSON.stringify(cleanupFormValues(newFilter));
    }
    history.replace(path.build({ ...params, ...resultNewParams }));
  };
  const changePathRef = useRef<typeof changePathCallback>(changePathCallback);
  changePathRef.current = changePathCallback;
  const changePath = useCallback(
    (newParams: any) => changePathRef.current(newParams),
    [changePathRef]
  );

  const setPathCallback = (newParams: any) => {
    history.replace(path.build(newParams));
  };
  const setPathRef = useRef<typeof setPathCallback>(setPathCallback);
  setPathRef.current = setPathCallback;
  const setPath = useCallback((newParams: any) => setPathRef.current(newParams), [setPathRef]);

  const pagination = {
    defaultCurrent: Number(params.page) || 1,
    defaultPageSize: Number(params.perPage) || defaultPageSize,
    current: params.page ? Number(params.page) : undefined,
    perPage: params.page ? Number(params.perPage) : undefined,
    hideOnSinglePage: true,
    onChange: (page: number, perPage = defaultPageSize) => changePath({ page, perPage }),
  };

  return {
    params: handleParams(params),
    changePath,
    setPath,
    pagination,
  };
};

usePath.defaultPageSize = defaultPageSize;

const handleParams = (params: Record<string, any>) => {
  const { page, perPage, ...rest } = params || {};
  const resultPage = page ? Number(page) : undefined;
  const resultPerPage = perPage ? Number(perPage) : undefined;
  return { page: resultPage, perPage: resultPerPage, ...rest };
};

export default usePath;
