import React, { FC } from 'react';
import { InputProps } from 'antd';
import { AsYouType } from 'libphonenumber-js';
import LtrInput from './LtrInput';

export function applyMask<V = InputProps['value']>(value: V): V | string {
  if (typeof value !== 'string') return value;
  return new AsYouType().input(value);
}

const PhoneNumberInput: FC<InputProps> = (props) => {
  const { value, ...rest } = props;
  return <LtrInput value={applyMask(value)} {...rest} />;
};

export default PhoneNumberInput;
