import React, { FC } from 'react';
import { Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import WizardContent from '~/layouts/WizardContent';
import features from '~/constants/features';
import LeftLayout from '~/layouts/LeftLayout';
import BottomLeftActions from '~/layouts/BottomLeftActions';
import { isMerchant } from '~/utils/getDecodedJwt';
import { Button } from '~/components';
import { useSubmitSalesforceTechnicalError } from '~/controllers/wizard';

const SalesforceError: FC = () => {
  const { t } = useTranslation('stageResult');
  const submitMutation = useSubmitSalesforceTechnicalError();
  return (
    <WizardContent
      left={
        <LeftLayout>
          <BottomLeftActions contactUsUrl={features.contactUsLinkSalesforceTechnicalError} />
        </LeftLayout>
      }
      content={
        <Container className="SalesforceError">
          <Typography.Title>{t('Application submission error')}</Typography.Title>
          <Typography.Paragraph>
            {isMerchant() ? (
              <p>
                <Trans
                  t={t}
                  i18nKey="Sorry, we have a technical problem right now, please try again later or <1>contact us</1>."
                >
                  {'Sorry, we have a technical problem right now, please try again later or '}
                  <a href={features.contactUsLinkSalesforceTechnicalError} target="__blank">
                    contact us
                  </a>
                  .
                </Trans>
              </p>
            ) : (
              <p>{t('Sorry, we have a technical problem right now, please try again later.')}</p>
            )}
          </Typography.Paragraph>
        </Container>
      }
      right={
        <Button
          id="salesforceTechnicalError_tryAgainButton"
          type="primary"
          loading={submitMutation.isLoading}
          onClick={() => submitMutation.mutate()}
          style={{ whiteSpace: 'break-spaces', height: 'auto' }}
        >
          {t('Try again')}
        </Button>
      }
    />
  );
};

const Container = styled.div`
  .SalesforceError-Img {
    margin-top: 36px;
    border-radius: 14px;
    width: 100%;
  }
`;

export default SalesforceError;
