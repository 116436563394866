import React, { FC } from 'react';
import { Input, InputNumber, Typography } from 'antd';
import { get, merge } from 'lodash';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import produce from 'immer';

import { CountryInput, DateInput, DocumentInput, Form, PhoneNumberFormItem } from '~/components';
import InputsGroupLayout from '~/layouts/InputsGroupLayout';
import { ApplicationPayload } from '~/types/ApplicationPayload';
import { NameFn } from '~/utils/buildNamePath';
import createUid from '~/utils/createUid';
import Section from '~/layouts/Section ';
import { useValidator } from '~/controllers/validation';
import { FormItemContextProvider } from '~/components/Form/FormItemContext';
import { useGetApplication } from '~/controllers/wizard';
import { useGetDraft } from '~/controllers/draft';
import { Draft } from '~/types/Draft';
import { isReadOnly } from '~/utils/isReadOnly';
import LtrInput from '~/components/inputs/LtrInput';
import { getStagePayload } from '~/utils/getStagePayload';
import CustomFields from '~/components/CustomFields';

interface Props {
  onFinish: (values: ApplicationPayload) => any;
  initialValues?: any | undefined;
  n: NameFn;
}

const BusinessForm: FC<Props> = (props) => {
  const { onFinish, initialValues, n } = props;
  const { t } = useTranslation('fillApplication');

  const [form] = Form.useForm<ApplicationPayload>();
  const getFormState = () => {
    return produce(initialValues || {}, (draft: any) => {
      merge(draft, form.getFieldsValue());
    });
  };
  const validatorController = useValidator(getFormState);

  const applicationQuery = useGetApplication();
  const draftQuery = useGetDraft<Draft<'shareholders'>>();

  const stagePayload = getStagePayload.wizard(applicationQuery.data);

  return (
    <FormItemContextProvider
      value={{
        defaultValidator: validatorController.validator,
        applicationPayload: applicationQuery.data?.payload,
        draftPayload: draftQuery.data,
        errorFields: stagePayload?.activeStep.errorInformation?.errorFields,
        enabledFields: stagePayload?.activeStep.errorInformation?.enabledFields,
        disabledByDefault: isReadOnly(applicationQuery.data),
        disabled: isReadOnly(applicationQuery.data),
      }}
    >
      <Container className="BusinessForm">
        <Form form={form} name={formName} onFinish={onFinish} initialValues={initialValues}>
          <Form.Item
            name={n(['data', 'uid'])}
            hidden
            initialValue={get(initialValues, n(['data', 'uid'])) || createUid()}
          />

          <section>
            <Typography.Title level={4}>{t('Company license').toUpperCase()}</Typography.Title>
            <Typography.Paragraph>
              {t('Please upload the company license photo.')}
            </Typography.Paragraph>
            <Form.Item name={n(['data', 'companyLicense', 'files'])}>
              <DocumentInput name="photos" variant="default" />
            </Form.Item>
          </section>

          <Section>
            <Typography.Title level={5}>{t('Document data:')}</Typography.Title>
            <Typography.Paragraph>
              {t('Please share basic details about the company.')}
            </Typography.Paragraph>
            <InputsGroupLayout>
              <Form.Item
                label={t('Company legal name')}
                name={n(['data', 'companyLicense', 'legalName'])}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('Company legal type')}
                name={n(['data', 'companyLicense', 'legalType'])}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('Company license number')}
                name={n(['data', 'companyLicense', 'licenseNumber'])}
              >
                <LtrInput />
              </Form.Item>
              <Form.Item
                label={t('Date of establishment')}
                name={n(['data', 'companyLicense', 'dateOfEstablishment'])}
              >
                <DateInput disableDateRule="date <= today" />
              </Form.Item>
            </InputsGroupLayout>
          </Section>

          <Section>
            <Typography.Title level={4}>{t('Company location').toUpperCase()}</Typography.Title>
            <InputsGroupLayout>
              <Form.Item label={t('Country of incorporation')} name={n(['data', 'country'])}>
                <CountryInput />
              </Form.Item>
              <Form.Item label={t('City')} name={n(['data', 'city'])}>
                <Input autoComplete="none" />
              </Form.Item>
              <Form.Item label={t('Address line')} name={n(['data', 'addressLine'])}>
                <Input autoComplete="none" />
              </Form.Item>
              <Form.Item label={t('P.O. Box')} name={n(['data', 'poBox'])}>
                <Input autoComplete="none" />
              </Form.Item>
              <PhoneNumberFormItem name={n(['data', 'phoneNumber'])} />
            </InputsGroupLayout>
          </Section>

          <section>
            <CustomFields nFn={(name) => n(['data', ...[name].flat()])} />
          </section>

          <section>
            <Typography.Title level={4}>{t('Shares').toUpperCase()}</Typography.Title>
            <Typography.Paragraph>{t('Please specify ownership share')}</Typography.Paragraph>
            <InputsGroupLayout>
              <Form.Item label={t('Shares, %')} name={n('shares')}>
                <InputNumber className="BusinessForm-SharesInput" max={100} />
              </Form.Item>
            </InputsGroupLayout>
          </section>
        </Form>
      </Container>
    </FormItemContextProvider>
  );
};

export const formName = 'addNewLegalEntityShareholder';

const Container = styled.div`
  .BusinessForm-SharesInput {
    width: 100%;
  }
`;

export default BusinessForm;
