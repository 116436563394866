/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useMemo, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { get, isEmpty } from 'lodash';
import produce from 'immer';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { CheckThinIcon } from '~/img';
import { useGetApplication } from '~/controllers/wizard';
import { ApplicationPayload } from '~/types/ApplicationPayload';

import AuthorizedRepresentativeAdd from './AuthorizedRepresentativeAdd';
import IndividualAdd from './IndividualAdd';
import IndividualEdit from './IndividualEdit';
import { authorizedRepresentativeCommonNames } from '../authorizedRepresentative/AuthorizedRepresentativeFormItems';
import DeleteButton from './DeleteButton';
import EditButton from './EditButton';
import { Draft } from '~/types/Draft';
import { FormItemInjectedProps } from '~/types/FormItemInjectedProps';
import { Shareholders } from '~/types/Shareholders';
import { hasSoftDeclineFields } from '~/utils/softDeclinesFields';
import IndividualCardMobile from './IndividualCardMobile';
import { Table, Space } from '~/components';
import theme from '~/constants/theme';
import { isShareholderEditButtonDisabled } from './utils';

const IndividualTable: FC<Partial<FormItemInjectedProps<Shareholders>>> = (props) => {
  const { value, onChange } = props as FormItemInjectedProps<Shareholders>;
  const shareholdersFormState = { shareholders: value };

  const { t } = useTranslation('fillApplication');

  const applicationQuery = useGetApplication();
  const hasIndividualsValidationWarnings = hasSoftDeclineFields(
    applicationQuery.data,
    'shareholders.individuals[]'
  );

  const individualColumns: ColumnsType<IndividualRowType> = useMemo(
    () => [
      {
        title: t('First name'),
        dataIndex: 'firstName',
        key: 'firstName',
      },
      {
        title: t('Last name'),
        dataIndex: 'lastName',
        key: 'lastName',
      },
      {
        title: t('Passport'),
        dataIndex: 'hasPassport',
        key: 'hasPassport',
        render: (hasPassport: boolean) => {
          if (hasPassport) return <CheckThinIcon />;
          return null;
        },
      },
      {
        title: t('Emirates ID'),
        dataIndex: 'hasEmiratesId',
        key: 'hasEmiratesId',
        render: (hasEmiratesId: boolean) => {
          if (hasEmiratesId) return <CheckThinIcon />;
          return null;
        },
      },
      {
        title: t('Shares, %'),
        dataIndex: 'shares',
        key: 'shares',
      },
      {
        title: '',
        key: 'action',
        fixed: 'right',
        render: (_, { onEdit, onDelete }) => (
          <Space size={16} style={actionsStyle}>
            {onEdit && (
              <EditButton
                id="individualTable_EditButton"
                disabled={isShareholderEditButtonDisabled(
                  applicationQuery.data,
                  hasIndividualsValidationWarnings
                )}
                hasValidationWarnings={hasIndividualsValidationWarnings}
                onClick={onEdit}
              />
            )}
            <DeleteButton
              id="individualTable_DeleteButton"
              disabled={isShareholderEditButtonDisabled(
                applicationQuery.data,
                hasIndividualsValidationWarnings
              )}
              onClick={onDelete}
            />
          </Space>
        ),
      },
    ],
    [hasIndividualsValidationWarnings, applicationQuery.data, t]
  );

  const [editId, setId] = useState('');
  const openEdit = (uid: string) => {
    setId(uid);
  };
  const closeEdit = () => {
    setId('');
  };

  const payload = applicationQuery.data?.payload;

  const individualDataSource = getAllIndividualsData(
    payload,
    shareholdersFormState,
    onChange,
    openEdit
  );

  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.sm });

  return (
    <>
      <IndividualEdit uid={editId} onClose={closeEdit} {...props} />
      <Space size={24} direction="vertical">
        {!isEmpty(individualDataSource) && (
          <>
            {!isTablet ? (
              <Table
                id="individualsTable"
                columns={individualColumns}
                dataSource={individualDataSource}
                loading={applicationQuery.isLoading}
                pagination={pagination}
                rowKey="uid"
              />
            ) : (
              <Space size={12} direction="vertical">
                {individualDataSource.map((item) => (
                  <IndividualCardMobile
                    key={item.uid}
                    disableRowActions={isShareholderEditButtonDisabled(
                      applicationQuery.data,
                      hasIndividualsValidationWarnings
                    )}
                    hasIndividualsValidationWarnings={hasIndividualsValidationWarnings}
                    {...item}
                  />
                ))}
              </Space>
            )}
          </>
        )}
        {isSelectAuthorizedRepresentativeVisible(shareholdersFormState, payload) && (
          <AuthorizedRepresentativeAdd {...props} />
        )}
        <IndividualAdd {...props} />
      </Space>
    </>
  );
};

const pagination = { hideOnSinglePage: true };

export type IndividualRowType = {
  uid: string;
  firstName: string | undefined;
  lastName: string | undefined;
  hasPassport: boolean;
  hasEmiratesId: boolean;
  shares: number | undefined;
  onDelete: () => void;
  onEdit?: () => void;
};

const getAllIndividualsData = (
  payload: ApplicationPayload | undefined,
  data: Draft<'shareholders'> | undefined,
  onFormChange: (value: Shareholders) => void,
  openEdit: (uid: string) => void
): IndividualRowType[] => {
  const authorizedRepresentative: IndividualRowType[] =
    data?.shareholders?.authorizedRepresentative?.map((item) => {
      const person = payload?.authorizedRepresentative?.find((p) => p.uid === item?.uid);
      return {
        uid: item?.uid || '',
        firstName: get(person, authorizedRepresentativeCommonNames.firstName),
        lastName: get(person, authorizedRepresentativeCommonNames.lastName),
        hasPassport: !isEmpty(get(person, authorizedRepresentativeCommonNames.passport)),
        hasEmiratesId: !isEmpty(get(person, authorizedRepresentativeCommonNames.emiratesId)),
        shares: item?.shares,
        onDelete: () => {
          onFormChange(
            produce(data, (draft) => {
              draft.shareholders!.authorizedRepresentative =
                draft.shareholders!.authorizedRepresentative?.filter((a) => a?.uid !== item?.uid);
            }).shareholders as Shareholders
          );
        },
      };
    }) || [];

  const individuals: IndividualRowType[] =
    data?.shareholders?.individuals?.map((item) => {
      return {
        uid: item?.data?.uid as string,
        firstName: item?.data?.firstName,
        lastName: item?.data?.lastName,
        hasPassport: !isEmpty(item?.data?.passport?.files),
        hasEmiratesId: !isEmpty(item?.data?.emiratesId?.files),
        shares: item?.shares,
        onDelete: () =>
          onFormChange(
            produce(data, (draft) => {
              draft.shareholders!.individuals = draft.shareholders!.individuals!.filter(
                (a) => a?.data?.uid !== item?.data?.uid
              );
            }).shareholders as Shareholders
          ),
        onEdit: () => item?.data?.uid && openEdit(item.data.uid),
      };
    }) || [];

  return authorizedRepresentative.concat(individuals);
};

const isSelectAuthorizedRepresentativeVisible = (
  data: Draft<'shareholders'> | undefined,
  payload: ApplicationPayload | undefined
): boolean => {
  if (data?.shareholders?.authorizedRepresentative === undefined) return true;
  if (
    payload?.authorizedRepresentative === undefined ||
    payload.authorizedRepresentative.length === 0
  )
    return false;
  if (
    data.shareholders?.authorizedRepresentative.length === payload.authorizedRepresentative.length
  )
    return false;
  return true;
};

const actionsStyle = { display: 'flex', justifyContent: 'flex-end' };

export default IndividualTable;
