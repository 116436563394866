import { FormInstance } from 'antd';
import { get, set } from 'lodash';
import { ReactNode, useMemo } from 'react';
import { MutationOptions, useMutation, useQuery, useQueryClient } from 'react-query';
import { applyCustomPricing, getServices, requestCustomPricing } from '~/api/pricing';
import { scrollToFirstError } from '~/components/Form';
import { ServicesDraft } from '~/components/views/ServicesView/ServicesForm';
import {
  getCustomPricing,
  insertOmittedData,
  withRole,
} from '~/components/views/ServicesView/utils';
import { useGetApplication } from '~/controllers/wizard';
import { useReduxState } from '~/hooks';
import { ApiError } from '~/types/ApiError';
import { CustomPricing, ProductMap } from '~/types/CustomPricing';
import {
  CompositeServiceField,
  PossibleFieldValue,
  PrimitiveFieldMap,
  PrimitiveServiceField,
  SchemaServiceField,
  ServiceField,
  ServiceFieldTag,
  Services,
} from '~/types/Services';
import { NamePath } from '~/utils/buildNamePath';
import { useTotalPriceController, useTotalPriceState } from './totalPrice';
import { isMerchant } from '~/utils/getDecodedJwt';

export const useGetServices = (enabled = true) => {
  const applicationQuery = useGetApplication();
  const currentApplication = applicationQuery.data?.uid;

  const servicesQuery = useQuery(['services', currentApplication], getServices, {
    staleTime: Infinity,
    enabled: !!currentApplication && enabled,
  });

  return servicesQuery;
};

export const useUpdateGetApplicationCache = () => {
  const queryClient = useQueryClient();
  const applicationQuery = useGetApplication();
  const currentApplication = applicationQuery.data?.uid;

  return (value: Services) =>
    queryClient.setQueryData<Services>(['services', currentApplication], () => value);
};

const useApplyCustomPricing = () => {
  const updateCache = useUpdateGetApplicationCache();
  return useMutation(applyCustomPricing, {
    onSuccess(data) {
      updateCache(data);
    },
  });
};

export const useApplyCustomPricingIsLoading = () => {
  return useReduxState<boolean>('applyCustomPricingIsLoading', false);
};

export const useApplyCustomPricingController = (form: FormInstance<ServicesDraft>) => {
  const servicesQuery = useGetServices();
  const applyPricingMutation = useApplyCustomPricing();
  const [reduxIsLoading, setIsLoading] = useApplyCustomPricingIsLoading();
  const [totalPriceState] = useTotalPriceState();
  const totalPriceController = useTotalPriceController();

  const mutateAsync = async () => {
    setIsLoading(true);
    try {
      await form.validateFields();
      const productMap: Partial<ProductMap> = {};
      let additionalConfigurations: CustomPricing = {};
      const formValues = form.getFieldsValue();
      const currProductMapKeys = Object.keys(formValues.productMap || {});
      servicesQuery.data?.services.forEach((item) => {
        if (isMerchant()) {
          productMap[item.key] = getCustomPricing({
            fields: item.fields,
            initialValues: get(form.getFieldsValue(), ['productMap', item.key]),
          });
        } else if (currProductMapKeys.includes(item.key))
          productMap[item.key] = insertOmittedData({
            fields: item.fields,
            initialValues: get(formValues, ['productMap', item.key]),
          });
      });
      if (servicesQuery.data?.additionalConfigurations) {
        if (isMerchant()) {
          additionalConfigurations = getCustomPricing({
            fields: servicesQuery.data.additionalConfigurations,
            initialValues: get(form.getFieldsValue(), ['additionalConfigurations']),
          });
        } else {
          additionalConfigurations = insertOmittedData({
            fields: servicesQuery.data.additionalConfigurations,
            initialValues: get(formValues, ['additionalConfigurations']),
          });
        }
      }
      await applyPricingMutation.mutateAsync({
        productMap: productMap as ProductMap,
        additionalConfigurations,
      });
      if (totalPriceState.requiresRecalculate) {
        await totalPriceController.mutateAsync(formValues);
      }
    } catch (reason) {
      if (Array.isArray((reason as any)?.errorFields)) {
        const firstErrorName = (reason as any).errorFields[0].name as NamePath;
        form.scrollToField(firstErrorName, scrollToFirstError);
      }
      setIsLoading(false);
      console.log('reason', reason);
      return Promise.reject(reason);
    }
    setIsLoading(false);
    return Promise.resolve();
  };

  return { mutateAsync, isLoading: reduxIsLoading };
};

export const useRequestCustomPricing = (options?: MutationOptions<void, ApiError, string>) => {
  const servicesQuery = useGetServices();
  const mutation = useMutation(requestCustomPricing, {
    ...options,
  });

  return {
    ...mutation,
    mutate: null,
    mutateAsync: async (msg: string) => {
      await mutation.mutateAsync(msg);
      await servicesQuery.refetch();
    },
    isLoading: mutation.isLoading || servicesQuery.isLoading,
  };
};

export const useCustomPricingDrawerOpen = () => {
  return useReduxState('customPricingDrawerOpen', false);
};

const getOptions = (possibleValues: PossibleFieldValue[]): { value: string; label: string }[] => {
  return possibleValues.map(({ value, localizedValue }) => ({ value, label: localizedValue }));
};

export const useConfiguration = (
  fields: ServiceField[],
  tag: ServiceFieldTag = 'CONFIGURATION'
) => {
  const configMap = useMemo(() => {
    const result: Record<string, ServiceField> = {};
    fields.forEach((f) => {
      if (f.tag === tag) {
        result[f.key] = f;
      }
    });
    return result;
  }, [fields, tag]);

  const getName = (key: ServiceField['key']): NamePath => {
    return [key, 'value'];
  };

  return {
    configMap,
    getName,
    renderField: (
      key: ServiceField['key'],
      fn: (args: {
        disabled: boolean;
        name: NamePath;
        options?: { value: string; label: string }[];
        field: PrimitiveServiceField;
      }) => ReactNode
    ) => {
      if (!configMap?.[key]) return null;
      const serviceField = configMap[key];
      if (serviceField.type === 'composite' || serviceField.type === 'schema') return null;
      if (!withRole(serviceField.visible)) return null;
      return fn({
        disabled: !withRole(serviceField.editable),
        name: getName(key),
        options: serviceField.possibleValues ? getOptions(serviceField.possibleValues) : undefined,
        field: serviceField,
      });
    },
    renderCompositeField: (
      key: ServiceField['key'],
      fn: (args: { field: CompositeServiceField | SchemaServiceField }) => ReactNode
    ) => {
      if (!configMap?.[key]) return null;
      const serviceField = configMap[key];
      if (serviceField.type !== 'composite' && serviceField.type !== 'schema') return null;
      return fn({
        field: serviceField,
      });
    },
  };
};

export const useFieldMap = () => {
  const servicesQuery = useGetServices();
  const services = servicesQuery.data?.services;
  return useMemo(() => {
    if (services) {
      const result: Record<string, PrimitiveFieldMap> = {};

      services.forEach((s) => {
        s.fields.forEach((f) => {
          switch (f.type) {
            case 'composite': {
              if (f.booleanField) {
                set(result, [s.key, f.booleanField.key], f.booleanField);
              }
              if (f.pricingField) {
                set(result, [s.key, f.pricingField.key], f.pricingField);
              }
              if (f.schemaField) {
                set(result, [s.key, f.schemaField.key], f.schemaField);
              }
              f.fields?.forEach((ff) => {
                set(result, [s.key, ff.key], ff);
              });
              break;
            }
            default: {
              set(result, [s.key, f.key], f);
            }
          }
        });
      });

      return result;
    }
    return null;
  }, [services]);
};
