import { RcFile } from 'antd/lib/upload/interface';

import axios from './axios';
import { FileType, ImageResponse } from '~/types/files';

const currentFileStorage = () =>
  `${window.location.pathname}`.split('/')[1] === 'agent' ? 'agent' : 'merchant';

export const getFile = (file: FileType) => {
  return axios.get<Blob>(`/${currentFileStorage()}/files/${file.id}`, { responseType: 'blob' });
};

export const getFileById = (id: string) => {
  return axios.get<Blob>(`/${currentFileStorage()}/files/${id}`, { responseType: 'blob' });
};

export const uploadFile = <T = any>(data: FormData) => {
  return axios
    .post<T>(`/${currentFileStorage()}/files`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res) => res.data);
};

export const uploadImage = (file: FileType | RcFile) => {
  const data = new FormData();
  data.append('data', file);
  return uploadFile<ImageResponse>(data);
};
