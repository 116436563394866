import React, { FC, useMemo } from 'react';

import Form from '~/components/Form';
import { PricingServiceField, PrimitiveServiceField, ServiceField } from '~/types/Services';
import { NameFn } from '~/utils/buildNamePath';
import { isNumeric } from '~/utils/isNumeric';
import ResetToDefaultButton from './ResetToDefaultButton';
import { getChangedFields, getTotalFeePricingValue, ServiceInput, withRole } from './utils';

interface Props {
  id: string;
  n: NameFn;
  fields: ServiceField[];
  showTotalPriceField?: boolean;
  productMapTempN?: NameFn;
  count?: number;
}

const ResetServiceFields: FC<Props> = (props) => {
  const { id, fields, n, showTotalPriceField, productMapTempN, count } = props;

  const inputsDefaultData = useMemo(() => {
    const result: ServiceInput[] = [];

    const addPrimitiveInput = (f: PrimitiveServiceField) => {
      switch (f.type) {
        case 'boolean':
        case 'string': {
          if (withRole(f.visible)) {
            result.push({
              name: n([f.key, 'value']),
              value: f.defaultValue,
            });
          }
          break;
        }
        default:
          if (f.value.defaultValue.amount && !f.amountFrom && withRole(f.visible)) {
            result.push({
              name: n([f.key, 'amount']),
              value: f.value.defaultValue.amount,
            });
          }
          if (
            typeof f.value.defaultValue.percent === 'number' &&
            !f.percentFrom &&
            withRole(f.visible)
          ) {
            result.push({
              name: n([f.key, 'percent']),
              value: f.value.defaultValue.percent,
            });
          }
      }
    };

    fields?.forEach((f) => {
      switch (f.type) {
        case 'composite': {
          if (f.booleanField) {
            addPrimitiveInput(f.booleanField);
          }
          if (f.schemaField) {
            f.schemaField.fields.forEach((subFee) => {
              addPrimitiveInput(subFee);
            });
          }
          if (f.pricingField) {
            addPrimitiveInput(f.pricingField);
          }
          f.fields?.forEach((ff) => {
            addPrimitiveInput(ff);
          });
          break;
        }
        case 'schema': {
          f.fields.forEach((subFee) => {
            addPrimitiveInput(subFee);
          });
          break;
        }
        default:
          addPrimitiveInput(f);
      }
    });
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  const resultInputsDefaultData = useMemo(() => {
    let result = inputsDefaultData;
    if (showTotalPriceField && productMapTempN && isNumeric(count)) {
      const terminalFeeField = fields.find((f) => f.key === 'terminalFee') as PricingServiceField;
      result = inputsDefaultData.concat([
        {
          name: productMapTempN(['terminalFee', 'amount']),
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          value: getTotalFeePricingValue(terminalFeeField.value.defaultValue, count!).amount,
        },
      ]);
    }
    return result;
  }, [inputsDefaultData, showTotalPriceField, productMapTempN, count, fields]);

  return (
    <Form.Item shouldUpdate noStyle>
      {(form) => {
        const changedFields =
          getChangedFields(form.getFieldsValue(), resultInputsDefaultData) || [];
        const hasChanges = changedFields.length > 0;
        return (
          <ResetToDefaultButton
            id={id}
            disabled={!hasChanges}
            onClick={() => {
              form.setFields(changedFields);
            }}
          />
        );
      }}
    </Form.Item>
  );
};

export default ResetServiceFields;
