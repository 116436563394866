/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { FC } from 'react';
import { Radio } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import buildNamePath from '~/utils/buildNamePath';
import { useGetApplication } from '~/controllers/wizard';
import { useNavigateToStepByUiKey } from '~/controllers/navigation';
import { Alert, Form } from '~/components';
import { getStagePayload } from '~/utils/getStagePayload';

const PepInputs: FC = () => {
  const { t } = useTranslation('fillApplication');

  const applicationQuery = useGetApplication();
  const navigateToStepByUiKey = useNavigateToStepByUiKey();

  const stagePayload = getStagePayload.wizard(applicationQuery.data);
  const applicationSteps = stagePayload?.steps.find((item) => item.key === 'application')?.steps;

  const authorizedRepresentativeUiKey = applicationSteps?.find(
    (step) => step.uiKey === 'authorizedRepresentative'
  )?.uiKey;
  const shareholdersUiKey = applicationSteps?.find((step) => step.uiKey === 'shareholders')?.uiKey;

  return (
    <Container>
      <Form.Item name={n(['hasPep'])}>
        <Radio.Group className="PepInputs-PepRadio">
          <Radio value>{t('Yes')}</Radio>
          <Radio value={false}>{t('No')}</Radio>
        </Radio.Group>
      </Form.Item>
      {authorizedRepresentativeUiKey && shareholdersUiKey ? (
        <Form.Visible name={n(['hasPep'])}>
          <Alert
            type="warning"
            className="PepInputs-Alert"
            message={
              <Trans
                t={t}
                i18nKey="Indicate at least one <1>Authorized Representative</1> or <3>Individual Shareholder</3> as a PEP person."
              >
                {'Indicate at least one '}
                <a
                  role="button"
                  onClick={() => navigateToStepByUiKey.navigate('authorizedRepresentative')}
                >
                  Authorized Representative
                </a>
                {' or '}
                <a role="button" onClick={() => navigateToStepByUiKey.navigate('shareholders')}>
                  Individual Shareholder
                </a>
                {' as a PEP person.'}
              </Trans>
            }
          />
        </Form.Visible>
      ) : null}
    </Container>
  );
};

const n = buildNamePath('additionalInformation');

const Container = styled.div`
  .PepInputs-PepRadio {
    margin-top: 16px;
  }
  .PepInputs-Alert {
    margin-bottom: 24px;
  }
`;

export default PepInputs;
