import React, { FC } from 'react';
import { SelectProps } from 'antd';
import Select from './Select';
import { useAgents } from '~/controllers/agent';
import { useIsTablet } from '~/hooks/useScreenSizes';

const AssigneeSelect: FC<SelectProps<string>> = (props) => {
  const agents = useAgents();
  const isTablet = useIsTablet();
  const options = agents.data?.map(({ id, fullName }) => ({
    label: fullName,
    value: id,
    key: id,
  }));

  return (
    <Select
      loading={agents.isLoading}
      disabled={agents.isLoading}
      showSearch={!isTablet}
      options={options}
      {...props}
    />
  );
};

export default AssigneeSelect;
