import React, { FC, memo } from 'react';
import DebugAlert from '~/components/DebugAlert';
import Popover from '~/components/Popover';
import {
  MarkerApplicationDoneIcon,
  MarkerApplicationOpenedIcon,
  MarkerLeadDoneIcon,
  MarkerLeadOpenedIcon,
} from '~/img';
import { AgentMapItem, DashboardStatus, MapItemType } from '~/types/AgentMapItem';
import LocationInfo from './LocationInfo';

import './styles.less';

interface Props {
  record: AgentMapItem;
  onClick?: () => void;
  [mapSpecificProps: string]: any;
}

const Marker: FC<Props> = (props) => {
  const { record, onClick } = props;
  return (
    <Popover placement="top" content={<LocationInfo record={record} />}>
      {getIcon(record.type, record.dashboardStatus, onClick)}
    </Popover>
  );
};

const getIcon = (type: MapItemType, dashboardStatus: DashboardStatus, onClick?: () => void) => {
  switch (type) {
    case MapItemType.APPLICATION: {
      if (dashboardStatus === DashboardStatus.OPENED) {
        return <MarkerApplicationOpenedIcon className="LocationIcon" onClick={onClick} />;
      }
      if (dashboardStatus === DashboardStatus.DONE) {
        return <MarkerApplicationDoneIcon className="LocationIcon" onClick={onClick} />;
      }
      return <DebugAlert message="unknown dashboardStatus" style={debugAlertStyle} />;
    }
    case MapItemType.LEAD: {
      if (dashboardStatus === DashboardStatus.OPENED) {
        return <MarkerLeadOpenedIcon className="LocationIcon" onClick={onClick} />;
      }
      if (dashboardStatus === DashboardStatus.DONE) {
        return <MarkerLeadDoneIcon className="LocationIcon" onClick={onClick} />;
      }
      return <DebugAlert message="unknown dashboardStatus" style={debugAlertStyle} />;
    }
    default:
      return <DebugAlert message="unknown mapItem.type" style={debugAlertStyle} />;
  }
};

const debugAlertStyle = { width: 200 };

export default memo(Marker);
