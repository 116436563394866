/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { cloneElement, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import colors from '~/constants/colors';
import { useDirection } from '~/hooks/i18nHooks';
import { Direction } from '~/types/Direction';
import direction from '~/utils/direction';

interface FloatLabelProps {
  label?: string;
  value?: any;
  onChange?: (...args: any[]) => void;
  children?: JSX.Element;
  optional?: boolean;
  disabled?: boolean;
  textarea?: boolean;
  [x: string]: any;
}

const FloatLabel: FC<FloatLabelProps> = (props) => {
  const { label, children, value, required, disabled, optional, textarea, ...rest } = props;
  const [focus, setFocus] = useState(false);
  const { t } = useTranslation();
  const dir = useDirection();

  const childProps =
    disabled === undefined ? { value, required, ...rest } : { value, required, disabled, ...rest };
  const element = children ? cloneElement(children, childProps) : null;

  const labelResult = optional ? t('{{label}} (optional)', { label }) : label;
  let { placeholder } = rest;
  if (!placeholder) placeholder = labelResult;

  const isOccupied = label && (focus || (value && value.length !== 0) || value === 0);

  return (
    <Container
      className="FloatLabel"
      isOccupied={isOccupied}
      dir={dir}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
      textarea={!!textarea}
    >
      {element}
      {label && (
        <label className="FloatLabel-Label">
          {isOccupied ? labelResult : placeholder} {required ? <span>*</span> : null}
        </label>
      )}
    </Container>
  );
};

const dirCss = css`
  &[dir='rtl'] {
    .ant-input-number-input {
      direction: ltr;
      text-align: right;
    }
    .ant-picker-clear {
      right: unset;
      left: 0;
    }
    .ant-picker-suffix {
      margin-left: 0;
      margin-right: 4px;
    }
    .ant-input-number-handler-wrap {
      right: unset;
      left: 0;
      border-radius: 8px 0 0 8px;
      .ant-input-number-handler {
        border-left: unset;
        border-right: 1px solid ${colors.grey[300]};
      }
      .ant-input-number-handler-up {
        border-top-right-radius: unset;
        border-top-left-radius: 8px;
      }
      .ant-input-number-handler-down {
        border-bottom-right-radius: unset;
        border-bottom-left-radius: 8px;
      }
    }
  }
`;

type UIProps = { isOccupied: boolean; dir: Direction; textarea: boolean };

const Container = styled.div<UIProps>`
  position: relative;
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 15px;
  }
  .ant-input-lg {
    padding: 10.5px 15px;
  }
  .FloatLabel-Label {
    position: absolute;
    pointer-events: none;
    ${direction.left(15)};
    top: ${({ textarea }) => (textarea ? '11px' : 'calc(50% - 12px)')};
    transition: 0.2s ease all;
    border-radius: 7px;
    color: ${colors.inputLabelColor};
    ${({ isOccupied }) =>
      isOccupied
        ? css`
            top: -9px;
            ${direction.left(12)};
            font-size: 12px !important;
            background: ${colors.componentBackground};
            padding: 0 8px;
            ${direction.margin.left(-4)};
          `
        : css``}
  }

  ${dirCss}
`;

export default FloatLabel;
