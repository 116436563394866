import React, { FC } from 'react';
import { SelectProps } from 'antd';

import { Select } from '~/components';
import { useHandbook } from '~/controllers/handbook';

const CountryInput: FC<SelectProps<string>> = (props) => {
  const handbook = useHandbook();
  const options = handbook.data?.handbooks
    ?.find((item) => item.key === 'entitycountryofestablishment')
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ?.values?.map((item) => ({ label: item.localizedValue, value: item.apiValue!, key: item.key }))
    .sort((a, b) => {
      return a.label > b.label ? 1 : -1;
    })
    .sort((a) => {
      if (a.label === priorCountry) return -1;
      return 1;
    });

  return (
    <Select
      options={options}
      showSearch
      allowClear
      {...props}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      autoComplete="none"
    />
  );
};

const priorCountry = 'United Arab Emirates';

export default CountryInput;
