import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ColumnsType } from 'antd/lib/table';
import { useHistory } from 'react-router';
import {
  useCreateApplication,
  useGetLeads,
  useSubmitLead,
  useTerminateLead,
} from '~/controllers/agent';
import PageHeader from '~/components/views/agent/PageHeader';
import { AgentLead } from '~/types/AgentLead';
import AgentTable from '~/components/views/agent/tableElements/AgentTable';
import {
  companyNameCell,
  emirateCell,
  businessLineCell,
  createdCell,
  updatedCell,
  referralCodeCell,
  statusCell,
  contactCell,
  riskLevelCell,
  assigneeCell,
} from '~/components/views/agent/tableElements/cells';
import AgentTableActions from '~/components/views/agent/AgentTableActions';
import TerminationDrawer from '~/components/views/agent/TerminationDrawer';
import CreatingApplicationModal from '~/components/views/agent/CreatingApplicationModal';
import LeadShow from '~/components/views/agent/leadForm/LeadShow';
import { goToApplication } from '~/utils/goToApplication';
import { isTeamLead } from '~/utils/getDecodedJwt';
import AgentFilters from './filters/AgentFilters';
import { useHandbook } from '~/controllers/handbook';
import AgentFiltersButton from '~/components/views/agent/filters/AgentFiltersButton';
import { useIsTablet } from '~/hooks/useScreenSizes';

const AgentLeadTable = () => {
  const { data, isFetching, total, refetch } = useGetLeads();
  const { t } = useTranslation('agent');
  const [openLead, setOpenLead] = useState(false);
  const [terminatedOpen, setTerminatedOpen] = useState(false);
  const [creatingApplicationOpen, setCreatingApplicationOpen] = useState(false);
  const [savedRecord, setSavedRecord] = useState<AgentLead | undefined>(undefined);
  const history = useHistory();
  const submitLead = useSubmitLead();
  const createApplication = useCreateApplication();
  const terminateLead = useTerminateLead();
  const { data: handbook } = useHandbook();

  const columns = useMemo<ColumnsType<AgentLead>>(
    () => [
      companyNameCell(),
      emirateCell(handbook),
      riskLevelCell(),
      businessLineCell(handbook),
      createdCell(),
      updatedCell(),
      statusCell(),
      contactCell(),
      isTeamLead() ? assigneeCell('LEAD') : {},
      referralCodeCell(),
      {
        title: '',
        key: 'actions',
        render: (_, record) => (
          <AgentTableActions
            actions={[
              {
                key: 'agentLeadTableActions-Terminate',
                title: t('Terminate'),
                disabled: record?.statusKey === 'CONVERTED' || record?.statusKey === 'TERMINATED',
                action: () => {
                  setSavedRecord(record);
                  setTerminatedOpen(true);
                },
              },
              {
                key: 'agentLeadTableActions-FlagAsContacted',
                title: t('Flag as Contacted'),
                disabled:
                  !record ||
                  record.statusKey !== 'NEW' ||
                  !record.payload.contactPerson.email ||
                  !record.payload.contactPerson.firstName ||
                  !record.payload.contactPerson.lastName ||
                  !record.payload.contactPerson.phoneNumber,
                action: async () => {
                  await submitLead.mutateAsync({
                    ...record?.payload,
                    leadId: record?.leadId,
                    isContacted: true,
                  });
                  await refetch();
                },
              },
              {
                key: 'agentLeadTableActions-CreateApplication',
                title: t('Create Application'),
                disabled: record?.statusKey === 'CONVERTED' || record?.statusKey === 'TERMINATED',
                action: () => {
                  setSavedRecord(record);
                  setCreatingApplicationOpen(true);
                },
              },
            ]}
          />
        ),
        width: 30,
      },
    ],
    [t, submitLead, refetch, handbook]
  );

  const isTablet = useIsTablet();

  return (
    <Container>
      <PageHeader heading={t('leads')} isFetching={isFetching}>
        {!isTablet ? <AgentFilters /> : <AgentFiltersButton />}
      </PageHeader>
      <AgentTable
        id="agentLeadsTable"
        type="LEAD"
        rowKey={(record) => record.leadId}
        dataSource={data}
        columns={columns}
        tableRefetch={refetch}
        totalPages={total}
        onRow={(record) => ({
          onClick: () => {
            setSavedRecord({ ...record }); // to update initialValues if same lead was selected twice in a row
            setOpenLead(true);
          },
        })}
      />
      <LeadShow open={openLead} initialValues={savedRecord} onClose={() => setOpenLead(false)} />
      <TerminationDrawer
        visible={terminatedOpen}
        companyName={savedRecord?.companyName}
        onTerminate={async (terminationReason) => {
          if (savedRecord?.leadId) {
            await terminateLead.mutateAsync({
              id: savedRecord.leadId,
              terminationReason,
            });
            await refetch();
          }
        }}
        onClose={() => {
          setTerminatedOpen(false);
        }}
      />
      <CreatingApplicationModal
        onClose={() => {
          setCreatingApplicationOpen(false);
        }}
        visible={creatingApplicationOpen}
        onConfirm={() => {
          createApplication
            .mutateAsync({ leadId: savedRecord?.leadId ?? '' })
            .then((application) => goToApplication(history, application?.uid))
            .catch(() => {});
        }}
        loading={createApplication.isLoading}
      />
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export default AgentLeadTable;
