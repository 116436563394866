import React, { FC } from 'react';
import { SelectProps } from 'antd';
import { useHandbook } from '~/controllers/handbook';
import Select from './Select';
import { useIsTablet } from '~/hooks/useScreenSizes';

const EmirateSelect: FC<SelectProps<string>> = (props) => {
  const handbook = useHandbook();
  const isTablet = useIsTablet();
  const options = handbook.data?.handbooks
    ?.find((item) => item.key === 'city')
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ?.values?.map((item) => ({ label: item.localizedValue, value: item.apiValue!, key: item.key }));

  return (
    <Select
      loading={handbook.isLoading}
      disabled={handbook.isLoading}
      showSearch={!isTablet}
      options={options}
      {...props}
    />
  );
};

export default EmirateSelect;
