import { createIcon, createImg } from './utils';

import logo from './logo.svg';
import logoWithText from './logoWithText.svg';
import logoWithTextAr from './logoWithTextAr.svg';
import servicesBanner from './servicesBanner.jpg';
import servicesBannerAr from './servicesBannerAr.jpg';
import screeningError from './screeningError.png';
import submitStage from './submitStage.png';
import successfulOnboarding from './successfulOnboarding.png';
import completed from './completed.png';
import paymentMethods from './paymentMethods.png';
import salesforceSoftDecline from './salesforceSoftDecline.png';
import companyTypeBackground from './companyTypeBackground.jpg';

import { ReactComponent as LogOut } from './icons/logOutOutline.svg';
import { ReactComponent as UserAvatar } from './icons/userAvatar.svg';
import { ReactComponent as Services } from './icons/services.svg';
import { ReactComponent as Application } from './icons/application.svg';
import { ReactComponent as Contract } from './icons/contract.svg';
import { ReactComponent as Payment } from './icons/payment.svg';
import { ReactComponent as Completion } from './icons/completion.svg';
import { ReactComponent as ArrowRight } from './icons/arrowRight.svg';
import { ReactComponent as Arrow } from './icons/arrow.svg';
import { ReactComponent as ArrowSort } from './icons/arrowSort.svg';
import { ReactComponent as Circle } from './icons/circle.svg';
import { ReactComponent as SuccessCheckCircle } from './icons/successCheckCircle.svg';
import { ReactComponent as Checkmark } from './icons/checkmark.svg';
import { ReactComponent as CheckThin } from './icons/checkThin.svg';
import { ReactComponent as Menu } from './icons/menu.svg';
import { ReactComponent as Location } from './icons/location.svg';
import { ReactComponent as LocationOutlined } from './icons/locationOutlined.svg';
import { ReactComponent as Marker } from './icons/marker.svg';
import { ReactComponent as Close } from './icons/close.svg';
import { ReactComponent as CloseSharp } from './icons/closeSharp.svg';
import { ReactComponent as Upload } from './icons/upload.svg';
import { ReactComponent as Signature } from './icons/signature.svg';
import { ReactComponent as Business } from './icons/business.svg';
import { ReactComponent as Individuals } from './icons/individuals.svg';
import { ReactComponent as PersonAdd } from './icons/personAdd.svg';
import { ReactComponent as PersonCheck } from './icons/personCheck.svg';
import { ReactComponent as Delete } from './icons/delete.svg';
import { ReactComponent as Edit } from './icons/edit.svg';
import { ReactComponent as Plus } from './icons/plus.svg';
import { ReactComponent as Hint } from './icons/hint.svg';
import { ReactComponent as ContractGeneration } from './icons/contractGeneration.svg';
import { ReactComponent as Pdf } from './icons/pdf.svg';
import { ReactComponent as Download } from './icons/download.svg';
import { ReactComponent as Warning } from './icons/warning.svg';
import { ReactComponent as WarningCircle } from './icons/warningCircle.svg';
import { ReactComponent as ContactUs } from './icons/contactUs.svg';
import { ReactComponent as ShoppingBasket } from './icons/shoppingBasket.svg';
import { ReactComponent as Pin } from './icons/pin.svg';
import { ReactComponent as NotPin } from './icons/notPin.svg';
import { ReactComponent as EllipsisVertical } from './icons/ellipsisVertical.svg';
import { ReactComponent as Dashboard } from './icons/dashboard.svg';
import { ReactComponent as Email } from './icons/email.svg';
import { ReactComponent as Phone } from './icons/phone.svg';
import { ReactComponent as RequestActive } from './icons/requestActive.svg';
import { ReactComponent as RequestApproved } from './icons/requestApproved.svg';
import { ReactComponent as RequestReview } from './icons/requestReview.svg';
import { ReactComponent as Globe } from './icons/globe.svg';
import { ReactComponent as ArrowDown } from './icons/arrowDown.svg';
import { ReactComponent as Reset } from './icons/reset.svg';
import { ReactComponent as MarkerLeadOpened } from './icons/markerLeadOpened.svg';
import { ReactComponent as MarkerLeadDone } from './icons/markerLeadDone.svg';
import { ReactComponent as MarkerApplicationOpened } from './icons/markerApplicationOpened.svg';
import { ReactComponent as MarkerApplicationDone } from './icons/markerApplicationDone.svg';
import { ReactComponent as UaePassLogo } from './icons/uaePassLogo.svg';
import { ReactComponent as Filter } from './icons/filter.svg';
import { ReactComponent as FilterEmpty } from './icons/filterEmpty.svg';
import { ReactComponent as ApplicationsMenu } from './icons/agentMenu/applications.svg';
import { ReactComponent as LeadsMenu } from './icons/agentMenu/leads.svg';
import { ReactComponent as MapMenu } from './icons/agentMenu/map.svg';
import { ReactComponent as AgentMenu } from './icons/agentMenu/menu.svg';

import { ReactComponent as Alipay } from './paymentSchema/alipay.svg';
import { ReactComponent as Diners } from './paymentSchema/diners.svg';
import { ReactComponent as Jcb } from './paymentSchema/jcb.svg';
import { ReactComponent as Mastercard } from './paymentSchema/mastercard.svg';
import mercury from './paymentSchema/mercury.png';
import { ReactComponent as Mir } from './paymentSchema/mir.svg';
import { ReactComponent as UnionPay } from './paymentSchema/unionPay.svg';
import { ReactComponent as Visa } from './paymentSchema/visa.svg';
import { ReactComponent as WeChatPay } from './paymentSchema/weChatPay.svg';
import { ReactComponent as Rupay } from './paymentSchema/rupay.svg';

export const LogoImg = createImg(logo, 'logo');
export const LogoWithTextImg = createImg(logoWithText, 'logo');
export const LogoWithTextArImg = createImg(logoWithTextAr, 'logo');
export const ServicesBannerImg = createImg(servicesBanner, 'servicesBanner');
export const ServicesBannerArImg = createImg(servicesBannerAr, 'servicesBanner');
export const ScreeningErrorImg = createImg(screeningError, 'screeningError');
export const SubmitStageImg = createImg(submitStage, 'submitStage');
export const SuccessfulOnboardingImg = createImg(successfulOnboarding, 'successfulOnboarding');
export const CompletedImg = createImg(completed, 'completed');
export const PaymentMethodsImg = createImg(paymentMethods, 'paymentMethods');
export const SalesforceSoftDeclineImg = createImg(salesforceSoftDecline, 'salesforceSoftDecline');
export const CompanyTypeBackgroundImg = createImg(companyTypeBackground, 'companyTypeBackground');

export const LogOutIcon = createIcon(LogOut);
export const UserAvatarIcon = createIcon(UserAvatar);
export const ServicesIcon = createIcon(Services);
export const ApplicationIcon = createIcon(Application);
export const ContractIcon = createIcon(Contract);
export const PaymentIcon = createIcon(Payment);
export const CompletionIcon = createIcon(Completion);
export const ArrowRightIcon = createIcon(ArrowRight);
export const ArrowIcon = createIcon(Arrow);
export const ArrowSortIcon = createIcon(ArrowSort);
export const CircleIcon = createIcon(Circle);
export const SuccessCheckCircleIcon = createIcon(SuccessCheckCircle);
export const CheckmarkIcon = createIcon(Checkmark);
export const CheckThinIcon = createIcon(CheckThin);
export const MenuIcon = createIcon(Menu);
export const LocationIcon = createIcon(Location);
export const LocationOutlinedIcon = createIcon(LocationOutlined);
export const MarkerIcon = createIcon(Marker);
export const CloseIcon = createIcon(Close);
export const CloseSharpIcon = createIcon(CloseSharp);
export const UploadIcon = createIcon(Upload);
export const SignatureIcon = createIcon(Signature);
export const BusinessIcon = createIcon(Business);
export const IndividualsIcon = createIcon(Individuals);
export const PersonAddIcon = createIcon(PersonAdd);
export const PersonCheckIcon = createIcon(PersonCheck);
export const DeleteIcon = createIcon(Delete);
export const EditIcon = createIcon(Edit);
export const PlusIcon = createIcon(Plus);
export const HintIcon = createIcon(Hint);
export const ContractGenerationIcon = createIcon(ContractGeneration);
export const PdfIcon = createIcon(Pdf);
export const DownloadIcon = createIcon(Download);
export const WarningIcon = createIcon(Warning);
export const WarningCircleIcon = createIcon(WarningCircle);
export const ContactUsIcon = createIcon(ContactUs);
export const ShoppingBasketIcon = createIcon(ShoppingBasket);
export const PinIcon = createIcon(Pin);
export const NotPinIcon = createIcon(NotPin);
export const EllipsisVerticalIcon = createIcon(EllipsisVertical);
export const DashboardIcon = createIcon(Dashboard);
export const EmailIcon = createIcon(Email);
export const PhoneIcon = createIcon(Phone);
export const RequestActiveIcon = createIcon(RequestActive);
export const RequestReviewIcon = createIcon(RequestReview);
export const RequestApprovedIcon = createIcon(RequestApproved);
export const GlobeIcon = createIcon(Globe);
export const ArrowDownIcon = createIcon(ArrowDown);
export const ResetIcon = createIcon(Reset);
export const MarkerLeadOpenedIcon = createIcon(MarkerLeadOpened);
export const MarkerLeadDoneIcon = createIcon(MarkerLeadDone);
export const MarkerApplicationOpenedIcon = createIcon(MarkerApplicationOpened);
export const MarkerApplicationDoneIcon = createIcon(MarkerApplicationDone);
export const FilterIcon = createIcon(Filter);
export const FilterEmptyIcon = createIcon(FilterEmpty);
export const ApplicationsMenuIcon = createIcon(ApplicationsMenu);
export const LeadsMenuIcon = createIcon(LeadsMenu);
export const MapMenuIcon = createIcon(MapMenu);
export const AgentMenuIcon = createIcon(AgentMenu);

export const AlipayIcon = createIcon(Alipay);
export const DinersIcon = createIcon(Diners);
export const JcbIcon = createIcon(Jcb);
export const MastercardIcon = createIcon(Mastercard);
export const MercuryImg = createImg(mercury, 'mercury');
export const MirIcon = createIcon(Mir);
export const UnionPayIcon = createIcon(UnionPay);
export const VisaIcon = createIcon(Visa);
export const WeChatPayIcon = createIcon(WeChatPay);
export const RupayIcon = createIcon(Rupay);
export const UaePassLogoIcon = createIcon(UaePassLogo);
