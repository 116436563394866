import React, { FC } from 'react';
import { SelectProps } from 'antd';
import { useTranslation } from 'react-i18next';
import Select from './Select';
import { AgentApplicationStatus } from '~/types/AgentApplication';
import { useIsTablet } from '~/hooks/useScreenSizes';

const status: Record<string, AgentApplicationStatus> = {
  duplicate: 'duplicate',
  draft: 'draft',
  terminated: 'terminated',
  fillingData: 'fillingData',
  rejected: 'rejected',
  screeningFailed: 'screeningFailed',
  onReview: 'onReview',
  approval: 'approval',
  onboarding: 'onboarding',
  posPreparation: 'posPreparation',
  posInstallation: 'posInstallation',
  completed: 'completed',
  hardRejected: 'hardRejected',
  screening: 'screening',
  contract: 'contract',
  payment: 'payment',
  submissionFailure: 'submissionFailure',
};

const ApplicationStatusSelect: FC<SelectProps<string>> = (props) => {
  const { t } = useTranslation('applicationStatus');
  const isTablet = useIsTablet();
  const options = Object.values(status).map((value: any) => ({
    label: t(value),
    value,
    key: value,
  }));

  return <Select showSearch={!isTablet} options={options} {...props} />;
};

export default ApplicationStatusSelect;
