import React, { FC } from 'react';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
}

const LeftLayout: FC<Props> = ({ children }: Props) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 2em;
`;

export default LeftLayout;
