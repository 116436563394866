import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ColumnsType } from 'antd/lib/table';
import FormDrawer from '~/components/FormDrawer';
import { AgentApplication } from '~/types/AgentApplication';
import { Button, Form, TextField } from '~/components';
import { openNewApplicationTab } from '~/utils/goToApplication';
import TerminationDrawer from '~/components/views/agent/TerminationDrawer';
import { useAssignApplication, useTerminateApplication } from '~/controllers/agent';
import GroupLayout from '~/layouts/GroupLayout';
import { AgentLead, AgentLeadPayload } from '~/types/AgentLead';
import {
  businessLineCell,
  createdCell,
  emirateCell,
  riskLevelCell,
  statusCell,
  updatedCell,
} from '~/components/views/agent/tableElements/cells';
import { useHandbook } from '~/controllers/handbook';
import InputsGroupLayout from '~/layouts/InputsGroupLayout';
import AssigneeSelect from '../../../inputs/AssigneeSelect';
import FormItem from '~/components/Form/FormItem';
import { queryClient } from '~/index';
import { getDecodedJwt, isTeamLead } from '~/utils/getDecodedJwt';
import { DeleteIcon } from '~/img';

interface Props {
  open: boolean;
  onClose: () => void;
  record?: AgentApplication;
}

const ApplicationShow: FC<Props> = (props) => {
  const { record, open, onClose } = props;
  const [t] = useTranslation('agent');
  const [terminatedOpen, setTerminatedOpen] = useState(false);
  const { data: handbook } = useHandbook();
  const terminateApplication = useTerminateApplication();
  const assignApplication = useAssignApplication();

  if (!record) return null;

  const { emirate, statusKey, businessLine, riskLevel, created, updated, assignee } = record;
  const isAssignedToCurrentUser = getDecodedJwt()?.email !== record.assignee?.email;
  const isTerminated = record?.statusKey === 'terminated';

  const onFinish = ({ agentId }: { agentId: string }) => {
    assignApplication
      .mutateAsync({ applicationId: record.applicationId, agentId })
      .then(async () => {
        onClose();
        await queryClient.refetchQueries('applications');
      })
      .catch(() => {});
  };

  return (
    <>
      <FormDrawer
        id="application"
        visible={open}
        onClose={onClose}
        title={record?.companyName}
        closable
        formName={formName}
        withCustomButtons
        withContinueButton={false}
        customButtonsProps={[
          ...(isTeamLead()
            ? [
                {
                  id: 'saveAssignee',
                  title: t('Save', { ns: 'common' }),
                  type: 'default',
                  htmlType: 'submit',
                  loading: assignApplication.isLoading,
                  disabled: isTerminated,
                  danger: true,
                  form: formName,
                } as const,
              ]
            : []),
          {
            id: 'goToApplicationButton',
            title: t('Go to Application'),
            type: 'primary',
            onClick: () => openNewApplicationTab(record.applicationId),
            disabled: isAssignedToCurrentUser,
          },
        ]}
      >
        <Container>
          <GroupLayout gutter={[16, 16]} xs={12} sm={12} className="ApplicationData">
            <TextField id="ApplicationShow_emirate" label={t('Emirate')}>
              {render(emirateCell(handbook), emirate, record)}
            </TextField>
            <TextField id="ApplicationShow_status" label={t('Status')}>
              {render(statusCell(), statusKey, record)}
            </TextField>
            <TextField id="ApplicationShow_businessLine" label={t('Business line')}>
              {render(businessLineCell(handbook), businessLine, record)}
            </TextField>
            <TextField id="ApplicationShow_riskLevel" label={t('Risk level')}>
              {render(riskLevelCell(), riskLevel, record)}
            </TextField>
            <TextField id="ApplicationShow_created" label={t('Created')}>
              {render(createdCell(), created, record)}
            </TextField>
            <TextField id="ApplicationShow_updated" label={t('Updated')}>
              {render(updatedCell(), updated, record)}
            </TextField>
          </GroupLayout>
          {isTeamLead() && (
            <AssigneeForm disabled={isTerminated} assignee={assignee?.id} onFinish={onFinish} />
          )}
        </Container>
        <ApplicationTerminateButton
          id="terminateApplicationButton"
          type="default"
          block
          danger
          icon={<DeleteIcon />}
          disabled={isTerminated}
          onClick={() => setTerminatedOpen(true)}
        >
          {t('Terminate Application')}
        </ApplicationTerminateButton>
      </FormDrawer>
      <TerminationDrawer
        visible={terminatedOpen}
        companyName={record?.companyName}
        onTerminate={async (terminationReason) => {
          if (record?.applicationId) {
            await terminateApplication.mutateAsync({
              id: record.applicationId,
              terminationReason,
            });
            await queryClient.refetchQueries('applications');
          }
        }}
        onClose={() => {
          setTerminatedOpen(false);
          onClose();
        }}
      />
    </>
  );
};

const formName = 'applicationAssignee';

interface AssigneeFormProps {
  assignee?: any;
  onFinish: (values: any) => void;
  disabled?: boolean;
}

const AssigneeForm = ({ assignee, onFinish, disabled }: AssigneeFormProps) => {
  const [form] = Form.useForm<AgentLeadPayload>();
  const { t } = useTranslation('agent');

  return (
    <Form form={form} name={formName} onFinish={onFinish}>
      <InputsGroupLayout>
        <FormItem
          label={t('Assignee')}
          name="agentId"
          disabled={disabled}
          initialValue={assignee}
          rules={[{ required: true }]}
        >
          <AssigneeSelect />
        </FormItem>
      </InputsGroupLayout>
    </Form>
  );
};

const render = (f: ColumnsType<any>[0], value: any, record: AgentApplication | AgentLead) =>
  f.render?.(value, record, 0);

const Container = styled.div`
  flex: 1;

  .ApplicationData {
    margin-bottom: 24px;
  }
`;

const ApplicationTerminateButton = styled(Button)`
  margin-bottom: 24px;
`;

export default ApplicationShow;
