import { get } from 'lodash';
import { ApplicationPayload } from '~/types/ApplicationPayload';

const emiratesIdOptionalFields = (n: (name: string) => string, nationality: string | undefined) => {
  if (nationality === 'UAE') {
    return {
      [n('emiratesId.files')]: '',
      [n('emiratesId.expiryDate')]: '',
      [n('emiratesId.documentNumber')]: '',
    };
  }
  return {};
};

const visaOptionalFields = (n: (name: string) => string, nationality: string | undefined) => {
  if (nationality === 'UAE') {
    return {
      [n('visa.files')]: '',
      [n('visa.expiryDate')]: '',
    };
  }
  return {};
};

const personOptionalFields = (payload: ApplicationPayload, name: string) => {
  function n(path: string) {
    return [name, path].join('.');
  }
  const nationality = get(payload, n('nationality'));
  return {
    ...emiratesIdOptionalFields(n, nationality),
    ...visaOptionalFields(n, nationality),
  };
};

const personShareholdersOptionalFields = (payload: ApplicationPayload, name: string) => {
  function n(path: string) {
    return [name, path].join('.');
  }
  return {
    [n('emiratesId.files')]: '',
    [n('emiratesId.expiryDate')]: '',
    [n('emiratesId.documentNumber')]: '',
    [n('visa.files')]: '',
    [n('visa.expiryDate')]: '',
  };
};

export default {
  companyInformation: () => ({}),
  addresses: () => {
    return {
      'addresses.headOffice.geolocation': '',
      'addresses.headOffice.faxNumber': '',
      'addresses.documents.files': '',
      'addresses.photos.outside.files': '',
      'addresses.photos.inside.files': '',
    };
  },
  soleProprietorAuthorizedSignatory: (payload) => {
    return personOptionalFields(payload, 'authorizedRepresentative.0');
  },
  multipleAuthorizedSignatory: (payload: any) => {
    return {
      ...personOptionalFields(payload, 'authorizedRepresentative.0'),
      ...personOptionalFields(payload, 'authorizedRepresentative.1'),
    };
  },
  multipleShareholders: (payload) => {
    const individualsCount = (payload.shareholders?.individuals || []).length;
    const beneficialOwnersCount = (payload.shareholders?.beneficialOwners || []).length;

    let optionalFields: Record<string, string> = {
      'shareholders.additionalInfo.files': '',
      'shareholders.additionalInfo.comment': '',
    };

    for (let i = 0; i <= individualsCount; i++) {
      optionalFields = {
        ...optionalFields,
        ...personOptionalFields(payload, `shareholders.individuals.${i}.data`),
      };
    }
    for (let i = 0; i <= beneficialOwnersCount; i++) {
      optionalFields = {
        ...optionalFields,
        ...personShareholdersOptionalFields(payload, `shareholders.beneficialOwners.${i}.data`),
      };
    }
    // all business fields are mandatory

    return optionalFields;
  },
  additionalInformation: () => {
    const result: Record<string, string> = {
      'additionalInformation.companyInformation.website': '',
      'additionalInformation.companyInformation.primaryLocation': '',
      'additionalInformation.companyInformation.tradeArea': '',
      'additionalInformation.companyInformation.numberOfEmployees': '',
    };
    return result;
  },
  bankAccount: () => {
    return {
      'bankAccount.devicesAccount.accountName': '',
      'bankAccount.ecommerceAccount.accountName': '',
    };
  },
} as { [x: string]: (payload: ApplicationPayload) => Record<string, string> };
