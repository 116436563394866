import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Typography } from 'antd';

import ReviewAndSubmitTabs from '~/components/views/ReviewAndSubmit';
import { getStagePayload } from '~/utils/getStagePayload';
import { useGetApplication } from '~/controllers/wizard';

const ReviewAndSubmit: FC = () => {
  const { t } = useTranslation('fillApplication');
  const applicationQuery = useGetApplication();
  const terminationInfo = getStagePayload.terminated(applicationQuery.data)?.terminationInfo;

  return (
    <Container className="ReviewAndSubmit">
      <Typography.Title>{t('Review & Submit')}</Typography.Title>
      {terminationInfo?.terminationReason ? (
        <>
          <Typography.Title level={4}>{t('Termination reason').toUpperCase()}</Typography.Title>
          <Typography.Paragraph>{terminationInfo?.terminationReason}</Typography.Paragraph>
        </>
      ) : (
        <Typography.Paragraph>
          <p>
            {t(
              'Please select each tab to review the information before submitting the application.'
            )}
          </p>
          <p>{t('To make changes, go to the appropriate section.')}</p>
        </Typography.Paragraph>
      )}
      <ReviewAndSubmitTabs />
    </Container>
  );
};

const Container = styled.div`
  .ReviewAndSubmitTabs {
    margin-top: 40px;
  }
`;

export default ReviewAndSubmit;
