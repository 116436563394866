export default {
  Required: 'Required',
  'Required at least 1': 'Required at least 1',
  'Impossible number': 'Impossible number',
  'Invalid number': 'Invalid number',
  'Invalid email': 'Invalid email',
  'Must contain digits only': 'Must contain digits only',
  'Must be a string': 'Must be a string',
  'Person must be older 18 years old': 'Person must be older 18 years old',
  'Must be {{days}} days before': 'Must be {{days}} days before',
  'Must be {{days}} days after': 'Must be {{days}} days after',
  'Must be like https://example.com': 'Must be like https://example.com',
  'Must be at least {{count}} character': 'Must be at least {{count}} character',
  'Must be at least {{count}} characters': 'Must be at least {{count}} characters',
  'Max length is {{count}} character': 'Max length is {{count}} character',
  'Max length is {{count}} characters': 'Max length is {{count}} characters',
  'Must be {{count}} character': 'Must be {{count}} character',
  'Must be {{count}} characters': 'Must be {{count}} characters',
  'Min is {{minNumber}}': 'Min is {{minNumber}}',
  'Max is {{maxNumber}}': 'Max is {{maxNumber}}',
  'Must be more than {{num}}': 'Must be more than {{num}}',
  'Must be less than {{num}}': 'Must be less than {{num}}',
  'Total shares is {{totalShares}}': 'Total shares is {{totalShares}}',
  'Invalid IBAN': 'Invalid IBAN',
  'Must starts with {{countryCode}}': 'Must starts with {{countryCode}}',
  'Impossible Emirates ID. Check for typos carefully':
    'Impossible Emirates ID. Check for typos carefully',
  "Character '.' (dot) should not be first or last character":
    "Character '.' (dot) should not be first or last character",
  "Character ' ' (space) should not be first or last character":
    "Character ' ' (space) should not be first or last character",
  "Character '.' (dot) should not appear two or more times consecutively":
    "Character '.' (dot) should not appear two or more times consecutively",
  "Character ' ' (space) should not appear two or more times consecutively":
    "Character ' ' (space) should not appear two or more times consecutively",
  'Includes forbidden symbols': 'Includes forbidden symbols',
  'Max length is 255 characters': 'Max length is 255 characters',
  "Character ' ' (space) is forbidden": "Character ' ' (space) is forbidden",
  'Should contain only letters (A-Z) and digits (0-9)':
    'Should contain only letters (A-Z) and digits (0-9)',
  'A-Z (hyphen) and 0-9 symbols are allowed only': 'A-Z (hyphen) and 0-9 symbols are allowed only',
  'Must be unique. Check another representative': 'Must be unique. Check another representative',
  'Must be unique. Check another shareholder': 'Must be unique. Check another shareholder',
  'Mark at least one person as an authorized signatory':
    'Mark at least one person as an authorized signatory',
  'Upload Copy of Memorandum of Association or Partnership deed':
    'Upload Copy of Memorandum of Association or Partnership deed',
  'Total shares must be at least {{minShares}}%': 'Total shares must be at least {{minShares}}%',
  'Edit persons at corresponding pages': 'Edit persons at corresponding pages',
  'Must includes letters': 'Must includes letters',
  'Must includes only letters': 'Must includes only letters',
  'Must include only latin letters': 'Must include only latin letters',
};
