import React, { FC } from 'react';
import { InputProps } from 'antd';
import msk from 'msk';

import LtrInput from './LtrInput';

const EMIRATES_ID_MASK = '784-XXXX-YYYYYYY-Z';

const m = (value: any) => msk(value?.toString() || '', EMIRATES_ID_MASK);

const EmiratesIdInput: FC<InputProps> = (props) => {
  const { value, onChange, ...rest } = props;

  return (
    <LtrInput
      value={m(value)}
      onChange={(e) => {
        const newValue = e.target.value || '';
        // eslint-disable-next-line no-param-reassign
        e.target.value = m(newValue);
        onChange?.(e);
      }}
      {...rest}
    />
  );
};

export default EmiratesIdInput;
