import React, { FC, useEffect } from 'react';
import { FormInstance, Radio, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import styled from 'styled-components';

import Form from '~/components/WizardForm';
import buildNamePath from '~/utils/buildNamePath';
import SanctionsAdditionalQuestionsInputs from './SanctionsAdditionalQuestionsInputs';
import { useIsSomeoneSanctioned } from '~/controllers/sanctions';
import { useGetApplication } from '~/controllers/wizard';
import { getStagePayload } from '~/utils/getStagePayload';
import { isAgent } from '~/utils/getDecodedJwt';
import Section from '~/layouts/Section ';
import DocumentInput from '~/components/inputs/DocumentInput';

interface Props {
  form: FormInstance;
}

const SanctionsQuestionsInputs: FC<Props> = (props) => {
  const { form } = props;
  const { t } = useTranslation('fillApplication');

  const applicationQuery = useGetApplication();
  const stagePayload = getStagePayload.wizard(applicationQuery.data);
  const errorFields = stagePayload?.activeStep.errorInformation?.errorFields;
  const enabledFields = stagePayload?.activeStep.errorInformation?.enabledFields;
  const needComplianceDocument =
    isAgent() &&
    (errorFields?.find((errMap) =>
      Object.keys(errMap).includes('additionalInformation.complianceDocument.files')
    ) ||
      enabledFields?.includes('additionalInformation.complianceDocument.files'));

  const isSomeoneSanctioned = useIsSomeoneSanctioned();

  useEffect(() => {
    if (form && isSomeoneSanctioned.data === true) {
      form.setFields([
        {
          name: commonPaths.isPersonResident,
          value: isSomeoneSanctioned.data,
        },
      ]);
    }
  }, [form, isSomeoneSanctioned.data]);

  if (isSomeoneSanctioned.isLoading) return <Spin size="large" />;
  return (
    <Container>
      <Typography.Title level={5} className="Questionnaire-NoBottomSpace">
        {t(
          'Does your company have any direct/indirect dealings involving a Sanctioned Country* or any Sanctioned Party**?'
        )}
      </Typography.Title>
      <Form.Item name={commonPaths.hasDealings}>
        <Radio.Group>
          <Radio value>{t('Yes')}</Radio>
          <Radio value={false}>{t('No')}</Radio>
        </Radio.Group>
      </Form.Item>

      <Typography.Title level={5} className="Questionnaire-NoBottomSpace">
        {t(
          'Is your company owned by any person/entity that is incorporated/based in, or resident in a Sanctioned Country/Territory?'
        )}
      </Typography.Title>
      <Form.Item name={commonPaths.isPersonResident}>
        <Radio.Group disabled={isSomeoneSanctioned.data}>
          <Radio value>{t('Yes')}</Radio>
          <Radio value={false}>{t('No')}</Radio>
        </Radio.Group>
      </Form.Item>

      <Typography.Title level={5} className="Questionnaire-NoBottomSpace">
        {t(
          'Does your business have any offices, investment activities or planned activities in any sanctioned country or have an ownership interest in a Sanctioned Party?'
        )}
      </Typography.Title>
      <Form.Item name={commonPaths.hasPropertyIn}>
        <Radio.Group>
          <Radio value>{t('Yes')}</Radio>
          <Radio value={false}>{t('No')}</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.ShouldUpdate name={n(['sanctions'])}>
        {() => {
          const formValues = form.getFieldsValue();
          const hasDealings = get(formValues, commonPaths.hasDealings);
          const isPersonResident = get(formValues, commonPaths.isPersonResident);
          const hasPropertyIn = get(formValues, commonPaths.hasPropertyIn);
          if (hasDealings || isPersonResident || hasPropertyIn) {
            return <SanctionsAdditionalQuestionsInputs />;
          }
          return null;
        }}
      </Form.ShouldUpdate>

      {needComplianceDocument && (
        <Section formItemLast>
          <Typography.Title level={4}>{t('Compliance document').toUpperCase()}</Typography.Title>
          <Form.Item name={n(['complianceDocument', 'files'])} rules={[{ required: true }]}>
            <DocumentInput name="photos" variant="default" />
          </Form.Item>
        </Section>
      )}

      <Typography.Paragraph className="Questionnaire-Asterisk">
        <p>
          {t(
            '*Sanctioned Countries - Iran, Syria, Cuba, North Korea)/Territory (Crimea) and any other countries/territories as updated time to time by the relevant authorities.'
          )}
        </p>
        <p>
          {t(
            '**A sanctioned party includes any person or entity designated for sanction reasons by United Nations Security council, The European Union, The Office of Foreign Assets Control (OFAC), UK HMT and Central Bank of UAE Blacklist.'
          )}
        </p>
      </Typography.Paragraph>
    </Container>
  );
};

const Container = styled.div`
  .Questionnaire-NoBottomSpace {
    margin-bottom: 0;
  }
  .Questionnaire-Asterisk {
    font-size: 12px;
    line-height: 16px;
  }
`;

const n = buildNamePath('additionalInformation');
const commonPaths = {
  hasDealings: n(['sanctions', 'hasDealings']),
  isPersonResident: n(['sanctions', 'isPersonResident']),
  hasPropertyIn: n(['sanctions', 'hasPropertyIn']),
};

export default SanctionsQuestionsInputs;
